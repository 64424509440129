import React, { useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ProductSchemeTyping, ProductSectionProps } from "../../types";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import { Plus, Undo } from "lucide-react";
import VariantMutationModal from "./VariantMutationModal";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import VariantDeletionModal from "./modals/VariantDeletionModal";
import BaseMaterialButton from "@reusables/BaseMaterialButton";

export default function Variants({ editing }: ProductSectionProps) {
    const { t } = useTranslation("", { keyPrefix: "products.general" });

    const { control, setValue, getValues } = useFormContext<ProductSchemeTyping>();

    const { fields: variants, append, update, remove } = useFieldArray({
        control,
        name: "variants",
        keyName: "loop_id"
    });

    const deletedVariants = useWatch({
        control,
        name: "deleted_variants"
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

    const [selectedVariantIndex, setSelectedVariantIndex] = useState<number>();

    return (
        <>
            <div className="space-y-[40px]">
                <div className="flex justify-between">
                    <div className="text-accent text-xl">{t("categories.variants")}</div>
                    {
                        !!variants.length &&
                        <BaseMaterialIconButton
                            icon={<Plus size={24} />}
                            onClick={() => {
                                setIsModalOpen(true);
                            }}

                            className="ml-[16px] mb-4"
                        />
                    }
                </div>
                {
                    variants.length > 0 ?
                        variants.map((variant, index) => {
                            const isDeleted = variant.id && deletedVariants.includes(variant.id);

                            return (
                                <div className="flex justify-between mt-2" key={variant.loop_id}>
                                    <div className="flex flex-row">
                                        <div
                                            className={`text-accent ${isDeleted ? "line-through opacity-40" : "underline hover:opacity-70 cursor-pointer"}`}
                                            onClick={() => {
                                                setIsModalOpen(true);
                                                setSelectedVariantIndex(index);
                                            }}
                                        >
                                            {variant.general.name}
                                        </div>
                                        {
                                            !variant.id && (
                                                <div
                                                    className="ml-2 text-tables-secondaryIcon">{t("fields.newVariant")}</div>
                                            )
                                        }
                                        {
                                            isDeleted &&
                                            <div className="ml-2 text-tables-secondaryIcon">
                                                {t("fields.deletedVariant")}
                                            </div>
                                        }
                                    </div>

                                    <div className="flex space-x-2">
                                        {!isDeleted ? (
                                            <>
                                                <EditSVG
                                                    className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                                    onClick={() => {
                                                        setIsModalOpen(true);
                                                        setSelectedVariantIndex(index);
                                                    }}
                                                />

                                                <TrashSVG
                                                    className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                                    onClick={() => {
                                                        setIsDeletionModalOpen(true);
                                                        setSelectedVariantIndex(index);
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <Undo
                                                className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                                onClick={() => setValue("deleted_variants", deletedVariants.filter((id) => id !== variant.id))}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })
                        :
                        (
                            <>
                                <div className="border border-solid border-gray-300 rounded-[8px] p-[26px] flex flex-column justify-center items-center space-y-[24px]">

                                    <div className="text-xl text-center text-gray-300 font-thin">
                                        {t("fields.noVariants")}
                                    </div>
                                    <BaseMaterialButton
                                        size="large"
                                        fontWeight={500}
                                        type="button"
                                        sx={{
                                            width: "300px"
                                        }}
                                        onClick={() => {
                                            setIsModalOpen(true);
                                            setSelectedVariantIndex(undefined);
                                        }}>
                                        {t("fields.addVariant")}
                                    </BaseMaterialButton>
                                </div>
                            </>
                        )
                }
            </div>

            <VariantMutationModal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setSelectedVariantIndex(undefined);
                }}
                onCreation={(variant) => {
                    selectedVariantIndex !== undefined ? update(selectedVariantIndex, variant) : append(variant);

                    setIsModalOpen(false);
                    setSelectedVariantIndex(undefined);
                }}
                selectedVariantIndex={selectedVariantIndex}
            />

            <VariantDeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => {
                    setIsDeletionModalOpen(false);
                    setSelectedVariantIndex(undefined);
                }}
                onDeletion={() => {
                    if (selectedVariantIndex === undefined) return;

                    const variantId = variants[selectedVariantIndex].id;
                    if (variantId) {
                        setValue("deleted_variants", [...deletedVariants, variantId]);
                    } else {
                        remove(selectedVariantIndex);
                    }

                    setIsDeletionModalOpen(false);
                    setSelectedVariantIndex(undefined);
                }}
                variant={selectedVariantIndex != undefined ? { name: variants[selectedVariantIndex]?.general?.name } : undefined}
            />
        </>
    );
}
