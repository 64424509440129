import React, {useMemo} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {
    PurchaseOrdersFormTyping
} from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/MutationLayout/types";
import {calculateOrderPrice, normalizePrice, smartParseFloat} from "@helpers/utils";

export default function SummaryBlock() {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.general.orders"});

    const {control} = useFormContext<PurchaseOrdersFormTyping>();

    const currency = useWatch({ control, name: "currency" });

    const lines = useWatch({
        control,
        name: "lines"
    });

    const shippingCost = useWatch({
        control,
        name: "shipping_cost"
    });

    const splitByProducts = useWatch({
        control,
        name: "is_split_by_products"
    });

    const linesSummary = useMemo(() => {
        let subtotal = 0;
        let taxes = 0;

        lines.forEach(line => {
            const linePrice = calculateOrderPrice(line?.data?.quantity ?? 0, line?.data?.unit_price?.converted ?? 0, line?.data?.discount ?? 0);
            subtotal += linePrice;
            taxes += linePrice * ((line?.data?.tax?.rate ?? 0) / 100);
        });

        const shipping = smartParseFloat(shippingCost) ??  0;
        const totalWithTaxes = subtotal + taxes + shipping;

        return {
            subtotal,
            taxes,
            shipping,
            totalWithTaxes
        }
    }, [lines, shippingCost]);

    return (
        <div className="flex justify-end mt-[48px] font-thin">
            <div className="w-1/2 grid grid-cols-2 gap-y-[16px]">
                <div className="text-right">
                    {t("summary.subtotal")}
                </div>
                <div className="text-right pr-[16px]">
                    {
                        normalizePrice(linesSummary.subtotal) 
                    } 
                    <span className="ml-1">{currency?.code}</span>
                </div>
                <div className="text-right">
                    {t("summary.taxes")}
                </div>
                <div className="text-right pr-[16px]">
                    {
                        normalizePrice(linesSummary.taxes)
                    }
                    <span className="ml-1">{currency?.code}</span>
                </div>

                {
                    !splitByProducts &&
                    (
                        <>
                            <div className="text-right animate-in fade-in">
                                {t("summary.shipping")}
                            </div>
                            <div className="text-right pr-[16px] animate-in fade-in">
                                {
                                    normalizePrice(linesSummary.shipping)
                                }
                                <span className="ml-1">{currency?.code}</span>
                            </div>
                        </>
                    )
                }

                <div className="text-right font-semibold text-accent">
                    {t("summary.total")}
                </div>
                <div className="text-right pr-[16px] font-semibold text-accent">
                    {
                        normalizePrice(linesSummary.totalWithTaxes)
                    }
                    <span className="ml-1">{currency?.code}</span>
                </div>
            </div>
        </div>
    )
}