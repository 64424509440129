import React from "react";
import { useTranslation } from "react-i18next";
import BaseInput from "@reusables/BaseInput";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import ToggleWrapper from "../../ToggleWrapper";
import UnitDropdown from "./elements/UnitDropdown";
import CategoryDropdown from "./elements/CategoryDropdown";
import LocationDropdown from "./elements/LocationDropdown";
import SupplierDropdown from "./elements/SupplierDropdown";
import TaxDropdown from "./elements/TaxDropdown";
import {
    ProductSectionProps,
    ProductType
} from "@components/Dashboard/pages/Products/components/CreationEditingLayout/types";
import { Controller, useFormContext } from "react-hook-form";
import ServiceHider from "../elements/ServiceHider";

export default function General({editing}: ProductSectionProps) {
    const {t} = useTranslation("", {keyPrefix: "products.general"});

    const {control} = useFormContext<ProductType>();

    return (
        <>
            <BaseInputsGrid title={t("categories.general")}>
                {/*  PRODUCT NAME */}
                <Controller
                    name={"general.name"}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("fields.productName") + " *"}
                        />
                    )}
                />

                {/*  PRODUCT CODE */}
                <Controller
                    name="general.code"
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("fields.productCode")}
                            disabled={editing}
                        />
                    )}
                />

                {/*  BARCODE */}
                <ServiceHider>
                    <ToggleWrapper
                        label={t("fields.barcode")}
                        fieldKey="barcode">
                        <Controller
                            name="general.barcode"
                            control={control}
                            render={({field, fieldState}) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                />
                            )}
                        />
                    </ToggleWrapper>
                </ServiceHider>

                {/*  CATEGORY */}
                <CategoryDropdown/>

                {/* UNIT */}
                <UnitDropdown/>


                {/*  DEFAULT LOCATION */}
                {
                    !editing &&
                    <ServiceHider>
                        <LocationDropdown/>
                    </ServiceHider>
                }

                {/*/!*  PRIMARY SUPPLIER *!/*/}
                <SupplierDropdown/>

                {/*  TAX */}
                <TaxDropdown/>
            </BaseInputsGrid>
        </>
    )
}