import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, CompanyPayload, TokensPayload, UserPayload } from "@redux/features/auth/types";
import { Integration } from "@/types/general";
import { RootState } from "@redux/hooks";


export const authSlice = createSlice({
    name: "auth",
    initialState: {} as AuthState,
    reducers: {
        authenticate: (state, action: PayloadAction<TokensPayload>) => {
            const tokens = action.payload;
            state.tokens = tokens;

            state.isAuthenticated = true;

            localStorage.setItem("sanctum", tokens.access);
        },

        logout(state) {
            state.tokens = undefined;
            state.user = undefined;
            state.isAuthenticated = false;

            localStorage.setItem("sanctum", "");
        },

        setUser(state, action: PayloadAction<UserPayload>) {
            state.user = action.payload;
        },

        setRole(state, action: PayloadAction<UserPayload["role"]>) {
            if (state.user) {
                state.user.role = action.payload;
            } else {
                console.warn("Trying to set permissions for an unauthenticated user!");
            }
        },

        setAccessToken(state, action: PayloadAction<string>) {
            if (state.tokens) {
                state.tokens.access = action.payload;
            } else {
                console.warn("Trying to set an access token for an unauthenticated user!");
            }
        },

        setCompany(state, action: PayloadAction<CompanyPayload>) {
            if (state.user) {
                state.user.company = action.payload;
            } else {
                console.warn("Trying to set a company for an unauthenticated user!");
            }
        },

        setIntegrations(state, action: PayloadAction<CompanyPayload["integrations"]>) {
            if (state.user) {
                state.user.company.integrations = action.payload;
            } else {
                console.warn("Trying to set integrations for an unauthenticated user!");
            }
        },

        enableIntegration(state, action: PayloadAction<Integration.ActiveState.Root>) {
            if (state.user) {
                state.user!.company.integrations![action.payload.slug] = action.payload;
            } else {
                console.warn("Trying to set an integration for an unauthenticated user!");
            }
        },
        disableIntegration(state, action: PayloadAction<Integration.ActiveState.Root["slug"]>) {
            if (state.user) {
                delete state.user!.company.integrations![action.payload];
            } else {
                console.warn("Trying to disable an integration for an unauthenticated user!");
            }
        },
    }
});

export const isSystemIntegratedSelector = (slug: Integration.ActiveState.Root["slug"]) => (state: RootState) => {
    return slug in (state.auth.user?.company?.integrations ?? {});
};

export const getPowerOfficeIntegrationSettingsSelector = (state: RootState) => state.auth.user?.company?.integrations?.poweroffice as Integration.ActiveState.PowerOfficeGO;

// Action creators are generated for each case reducer function
export const {
    authenticate,
    logout,
    setRole,
    setCompany,
    setUser,
    setAccessToken,
    setIntegrations,
    enableIntegration,
    disableIntegration,
} = authSlice.actions;

export default authSlice.reducer;