import React, { useEffect, useRef, useState } from "react";
import Select, { components, GroupBase } from "react-select";

// reusables
import BaseButton from "../BaseButton";

// MUI
import { Pagination } from "@mui/material";

// scss
import "./index.scss";
import BaseMaterialButton from "../BaseMaterialButton";
import { useTheme } from "@mui/material/styles";
import { DropdownOption } from "./types";
import SelectType from "react-select/dist/declarations/src/Select";

export type BaseDropdownType = {
  label?: string;
  placeholder?: string;
  iconColor?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  backgroundColor?: string;
  useBorderGradient?: boolean;
  selectName?: string;
  id?: string;
  options?: DropdownOption[];
  disabled?: boolean;

  value?: DropdownOption | any;
  onChange?: (e: any) => void;

  height?: string;
  width?: number;
  minWidth?: number;

  createNew?: boolean;
  createNewText?: string;
  createNewAction?: (e: any) => void;

  paginated?: boolean;
  paginationCount?: number;
  paginationCurrentPage?: number;
  paginationChange?: (e: any, value: any) => void;
  paginationLoading?: boolean;

  className?: string;

  slim?: boolean;
};

const BaseDropdown = ({
  label,
  placeholder,
  leftIcon,
  rightIcon,
  iconColor,
  backgroundColor = "#fff",
  useBorderGradient = false,
  selectName,
  id,
  options,
  disabled,
  value,
  onChange,
  height,
  width,
  minWidth,
  createNew,
  createNewText = "Create",
  createNewAction,
  paginated,
  paginationCount,
  paginationCurrentPage,
  paginationChange,
  paginationLoading,
  className,

  slim = false,
}: BaseDropdownType) => {

  const theme = useTheme();

  const [selectedValue, setSelectedValue] = useState(value);

  const CustomArrow = () => (
    <span className="absolute right-5">
      {!paginationLoading && (
        <>
          {
            rightIcon ??
            <svg
              width="20"
              height="11"
              viewBox="0 0 20 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.6263 1.12625C18.0168 0.735723 18.65 0.735723 19.0405 1.12625C19.431 1.51677 19.431 2.14994 19.0405 2.54046L17.6263 1.12625ZM10.7071 9.45958L10 8.75248L10 8.75247L10.7071 9.45958ZM9.29293 9.45958L10 8.75247L10 8.75247L9.29293 9.45958ZM0.9596 2.54046C0.569076 2.14994 0.569076 1.51677 0.9596 1.12625C1.35013 0.735723 1.98329 0.735723 2.37381 1.12625L0.9596 2.54046ZM19.0405 2.54046L11.4143 10.1667L10 8.75247L17.6263 1.12625L19.0405 2.54046ZM8.58583 10.1667L0.9596 2.54046L2.37381 1.12625L10 8.75247L8.58583 10.1667ZM11.4143 10.1667C10.6332 10.9477 9.36687 10.9477 8.58583 10.1667L10 8.75247L10 8.75248L11.4143 10.1667Z"
                fill={iconColor??"#7556FA"}
              />
            </svg>
          }
        </>
      )}
    </span>
  );

  const SelectMenuButton = (props: any) => {
    return (
      <components.MenuList {...props}>
        {props.children}

        <div
          className="d-flex justify-content-center align-items-center flex-column"
          style={{ paddingRight: "24px", paddingLeft: "240px" }}
        >
          {paginated && (
            <Pagination
              sx={{
                marginTop: "20px",
              }}
              count={paginationCount}
              page={paginationCurrentPage}
              onChange={paginationChange}
              shape="rounded"
              disabled={paginationLoading}
            />
          )}
          {createNew && (
            <div className="mt-[24px] w-full">
              <BaseMaterialButton
                size="large"
                fontWeight={600}
                onClick={createNewAction}
                className="w-full"
              >
                {createNewText}
              </BaseMaterialButton>
            </div>
          )}
        </div>
      </components.MenuList>
    );
  };

  const selectRef = useRef<SelectType<any, false, GroupBase<any>>>(null);

  const handleSelectClick = () => {
    if (selectRef.current) {
      selectRef.current.focus();
    }
  };

  return (
    <div
      className={`input-box w-full ${className}${slim ? " slim" : ""}`}
      style={{ width: `${width}px`, zIndex: "9999 !important" }}
    >
      {label && (
        <p className="label mb-2 text-md-semibold text-gray-600">
          {label}
        </p>
      )}
      <div style={{ position: "relative" }} onClick={handleSelectClick}>
        <Select
          menuPortalTarget={document.body}
          ref={selectRef}

          styles={{
            menu: (provided, state) => ({
              ...provided,
              minWidth: selectRef.current?.controlRef?.offsetWidth ?? "max-content",
              background: backgroundColor,
              boxShadow: "-6px 5px 33px rgba(59, 65, 208, 0.11)",
              borderRadius: "10px",
              marginTop: "8px",
              padding: "16px 0",

              zIndex: 9999,
            }),
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999,
            }),
            menuList: (provided) => ({
              ...provided,
              padding: "0 16px"
            }),
            control: (provided, state) => ({
              ...provided,
              ...(useBorderGradient ? {
                background: `linear-gradient(${backgroundColor}, ${backgroundColor}) padding-box, linear-gradient(90deg, rgba(43, 42, 53, 1), rgba(105, 89, 252, 1)) border-box`,
                borderColor: "transparent",
                "&:hover": {
                  borderColor: "transparent"
                }
              } : {
                border: "1px solid #D9D6DA",
                "&:hover": {
                  borderColor: "#D9D6DA"
                }
              }),
              width,
              borderRadius: 8,
              zIndex: 9999,
              cursor: "pointer",
              ...(state.isFocused && {
                boxShadow: "none",
                borderColor: "#7556FA !important"
              })
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              zIndex: 9999,
              padding: "0 8px",
              paddingRight: "2.5rem",
              ...(leftIcon && {
                paddingLeft: "48px"
              })
            }),
            input: (provided, state) => ({
              ...provided,
              zIndex: 9999,
            }),
            option: (provided, state) => ({
              ...provided,
              padding: "12px 18px",
              gap: "10px",
              fontWeight: 400,
              fontSize: 16,
              cursor: "pointer",
              zIndex: 9999,
              transition: "background-color .05s",
              borderRadius: 8,
              marginBottom: "4px",
              color: state.isSelected ? theme.custom.textAccent : theme.custom.gray[600],
              backgroundColor: state.isSelected ? theme.custom.purple[100] : "transparent",
              ...(!state.isSelected &&
                {
                  "&:hover":{
                    backgroundColor: theme.custom.gray[100]
                  }
                }
              )
            }),
            placeholder: (provided) => ({
              ...provided,
              color: "#D9D6DA",
            }),
          }}

          options={options}
          value={selectedValue}
          placeholder={placeholder}
          components={{
            DropdownIndicator: CustomArrow,
            IndicatorSeparator: () => null,
            MenuList: SelectMenuButton,
          }}
          onChange={(newValue, actionMeta) => {
            setSelectedValue(newValue);

            onChange?.(newValue);
          }}
          isSearchable={false}
          isLoading={paginationLoading}
          isDisabled={disabled}
        />
        {
          leftIcon &&
          <div className="absolute top-[50%] left-[16px]" style={{
            transform: "translateY(-50%)",
            zIndex: 9999
          }}>
            {leftIcon}
          </div>
        }
      </div>
    </div>
  );
};

export default BaseDropdown;