import React, { useEffect, useMemo, useState } from "react";
import { SidebarItemT, Link } from "../types";
import { NavLink, useHistory } from "react-router-dom";
import { Collapse, Icon } from "@mui/material";
import SidebarItemsGroup from "../SidebarItemsGroup";

import "./index.scss";

// Sidebar redux store related (for managing collapse)
import { useDispatch, useSelector } from "react-redux";
import { StoreState, collapseGroup } from "../../../../redux/sidebarStore";
import BaseTooltip from "@reusables/BaseTooltip";

interface SidebarItemProperties{
  item: SidebarItemT;
  recursionHierarchy: number[];
  isMinified: boolean;
}

/**
 * Renders sidebar item as link or collapsable group depending on it`s type.
 * @param item can be a {@link Link} / {@link ItemsGroup} (generally {@link SidebarItemT})
 * @param recursionHierarchy array of groups` UID, included in each-other, forming tree hierarchy.
 * Needed for closing collapsed groups which are not parents to currently pressed or in another tree.
 * Includes UIDs of parent-groups, !excluding! current.
 * @constructor
 */
export default function SidebarItem({
  item,
  recursionHierarchy,
  isMinified
                                    }: SidebarItemProperties): JSX.Element{
  const dispatch = useDispatch();

  // Rendering simple link, which we cannot collapse
  if(item instanceof Link){
    const isItemActive = window.location.pathname.includes(item.link);

    return (
      <BaseTooltip title={item.getTitle()} active={isMinified}>
        <li className={`sidebar-item ${isItemActive ? " !bg-sidebar-active" : ""}`}>
          <NavLink to={item.link} className="sidebar-link text-sidebar-item flex items-center" onClick={() => dispatch(collapseGroup(recursionHierarchy))}>
            <div className={`sidebar-item-icon${isItemActive ? " bg-sidebar-iconActive" : ""}`}>
              {item.icon}
            </div>
            <div className="group-[.minified]:hidden truncate grow ml-[16px] text-sidebar-text">
              {item.getTitle()}
            </div>
            <div className="group-[.minified]:hidden">
              <div className="active-icon ml-3 mr-[10px]"></div>
            </div>
          </NavLink>
        </li>
      </BaseTooltip>
    )
  }
  // Rendering collapsable link, using recursive call to SidebarItemsGroup component
  else{
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    /**
     * forwardingHierarchy variable stores current recursionHierarchy, acquired from recursion calls, combined with current item UID.
     * It is used to set active collapse hierarchy in store, if needed, and pass hierarchy to the next recursively rendering component.
     * This solution has been chosen to prevent closing collapsed groups when nested groups being collapsed
     * (problem might be seen in earlier commits (before 2022.12.15) on [nekear/menu-sidebar] branch.)
     */
    const [forwardingHierarchy] = useState<number[]>([...recursionHierarchy, item.UID]);

    // Getting collapsed group id to detect, whether we should collapse current group or not
    const collapsedHierarchy = useSelector((state: StoreState) => state.collapseSlice.collapseHierarchy);

    useEffect(() => {
      setIsCollapsed(collapsedHierarchy.includes(item.UID));
    }, [collapsedHierarchy]);

    return(
      <>
        <li className={`sidebar-item sidebar-link`}>
          <div onClick={() => {
            dispatch(
              collapseGroup(
                isCollapsed ? recursionHierarchy : forwardingHierarchy
              )
            );
          }} className={`flex items-center ${isCollapsed ? "collapsed" : ""}`}>
            <div className="sidebar-item-icon">
              {item.icon}
            </div>
            <div className="group-[.minified]:hidden truncate ml-[16px] grow text-sidebar-text">
              {item.getTitle()}
            </div>
            <div className="group-[.minified]:hidden">
              <Icon className="collapse-icon text-sidebar-unaccent">arrow_drop_down</Icon>
            </div>
          </div>
        </li>
        <Collapse in={isCollapsed}>
          <SidebarItemsGroup items={item.includes} recursionHierarchy={forwardingHierarchy} isMinified={isMinified}/>
        </Collapse>
      </>
    );
  }
}