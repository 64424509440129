import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/react";
import { AxiosError, AxiosRequestConfig } from "axios";
import { AppDispatch, RootState } from "@redux/hooks";
import caxios from "@/config";
import { logout, setAccessToken } from "../features/auth/authSlice";
import { Mutex } from "async-mutex";
import i18nInstance from "@/i18n";

export type FetchServerError = {
    code: number,
    message: string,
    __origin: AxiosError
}

type BaseQueryArgs = {
    url: string,
    method?: AxiosRequestConfig["method"],
    headers?: AxiosRequestConfig["headers"],
    data?: AxiosRequestConfig["data"],
    params?: AxiosRequestConfig["params"],
    responseType?: AxiosRequestConfig["responseType"],
    parseDates?: string[],
    useAuth?: boolean
}

type BaseQueryScheme<ResponseInData = { payload: any }> = BaseQueryFn<
    BaseQueryArgs,
    ResponseInData,
    FetchServerError,
    unknown
>;


export const axiosBaseQuery: <ResponseInData = { payload: any }>() => BaseQueryScheme<ResponseInData> =
    () =>
        async ({ useAuth = true, ...args }, api) => {
            try {

                // Getting redux store to allow extracting auth token
                const store = api.getState() as RootState;

                // Get the token from your Redux state
                const token = store.auth.tokens?.access;

                const result = await caxios(
                    {
                        url: args.url,
                        headers: {
                            "X-App-Locale": i18nInstance.language,
                            ...(useAuth ? { Authorization: `Bearer ${token}` } : {}),
                            ...args.headers
                        },
                        responseType: args.responseType,
                        method: args.method,
                        data: args.data,
                        params: args.params,
                        parseDates: args.parseDates
                    }
                );

                return { data: result.data };
            } catch (axiosError) {
                const err = axiosError as AxiosError<FetchServerError>;
                let errorData = err.response?.data;

                // Needed to overcome the issue with axios not parsing the response data when responseType is set to blob
                if (errorData && args.responseType === "blob") {
                    const text = await (err.response?.data as any).text();
                    errorData = JSON.parse(text);
                }

                return {
                    error: {
                        code: err.response?.status as number,
                        message: errorData?.message ?? err.message,
                        __origin: err
                    }
                };
            }
        };

const mutex = new Mutex();

export const axiosBaseQueryWithReauth =
    (): BaseQueryScheme =>
        async (args, api, extraOptions) => {
            // Wait until the mutex is available without locking it
            await mutex.waitForUnlock();

            let result = await axiosBaseQuery()(args, api, extraOptions);
            if (result.error?.code === 401) {
                const store = api.getState() as RootState;
                // If mutex unlocked, proceeding to refresh the token
                if (!mutex.isLocked() && store.auth.tokens?.refresh) {
                    const release = await mutex.acquire();

                    try {
                        const dispatch = api.dispatch as AppDispatch;

                        // Trying to get a new token
                        const refreshResult = await axiosBaseQuery<{ payload: { token: string } }>()(
                            {
                                url: "refresh-token",
                                method: "POST",
                                useAuth: false,
                                headers: {
                                    Authorization: `Bearer ${store.auth.tokens?.refresh}`
                                }
                            },
                            api,
                            extraOptions
                        );

                        if (refreshResult.data) {
                            const newAccessToken = refreshResult.data.payload.token;
                            // Updating access token in store
                            dispatch(setAccessToken(newAccessToken));
                            // Retrying the original request
                            result = await axiosBaseQuery()(args, api, extraOptions);
                        } else {
                            api.dispatch(logout());
                        }
                    } finally {
                        // Releasing the mutex
                        release();
                    }
                } else {
                    // Waiting until the mutex is available without locking it
                    await mutex.waitForUnlock();

                    result = await axiosBaseQuery()(args, api, extraOptions);
                }
            }

            return result;
        };


/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(
    error: unknown
): error is FetchServerError {
    return (
        typeof error === "object" &&
        error != null &&
        "message" in error &&
        typeof (error as any).message === "string"
        && "code" in error
    );
}

/**
 * Type predicate to check if an object has an 'errors' property that is an array of arrays of strings
 */
export function isErrorWithValidationErrors(
    obj: unknown
): obj is { errors: string[][] } {
    return (
        typeof obj === "object" &&
        obj != null &&
        "errors" in obj &&
        Array.isArray((obj as any).errors) &&
        (obj as any).errors.every((error: unknown) => Array.isArray(error) && error.every((item: unknown) => typeof item === "string"))
    );
}