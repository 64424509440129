import React from "react";
import HeaderLogo from "../../../../assets/images/logo.svg";
import HeaderLogoMinified from "../../../../assets/images/logo-sm.svg";
import SidebarItemsGroup from "../SidebarItemsGroup";
import { SidebarItemT } from "../types";
import { NavLink } from "react-router-dom";

interface DrawerContentProperties{
  items: SidebarItemT[],
  isMinified: boolean
}

export default function DrawerContent({
  items,
  isMinified
                                      }: DrawerContentProperties){
  return(
    <div className={`px-[20px] group${isMinified ? ` minified` : ``}`}>
      <div className="py-[56px] text-center">
        <NavLink to={"/dashboard/main"}>
          {
            isMinified
              ? <img src={HeaderLogoMinified} width={44} alt="suppli logo"/>
              : <img src={HeaderLogo} alt="suppli logo"/>
          }
        </NavLink>
      </div>
      <SidebarItemsGroup
        items={items}
        recursionHierarchy={[]}
        useIntending={false}
        isMinified={isMinified}
      />
    </div>
  )
}