import React from "react";
import BaseMaterialCheckbox from "@reusables/BaseMaterialCheckbox";
import { Collapse } from "@mui/material";
import { cn } from "@/helpers/utils";

interface SelectableImportCategoryProps {
    text: string;
    icon: JSX.Element;
    isSelected: boolean;
    onChange: (value: boolean) => void;
    children?: JSX.Element;
}

export default function SelectableImportCategory(props: SelectableImportCategoryProps) {
    return (
        <div
            className={
                cn(
                    "border-[1px] border-solid rounded-lg bg-white transition-all overflow-hidden relative",
                    "hover:border-inputs-border-focused",
                    props.isSelected ? "border-inputs-border-focused" : `border-inputs-border-default cursor-pointer`
                )
            }
        >
            <div
                className={"flex flex-row items-center justify-between space-x-2 cursor-pointer p-[16px]"}
                onClick={() => {
                    props.onChange(!props.isSelected);
                }}
            >
                <div>
                    {props.icon}
                </div>
                <div className={"grow"}>
                    {props.text}
                </div>
                <div className={"grow text-right"}>
                    <BaseMaterialCheckbox
                        checked={props.isSelected}
                        onChange={(e, v) => props.onChange(v)}
                        checkedClassName={"bg-purple-500"}
                    />
                </div>
            </div>
            <Collapse in={props.isSelected && !!props.children}>
                <div className={"border-t-[1px] border-t-inputs-border-default space-y-1 p-[16px]"}
                     style={{ borderTopWidth: "1px", borderTopStyle: "solid" }}>
                    {props.children}
                </div>
            </Collapse>
        </div>
    );
}