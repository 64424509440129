import { flattenPaginationRequest, internalApiSlice, PaginationRequest, PaginationResponse, transformResponse } from "@redux/api/internalApiSlice";
import { CustomerGroups } from "@/types/general";

export const customerGroupsApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomerGroups: builder.query<PaginationResponse<CustomerGroups.Root>, PaginationRequest<CustomerGroups.DTO.Filters, CustomerGroups.DTO.OrderBy>>({
            query: (req) => ({
                url: `customer-group`,
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: ['valid_from', 'valid_to'],
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({id}) => ({type: 'CustomerGroup' as const, id})),
                        {type: 'CustomerGroup', id: 'PARTIAL-LIST'},
                    ]
                    : [{type: 'CustomerGroup', id: 'PARTIAL-LIST'}]
        }),

        getCustomerGroup: builder.query<CustomerGroups.Extended, number>({
            query: (id) => ({
                url: `customer-group/${id}`,
                parseDates: ['valid_from', 'valid_to'],
            }),
            transformResponse,
            providesTags: (result, error, id) => [{type: 'CustomerGroup', id}],
        }),

        createCustomerGroup: builder.mutation<CustomerGroups.Extended, CustomerGroups.DTO.Create>({
            query: (body) => ({
                url: `customer-group`,
                method: "POST",
                data: body,
                parseDates: ['valid_from', 'valid_to'],
            }),
            invalidatesTags: (result, error, creationArgs) =>
                [
                    { type: "CustomerGroup", id: "LIST" },
                    ...(result ? result.customers.map(({id}) => ({ type: "Customer", id })) : []),
                ],
            transformResponse,
        }),

        updateCustomerGroup: builder.mutation<CustomerGroups.Extended, CustomerGroups.DTO.Update & {id: number}>({
            query: ({id, ...body}) => ({
                url: `customer-group/${id}`,
                method: "PUT",
                data: body,
                parseDates: ['valid_from', 'valid_to'],
            }),
            invalidatesTags: (result, error, {id}) => [
                { type: "CustomerGroup", id },
                ...(result ? result.customers.map(({id}) => ({ type: "Customer", id })) : [])
            ],
            transformResponse,
        }),

        deleteCustomerGroup: builder.mutation<void, number>({
            query: (id) => ({
                url: `customer-group/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, id) => [
                { type: "CustomerGroup", id },
                "Customer",
            ],
        }),
    }),
})

export const {
    useGetCustomerGroupsQuery,
    useGetCustomerGroupQuery,
    useCreateCustomerGroupMutation,
    useUpdateCustomerGroupMutation,
    useDeleteCustomerGroupMutation,
} = customerGroupsApi;