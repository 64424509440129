import React, { useEffect } from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseButton from "@reusables/BaseButton";
import { useTranslation } from "react-i18next";
import BaseNotFound from "@reusables/BaseNotFound";
import { z } from "zod";
import { useFieldArray, useForm } from "react-hook-form";
import {
    SerialGroup
} from "@components/Dashboard/pages/Sales/Picking/components/modals/SerialNumbersModal/SerialGroup";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { zodResolver } from "@hookform/resolvers/zod";
import { stopPropagate } from "@helpers/utils";

/**
 * This modal handles the validation of serial numbers.
 * Users can attach these numbers (click the "attach" button) if they meet the following criteria:
 * 1. All input fields are filled.
 * 2. For serial numbers, the number of inputs should not exceed the selected quantity. This applies to every product line.
 *
 * Once serial numbers are attached, users have the option to modify them or use them during the creation of a picking record.
 * If used in the picking creation, there might be instances where the number of serials is less than the selected quantity.
 * In such cases:
 * 1. Users can edit and add more serial numbers.
 * 2. An error message will be displayed, prompting the user to match the number of serials with the selected quantity.
 */



const formScheme = z.object({
    lines: z.array(
        z.object({
            id: z.number(),

            comment: z.string().nullish(),

            product: z.object({
                id: z.number(),
                name: z.string(),
                code: z.string()
            }),

            picked_quantity: z.coerce.number(),

            // Serial_number cannot be empty, however because of RHF bug
            // we cannot correctly validate the serials, if they are added to the end of the array
            // therefore we add them with a `prepend` method.
            serial_numbers: z.array(
                z.object({
                    serial_number: z.string().nonempty()
                })
            )
        }).refine(
            scheme => scheme.serial_numbers.length <= scheme.picked_quantity,
            {
                message: "Serial numbers count cannot be greater than picked quantity",
                path: ["serial_numbers"]
            }
        )
    )
});

export type SerialNumbersScheme = z.infer<typeof formScheme>

interface SerialNumbersModalProps {
    isOpen: boolean;
    onClose: () => void;

    /**
     * Note: only FILTERED lines should be provided. Component doesn't handle any picked_quantity or outgoing_location checks.
     */
    lines: {
        id: number;

        comment?: string;
        
        product: {
            id: number;
            name: string;
            code: string;
        };

        picked_quantity?: number;

        serial_numbers?: {
            serial_number: string;
        }[]
    }[];

    onSave?: (data: SerialNumbersScheme) => void;

    preview?: boolean;
}

export default function SerialNumbersModal(props: SerialNumbersModalProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.picking.modals.serialNumbers" });

    const { control, handleSubmit, setValue, reset } = useForm<SerialNumbersScheme>({
        resolver: zodResolver(formScheme)
    });

    const { fields: lines } = useFieldArray({
        control,
        name: "lines"
    });

    const onSubmit = handleSubmit(data => props.onSave?.(data));

    useEffect(() => {
        if (props.isOpen) {
            const formReadyLines = props.lines
                .map(line => {
                    const serial_numbers = line.serial_numbers?.length ? line.serial_numbers : [{ serial_number: "" }];

                    return {
                        id: line.id,
                        comment: line.comment,
                        product: {
                            id: line.product.id,
                            name: line.product.name,
                            code: line.product.code
                        },
                        picked_quantity: Math.ceil(line.picked_quantity ?? 0),
                        serial_numbers
                    };
                });

            setValue(
                "lines",
                formReadyLines
            );
        } else {
            reset();
        }
    }, [props.lines, props.isOpen, setValue, reset]);

    return <BaseModal
        isOpen={props.isOpen}
        onClose={props.onClose}
        width={900}
        padding="56px"
        position={"center"}
    >
        <form onSubmit={stopPropagate(onSubmit)} className="space-y-[32px]">
            {
                lines.length ?
                    lines.map((line, lineIndex) => (
                        <SerialGroup
                            key={line.id}
                            index={lineIndex}
                            control={control}
                            preview={props.preview}
                        />
                    ))
                    :
                    <BaseNotFound text={t("noProductsPicked")} height={250} />
            }
            <BaseInputsGrid cols={props.preview ? 1 : 2}>
                {/* "Cancel" button */}
                <BaseButton text={t("buttons.cancel")}
                            size="md"
                            buttonWidth={"100%"}
                            primaryOutlined
                            type={"button"}
                            onClick={props.onClose}
                />

                {/* "Attach" button */}
                {
                    !props.preview &&
                    <BaseButton text={t("buttons.attach")}
                                size="md"
                                buttonWidth={"100%"}
                    />
                }
            </BaseInputsGrid>
        </form>
    </BaseModal>;
}