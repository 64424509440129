import { createContext, useContext } from "react";
import { Permission } from "@/types/general";

interface PermissionsContextProps {
    permissions: Permission.Root;
}

export const PermissionsContext = createContext<PermissionsContextProps>(null!);

export const usePermissionsContext = () => {
    const props = useContext(PermissionsContext);
    if (!props) {
        throw new Error("usePermissionsContext must be used within PermissionsContextProvider");
    }

    return props;
};
