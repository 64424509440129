import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import ViewLayout from "../components/ViewLayout";

import { useGetSaleOrderQuery } from "@redux/features/sales/orders/salesOrdersApi";

import { Can, useAbility } from "@/casl.config";
import { BaseTinyActionIcon, TinyActionIconType } from "@reusables/BaseTinyActionIcon";
import { useExportOrderMutation } from "@redux/api/internalApiSlice";
import { downloadBlobExport } from "@helpers/utils";
import OrderDeletionModal from "../components/modals/DeleteOrder";
import BaseTooltip from "@reusables/BaseTooltip";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function SaleOrderDetailsPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "sales.orders.mutations.details" });

    const ability = useAbility();

    const { id } = useParams<{ id: string }>();

    const { data: saleOrder, isLoading: isSaleOrderLoading } = useGetSaleOrderQuery(parseInt(id));

    const [isDeletionModalOpen, setIsDeletionModalOpen] = React.useState<boolean>(false);

    const [exportPDF, { isLoading: isExportLoading }] = useExportOrderMutation();

    return (
        <>
            <Can not I="view" a="sale_order">
                <div className="levitation-extended">
                    <NoPermissionBanner />
                </div>
            </Can>

            <Can I="view" a="sale_order">
                <BaseRecordRenderer record={saleOrder} loading={isSaleOrderLoading || isExportLoading} hideLevitation>
                    <div className="space-y-[40px] levitation-extended">
                        <div className="flex items-center">
                            <h6 className="text-accent grow">
                                {t("heading")} #{saleOrder?.code ?? saleOrder?.id}
                            </h6>
                            <div className="space-x-[8px] flex items-center h-full">
                                <BaseTinyActionIcon
                                    type={TinyActionIconType.PDF}
                                    onClick={() =>
                                        exportPDF({ id: saleOrder?.id as number, type: "sale" })
                                            .unwrap()
                                            .then((data) => {
                                                downloadBlobExport(() => data, `sales_order_${saleOrder?.code ?? saleOrder?.id}`, "pdf");
                                            })
                                    }
                                />
                                <BasePermissionBlocker action="create" module="picking">
                                    <BaseTooltip active={ability.can("create", "picking")} title="Create picking" placement="bottom">
                                        <BaseTinyActionIcon type={TinyActionIconType.StartPicking} onClick={() => ability.can("create", "picking") && void history.push(`/dashboard/picking/new`, { saleOrder: saleOrder })} />
                                    </BaseTooltip>
                                </BasePermissionBlocker>
                                <BasePermissionBlocker action="edit" module="picking">
                                    <BaseTooltip active={ability.can("edit", "picking")} title="Edit" placement="bottom">
                                        <BaseTinyActionIcon type={TinyActionIconType.Edit} onClick={() => ability.can("edit", "picking") && history.push(`/dashboard/sales/orders/${saleOrder?.id}/edit`)} />
                                    </BaseTooltip>
                                </BasePermissionBlocker>
                                <BasePermissionBlocker action="delete" module="picking">
                                    <BaseTooltip active={ability.can("delete", "picking")} title="Delete" placement="bottom">
                                        <BaseTinyActionIcon type={TinyActionIconType.Delete} onClick={() => ability.can("delete", "picking") && setIsDeletionModalOpen(true)} />
                                    </BaseTooltip>
                                </BasePermissionBlocker>
                            </div>
                        </div>
                        <ViewLayout.Main saleOrder={saleOrder as NonNullable<typeof saleOrder>} />
                    </div>

                    <ViewLayout.Pickings saleOrder={saleOrder as NonNullable<typeof saleOrder>} />
                </BaseRecordRenderer>
            </Can>

            <OrderDeletionModal isOpen={isDeletionModalOpen} entity_id={parseInt(id)} onClose={() => setIsDeletionModalOpen(false)} onDeletion={() => history.push("/dashboard/sales/orders")} />
        </>
    );
}
