import { Product } from "@/types/general";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    product: null as Product.Extended | null
}

const productsSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        addProductCopy: (state, action: PayloadAction<Product.Extended>) => {
            state.product = action.payload;
        },
        emptyProductCopy: (state) => {
            state.product = null;
        }
    }
})

export const { addProductCopy, emptyProductCopy } = productsSlice.actions;

export default productsSlice.reducer;
