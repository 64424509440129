import React, { useEffect, useMemo, useState } from "react";
import PermanentDrawer from "./PermanentDrawer";
import { ItemsGroup, Link, SidebarItemT } from "./types";
import "./index.scss";

// Imported icons needed for sidebar links
import { ReactComponent as DashboardIcon } from "@assets/icons/sidebar/ic_tiles.svg";
import { ReactComponent as AllProductsIcon } from "@assets/icons/sidebar/ic_small_bag.svg";
import { ReactComponent as BOMIcon } from "@assets/images/icons/dashboard/bom.svg";

import { ReactComponent as PurchasingIcon } from "@assets/icons/sidebar/ic_bag.svg";
import { ReactComponent as AllPurchasesIcon } from "@assets/icons/sidebar/ic_market_bucket.svg";
import { ReactComponent as ReceiveIcon } from "@assets/icons/sidebar/ic_package_receive.svg";

import { ReactComponent as InventoryIcon } from "@assets/icons/sidebar/ic_packages.svg";
import { ReactComponent as StockIcon } from "@assets/icons/sidebar/ic_packages_more.svg";
import { ReactComponent as AdjustmentIcon } from "@assets/icons/sidebar/ic_present.svg";
import { ReactComponent as CollectionsIcon } from "@assets/icons/sidebar/ic_folders.svg";
import { ReactComponent as TransfersIcon } from "@assets/icons/sidebar/ic_buildings.svg";

import { ReactComponent as SuppliersIcon } from "@assets/icons/sidebar/ic_package_delivery.svg";

import { ReactComponent as SalesIcon } from "@assets/images/icons/dashboard/sales.svg";
import { ReactComponent as OrdersIcon } from "@assets/images/icons/dashboard/orders.svg";

import { ReactComponent as ClientsIcon } from "@assets/images/icons/dashboard/clients.svg";
import { ReactComponent as CustomerIcon } from "@assets/icons/sidebar/ic_person.svg";
import { ReactComponent as CustomerGroupsIcon } from "@assets/icons/sidebar/ic_people_group.svg";
import { ReactComponent as SetupIcon } from "@assets/images/icons/dashboard/setup.svg";
import { ReactComponent as PickingIcon } from "@assets/icons/sidebar/ic_picking.svg";

// Sidebar store to manage closing of collapsing blocks
import { sidebarStore } from "@redux/sidebarStore";
import { Provider } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAbility } from "@casl/react";
import { AbilityContext } from "@/casl.config";

interface SidebarProperties {
    minifyOnURLs?: string[];
}

export default function Sidebar({ minifyOnURLs = [] }: SidebarProperties): JSX.Element {
    const { t } = useTranslation("", { keyPrefix: "dashboard.sidebar" });

    const ability = useAbility(AbilityContext);

    // List of links, that should be rendered. Supports collapsable groups and simple links.
    // Modify as needed.
    // ! NOTE: *Link* comes from custom types.ts not from react-dom-router !
    const [items, setItems] = useState<SidebarItemT[]>([]);

    // TODO: [nekear] finish translations
    useEffect(() => {
        setItems([
            new Link(<DashboardIcon />, () => t("dashboard"), "/dashboard/main"),
            // new Link(<CalendarIcon style={{marginTop: "-3px"}}/>, () => t("calendar"), "/dashboard/calendar"),
            new ItemsGroup(<AllPurchasesIcon />, () => t("products"), [
                new Link(
                    <AllProductsIcon />, () => t("allProducts"), "/dashboard/products"),
                new Link(
                    <BOMIcon />, () => t("productionOrder"), "/dashboard/bill-of-materials")
            ]),
            // new Link(<AllProductsIcon />, () => t("products"), "/dashboard/products", Link.checkPermissions(ability, "product", ["index", "create", "export"])),
            new ItemsGroup(
                <PurchasingIcon />,
                () => t("purchasing"),
                [
                    new Link(<AllPurchasesIcon />, () => t("purchases"), "/dashboard/purchasing"),
                    new Link(<ReceiveIcon />, () => t("receive"), "/dashboard/receive")
                ],
                // ability.can("view", "purchase_order") || ability.can("view", "receive")
            ),
            // new Link(<AllPurchasesIcon/>, () => t("purchases"), "/dashboard/purchasing"),
            new ItemsGroup(<InventoryIcon />, () => t("inventory"), [
                new Link(<StockIcon />, () => t("stock"), "/dashboard/inventory/stock"),
                // new Link(<StockIcon/>, () => "Stock Count", "/dashboard/inventory/stock-count"),
                new Link(
                    <AdjustmentIcon />, () => t("adjustments"), "/dashboard/inventory/adjustments"),
                new Link(<CollectionsIcon />, () => t("collections"), "/dashboard/inventory/collections"),
                new Link(
                    <TransfersIcon />, () => t("transfers"), "/dashboard/inventory/transfers")
            ]),
            new ItemsGroup(<SalesIcon />, "Sales", [
                new Link(
                    <OrdersIcon />, () => t("orders"),
                    "/dashboard/sales/orders"
                ),
                new Link(
                    <PickingIcon />, () => t("picking"), "/dashboard/picking")
            ]),
            // new Link(<OrdersIcon/>, () => t("orders"), "/dashboard/sales/orders"),
            new ItemsGroup(<ClientsIcon />, () => t("customers"), [
                new Link(
                    <CustomerIcon />, () => t("customers"), "/dashboard/customers"),
                new Link(
                    <CustomerGroupsIcon />, () => t("customerGroup"), "/dashboard/customer-groups")
            ]),
            new Link(
                <SuppliersIcon />, () => t("suppliers"), "/dashboard/suppliers"),

            // new Link(<UserlogsIcon/>, () => t("userLogs"), "/dashboard/user-logs"),

            new Link(<SetupIcon />, () => t("settings"), "/dashboard/settings/company/details")
        ]);
    }, [ability]);

    // Minified items list, containing only Link(s). Check out minifyItems function to learn more.
    const minifiedItems = useMemo(() => minifyItems(items), [items]);

    const isMinified = resolveMinification(minifyOnURLs);

    return (
        <Provider store={sidebarStore}>
            <PermanentDrawer items={isMinified ? minifiedItems : items} isMinified={isMinified} />
        </Provider>
    );
}

/**
 * Simple function to transform first-level groups to the first link of that groups.
 * Reason: minified version of sidebar shouldn`t contain dropdowns, replacing them the first link of that dropdown.
 * @param items original list of items to show in the not minified dropdown.
 */
function minifyItems(items: SidebarItemT[]): SidebarItemT[] {
    return items
        ?.filter((item) => {
            if (item instanceof ItemsGroup) return item.includes.filter((item) => item instanceof Link).length > 0;

            return true;
        })
        ?.map((item) => {
            if (item instanceof Link) return item;

            const firstLink = item.includes.filter((item) => item instanceof Link)[0] as Link;
            // Ensure that the arguments are in the correct order and of the correct type for the Link constructor
            return new Link(item.icon, item.title, firstLink.link, item.isAccessible, firstLink.UID);
        });
}

function resolveMinification(urls: string[]): boolean {
    return urls.filter((url) => window.location.pathname.includes(url)).length > 0;
}
