import React from "react";
import {Control, Controller} from "react-hook-form";
import {DetailsTabFormTyping} from "../index";
import {useGetCurrenciesPublicQuery} from "@redux/api/internalApiSlice";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";

export default function CurrencyDropdown({control}: {control: Control<DetailsTabFormTyping>}) {
    const {t} = useTranslation("", {keyPrefix: "settings.general.companyInformation.tabs.companyDetails"});

    const {data: currenciesOptions, isLoading: isCurrenciesOptionsLoading} = useGetCurrenciesPublicQuery();

    return <div>
        <Controller
            name={'base_currency'}
            control={control}
            render={({field, fieldState}) => (
                <BaseDropdown
                    {...field}
                    {...fieldState}
                    label={t('dropdowns.baseCurrency.label') + " *"}
                    options={currenciesOptions}
                    getter={{
                        label: opt => opt.name,
                        key: opt => opt.id,
                        caption: opt => opt.code
                    }}
                    isLoading={isCurrenciesOptionsLoading}
                    autocomplete
                />
            )}
        />
        <Typography variant="caption" display="block" sx={{opacity: 0.5}}>
            {t('dropdowns.baseCurrency.caption')}
        </Typography>
    </div>
}