import React, {cloneElement, PropsWithChildren, ReactElement} from "react";
import BaseToggleInput from "@reusables/BaseToggleInput";
import BaseSwitch from "@reusables/BaseSwitch";
import {ProductContext} from "../context";
import {useContextSafely} from "@helpers/utils";
import {DisTempField} from "../types";

type ToggleWrapperProperties = {
    label: string;
    fieldKey: DisTempField;
}

export default function ToggleWrapper({children, label, fieldKey}: PropsWithChildren<ToggleWrapperProperties>) {

    const productContext = useContextSafely(ProductContext, "ProductContext");

    const [disabledFields, setDisabledFields] = productContext.disabledFields;

    if (disabledFields.includes(fieldKey) && productContext?.templatesMode === "selected")
        return null;

    if (productContext?.product || productContext?.templatesMode === "selected") {
        return <div>
            <p className={`mb-2 font-semibold text-inputs-label-dim`}>
                {label}
            </p>
            {children}
        </div>
    } else {
        return (
            <BaseToggleInput
                control={
                    <BaseSwitch
                        checked={!disabledFields.includes(fieldKey)}
                        onChange={(el, state) => {
                            const newDisabled = disabledFields.filter(x => x !== fieldKey);

                            if (!state)
                                newDisabled.push(fieldKey);

                            setDisabledFields(newDisabled);
                        }}
                        className="focus:outline-offset-1 focus:color-primary-500 focus:shadow-outline-primary-500 focus:border-primary-500 focus:ring-primary-500 focus:ring-offset-primary-500"
                    />
                }
                label={label}
                inputEl={children ? cloneElement(children as ReactElement, {disabled: disabledFields.includes(fieldKey)}) : <></>}
            />
        );
    }
}