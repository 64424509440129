import { Dialog, DialogProps, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import BaseButton from "@reusables/BaseButton";

type Props = {
  title?: string;
  footer?: React.ReactNode;
  okText?: string;
  onSubmit?: () => any;
  onCancel?: () => any;
  children?: React.ReactNode;
} & DialogProps;

export const Modal = ({
  title,
  open,
  children,
  footer,
  okText = "Submit",
  onSubmit,
  onCancel,
  ...rest
}: Props) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onCancel}
        fullWidth={true}
        PaperProps={{
          style: {
            borderRadius: "20px",
            boxShadow: "none",
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: "#252525",
            opacity: "0.20",
          },
        }}
        {...rest}
      >
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="p-14 space-y-8">
          {title && (
            <div className="text-xl text-[#3C3769] text-center leading-[1.4] font-semibold">
              {title}
            </div>
          )}
          <div>{children}</div>
          <div className="flex justify-center gap-8">
            {footer ?? (
              <BaseButton
                className="w-full"
                size="md"
                text={okText}
                onClick={onSubmit}
              />
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Modal;
