import React, {useEffect} from 'react';

import BaseButton from '@reusables/BaseButton';
import BaseInput from '@reusables/BaseInput';
import BaseModal from '@reusables/Modals/BaseModal';
import BaseDropdown from '@reusables/dropdowns/BaseDropdown';

import {
    useCreateLanguageMutation,
    useGetLanguagesListQuery,
    useGetLanguagesQuery
} from '@redux/features/languages/languagesApi';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import {Controller, useForm, useWatch} from "react-hook-form";
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';


interface MutationLanguagesModalProperties {
    isOpen: boolean;
    onClose: () => void;
}

const formScheme = z.object({
    selectedLanguage: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string(),
    }),
})

type FormTyping = z.infer<typeof formScheme>;

export default function MutationModal(props: MutationLanguagesModalProperties) {
    const {t} = useTranslation("", {keyPrefix: "settings.general.languages.modals.mutation"});

    const {data: languagesOptions = [], isLoading: languagesOptionsLoading} = useGetLanguagesListQuery();
    const {data: companyLanguages = []} = useGetLanguagesQuery();
    const [createLanguage, {isLoading: isCreationLoading}] = useCreateLanguageMutation();

    const {control, handleSubmit, reset} = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
    });

    useEffect(() => {
        reset();
    }, [props.isOpen]);

    const onSubmit = handleSubmit((data) => {
        if (companyLanguages.some(lang => lang.id === data.selectedLanguage.id)) {
            toast.error(t("responses.creation.duplicate"));
            return;
        }
        createLanguage({
            language_id: data.selectedLanguage.id,
        }).then(() => {
            toast.success(t("responses.creation.success"));
        }).catch(() => {
            toast.error(t("responses.creation.error"));
        }).finally(() => {
            props.onClose();
        });
    });

    const languageCode = useWatch({
        control,
        name: "selectedLanguage.code",
    })

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={562}
            padding='56px'
            useCloseIcon
            isLoading={isCreationLoading}
        >
            <form onSubmit={onSubmit} className='space-y-[32px]'>
                <div className='text-xl font-semibold text-center text-accent'>
                    {t("creationHeading")}
                </div>

                <Controller
                    name={'selectedLanguage'}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}

                            label={t("fields.name.label")}
                            options={languagesOptions}
                            isLoading={languagesOptionsLoading}

                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name,
                            }}

                            autocomplete
                        />
                    )}
                />

                <BaseInput
                    label={t("fields.code.label")}
                    value={languageCode}
                    disabled
                />

                <div>
                    <BaseButton
                        buttonWidth='100%'
                        text={`${t("buttons.save")}`}
                        size='md'
                        type='submit'
                    />
                </div>
            </form>
        </BaseModal>
    );
}