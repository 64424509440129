import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {
    selectPackageByCode,
    useCreatePackageMutation,
    useGetPackagesQuery,
    useUpdatePackageMutation
} from '@redux/features/packaging/packagingApi';

import {Packaging} from '@/types/general';

import BaseModal from '@reusables/Modals/BaseModal';
import BaseInput from '@reusables/BaseInput';
import BaseButton from '@reusables/BaseButton';
import BaseInputsGrid from '@reusables/BaseInputsGrid';

import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {toast} from 'react-toastify';
import {selectEmployeeByCode} from "@redux/features/employees/employeesApi";
import {globalStore} from "@redux/store";

interface MutationModalProperties {
    isOpen: boolean;
    onClose: () => void;
    package?: Packaging.Root;
}

const formScheme = z.object({
    name: z.string().trim(),
    code: z.object({
        value: z.string().optional(),
        checkable: z.boolean().optional(),
    }).refine( (code) => {
        return code.checkable ? selectPackageByCode(globalStore.getState(), code.value) === undefined : true;
    }, {
        message: "Code is already taken!",
        path: ["value"]
    }),
    width: z.coerce.number().positive(),
    height: z.coerce.number().positive(),
    length: z.coerce.number().positive(),
});

type FormTyping = z.infer<typeof formScheme>

export default function MutationModal(props: MutationModalProperties) {
    const {t} = useTranslation("", {keyPrefix: "settings.general.packaging.modals.mutation"});

    // Getting RTK Query hooks for saving data
    const {data: packages} = useGetPackagesQuery();
    const [createPackage, {isLoading: isCreationLoading}] = useCreatePackageMutation();
    const [updatePackage, {isLoading: isUpdateLoading}] = useUpdatePackageMutation();

    const {control, handleSubmit, setValue, reset} = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            code: {
                checkable: true,
            }
        }
    });

    // Updating values depending on the selected packaging
    useEffect(() => {
        reset();

        if (props.package) {
            setValue("name", props.package.name);
            setValue("code.value", props.package.code);
            setValue("code.checkable", false)
            setValue("width", props.package.width);
            setValue("height", props.package.height);
            setValue("length", props.package.length);
        }
    }, [props.package, reset, props.isOpen]);

    const onSubmit = handleSubmit((data) => {
        if (props.package) {
            updatePackage({
                id: props.package.id,
                name: data.name,
                width: data.width,
                height: data.height,
                length: data.length,
            }).unwrap().then(() => {
                toast.success(t('responses.update.success'));

                props.onClose();
            }).catch(() => {
                toast.error(t('responses.update.error'));
            });
        } else {
            createPackage({
                name: data.name,
                code: data.code.value,
                width: data.width,
                height: data.height,
                length: data.length,
            }).unwrap().then(() => {
                toast.success(t('responses.creation.success'));

                props.onClose();
            }).catch(() => {
                toast.error(t('responses.update.error'));
            });
        }
    });

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={562} 
            padding='56px'
            useCloseIcon
            isLoading={isCreationLoading || isUpdateLoading}   
        >
            <form className='space-y-[32px]' onSubmit={onSubmit}>
                <div className='text-xl font-semibold text-center text-accent'>
                    {
                        props.package ?
                            props.package.name
                            :
                            t('creationHeading')
                    }
                </div>

                <Controller
                    name={'name'}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            hideErrors
                            label={t('fields.name.label') + ' *'}
                        />
                    )}
                />

                <Controller
                    name={'code.value'}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t('fields.code.label')}
                            onKeyPress={(event) => {
                                if (event.key === " ") {
                                    event.preventDefault();
                                }
                            }}

                            disabled={props.package !== undefined}
                        />
                    )}
                />

                <BaseInputsGrid cols={3}>
                    <Controller
                        name={'width'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                hideErrors
                                label={t('fields.width.label') + ' *'}
                                type={'number'}
                            />
                        )}
                    />

                    <Controller
                        name={'height'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                hideErrors
                                label={t('fields.height.label') + ' *'}
                                type={'number'}
                            />
                        )}
                    />

                    <Controller
                        name={'length'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                hideErrors
                                label={t('fields.length.label') + ' *'}
                                type={'number'}
                            />
                        )}
                    />
                </BaseInputsGrid>

                <div>
                    <BaseButton
                        buttonWidth='100%'
                        text={props.package ? `${t('buttons.save')}` : `${t('buttons.create')}`}
                        size='md'
                        type={'submit'}
                    />
                </div>
            </form>
        </BaseModal>
    );
}
