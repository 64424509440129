import React from "react";
import { useTranslation } from "react-i18next";
import { Collapse, Stack } from "@mui/material";
import BaseTooltip from "@reusables/BaseTooltip";
import { HelpCircle } from "lucide-react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import BaseSwitch from "@reusables/BaseSwitch";
import { cn } from "@helpers/utils";
import { LowStockRefillSchemeTyping } from "@components/Dashboard/pages/Sales/Orders/components/modals/LowStockRefillModal/types";

export default function RefillConfiguration() {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.mutations.creation.lowStockOffer.modals.refill" });

    const { control } = useFormContext<LowStockRefillSchemeTyping>();

    const linesWatch = useWatch({
        control,
        name: "lines"
    });

    const isAnyWithBOMPresent = linesWatch.some((l) => l.should_use_bom);

    return (
        <Collapse in={isAnyWithBOMPresent}>
            <div className={`border-b-[1px] border-b-inputs-border-default flex flex-row items-center justify-between pb-3`} style={{ borderBottomWidth: "1px", borderBottomStyle: "solid" }}>
                <Stack direction={"row"} alignItems={"center"} spacing={1} className={"pointer-events-auto"}>
                    <span>{t("smartRefill.label")}</span>
                    <BaseTooltip title={t("smartRefill.tooltip") + ""} placement={"right"}>
                        <HelpCircle size={16} color={"#686868"} />
                    </BaseTooltip>
                </Stack>
                <Controller
                    control={control}
                    name="smart_refill"
                    render={({ field }) => (
                        <BaseSwitch
                            checked={field.value}
                            onChange={field.onChange}
                            className={cn(
                                "focus:outline-offset-1 focus:color-primary-500 focus:shadow-outline-primary-500 focus:border-primary-500 focus:ring-primary-500 focus:ring-offset-primary-500"
                            )}
                        />
                    )}
                />
            </div>
        </Collapse>
    );
}