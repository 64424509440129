import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "@reusables/Modals/BaseModal";
import { Alert, Stack } from "@mui/material";
import BaseInput from "@reusables/BaseInput";
import BaseDatepicker from "@reusables/BaseDatepicker";
import BaseButton from "@reusables/BaseButton";
import { z } from "zod";
import { Controller, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import dayjs, { Dayjs } from "dayjs";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import { ProductSchemeTyping } from "@components/Dashboard/pages/Products/components/CreationEditingLayout/types";
import { stopPropagate } from "@helpers/utils";

interface BatchNumbersModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (data: FormTyping) => void;
}

const formScheme = z
    .object({
        in_stock: z.coerce.number(),
        batch_numbers: z.array(
            z.object({
                batch_number: z.string(),
                expiration_date: z
                    .instanceof(dayjs as unknown as typeof Dayjs)
                    .refine((value) => value.isValid(), {
                        message: "Invalid date"
                    })
                    .optional()
            })
        )
    })
    .refine((scheme) => scheme.batch_numbers.length <= scheme.in_stock, {
        params: {
            i18n: "products.newProduct.modals.batchNumbers.alerts.overflowBatchNumbers"
        },
        path: ["batch_numbers"]
    });

type FormTyping = z.infer<typeof formScheme>;

export default function BatchNumbersModal(props: BatchNumbersModalProps) {
    const { t } = useTranslation("", { keyPrefix: "products.newProduct.modals.batchNumbers" });

    const parentRHF = useFormContext<ProductSchemeTyping>();

    // Setting up RHF controller
    const {
        control,
        formState: { errors },
        setValue,
        handleSubmit,
        getValues,
        reset
    } = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            in_stock: 0,
            batch_numbers: [{}]
        }
    });

    // Configuring fields array
    const {
        fields: batchNumbers,
        remove,
        append,
        replace
    } = useFieldArray({
        control,
        name: "batch_numbers"
    });

    // Determining batch numbers custom errors
    const batchNumbersError = useMemo(() => {
        if (errors.batch_numbers) {
            return errors.batch_numbers as { root?: { message: string } } & { message?: string };
        }
    }, [errors]);

    // Handling form submit
    const onSubmit = handleSubmit((data) => {
        props.onSubmit(data);
    });

    // Loading currently set batch numbers
    useEffect(() => {
        if (props.isOpen) {
            const items = parentRHF.getValues("batch_numbers");
            reset({
                in_stock: parentRHF.getValues("inventory.in_stock") ?? 0,
                batch_numbers: items.length ? parentRHF.getValues("batch_numbers") : [{}]
            });
        }
    }, [props.isOpen]);

    return (
        <BaseModal isOpen={props.isOpen} onClose={props.onClose} width={912} padding="56px">
            <form className="space-y-[32px]" onSubmit={stopPropagate(onSubmit)}>
                <div className="flex items-center mb-[32px]">
                    <div className="grow text-lg font-semibold text-accent">{t("heading")}</div>
                    {
                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            {/* Batches for the product left (limited by picked quantity) */}
                            <div className={`opacity-50 ${batchNumbers.length > getValues("in_stock") ? "text-[#E25C5C]" : ""}`}>
                                ({batchNumbers.length} / {getValues("in_stock")})
                            </div>
                            {/* Button to append batches to the product */}
                            <BaseMaterialButton
                                onClick={() => {
                                    append({} as { batch_number: string });
                                }}
                            >
                                {t("buttons.addBatchNumber")}
                            </BaseMaterialButton>
                        </Stack>
                    }
                </div>

                {/* Error, if the batches number exceeds the picked quantity */}
                {!!batchNumbersError?.root && (
                    <Alert severity="error" className="mb-[32px]">
                        {batchNumbersError.root?.message}
                    </Alert>
                )}

                <div className="space-y-[8px]">
                    {batchNumbers.map((batch, batchIndex) => (
                        <Stack key={batch.id} direction={"row"} alignItems={"center"} spacing={1}>
                            <div className="w-full">
                                <BaseInputsGrid cols={2} gap={8}>
                                    {/* Value */}
                                    <Controller
                                        control={control}
                                        name={`batch_numbers.${batchIndex}.batch_number`}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <BaseInput
                                                    {...field}
                                                    error={fieldState.error}
                                                    placeholder={t("fields.batchNumber.placeholder")}
                                                    className="w-full"
                                                    sx={{
                                                        "&": {
                                                            input: {
                                                                background: "#F9F9F9",
                                                                padding: "12px",
                                                                border: 0
                                                            }
                                                        }
                                                    }}
                                                />
                                            </>
                                        )}
                                    />

                                    {/* Expiration date*/}
                                    <Controller
                                        control={control}
                                        name={`batch_numbers.${batchIndex}.expiration_date`}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <BaseDatepicker
                                                    {...field}
                                                    error={fieldState.error}
                                                    placeholder={t("fields.expirationDate.placeholder")}
                                                    inputSx={{
                                                        "&": {
                                                            border: 0,
                                                            padding: 0,
                                                            input: {
                                                                background: "#F9F9F9",
                                                                padding: "12px",
                                                                border: 0
                                                            }
                                                        }
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                </BaseInputsGrid>
                            </div>
                            {
                                <span>
                                    <DeleteSVG
                                        className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                        onClick={() => {
                                            remove(batchIndex);
                                        }}
                                    />
                                </span>
                            }
                        </Stack>
                    ))}
                </div>
                <div className="w-full">
                    <BaseButton text={t("buttons.save")} size="md" buttonWidth={"100%"} />
                </div>
            </form>
        </BaseModal>
    );
}
