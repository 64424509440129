import React from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseButton from "@reusables/BaseButton";
import { useDeleteProductTranslationMutation } from "@redux/features/products/productsApi";
import { toast } from "react-toastify";
import { Product } from "@/types/general";
import { isErrorWithMessage } from "@redux/api/query";

interface DeletionModalProperties {
    isOpen: boolean;
    selectedTranslation: { product_id: number; translation?: Product.Augmentations.Translation.Root };
    onDeletion?: () => void;
    onClose: () => void;
}

export default function DeletionModal(props: DeletionModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct.tabs.translations.modals.deletion" });

    const [deleteProductTranslation, { isLoading: isDeleteProductTranslationLoading }] = useDeleteProductTranslationMutation();

    return (
        <BaseModal 
            isOpen={props.isOpen} 
            onClose={props.onClose} 
            width={700} 
            padding="56px" 
            isLoading={isDeleteProductTranslationLoading}
        >
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">
                    {t("subHeading")} <span className="bold-highlight">{props.selectedTranslation.translation?.language.name}?</span>
                </div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose} />
                    <BaseButton
                        text={t("buttons.delete")}
                        size="md"
                        onClick={() => {
                            if (props.selectedTranslation.translation) {
                                deleteProductTranslation({ product_id: props.selectedTranslation.product_id, translation_id: props.selectedTranslation.translation?.id })
                                    .unwrap()
                                    .then(() => {
                                        toast.success(t("responses.success"));
                                        props.onClose();
                                        props.onDeletion?.();
                                    })
                                    .catch((e) => {
                                        if(isErrorWithMessage(e)) {
                                            toast.error(e.message)
                                        } else {
                                            toast.error(t("responses.error"));
                                        }
                                    });
                            }
                        }}
                    />
                </div>
            </div>
        </BaseModal>
    );
}