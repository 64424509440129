import React, { PropsWithChildren } from "react";
import { Fade, Popper, PopperPlacementType } from "@mui/material";

type BasePopperProperties = {
  open: boolean;
  anchorEl: HTMLElement | null;
  placement?: PopperPlacementType
}

export default function BasePopper({open, anchorEl, children, placement = "left-end"}: PropsWithChildren<BasePopperProperties>){
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      disablePortal={false}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        }
      ]}
      style={{zIndex: 10000}}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={{enter: 300, exit: 0}}>
          <div>
            {children}
          </div>
        </Fade>
      )}
    </Popper>
  );
}