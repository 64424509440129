import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImportProgressStatus, ImportState } from "./types";

const initialState = {
    value: {
        status: ImportProgressStatus.IDLE
    } as ImportState
};

export const importSlice = createSlice({
    name: "import",
    initialState: initialState,
    reducers: {
        startImport: (state, action: PayloadAction<{ module: string }>) => {
            state.value = {
                status: ImportProgressStatus.ACTIVE,
                module: action.payload.module
            };
        },

        updateImportProgress: (state, action: PayloadAction<{ current: number[], total: number[] }>) => {
            if (state.value.status === ImportProgressStatus.ACTIVE) {
                const { current, total } = action.payload;

                state.value = {
                    ...state.value,
                    progress: current.map((c, i) => ({
                        current: c,
                        total: total[i]
                    }))
                };
            }
        },

        finishImport: (state, action: PayloadAction<{ has_errors: boolean, data?: any }>) => {
            state.value = {
                status: ImportProgressStatus.FINISHED,
                module: state.value.module,
                has_errors: action.payload.has_errors,
                data: action.payload.data
            };
        },

        resetImport: (state) => {
            state.value = {
                status: ImportProgressStatus.IDLE
            };
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    startImport,
    updateImportProgress,
    finishImport,
    resetImport
} = importSlice.actions;

export default importSlice.reducer;