import React from "react";
import TypeButton from "../TypeButton";
import { IntegrationTypes } from "@components/Dashboard/pages/SettingsPage/Integrations/ThirdPartyIntegrations/types";


interface TypeSelectorProps {
    selectedType: IntegrationTypes;
    onTypeChange: (type: IntegrationTypes) => void;
}

export default function TypeSelector({ selectedType, onTypeChange }: TypeSelectorProps) {
    const types: IntegrationTypes[] = ["all", "accounting", "shipping"];

    return (
        <div className="flex gap-3">
            {types.map((type, index) => (
                <TypeButton
                    key={index}
                    selectedType={selectedType}
                    onTypeChange={onTypeChange}
                    buttonType={type}
                />
            ))}
        </div>
    );
}