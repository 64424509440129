import BaseModal from "@reusables/Modals/BaseModal";
import React, { useEffect, useState } from "react";
import { removeEmpty } from "@helpers/utils";
import BaseButton from "@reusables/BaseButton";
import { useTranslation } from "react-i18next";
import BaseInput from "@reusables/BaseInputLegacy";
import { validate, ValidationStore } from "@reusables/Validator/validationStore";
import BaseValidationManager from "@reusables/Validator";
import {Constraint, NotEmpty } from "@reusables/Validator/types";
import { DeliveryTerms } from "@/types/general";
import { toast } from "react-toastify";
import {
    useCreateDeliveryTermsMutation,
    useUpdateDeliveryTermsMutation
} from "@redux/features/deliveryTerms/deliveryTermsApi";
import BaseTextarea from "@reusables/BaseTextarea";

interface MutationPaymentTermsModalProperties {
    isOpen: boolean;
    onClose: () => void;
    deliveryTerms?: DeliveryTerms.Root;
}

export default function MutationModal(props: MutationPaymentTermsModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.deliveryTerms.modals.mutation" });

    // Initializing state for input fields
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>();

    // Initializing validation store
    const [validationStore, setValidationStore] = useState<ValidationStore>();

    // Getting RTK Query hooks for saving data
    const [createDeliveryTerms, { isLoading: isCreationLoading }] = useCreateDeliveryTermsMutation();
    const [updateDeliveryTerms, { isLoading: isUpdateLoading }] = useUpdateDeliveryTermsMutation();

    const minimalNameLengthConstraint = new Constraint("min", value => value.trim().length >= 3, "Text must be at least 3 characters long!");

    const handleSave = () => {
        validationStore?.dispatch(validate({
            onSuccess: () => {
                const data: DeliveryTerms.DTO.CreateUpdate = removeEmpty({
                    name,
                    description: description,
                });

                // If delivery terms provided in the props => performing update
                if (props.deliveryTerms) {
                    updateDeliveryTerms({
                        id: props.deliveryTerms.id,
                        ...data
                    })
                    .unwrap()
                    .then(() => {
                        toast.success(t("responses.update.success"));
                    }).catch(() => {
                        toast.error(t("responses.update.error"));
                    }).finally(() => {
                        props.onClose();
                    });

                // If no delivery terms provided as props => performing creation
                } else {
                    createDeliveryTerms(data)
                    .unwrap()
                    .then(() => {
                        toast.success(t("responses.creation.success"));
                    }).catch(() => {
                        toast.error(t("responses.creation.error"));
                    }).finally(() => {
                        props.onClose();
                    });
                }

            }
        }));
    };

    // Updating values depending on the selected payment terms
    useEffect(() => {
        setName(props.deliveryTerms?.name ?? "");
        setDescription(props.deliveryTerms?.description);
    }, [props.deliveryTerms]);

    return (
      <BaseModal
        isOpen={props.isOpen}
        onClose={props.onClose}
        width={562}
        padding="56px"
        useCloseIcon
        isLoading={isCreationLoading || isUpdateLoading}
      >
          <div className="space-y-[32px]">
              <div className="text-xl font-semibold text-center text-accent">
                  {
                      props.deliveryTerms ?
                        props.deliveryTerms.name
                        :
                        t("creationHeading")
                  }
              </div>

              <BaseValidationManager onStoreInit={setValidationStore}>
                  <BaseInput
                    label={t("fields.name.label") + " *"}
                    value={name ?? ""}
                    onChange={setName}

                    constraints={[new NotEmpty(), minimalNameLengthConstraint]}
                  />

                  <BaseTextarea
                    label={t("fields.description.label")}
                    value={description?.toString() ?? ""}
                    onChange={val => setDescription(val)}

                    constraints={[minimalNameLengthConstraint]}
                  />
              </BaseValidationManager>

              <div>
                  <BaseButton
                    buttonWidth="100%"
                    text={`${t("buttons.save")}`}
                    size="md"
                    onClick={() => handleSave()}
                  />
              </div>
          </div>
      </BaseModal>
    );
}