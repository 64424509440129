import React, { HTMLInputTypeAttribute, useEffect, useRef, useState } from "react";
import { ConstraintSupport } from "../Validator/types";
import { useValidator } from "@helpers/utils";
import BasePopper from "../BasePopper";
import {ReactComponent as CrossSVG} from "@assets/icons/ic_cross.svg"

type BaseTextareaProps = {
  label?: string;
  value?: string;
  placeholder?: string;
  brightLabel?: boolean;
  onChange?: (val: string, fieldId: string) => void;
  onFocus?: (val: string, fieldId: string) => void;
  disabled?: boolean;
  height?: string;
} & ConstraintSupport<string>;

export default function BaseTextarea({constraints, ...props}: BaseTextareaProps){
  const elRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>();
  // ==== VALIDATION RELATED ==== //
  const {uniqueId, validationStore, validationDispatch, validationTrigger, errors, resetValidation, revalidate} = useValidator<string>(constraints, props.value);

  useEffect(() => {
    revalidate(props.value ?? "");
  }, [props.value])

  return (
    <div {...(constraints && {ref: elRef})}>
      {props.label && <p className={`mb-2 font-semibold${props.brightLabel ? " text-inputs-label-bright" : " text-inputs-label-dim"}`}>
        {props.label}
      </p>}
      <div className="relative">
        <textarea
          value={props.value}
          placeholder={props.placeholder}
          onFocus={(event) => {
            props.onFocus?.(event.target.value, uniqueId.current);

            setIsFocused(true)
          }}
          onChange={(event) => props.onChange?.(event.target.value, uniqueId.current)}

          onBlur={() => setIsFocused(false)}
          className={`${props.disabled ? "bg-inputs-disabled" : "bg-inputs-default"} border-[1px] border-solid focus:border-inputs-border-focused transition-[.25s] placeholder:text-inputs-placeholder ${errors ? "!border-inputs-border-error text-inputs-textError" : `${props.disabled ? "border-transparent" : "border-inputs-border-default"} text-inputs-text`}`}
          disabled={props.disabled}
          style={{width: "100%", borderRadius: "8px", resize: "none", padding: "16px", height: props.height, fontWeight: 300}}
        />

        <BasePopper anchorEl={elRef.current} open={!!isFocused && !!errors} placement="bottom">
          <div className="bg-[#fff] p-[14px] rounded-[8px] levitation text-inputs-textError font-thin text-sm space-y-[4px]" style={{width: elRef.current?.offsetWidth}}>
            {
              errors?.map(error => (
                <div key={error.id} className="flex items-center"><CrossSVG style={{transform: "scale(.7)", marginRight: "4px", marginTop: "-1px"}}/> <span>{error.text}</span></div>
              ))
            }
          </div>
        </BasePopper>
      </div>

    </div>
  )
}