import React, { useEffect } from "react";
import { ModalProperties, ProductSchemeTyping, ProductType, rawProductScheme } from "../../types";
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import BaseModal from "@reusables/Modals/BaseModal";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseButton from "@reusables/BaseButton";
import { stopPropagate } from "@/helpers/utils";
import ServiceHider from "../elements/ServiceHider";
import { useTranslation } from "react-i18next";
import General from "@components/Dashboard/pages/Products/components/CreationEditingLayout/parts/General";
import Prices from "@components/Dashboard/pages/Products/components/CreationEditingLayout/parts/Prices";
import Weights from "@components/Dashboard/pages/Products/components/CreationEditingLayout/parts/Weights";
import Inventory from "@components/Dashboard/pages/Products/components/CreationEditingLayout/parts/Inventory";
import Options from "@components/Dashboard/pages/Products/components/CreationEditingLayout/parts/Options";
import Description from "@components/Dashboard/pages/Products/components/CreationEditingLayout/parts/Description";
import BaseCustomFieldsSection from "@/components/reusables/BaseCustomFieldsSection";

interface VariantMutationModalProps extends ModalProperties {
    onCreation: (variant: ProductType) => void;
    selectedVariantIndex?: number;
}

export default function VariantMutationModal(props: VariantMutationModalProps) {
    const parentForm = useFormContext<ProductSchemeTyping>();

    const variantForm = useForm<ProductType>({
        resolver: zodResolver(rawProductScheme),
        defaultValues: {
            batch_numbers: [],
            serial_numbers: [],
            options: {
                is_batch_number: false,
                is_serial_number: false,
                is_component: false
            },
            mode: "creation"
        },
        resetOptions: {
            keepDefaultValues: true
        }
    });


    const mode = useWatch({
        control: variantForm.control,
        name: "mode"
    });

    useEffect(() => {
        if (props.selectedVariantIndex !== undefined) {
            const variant = parentForm.getValues("variants")?.[props.selectedVariantIndex];
            if (variant) {
                // For editing purposes extracting values from existing (and selected) variant
                const resetValues = {
                    ...variantForm.getValues(), // Here spreading existing defaultValues to maintain unspecified defaults
                    id: variant.id,
                    mode: variant.id ? "editing" : "creation" as "editing" | "creation",
                    is_service: variant.is_service ?? false,
                    general: {
                        name: variant.general.name ?? "",
                        code: variant.general.code,
                        barcode: variant.general.barcode,
                        location: variant.general.location,
                        supplier: variant.general.supplier,
                        category: variant.general.category,
                        unit: variant.general.unit,
                        tax: variant.general.tax
                    },
                    prices: {
                        purchase: variant.prices?.purchase,
                        sale: variant.prices?.sale,
                        extra_cost: variant.prices?.extra_cost
                    },
                    inventory: {
                        min_purchase_quantity: variant.inventory?.min_purchase_quantity,
                        min_sale_quantity: variant.inventory?.min_sale_quantity,
                        in_stock: variant.inventory?.in_stock
                    },
                    weights: {
                        weight: variant.weights?.weight,
                        CBM: variant.weights?.CBM,
                        width: variant.weights?.width,
                        height: variant.weights?.height,
                        length: variant.weights?.length
                    },
                    options: {
                        is_batch_number: variant.options?.is_batch_number ?? false,
                        is_serial_number: variant.options?.is_serial_number ?? false,
                        is_component: variant.options?.is_component ?? false
                    },
                    description: {
                        image: variant.description?.image,
                        description: variant.description?.description
                    },
                    batch_numbers: variant.batch_numbers,
                    serial_numbers: variant.serial_numbers,
                    custom_fields: variant.custom_fields
                };
                variantForm.reset(resetValues);
            }
        } else {
            // Preparing values for a new variant based on parent form values, when creating a new variant
            const parentValues = parentForm.getValues();
            const resetValues = {
                ...variantForm.getValues(),
                is_service: parentValues.is_service ?? false,
                general: {
                    name: parentValues.general?.name ?? null,
                    supplier: parentValues.general?.supplier,
                    tax: parentValues.general?.tax,
                    location: parentValues.general?.location,
                    category: parentValues.general?.category,
                    unit: parentValues.general?.unit
                },
                prices: {
                    purchase: parentValues.prices?.purchase,
                    sale: parentValues.prices?.sale,
                    extra_cost: parentValues.prices?.extra_cost
                },
                inventory: {
                    min_purchase_quantity: parentValues.inventory?.min_purchase_quantity,
                    min_sale_quantity: parentValues.inventory?.min_sale_quantity
                },
                options: {
                    is_batch_number: parentValues.options?.is_batch_number,
                    is_serial_number: parentValues.options?.is_serial_number,
                    is_component: parentValues.options?.is_component
                },
                custom_fields: parentValues.custom_fields
            };
            variantForm.reset(resetValues);
        }
    }, [props.isOpen]);

    const onSubmit = variantForm.handleSubmit((data) => {
        props.onCreation(data);
        variantForm.reset();
        // TODO: [IMPORTANT] Due to unknown reasons, all the refines of the ZOD schema are called about 28 times in a row after variant editing is completed. This should be solved asap.
    }, console.error);

    return (
        <>
            <FormProvider {...variantForm} >
                <BaseModal
                    isOpen={props.isOpen}
                    onClose={props.onClose}
                    width={1144}
                    padding="56px"
                    useCloseIcon
                >
                    <form className="space-y-[32px]" onSubmit={stopPropagate(onSubmit)}>
                        <Heading />

                        <General editing={mode === "editing"} />

                        <Prices editing={mode === "editing"} />

                        <ServiceHider className={"space-y-[40px]"}>
                            <Weights />
                        </ServiceHider>

                        <Inventory editing={mode === "editing"} />

                        <ServiceHider className={"space-y-[40px]"}>
                            <Options />
                        </ServiceHider>

                        <BaseCustomFieldsSection.Mutation
                            module={"product"}
                            mode={mode}
                        />

                        <Description />

                        <BaseButton
                            className="w-full"
                            text={props.selectedVariantIndex != undefined ? "Save" : "Create"}
                            size="md"
                        />
                    </form>
                </BaseModal>
            </FormProvider>
        </>
    );
}

/**
 * In a separate component to avoid re-rendering the whole modal
 */
function Heading() {
    const { t } = useTranslation("", { keyPrefix: "products.createEditProduct.modals.variant" });

    const variantForm = useFormContext<ProductType>();

    const name = useWatch({
        control: variantForm.control,
        name: "general.name"
    });

    return (
        <h2 className="modal-title">{(name && name.length > 0) ? name : t("heading")}</h2>
    );
}