import { cn } from "@/helpers/utils";
import React, { PropsWithChildren } from "react";

type BadgeFill = "blue" | "green" | "yellow" | "none" | string;
export default function BaseChip(props: PropsWithChildren<{ fill?: BadgeFill, className?: string, asDot?: boolean }>) {
    if(props.asDot){
        return (
            <div className={cn("flex items-center space-x-2", props.className)}>
                <span className={`${getPalette(props.fill)} w-[8px] h-[8px] rounded-full`}></span>
                <span className="text-accent">{props.children}</span>
            </div>
        )
    }

    return (
        <div
            className={cn(`inline-block min-w-[160px] font-thin py-[2px] px-[10px] rounded-[8px] transition-[.15s] text-center`, getPalette(props.fill), props.className)}>
            {props.children}
        </div>
    );
}

function getPalette(fill?: BadgeFill) {
    switch (fill) {
        case "blue":
            return "text-accent bg-badge-blue";
        case "green":
            return "text-accent bg-badge-green";
        case "yellow":
            return "text-accent bg-badge-yellow";
        case "red":
            return "text-accent bg-badge-red";
        case "purple":
            return "text-accent bg-badge-purple";
        case "none":
            return "text-gray-600";
        default:
            return `text-accent bg-[${fill}]`;
    }
}