import React from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {ReceiptsMutationFormTyping} from "@components/Dashboard/pages/PurchasingPage/Receive/components/MutationLayout";
import BaseMaterialCheckbox from "@reusables/BaseMaterialCheckbox";

export default function LineCheckbox({index}: { index: number }) {
    const {control} = useFormContext<ReceiptsMutationFormTyping>()

    const receipt = useWatch({
        control,
        name: `receipts.${index}`
    })

    return (
        <BaseMaterialCheckbox
            checked={(receipt?.received_quantity ?? 0) > 0 && !!receipt?.location}
        />
    )
}