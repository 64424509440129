import React, { HTMLInputTypeAttribute, useEffect, useRef, useState } from "react";
import { ConstraintSupport } from "../Validator/types";
import { useValidator } from "@helpers/utils";
import BasePopper from "../BasePopper";
import { ReactComponent as CrossSVG } from "@assets/icons/ic_cross.svg"

type BaseInputProps = {
  label?: string;
  value?: string;
  type?: HTMLInputTypeAttribute;
  icon?: Partial<Record<"left" | "right", {
    el: JSX.Element,
    offset: string | number;
  }>>;
  placeholder?: string;
  brightLabel?: boolean;
  onChange?: (val: string, fieldId: string) => void;
  onFocus?: (val: string, fieldId: string) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onUIDInit?: (uid: string) => void;
  disabled?: boolean;
  padding?: string;
  height?: string;
  inputClasses?: (states: { hasErrors: boolean, isDisabled: boolean }) => string | undefined;
  containerClasses?: string; // better to make it as function
} & ConstraintSupport<string>;

export default function BaseInput({ padding = "16px", height, constraints, showPopper = true, ...props }: BaseInputProps) {
  const elRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>();

  // ==== VALIDATION RELATED ==== //
  const { uniqueId, validationStore, validationDispatch, validationTrigger, errors, resetValidation, revalidate, showErrorsPopper } = useValidator<string>(constraints, props.value, props.externalErrors);

  useEffect(() => {
    props.onUIDInit?.(uniqueId.current)
  }, [uniqueId])

  useEffect(() => {
    revalidate(props.value ?? "");
  }, [props.value])

  return (
    <div ref={elRef} className={props.containerClasses}>
      {props.label && <p className={`mb-2 font-semibold${props.brightLabel ? " text-inputs-label-bright" : " text-inputs-label-dim"}`}>
        {props.label}
      </p>}
      <div className="relative">
        {
          props.icon?.left && <div className={`absolute top-50 translate-middle-y`} style={{ left: props.icon.left.offset }}>
            {props.icon.left.el}
          </div>
        }

        <input
          value={props.value}
          placeholder={props.placeholder}
          type={props.type}
          onFocus={(event) => {
            props.onFocus?.(event.target.value, uniqueId.current);

            setIsFocused(true)
          }}
          onChange={(event) => props.onChange?.(event.target.value, uniqueId.current)}

          onBlur={(e) => {
            props.onBlur?.(e);
            setIsFocused(false)
          }}

          className={`w-full rounded-[8px] p-[${padding}] max-h-[${height}] 
          ${props.disabled ? "bg-inputs-disabled" : "bg-inputs-default"} 
          border-[1px] border-solid focus:border-inputs-border-focused 
          transition-[.25s] placeholder:text-inputs-placeholder 
          ${errors ? "!border-inputs-border-error text-inputs-textError" : `${props.disabled ? "border-transparent" : "border-inputs-border-default"} text-inputs-text`}
          ${props.inputClasses?.({ hasErrors: !!errors, isDisabled: !!props.disabled }) ?? ""}
          `}

          style={{ paddingRight: props.icon ? "40px" : padding }}

          disabled={props.disabled}
        />

        {
          props.icon?.right && <div className={`absolute top-50 translate-middle-y`} style={{ right: props.icon.right.offset }}>
            {props.icon.right.el}
          </div>
        }


        {
          showPopper &&

          <BasePopper anchorEl={elRef.current} open={showErrorsPopper && !!isFocused && !!errors} placement="bottom">
            <div className="bg-[#fff] p-[14px] rounded-[8px] levitation text-inputs-textError font-thin text-sm space-y-[4px]" style={{ width: elRef.current?.offsetWidth }}>
              {
                errors?.map(error => (
                  <div key={error.id} className="flex items-center"><CrossSVG style={{ transform: "scale(.7)", marginRight: "4px", marginTop: "-1px" }} /> <span>{error.text}</span></div>
                ))
              }
            </div>
          </BasePopper>
        }

      </div>

    </div>
  )
}