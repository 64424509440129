import React from "react";
import { useTranslation } from "react-i18next";
import BaseInput from "@reusables/BaseInput";
import BaseButton from "@reusables/BaseButton";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Adjustments } from "@/types/general";
import TypeSelector
    from "@components/Dashboard/pages/Inventory/Adjustments/components/EditingLayout/parts/TypeSelector";
import {
    adjustmentFormScheme,
    AdjustmentFormTyping
} from "@components/Dashboard/pages/Inventory/Adjustments/components/EditingLayout/types";
import Fields from "@components/Dashboard/pages/Inventory/Adjustments/components/EditingLayout/parts/Fields";
import dayjs from "dayjs";

type EditingLayoutProperties = {
    adjustment: Adjustments.Extended | undefined,
    buttonText: string,
    onSubmit: (data: AdjustmentFormTyping) => void
}


export default function EditingLayout({ adjustment, ...props }: EditingLayoutProperties) {
    const { t } = useTranslation("", { keyPrefix: "inventory.adjustments.createViewEditAdjustments" });

    // === RHF === //
    const form = useForm<AdjustmentFormTyping>({
        resolver: zodResolver(adjustmentFormScheme),
        defaultValues: {
            adjustment_type: 0,
            date: dayjs()
        }
    });

    const { control, handleSubmit, reset, setValue, resetField } = form;

    const onSubmit = handleSubmit(props.onSubmit, console.error);


    return (
        <FormProvider {...form}>
            <form className="space-y-[40px]" onSubmit={onSubmit}>
                <div className="mb-[40px]">
                    <TypeSelector />
                </div>

                <Fields />

                <Controller
                    control={control}
                    name="remarks"
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("fieldsGeneral.remarks.label") + " *"}
                            multiline
                            rows={4}
                        />
                    )}
                />

                <div className="flex justify-center">
                    <BaseButton
                        text={props.buttonText}
                        size="md"
                    />
                </div>
            </form>
        </FormProvider>
    );
}
