import React from "react";
import { useTranslation } from "react-i18next";
import BaseButton from "@reusables/BaseButton";
import BaseModal from "@reusables/Modals/BaseModal";
import { isErrorWithMessage } from "@redux/api/query";
import { Product } from "@/types/general";
import { toast } from "react-toastify";
import { useDeleteProductMutation } from "@redux/features/products/productsApi";

interface DeletionModalProperties {
    isOpen: boolean;
    entity?: Product.Root;
    onClose: () => void;
    onDeletion?: () => void;
}

export default function DeletionModal(props: DeletionModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "products.modals.deletion" });

    const [deleteProduct, { isLoading: isActiveDeletionLoading }] = useDeleteProductMutation();

    return (
        <BaseModal isOpen={props.isOpen} onClose={props.onClose} width={700} padding="56px" isLoading={isActiveDeletionLoading}>
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">
                    {t("subheading")} <span className="bold-highlight">{props.entity?.name}?</span>
                </div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose} />
                    <BaseButton
                        text={t("buttons.delete")}
                        size="md"
                        onClick={() => {
                            if (props.entity) {
                                deleteProduct(props.entity.id)
                                    .then(() => {
                                        toast.success(t("responses.success"));
                                        props.onDeletion?.();
                                    })
                                    .catch((e) => {
                                        if (isErrorWithMessage(e)) {
                                            toast.error(e.message);
                                        } else {
                                            toast.error(t("responses.error"));
                                        }
                                    })
                                    .finally(() => {
                                        props.onClose();
                                    });
                            }
                        }}
                    />
                </div>
            </div>
        </BaseModal>
    );
}
