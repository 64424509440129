import React, {useState} from "react";
import BaseTable from "@reusables/BaseTable";
import {selectCurrencyById, useGetCurrenciesQuery} from "@redux/features/currencies/currenciesApi";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "@redux/hooks";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import {ReactComponent as DeleteSVG} from "@assets/icons/ic_trash.svg";
import {Stack} from "@mui/material";
import BaseBadge from "@reusables/BaseBadge";
import RateCell from "./components/RateCell";
import MutationModal from "./modals/MutationModal";
import DeletionModal from "./modals/DeletionModal";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

function CurrenciesPage() {
    const {t} = useTranslation("", {keyPrefix: "settings.general.currencies"});

    const {data: currencies = [], isLoading: isLoadingCurrencies} = useGetCurrenciesQuery();

    const [isMutationModalOpen, setIsMutationModalOpen] = useState(false);
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

    // Ids and corresponding selected cache entries allow modals to work with data
    const [selectedCurrencyId, setSelectedCurrencyId] = useState<number>();
    const [deletableCurrencyId, setDeletableCurrencyId] = useState<number>();

    const deletableCurrency = useAppSelector((state) => selectCurrencyById(state, deletableCurrencyId));

    return (
        <>
            <Can not I="currencies.view" a="general">
                <NoPermissionBanner />
            </Can>

            <Can I="currencies.view" a="general">
                <div className="levitation-extended">
                    <div className="flex flex-row justify-between items-center mb-[44px]">
                        <div className="font-bold text-2xl text-accent">{t("heading")}</div>
                        <Can I="currencies.edit" a="general">
                            <BaseMaterialButton fontWeight={500} onClick={() => {
                                setSelectedCurrencyId(undefined);
                                setIsMutationModalOpen(true);
                            }}>
                                {t("table.buttons.add")}
                            </BaseMaterialButton>
                        </Can>
                    </div>
                    <BaseTable
                        data={currencies}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: (row) =>
                                    <Stack direction={"row"} spacing={2}>
                                        <div className="bold-highlight">{row.name}</div>
                                        {
                                            row.isBase &&
                                            <BaseBadge>{t("table.baseCurrency")}</BaseBadge>
                                        }
                                    </Stack>,
                            },
                            {
                                header: t("table.columns.1"),
                                getter: (row) => <div className="bold-highlight">{row.symbol}</div>
                            },
                            {
                                header: t("table.columns.2"),
                                getter: (row, index) =>
                                    (
                                        <>
                                            {
                                                (row.isBase ?
                                                        row.rate
                                                        :
                                                        <RateCell
                                                            currencyId={row.id}
                                                            rate={row.rate}
                                                            isFixed={row.isFixed}
                                                        />
                                                )
                                            }
                                        </>
                                    )
                            },
                            {
                                header: () => "",
                                getter: (row) => (
                                    <div className="text-tables-secondaryIcon">
                                        {
                                            !row.isBase &&
                                            <Can I="currencies.edit" a="general">
                                                <DeleteSVG
                                                    className="cursor-pointer hover:text-tables-highlightedIcon"
                                                    onClick={() => {
                                                        setDeletableCurrencyId(row.id);
                                                        setIsDeletionModalOpen(true);
                                                    }}
                                                />
                                            </Can>
                                        }
                                    </div>
                                ),
                                sx: {
                                    textAlign: "right"
                                }
                            }
                        ]}
                        isDataLoading={isLoadingCurrencies}
                        headerSx={{backgroundColor: "#F9F9F9"}}
                        sx={{
                            borderCollapse: "separate",
                            borderSpacing: "0 15px"
                        }}
                        size={"small"}
                    />
                </div>
            </Can>

            <MutationModal
                isOpen={isMutationModalOpen}
                onClose={() => setIsMutationModalOpen(false)}
            />

            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                currency={deletableCurrency}
            />
        </>
    );
}

export default CurrenciesPage;