import React, { useState } from "react";
import { ProduceFormTyping } from "@components/Dashboard/pages/BOM/components/modals/ProduceModal/types";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import { ArrowDown10, MapPin } from "lucide-react";
import BaseTooltip from "@reusables/BaseTooltip";
import AutofillLocationModal from "./AutofillLocationModal";

export default function ShortcutsSection() {
    const { t } = useTranslation("", { keyPrefix: "bom.produceModal.shortcuts" });

    const form = useFormContext<ProduceFormTyping>();

    const quantity = useWatch({
        control: form.control,
        name: "quantity"
    });

    const handleSetQuantity = () => {
        const updatedQuantities = form.getValues("components_picks").map((pick) => {
            // Algorithm with smart quantity distribution
            // 1. Calculate the needed quantity
            // 2. Order sources by quantity (greatest first) + prioritize sources with locations set
            // 3. Fill sources  with locations with the maximum available quantity and then fill the rest with left quantities

            let totalNeededQuantity = pick.component.product.quantity * quantity;

            // Order sources by quantity (lowest first) and giving priority to sources with locations set
            const orderedSources = pick.sources
                .sort((a, b) =>
                    (b.locations?.in_stock ?? 0) - (a.locations?.in_stock ?? 0)
                    +
                    (b.locations ? 1 : 0) - (b.locations ? 1 : 0)
                );

            // Preparing an array, which will contain sources with empty locations and we will fill this one with left quantities
            const sourcesWithEmptyLocations: typeof orderedSources = [];

            // Calculating the quantity for sources with locations
            const sourcesWithCalculatedDistribution =
                orderedSources
                    // Removing sources with no locations
                    .filter((source) => {
                        const isLocationPresent = !!source.locations;

                        if (!isLocationPresent) {
                            sourcesWithEmptyLocations.push(source);
                        }

                        return isLocationPresent;
                    })
                    // Reducing the quantity of sources with locations with each iteration
                    .map((source) => {
                        const quantityPerSource = Math.min(source.locations!.in_stock ?? 0, totalNeededQuantity);

                        totalNeededQuantity -= quantityPerSource;

                        return {
                            ...source,
                            quantity: quantityPerSource > 0 ? quantityPerSource : (null as never as number)
                        };
                    });

            // Filling sources with empty locations with left quantities (last location will also be filled with left quantity)
            const leftQuantityPerSourceForEmptyLocations = Math.floor(totalNeededQuantity / sourcesWithEmptyLocations.length);
            const lastIncrement = totalNeededQuantity - (leftQuantityPerSourceForEmptyLocations * sourcesWithEmptyLocations.length);
            sourcesWithEmptyLocations.forEach(((source, index) => {
                source.quantity = leftQuantityPerSourceForEmptyLocations + (index === sourcesWithEmptyLocations.length - 1 ? lastIncrement : 0);

                // Reducing total needed quantity to keep it up-to-date
                totalNeededQuantity -= source.quantity;
            }));

            // Returning final result
            return {
                ...pick,
                sources: [
                    ...sourcesWithCalculatedDistribution,
                    ...sourcesWithEmptyLocations
                ]
            };
        });

        form.setValue("components_picks", updatedQuantities);
    };

    const [isLocationsModalOpen, setIsLocationsModalOpen] = useState(false);

    return (
        <>
            <div>
                <div className={"mb-2"}>{t("heading")}</div>
                <div className="flex items-center space-x-2">
                    <BaseMaterialButton type={"button"} onClick={() => setIsLocationsModalOpen(true)}>
                        <MapPin size={14} className={"mr-2"} />
                        <span>{t("buttons.setLocations.value")}</span>
                    </BaseMaterialButton>

                    <BaseTooltip title={"" + t("buttons.setQuantity.disabledTooltip")} active={!quantity}
                                 placement="top">
                        <BaseMaterialButton
                            type={"button"}
                            disabled={!quantity}
                            onClick={handleSetQuantity}
                        >
                            <ArrowDown10 size={14} className={"mr-2"} />
                            <span>{t("buttons.setQuantity.value")}</span>
                        </BaseMaterialButton>
                    </BaseTooltip>
                </div>
            </div>

            <AutofillLocationModal
                isOpen={isLocationsModalOpen}
                onClose={() => setIsLocationsModalOpen(false)}
                onSubmit={(data) => {
                    const updatedLocations = form.getValues("components_picks").map((pick) => {
                        // Algorithm for the best UX (I hope):
                        // 1. Determine whether such location can be set for the component (compare with component's locations)
                        // 2. If yes, check if this location is already selected in the sources
                        // 3. If no, add it to the sources

                        const presentLocation = pick.component.locations.find((location) => compareLocations(location, data.location));

                        if (!presentLocation) {
                            return pick;
                        }

                        const locationIndex = pick.sources.findIndex((source) => !!source.locations && compareLocations(source.locations, presentLocation));

                        if (locationIndex === -1) {
                            // Sometimes there might be sources without locations, so we need to check if there is an empty location to insert to not allow empty location rows
                            const indexOfEmptyLocationToInsert = pick.sources.findIndex((source) => !source.locations);

                            if (!indexOfEmptyLocationToInsert) {
                                pick.sources[indexOfEmptyLocationToInsert].locations = presentLocation;

                                return {
                                    ...pick,
                                    sources: pick.sources
                                };
                            } else {
                                return {
                                    ...pick,
                                    sources: [
                                        ...pick.sources,
                                        {
                                            product_id: pick.component.product.id,
                                            locations: presentLocation,
                                            quantity: null as never as number
                                        }
                                    ]
                                };
                            }

                        } else {
                            return pick;
                        }
                    });


                    form.resetField("components_picks");
                    form.setValue("components_picks", updatedLocations);

                    setIsLocationsModalOpen(false);
                }}
            />
        </>
    );
}

type ComparableLocation = {
    store: { id: number },
    section?: { id: number } | null
}

function compareLocations(a: ComparableLocation, b: ComparableLocation) {
    return a.store.id === b.store.id && a.section?.id === b.section?.id;
}