import React from "react";
import BaseButton from "@reusables/BaseButton";
import { toast } from "react-toastify";
import BaseModal from "@reusables/Modals/BaseModal";
import { useTranslation } from "react-i18next";
import { useDeleteTransferMutation } from "@redux/features/transfers/transfersApi";

interface DeletionModalProps {
    isOpen: boolean;
    onClose: () => void;
    transferId?: number;
    onDeletion?: () => void;
}

export default function DeletionModal(props: DeletionModalProps) {
    const { t } = useTranslation("", { keyPrefix: "inventory.stockTransfers.modals.confirmDelete" });

    const [deleteTransfer, { isLoading }] = useDeleteTransferMutation();

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
            isLoading={isLoading}
        >
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center">{t("subheading")} <span
                    className="bold-highlight">{t("deletionItem")} FGH{props.transferId}?</span></div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose} />
                    <BaseButton text={t("buttons.delete")} size="md" onClick={() => {
                        if (props.transferId) {
                            deleteTransfer(props.transferId).unwrap().then(() => {
                                toast.success(t("responses.success"));

                                props.onDeletion?.();
                            }).catch(e => {
                                console.error(e);

                                toast.error(t("responses.error"));
                            });
                        }
                    }} />
                </div>
            </div>
        </BaseModal>
    );
}