import { vatNumberPatterns } from "../../../config/vats";

export type Registrations = {
  [P: string]: Constraint[]
}

export type ConstraintSupport<T> = {
  constraints?: Constraint<T>[],
  externalErrors?: Constraint<T>[],
  showPopper?: boolean;
}

export class Constraint<T = string>{
  private readonly _id: string;
  private readonly _text?: string;
  private _hideMessage = false;

  readonly validate: (val: T) => boolean;

  constructor(id: string, validate: (val: T) => boolean, text?: string) {
    this._id = id;
    this.validate = validate;
    this._text = text;
  }

  isSameAs(con: Constraint): boolean{
    return con._id === this._id;
  }

  get id(): string {
    return this._id;
  }

  get text(): string | undefined {
    return this._text;
  }

  get hideMessage(): boolean {
    return this._hideMessage;
  }

  setHideMessage(state = true){
    this._hideMessage = state;
    return this;
  }
}

export class NotEmpty<T> extends Constraint<any> {
  constructor(text?: string) {
    super("required", (val) => {
      if(typeof val === "string"){
        return !!val;
      }else if(Array.isArray(val)){
        return val.length > 0;
      }else if(typeof val === "number"){
        return !isNaN(val)
      }

      return val != undefined || val != null;

    }, text ?? "Field should not be empty!");
  }
}

export class PositiveNumber extends Constraint<string | number | undefined>{
  constructor(text?: string) {
    super("pos_number", (val) => {
      if(typeof val === "number") return val >= 0;

      return val ? parseFloat(val) >= 0 : true;
    }, text ?? "Number should be positive!");
  }
}

export class BoundedBetween extends Constraint{
  constructor(bottom: number, top: number, text?: string) {
    super("bound", (val) => {
      if(!val)
        return true;

      return parseFloat(val) >= bottom && parseFloat(val) <= top
    }, text ?? `Number should be between ${bottom} and ${top}!`);
  }
}

export class GreaterThan extends Constraint {
  constructor(limit: number, text?: string) {
    super("greater_than", (val) => {
      return parseFloat(val) >= limit
    }, text ?? `Number should be greater than ${limit}!`);
  }
}

export class Url extends Constraint {
  constructor(required?: boolean, text?: string) {
    super("url", (val) => {
      if(required || !!val) {
        const urlRegex = /^[^ "]+(\.[^ "]+)+$/;
        return urlRegex.test(val);
      }

      return true;
    }, text ?? "Field should be a valid URL!");
  }
}

export class Email extends Constraint {
  constructor(required?: boolean, text?: string) {
    super("email", (val) => {
      if(required || !!val){
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;
        return emailRegex.test(val);
      }

      return true;
    }, text ?? "Field should be a valid email address!");
  }
}

export class Street extends Constraint {
  constructor(required?: boolean, text?: string) {
    super("street", (val) => {
      if (required || !!val) {
        return /^[\p{L}\p{N}\s\-\.]+$/u.test(val.trim());
      }
      return true;
    }, text ?? "Street should only contain alphanumeric characters and spaces.");
  }
}

export class Alphabetical extends Constraint {
  constructor(required?: boolean, text?: string) {
    super("city", (val) => {
      if (required || !!val) {
        return /^[\p{L} ]+$/u.test(val.trim());
      }
      return true;
    }, text ?? "Field should only contain alphabetical characters and spaces.");
  }
}


export class VatNumber extends Constraint {
  constructor(text?: string) {
    super("vatNumber", (val: string) => {
      if(!val)
        return true;

      const countryCode = val.slice(0, 2).toUpperCase();
      const pattern = vatNumberPatterns[countryCode];
      return pattern ? pattern.test(val) : false;
    }, text ?? "Invalid VAT number! Please check the format for your country.");
  }
}
