import React, { useEffect, Fragment } from "react";
import { RouteProps, useHistory, withRouter } from "react-router-dom";

// type Props = {}
// {}: Props

function ScrollToTop({ children }: RouteProps) {
    const history = useHistory()
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0)
        });
        return () => {
            unlisten()
        };
    }, [])

    return <Fragment>{children}</Fragment>
}

export default ScrollToTop;
