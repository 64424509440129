import {
    ExportRequest,
    flattenExportRequest,
    flattenPaginationRequest,
    internalApiSlice,
    PaginationRequest,
    PaginationResponse,
    transformResponse
} from "@redux/api/internalApiSlice";
import { SaleOrder } from "@/types/general";

const SOExtendedDateFields = ["preferred_delivery_date", "order_date", "delivery_date", "date", "shipment_change_date"];

export const salesOrdersApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSaleOrdersFull: builder.query<PaginationResponse<SaleOrder.Root>, PaginationRequest<SaleOrder.DTO.Filters, SaleOrder.DTO.OrderBy>>({
            query: (req) => ({
                url: "sale",
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: SOExtendedDateFields
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({ id }) => ({ type: "SaleOrder" as const, id })),
                        { type: "SaleOrder", id: "PARTIAL-LIST" }
                    ]
                    : [{ type: "SaleOrder", id: "PARTIAL-LIST" }]
        }),

        getModuleSaleOrdersFull: builder.query<PaginationResponse<SaleOrder.Root>, PaginationRequest<SaleOrder.DTO.Filters, SaleOrder.DTO.OrderBy> & {module: string}>({
            query: ({ module, ...req }) => ({
                url: `${module}/sale`,
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: SOExtendedDateFields
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({ id }) => ({ type: "SaleOrder" as const, id })),
                        { type: "SaleOrder", id: "PARTIAL-LIST" }
                    ]
                    : [{ type: "SaleOrder", id: "PARTIAL-LIST" }]
        }),

        createSaleOrder: builder.mutation<SaleOrder.Extended, SaleOrder.DTO.Create>({
            query: (body) => ({
                url: `sale`,
                method: "POST",
                data: body,
                parseDates: SOExtendedDateFields
            }),
            transformResponse,
            invalidatesTags: ["SaleOrder", "Picking", "Product"]
        }),
        updateSaleOrder: builder.mutation<SaleOrder.Extended, SaleOrder.DTO.Update & { id: number }>({
            query: ({ id, ...body }) => ({
                url: `sale/${id}`,
                method: "PUT",
                data: body,
                parseDates: SOExtendedDateFields
            }),
            transformResponse,
            invalidatesTags: (result, error, args) => result ? [{ type: "SaleOrder", id: args.id }, "Picking"] : []
        }),
        getSaleOrder: builder.query<SaleOrder.Extended, number>({
            query: (id) => ({
                url: `sale/${id}`,
                parseDates: SOExtendedDateFields
            }),
            transformResponse,
            providesTags: result => result ? [{ type: "SaleOrder", id: result.id }] : []
        }),

        getModuleSaleOrder: builder.query<SaleOrder.Extended, { id: number, module: string }>({
            query: ({ id, module }) => ({
                url: `${module}/sale/${id}`,
                parseDates: SOExtendedDateFields
            }),
            transformResponse,
            providesTags: result => result ? [{ type: "SaleOrder", id: result.id }] : []
        }),

        deleteSaleOrder: builder.mutation<void, number>({
            query: (id) => ({
                url: `sale/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, arg_id) => [{ type: "SaleOrder", id: arg_id }, "Picking"]
        }),

        exportSaleOrders: builder.mutation<void, ExportRequest<undefined, SaleOrder.DTO.Filters>>({
            query: (args) => ({
                url: `sale/export`,
                responseType: "blob",
                method: "POST",
                data: flattenExportRequest(args)
            })
        })
    })
});

export const {
    useGetSaleOrdersFullQuery,
    useGetModuleSaleOrdersFullQuery,
    useCreateSaleOrderMutation,
    useUpdateSaleOrderMutation,
    useGetSaleOrderQuery,
    useGetModuleSaleOrderQuery,
    useDeleteSaleOrderMutation,
    useExportSaleOrdersMutation
} = salesOrdersApi;