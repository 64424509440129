import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ImportData {
    payload: {
        headers: string[];
        hash: string;
        rows: number;
    }
}

interface ImportState {
    importData: ImportData | null;
    importFile: File | null;
}

const initialState: ImportState = {
    importData: null,
    importFile: null
};

export const importInfoSlice = createSlice({
    name: "importInfo",
    initialState: initialState,
    reducers: {
        setImportData: (state, action: PayloadAction<ImportData | null>) => {
            state.importData = action.payload;
        },

        setImportFile: (state, action: PayloadAction<File | null>) => {
            state.importFile = action.payload;
        },

        resetImportData: (state) => {
            state.importData = null;
            state.importFile = null;
        }
    }
});

export const { setImportData, setImportFile, resetImportData } = importInfoSlice.actions;

export default importInfoSlice.reducer;