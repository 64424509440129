import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import BaseButton from "@reusables/BaseButton";
import BaseModal from "@reusables/Modals/BaseModal";
import { useDeleteRoleMutation, useGetRolesQuery } from "@redux/features/roles/rolesApi";
import { toast } from "react-toastify";
import BaseBadge from "@reusables/BaseBadge";
import { toastError } from "@helpers/utils";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { refinements } from "@helpers/refinements";

interface DeleteModalProperties {
    isOpen: boolean;
    role_id?: number;
    onClose: () => void;
}

const deletionScheme = z.object({
    affecting_users: z.number(),
    replacing_role_id: z.number().nullish()
}).refine((scheme) => {
    if (scheme.affecting_users > 0) {
        return refinements.required.refine(scheme.replacing_role_id);
    } else {
        return true;
    }
}, {
    ...refinements.required.message,
    path: ["replacing_role_id"]
});

type DeletionFormTyping = z.infer<typeof deletionScheme>;

export default function DeletionModal(props: DeleteModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.roles.modals.deletion" });

    const methods = useForm<DeletionFormTyping>({
        resolver: zodResolver(deletionScheme),
        defaultValues: {
            affecting_users: 0
        }
    });

    // Getting all the roles, which are not the one, which is planned for deletion
    const { roles, isLoading } = useGetRolesQuery(undefined, {
        selectFromResult: result => ({
            roles: result.data?.filter(x => x.id !== props.role_id),
            isLoading: result.isLoading
        })
    });

    // Selecting role by id
    const { role } = useGetRolesQuery(undefined, {
        selectFromResult: result => ({
            role: result.data?.find(r => r.id === (props.role_id ?? -1))
        })
    });

    const [deleteRole, { isLoading: isDeletionLoading }] = useDeleteRoleMutation();

    useEffect(() => {
        methods.reset({
            affecting_users: role?.users.length ?? 0
        });
    }, [role]);

    if (!role) {
        return null;
    }

    const onSubmit = methods.handleSubmit(data => {
        deleteRole({
            id: role.id,
            new_role_id: data.replacing_role_id
        }).unwrap().then(resp => {
            toast.success(t("responses.success"));
        }).catch(e => {
            console.error(e);
            toastError(e);
        }).finally(() => {
            props.onClose();
            methods.reset();
        });
    }, console.error);

    const isUsersConnected = role.users.length > 0;

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
            isLoading={isDeletionLoading}
        >
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">
                    <Trans
                        t={t}
                        i18nKey="subheading"
                        values={{
                            roleName: role.name,
                            usersCount: role.users.length
                        }}
                        count={role.users.length}
                        components={{
                            badge: <BaseBadge className={"px-2 bg-[#D9DAFD]"} />
                        }}
                    />
                    {
                        isUsersConnected &&
                        <>
                            <br />
                            <Trans t={t} i18nKey="subheading_prompt" />
                        </>
                    }
                </div>
                {
                    isUsersConnected &&
                    <div>
                        <Controller
                            name={"replacing_role_id"}
                            control={methods.control}
                            render={({ field, fieldState }) => (
                                <BaseDropdown
                                    {...field}
                                    {...fieldState}

                                    onChange={(val) => {
                                        field.onChange(val?.id);
                                    }}

                                    options={roles}

                                    label={t("fields.role_replacement.label")}
                                    placeholder={t("fields.role_replacement.placeholder")}

                                    getter={{
                                        key: opt => opt.id,
                                        label: opt => opt.name
                                    }}
                                    autocomplete
                                    isLoading={isLoading}
                                />
                            )}
                        />
                    </div>
                }
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" type={"button"} primaryOutlined
                                onClick={props.onClose} />
                    <BaseButton text={t("buttons.delete")} size="md" type={"submit"} />
                </div>
            </form>
        </BaseModal>
    );
}
