import React from 'react'
import {Redirect, Route, RouteProps} from 'react-router-dom'
import {useAppSelector} from "@redux/hooks";

const PrivateRoute = ( { component: Component, ...rest }:RouteProps ) => {

    const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);

    if(!isAuthenticated) {
        return <Redirect to="/login" />
    }

    return <Route component={Component} {...rest} />
}

export default PrivateRoute
