import React from "react";
import EditingLayout from "../components/EditingLayout";
import { useTranslation } from "react-i18next";

export default function LocationsCreationPage(){
  const {t} = useTranslation("", {keyPrefix: "settings.general.locations.createLocation"});

  return (
    <div className="levitation-extended">
      <div className="flex mb-[40px]">
        <h6 className="text-accent grow">{t("heading")}</h6>
      </div>
      <EditingLayout location={undefined} buttonText={t("button")}/>
    </div>
  )
}