// useMaxProducts.js
import { useMemo } from "react";
import { BOM } from "@/types/general";

// Calculate the maximum number of products that can be produced
export const useMaxProducts = (components: BOM.Components.Component[]) => {
    return useMemo(() => {
        const minProductsPerComponent = components?.map(component => {
            const totalInStock = component.product.in_stock;
            return Math.floor(totalInStock / component.quantity);
        });

        return minProductsPerComponent ? Math.min(...minProductsPerComponent) : 0;
    }, [components]);
};
