import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";
import React from "react";
import { Search } from "lucide-react";

export default function FilterInheritanceWarning() {
    const { t } = useTranslation("", { keyPrefix: "general.modals.export" });

    return (
        <>
            <Alert severity="info" icon={<Search size={20} />}>
                <span>{t("filterInheritanceWarning")}</span>
            </Alert>
        </>
    );
}