import React, { useState } from "react";
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import BaseTable from "@reusables/BaseTable";
import { useHistory } from "react-router-dom";
import {
    ArrayElementType,
    genT,
    PredefinedTranslations,
    removeEmpty,
    useDatesRangeFilter,
    useOrdering,
    usePagination
} from "@helpers/utils";
import { useTranslation } from "react-i18next";

import { ReactComponent as RemarkSVG } from "@assets/icons/ic_remark.svg";
import BaseTooltip from "@reusables/BaseTooltip";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseDropdown from "@reusables/dropdowns/BaseDropdownLegacy";
import BaseDatepicker from "@reusables/BaseDatepickerLegacy";
import { useGetLocationsQuery, useGetModuleLocationsQuery } from "@redux/features/locations/locationsApi";
import { useGetModuleProductsQuery, useGetProductsQuery } from "@redux/features/products/productsApi";
import { useGetModuleUsersQuery, useGetUsersQuery } from "@redux/api/internalApiSlice";
import _ from "lodash";
import { Adjustments } from "@/types/general";
import { useGetAdjustmentsFullQuery } from "@redux/features/adjustments/adjustmentsApi";
import { Pagination } from "@mui/material";
import { DatepickerRangeContext } from "@reusables/BaseDatepickerLegacy/context";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function AdjustmentsMainPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "inventory.adjustments" });

    // =========== DROPDOWNS AND DATEPICKER =========== //
    // ---> Product selection <--- //
    const { data: productOptions = [], isLoading: isProductOptionsLoading } = useGetModuleProductsQuery("adjustments");
    const [selectedProducts, setSelectedProducts] = useState<typeof productOptions>([]);

    // ---> Adjustment selection <--- //
    const adjustmentOptions: { name: string, type: 0 | 1 }[] = [
        {
            name: t("table.adjustmentTypes.quantity"),
            type: 0
        },
        {
            name: t("table.adjustmentTypes.costPrice"),
            type: 1
        }
    ];
    const [selectedAdjustment, setSelectedAdjustment] = useState<ArrayElementType<typeof adjustmentOptions>>();

    // ---> Location selection <--- //
    const { data: locationOptions = [], isLoading: isLocationOptionsLoading } = useGetModuleLocationsQuery("adjustments");
    const [selectedLocations, setSelectedLocations] = useState<typeof locationOptions>([]);

    // ---> Users selection <--- //
    const { data: userOptions = [], isLoading: isUserOptionsLoading } = useGetModuleUsersQuery("adjustments");
    const [selectedUsers, setSelectedUsers] = useState<typeof userOptions>([]);

    // ---> Dates selection <--- //
    const { datesRange, setDatesRange, filterAdaptedDatesRange } = useDatesRangeFilter();

    // =========== FILTERS =========== //
    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = _.debounce(setSearchingValue, 1000);

    // Table ordering
    const { orderBy, setOrderBy } = useOrdering<Adjustments.DTO.OrderBy>({ name: "id", type: "desc" });

    const pagination = usePagination({ page: 1, limit: 8 });

    const filters = removeEmpty({
        search: searchingValue,
        products: selectedProducts.map(x => x.id),
        adjustment: selectedAdjustment?.type,
        location: selectedLocations.map(x => ({
            store: x.store.id,
            ...(x.section && { section: x.section.id })
        })),
        users: selectedUsers.map(x => x.id),
        dates_range: filterAdaptedDatesRange
    });

    const { data: adjustmentsResponse, isFetching: isAdjustmentsLoading } = useGetAdjustmentsFullQuery({
        filters,
        orderBy,
        pagination: {
            page: pagination.page,
            limit: pagination.limit
        }
    });

    return (
        <>
            {/* FILTERS BLOCK */}
            <BaseAdminFilter
                permissionModule="adjustment"
                subPermission={["with_purchase_price.create", "with_quantity.create"]}
                filterItems={
                    <div className="w-[702px]">
                        <BaseInputsGrid cols={2} gap={24}>
                            <BaseDropdown
                                label={t("modals.filter.fields.product.label")}
                                options={productOptions}
                                value={selectedProducts}
                                getter={{
                                    label: opt => opt.name,
                                    key: opt => opt.id,
                                    renderOption: (opt, icon) => (
                                        <div>
                                            <span className="grow">{opt.name}</span>
                                            {
                                                selectedProducts.includes(opt) ? icon :
                                                    <span className="text-lightGreen-500 font-thin">{opt.code}</span>
                                            }
                                        </div>
                                    )
                                }}

                                onChange={newState => setSelectedProducts(newState)}

                                autocomplete
                                multiple

                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                isLoading={isProductOptionsLoading}

                                brightLabel
                            />

                            <BaseDropdown
                                label={t("modals.filter.fields.adjustment.label")}
                                options={adjustmentOptions}
                                value={selectedAdjustment}
                                getter={{
                                    label: opt => opt.name,
                                    key: opt => opt.type
                                }}

                                onChange={(_, opt) => setSelectedAdjustment(opt)}

                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                brightLabel
                            />

                            <BaseDropdown
                                label={t("modals.filter.fields.location.label")}
                                options={locationOptions}
                                value={selectedLocations}
                                getter={{
                                    label: opt => `${opt.store.name}${opt.section ? ` - ${opt.section.name}` : ""}`,
                                    key: opt => opt.section ? `${opt.store.id}_${opt.section.id}` : `${opt.store.id}`,
                                    renderOption: (opt, icon) => (
                                        <div>
                                            <div className="grow">
                                                <span
                                                    className="bold-highlight">{opt.store.name}</span>{opt.section ? <> - {opt.section.name}</> : null}
                                            </div>
                                            {
                                                selectedLocations.includes(opt) ? icon : null
                                            }
                                        </div>
                                    )
                                }}

                                onChange={newState => setSelectedLocations(newState)}

                                autocomplete
                                multiple

                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                isLoading={isProductOptionsLoading}

                                brightLabel
                            />

                            <BaseDropdown
                                label={t("modals.filter.fields.user.label")}
                                options={userOptions}
                                value={selectedUsers}
                                getter={{
                                    label: opt => opt.first_name.charAt(0).toUpperCase().concat(opt.first_name.slice(1, opt.first_name.length)),
                                    key: opt => opt.id
                                }}

                                onChange={newState => setSelectedUsers(newState)}

                                autocomplete
                                multiple

                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                isLoading={isUserOptionsLoading}

                                brightLabel
                            />

                            <DatepickerRangeContext.Provider value={{
                                "dates_range": { range: datesRange, setRange: setDatesRange }
                            }}>
                                <BaseDatepicker
                                    label={t("modals.filter.fields.date.label")}
                                    placeholder={t("modals.filter.fields.date.placeholder")}
                                    rangeConfig={{ groupKey: "dates_range", role: "solo" }}
                                    brightLabel
                                />
                            </DatepickerRangeContext.Provider>
                        </BaseInputsGrid>
                    </div>
                }

                handleSearch={searchInputDebounce}

                handleFilter={(e) => console.log(e)}
                handleCreate={() => history.push("/dashboard/inventory/adjustments/new")}
            />

            <div className="levitation-extended mt-[32px]">
                <Can not I="view" a="adjustment">
                    <NoPermissionBanner />
                </Can>

                <Can I="view" a="adjustment">
                    <BaseTable
                        data={adjustmentsResponse?.payload ?? []}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: row =>
                                    <>
                                        <Can I="view" a="adjustment">
                                            <div
                                                onClick={() => history.push(`/dashboard/inventory/adjustments/${row.id}/details`)}
                                                className="cursor-pointer hover:opacity-60 transition-[.15s]">
                                                <div className="bold-highlight">{row.product.name}</div>
                                                <div className="text-sm text-accent">{row.code}</div>
                                            </div>
                                        </Can>

                                        <Can not I="view" a="adjustment">
                                            <div className="cursor-pointer hover:opacity-60 transition-[.15s]">
                                                <div className="bold-highlight">{row.product.name}</div>
                                                <div className="text-sm text-accent">{row.code}</div>
                                            </div>
                                        </Can>
                                    </>,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.1"),
                                getter: row => {
                                    if(row.location) {
                                        return <div className="capitalize">
                                            {row.location.store.name}{row.location.section ? ` - ${row.location.section.name}` : ""}
                                        </div>;
                                    } else {
                                        return "-"
                                    }
                                },
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.2"),
                                getter: row => <>
                                    {row.user.name.charAt(0).toUpperCase().concat(row.user.name.slice(1, row.user.name.length))}
                                </>,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.3"),
                                getter: row => <>
                                    {
                                        row.adjustment_type === 0 ? t("table.adjustmentTypes.quantity") : t("table.adjustmentTypes.costPrice")
                                    }
                                </>,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.4"),
                                getter: row => row.remarks ? <BaseTooltip title={row.remarks}><RemarkSVG
                                    className="text-tables-primaryIcon" /></BaseTooltip> : <></>
                            },
                            {
                                header: t("table.columns.5"),
                                getter: row => row.date.format("DD.MM.YYYY"),
                                comparator: () => 0
                            }
                            // {
                            //   header: t("table.columns.6"),
                            //   getter: row => <div className="space-x-[20px] text-tables-secondaryIcon">
                            //     <EditSVG className="hover:text-tables-highlightedIcon cursor-pointer" onClick={() => history.push(`/dashboard/inventory/adjustments/${row.id}/edit`)}/>
                            //     <DeleteSVG className="hover:text-tables-highlightedIcon cursor-pointer" onClick={() => setActiveAdjustmentDeletion(row)}/>
                            //   </div>
                            // }
                        ]}
                        alternate
                        isDataLoading={isAdjustmentsLoading}
                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering) {
                                    let name: Adjustments.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 0:
                                            name = "product";
                                            break;
                                        case 1:
                                            name = "location";
                                            break;
                                        case 2:
                                            name = "user";
                                            break;
                                        case 3:
                                            name = "type";
                                            break;
                                        case 5:
                                            name = "date";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                        setOrderBy({
                                            name,
                                            type: newOrdering.order
                                        });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}
                    />

                    <Pagination
                        className="mt-[32px]"
                        {...pagination.adapt(adjustmentsResponse)}
                    />
                </Can>
            </div>
        </>
    );
}