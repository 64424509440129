import React from "react";
import CreationLayout from "../components/CreationLayout";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {useCreateTransferMutation} from "@redux/features/transfers/transfersApi";
import {useHistory} from "react-router-dom";
import {isErrorWithMessage} from "@redux/api/query";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function TransfersCreationPage() {
    const history = useHistory();

    const {t} = useTranslation("", {keyPrefix: "inventory.stockTransfers.createTransfer"})

    const [createTransfer, isLoading] = useCreateTransferMutation();

    return (
        <>
            <Can not I="create" a="transfer">
                <div className="levitation-extended">
                    <NoPermissionBanner/>
                </div>
            </Can>

            <Can I="create" a="transfer">
                <div className="levitation-extended">
                    <div className="flex mb-[40px]">
                        <h6 className="text-accent grow">{t("heading")}</h6>
                    </div>
                    <CreationLayout
                        onSubmit={(data) => {
                            createTransfer({
                                product: data.product,
                                location_from: {
                                    store: data.from_location,
                                    section: data.from_section
                                },
                                location_to: {
                                    store: data.to_location,
                                    section: data.to_section
                                },
                                date: data.date,
                                quantity: data.quantity,
                                remarks: data.remarks
                            }).unwrap().then(() => {
                                history.push("/dashboard/inventory/transfers")

                                toast.success(t("responses.success"))
                            }).catch(e => {
                                if(isErrorWithMessage(e)){
                                    toast.error(e.message)
                                }else{
                                    toast.error(t("responses.error"))
                                }
                            })
                        }}
                    />
                </div>
            </Can>
        </>
    )
}