import React from "react";
import { useTranslation } from "react-i18next"
import { IntegrationTypes } from "../../types"


interface TypeButtonProps {
    buttonType: IntegrationTypes;
    selectedType: IntegrationTypes;
    onTypeChange: (type: IntegrationTypes) => void;
}

export default function TypeButton({ buttonType, selectedType, onTypeChange }: TypeButtonProps) {
    const { t } = useTranslation("", {keyPrefix: "settings.integrations.thirdParty.integrationTypes"})

    return (
        <span
            className={
                (
                    buttonType === selectedType
                    ? // Classes when the integration type is selected
                    "bg-lightGreen-400 text-accent"
                    :  // Classes when the integration type is not selected
                    "bg-gray-100"
                ) +
                " font-semibold rounded-full px-4 py-2 cursor-pointer transition ease-in-out duration-200" // Common classes
            }
            onClick={() => onTypeChange(buttonType)}
        >
            {t(buttonType)}
        </span>
    )
}
