import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import CustomerStepper from "../components/Stepper/CustomerStepper";
import {useHistory, useParams} from "react-router-dom";

import GeneralTabPreview from "../components/tabs/previews/GeneralTabPreview";
import DiscountTab from "../components/tabs/previews/DiscountTab";
import AddressTabPreview from "../components/tabs/previews/AddressTabPreview";
import SettingsTab from "../components/tabs/previews/SettingsTab";

import DeletionModal from "../components/modals/DeletionModal";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import {useGetCustomerQuery} from "@redux/features/customers/customersApi";
import {Can} from "@/casl.config";
import {BaseTinyActionIcon, TinyActionIconType} from "@reusables/BaseTinyActionIcon";
import SaleOrdersTab from "../components/tabs/previews/SaleOrdersTab";
import BaseTooltip from "@reusables/BaseTooltip";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function CustomerDetailsPage() {
    const {t} = useTranslation("", {keyPrefix: "customers.viewPage"});

    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const { data: customer, isLoading: customerLoading } = useGetCustomerQuery(parseInt(id));
    const [activeTab, setActiveTab] = useState<number>(0);

    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);

    return (
      <>
            <Can not I="view" a="customer">
                <div className="levitation-extended">
                    <NoPermissionBanner />
                </div>
            </Can>

            <Can I="view" a="customer">
                <BaseRecordRenderer
                    record={customer}
                    loading={customerLoading}
                >
                    <div className="flex items-center mb-[40px]">
                        <h6 className="text-accent grow">{customer?.name}</h6>
                        <div className="space-x-[8px] flex items-center h-full">
                                <BaseTooltip title={`${t("sideButtons.createSalesOrder")}`} placement="bottom-start">
                                    <Can I="create" a="sale_order">
                                        <BaseTinyActionIcon
                                            type={TinyActionIconType.SalesOrder}
                                            onClick={() => history.push("/dashboard/sales/orders/new", { customer: customer })}
                                        />
                                    </Can>
                                </BaseTooltip>

                                <BaseTooltip title={`${t("sideButtons.edit")}`} placement="bottom">
                                    <Can I="edit" a="customer">
                                        <BaseTinyActionIcon
                                            type={TinyActionIconType.Edit}
                                            onClick={() => history.push(`/dashboard/customers/${id}/edit`)}
                                        />
                                    </Can>
                                </BaseTooltip>

                                <BaseTooltip title={`${t("sideButtons.delete")}`} placement="bottom-end">
                                    <Can I="delete" a="customer">
                                        <BaseTinyActionIcon
                                            type={TinyActionIconType.Delete}
                                            onClick={() => setIsDeletionModalOpen(true)}
                                        />
                                    </Can>
                                </BaseTooltip>
                        </div>
                    </div>
                    <CustomerStepper
                        tabs={[
                            {
                                title: t => t("general.heading"),
                                content: <GeneralTabPreview customer={customer as NonNullable<typeof customer>} />
                            },
                            {
                                title: t => t("pricesAndDiscounts.heading"),
                                content: <DiscountTab customer={customer as NonNullable<typeof customer>} />
                            },
                            {
                                title: t => t("address.heading"),
                                content: <AddressTabPreview customer={customer as NonNullable<typeof customer>} />
                            },
                            {
                                title: t => t("settings.heading"),
                                content: <SettingsTab customer={customer as NonNullable<typeof customer>}/>
                            },
                            {
                                title: t => t("salesOrders.heading"),
                                content: <SaleOrdersTab customer={customer as NonNullable<typeof customer>}/>
                            }
                        ]}

                        manual={{
                            activeTab: activeTab,
                            onClick: setActiveTab
                        }}
                    />
                </BaseRecordRenderer>
            </Can>

          <DeletionModal
            isOpen={isDeletionModalOpen}
            onClose={() => setIsDeletionModalOpen(false)}
            onDeletion={() => history.push("/dashboard/customers")}
            entity={customer}
          />
      </>
    );
}