import React, { useLayoutEffect, useState } from "react";
import { useWindowSize } from "@helpers/utils";

export default function BackFadeBubble({ track }: { track: HTMLElement | null }) {
    const [backFadeConfig, setBackFadeConfig] = useState<{ left: number, width: number, height: number }>();

    const [width, height] = useWindowSize();

    useLayoutEffect(() => {
        if (track != null) {
            setBackFadeConfig({
                left: track.offsetLeft,
                width: track.offsetWidth + 1,
                height: track.offsetHeight
            });
        }
    }, [track, width, height]);

    return (
        <div className="absolute bg-stepper-selected rounded-[100px] transition-[.25s] z-0 top-0"
             style={backFadeConfig}></div>
    );
}