import React, { useCallback, useState } from "react";
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import BaseTable from "@reusables/BaseTable";
import { Link, useHistory } from "react-router-dom";
import { useFilters, useOrdering, usePagination } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import _ from "lodash";
import { ability, Can } from "@/casl.config";
import { Collection } from "@/types/general";
import DeletionModal from "../components/modals/DeletionModal";
import { useGetCollectionsFullQuery } from "@/redux/features/collections/collectionsApi";
import { Pagination } from "@mui/material";
import NoPermissionBanner from "@components/ErrorPages/NoPermissionBanner";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";

export default function CollectionsMainPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "inventory.collections" });

    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(
        _.debounce(setSearchingValue, 1000),
        []
    );

    const filters = useFilters(
        () => ({
            search: searchingValue
        }),
        [searchingValue]
    );

    // Table ordering
    const { orderBy, setOrderBy } = useOrdering<Collection.DTO.OrderBy>({
        name: "id",
        type: "desc"
    });

    const pagination = usePagination({ page: 1, limit: 8 });

    const { data: data, isFetching: dataLoading } = useGetCollectionsFullQuery({
        filters,
        orderBy,
        pagination: { page: pagination.page, limit: pagination.limit }
    });

    const [activeDeletion, setActiveDeletion] = useState<Collection.Root>();

    return (
        <>
            <BaseAdminFilter
                permissionModule="collection"
                handleSearch={searchInputDebounce}
                handleCreate={() =>
                    history.push("/dashboard/inventory/collections/new")
                }
            />

            <div className="levitation-extended mt-[32px]">
                <Can not I="view" a="collection">
                    <NoPermissionBanner />
                </Can>

                <Can I="view" a="collection">
                    <BaseTable
                        data={data?.payload ?? []}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: (row) => (
                                    <div className="bold-highlight">{row.code}</div>
                                ),
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.1"),
                                getter: (row) => row.name,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.2"),
                                getter: (row) => <>{row.products.length}</>,
                                comparator: () => 0
                            },
                            {
                                header: (
                                    <div className="text-center">{t("table.columns.3")}</div>
                                ),
                                getter: (row, index, isCollapsed) => (
                                    <div className="text-center space-x-[12px]">
                                        <BasePermissionBlocker action="edit" module="collection">
                                            <EditSVG
                                                className={`${
                                                    isCollapsed
                                                        ? "text-tables-highlightedIcon"
                                                        : "text-tables-secondaryIcon"
                                                } hover:text-tables-highlightedIcon cursor-pointer`}
                                                onClick={() => {
                                                    ability.can("edit", "collection") && history.push(
                                                        `/dashboard/inventory/collections/${row.id}/edit`,
                                                        row
                                                    );
                                                }}
                                            />
                                        </BasePermissionBlocker>

                                        <BasePermissionBlocker action="delete" module="collection">
                                            <TrashSVG
                                                className={`${
                                                    isCollapsed
                                                        ? "text-tables-highlightedIcon"
                                                        : "text-tables-secondaryIcon"
                                                } hover:text-tables-highlightedIcon cursor-pointer`}
                                                onClick={() => ability.can("delete", "collection") && setActiveDeletion(row)}
                                            />
                                        </BasePermissionBlocker>
                                    </div>
                                ),
                                preventCollapsePropagation: true
                            }
                        ]}
                        collapse={{
                            fill: "#D9DAFD",
                            borderColor: "#B3B5EB",
                            content: (mainRow) => {
                                return (
                                    <BaseTable
                                        data={mainRow.products}
                                        columns={[
                                            {
                                                header: (
                                                    <div className="text-accent font-semibold">
                                                        {t("subtable.columns.0")}
                                                    </div>
                                                ),
                                                getter: (subRow) => <Link
                                                    to={`/dashboard/products/${subRow.id}/details`}><u>{subRow.code}</u></Link>
                                            },
                                            {
                                                header: (
                                                    <div className="text-accent font-semibold">
                                                        {t("subtable.columns.1")}
                                                    </div>
                                                ),
                                                getter: (subRow) => subRow.name
                                            },
                                            {
                                                header: (
                                                    <div className="text-accent font-semibold">
                                                        {t("subtable.columns.2")}
                                                    </div>
                                                ),
                                                getter: (subRow) => subRow.quantity
                                            }
                                        ]}
                                        size="small"
                                        nothingFound={{
                                            height: 200
                                        }}
                                        hideTableWhenNothingFound
                                    />
                                );
                            }
                        }}
                        alternate
                        isDataLoading={dataLoading}
                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering) {
                                    let name: Collection.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 0:
                                            name = "id";
                                            break;
                                        case 1:
                                            name = "name";
                                            break;
                                        case 2:
                                            name = "quantity";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                        setOrderBy({
                                            name,
                                            type: newOrdering.order
                                        });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}
                    />
                    <Pagination className="mt-[32px]" {...pagination.adapt(data)} />
                </Can>
            </div>

            {/* DELETION MODAL */}
            <DeletionModal
                isOpen={!!activeDeletion}
                onClose={() => setActiveDeletion(undefined)}
                entity={activeDeletion}
            />
        </>
    );
}
