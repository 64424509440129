import dayjs, { Dayjs } from "dayjs";
import { z } from "zod";
import { refinements } from "@helpers/refinements";
import i18n from "@/i18n";

const ORDER_PRICE_PRECISION = import.meta.env.VITE_ORDER_PRICE_PRECISION;

const validDateRefinement = refinements.validDate;

const maxDecimalPrecisionRefinement = refinements.maxDecimalPrecision(ORDER_PRICE_PRECISION);

const lineDataSchema = z.object({
    id: z.number().nullish(),
    comment: z.string().max(90).nullish(),
    product: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string(),
        is_service: z.boolean(),
        in_stock: z.coerce.number().nullish(),
        min_sale_quantity: z.coerce.number().nullish(),
        prices: z.object({
            purchase_price: z.number(),
            selling_price: z.number()
        }),
        has_bom: z.boolean(),
        is_component: z.boolean(),
    }),
    quantity: z.coerce.number().positive().min(0),
    unit_price: z.coerce.number().positive().min(0).refine(maxDecimalPrecisionRefinement.refine, maxDecimalPrecisionRefinement.message),
    discount: z.coerce.number().min(0).max(100).optional(),
    tax: z.object({
        id: z.number(),
        rate: z.number()
    }).nullish(),
    availableQuantity: z.number().nullish()
})

// const linesDataSchema = z.object({
//     type: z.literal("line"),
//     id: z.number().optional(),
//     module: z.string().optional(),
//     group_id: z.number().optional(),
//     data: lineDataSchema
// })

// const groupLineDataSchema = z.object({
//     type: z.literal("group"),
//     id: z.number().optional(),
//     group_id: z.number().optional(),
//     name: z.string().optional(),
//     module: z.string(),
//     data: lineDataSchema.nullish()
// });

const linesSchema = z.object({
    type: z.union([z.literal("line"), z.literal("group")]),
    id: z.number().optional(),
    name: z.string().optional(),
    module: z.string().optional(),
    group_id: z.number().optional(),
    key: z.string().optional(),
    data: lineDataSchema.optional()
});

// const linesSchema = z.union([linesDataSchema, groupLineDataSchema]);

export const saleOrdersScheme = z.object({
    mode: z.union([z.literal("creation"), z.literal("editing")]),

    is_invoiced: z.boolean(),
    is_editable: z.boolean(),

    // ==== Customer ====
    customer: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string().nullish()
    }),

    order_date: z.instanceof(dayjs as unknown as typeof Dayjs).refine(validDateRefinement.refine, validDateRefinement.message),
    preferred_delivery_date: z.instanceof(dayjs as unknown as typeof Dayjs).nullish(),
    language: z.object({ id: z.number(), name: z.string(), code: z.string() }).nullish(),

    // ==== References ====
    our_reference: z.object({
        id: z.number(),
        name: z.string()
    }).nullish(),
    their_reference: z.object({
        id: z.number(),
        name: z.string()
    }).nullish(),
    payment_terms: z.object({
        id: z.number(),
        name: z.string()
    }).nullish(),
    delivery_terms: z.object({
        id: z.number(),
        name: z.string()
    }).nullish(),

    // ==== Addresses ====
    billing_address: z.object({
        name: z.string(),
        street: z.string(),
        city: z.string(),
        country: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string()
        }).nullish()
    }),

    delivery_address: z.object({
        id: z.number(),
        name: z.string(),
        street: z.string(),
        city: z.string(),
        country: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string()
        }).nullish()
    }).nullish(),

    is_billing_for_delivery: z.boolean(),

    // ==== Products ====
    lines: z.array(linesSchema).min(1, {
        message: i18n.t("general.validation.global.noProductsAdded")
    }),
    
    deleted_lines: z.array(z.number().nullish()).nullish()
}).superRefine((data, ctx) => {
    if (!data.is_billing_for_delivery && !data.delivery_address) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            params: {
                i18n: "general.validation.global.required"
            },
            path: ["delivery_address"]
        });
    }
});


export type SaleOrdersFormTyping = z.infer<typeof saleOrdersScheme>;
