import React, {useEffect} from "react";
import {useFormContext} from "react-hook-form";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import {PurchaseOrdersFormTyping} from "../../types";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "@redux/hooks";
import {useGetCompanyQuery} from "@redux/features/companies/companiesApi";
import BillingAddressInput from "./BillingAddressInput";
import DeliveryAddressInput from "./DeliveryAddressInput";
import ShippingCostInput from "./ShippingCostInput";

export default function AddressSection() {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.general"});

    const {control, setValue, watch} = useFormContext<PurchaseOrdersFormTyping>();

    const activeCompany = useAppSelector(state => state.auth.user?.company);

    const {
        data: activeCompanyFull,
        isLoading: isActiveCompanyFullLoading
    } = useGetCompanyQuery(activeCompany?.id ?? -1, {
        skip: !activeCompany
    });

    useEffect(() => {
        if (activeCompanyFull) {
            setValue("billing_address", activeCompanyFull.billing_address);
        }
    }, [activeCompanyFull]);


    return (
        <>
            <BaseInputsGrid title={t("categories.address")} cols={3}>
                <BillingAddressInput isLoading={isActiveCompanyFullLoading}/>
                <DeliveryAddressInput
                    options={activeCompanyFull?.deliveries ?? []}
                    isLoading={isActiveCompanyFullLoading}
                />
                <ShippingCostInput/>
            </BaseInputsGrid>
        </>
    )
}