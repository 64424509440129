import React from "react";
import ToggleWrapper from "../../../ToggleWrapper";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { useTranslation } from "react-i18next";
import { useGetModuleSuppliersQuery, useGetSuppliersQuery } from "@redux/features/suppliers/suppliersApi";
import { Controller, useFormContext } from "react-hook-form";
import { ProductSchemeTyping } from "@components/Dashboard/pages/Products/components/CreationEditingLayout/types";

export default function SupplierDropdown() {
    const { t } = useTranslation("", { keyPrefix: "products" });

    const { data: options, isLoading: optionsLoading } = useGetModuleSuppliersQuery("product");

    const { control, setValue } = useFormContext<ProductSchemeTyping>();

    return (
        <>
            <ToggleWrapper label={t("general.fields.primarySupplier.label")} fieldKey="supplier">
                <Controller
                    name="general.supplier" // Adjust this to the correct path in your form schema
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}
                            placeholder={t("general.fields.primarySupplier.placeholder")}
                            options={options}
                            getter={{
                                key: (opt) => opt.id,
                                label: (opt) => opt.name,
                                caption: (opt) => opt.code ?? opt.id
                            }}
                            isLoading={optionsLoading}
                            autocomplete
                        />
                    )}
                />
            </ToggleWrapper>
        </>
    );
}
