import { Menu } from '@mui/material'
import React, { useEffect, useState } from 'react'

import './index.scss'

// flags
import GBFlag from "@assets/images/flags/gb.svg"
import NOFlag from "@assets/images/flags/no.svg"

// translation
import { useTranslation } from 'react-i18next'

// type Props = {}
// {}: Props

function LanguageChanger() {

    // translation
    const { t, i18n } = useTranslation()

    // language changer menu
    const [languageOpen, setLanguageOpen] = useState<boolean>(false)
    const [languageAnchorEl, setLanguageAnchorEl] = useState<HTMLAnchorElement | null>(null)
    const openLanguageMenu = (event: React.MouseEvent<HTMLAnchorElement>) => {
        setLanguageOpen(true)
        setLanguageAnchorEl(event.currentTarget)
    }
    const closeLanguageMenu = () => {
        setLanguageOpen(false)
        setLanguageAnchorEl(null)
    };

    // supported languages
    const languages = [
        {
            idx: 0,
            flag: GBFlag,
            label: t("dashboard.navbar.languages.english"),
            shortLabel: "en"
        },
        {
            idx: 1,
            flag: NOFlag,
            label: t("dashboard.navbar.languages.norwegian"),
            shortLabel: "no"
        }
    ]

    const [selectedLang, setSelectedLang] = useState<number>(0)

    const handleLangChange = (language: any) => {
        i18n.changeLanguage(language?.shortLabel)
        setSelectedLang(language?.idx)

        closeLanguageMenu()
    }

    useEffect(() => {
        // console.log("LAN")
        // console.log(i18n.language)

        const currLang = languages.filter((language) => language.shortLabel == i18n.language)

        // if current language is a language available for dashboard
        if(currLang[0] != null) {
            setSelectedLang(currLang[0]?.idx)
        }
        // if current language is a different language
        else {
            setSelectedLang(languages[0].idx)
        }
    }, [])

    return (
        <>
            <a className="language-changer" onClick={openLanguageMenu}>
                <img src={languages[selectedLang].flag} alt="" />
            </a>
            <Menu
                id={languageOpen ? "notification-menu" : undefined}
                open={languageOpen}
                anchorEl={languageAnchorEl}
                onClose={closeLanguageMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <div className="d-flex flex-column" style={{ padding: "24px", width: "11rem", gap: '24px' }}>
                    {
                        languages.map((language, idx) => (
                            <div key={idx} className="d-flex" style={{ columnGap: '16px', cursor: 'pointer' }} onClick={() => handleLangChange(language)}>
                                <img src={language.flag} alt="" />
                                <span className="text-md-light color-4">{language.label}</span>
                            </div>
                        ))
                    }
                </div>
            </Menu>
        </>
    )
}

export default LanguageChanger;
