import React, { useEffect, useRef, useState } from "react";
import { Button, Menu } from "@mui/material";
import BaseButton from "../BaseButton";

// icons
import { ReactComponent as SearchSVG } from "@assets/icons/ic_search.svg";
import { ReactComponent as IcFilter } from "@assets/images/icons/dashboard/filter.svg";
import { ReactComponent as IcImport } from "@assets/images/icons/dashboard/import.svg";
import { ReactComponent as IcExport } from "@assets/icons/ic_signout.svg";
import { ReactComponent as IcPlus } from "@assets/icons/ic_plus.svg";
import IcChooseFile from "@assets/icons/ic_choose_file.svg";
import BaseFormItem, { BaseFormItemProps } from "../BaseFormItem";
import Modal from "../../common/Modal";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import { AbilityContext, Actions, Modules } from "@/casl.config";
import { useAbility } from "@casl/react";
import { Permission } from "@/types/general";
import { cn } from "@helpers/utils";
import { toast } from "react-toastify";
import BaseTooltip from "../BaseTooltip";

type FilterConfig = {
  filterTitle?: string;
  importTitle?: string;
  exportTitle?: string;
  createTitle?: string;
};

type Props = {
  filterItems?: BaseFormItemProps[] | JSX.Element;
  filterContainerClassName?: string;
  config?: FilterConfig;
  handleSearch?: (val: string) => void;
  handleFilter?: (value: string, ...args: any) => any;
  handleImport?: (file: File | undefined) => any;
  handleExport?: () => any;
  handleCreate?: () => any;

  extendWith?: JSX.Element;
  className?: string;
  permissionModule?: Modules;
  subPermission?: Actions | Actions[];
};

export default function Filter({
  filterContainerClassName,
  filterItems,
  config,
  handleSearch,
  handleFilter,
  handleImport,
  handleExport,
  handleCreate,
  extendWith,
  ...props
}: Props) {
  const { t } = useTranslation("", { keyPrefix: "general" });

  const [showFilter, setShowFilter] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [importFile, setImportFile] = useState<File>();

  const ability = useAbility(AbilityContext);

  const action = props.subPermission ? props.subPermission : 'create';
  let cannotPerformAction: boolean;

  if (Array.isArray(action)) {
    // If all actions are disallowed, cannotPerformAction is true
    // If at least one action is allowed, cannotPerformAction is false
    cannotPerformAction = action.every((act) =>
      ability.cannot(act, props.permissionModule as Modules)
    );
  } else {
    cannotPerformAction = ability.cannot(action, props.permissionModule as Modules);
  }

  const toggleShowFilter = () => setShowFilter((prev) => !prev);
  const toggleShowImport = () => {
    setShowImport((prev) => !prev);
    setImportFile(undefined);
  };

  const filterBtnRef = useRef(null);
  const importBtnRef = useRef(null);

  const [isSearchFocused, setIsSearchFocused] = useState<boolean>(false);

  const _renderFilter = () => {
    return (
      handleFilter && (
        <>
          <Button
            ref={filterBtnRef}
            variant="contained"
            color="secondary"
            size="large"
            onClick={toggleShowFilter}
            className="max-w-[128px]"
          >
            <div className="flex justify-center items-center space-x-2 px-[32px]">
              <span>{t("filter.title")}</span>
              <IcFilter />
            </div>
          </Button>
          {filterItems && (
            <Menu
              anchorEl={filterBtnRef?.current}
              id="filterMenu"
              open={showFilter}
              onClose={toggleShowFilter}
              elevation={0}
              className="!shadow-sm-1 mt-2 p-6"
              sx={{
                ".MuiPaper-root": {
                  p: "24px",
                  boxShadow: "-6px 5px 33px rgba(59, 65, 208, 0.11)",
                },
              }}
            >
              {Array.isArray(filterItems)
                ? filterItems.length > 0 && (
                    <div className={filterContainerClassName}>
                      {filterItems?.map((item, i) => (
                        <BaseFormItem
                          {...item}
                          key={item.key}
                          onChange={(e) => {
                            handleFilter(item?.key as string, e.target.value);
                          }}
                        />
                      ))}
                    </div>
                  )
                : filterItems}
            </Menu>
          )}
        </>
      )
    );
  };

  const _renderImport = () => {
    if (!handleImport) return;
    const handleFileChange = (e: any) => setImportFile(e.target.files[0]);
    const handleSubmitImport = async () => {
      if (importFile?.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        && importFile?.type !== "application/vnd.ms-excel"
        && importFile?.type !== "text/csv"
      ) {
        toast.error(t("import.invalidFileType"));
        return;
      }
      await handleImport(importFile);
      toggleShowImport();
    };
    return (
      <>
        <BaseTooltip active={ability.cannot("import", props.permissionModule as Modules)} title="Access denied" placement="bottom">
          <Button
            ref={importBtnRef}
            variant="contained"
            color="secondary"
            size="large"
            onClick={toggleShowImport}
            className="max-w-[128px] h-[56px]"
            disabled={ability.cannot("import", props.permissionModule as any)}
          >
            <div className="flex justify-center items-center space-x-2 px-[32px]">
              <IcImport />
              <span>{t("import.title")}</span>
            </div>
          </Button>
        </BaseTooltip>
        {showImport &&
          createPortal(
            <Modal
              open={showImport}
              onCancel={toggleShowImport}
              title={config?.importTitle}
              footer={
                <>
                  <BaseButton
                    className="w-full"
                    size="md"
                    text="Import"
                    disabled={!importFile}
                    onClick={handleSubmitImport}
                  />
                </>
              }
            >
              <div
                className="relative flex flex-column justify-center items-center min-h-[260px] rounded-lg"
                style={{
                  background: "rgba(200, 233, 142, 0.2)",
                }}
              >
                <input
                  type="file"
                  className="opacity-0 absolute top-0 left-0 right-0 bottom-0 h-full"
                  onChange={handleFileChange}
                />
                <img src={IcChooseFile} />
                <div className="mt-[8px] text-accent font-semibold">
                  {importFile ? importFile.name : t("import.action")}
                </div>
              </div>
            </Modal>,
            document.body
          )}
      </>
    );
  };

  return (
    <div
      className={cn(
        "bg-lightGreen-400 bg-opacity-40 border-[1px] border-lightGreen-500 border-solid rounded-xl px-8 py-4",
        props.className
      )}
    >
      <div className={`flex space-x-4 justify-between items-center`}>
        <div
          className={`flex space-x-4 transition-all ${
            isSearchFocused ? " grow" : ""
          }`}
        >
          {handleSearch && (
            <div
              className={`relative w-full ${
                isSearchFocused ? "" : "max-w-[300px]"
              }`}
            >
              <input
                placeholder={t("search.placeholder")}
                onChange={(e) => handleSearch(e.currentTarget.value)}
                className={`${
                  isSearchFocused
                    ? "bg-[#fff] placeholder:inputs-placeholder"
                    : "bg-[#C8E98E] placeholder:text-accent"
                } p-[16px] rounded-[8px] border-0 pl-[40px] w-full`}
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setIsSearchFocused(false)}
              />

              <div className="absolute top-50 left-[18px] translate-middle-y">
                <SearchSVG className="text-accent mt-[-2px]" />
              </div>
            </div>
          )}

          {!isSearchFocused && _renderFilter()}

          { props.permissionModule &&
            !isSearchFocused && handleImport &&
            _renderImport()
          }
          

          {!isSearchFocused && handleExport && (
            <BaseTooltip active={ability.cannot("export", props.permissionModule as Modules)} title="Access denied">
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleExport}
                className="max-w-[128px] h-[56px]"
                disabled={ability.cannot("export", props.permissionModule as Modules)}
              >
                <div className="flex justify-center items-center space-x-2 px-[32px]">
                  <IcExport />
                  <span>{t("export.title")}</span>
                </div>
              </Button>
            </BaseTooltip>
          )}

          {!isSearchFocused && extendWith}
        </div>
        {/* TODO: hide if ability is not verified */}
        {handleCreate &&
          (props.permissionModule && (
            <BaseTooltip active={cannotPerformAction} title="Access denied">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCreate}
                disabled={cannotPerformAction}
              >
                <div className="flex justify-center items-center space-x-2">
                  <IcPlus />
                  <span>{config?.createTitle || t("creation.title")}</span>
                </div>
              </Button>
            </BaseTooltip>
          ))}
      </div>
    </div>
  );
}
