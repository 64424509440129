import BaseRelativeTime from "@reusables/BaseMicroUtils/components/BaseRelativeTime";
import BaseCurrentTime from "@reusables/BaseMicroUtils/components/BaseCurrentTime";
import BaseCurrency from "@reusables/BaseMicroUtils/components/BaseCurrency";

const BaseMicroUtils = {
    RelativeTime: BaseRelativeTime,
    CurrentTime: BaseCurrentTime,
    BaseCurrency: BaseCurrency
};

export default BaseMicroUtils;