import React from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

// random stranger
// about pics carousel
import ImAbout1 from "@assets/images/im_about_1.jpg";
import ImAbout2 from "@assets/images/im_about_2.jpg";
import ImAbout3 from "@assets/images/im_about_3.jpg";

// team members
import SteinarImg from "@assets/images/team-members/Steinar.png";
import DanielImg from "@assets/images/team-members/Daniel.png";
import OlgaImg from "@assets/images/team-members/Olga.png";
import MykhailoImg from "@assets/images/team-members/Mykhailo.png";
import KacperImg from "@assets/images/team-members/Kacper.png";
import AnnaImg from "@assets/images/team-members/Anna.png";
import GardImg from "@assets/images/team-members/Gard.png";
import RolfImg from "@assets/images/team-members/Rolf.png";
import BozhidarImg from "@assets/images/team-members/Bozhidar.png";

import MediaInquiriesBanner from "@assets/images/media_inquiries_banner.jpg";

import "./index.scss";
import BaseInputsGrid from "@reusables/BaseInputsGrid";

function AboutBlock() {
    const {t} = useTranslation();

    const images = [ImAbout1, ImAbout2, ImAbout3];


    const members = [
        {
            img: SteinarImg,
            name: "Steinar Eriksen",
            position: "Founder",
            email: "se@suppli.no",
        },
        {
            img: RolfImg,
            name: "Rolf Ivar Skår",
            position: "Chief Executive Officer",
            email: "rs@suppli.no",
        },
        {
            img: AnnaImg,
            name: "Anna Hych",
            position: "Chief Marketing Officer",
            email: "ah@vantevo.no",
        },
        {
            img: DanielImg,
            name: "Daniel Christiansen",
            position: "Customer Success Manager"
        },
        {
            img: OlgaImg,
            name: "Olga Lobanok",
            position: "UI/UX Designer",
        },
        {
            img: MykhailoImg,
            name: "Mykhailo Diachenko",
            position: "Team Lead",
        },
        {
            img: BozhidarImg,
            name: "Bozhidar Kukeski",
            position: "Development Manager",
        },
        {
            img: KacperImg,
            name: "Kacper Kuklinski",
            position: "Developer"
        },
        {
            img: GardImg,
            name: "Gard Nornes Pedersen",
            position: "Customer Service",
        },
    ];

    const renderCarousel = () => (
        <div
            className="product-slider slick-slider list-unstyled"
            data-slick='{"slidesToShow": 1, "slidesToScroll": 1, "fade": true, "autoplay": true, "arrows": true, "dots": true, "infinite": false, "responsive":[{"breakpoint":574,"settings":{"fade": true}}]}'
        >
            {images.map((im, i) => (
                <div key={i} className="slide">
                    <div className="slide-pic max-h-[604px]!">
                        <LazyLoad>
                            <img src={im}/>
                        </LazyLoad>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <>
            <div className="section about-section">
                <div className="container-lg space-y-[200px]">
                    <div className="about-main">
                        <div className="about-text">
                            <div className="text-lg text-[#8850F4] mb-4 tracking-wider uppercase">
                                {t("homePage.aboutBlock.preTitle")}
                            </div>
                            <div className="text-[56px] text-[#2B2A35] font-[700] leading-[64.4px] mb-8">
                                {t("homePage.aboutBlock.title")}
                            </div>
                            <div className="max-w-[678px] mx-auto text-base text-[#686868] leading-[2.05]">
                                <p>{t("homePage.aboutBlock.descriptions.1")}</p>
                                <p>{t("homePage.aboutBlock.descriptions.2")}</p>
                                <p>{t("homePage.aboutBlock.descriptions.3")}</p>
                            </div>
                        </div>

                        {renderCarousel()}
                    </div>
                    <h1 className="margin-important text-center text-[#8850F4] leading-[96px] text-[80px]">One team -
                        one vision</h1>
                    <BaseInputsGrid
                        cols={{
                            xl: 3,
                            lg: 3,
                            md: 2,
                            sm: 2,
                            xs: 1
                        }}
                        gap={{x: 64, y: 64}}
                    >
                        {members.map((item, i) => (
                            <div className="text-center" key={i}>
                                <img className="w-[264px] mb-[24px]" src={item.img}/>
                                <div
                                    className="max-tablet:h-[56px] max-tablet:flex max-tablet:items-center max-tablet:justify-center text-[#8850F4] font-bold text-xl leading-[1.4] mb-[8px]">
                                    {item.name}
                                </div>
                                <div className="font-thin text-[#3C3769] leading-[1.5] mb-[16px]">
                                    {item.position}
                                </div>
                                <div className="font-thin text-[686868] leading-[1.5]">
                                    {item.email}
                                </div>
                            </div>
                        ))}
                    </BaseInputsGrid>
                </div>
            </div>
            <div
                className="section bg-gradient-to-r from-[#8850F4] to-[#6959FC] tablet:p-[60px] phone:p-[30px] mt-[200px]">
                <div className="container-lg grid desktop:grid-cols-2 gap-[10px] selection:bg-[#fff] selection:text-[#3C3769]">
                    <div
                        className="desktop:w-[558px] desktop:h-[600px] desktop:justify-end tablet:h-[600px] phone:w-full phone:h-[400px]
                        bg-center bg-no-repeat bg-cover flex items-end p-[24px]"
                        style={{backgroundImage: `url(${MediaInquiriesBanner})`}}
                    >
                        <div
                            className="text-uppercase p-[8px_16px] bg-[#fff] text-[#3C3769] phone:text-[12px] tablet:text-[18px] font-medium tracking-widest rounded-[20px]">
                            For media inquiries
                        </div>
                    </div>
                    <div className="flex flex-column justify-end space-y-[32px]">
                        <h3 className="text-[#fff]">Steinar Mc Shane Eriksen</h3>
                        <div className="space-y-[16px] text-[20px] font-thin text-[#fff]">
                            <div>Founder Suppli AS</div>
                            <div>Email: <u>se@suppli.no</u></div>
                            <div>Tel: +47 332 00 955</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutBlock;
