import { Icon } from "@mui/material";
import React from "react";
import { Ordering } from "../types";
import "./index.scss";

interface SorterProperties{
    index: number;
    currentOrdering?: Ordering;
}

export default function Sorter(props: SorterProperties): JSX.Element{
    const state = props.currentOrdering && props.currentOrdering.index === props.index
    ? props.currentOrdering.order : "";
    return(
        <>
            {
                <div className={`arrow ${state}`}>
                    <Icon>arrow_drop_down</Icon>
                </div>
            }
        </>
    )
}