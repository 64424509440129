import CFMutator from "@reusables/BaseCustomFieldsSection/parts/CFMutator";
import CFPreviewer from "@reusables/BaseCustomFieldsSection/parts/CFPreviewer";

const BaseCustomFieldsSection = {
    Mutation: CFMutator,
    Previewer: CFPreviewer
}

/**
 * == Mutator: ==
 *  Should be placed in the mutation form to inject custom fields into the form. The form should be enhanced with "withCustomFields" ZOD schema injector utility.
 *  <BaseCustomFieldsSection.Mutation
 *   mode={product ? "editing" : "creation"}
 *   module={"product"}
 *  />
 *
 *  == Previewer: ==
 *  Should be placed in the preview form to display custom fields. "source" prop should contain "custom_fields" field of proper type (see general.ts examples).
 *  <BaseCustomFieldsSection.Previewer source={product}/>
 **/

export default BaseCustomFieldsSection;