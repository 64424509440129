import React from "react";
import NavigationBar from "../NavigationBar";
import FooterBlock from "../FooterBlock";

import AboutBlock from "./AboutBlock";

function AboutPage() {

  return (
    <>
      <NavigationBar />

      <main className="about-wrap">
        <AboutBlock />
      </main>

      <FooterBlock />
    </>
  );
}

export default AboutPage;
