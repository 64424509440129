import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'

import "./index.scss"
import {InputBasics} from "@reusables/reusablesCore";
import BasePopper from "@reusables/BasePopper";

import {ReactComponent as CrossSVG} from "@assets/icons/ic_cross.svg";


type BasePhoneInputProps = {
    label?: string;
    brightLabel?: boolean;
    variant?: "default" | "custom";
    country?: string;
    size?: "sm" | "md";
    showDropdown?: boolean;
    dropdownClass?: string;
    inputClass?: string;
    dropdownStyle?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    enableSearch?: boolean;
    onValueChange?: (value: string, country: any) => void;
} & InputBasics<string>


export default forwardRef<HTMLDivElement, BasePhoneInputProps>(function BasePhoneInput(props: BasePhoneInputProps, ref): JSX.Element {
    const { variant = 'default', size = 'sm', country = "no" } = props;

    const anchorEl = React.useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => {
        return anchorEl.current as unknown as HTMLDivElement;
    }, [anchorEl]);

    const [isFocused, setIsFocused] = React.useState(false);

    return (
        <div ref={anchorEl} className={`phone-input-container ${variant} ${size}`}>
            {
                props.label &&
                <p className={`mb-2 font-semibold${props.brightLabel ? " text-inputs-label-bright" : " text-inputs-label-dim"}`}>
                    {props.label}
                </p>
            }
            <PhoneInput
                country={country.toLowerCase()}
                value={props.value ?? ""}
                onChange={(value, country) => {
                    if(props.onChange) {
                        props.onChange(value);
                    }
                    if(props.onValueChange) {
                        props.onValueChange(value, country);
                    }
                }}
                buttonClass={"shadow-none"}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                inputClass={`
                phone-input ${variant} ${props.inputClass ?? ""}
                ${props.error ? "error" : ""}
                `} // Conditionally apply variant class
                inputStyle={props.inputStyle}
                enableSearch={true}
                containerClass={`${props.disabled ? "disabled" : ""}`}
                disabled={props.disabled}
            />

            <BasePopper anchorEl={anchorEl.current} open={isFocused && !!props.error} placement="bottom">
                <div
                    className="bg-[#fff] p-[14px] rounded-[8px] levitation text-inputs-textError font-thin text-sm space-y-[4px]"
                    style={{ width: anchorEl.current?.offsetWidth }}>
                    <div className="flex items-center">
                        <CrossSVG style={{ transform: "scale(.7)", marginRight: "4px", marginTop: "-1px" }} />
                        <span>{typeof props.error === "string" ? props.error : props.error?.message}</span>
                    </div>
                </div>
            </BasePopper>
        </div>
    )
});