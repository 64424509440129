import BaseButton from "@reusables/BaseButton";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseChip from "@reusables/BaseChip";
import { Integration } from "@/types/general";
import TripletexImportModal
    from "src/components/Dashboard/pages/SettingsPage/Integrations/ThirdPartyIntegrations/components/modals/import/TripletexImportModal";
import BaseTooltip from "@reusables/BaseTooltip";
import PowerofficeImportModal from "../modals/import/PowerofficeImportModal";
import PowerOfficeSettingsModal from "../modals/settings/PowerOfficeSettingsModal";

interface IntegrationCardProps {
    name: string;
    description: string;
    imageUrl: string;
    integrated: boolean;
    onModalOpen: () => void;
    hidden?: boolean;
    slug: Integration.Components.Slug;
    isIntegrationsLimitReached: boolean;
}

const isImportAvailable = (slug: Integration.Components.Slug) => slug === "tripletex" || slug === "poweroffice";
const isConfigurationAvailable = (slug: Integration.Components.Slug) => slug === "poweroffice";

export default memo(function IntegrationCard({
                                                 name,
                                                 description,
                                                 imageUrl,
                                                 onModalOpen,
                                                 hidden,
                                                 integrated,
                                                 slug,
                                                 isIntegrationsLimitReached
                                             }: IntegrationCardProps) {
    const { t } = useTranslation("", { keyPrefix: "settings.integrations.thirdParty.buttons" });

    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

    return (
        <>
            <li className="flex-row space-x-[20px]" style={{ display: hidden ? "none" : "flex" }}>
                <div className={"grow flex flex-row space-x-4"}>
                    <div>
                        <img
                            src={imageUrl}
                            alt={name}
                            className="w-[80px]"
                        />
                    </div>
                    <div>
                        <div className={"flex flex-row space-x-4"}>
                            <p className="text-2xl text-[#3c3769]">{name}</p>
                            {
                                integrated &&
                                <div className={"mt-[4px]"}>
                                    <BaseChip fill={"#A6DD4C"} asDot>Integrated</BaseChip>
                                </div>
                            }
                        </div>
                        <p>{description}</p>
                    </div>
                </div>
                <div className={"flex justify-center items-end gap-2"}>
                    {
                        isImportAvailable(slug) && integrated &&
                        <BaseButton
                            onClick={() => void setIsImportModalOpen(true)}
                            text={t("import")}
                            size={"small"}
                            buttonWidth={"150px"}
                        />
                    }
                    {
                        isConfigurationAvailable(slug) && integrated &&
                        <BaseButton
                            onClick={() => void setIsSettingsModalOpen(true)}
                            text={t("settings")}
                            size={"small"}
                            buttonWidth={"150px"}
                        />
                    }
                    {
                        !integrated &&
                        <BaseTooltip
                            title={t("integrationsLimitReached") as string}
                            active={isIntegrationsLimitReached}
                        >
                            <BaseButton
                                onClick={onModalOpen}
                                text={t("integrate")}
                                size={"small"}
                                primaryOutlined
                                buttonWidth={"150px"}
                                disabled={isIntegrationsLimitReached}
                            />
                        </BaseTooltip>
                    }
                    {
                        integrated &&
                        <BaseButton
                            onClick={onModalOpen}
                            text={t("disable")}
                            size="small"
                            primaryOutlined
                            buttonWidth="150px"
                            borderColor={"#E25C5C"}
                            textColor={"#E25C5C"}
                        />
                    }
                </div>
            </li>

            {
                slug === "tripletex" && integrated &&
                <TripletexImportModal
                    isOpen={isImportModalOpen}
                    onClose={() => setIsImportModalOpen(false)}
                />
            }

            {
                slug === "poweroffice" && integrated &&
                <PowerofficeImportModal
                    isOpen={isImportModalOpen}
                    onClose={() => setIsImportModalOpen(false)}
                />
            }

            {
                slug === "poweroffice" && integrated &&
                <PowerOfficeSettingsModal
                    isOpen={isSettingsModalOpen}
                    onClose={() => setIsSettingsModalOpen(false)}
                />
            }
        </>
    );
});