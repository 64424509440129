import React from "react";
import { useTranslation } from "react-i18next";
import BaseButton from "@reusables/BaseButton";
import BaseModal from "@reusables/Modals/BaseModal";

interface DeletionModalProperties {
    isOpen: boolean;
    onClose: () => void;
    onDeletion: () => void;
    variant?: {name?: string | null}
}

export default function VariantDeletionModal(props: DeletionModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "products.createEditProduct.modals.deletionVariant" });

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
        >
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">{t("subheading")} <span className="font-bold">{props.variant?.name}</span>?</div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose}/>
                    <BaseButton text={t("buttons.delete")} size="md" onClick={() => {
                        props.onDeletion();
                    }}/>
                </div>
            </div>
        </BaseModal>
    )
}
