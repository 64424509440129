import React from "react";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { Customer } from "@/types/general";
import { useTranslation } from "react-i18next";

export default function SettingsTab({ customer }: { customer: Customer.Extended }) {
    const { t } = useTranslation("", { keyPrefix: "customers.general.tabs.settings" });
    return (
        <BaseInputsGrid>
            <InputLikeContainer 
                label={t("dropdowns.language.label")}
                content={customer.language?.name}
            /> 
            <InputLikeContainer
                label={t("dropdowns.taxRate.label")}
                content={customer.tax_rate ? `${customer.tax_rate.rate}%` : undefined}
            />

            <InputLikeContainer
                label={t("fields.discount.label")}
                content={customer.discount ? customer.discount + "%" : undefined}
            />

            <InputLikeContainer
                label={t("dropdowns.paymentTerms.label")}
                content={customer.payment_terms?.name}
            />

            <InputLikeContainer
                label={t("dropdowns.deliveryTerms.label")}
                content={customer.delivery_terms?.name}
            />

            <InputLikeContainer
                label={t("dropdowns.reference.label")}
                content={customer.reference?.name}
            />
        </BaseInputsGrid>
    )
}

function InputLikeContainer({ label, content }: { label: string, content: JSX.Element | string | undefined | number }) {
    return (
      <div>
          <div className="font-semibold text-inputs-label-dim mb-[8px]">{label}</div>
          <div className="text-accent">
              {content ?? "-"}
          </div>
      </div>
    );
}
