import React, { useMemo, useState } from "react";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableTypeMap,
} from "@mui/material";

import { ReactComponent as IcEdit } from "@assets/icons/ic_edit.svg";
import { ReactComponent as IcCopy } from "@assets/icons/ic_copy.svg";
import { ReactComponent as IcDelete } from "@assets/icons/ic_trash.svg";
import { ReactComponent as IcCart } from "@assets/icons/ic_cart.svg";

type Entity = Record<string, unknown>;
export type Column = {
  key?: string;
  label?: string;
  render?: (value: any) => React.ReactNode;
} & Partial<TableCellProps>;

type ActionProps = {
  type: string;
  handler: (row: any) => void;
};

type Props<T extends Entity> = {
  columns: Column[];
  data: T[];
  loading?: boolean;
  tableConfig?: Entity & Partial<TableTypeMap>;
  emptyComponent?: React.ReactNode;
  rowClassName?: string;
  cellClassName?: string;
  inverted?: boolean;
  actions?: ActionProps[];
  expandable?: (row?: T) => React.ReactNode;
};

const DEFAULT_TABLE_BODY_STYLES = {
  color: "#3C3769",
  fontWeight: 300,
  fontSize: 14,
  "&.font-bold": { fontWeight: 600 },
  border: "none",
};

const DEFAULT_TABLE_HEAD_STYLE = {
  ...DEFAULT_TABLE_BODY_STYLES,
  color: "#686868",
};

const BaseAdminTable = <T extends Entity>({
  loading,
  columns,
  data,
  tableConfig,
  emptyComponent,
  cellClassName,
  inverted,
  actions,
  expandable,
}: Props<T>) => {
  const isEmpty = useMemo(() => data?.length === 0, [data]);
  const hasActions = useMemo(() => actions && actions?.length > 0, [actions]);

  const _renderActionitem = (row: any, action: ActionProps) => {
    const Icon = {
      edit: IcEdit,
      copy: IcCopy,
      delete: IcDelete,
      cart: IcCart,
    }[action.type];

    return (
      <div className="cursor-pointer" onClick={action.handler.bind(this, row)}>
        {Icon && (
          <Icon className="text-primary-gray hover:text-dark-blue hover:fill-dark-blue" />
        )}
      </div>
    );
  };

  const _renderRow = (row: T, i: number) => {
    return (
      <TableRow
        key={`${row?.id}-${i}`}
        className={`${inverted && i % 2 == 0 ? "bg-tab-bg" : ""}`}
      >
        {columns.map((col, colIdx) => (
          <TableCell className={cellClassName} {...col} key={colIdx}>
            <>
              {loading ? (
                <Skeleton />
              ) : col?.render ? (
                col.render(col?.key ? row[col.key] : row)
              ) : (
                col.key && row[col?.key]
              )}
            </>
          </TableCell>
        ))}
        {hasActions && (
          <TableCell className={cellClassName}>
            <div className="flex items-center space-x-2">
              {actions?.map(_renderActionitem.bind(this, row))}
            </div>
          </TableCell>
        )}
      </TableRow>
    );
  };

  const _renderExpandableRow = (row: T, i: number) => {
    const [open, setOpen] = useState(false);
    const expandableContent = open && expandable ? expandable(row) : null;
    const bgStyle = {
      background: expandableContent ? "#E3F5D5" : i % 2 == 0 ? "#F9F9F9" : "",
    };

    return (
      <>
        <TableRow style={bgStyle} onClick={() => setOpen((prev) => !prev)}>
          {columns?.map((col, i) => (
            <TableCell key={i}>
              {loading ? (
                <Skeleton />
              ) : col?.render ? (
                col.render(col?.key ? row[col.key] : row)
              ) : (
                col.key && row[col?.key]
              )}
            </TableCell>
          ))}
        </TableRow>
        {expandableContent}
      </>
    );
  };

  const _renderLoadingRow = () => {
    return [...Array(10)].map((i) => (
      <TableRow key={i}>
        {columns.map((_, idx) => (
          <TableCell key={idx}>
            <Skeleton />
          </TableCell>
        ))}
        <TableCell>{hasActions && <Skeleton />}</TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <TableContainer
        sx={{
          ".MuiTable-root": {
            ".MuiTableHead-root": {
              borderBottom: "1px solid #F3F2EF",
              ".MuiTableCell-root": DEFAULT_TABLE_HEAD_STYLE,
            },
            ".MuiTableBody-root .MuiTableCell-root": DEFAULT_TABLE_BODY_STYLES,
          },
        }}
      >
        <Table {...tableConfig}>
          <TableHead>
            <TableRow>
              {columns.map((col, i) => (
                <TableCell key={i}>{col.label}</TableCell>
              ))}
              {hasActions && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              ".MuiTableCell-root": {
                color: "#3C3769",
                fontWeight: 300,
                fontSize: 13,
                "&.font-bold": {
                  fontWeight: 600,
                },
              },
            }}
          >
            {loading
              ? _renderLoadingRow()
              : data?.map(expandable ? _renderExpandableRow : _renderRow)}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BaseAdminTable;
