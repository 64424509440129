import React, { useEffect } from "react";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseModal from "@reusables/Modals/BaseModal";
import { Product } from "@/types/general";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useGetLanguagesQuery } from "@redux/features/languages/languagesApi";
import BaseInput from "@reusables/BaseInput";
import BaseButton from "@reusables/BaseButton";
import { useCreateProductTranslationMutation, useUpdateProductTranslationMutation } from "@redux/features/products/productsApi";
import { toast } from "react-toastify";
import _ from "lodash";
import { isErrorWithMessage } from "@redux/api/query";

const formScheme = z.object({
    selectedLanguage: z.object({
        id: z.number(),
        code: z.string(),
        name: z.string()
    }),
    name: z.string().nonempty(),
    description: z.string().nonempty()
});

type FormTyping = z.infer<typeof formScheme>;

interface MutationModalProperties {
    isOpen: boolean;
    translation?: Product.Augmentations.Translation.Root;
    productId: number;
    alreadySelectedIds: number[];
    onClose: () => void;
}

export default function MutationModal(props: MutationModalProperties) {
    const { t } = useTranslation("", {
        keyPrefix: "products.viewEditProduct.tabs.translations.modals.mutation"
    });

    const [createProductTranslation, { isLoading: isCreatingProductTranslationLoading }] = useCreateProductTranslationMutation();

    const [updateProductTranslation, { isLoading: isUpdatingProductTranslationLoading }] = useUpdateProductTranslationMutation();
    const { control, handleSubmit, setValue, reset } = useForm<FormTyping>({
        resolver: zodResolver(formScheme)
    });

    const { data: languages, isLoading: isLanguagesLoading } = useGetLanguagesQuery();

    const filteredModels = _.filter(languages, (model) => !props.alreadySelectedIds.includes(model.id));

    console.log(filteredModels);
    const onSubmit = handleSubmit((data) => {
        if (props.translation) {
            updateProductTranslation({ 
                id: props.translation.id, 
                product_id: props.productId, 
                name: data.name,
                description: data.description
            }).unwrap()
                .then(() => {
                    toast.success(t("editing.responses.success"));
                    props.onClose();
                })
                .catch((e) => {
                    if(isErrorWithMessage(e)) {
                        toast.error(e.message)
                    } else {
                        toast.error(t("editing.responses.error"));
                    }
                });
        } else {
            createProductTranslation({ 
                product_id: props.productId, 
                language_id: data.selectedLanguage.id, 
                name: data.name, 
                description: data.description
            }).unwrap()
                .then(() => {
                    toast.success(t("addition.responses.success"));
                    props.onClose();
                })
                .catch((e) => {
                    if(isErrorWithMessage(e)) {
                        toast.error(e.message)
                    } else {
                        toast.error(t("addition.responses.error"));
                    }
                });
        }
    });

    useEffect(() => {
        if (props.isOpen) {
            if (props.translation) {
                setValue("selectedLanguage", props.translation.language);
                setValue("name", props.translation.name);
                setValue("description", props.translation.description);
            }
        } else {
            reset();
        }
    }, [props.isOpen]);

    return (
        <BaseModal 
            isOpen={props.isOpen} 
            onClose={props.onClose} 
            width={562} 
            padding="56px" 
            useCloseIcon 
            isLoading={isCreatingProductTranslationLoading || isUpdatingProductTranslationLoading}
        >
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent">{props.translation ? t("editing.heading") : t("addition.heading")}</div>

                <Controller
                    disabled={!!props.translation}
                    name={"selectedLanguage"}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseDropdown
                            options={filteredModels}
                            {...field}
                            isLoading={isLanguagesLoading}
                            {...fieldState}
                            label={`${t("fields.language.label")} *`}
                            placeholder={t("fields.language.placeholder")}
                            getter={{
                                key: (opt) => opt.id,
                                label: (opt) => opt.name,
                                caption: (opt) => opt.code
                            }}
                        />
                    )}
                />

                <Controller
                    name={"name"}
                    control={control}
                    render={({ field, fieldState }) => 
                        <BaseInput 
                            {...field} 
                            error={fieldState.error} 
                            label={t("fields.name.label")} 
                            placeholder={t("fields.name.placeholder")} 
                            type={"text"} 
                        />
                    }
                />

                <Controller
                    name={"description"}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field} 
                            error={fieldState.error} 
                            label={t("fields.description.label")} 
                            placeholder={t("fields.description.placeholder")} 
                            type={"text"} 
                            multiline 
                            rows={2} 
                        />
                    )}
                />

                <BaseButton 
                    buttonWidth="100%" 
                    text={t("buttons.save")} 
                    size="md" 
                    type={"submit"} 
                />
            </form>
        </BaseModal>
    );
}