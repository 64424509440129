import { z } from "zod";

export const lowStockRefillScheme = z.object({
    smart_refill: z.boolean(),
    lines: z.array(
        z
            .object({
                should_be_refilled: z.boolean(),

                product: z.object({
                    id: z.number(),
                    name: z.string(),
                    code: z.string(),
                    has_bom: z.boolean()
                }),
                quantity: z.coerce.number().optional(),

                should_use_bom: z.boolean().optional(),
                selected_bom: z
                    .object({
                        id: z.number(),
                        name: z.string(),
                        components: z.array(
                            z.object({
                                product: z.object({
                                    id: z.number(),
                                    name: z.string(),
                                    code: z.string(),
                                    unit_price: z.number(),
                                    in_stock: z.number()
                                }),
                                quantity: z.number()
                            })
                        )
                    })
                    .optional()
            })
            .superRefine((scheme, ctx) => {
                if (scheme.should_be_refilled && !scheme.quantity) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        params: {
                            i18n: "general.validation.global.required"
                        },
                        path: ["quantity"]
                    });
                }
                return scheme;
            })
            .superRefine((scheme, ctx) => {
                if (scheme.should_use_bom && !scheme.selected_bom) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        params: {
                            i18n: "sales.orders.mutations.creation.lowStockOffer.modals.refill.validation.noBOMSelected"
                        },
                        path: ["selected_bom"]
                    });
                }
                return scheme;
            })
    )
});

export type LowStockRefillSchemeTyping = z.infer<typeof lowStockRefillScheme>;
