import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "@reusables/Modals/BaseModal";
import { Alert, Stack } from "@mui/material";
import BaseInput from "@reusables/BaseInput";
import BaseButton from "@reusables/BaseButton";
import { z } from "zod";
import { Controller, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import { ProductSchemeTyping } from "@components/Dashboard/pages/Products/components/CreationEditingLayout/types";
import { stopPropagate } from "@helpers/utils";
import BaseInputsGrid from "@reusables/BaseInputsGrid";

interface SerialNumbersModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (data: FormTyping) => void;
}

const formScheme = z
    .object({
        in_stock: z.coerce.number(),
        serial_numbers: z.array(
            z.object({
                serial_number: z.string()
            })
        )
    })
    .refine((scheme) => scheme.serial_numbers.length <= scheme.in_stock, {
        params: {
            i18n: "products.newProduct.modals.serialNumbers.alerts.overflowSerialNumbers"
        },
        path: ["serial_numbers"]
    });

type FormTyping = z.infer<typeof formScheme>;

export default function SerialNumbersModal(props: SerialNumbersModalProps) {
    const { t } = useTranslation("", { keyPrefix: "products.newProduct.modals.serialNumbers" });

    const parentRHF = useFormContext<ProductSchemeTyping>();

    // Setting up RHF controller
    const {
        control,
        watch,
        formState: { errors },
        setValue,
        handleSubmit,
        getValues,
        reset
    } = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            in_stock: 0,
            serial_numbers: [{}]
        }
    });

    // Configuring fields array
    const {
        fields: serial_numbers,
        remove,
        append,
        replace
    } = useFieldArray({
        control,
        name: "serial_numbers"
    });

    // Determining serial numbers custom errors
    const serialNumbersError = useMemo(() => {
        if (errors.serial_numbers) {
            return errors.serial_numbers as { root?: { message: string } } & { message?: string };
        }
    }, [errors]);

    // Handling form submit
    const onSubmit = handleSubmit((data) => {
        props.onSubmit(data);
    });

    // Loading currently set serial numbers
    useEffect(() => {
        if (props.isOpen) {
            const items = parentRHF.getValues("serial_numbers");
            reset({
                in_stock: parentRHF.getValues("inventory.in_stock") ?? 0,
                serial_numbers: items.length ? parentRHF.getValues("serial_numbers") : [{}]
            });
        }
    }, [props.isOpen]);

    return (
        <BaseModal isOpen={props.isOpen} onClose={props.onClose} width={912} padding="56px">
            <form className="space-y-[32px]" onSubmit={stopPropagate(onSubmit)}>
                <div className="flex items-center mb-[32px]">
                    <div className="grow text-lg font-semibold text-accent">{t("heading")}</div>
                    {
                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            {/* Serials for the product left (limited by picked quantity) */}
                            <div className={`opacity-50 ${serial_numbers.length > getValues("in_stock") ? "text-[#E25C5C]" : ""}`}>
                                ({serial_numbers.length} / {getValues("in_stock")})
                            </div>
                            {/* Button to append serial to the product */}
                            <BaseMaterialButton
                                onClick={() => {
                                    append({} as { serial_number: string });
                                }}
                            >
                                {t("buttons.addSerialNumber")}
                            </BaseMaterialButton>
                        </Stack>
                    }
                </div>

                {/* Error, if the serial number exceeds the picked quantity */}
                {!!serialNumbersError?.root && (
                    <Alert severity="error" className="mb-[32px]">
                        {serialNumbersError.root?.message}
                    </Alert>
                )}

                <BaseInputsGrid
                    cols={2}
                    gap={{
                        x: 32,
                        y: 8
                    }}
                >
                    {serial_numbers.map((serial, serialIndex) => (
                        <Stack key={serial.id} direction={"row"} alignItems={"center"} spacing={1}>
                            {/* Serial number value */}
                            <Controller
                                control={control}
                                name={`serial_numbers.${serialIndex}.serial_number`}
                                render={({ field, fieldState }) => (
                                    <>
                                        <BaseInput
                                            {...field}
                                            error={fieldState.error}
                                            placeholder={t("fields.serialNumber.placeholder")}
                                            className="w-full"
                                            sx={{
                                                "&": {
                                                    input: {
                                                        background: "#F9F9F9",
                                                        padding: "12px",
                                                        border: 0
                                                    }
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            />

                            {/* Deletion button */}
                            {
                                <span>
                                    <DeleteSVG
                                        className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                        onClick={() => {
                                            remove(serialIndex);
                                        }}
                                    />
                                </span>
                            }
                        </Stack>
                    ))}
                </BaseInputsGrid>
                <div className="w-full">
                    <BaseButton text={t("buttons.save")} size="md" buttonWidth={"100%"} />
                </div>
            </form>
        </BaseModal>
    );
}
