import React, { useEffect, useState } from "react";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInputLegacy";
import BaseCheckbox from "@reusables/BaseCheckboxLegacy";
import Fade from "@mui/material/Fade/Fade";
import { useTranslation } from "react-i18next";
import { Adjustments } from "@/types/general";
import { addSign, getDifference } from "@components/Dashboard/pages/Inventory/Adjustments/logic";

type ViewLayoutProperties = {
    adjustment: Adjustments.Extended;
}

export default function ViewLayout({ adjustment }: ViewLayoutProperties) {
    const { t } = useTranslation("", { keyPrefix: "inventory.adjustments.createViewEditAdjustments" });
    const [selectedAdjustmentType, setSelectedAdjustmentType] = useState<number>(0);

    useEffect(() => {
        if (adjustment) {
            setSelectedAdjustmentType(adjustment.adjustment_type);
        }
    }, [adjustment]);

    return (
        <>
            <div className="space-y-[40px]">
                <div>
                    <div className="font-semibold text-inputs-label-dim mb-[24px]">
                        {t("fieldsGeneral.adjustment.label")}
                    </div>
                    <div className="flex space-x-[40px]">
                        {
                            !adjustment.product.is_service &&
                            <BaseCheckbox label={`${t("fieldsGeneral.adjustment.selectQuantity")}`}
                                          checked={selectedAdjustmentType === 0}
                                          disabled
                            />
                        }

                        <BaseCheckbox label={`${t("fieldsGeneral.adjustment.selectCostPrice")}`}
                                      checked={selectedAdjustmentType === 1}
                                      disabled
                        />
                    </div>
                </div>

                <BaseInputsGrid>
                    <InputLikeContainer
                        label={t("fieldsGeneral.product.label")}
                        content={adjustment.product.name}
                    />
                    {
                        !!adjustment.location &&
                        <InputLikeContainer
                            label={t("fieldsGeneral.location.label")}
                            content={
                                <>
                                    <span className="bold-highlight">{adjustment.location.store.name}</span>
                                    {adjustment.location.section ? ` - ${adjustment.location.section.name}` : ""}
                                </>
                            }
                        />
                    }
                    <BaseInput
                        label={t("fieldsGeneral.date.label")}
                        value={adjustment.date.format("YYYY-MM-DD")}
                        disabled
                    />
                </BaseInputsGrid>

                <Fade in={selectedAdjustmentType === 0}>
                    <div className={selectedAdjustmentType === 0 ? "block" : "hidden"}>
                        <BaseInputsGrid>
                            <BaseInput
                                label={t("fieldsCreateQuantityAdjustment.availableQuantity.label")}
                                value={adjustment.quantity_old?.toFixed(2)}
                                disabled
                                inputClasses={() => "text-inputs-textGrayscale"}
                            />

                            <InputLikeContainer
                                label={t("fieldsCreateQuantityAdjustment.actualQuantity.label")}
                                content={adjustment.quantity_actual?.toFixed(2) ?? ""}
                            />

                            <BaseInput
                                label={t("fieldsCreateQuantityAdjustment.availableQuantityDifference.label")}
                                value={addSign(getDifference(adjustment.quantity_old, adjustment.quantity_actual))}
                                disabled
                                inputClasses={() => "text-inputs-textGrayscale"}
                            />
                        </BaseInputsGrid>
                    </div>
                </Fade>

                <Fade in={selectedAdjustmentType === 1}>
                    <div className={selectedAdjustmentType === 1 ? "block" : "hidden"}>
                        <BaseInputsGrid>
                            <BaseInput
                                label={t("fieldsCreateCostPriceAdjustment.costPrice.label")}
                                value={adjustment.cost_price_old?.toFixed(2)}
                                disabled
                                inputClasses={() => "text-inputs-textGrayscale"}
                            />

                            <InputLikeContainer
                                label={t("fieldsCreateCostPriceAdjustment.actualCostPrice.label")}
                                content={adjustment.cost_price_actual?.toFixed(2) ?? ""}
                            />

                            <BaseInput
                                label={t("fieldsCreateCostPriceAdjustment.actualCostPriceDifference.label")}
                                value={addSign(getDifference(adjustment.cost_price_old, adjustment.cost_price_actual))}
                                disabled
                                inputClasses={() => "text-inputs-textGrayscale"}
                            />
                        </BaseInputsGrid>
                    </div>
                </Fade>

                {
                    !!adjustment.updated_selling_price &&
                    <BaseInputsGrid>
                        <BaseInput
                            label={t("fieldsGeneral.sellingPriceChange.label")}
                            value={adjustment.updated_selling_price.toString()}
                            disabled
                        />
                    </BaseInputsGrid>
                }

                <div className="min-h-[115px]">
                    <InputLikeContainer
                        label={t("fieldsGeneral.remarks.label")}
                        content={adjustment.remarks}
                    />
                </div>
            </div>
        </>
    );
}


function InputLikeContainer({ label, content }: { label: string, content: JSX.Element | string | undefined }) {
    return (
        <div>
            <div className="font-semibold text-inputs-label-dim mb-[24px]">{label}</div>
            <div className="text-accent">
                {content}
            </div>
        </div>
    );
}