enum ExportProgressStatus {
    IDLE = "idle",
    ACTIVE = "active",
    FINISHED = "finished",
}

interface ExportStateBase {
    status: ExportProgressStatus;
    module?: string;
}

interface ExportStateIdle extends ExportStateBase {
    status: ExportProgressStatus.IDLE;
}

// TODO: add support for progress tracking, the same way as during import
// currently (20.05.2024) the progress is not tracked during export
interface ExportStateActive extends ExportStateBase {
    status: ExportProgressStatus.ACTIVE;
}

interface ExportStateFinished extends ExportStateBase {
    status: ExportProgressStatus.FINISHED;
}

type ExportState = ExportStateIdle | ExportStateActive | ExportStateFinished;

function isExportStateActive(state: ExportState): state is ExportStateActive {
    return state.status === ExportProgressStatus.ACTIVE;
}

function isExportStateFinished(state: ExportState): state is ExportStateFinished {
    return state.status === ExportProgressStatus.FINISHED;
}


export { isExportStateActive, isExportStateFinished, ExportProgressStatus };
export type { ExportState, ExportStateActive, ExportStateFinished };