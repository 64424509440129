import React from 'react'
import BaseTable from '@reusables/BaseTable';
import {useOrdering, usePagination} from '@helpers/utils';
import {useGetModuleReceiptsFullQuery} from '@redux/features/receipts/receiptsApi';
import {Receive, Supplier} from '@/types/general'
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Pagination} from "@mui/material";
import { Can } from '@/casl.config';

export default function ReceivesTab({supplier}: {supplier: Supplier.Extended}) {
    const {t} = useTranslation("", {keyPrefix: "suppliers.general.tabs.receives"});

    const history = useHistory();

    const filters = {
        suppliers: [supplier.id],
    }

    const {orderBy, setOrderBy} = useOrdering<Receive.DTO.OrderBy>({"name": "id", "type": "desc"});

    const pagination = usePagination({ page: 1, limit: 8 })

    const {data: receives, isLoading: receivesLoading} = useGetModuleReceiptsFullQuery({
        filters,
        orderBy,
        pagination,
        module: "supplier"
    })
        
    return (
        <>
            {/* <Can I="receive.view" a="supplier"> */}
                <BaseTable
                    data={receives?.payload ?? []}
                    isDataLoading={receivesLoading}

                    columns={[
                        {
                            header: t("columns.0"),
                            getter: row => <u className='cursor-pointer' onClick={() => history.push(`/dashboard/receive/${row.id}/details`)}>{row.code ?? row.id}</u>
                        },

                        {
                            header: t("columns.1"),
                            getter: row => <u className="cursor-pointer" onClick={() => history.push(`/dashboard/purchasing/${row.purchase_order.id}/details`)}>{row.purchase_order.code}</u>
                        },
                        {
                            header: t("columns.2"),
                            getter: row => row.date.format("DD.MM.YYYY"),
                        },
                    ]}
                />
                <Pagination
                    className="mt-[32px]"
                    {...pagination.adapt(receives)}
                />
            {/* </Can> */}
        </>
    )
}
