import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseButton from "@reusables/BaseButton";
import { FormProvider, useController, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import JsBarcode from "jsbarcode";
import barcodeStandards from "./barcodeStandards";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { Alert, Collapse } from "@mui/material";
import { centerSVGElement, toastError } from "@helpers/utils";

type BarcodeLabelModalProps = {
    product?: {
        name: string;
        code: string;
        barcode?: string;
    },
    isOpen: boolean;
    onClose: () => void;
}

const barcodeFormScheme = z.object({
    barcode: z.string(),
    standard: z.string()
});

type BarcodeFormTyping = z.infer<typeof barcodeFormScheme>;

export default function BarcodeLabelModal(props: BarcodeLabelModalProps) {
    const { t } = useTranslation("", { keyPrefix: "products.allProducts.modals.barcodeLabel" });

    const form = useForm<BarcodeFormTyping>({
        resolver: zodResolver(barcodeFormScheme)
    });

    const barcodeSvgRef = useRef<SVGSVGElement>(null);

    const barcode = useWatch({
        name: "barcode",
        control: form.control
    });

    const { field: standard, fieldState: standardState } = useController({
        control: form.control,
        name: "standard"
    });

    useEffect(() => {
        if (barcodeSvgRef.current) {
            // ======================================================================= //
            // === Generating barcode using JsBarcode === //
            // ======================================================================= //

            JsBarcode(barcodeSvgRef.current, barcode, {
                format: standard.value,
                width: 1.5,
                height: 40,
                fontSize: 12,
                displayValue: true,
                valid: valid => {
                    if (!valid) {
                        form.setError("standard", {
                            type: "invalid",
                            message: t("errors.invalidStandard")
                        });
                    } else {
                        form.clearErrors("standard");
                    }
                }
            });

            // ======================================================================= //
            // === Modifying the SVG to fit product name and code === //
            // ======================================================================= //

            const innerSvg = barcodeSvgRef.current;

            // Setting viewBox and size to match 78x28mm (assuming 96 DPI)
            const width = 293; // 78mm * 96px/in * 1in/25.4mm
            const height = 100; // 28mm * 96px/in * 1in/25.4mm - 5px
            innerSvg.setAttribute("viewBox", `0 0 ${width} ${height}`);
            innerSvg.setAttribute("width", `${width}`);
            innerSvg.setAttribute("height", `${height}`);

            // Centering the barcode in the new viewBox
            const barcodeGroup = innerSvg.querySelector("g");
            if (barcodeGroup) {
                centerSVGElement(innerSvg, barcodeGroup, { x: 0, y: 40 });
            }

            if (props.product?.name && props.product?.code) {
                const { name, code } = props.product;

                // Adding product name
                const nameText = document.createElementNS("http://www.w3.org/2000/svg", "text");
                const slicingLength = Math.floor(width / 10);
                nameText.textContent = name.slice(0, slicingLength) + (name.length > slicingLength ? "..." : "");
                nameText.setAttribute("text-anchor", "middle");
                nameText.setAttribute("font-family", "Arial");
                nameText.setAttribute("font-size", "12");
                innerSvg.appendChild(nameText);
                centerSVGElement(innerSvg, nameText, { x: 0, y: 15 });

                // Adding product code
                const codeText = document.createElementNS("http://www.w3.org/2000/svg", "text");
                codeText.textContent = code;
                codeText.setAttribute("text-anchor", "middle");
                codeText.setAttribute("font-family", "Arial");
                codeText.setAttribute("font-size", "10");
                innerSvg.appendChild(codeText);
                centerSVGElement(innerSvg, codeText, { x: 0, y: 30 });
            }
        }
    }, [barcodeSvgRef, barcode, standard.value, props.product]);

    const onSubmit = form.handleSubmit((data) => {
        // Latest barcode standard is stored in local storage (remembered across sessions)
        localStorage.setItem("latest_barcode_standard", data.standard);

        const printWindow = window.open("", "_blank", "width=600,height=600");
        if (barcodeSvgRef.current?.innerHTML) {
            printWindow?.document.write(`
                <!DOCTYPE html>
                <html>
                    <head>
                        <title>Suppli - [${props.product?.name}] label printing</title>
                        <style>
                            @page {
                                size: 78mm 28mm;
                                margin: 0;
                            }
                            body {
                                margin: 0;
                                padding: 0;
                                width: 78mm;
                                height: 28mm;
                            }
                            svg {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        </style>
                    </head>
                    <body>
                        <div class="label-container">
                            ${barcodeSvgRef.current?.outerHTML}
                        </div>
                        <script>
                            window.onload = function() {
                                setTimeout(function() {
                                    window.print();
                                    window.onafterprint = function() {
                                        window.close();
                                    };
                                }, 500);
                            };
                        </script>
                    </body>
                </html>
            `);

            printWindow?.document.close();
        } else {
            toastError(Error("Barcode is not generated"));
            console.error("Unable to extract SVG for barcode generation!");
        }
    }, console.error);

    useEffect(() => {
        if (props.isOpen) {
            form.reset();

            form.setValue("standard", localStorage.getItem("latest_barcode_standard") ?? "EAN13");

            if (props.product) {
                form.setValue("barcode", props.product.barcode || props.product.code);
            }
        }
    }, [props.isOpen, props.product]);

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
        >
            <FormProvider {...form}>
                <form
                    onSubmit={onSubmit}
                >
                    <div className="text-xl font-semibold text-center text-accent mb-[32px]">{t("heading")}</div>
                    <Collapse in={standardState.invalid}>
                        <Alert severity={"error"} className={"mb-[24px]"}>
                            {standardState.error?.message}
                        </Alert>
                    </Collapse>
                    <Collapse in={!standardState.invalid}>
                        <div className={"text-center mb-[32px]"}>
                            <svg ref={barcodeSvgRef} />
                        </div>
                    </Collapse>
                    <div className={"mb-[32px]"}>
                        <BaseDropdown
                            {...standard}
                            {...standardState}

                            value={barcodeStandards.find(s => s.key === standard.value)}
                            onChange={(option) => standard.onChange(option?.key)}

                            label={t("fields.standard.label")}
                            options={barcodeStandards}

                            getter={{
                                key: (option) => option.key,
                                label: (option) => option.label
                            }}

                            disableClearable
                            autocomplete
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-[16px]">
                        <BaseButton
                            text={t("buttons.cancel")}
                            size="md"
                            primaryOutlined
                            onClick={props.onClose}
                            type="button"
                        />
                        <BaseButton
                            text={t("buttons.print")}
                            disabled={standardState.invalid}
                            type="submit"
                            size="md"
                        />
                    </div>
                </form>
            </FormProvider>
        </BaseModal>
    );
}