import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { FileUp, XCircle } from "lucide-react";
import { useEchoEffect } from "@helpers/utils";
import {
    extractDataFlowProcess,
    useGetCurrentDataFlowProcessQuery
} from "@redux/features/importExport/importExportApi";
import { Imports } from "@/types/general";
import {
    isImportStateActive,
    isImportStateFinished,
    selectProgressMean
} from "@redux/features/importExport/import/types";
import {
    finishImport,
    resetImport,
    startImport,
    updateImportProgress
} from "@redux/features/importExport/import/importSlice";
import { useTranslation } from "react-i18next";
import BaseModal from "@reusables/Modals/BaseModal";
import { ReactComponent as SuccessSVG } from "@assets/icons/ic_success.svg";
import { invalidateModuleCache } from "../cacheUtils";
import { BaseCircularProgressWithContent } from "@reusables/BaseCircularProgressWithContent";


export default function ImportIndicator() {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const company = useAppSelector(state => state.auth.user?.company);

    const { data: currentDataFlowProcess } = useGetCurrentDataFlowProcessQuery();
    const currentImport = extractDataFlowProcess(currentDataFlowProcess, "import");


    const importState = useAppSelector(state => state.import.value);

    useEffect(() => {
        if (currentImport?.module) {
            dispatch(startImport({
                module: currentImport.module
            }));
        }
    }, [currentImport]);

    useEchoEffect<Imports.ImportStarted>(
        `imports.${company?.id ?? -1}`,
        "ImportStarted",
        (startData) => {
            dispatch(startImport({ module: startData.module }));
        },
        undefined,
        [company]
    );

    useEchoEffect<Imports.ImportProgressUpdate>(
        `imports.${company?.id ?? -1}`,
        "ImportProgressUpdate",
        (updateData) => {
            dispatch(updateImportProgress({
                current: updateData.current,
                total: updateData.total
            }));
        },
        undefined,
        [company]
    );

    useEchoEffect<Imports.ImportFinished>(
        `imports.${company?.id ?? -1}`,
        "ImportFinished",
        (finishData) => {
            dispatch(finishImport({
                has_errors: !finishData.success,
                data: finishData.data
            }));
            finishData.success && invalidateModuleCache(finishData.module, dispatch);
        },
        undefined,
        [company]
    );

    const [isResultModalOpen, setIsResultModalOpen] = useState(false);

    return (
        <>
            {
                isImportStateActive(importState) && (
                    <BaseCircularProgressWithContent
                        size={26}
                        {
                            ...importState.progress && {
                                // Adding the percentage value to the progress bar, if progress was specified
                                value: selectProgressMean(importState) * 100,
                                variant: "indeterminate"
                            }
                        }

                        tooltip={t(`imports.modules.${importState.module}.states.inProgress`)}
                    >
                        <FileUp size={12} />
                    </BaseCircularProgressWithContent>
                )
            }
            {
                isImportStateFinished(importState) && (
                    <>
                        <BaseCircularProgressWithContent
                            size={26}
                            variant="determinate"
                            value={100}

                            sx={{
                                color: importState.has_errors ? "#E25C5C" : "#A6DD4C"
                            }}

                            onClick={() => setIsResultModalOpen(true)}
                        >
                            <FileUp size={12} />
                        </BaseCircularProgressWithContent>

                        {
                            importState.has_errors ? (
                                <BaseModal
                                    isOpen={isResultModalOpen}
                                    onClose={() => {
                                        dispatch(resetImport());
                                        setIsResultModalOpen(false);
                                    }}
                                    padding="40px"
                                    position="center"
                                >
                                    <div className="flex items-center gap-4">
                                        <XCircle width={40} height={40} fill="#FF0000" color="#FFF" />
                                        <div className="flex flex-col items-start space-y-4">
                                            <div className="text-accent font-bold text-xl text-center">
                                                {t(`imports.modules.${importState.module}.states.error.title`)}
                                            </div>
                                            <div
                                                className="text-accent text-lg">{t(`imports.modules.${importState.module}.states.error.message`)}</div>
                                        </div>

                                    </div>
                                </BaseModal>
                            ) : (
                                <BaseModal
                                    isOpen={isResultModalOpen}
                                    onClose={() => {
                                        dispatch(resetImport());
                                        setIsResultModalOpen(false);
                                    }}
                                    padding="40px"
                                    position="center"
                                >
                                    <div className="flex items-center gap-4">
                                        <SuccessSVG width={40} height={40} />
                                        <div
                                            className="text-accent font-bold text-xl text-center">{t(`imports.modules.${importState.module}.states.imported`)}</div>
                                    </div>
                                </BaseModal>
                            )
                        }
                    </>
                )
            }
        </>
    );
}

