import React, { useState } from "react";
import "@assets/stylesheets/_faq.scss";
import NavigationBar from "../NavigationBar";
import WaveImg from "@assets/images/waveIcon.svg";

import IcPlus from "@assets/images/plus.svg";
import IcMinus from "@assets/images/minus.svg";
import FAQImg from "@assets/images/logo-faq.svg";
import FooterBlock from "../FooterBlock";
import SupportImg from "@assets/images/support-contact-image.png"

import { useTranslation } from "react-i18next";
import BaseModal from "@reusables/Modals/BaseModal";

import { ReactComponent as SubscribeBanner } from "@assets/images/subscribe.svg"
import SubscribeSubmitted from "@assets/images/subscribe-submitted.svg"

import { toast } from "react-toastify"

import { subscribe } from "@helpers/subscribers"

type Question = {
  question: string;
  answer: string;
  open?: boolean;
};

const QuestionItem = ({ question, answer, open }: Question) => {
  const [show, setShow] = useState<boolean>(open || false);

  const toggle = () => setShow((prev) => !prev);
  const imgSrc = show ? IcMinus : IcPlus;

  return (
    <div>
      <div className="flex justify-between">
        <div className="text-[#2B2A35] text-xl font-semibold leading-[1.4]">
          {question}
        </div>
        <div className="cursor-pointer" onClick={toggle}>
          <img src={imgSrc} />
        </div>
      </div>
      {show && (
        <div className="pt-2 tablet:pt-4 max-w-[503px] text-base text-[#686868] leading-[2.05]">
          {answer}
        </div>
      )}
    </div>
  );
};
const SupportPage = () => {
  const { t } = useTranslation();

  const [isOpen, openModal] = useState<boolean>(false);
  const [contacted, setContacted] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [submitted, setSubmit] = useState<boolean>(false);

  const questions = [
    {
      question: t("supportPage.questionsBlock.1.q"),
      answer: t("supportPage.questionsBlock.1.a"),
      open: true,
    },
    {
      question: t("supportPage.questionsBlock.2.q"),
      answer: t("supportPage.questionsBlock.2.a"),
    },
    {
      question: t("supportPage.questionsBlock.3.q"),
      answer: t("supportPage.questionsBlock.3.a"),
    },
    {
      question: t("supportPage.questionsBlock.4.q"),
      answer: t("supportPage.questionsBlock.4.a"),
    },
    {
      question: t("supportPage.questionsBlock.5.q"),
      answer: t("supportPage.questionsBlock.5.a"),
    },
    {
      question: t("supportPage.questionsBlock.6.q"),
      answer: t("supportPage.questionsBlock.6.a"),
    },
    {
      question: t("supportPage.questionsBlock.7.q"),
      answer: t("supportPage.questionsBlock.7.a"),
    },
  ];
  return (
    <>
      <NavigationBar />
      <main className="main">
        <BaseModal
          isOpen={isOpen}
          onClose={() => {
            openModal(false)
            setSubmit(false)
          }}
          width={submitted ? 1096 : 852}
          height={submitted ? 439 : 493}
          padding="56px"
          useCloseIcon
          sx={{
            ".MuiDialogContent-root " : {
              backgroundColor: submitted ? "#8850F4" : "#fff"
            }
          }}
        >
          {submitted === false ?
            <div className="w-max mx-auto">
              <div className="text-center mr-auto ml-auto">
                <div className="mx-auto mb-[32px]">
                  <SubscribeBanner />
                </div>
                <p className="ml-[52px] w-[600px] text-center mb-[32px]">{t("supportPage.subscribeText")}</p>
              </div>
              <div className="tablet:flex tablet:space-x-4">
                <div className="grid">
                  <label className="text-[#686868] font-[600] text-[16px] leading-[24px] mb-[8px]">Email</label>
                  <input
                    className="max-tablet:mb-4 border-none bg-[#F9F9F9] rounded-lg tablet:min-w-[500px] tablet:min-h-[56px] px-[16px] py-[8px]"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <button onClick={() => {
                  if (/\S+@\S+\.\S+/.test(email) === false) toast.error(`Invalid e-mail address!`)
                  else {
                    subscribe(email)
                    setSubmit(true)
                    setEmail("")
                  }
                }} className="btn btn-filled min-w-[200px] rounded-lg mt-[32px]">
                  {t("supportPage.subscribeBlock.notifyBtn")}
                </button>
              </div>
            </div> :
            <div className="h-[327px] w-min rounded-xl"><div className="min-h-[100%] min-w-[100%] flex my-auto">
              <img src={SubscribeSubmitted} />
              <div className="ml-[80px] text-[#FFFFFF] my-auto">
                <h1 className="font-[700] text-[44px] leading-[55.88px] text-[#FFFFFF] mr-auto ml-auto w-[559px] mb-[24px]">You're One Step Closer to WMS Excellence!</h1>
                <p className="font-[400] text-[16px] leading-[32.8px] w-[528px]">Congratulations on taking the first step towards warehouse management success. Keep an eye on your inbox within the next <b>3 working days</b> for your free trial details.</p>
                <div className="flex gap-x-[24px] mt-[24px]">
                  <button className="w-[228px] h-[52px] rounded-lg border-none font-[600] text-[16px] leading-[24px] bg-[#C8E98E] text-[#3C3769]" onClick={() => window.location.href = "/news"}>Request a Free Demo</button>
                  <button className="w-[228px] h-[52px] rounded-lg border-[#C8E98E] border-1 border-solid shadow-none bg-transparent text-[#C8E98E] font-[600] text-[16px] leading-[24px]" onClick={() => window.location.href = "/news"}>Explore the Blog</button>
                </div>
              </div>
            </div>
            </div>}
        </BaseModal>
        <BaseModal
          isOpen={contacted}
          onClose={() => {
            setContacted(false)
            setSubmit(false)
          }}
          width={1096}
          height={439}
          padding="56px"
          useCloseIcon
          sx={{
            "& .MuiDialogContent-root" : {
                backgroundColor: "#8850F4"
            }
          }}
        >
          <div className="h-[327px] w-min rounded-xl"><div className="min-h-[100%] min-w-[100%] flex my-auto">
            <img src={SubscribeSubmitted} />
            <div className="ml-[80px] text-[#FFFFFF] my-auto">
              <h1 className="font-[700] text-[44px] leading-[55.88px] text-[#FFFFFF] w-[409px] mb-[24px]">Your Success is Our Priority!</h1>
              <p className="font-[400] text-[16px] leading-[32.8px] w-[528px]">We appreciate you contacting Suppli Support. Rest assured, our dedicated team is ready to help you overcome any obstacles you are facing. We will get in touch within 3 working days.</p>
              <div className="flex gap-x-[24px] mt-[24px]">
                <button className="w-[228px] h-[52px] rounded-lg border-none font-[600] text-[16px] leading-[24px] bg-[#C8E98E] text-[#3C3769]" onClick={() => window.location.href = "/news"}>Discover Solutions</button>
                <button className="w-[228px] h-[52px] rounded-lg border-[#C8E98E] border-1 border-solid shadow-none bg-transparent text-[#C8E98E] font-[600] text-[16px] leading-[24px]" onClick={() => window.location.href = "/news"}>Explore the Blog</button>
              </div>
            </div>
          </div>
          </div></BaseModal>
        <div className="section faq-section">
          <div className="container-lg space-y-[100px]">
            {/* Header */}
            <div className="tablet:flex tablet:min-h-[416px] tablet:items-center max-tablet:space-y-8">
              <div className="tablet:min-w-[567px] space-y-4 tablet:mr-[187px]">
                <div className="text-lg text-[#8850F4] tracking-wider uppercase">
                  {t("supportPage.faqBlock.preTitle")}
                </div>
                <div className="flex justify-between">
                  <div className="text-4xl tablet:text-[56px] text-[#2B2A35] leading-[1.15] font-bold">
                    {" "}
                    {t("supportPage.faqBlock.title")}
                  </div>
                  <img className="max-w-[78px]" src={WaveImg} />
                </div>
                <div className="text-[#686868] text-base leading-[2.05] max-w-[539px]">
                  {t("supportPage.faqBlock.desc")}
                </div>
              </div>
              <img className="max-tablet:w-full" src={FAQImg} alt="Suppli" />
            </div>

            {/* FAQ List */}
            <div className="tablet:flex tablet:justify-between">
              <div className="max-tablet:mb-8 tablet:max-w-[559px]">
                <div className="text-xl text-[#2B2A35] font-semibold leading-[1.4] mb-4">
                  {t("supportPage.faqBlock.article1.boldTitle")}
                </div>
                <p className="text-base text-[#686868] mb-4 leading-[2.05]">
                  {t("supportPage.faqBlock.article1.desc1")}
                </p>
                <p className="text-base text-[#686868] leading-[2.05]">
                  {t("supportPage.faqBlock.article1.desc2")}
                </p>
                <p className="text-base text-[#686868] leading-[2.05]">
                  {t("supportPage.faqBlock.article1.desc3")}
                </p>
              </div>

              <div className="space-y-8 tablet:min-w-[669px]">
                {questions.map((item, i) => (
                  <QuestionItem key={i} {...item} />
                ))}
              </div>
            </div>
            <div className="grid grid-cols-2 my-[160px] margin-important-form">
              <form className="space-y-6">
                <h1 className="text-[56px] font-[700] leading-[64.4px]">{t("supportPage.contactBlock.title")}</h1>
                <p className="text-[16px] font-[400] leading-[32.8px]">{t("supportPage.contactBlock.subTitle")}</p>
                <div><label className="block text-[16px] font-[600] leading-[24px] mb-[8px]">Name</label>
                  <input
                    className="max-tablet:mb-4 border-none bg-[#F9F9F9] tablet:min-w-[539px] tablet:min-h-[56px] p-[16px] rounded-lg"
                    type="text"
                    name="email"
                  /></div>
                <div><label className="block text-[16px] font-[600] leading-[24px] mb-[8px]">Email</label>
                  <input
                    className="max-tablet:mb-4 border-none bg-[#F9F9F9] tablet:min-w-[539px] tablet:min-h-[56px] p-[16px] rounded-lg"
                    type="text"
                    name="email"
                  /></div>
                <div><label className="block text-[16px] font-[600] leading-[24px] mb-[8px]">Message</label>
                  <textarea className="max-tablet:mb-4 border-none bg-[#F9F9F9] tablet:min-w-[539px] tablet:min-h-[168px] p-[16px] rounded-lg" />
                </div>
                <button type="submit" className="btn btn-filled w-[539px] text-[16px] font-[600] leading-[24px]" onClick={(e) => {
                  e.preventDefault()
                  setContacted(true)
                }}>
                  {t("supportPage.subscribeBlock.sendMessage")}
                </button>
              </form>
              <img src={SupportImg} className="max-w-[750px] max-h-[500px] ml-auto mt-auto mb-auto" />
            </div>
            {/* Subscribe */}
            <div className="tablet:flex tablet:justify-between tablet:items-center">
              <div className=" tablet:max-w-[559px] space-y-4 mb-[40px]">
                <div className="text-lg text-[#8850F4] tracking-wider uppercase">
                  {t("supportPage.subscribeBlock.preTitle")}
                </div>
                <div className="text-4xl tablet:text-[56px] font-[700] text-[#2B2A35] leading-[64.4px]">
                  {t("supportPage.subscribeBlock.title")}
                </div>

              </div>

              <div className="tablet:min-w-[750px] space-y-4 space-x-[96px] flex">
                <div className="text-base text-[#2B2A35] leading-[2.05] text-left my-auto">
                  {t("supportPage.subscribeBlock.subscribeTextRight1")}{" "}
                  <p className="mb-auto mt-auto">
                    {t("supportPage.subscribeBlock.subscribeTextRight2")}
                  </p>
                </div>
                <div className="tablet:flex tablet:space-x-4 mb-auto mt-auto">
                  {/* <input
                    className="max-tablet:mb-4 border-none bg-[#F9F9F9] tablet:min-w-[448px] tablet:min-h-[56px] p-[16px] rounded-lg"
                    type="text"
                    name="email"
                  /> */}
                  <button onClick={() => openModal(true)} className="btn btn-filled min-w-[250px] min-h-[56px] mr-auto ml-auto">
                    {t("supportPage.subscribeBlock.subscribeBtn")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <FooterBlock />
    </>
  );
};

export default SupportPage;
