import dayjs, { Dayjs } from "dayjs";
import { z } from "zod";
import { refinements } from "@helpers/refinements";
import i18n from "@/i18n";
import { withCustomFields } from "@reusables/BaseCustomFieldsSection/logic";

const ORDER_PRICE_PRECISION = import.meta.env.VITE_ORDER_PRICE_PRECISION;

const validDateRefinement = refinements.validDate;
const maxDecimalPrecisionRefinement = refinements.maxDecimalPrecision(ORDER_PRICE_PRECISION);
const boundedByRefinement = refinements.boundedBy(0, 100);

const lineDataSchema = z.object({
    id: z.number().optional(),
    product: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string()
    }),
    quantity: z.coerce.number().positive(),
    unit_price: z.object({
        converted: z.coerce.number()
            .refine(refinements.positive.refine, refinements.positive.message)
            .refine(maxDecimalPrecisionRefinement.refine, maxDecimalPrecisionRefinement.message),
        original: z.number()
    }),
    discount: z.coerce.number().refine(boundedByRefinement.refine, boundedByRefinement.message).optional(),
    tax: z.object({
        id: z.number(),
        code: z.string().nullish(),
        rate: z.number()
    }).nullish()
});

const linesSchema = z.object({
    type: z.union([z.literal("line"), z.literal("group")]),
    id: z.number().optional(),
    name: z.string().optional(),
    module: z.string().optional(),
    group_id: z.number().optional(),
    key: z.string().optional(),
    data: lineDataSchema.optional()
});

export const purchaseOrdersScheme = withCustomFields(
    z.object({
        mode: z.union([z.literal("creation"), z.literal("editing")]),
        is_editable: z.boolean(),

        reference_number: z.object({
            id: z.number().nullish(),
            code: z.string().trim().nullish()
        }),

        supplier: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string().nullish()
        }),
        purchase_date: z.instanceof(dayjs as unknown as typeof Dayjs).refine(validDateRefinement.refine, validDateRefinement.message),
        preferred_delivery_date: z.instanceof(dayjs as unknown as typeof Dayjs).refine(validDateRefinement.refine, validDateRefinement.message).nullish(),

        our_reference: z.object({
            id: z.number(),
            name: z.string()
        }).nullish(),
        their_reference: z.object({
            id: z.number(),
            name: z.string()
        }).nullish(),
        payment_terms: z.object({
            id: z.number(),
            name: z.string()
        }).nullish(),
        delivery_terms: z.object({
            id: z.number(),
            name: z.string()
        }).nullish(),

        currency: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string(),
            rate: z.number(),
            symbol: z.string(),
            isBase: z.boolean(),
            isFixed: z.boolean()
        }),

        language: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string()
        }).nullish(),

        exchange_rate: z.number().positive(),

        billing_address: z.object({
            name: z.string(),
            street: z.string(),
            city: z.string(),
            country: z.object({
                id: z.number(),
                name: z.string(),
                code: z.string()
            })
        }),

        delivery_address: z.object({
            id: z.number(),
            name: z.string(),
            street: z.string(),
            city: z.string(),
            country: z.object({
                id: z.number(),
                name: z.string(),
                code: z.string()
            })
        }).nullish(),

        is_billing_for_delivery: z.boolean(),

        is_split_by_products: z.boolean(),
        shipping_cost: z.coerce.number().optional(),

        lines: z.array(linesSchema).min(1, {
            message: i18n.t("general.validation.global.noProductsAdded")
        }),

        deleted_lines: z.array(z.number().nullish()).nullish()
    }).superRefine((data, ctx) => {
        if (!data.is_billing_for_delivery && !data.delivery_address) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                params: {
                    i18n: "general.validation.global.required"
                },
                path: ["delivery_address"]
            });
        }
    })
);

export type PurchaseOrdersFormTyping = z.infer<typeof purchaseOrdersScheme>;
