import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { Exports, Imports } from "@/types/general";
import _ from "lodash";

type DataFlowProcess = {
    operation: "import" | "export",
    module: string;
}

export const importExportApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCurrentDataFlowProcess: builder.query<DataFlowProcess, void>({
            query: () => ({
                url: `data-transfer/current`,
                method: "GET"
            }),
            transformResponse
        }),

        getExportableColumns: builder.query<Exports.Components.Column[], { module: Exports.Components.Modules }>({
            query: ({ module }) => ({
                url: `data-transfer/export/fields/${module}`,
                method: "GET"
            }),
            transformResponse: (response: { payload: Record<string, string> }) => {
                return _.values(
                    _.mapValues(response.payload, (value, key) => ({
                            key,
                            title: value
                        })
                    )
                );
            }
        }),

        getImportableColumns: builder.query<Imports.Components.Column[], { module: Imports.Components.Modules }>({
            query: ({ module }) => ({
                url: `data-transfer/import/fields/${module}`,
                method: "GET"
            }),
            transformResponse: (response: {
                payload: Record<string, { title: string, required: boolean, is_code: boolean }>
            }) => {
                return _.map(response.payload, (value, key) => ({
                    key,
                    title: value.title,
                    required: value.required,
                    is_code: value.is_code
                }));
            }
        })
    })
});

export const extractDataFlowProcess = (data: DataFlowProcess | undefined, operation: DataFlowProcess["operation"]) => {
    if (data && data.operation === operation) {
        return {
            module: data.module
        };
    }
};

export const {
    useGetCurrentDataFlowProcessQuery,
    useGetExportableColumnsQuery,
    useGetImportableColumnsQuery
} = importExportApi;