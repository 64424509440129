import React, { useEffect, useState } from "react";
import BaseTable from "@reusables/BaseTable";
import { useGetProductTranslationsQuery } from "@redux/features/products/productsApi";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import DeletionModal from "./components/DeletionModal";
import { Product } from "@/types/general";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import { PlusIcon } from "lucide-react";
import MutationModal from "./components/MutationModal";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import { createPagination } from "@helpers/utils";
import BasePagination from "@reusables/BasePagination";
import { useTranslation } from "react-i18next";

interface TranslationsTabProperties {
    product: Product.Extended;
}
export default function TranslationsTab(props: TranslationsTabProperties) {
    const { t } = useTranslation("", {
        keyPrefix: "products.viewEditProduct.tabs.translations"
    });

    const { data: translationsResponse, isLoading: isTranslationsResponseLoading } = useGetProductTranslationsQuery({ id: props.product.id });

    const [selectedTranslationToDelete, setSelectedTranslationToDelete] = useState<Product.Augmentations.Translation.Root>();

    const [isMutationModalOpen, setIsMutationModalOpen] = useState<boolean>(false);
    const [selectedTranslationToMutate, setSelectedTranslationToMutate] = useState<Product.Augmentations.Translation.Root>();

    const pagination = createPagination();

    useEffect(() => {
        pagination.perPage.setElementsPerPage(5);
    }, []);

    useEffect(() => {
        pagination.calculate(translationsResponse ?? []);
    }, [translationsResponse]);

    return (
        <>
            {(translationsResponse ?? []).length === 0 && !isTranslationsResponseLoading ? (
                <div className="flex items-center justify-center h-[500px] border border-gray-500 rounded-lg ">
                    <BaseMaterialButton
                        size="large"
                        onClick={() => {
                            setIsMutationModalOpen(true);
                            setSelectedTranslationToMutate(undefined);
                        }}
                    >
                        {t("table.buttons.addLanguage")}
                    </BaseMaterialButton>
                </div>
            ) : (
                <BaseTable
                    data={translationsResponse ?? []}
                    pagination={pagination}
                    isDataLoading={isTranslationsResponseLoading}
                    columns={[
                        {
                            header: t("table.columns.0"),
                            getter: (row) => <>{row.language.name}</>,
                            comparator: (a, b) => a.language.name.localeCompare(b.language.name)
                        },
                        {
                            header: t("table.columns.1"),
                            getter: (row) => <>{row.name}</>,
                            comparator: (a, b) => a.name.localeCompare(b.name)
                        },
                        {
                            header: t("table.columns.2"),
                            getter: (row) => <div className="w-[300px] whitespace-nowrap overflow-hidden overflow-ellipsis">{row.description}</div>,
                            comparator: (a, b) => a.description.localeCompare(b.description),
                        },
                        {
                            header: t("table.columns.3"),
                            getter: (row, index, isCollapsed) => (
                                <div className="text-center space-x-[12px]">
                                    <EditSVG
                                        className={`${isCollapsed ? "text-tables-highlightedIcon" : "text-tables-secondaryIcon"} hover:text-tables-highlightedIcon cursor-pointer`}
                                        onClick={() => {
                                            setSelectedTranslationToMutate(row);
                                            setIsMutationModalOpen(true);
                                        }}
                                    />

                                    <TrashSVG
                                        className={`${isCollapsed ? "text-tables-highlightedIcon" : "text-tables-secondaryIcon"} hover:text-tables-highlightedIcon cursor-pointer`}
                                        onClick={() => {
                                            setSelectedTranslationToDelete(row);
                                        }}
                                    />
                                </div>
                            )
                        }
                    ]}
                />
            )}

            {(translationsResponse ?? []).length === 0 ? (
                <></>
            ) : (
                <div className="pl-[16px]">
                    <BaseMaterialIconButton
                    icon={<PlusIcon />}
                    onClick={() => {
                        setSelectedTranslationToMutate(undefined);
                        setIsMutationModalOpen(true);
                    }}
                ></BaseMaterialIconButton>
                </div>
            )}

            {pagination && <BasePagination config={pagination} />}

            <DeletionModal
                isOpen={!!selectedTranslationToDelete}
                selectedTranslation={{ product_id: props.product.id, translation: selectedTranslationToDelete }}
                onClose={() => {
                    setSelectedTranslationToDelete(undefined);
                }}
            />

            <MutationModal
                alreadySelectedIds={
                    translationsResponse?.map((e) => {
                        return e.language.id;
                    }) ?? []
                }
                isOpen={isMutationModalOpen}
                translation={selectedTranslationToMutate}
                onClose={() => {
                    setSelectedTranslationToMutate(undefined);
                    setIsMutationModalOpen(false);
                }}
                productId={props.product.id}
            />
        </>
    );
}