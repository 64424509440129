import { flattenPaginationRequest, internalApiSlice, PaginationRequest, PaginationResponse, transformResponse } from "@/redux/api/internalApiSlice";
import { Collection } from "@/types/general";

export const collectionApi = internalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCollections: builder.query<Collection.Root[], void>({
      query: () => ({
        url: `collection`,
      }),
      transformResponse,
      providesTags: [{ type: "Collection", id: "LIST" }],
    }),

    getModuleCollections: builder.query<Collection.Root[], string>({
      query: (module) => ({
        url: `${module}/collection`,
      }),
      transformResponse,
      providesTags: [{ type: "Collection", id: "LIST" }],
    }),

    getCollectionsFull: builder.query<
      PaginationResponse<Collection.Root>,
      PaginationRequest<Collection.DTO.Filters, Collection.DTO.OrderBy>
    >({
      query: (req) => ({
        url: "collection",
        method: "GET",
        params: {
          ...flattenPaginationRequest(req),
        },
        parseDates: ["date"],
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.payload.map(({ id }) => ({
                type: "Collection" as const,
                id,
              })),
              { type: "Collection", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Collection", id: "PARTIAL-LIST" }],
    }),

    createCollection: builder.mutation<Collection.Root, Collection.DTO.Create>({
      query: (body) => ({
        url: `collection`,
        method: "POST",
        data: body,
      }),
      invalidatesTags: [
        { type: "Collection", id: "LIST" },
        { type: "Collection", id: "PARTIAL-LIST" },
      ],
    }),

    // Getting specific collection by id
    getCollection: builder.query<Collection.Root, number>({
      query: (id) => ({
        url: `collection/${id}`,
      }),
      transformResponse,
      providesTags: (result) =>
        result ? [{ type: "Collection", id: result.id }] : [],
    }),

    deleteCollection: builder.mutation<void, number>({
      query: (id) => ({
        url: `collection/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            collectionApi.util.updateQueryData(
              "getCollections",
              undefined,
              (draft) => {
                const index = draft.findIndex((customer) => customer.id === id);
                draft.splice(index, 1);
              }
            )
          );
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: (result, error, id) => [{ type: "Collection", id }],
    }),

    updateCollection: builder.mutation<
      Collection.Root,
      Collection.DTO.Update & { id: number }
    >({
      query: ({ id, ...body }) => {
        return {
          url: `collection/${id}`,
          method: "PUT",
          data: body,
        };
      },

      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedProduct } = await queryFulfilled;
          dispatch(
            collectionApi.util.updateQueryData(
              "getCollections",
              undefined,
              (draft) => {
                const index = draft.findIndex(
                  (product) => product.id === updatedProduct.id
                );
                draft[index] = {
                  id: updatedProduct.id,
                  name: updatedProduct.name,
                  code: updatedProduct.code,
                  barcode: updatedProduct.barcode,
                  totalQuantity: updatedProduct.totalQuantity,
                  products: updatedProduct.products,
                };
              }
            )
          );
        } catch (err) {
          console.error(err);
        }
      },
      invalidatesTags: (result, error, args) => [
        { type: "Collection", id: args.id },
      ],
    }),
  }),
});

export const {
  useGetCollectionsFullQuery,
  useGetModuleCollectionsQuery,
  useCreateCollectionMutation,
  useGetCollectionQuery,
  useUpdateCollectionMutation,
  useGetCollectionsQuery,
  useDeleteCollectionMutation,
} = collectionApi;
