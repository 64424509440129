'use strict';

import $ from 'jquery';
import 'slick-carousel';
import '../../../node_modules/slick-carousel/slick/slick.scss';

export default function Slider() {
  $('.slick-slider').each(function() {
    var $this = $(this);
    if ($this.parents('.hidden').length == 0) {
      $this.slick();
    }
  });

  $('.video-next').on('click', function() {
    $('.video-slider').slick('slickNext');
  });

  $('.video-prev').on('click', function() {
    $('.video-slider').slick('slickPrev');
  });
}