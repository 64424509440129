import React from 'react'

function CancelPage() {
  return (
    <div>
        <h1>Cancelled</h1>
        <h2>Your payment was cancelled</h2>

        <a href="/">Go to main page</a>
    </div>
  )
}

export default CancelPage;