import React, { useEffect } from "react";
import { useGetBOMsFullQuery, useGetModuleBOMsFullQuery } from "@redux/features/bom/bomApi";
import BaseTable from "@reusables/BaseTable";
import { cn, normalizePrice } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import TableButton from "@reusables/BaseTable/TableButton";
import { Check } from "lucide-react";
import { Control, useController } from "react-hook-form";
import { AddProductFormSchemeTyping } from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/MutationLayout/parts/ProductSection/AddProductModal";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";

interface BOMSelectorProps {
    active: boolean;
    product_id: number;
    control: Control<AddProductFormSchemeTyping>;
}

export default function BOMSelector({ active, product_id, control }: BOMSelectorProps) {
    const { t } = useTranslation("", { keyPrefix: "purchaseOrder.general.modals.addProduct.bom" });

    const { data: productRelatedBOMs, isLoading: productRelatedBOMsLoading } = useGetModuleBOMsFullQuery({
        filters: {
            productId: product_id
        },
        reactive: true,
        module: "purchase-order"
    }, {
        skip: !active
    });

    const { field: field } = useController({
        name: "selected_bom",
        control: control
    });

    /**
     * SUGAR: If there is only one BOM for the product, select it automatically.
     */
    useEffect(() => {
        if (active && productRelatedBOMs && productRelatedBOMs.payload.length === 1) {
            field.onChange(productRelatedBOMs.payload[0]);
        }
    }, [active, productRelatedBOMs]);

    return (
        <div className={`border-t-[1px] border-t-inputs-border-default`}
             style={{ borderTopWidth: "1px", borderTopStyle: "solid" }}>
            <BaseLoadingBlocker active={productRelatedBOMsLoading}>
                <BaseTable
                    data={productRelatedBOMs?.payload ?? []}
                    rowOnClick={(row, prevIsCollapsedState) => {
                        const isCurrentRowSelected = field.value?.id === row.id;
                        field.onChange(isCurrentRowSelected && prevIsCollapsedState ? undefined : row);
                    }}
                    columns={[
                        {
                            header: () => t("table.columns.0"),
                            getter: row => row.name,
                            sx: {
                                width: "100%"
                            }
                        },
                        {
                            header: () => t("table.columns.1"),
                            getter: row => normalizePrice(row.components.reduce((acc, curr) => acc + curr.product.unit_price * curr.quantity, 0)),
                            sx: {
                                minWidth: "200px"
                            }
                        },
                        {
                            header: () => "",
                            getter: (row, index, isCollapsed) => <div className={"text-right"}>
                                <TableButton
                                    className={cn("border-[1px] border-solid transition-all", isCollapsed && "!border-purple-400")}
                                >
                                    {
                                        field.value?.id === row.id ?
                                            <div className={"animate-in fade-in"}>
                                                <Check size={12} className={"mr-1"} />
                                                {t("table.actions.select.selected")}
                                            </div>
                                            :
                                            t("table.actions.select.idle")

                                    }
                                </TableButton>
                            </div>,
                            sx: {
                                minWidth: "150px"
                            }
                        }
                    ]}

                    collapse={{
                        fill: "#D9DAFD",
                        borderColor: "#B3B5EB",
                        content: (mainRow) => {
                            return (
                                <BaseTable
                                    data={mainRow.components}
                                    columns={[
                                        {
                                            header: <div className="text-accent font-semibold">
                                                {t("table.subtable.columns.0")}
                                            </div>,
                                            getter: (row) => row.product?.name
                                        },
                                        {
                                            header: <div className="text-accent font-semibold">
                                                {t("table.subtable.columns.1")}
                                            </div>,
                                            getter: (row) => row?.quantity
                                        },
                                        {
                                            header: <div className="text-accent font-semibold">
                                                {t("table.subtable.columns.2")}
                                            </div>,
                                            getter: (row) => normalizePrice(row?.product?.unit_price)
                                        },
                                        {
                                            header: <div className="text-accent font-semibold">
                                                {t("table.subtable.columns.3")}
                                            </div>,
                                            getter: (row) => normalizePrice(row?.product?.unit_price * row?.quantity)
                                        },
                                        {
                                            header: <div className="text-accent font-semibold">
                                                {t("table.subtable.columns.4")}
                                            </div>,
                                            getter: (row) => row?.product?.in_stock
                                        }
                                    ]}
                                    size="small"
                                    nothingFound={{
                                        height: 200
                                    }}
                                    hideTableWhenNothingFound
                                />
                            );
                        }
                    }}

                    nothingFound={{
                        height: 200
                    }}

                    alternate
                    sx={{
                        "& .MuiTableBody-root": {
                            borderCollapse: "separate",
                            "& .MuiTableCell-root:first-child": {
                                borderTopLeftRadius: "8px",
                                borderBottomLeftRadius: "8px"
                            },
                            "& .MuiTableCell-root:last-child": {
                                borderTopRightRadius: "8px",
                                borderBottomRightRadius: "8px"
                            }
                        }
                    }}
                />
            </BaseLoadingBlocker>
        </div>
    );
}