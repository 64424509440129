import React, { useEffect } from "react";
import { LowStockRefillSchemeTyping } from "@components/Dashboard/pages/Sales/Orders/components/modals/LowStockRefillModal/types";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { useGetBOMsFullQuery } from "@redux/features/bom/bomApi";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import BaseTable from "@reusables/BaseTable";
import { cn, normalizePrice } from "@helpers/utils";
import TableButton from "@reusables/BaseTable/TableButton";
import { Check } from "lucide-react";
import { useTranslation } from "react-i18next";
import BaseMicroUtils from "@reusables/BaseMicroUtils";
import { Alert, Collapse } from "@mui/material";

export default function BOMSelector({ index, active }: { index: number; active: boolean }) {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.mutations.creation.lowStockOffer.modals.refill.bom" });

    const { control } = useFormContext<LowStockRefillSchemeTyping>();

    const line = useWatch({
        control,
        name: `lines.${index}`
    });

    const { data: productRelatedBOMs, isLoading: productRelatedBOMsLoading } = useGetBOMsFullQuery(
        {
            filters: {
                productId: line?.product?.id
            },
            reactive: true
        },
        {
            skip: !active
        }
    );

    const { field: field, fieldState } = useController({
        name: `lines.${index}.selected_bom`,
        control: control
    });

    /**
     * SUGAR: If there is only one BOM for the product, select it automatically.
     */
    useEffect(() => {
        if (active && productRelatedBOMs && productRelatedBOMs.payload.length === 1) {
            field.onChange(productRelatedBOMs.payload[0]);
        }
    }, [active, productRelatedBOMs]);

    return (
        <div className={`border-t-[1px] border-t-inputs-border-default space-y-1`} style={{ borderTopWidth: "1px", borderTopStyle: "solid" }}>
            <Collapse in={!!fieldState.error}>
                <Alert severity={"error"} className={"rounded-0"}>
                    {fieldState.error?.message}
                </Alert>
            </Collapse>
            <BaseLoadingBlocker active={productRelatedBOMsLoading}>
                <BaseTable
                    data={productRelatedBOMs?.payload ?? []}
                    rowOnClick={(row, prevIsCollapsedState) => {
                        const isCurrentRowSelected = field.value?.id === row.id;
                        field.onChange(isCurrentRowSelected && prevIsCollapsedState ? undefined : row);
                    }}
                    columns={[
                        {
                            header: () => t("table.columns.0"),
                            getter: (row) => row.name,
                            sx: {
                                width: "100%"
                            }
                        },
                        {
                            header: () => t("table.columns.1"),
                            getter: (row) => (
                                <>
                                    {normalizePrice(row.components.reduce((acc, curr) => acc + curr.product.unit_price * curr.quantity, 0) as number)}
                                    <BaseMicroUtils.BaseCurrency />
                                </>
                            ),
                            sx: {
                                minWidth: "200px"
                            }
                        },
                        {
                            header: () => t("table.columns.2"),
                            getter: (row) => (
                                <>
                                    {normalizePrice((row.components.reduce((acc, curr) => acc + curr.product.unit_price * curr.quantity, 0) as number) * (line?.quantity ?? 0))}
                                    <BaseMicroUtils.BaseCurrency />
                                </>
                            ),
                            sx: {
                                minWidth: "200px"
                            }
                        },
                        {
                            header: () => "",
                            getter: (row, index, isCollapsed) => (
                                <div className={"text-right"}>
                                    <TableButton className={cn("border-[1px] border-solid transition-all", isCollapsed && "!border-purple-400")}>
                                        {field.value?.id === row.id ? (
                                            <div className={"animate-in fade-in"}>
                                                <Check size={12} className={"mr-1"} />
                                                {t("table.actions.select.selected")}
                                            </div>
                                        ) : (
                                            t("table.actions.select.idle")
                                        )}
                                    </TableButton>
                                </div>
                            ),
                            sx: {
                                minWidth: "150px"
                            }
                        }
                    ]}
                    collapse={{
                        fill: "#D9DAFD",
                        borderColor: "#B3B5EB",
                        content: (mainRow) => {
                            return (
                                <BaseTable
                                    data={mainRow.components}
                                    columns={[
                                        {
                                            header: <div className="text-accent font-semibold">{t("table.subtable.columns.0")}</div>,
                                            getter: (row) => row.product?.name
                                        },
                                        {
                                            header: <div className="text-accent font-semibold">{t("table.subtable.columns.1")}</div>,
                                            getter: (row) => row?.quantity
                                        },
                                        {
                                            header: <div className="text-accent font-semibold">{t("table.subtable.columns.2")}</div>,
                                            getter: (row) => (
                                                <>
                                                    {normalizePrice(row?.product?.unit_price)}
                                                    <BaseMicroUtils.BaseCurrency />
                                                </>
                                            )
                                        },
                                        {
                                            header: <div className="text-accent font-semibold">{t("table.subtable.columns.3")}</div>,
                                            getter: (row) => (
                                                <>
                                                    {normalizePrice(row?.product?.unit_price * row?.quantity)}
                                                    <BaseMicroUtils.BaseCurrency />
                                                </>
                                            )
                                        },
                                        {
                                            header: <div className="text-accent font-semibold">{t("table.subtable.columns.4")}</div>,
                                            getter: (row) => row?.product?.in_stock
                                        }
                                    ]}
                                    size="small"
                                    nothingFound={{
                                        height: 200
                                    }}
                                    hideTableWhenNothingFound
                                />
                            );
                        }
                    }}
                    nothingFound={{
                        height: 200
                    }}
                    alternate
                    sx={{
                        "& .MuiTableBody-root": {
                            borderCollapse: "separate",
                            "& .MuiTableCell-root:first-child": {
                                borderTopLeftRadius: "8px",
                                borderBottomLeftRadius: "8px"
                            },
                            "& .MuiTableCell-root:last-child": {
                                borderTopRightRadius: "8px",
                                borderBottomRightRadius: "8px"
                            }
                        }
                    }}
                />
            </BaseLoadingBlocker>
        </div>
    );
}
