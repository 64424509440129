import React, { useEffect } from "react";
import EditingLayout from "../components/EditingLayout";
import { useTranslation } from "react-i18next";
import { fetchEntity } from "@helpers/utils";
import { Location } from "@/types/general";
import { useParams } from "react-router-dom";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";

export default function LocationsEditingPage() {
    const { t } = useTranslation("", { keyPrefix: "settings.general.locations.editLocation" });

    const { id } = useParams<{ id: string }>();

    const { data: location, loading: locationLoading } = fetchEntity<Location.Extended>({ route: `location/${id}` });

    const [locationName, setLocationName] = React.useState<string>("");

    useEffect(() => {
        setLocationName(location?.store ?? "");
    }, [location]);

    return (
        <BaseRecordRenderer
            loading={locationLoading}
            record={location}
        >
            <>
                <div className="flex mb-[40px]">
                    <h6 className="text-accent grow">{locationName}</h6>
                </div>
                <EditingLayout location={location} buttonText={t("button")}
                               onLocationUpdate={loc => setLocationName(loc?.store)} />
            </>
        </BaseRecordRenderer>
    );
}