import React from "react";
import BaseInput from "@reusables/BaseInput";
import {Search} from "lucide-react";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";


type SearchInputProps = {
    onSearch: (search: string) => void;
    permanent?: boolean;
}

export default function SearchInput(props: SearchInputProps){
    const [isShowSearch, setIsShowSearch] = React.useState(false)
    const [search, setSearch] = React.useState("")

    if(isShowSearch || props.permanent){
        return (
            <BaseInput
                className={"animate-in slide-in-from-right-0.5"}
                type="text"
                placeholder={'Search'}
                value={search}
                onBlur={() => {
                    if (!search || !search.length)
                        setIsShowSearch(false)
                }}
                onChange={val => {
                    setSearch(val ?? "")
                    props.onSearch(val ?? "")
                }}
                autoFocus
                sx={{
                    "& .MuiInput-input": {
                        padding: "7.5px"
                    }
                }}

                icon={{
                    right: {
                        el: <Search size={16} style={{marginTop: "-3px"}}/>,
                        offset: "12px"
                    }
                }}
            />
        )
    }else {
        return (
            <BaseMaterialIconButton
                icon={<Search size={16}/>}
                onClick={() => setIsShowSearch(true)}
            />
        )
    }
}