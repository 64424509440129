import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { StepperItem } from "./types";
import BackFadeBubble from "@reusables/BaseStepper/parts/BackFadeBubble";

interface StepperProperties {
    items: StepperItem[];
    manual?: {
        activeTab: number;
        onClick?: (tabIndex: number) => void;
    };
    className?: string;
}

export default function BaseStepper({ items, className, manual }: StepperProperties) {
    // Custom animation configuration. Moving "back fade" to the relative position of clicked button, fully copying its size.
    const [active, setActive] = useState<number>(manual?.activeTab ?? 0);
    const linksGroupRef = useRef<HTMLDivElement>(null);

    const [track, setState] = useState<HTMLElement | null>(null);

    useLayoutEffect(() => {
        setState(linksGroupRef?.current?.children.item(active) as HTMLElement | null);
    }, [active]);

    useEffect(() => {
        if (manual)
            setActive(manual.activeTab);
    }, [manual]);

    return (
        <div className={`relative ${className ?? ""}`}>
            <div className="grid grid-flow-col bg-stepper-main rounded-[100px]" ref={linksGroupRef}>
                {
                    items.filter(i => !i.hidden).map((item, index) => {
                        return (
                            <div key={index}
                                //    className={`${manual?.onClick ? "cursor-pointer" : "cursor-default" } flex justify-center items-center px-[32px] py-[20px] rounded-[100px] relative z-50 hover:text-stepper-main ${active === index ? "!text-stepper-selected" : "text-stepper-main"}`}
                                 className={`
                                        flex justify-center items-center px-[32px] py-[20px] rounded-[100px] relative z-50 hover:text-stepper-main
                                        ${active === index ? "text-white" : "text-black"}
                                        ${manual?.onClick ? "cursor-pointer" : "cursor-default"}
                                        ${active === index ? "!text-stepper-selected" : "text-stepper-main"}
                                        ${active !== index ? "hidden md:flex" : "flex"}
                                     `}

                                 onClick={() => {
                                     if (manual) {
                                         manual.onClick?.(index);
                                     } else {
                                         setActive(index);
                                     }

                                     item.onClick?.(index);
                                 }}
                            >
                                {item.title}
                            </div>
                        );
                    })
                }
            </div>

            <BackFadeBubble track={track} />
        </div>
    );
}