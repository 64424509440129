import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import { ChevronDown } from "lucide-react";
import { Control, Controller } from "react-hook-form";
import {
    CategoryMutationFormTyping
} from "@components/Dashboard/pages/SettingsPage/General/Categories/modals/MutationModal/index";
import { useTranslation } from "react-i18next";
import { useGetPOGOSalesAccountsQuery } from "@redux/features/integrations/integrationsApi";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";

interface PowerofficeIntegrationSectionProps {
    control: Control<CategoryMutationFormTyping>;
}

export default function PowerofficeIntegrationSection({ control }: PowerofficeIntegrationSectionProps) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.categories.modals.mutation.integrations" });

    const { data: salesAccountsOptions, isLoading: isSalesAccountsLoading } = useGetPOGOSalesAccountsQuery();

    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ChevronDown />}
                aria-controls="pogo-integration-content"
                id="pogo-integration-header"
            >
                <div className={"text-accent"}>{t(`poweroffice.heading`)}</div>
            </AccordionSummary>
            <AccordionDetails>
                <Controller
                    name={"integrations.poweroffice.sales_account"}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}

                            label={t("poweroffice.salesAccount.label")}
                            placeholder={t("poweroffice.salesAccount.placeholder")}

                            options={salesAccountsOptions ?? []}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name,
                                caption: opt => opt.code
                            }}
                            isLoading={isSalesAccountsLoading}

                            virtualize
                            autocomplete
                        />
                    )}
                />
            </AccordionDetails>
        </Accordion>
    );
}