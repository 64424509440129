import {
    flattenPaginationRequest,
    internalApiSlice,
    PaginationRequest,
    PaginationResponse,
    transformResponse
} from "@redux/api/internalApiSlice";
import { RootState } from "@redux/hooks";
import { Me, Product, Users } from "@/types/general";

import { createSelector } from "@reduxjs/toolkit";


export const usersApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUsersFull: builder.query<
            PaginationResponse<Users.Root>,
            PaginationRequest<Users.DTO.Filters, Users.DTO.OrderBy>
        >({
            query: (req) => ({
                url: `user`,
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req),
                },
                parseDates: ["last_seen"]
            }),
            providesTags: (result) => result?.payload?.length ? [
                ...result.payload.map(x => ({ type: "User", id: x.id })),
                { type: "User", id: "LIST" }
            ] : [{ type: "User", id: "LIST" }],
        }),

        getMe: builder.query<Me.Root, void>({
            query: () => ({
                url: `me`
            }),
            providesTags: [{ type: "Me", id: "LIST" }],
            transformResponse
        }),

        getUserData: builder.query<Users.Root, number>({
            query: (id) => ({ url: `user/${id}` })
        }),

        inviteUser: builder.mutation<Users.Root, Users.DTO.Create>({
            query: (body) => ({
                url: `user`,
                method: "POST",
                data: body
            }),
            transformResponse,
            invalidatesTags: [{ type: "User", id: "LIST" }]

            // async onQueryStarted(body, { dispatch, queryFulfilled }) {
            //     try {
            //         const { data: newUser } = await queryFulfilled;
            //         const createResult = dispatch(usersApi.util.updateQueryData("getUsers", undefined, (draft) => {
            //             draft.push({ ...newUser })
            //         }));
            //     } catch (err) {
            //         console.error(err);
            //     }
            // }
        }),

        updateUser: builder.mutation<Users.Root, Users.DTO.Update>({
            query: ({ id, ...body }) => ({
                url: `user/${id}`,
                method: "PUT",
                data: body
            }),
            transformResponse,
            invalidatesTags: [{ type: "User", id: "LIST" }]

            // async onQueryStarted(body, { dispatch, queryFulfilled }) {
            //     try {
            //         const { data: updatedUser } = await queryFulfilled;
            //         const updateResult = dispatch(usersApi.util.updateQueryData("getUsers", undefined, (draft) => {
            //             const index = draft.findIndex(item => item.id === updatedUser.id)
            //             if (index !== -1) draft[index] = updatedUser
            //         }))
            //     } catch (err) {
            //         console.error(err)
            //     }
            // }
        }),

        updateUserPassword: builder.mutation<void, Users.DTO.UpdatePassword>({
            query: ({ current_password, new_password, confirm_password }) => ({
                url: `user/password?current_password=${current_password}&new_password=${new_password}&confirm_password=${confirm_password}`,
                method: "PUT",
            })
        }),

        suspendUser: builder.mutation<void, number>({
            query: (userId) => ({
                url: `user/block/${userId}`,
                method: "PUT"
            }),
            invalidatesTags: [{ type: "User", id: "LIST" }]
        }),

        deleteUser: builder.mutation<void, number>({
            query: (id) => ({
                url: `user/${id}`,
                method: "DELETE"
            })
            // async onQueryStarted(id, { dispatch, queryFulfilled }) {
            //     try {
            //         const { data: deleted } = await queryFulfilled;
            //         const createResult = dispatch(usersApi.util.updateQueryData("getUsers", undefined, (draft) => {
            //             const index = draft.findIndex(item => item.id === id);
            //             if (index !== -1) {
            //                 draft.splice(index, 1);
            //             }
            //         }));
            //     } catch (err) {
            //         console.error(err);
            //     }
            // }
        })
    })
});

export const {
    useGetUsersFullQuery,
    useGetMeQuery,
    useGetUserDataQuery,
    useInviteUserMutation,
    useUpdateUserMutation,
    useUpdateUserPasswordMutation,
    useSuspendUserMutation,
    useDeleteUserMutation
} = usersApi;