import React, { Dispatch } from "react";
import { Product } from "@/types/general";
import { DisTempField, TemplateMode } from "./types";

/**
 * A shared context is necessary to maintain a consistent state across multiple product components and reduce the amount of repetitive code.
 */

export type ProductContextType = {
    disabledFields: [DisTempField[], Dispatch<React.SetStateAction<DisTempField[]>>];
    templatesMode: TemplateMode;
    product?: Product.Extended
}

export const ProductContext = React.createContext<ProductContextType | undefined>(undefined);