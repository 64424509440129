import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { Permission } from "@/types/general";

export const permissionsApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPermissions: builder.query<Permission.Root, void>({
            query: () => ({
                url: `permissions`
            }),
            providesTags: [{ type: "Permission", id: "LIST" }],
            transformResponse
        })
    })
});


export const {
    useGetPermissionsQuery
} = permissionsApi;