import React, {useState} from 'react'
import BaseRecordRenderer from '@reusables/BaseRecordRenderer';
import {useGetCustomerGroupQuery} from '@redux/features/customerGroups/customerGroupsApi';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import ViewLayout from '../components/ViewLayout';
import BaseTooltip from '@reusables/BaseTooltip';
import BaseTinyActionIcon, {TinyActionIconType} from '@reusables/BaseTinyActionIcon';
import DeletionModal from '../components/modals/DeletionModal';
import {Stack} from '@mui/material';
import {Can} from '@/casl.config';
import NoPermissionBanner from '@/components/ErrorPages/NoPermissionBanner';

export default function CustomerGroupDetailsPage() {
    const {t} = useTranslation("", {keyPrefix: "customerGroups.viewPage"});
    
    const history = useHistory();
    const {id} = useParams<{ id: string }>();

    const {data: customerGroup, isLoading: customerGroupLoading} = useGetCustomerGroupQuery(parseInt(id));

    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);
    
    return (
        <>
            <Can not I="view" a="customer">
                <div className="levitation-extended">
                    <NoPermissionBanner />
                </div>
            </Can>

            <Can I="view" a="customer">
                <BaseRecordRenderer
                    record={customerGroup}
                    loading={customerGroupLoading}
                >
                    <div className='space-y-[40px]'>
                        <div className="flex items-center">
                            <h6 className="text-accent grow">{customerGroup?.name}</h6>
                            <Stack direction={"row-reverse"} spacing={4} alignItems={"center"}>
                                <div className="space-x-[8px] flex items-center h-full">
                                    <Can I="edit" a="customer">
                                        <BaseTooltip title={`${t("sideButtons.edit")}`} placement='bottom-start'>
                                            <BaseTinyActionIcon
                                                type={TinyActionIconType.Edit}
                                                onClick={() => void history.push(`/dashboard/customer-groups/${customerGroup?.id}/edit`)}
                                            />
                                        </BaseTooltip>
                                    </Can>
                                    <Can I="delete" a="customer">
                                        <BaseTooltip title={`${t("sideButtons.delete")}`} placement='bottom-end'>
                                            <BaseTinyActionIcon
                                                type={TinyActionIconType.Delete}
                                                onClick={() => setIsDeletionModalOpen(true)}
                                            />
                                        </BaseTooltip>
                                    </Can>
                                </div>
                            </Stack>
                        </div>

                        <ViewLayout customerGroup={customerGroup as NonNullable<typeof customerGroup>} />
                    </div>
                </BaseRecordRenderer>
            </Can>

            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                onDeletion={() => history.push("/dashboard/customer-groups")}
                entity={customerGroup}
            />
        </>
    )
}
