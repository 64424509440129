import React from "react";
import BaseButton from "@reusables/BaseButton";
import {toast} from "react-toastify";
import BaseModal from "@reusables/Modals/BaseModal";
import {useTranslation} from "react-i18next";
import {Supplier} from "@/types/general";
import {useDeleteSupplierMutation} from "@redux/features/suppliers/suppliersApi";

interface DeletionModalProperties {
    isOpen: boolean;
    entity?: Supplier.Root | Supplier.Extended;
    onDeletion?: () => void;
    onClose: () => void;
}

export default function DeletionModal(props: DeletionModalProperties) {
    const {t} = useTranslation("", {keyPrefix: "suppliers.general.modals.deletion"});

    const [deleteSupplier, {isLoading: isActiveDeletionLoading}] = useDeleteSupplierMutation();

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
            isLoading={isActiveDeletionLoading}
        >
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">{t("subheading")} <span
                    className="bold-highlight">{props.entity?.name}?</span></div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose}/>
                    <BaseButton text={t("buttons.delete")} size="md" onClick={() => {
                        if (props.entity) {
                            deleteSupplier(props.entity.id).unwrap().then(resp => {
                                toast.success(t("responses.success"));
                                props.onDeletion?.();
                            }).catch(e => {
                                console.error(e);
                                toast.error(t("responses.error"));
                            }).finally(() => {
                                props.onClose();
                            });
                        }
                    }}/>
                </div>
            </div>
        </BaseModal>
    )
}