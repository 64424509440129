import React from 'react'

// type Props = {}
// {}: Props

function QuickPurchaseOrderPage() {
  return (
    <div>QuickPurchaseOrderPage</div>
  )
}

export default QuickPurchaseOrderPage