import React from "react";
import "./index.scss";

interface GradientCardInterface {
  img: string;
  header: string;
  description: string;
  isGradientHeader?: boolean;
}

const GradientCard: React.FC<GradientCardInterface> = (props) => {
  const { img, header, description, isGradientHeader } = props;
  return (
    <div className="attendance-card">
      <img src={img} className="attendance-img" />
      <div className={`header-gradient ${isGradientHeader ? "gradient" : ""}`}>
        {header}
      </div>
      <div className="description">{description}</div>
    </div>
  );
};

export default GradientCard;
