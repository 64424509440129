import { cn } from "@/helpers/utils";
import React, { PropsWithChildren } from "react";

export default function BaseBadge(props: PropsWithChildren<{
    className?: string;
    onClick?: () => void,
    image?: JSX.Element
}>) {
    return (
        <div
            className={cn("inline-flex items-center rounded-md px-[16px] text-xs text-accent transition-colors", "bg-light-green", props.className)}
            onClick={props.onClick}>
            {
                !!props.image &&
                <div className={"bg-white rounded-[5px] mr-[8px] w-[16px] h-[16px] flex items-center justify-center"}>
                    {
                        props.image
                    }
                </div>
            }
            <div className={"leading-[24px]"}> {props.children}</div>
        </div>
    );
}