import React, { useCallback, useState } from "react";

import BaseTable from "@reusables/BaseTable";
import { Menu, Pagination } from "@mui/material";
import BaseTooltip from "@reusables/BaseTooltip";
import { Link, useHistory } from "react-router-dom";
import BaseAdminFilter from "@reusables/BaseAdminFilter";

import customParseFormat from "dayjs/plugin/customParseFormat";
import BaseDatepicker from "@reusables/BaseDatepickerLegacy";
import { DatepickerRangeContext } from "@reusables/BaseDatepickerLegacy/context";

import dayjs from "dayjs";
import {
    calculateOrderPrice,
    genT,
    normalizePrice,
    PredefinedTranslations,
    removeEmpty,
    useDatesRangeFilter,
    useOrdering,
    usePagination
} from "@helpers/utils";
import { Product, PurchaseOrder } from "@/types/general";
import PurchaseOrderDeletionModal from "./components/modals/DeletePurchaseOrder";
import { useTranslation } from "react-i18next";
import { useGetModuleSuppliersQuery } from "@redux/features/suppliers/suppliersApi";
import { AbilityContext, Can } from "@/casl.config";
import { useAbility } from "@casl/react";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import { BaseTinyActionIcon, TinyActionIconType } from "@components/reusables/BaseTinyActionIcon";
import { useGetPurchaseOrdersFullQuery } from "@redux/features/purchases/purchasesApi";
import PurchaseOrdersExportModal
    from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/modals/PurchaseOrdersExportModal";
import _ from "lodash";
import { productsApi } from "@redux/features/products/productsApi";
import BaseInfiniteLoader from "@reusables/dropdowns/BaseInfiniteLoader";
import { useAppDispatch } from "@redux/hooks";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import i18n from "@/i18n";
import BaseDropdown, { adaptInfiniteLoader } from "@reusables/dropdowns/BaseDropdown";
import { handleQuickReceive } from "@redux/features/receipts/thunks";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";

dayjs.extend(customParseFormat);

export default function PurchasesPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "purchaseOrder.main" });

    const dispatch = useAppDispatch();

    const ability = useAbility(AbilityContext);

    // ===> ACTIONS DOTS CONFIGURATION <===
    const [actionsAnchor, setActionsAnchor] = useState<HTMLDivElement>();
    const [actionEntity, setActionEntity] = useState<PurchaseOrder.Root>();

    // MODALS RELATED
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);

    // FILTERS RELATED
    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(_.debounce(setSearchingValue, 1000), [setSearchingValue]);

    const { data: supplierOptions, isLoading: supplierOptionsLoading } = useGetModuleSuppliersQuery("purchase-order");

    const [selectedSuppliers, setSelectedSuppliers] = useState<typeof supplierOptions>([]);

    // Products options for dropdown filter (lazy loading)
    const [productsOptions, setProductsOptions] = useState<Product.Slim[]>([]);
    // Selected products for filtering by products (dropdown)
    const [selectedProducts, setSelectedProducts] = useState<Product.Slim[]>();

    // Received filter
    const receivedOptions = [
        {
            label: t("filters.receive.options.received"),
            value: 0
        },
        {
            label: t("filters.receive.options.inProgress"),
            value: 1
        },
        {
            label: t("filters.receive.options.notReceived"),
            value: 2
        }
    ];
    const [selectedReceived, setSelectedReceived] = useState<typeof receivedOptions>([]);

    const { datesRange, setDatesRange, filterAdaptedDatesRange } = useDatesRangeFilter();


    const { orderBy, setOrderBy } = useOrdering<PurchaseOrder.DTO.OrderBy>({ "name": "id", "type": "desc" });

    const filters: PurchaseOrder.DTO.Filters = removeEmpty({
        search: searchingValue,
        products: selectedProducts?.map(x => x.id),
        suppliers: selectedSuppliers?.map(x => x.id),
        receive_state: selectedReceived.length ? selectedReceived[0].value : undefined,
        dates_range: filterAdaptedDatesRange
    });

    const pagination = usePagination({ page: 1, limit: 8 });

    const { data: purchasesResponse, isLoading: isPurchasesResponseLoading } = useGetPurchaseOrdersFullQuery({
        filters,
        orderBy,
        pagination
    });

    const [isExportModalOpen, setIsExportModalOpen] = useState(false);

    const [isProductLazyLoadingFetching, setIsProductLazyLoadingFetching] = useState(false);

    const handleTotalPrice = (row: PurchaseOrder.Root) => {
        if (row.lines.length) {
            const total = row.lines.reduce((acc, line) => {
                if (line.type === 'line') {
                    const { quantity, unit_price, discount, tax } = line.data;
                    return acc + calculateOrderPrice(quantity, unit_price, discount ?? 0) * (1 + (tax?.rate ?? 0) / 100);
                } else if (line.type === 'group' && line.data) {
                    return acc + line.data?.reduce((groupAcc, groupLine) => {
                        const { quantity, unit_price, discount, tax } = groupLine;
                        return groupAcc + calculateOrderPrice(quantity, unit_price, discount ?? 0) * (1 + (tax?.rate ?? 0) / 100);
                    }, 0);
                }
                return acc;
            }, 0);
            return normalizePrice(total);
        } else {
            return '-';
        }
    };

    const handleCollapseContent = (rowRelated: PurchaseOrder.Root) => {
        // Combine lines and group items, while expanding group items into individual lines
        const combinedItems = rowRelated.lines.flatMap(line => {
            if (line.type === 'line') {
                return [line.data];
            } else if (line.type === 'group') {
                return line.data!.map(groupLine => ({
                    ...groupLine,
                    groupName: line.name,
                    groupId: line.id
                }));
            }
            return [];
        });
    
        return (
            <BaseTable
                data={combinedItems}
                columns={[
                    {
                        header: t("subtable.columns.0"),
                        getter: (row) => <Link to={`/dashboard/products/${row.product.id}/details`}><u>{row.product.code}</u></Link>
                    },
                    {
                        header: t("subtable.columns.1"),
                        getter: (row) => row.product.name
                    },
                    {
                        header: t("subtable.columns.2"),
                        getter: (row) => row.quantity
                    },
                    {
                        header: t("subtable.columns.3"),
                        getter: (row) => normalizePrice(row.unit_price)
                    },
                    {
                        header: t("subtable.columns.4"),
                        getter: (row) => row.discount ? row.discount.toFixed(2) + "%" : "-"
                    },
                    {
                        header: t("subtable.columns.5"),
                        getter: (row) => row.tax ? row.tax.rate.toFixed(2) + "%" : "-"
                    },
                    {
                        header: t("subtable.columns.6"),
                        getter: (row) => {
                            const total = calculateOrderPrice(row.quantity, row.unit_price, row.discount ?? 0);
                            const taxed = total * (1 + (row.tax?.rate ?? 0) / 100);
                            return normalizePrice(taxed);
                        }
                    }
                ]}
                size={"small"}
                boldHeaders
                hideTableWhenNothingFound
                nothingFound={{ height: 200 }}
            />
        );
    };

    return (
        <>
            {/* FILTERS BLOCK */}
            <BaseAdminFilter
                permissionModule="purchase_order"
                filterItems={
                    <div className="w-[364px]">
                        <div className="grid grid-cols-1 gap-[24px]">
                            <BaseDropdown
                                label={t("filters.supplier.label")}
                                options={supplierOptions}
                                value={selectedSuppliers}
                                getter={{
                                    label: (item) => item.name,
                                    key: (item) => item.id
                                }}

                                multiple
                                autocomplete
                                brightLabel

                                onChange={(_, opts) => setSelectedSuppliers(opts)}

                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                isLoading={supplierOptionsLoading}
                            />

                            <BaseInfiniteLoader
                                fetch={(search,page, limit) => {
                                    setIsProductLazyLoadingFetching(true);
                                    dispatch(productsApi.endpoints.getProductsFull.initiate(
                                        {
                                            filters: {
                                                search
                                            },
                                            pagination: {
                                                page,
                                                limit
                                            },
                                        },
                                        {
                                            subscribe: false,
                                        }
                                    )
                                ).unwrap()
                                    .then(res => {
                                        setProductsOptions(res?.payload ?? []);
                                    })
                                    .catch(e => {
                                        if (isErrorWithMessage(e)) {
                                            toast.error(e.message);
                                        } else {
                                            toast.error(i18n.t("general.responses.somethingWentWrong"));
                                        }
                                    })
                                    .finally(() => {
                                        setIsProductLazyLoadingFetching(false);
                                    });
                                }}
                                limit={100}
                                result={productsOptions}
                                isLoading={isProductLazyLoadingFetching}
                            >
                                {
                                    (infinity) => (
                                        <BaseDropdown
                                            {...adaptInfiniteLoader(infinity)}
                                            label={t("filters.product.label")}
                                            // options={productsOptions}
                                            value={selectedProducts}
                                            getter={{
                                                label: opt => opt.name,
                                                key: opt => opt.id,
                                                renderOption: (opt, icon) => (
                                                    <div>
                                                        <span className="grow">{opt.name}</span>
                                                        {
                                                            selectedProducts?.includes(opt) ? icon :
                                                                <span className="text-lightGreen-500 font-thin">{opt.code}</span>
                                                        }
                                                    </div>
                                                )
                                            }}

                                            onChange={(_, opts) => setSelectedProducts(opts)}

                                            virtualize
                                            autocomplete
                                            multiple

                                            emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                            brightLabel
                                        />
                                    )
                                }
                            </BaseInfiniteLoader>

                            {/*<BaseDropdown*/}
                            {/*  label={t("filters.receive.label")}*/}
                            {/*  options={receivedOptions}*/}
                            {/*  value={selectedReceived}*/}

                            {/*  getter={{*/}
                            {/*    label: (item) => item.label,*/}
                            {/*    key: (item) => item.value*/}
                            {/*  }}*/}

                            {/*  brightLabel*/}

                            {/*  emptyValue={genT(PredefinedTranslations.DropdownsALL)}*/}

                            {/*  onChange={newState => setSelectedReceived(newState)}*/}
                            {/*/>*/}

                            <DatepickerRangeContext.Provider value={{
                                "delivery_date": { range: datesRange, setRange: setDatesRange }
                            }}>
                                <BaseDatepicker
                                    label={t("filters.deliveryDate.label")}
                                    placeholder={t("filters.deliveryDate.placeholder")}
                                    rangeConfig={{ groupKey: "delivery_date", role: "solo" }}
                                    brightLabel
                                />
                            </DatepickerRangeContext.Provider>
                        </div>
                    </div>
                }

                handleSearch={searchInputDebounce}

                handleFilter={(e) => console.log(e)}
                handleCreate={() => history.push("/dashboard/purchasing/new")}

                handleExport={() => void setIsExportModalOpen(true)}
            />

            {/* TABLE BLOCK */}
            <div className="levitation-extended mt-[32px]">
                <Can not I="view" a="purchase_order">
                    <NoPermissionBanner />
                </Can>

                <Can I="view" a="purchase_order">
                    <BaseTable
                        data={purchasesResponse?.payload ?? []}
                        isDataLoading={isPurchasesResponseLoading}
                        columns={[
                            // ID
                            {
                                header: t("table.columns.0"),
                                getter: (row) => <div className="bold-highlight">{row.code ?? row.id}</div>
                            },
                            // Reference
                            {
                                header: t("table.columns.1"),
                                getter: (row) => row.reference_number?.code ?? row.reference_number?.id ?? ""
                            },
                            // Supplier
                            {
                                header: t("table.columns.2"),
                                getter: (row) => <div className="bold-highlight">{row.supplier.name}</div>,
                                comparator: () => 0
                            },
                            // Purchase date
                            {
                                header: t("table.columns.3"),
                                getter: (row) => row.purchase_date?.format("YYYY-MM-DD") ?? "-",
                                comparator: () => 0
                            },
                            // {
                            //     header: t("table.columns.3"),
                            //     getter: (row, index, collapseState) => {
                            //         switch(row.receive_state){
                            //             case 1: return <BaseChip fill="green">Received</BaseChip>;
                            //             case 2: return <BaseChip fill="blue">In progress</BaseChip>;
                            //             case 3: return <BaseChip fill="none" className={collapseState ? "bg-gray-300 text-accent" : ""}>Not received</BaseChip>
                            //         }
                            //     },
                            //     comparator: () => 0
                            // },
                            // Total
                            {
                                header: t("table.columns.5"),
                                getter: (row) => <div>{handleTotalPrice(row)}</div>
                            },
                            // TODO: add here docs column
                            {
                                // visible: ability.can("view", "purchase_order") || ability.can("delete", "purchase_order"),
                                header: <div className="text-center">{t("table.columns.6")}</div>,
                                getter: (row, index, collapseState) => (
                                    <div
                                        className="flex space-x-[4px] justify-center align-center py-[10px] cursor-pointer group"
                                        onClick={(e) => {
                                            setActionsAnchor(e.currentTarget);
                                            setActionEntity(row);
                                        }}>
                                        {
                                            [...Array(3)].map((x, i) => <div key={i}
                                                                             className={`${collapseState ? "bg-gray-600" : "bg-gray-300"} rounded-full w-[5px] h-[5px] group-hover:bg-gray-600 transition-[.15s]`}></div>)
                                        }
                                    </div>
                                ),
                                preventCollapsePropagation: true
                            }
                        ]}
                        
                        collapse={{
                            content: handleCollapseContent,
                            fill: "#D9DAFD",
                            borderColor: "#B1B2E1"
                        }}

                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering) {
                                    let name: PurchaseOrder.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 2:
                                            name = "supplier";
                                            break;
                                        case 3:
                                            name = "delivery_date";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                        setOrderBy({
                                            name,
                                            type: newOrdering.order
                                        });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}

                        alternate
                    />

                    <Menu
                        id="basic-menu"
                        anchorEl={actionsAnchor}
                        open={!!actionsAnchor}
                        onClose={() => setActionsAnchor(undefined)}
                    >
                        <div
                            className="flex space-x-[16px] p-[18px] bg-gradient-to-r from-gradients-primaryLinear-from to-gradients-primaryLinear-to text-[#fff]"
                        >
                            <BasePermissionBlocker action="create" module="receive">
                                    <BaseTooltip active={ability.can("create", "receive")} title={t("actions.receive") || ""} placement="bottom">
                                        <BaseTinyActionIcon 
                                            type={TinyActionIconType.StartReceiving}
                                            onClick={() => {
                                                if (actionEntity?.id) {
                                                    ability.can("create", "receive") &&
                                                    dispatch(handleQuickReceive(actionEntity.id, history))
                                                }
                                            }}
                                            variant="gradient"
                                        />
                                    </BaseTooltip>
                                </BasePermissionBlocker>
                                <BaseTooltip title={t("actions.details") || ""} placement="bottom">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.ViewDetails}
                                        onClick={() => actionEntity && history.push(`/dashboard/purchasing/${actionEntity.id}/details`)}
                                        variant="gradient"
                                    />
                                </BaseTooltip>
                            {/* 
                            <Can I="duplicate" a="purchase_order">
                                <BaseTooltip title={t("actions.duplicate") || ""} placement="bottom">
                                    <BaseTinyActionIcon 
                                    type={TinyActionIconType.Duplicate} 
                                    variant="gradient"
                                    />
                                </BaseTooltip> 
                            </Can> */}

                            <BasePermissionBlocker action="edit" module="purchase_order">
                                <BaseTooltip active={ability.can("edit", "purchase_order")} title={t("actions.edit") || ""} placement="bottom">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Edit}
                                        onClick={() => ability.can("edit", "purchase_order") && actionEntity && history.push(`/dashboard/purchasing/${actionEntity.id}/edit`)}
                                        variant="gradient"
                                        className={actionEntity?.is_editable ? "" : "opacity-20 cursor-default"}
                                    />
                                </BaseTooltip>
                            </BasePermissionBlocker>
                            <BasePermissionBlocker action="delete" module="purchase_order">
                                <BaseTooltip active={ability.can("delete", "purchase_order")} title={t("actions.delete") || ""} placement="bottom">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Delete}
                                        onClick={() => {
                                            ability.can("delete", "purchase_order") ? (
                                                setIsDeletionModalOpen(true),
                                                setActionsAnchor(undefined)
                                            ) : null;
                                        }}
                                        variant="gradient"
                                        className={actionEntity?.is_editable ? "" : "opacity-20 cursor-default"}
                                    />
                                </BaseTooltip>
                            </BasePermissionBlocker>
                        </div>
                    </Menu>

                    <Pagination
                        className="mt-[32px]"
                        {...pagination.adapt(purchasesResponse)}
                    />
                </Can>
            </div>

            {/* DELETION MODAL */}
            <PurchaseOrderDeletionModal
                isOpen={isDeletionModalOpen}
                entity_id={actionEntity?.id}
                onClose={() => setIsDeletionModalOpen(false)}
            />

            {/* EXPORT MODAL */}
            <PurchaseOrdersExportModal
                isOpen={isExportModalOpen}
                onClose={() => void setIsExportModalOpen(false)}
                filters={filters}
                orderBy={orderBy}
            />
        </>
    );
}
