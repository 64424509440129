import React, { useCallback, useMemo } from "react";
import { useGetRevenueQuery } from "@redux/features/dashboard/dashboardApi";
import { Revenue } from "@/types/general";
import { useTranslation } from "react-i18next";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import DashboardCard from "@components/Dashboard/DashboardPage/DashboardCard";
import { ResponsiveContainer } from "recharts";
import BaseAreaChart from "@/components/reusables/Charts/BaseAreaChart";
import BaseButtonGroup from "@/components/reusables/BaseButtonGroup";
import { normalizePrice, useEchoEffect } from "@helpers/utils";
import { useAppSelector } from "@redux/hooks";
import _ from "lodash";

export default function RevenueWidget() {
    const { t } = useTranslation("", { keyPrefix: "dashboard.dashboard.stats.revenue" });

    const user = useAppSelector(state => state.auth.user);

    const [activeRevenueTab, setActiveRevenueTab] = React.useState<Revenue.Components.Mode>("month");

    const { data: revenue = [], isLoading: isRevenueLoading, refetch } = useGetRevenueQuery({
        mode: activeRevenueTab
    });

    const refetchDebounced = useCallback(_.debounce(() => refetch(), 1000), []);

    const normalizedRevenue = useMemo(() => revenue.map(item => ({
        name: item.name,
        income: normalizePrice(item.income),
        expenses: normalizePrice(item.expenses)
    })), [revenue]);

    useEchoEffect(
        `dashboard.revenue.${user?.company.id ?? -1}`,
        "DashboardUpdateEvent",
        () => {
            // This socket doesn't provide any data on update, so we just refetch the data
            refetchDebounced();
        },
        undefined,
        [user]
    );

    return (
        <BaseLoadingBlocker active={isRevenueLoading} useSolidBackground>
            <DashboardCard
                title={t("heading")}
                headerActions={
                    <BaseButtonGroup
                        items={[
                            {
                                content: t("tabs.0"),
                                onClick: () => setActiveRevenueTab("month")

                            },
                            {
                                content: t("tabs.1"),
                                onClick: () => setActiveRevenueTab("year")
                            }
                        ]}
                    />
                }
            >
                <ResponsiveContainer width="100%" height={300}>
                    <BaseAreaChart
                        data={normalizedRevenue}
                        strokes={{
                            income: {
                                color: "#A6DD4C",
                                fill: "#EAFAFE"
                            },
                            expenses: {
                                color: "#7556FA",
                                fill: "#F5FEF8"
                            }
                        }}
                        width={500}
                        height={300}
                    />
                </ResponsiveContainer>
            </DashboardCard>
        </BaseLoadingBlocker>
    );
}