import React from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import { stopPropagate } from "@helpers/utils";
import BaseButton from "@reusables/BaseButton";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { useGetModuleLocationsQuery } from "@redux/features/locations/locationsApi";

const formSchema = z.object({
    location: z.object({
        store: z.object({
            id: z.number(),
            name: z.string()
        }),
        section: z.object({
            id: z.number(),
            name: z.string()
        }).nullish().optional()
    })
});

type FormSchemaTyping = z.infer<typeof formSchema>;

interface AutofillLocationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (data: FormSchemaTyping) => void;
}

export default function AutofillLocationModal(props: AutofillLocationModalProps) {
    const { t } = useTranslation("", { keyPrefix: "bom.produceModal.shortcuts.buttons.setLocations.modal" });

    const { data: locations, isLoading: isLocationsLoading } = useGetModuleLocationsQuery("product");

    const form = useForm<FormSchemaTyping>({
        resolver: zodResolver(formSchema)
    });

    const onSubmit = form.handleSubmit((data) => {
        props.onSubmit(data);
        form.reset();
    }, console.error);

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={900}
            padding="56px"
            position="center"
        >
            <form onSubmit={stopPropagate(onSubmit)} className="space-y-8">
                <div className="space-y-[32px]">
                    <div className="text-xl font-semibold text-center text-accent">
                        {t("heading")}
                    </div>
                    <div className="text-center text-accent">
                        {t("subheading")}.
                    </div>
                    <Controller
                        name={"location"}
                        control={form.control}
                        render={({ field, fieldState }) => (
                            <BaseDropdown
                                {...field}
                                {...fieldState}

                                options={locations}
                                isLoading={isLocationsLoading}

                                getter={{
                                    label: (opt) => `${opt.store.name}${opt.section ? ` - ${opt.section.name}` : ""}`,
                                    key: (opt) => opt.section ? `${opt.store.id}_${opt.section.id}` : `${opt.store.id}`
                                }}

                                autocomplete
                            />
                        )}
                    />
                    <div className="grid grid-cols-2 gap-[16px]">
                        <BaseButton
                            text={t("buttons.cancel")}
                            size="md"
                            buttonWidth="100%"
                            primaryOutlined
                            type="button"
                            onClick={props.onClose}
                        />

                        <BaseButton
                            text={t("buttons.confirm")}
                            size="md"
                            buttonWidth="100%"
                        />
                    </div>
                </div>
            </form>
        </BaseModal>
    );
}

