import React, { useState } from "react";
import { cleanAllErrors, validate, ValidationStore } from "@reusables/Validator/validationStore";
import BaseValidationManager from "@reusables/Validator";
import BaseInput from "@reusables/BaseInputLegacy";
import { BoundedBetween, NotEmpty } from "@reusables/Validator/types";
import { manualRequest, normalizeNumber } from "@helpers/utils";
import BaseButton from "@reusables/BaseButton";
import { toast } from "react-toastify";
import BaseModal from "@reusables/Modals/BaseModal";
import { CreationModalProperties, ModalProperties } from "../../types";
import { useTranslation } from "react-i18next";
import { Product, Template } from "@/types/general";

export default function TemplateCreationModal(props: ModalProperties & CreationModalProperties<Template.Root<Product.Components.DisableableTemplateField>> & {disabled_fields: string[]}){
    const { t } = useTranslation("", { keyPrefix: "products.createEditProduct.modals.templates.creationModal" });

    const [templateName, setTemplateName] = useState<string>("")

    const [creationLoading, setCreationLoading] = useState<boolean>(false);

    const [validationStore, setValidationStore] = useState<ValidationStore>();

    return (
      <BaseModal
        isOpen={props.isOpen}
        onClose={() => {
            props.onClose?.()
        }}
        width={700}
        padding="56px"
        isLoading={creationLoading}
        useCloseIcon
      >
          <div className="space-y-[32px]">
              <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
              <BaseValidationManager onStoreInit={store => setValidationStore(store)}>
                  <BaseInput
                    label={t("fields.name")}
                    value={templateName}
                    onChange={setTemplateName}

                    constraints={[new NotEmpty()]}
                  />
              </BaseValidationManager>

              <div>
                  <BaseButton text={t("buttons.save")} className="w-full" size="md" onClick={() => {
                      if(validationStore){
                          validationStore.dispatch(
                            validate({
                                onSuccess: () => {
                                    manualRequest({
                                        method: "POST",
                                        route: "template",
                                        body: {
                                            disabled_fields: props.disabled_fields,
                                            name: templateName
                                        },
                                        then: resp => {
                                            toast.success(t("responses.success"))

                                            setTemplateName("")
                                            props.onCreation?.(resp.data.payload)
                                            props.onClose?.()
                                        },
                                        catch: e => {
                                            toast.error(t("responses.error"))
                                        },
                                        onLoadingChange: setCreationLoading
                                    })
                                }
                            })
                          )
                      }
                  }} />
              </div>
          </div>
      </BaseModal>
    )
}