import { Location } from "@/types/general";
import BaseTable from "@reusables/BaseTable";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createPagination } from "@helpers/utils";
import BasePagination from "@reusables/BasePagination";
import SearchInput from "@components/Dashboard/pages/SettingsPage/General/Locations/components/SearchInput";
import _ from "lodash";
import { Box } from "@mui/material";

export default function SectionsCollapseTable({ location }: { location: Location.Root }) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.locations.subtable" });

    const pagination = createPagination();

    const [search, setSearch] = React.useState("");
    const debouncedSearch = React.useRef(_.debounce(setSearch, 1000)).current;

    const filteredSections = useMemo(() => location.sections?.filter(section => {
        const lowerCaseSearch = search.toLowerCase();
        return section.name.toLowerCase().includes(lowerCaseSearch) ||
            section.sector?.toString().toLowerCase().includes(lowerCaseSearch) ||
            section.row?.toString().toLowerCase().includes(lowerCaseSearch) ||
            section.shelf_height?.toLowerCase().includes(lowerCaseSearch);
    }), [location.sections, search]);


    useEffect(() => {
        pagination.calculate(filteredSections ?? []);
    }, [filteredSections]);

    return (
        <div className={"space-y-2"}>
            {
                !!location.sections?.length && location.sections.length > pagination.perPage.elementsPerPage &&
                <Box
                    className="w-[300px] ml-2 mt-2 mb-4"
                    sx={{
                        "& .MuiInput-input": {
                            borderColor: "#7556FA",
                            opacity: search ? 1 : 0.5,
                            "&:focus": {
                                opacity: 1
                            }
                        }
                    }}
                >
                    <SearchInput
                        onSearch={debouncedSearch}
                        permanent
                    />
                </Box>
            }
            <BaseTable
                pagination={pagination}
                data={filteredSections ?? []}
                columns={[
                    {
                        header: () => t("rowHints.name"),
                        getter: subRow => subRow.name
                    },
                    {
                        header: () => t("rowHints.code"),
                        getter: subRow => subRow.code ?? "-"
                    },
                    {
                        header: () => t("rowHints.sector"),
                        getter: subRow => subRow.sector ?? "-",
                    },
                    {
                        header: () => t("rowHints.row"),
                        getter: subRow => subRow.row ?? "-"
                    },
                    {
                        header: () => t("rowHints.shelfHeight"),
                        getter: subRow => subRow.shelf_height ?? "-"
                    }
                ]}

                size="small"

                hideTableWhenNothingFound
                nothingFound={{
                    height: 100
                }}
            />
            {!!location.sections?.length
                && location.sections.length > pagination.perPage.elementsPerPage
                && <BasePagination
                    config={pagination}
                />}
        </div>
    );
}