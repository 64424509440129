import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { globalStore } from "@/redux/store";
import { authApi } from "@redux/features/auth/authApi";


// @ts-ignore
window.Pusher = Pusher;

const envType = import.meta.env.VITE_ENV;

const laravelEcho = new Echo({
    broadcaster: "pusher",
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    wsHost: import.meta.env.VITE_MIX_PUSHER_HOST,
    wsPort: import.meta.env.VITE_MIX_PUSHER_PORT,
    wssPort: import.meta.env.VITE_MIX_PUSHER_PORT,
    authorizer: (channel: { name: string }, options: unknown) => {
        return {
            authorize: (socketId: number, callback: (x: unknown, y?: unknown) => void) => {
                globalStore.dispatch(authApi.endpoints.authEchoChannel.initiate({
                    socketId,
                    channelName: channel.name
                }))
                .unwrap()
                .then(response => {
                    console.log("Echo server connection established", channel.name, response);
                    callback(null, response);
                })
                .catch(error => {
                    callback(error);
                    console.error("Unable to establish connection with echo server", channel.name, error);
                });
            }
        };
    },
    forceTLS: envType === "production",
    encrypted: true,
    disableStats: true,
    enabledTransports: ["ws", "wss"]
});

export default laravelEcho;