import React from "react";
import { useTranslation } from "react-i18next";
import ZeroOneImg from "../../../assets/images/01.svg";
import ZeroTwoImg from "../../../assets/images/02.svg";

export const Attendance = () => {
  const { t } = useTranslation();

  const cardArr = [
    {
      img: ZeroOneImg,
      header: t("pricingPage.attendanceBlock.card.1.title"),
      description: t("pricingPage.attendanceBlock.card.1.desc"),
    },
    {
      img: ZeroTwoImg,
      header: t("pricingPage.attendanceBlock.card.2.title"),
      description: t("pricingPage.attendanceBlock.card.2.desc"),
    },
  ];

  return (
    <>
      <div className="flex flex-col items-center space-y-8 font-display mb-16">
        <div className="text-lg text-[#6959FC] uppercase tracking-widest">
          {t("pricingPage.attendanceBlock.preTitle")}
        </div>
        <div className="text-[56px] text-[#2B2A35] font-[700] leading-[64.4px]">
          {t("pricingPage.attendanceBlock.title")}
        </div>
        <div className="max-w-xl text-gray-600 text-center">
          {t("pricingPage.attendanceBlock.description")}
        </div>
      </div>
      <div className="grid tablet:grid-cols-2 gap-8">
        {cardArr.map((card, idx) => (
          <div
            className="p-8 border border-[#6959FC] rounded-lg space-y-4"
            key={idx}
          >
            <div className="text-[#8850F4] font-bold text-5xl mb-4">
              {`0${idx + 1}.`}
            </div>
            <div className="text-xl text-black font-semibold">{card.header}</div>
            <p className="text-base text-gray-600">{card.description}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default Attendance;
