import React from "react";
import { Input, Select, InputProps, MenuItem, Button } from "@mui/material";
import BaseSwitch from "../BaseSwitch";
import { ReactComponent as IcArrow } from "@assets/icons/ic_expand_down.svg";
import BaseCheckbox from "../BaseCheckboxLegacy";

type FormItemType = "input" | "select" | "text" | string;
type FormItemOption = {
  label: string;
  value: string | number | Record<string, any>;
};

type BaseFormItemProps = {
  key?: string | number;
  containerClassName?: string;
  type: FormItemType;
  label?: any;
  options?: FormItemOption[];
  value?: any;
  withToggle?: boolean;
  enabled?: boolean;
  onChange?: (event: any) => any;
  onToggle?: (event: any) => any;
  onCreateOption?: () => any;
} & Partial<InputProps>;

const BaseFormItem = ({
  containerClassName,
  type,
  label,
  withToggle,
  enabled = true,
  onToggle,
  ...rest
}: BaseFormItemProps) => {
  const ControlComponent = {
    input: Input,
    select: ({ options, onCreateOption, ...rest }: any) => (
      <Select
        IconComponent={IcArrow}
        MenuProps={{
          PaperProps: {
            sx: {
              "&.MuiPaper-root": {
                boxShadow: "-6px 5px 33px rgba(59, 65, 208, 0.11) !important",
                py: 2,
                mt: 2,
              },
            },
          },
        }}
        inputProps={{ "aria-label": "Without label" }}
        {...rest}
      >
        {options?.map(({ label, value }: { label: string; value: string }) => (
          <MenuItem key={value} value={value}>
            <div className="p-2 text-base leading-[1.5] font-[400]">
              {label}
            </div>
          </MenuItem>
        ))}
        {onCreateOption && (
          <div className="mt-2 px-4 w-full">
            <Button
              onClick={onCreateOption}
              className="w-full"
              variant="contained"
              color="neutral"
            >
              Create
            </Button>
          </div>
        )}
      </Select>
    ),
    "checkbox-group": ({ options, className }: any) => (
      <div className={`${className}`}>
        {options?.map(({ key, label, value }: any) => (
          <BaseCheckbox key={key} label={label} value={value} />
        ))}
      </div>
    ),

    text: ({ value, ...rest }: any) => (
      <div className="text-description" {...rest}>
        {value}
      </div>
    ),
  }[type];

  const labelDisabledClassName = !enabled
    ? "text-[#E5E5E5]"
    : "text-secondary-gray";

  return (
    <div className={containerClassName}>
      <div className="flex justify-between items-center mb-1">
        {label && (
          <div
            className={`font-semibold text-base leading-[1.5] ${labelDisabledClassName}`}
          >
            {label}
          </div>
        )}
        {withToggle && (
          <BaseSwitch
            defaultChecked
            value={enabled}
            onChange={(e) => onToggle && onToggle(e.target.checked)}
          />
        )}
      </div>
      {ControlComponent && <ControlComponent disabled={!enabled} {...rest} />}
    </div>
  );
};

export default BaseFormItem;

export type { BaseFormItemProps };
