import React, { useMemo } from "react";
import { Control, Controller, useController, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { Alert, Stack } from "@mui/material";
import BaseInput from "@reusables/BaseInput";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import { SerialNumbersScheme } from "./index";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import BaseTooltip from "@/components/reusables/BaseTooltip";
import { MessageSquareText } from "lucide-react";

export function SerialGroup({index, control, preview}: {index: number, control: Control<SerialNumbersScheme>, preview?: boolean;}){
    const { t } = useTranslation("", { keyPrefix: "sales.picking.modals.serialNumbers" });

    const {field: line, fieldState: {error}} = useController({
        control,
        name: `lines.${index}`
    });

    const serialNumbersError = useMemo(() => {
        if(error && "serial_numbers" in error){
            return (error.serial_numbers as {root?: {message: string}} & {message?: string});
        }
    }, [error])

    const { fields: serialNumbers, prepend, remove } = useFieldArray({
        control,
        name: `lines.${index}.serial_numbers` as 'lines.0.serial_numbers'
    });

    return (
        <div>
            <div className="flex items-center mb-[32px]">
                <div className="flex justify-start items-center grow">
                    <div className="text-lg font-semibold text-accent">{line.value.product.name}</div>
                    <div className="ml-2">
                        {line.value.comment && (
                            <BaseTooltip
                                title={line.value.comment}
                                placement="bottom"
                            >
                                <MessageSquareText className="w-5 ml-2 text-[#5A5587] hover:text-accent cursor-pointer" />
                            </BaseTooltip>
                        )}
                    </div>
                </div>
                {
                    !preview &&
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                    >
                        {/* Serial inputs number limit (limited with the picked quantity for the product) */}
                        <div className={`opacity-50 ${serialNumbers.length > line.value.picked_quantity ? "text-[#E25C5C]" : ""}`}>
                            ({serialNumbers.length} / {line.value.picked_quantity})
                        </div>
                        {/* Button to append serial inputs to the product */}
                        {/* Prepending, because RHF somehow can't show validation the right way */}
                        <BaseMaterialButton type="button" onClick={() => {
                            prepend({
                                serial_number: ""
                            })
                        }}>
                            {t("buttons.addSerialNumber")}
                        </BaseMaterialButton>
                    </Stack>
                }
            </div>

            {/* Error, if inputs number exceeds picking quantity */}
            {
                !!serialNumbersError?.root &&
                <Alert severity="error" className="mb-[32px]">
                    {serialNumbersError.root?.message}
                </Alert>
            }
            <BaseInputsGrid cols={2} gap={{
                x: 32,
                y: 8
            }}>
                {
                    serialNumbers.map((serialNumber, serialIndex) => (
                        <Stack
                            key={serialNumber.id}
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                        >
                            {/* Serial number value */}
                            <Controller
                                control={control}
                                name={`lines.${index}.serial_numbers.${serialIndex}.serial_number`}
                                render={({ field, fieldState }) => (
                                    <>
                                        <BaseInput
                                            {...field}
                                            error={fieldState.error}

                                            placeholder={t("field.placeholder")}
                                            className="w-full"
                                            sx={{
                                                "& input": {
                                                    background: "#F9F9F9",
                                                    padding: "12px",
                                                    border: 0
                                                }
                                            }}

                                            disabled={preview}
                                        />
                                    </>
                                )}
                            />

                            {/* Deletion button */}
                            {
                                !preview &&
                                <span>
                                    <DeleteSVG
                                        className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                        onClick={() => {
                                            remove(serialIndex)
                                        }}
                                    />
                                </span>
                            }
                        </Stack>
                    ))
                }
            </BaseInputsGrid>
        </div>
    )

}