import React from "react";
import { CustomFields } from "@/types/general";
import BaseInputsGrid, { BaseInputsGridProps } from "@reusables/BaseInputsGrid";
import { useTranslation } from "react-i18next";

interface CFPreviewerProps {
    source?: CustomFields.Embedded.Read;
    inputsGrid?: BaseInputsGridProps;
}

export default function CFPreviewer({ source, inputsGrid }: CFPreviewerProps) {
    if (!source) return null;

    const { t } = useTranslation("", { keyPrefix: "settings.general.customFields.inject" });

    const { custom_fields } = source;

    if (!custom_fields.filter(field => field.value).length) return null;

    return (
        <BaseInputsGrid
            {...inputsGrid}
            title={inputsGrid?.title ?? t("title")}
        >
            {
                custom_fields.map((field, index) => {
                    const { label, value } = field;
                    return (
                        <InputLikeContainer
                            key={index}
                            label={label}
                            content={value}
                        />
                    );
                })
            }
        </BaseInputsGrid>
    );
}

function InputLikeContainer({ label, content }: {
    label: string;
    content?: JSX.Element | string | number | null;
}) {
    return (
        <div>
            <div className="font-semibold text-inputs-label-dim mb-[24px]">{label}</div>
            <div className="text-accent">{content ?? "-"}</div>
        </div>
    );
}
