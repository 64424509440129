import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { Control, Controller, UseFormResetField, UseFormSetValue, useWatch } from "react-hook-form";
import { PickingMutationFormTyping } from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/types";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { useGetShipmondoCarriesQuery, useGetShipmondoProductsQuery } from "@redux/features/shipmondo/shipmondoApi";
import { useAppSelector } from "@redux/hooks";
import { useGetCompanyQuery } from "@redux/features/companies/companiesApi";
import BaseBadge from "@reusables/BaseBadge";
import BaseSwitch from "@reusables/BaseSwitch";
import { Collapse } from "@mui/material";
import { cn, getCountryFlag } from "@helpers/utils";
import { useGetCountriesQuery } from "@redux/api/internalApiSlice";

interface ShipmondoIntegrationProps {
    control: Control<PickingMutationFormTyping>;
    resetField: UseFormResetField<PickingMutationFormTyping>;
    setValue: UseFormSetValue<PickingMutationFormTyping>;
}

export default function ShipmondoIntegration({ control, resetField, setValue }: ShipmondoIntegrationProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.picking.shipmondo" });

    // Loading current company data to extract current country for the carries list
    const companyId = useAppSelector((state) => state.auth.user?.company?.id);
    const { data: companyData, isLoading: isCompanyDataLoading } = useGetCompanyQuery(
        companyId as number,
        {
            skip: !companyId
        }
    );
    const { data: countriesOptions, isLoading: isCountriesOptionsLoading } = useGetCountriesQuery();

    const selectedCarrier = useWatch({
        control,
        name: "shipmondo.carrier"
    });

    const selectedProduct = useWatch({
        control,
        name: "shipmondo.product"
    });

    const isIntegrationEnabled = useWatch({
        control,
        name: "shipmondo.enabled"
    });

    const selectedCountry = useWatch({
        control,
        name: "shipmondo.country"
    });

    const destinationCountry = useWatch({
        control,
        name: "order_destination_country"
    });

    const { data: carrierOptions = [], isLoading: isCarrierOptionsLoading } = useGetShipmondoCarriesQuery({
            sender: selectedCountry?.code as string,
            receiver: destinationCountry?.code as string
        },
        {
            skip: !selectedCountry || !destinationCountry
        }
    );

    const { data: productsOptions = [], isLoading: isProductsOptionsLoading } = useGetShipmondoProductsQuery({
            carrier_code: selectedCarrier?.code as string
        },
        {
            skip: !selectedCarrier
        }
    );

    useEffect(() => {
        if (companyData?.country && !selectedCountry) {
            setValue("shipmondo.country", companyData.country);
        }
    }, [companyData, setValue, selectedCountry]);

    useEffect(() => {
        if (selectedCountry) {
            resetField("shipmondo.carrier");
            resetField("shipmondo.product");
            resetField("shipmondo.services");
        }
    }, [selectedCountry, resetField]);

    useEffect(() => {
        if (selectedCarrier) {
            resetField("shipmondo.product");
            resetField("shipmondo.services");
        }
    }, [selectedCarrier, resetField]);

    useEffect(() => {
        if (selectedProduct) {
            resetField("shipmondo.services");
        }
    }, [selectedProduct, resetField]);

    const deliveryCountriesPathSuffix = selectedCountry?.code && destinationCountry?.code ? ` (${selectedCountry.code} > ${destinationCountry.code})` : "";

    return (
        <div className={cn("transition-all", isIntegrationEnabled ? "opacity-100" : "opacity-50")}>
            <BaseInputsGrid title={
                // "Integration" badge for better UX
                <div className={"flex flex-row items-center justify-between"}>
                    <div className={"flex flex-row items-center space-x-[12px]"}>
                        <div className="text-xl font-semibold text-accent">{t("heading")}</div>
                        <BaseBadge className={"bg-purple-400 text-white"}>
                            {t("integrationBadge")}
                        </BaseBadge>
                    </div>
                    <div>
                        <Controller
                            control={control}
                            name="shipmondo.enabled"
                            render={({ field }) => (
                                <BaseSwitch
                                    checked={field.value}
                                    onChange={(_, checked) => field.onChange(checked)}
                                />
                            )}
                        />
                    </div>
                </div>
            }
                            cols={1}
            >
                <Collapse in={isIntegrationEnabled}>
                    <div className={"space-y-[20px] mt-[20px]"}>
                        <BaseInputsGrid>
                            <Controller
                                control={control}
                                name="shipmondo.country"
                                render={({ field, fieldState }) => (
                                    <BaseDropdown
                                        {...field}
                                        {...fieldState}

                                        label={t("fields.country.label")}
                                        placeholder={t("fields.country.placeholder")}

                                        options={countriesOptions}
                                        getter={{
                                            key: (opt) => opt.id,
                                            label: (opt) => opt.name,
                                            renderOption: (opt, icon) => (
                                                <div>
                                                    <img src={getCountryFlag(opt.code)} width={20}
                                                         alt={opt.name + " flag"} />
                                                    <span className="ml-[8px] grow">{opt.name}</span>
                                                    {icon}
                                                </div>
                                            )
                                        }}
                                        isLoading={isCountriesOptionsLoading}
                                        autocomplete
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="order_destination_country"
                                render={({ field, fieldState }) => (
                                    <BaseDropdown
                                        {...field}
                                        {...fieldState}

                                        label={t("fields.destinationCountry.label")}
                                        placeholder={t("fields.destinationCountry.placeholder")}

                                        options={countriesOptions}
                                        getter={{
                                            key: (opt) => opt.id,
                                            label: (opt) => opt.name,
                                            renderOption: (opt, icon) => (
                                                <div>
                                                    <img src={getCountryFlag(opt.code)} width={20}
                                                         alt={opt.name + " flag"} />
                                                    <span className="ml-[8px] grow">{opt.name}</span>
                                                    {icon}
                                                </div>
                                            )
                                        }}
                                        isLoading={isCountriesOptionsLoading}
                                        autocomplete

                                        disabled
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="shipmondo.carrier"
                                render={({ field, fieldState }) => (
                                    <BaseDropdown
                                        {...field}
                                        {...fieldState}

                                        label={t("fields.carrier.label") + deliveryCountriesPathSuffix}
                                        placeholder={t("fields.carrier.placeholder")}

                                        options={carrierOptions}
                                        getter={{
                                            key: (opt) => opt.id,
                                            label: (opt) => opt.name,
                                            caption: (opt) => opt.code
                                        }}
                                        isLoading={isCarrierOptionsLoading}
                                        autocomplete
                                        virtualize
                                    />
                                )}
                            />
                        </BaseInputsGrid>
                        <BaseInputsGrid>
                            <Controller
                                control={control}
                                name="shipmondo.product"
                                render={({ field, fieldState }) => (
                                    <BaseDropdown
                                        {...field}
                                        {...fieldState}

                                        label={t("fields.product.label")}
                                        placeholder={t("fields.product.placeholder")}

                                        options={productsOptions}
                                        getter={{
                                            key: (opt) => opt.id,
                                            label: (opt) => opt.name,
                                            caption: (opt) => opt.code
                                        }}
                                        isLoading={isProductsOptionsLoading}
                                        autocomplete
                                        virtualize

                                        disabled={!selectedCarrier}
                                        disableReason={t("fields.product.selectCarrierFirst")}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="shipmondo.services"
                                render={({ field, fieldState }) => (
                                    <BaseDropdown
                                        {...field}
                                        {...fieldState}

                                        onChange={(_, values) => {
                                            field.onChange(values);
                                        }}

                                        label={t("fields.service.label")}
                                        placeholder={t("fields.service.placeholder")}

                                        options={selectedProduct?.available_services}
                                        getter={{
                                            key: (opt) => opt.id,
                                            label: (opt) => opt.name,
                                            caption: (opt) => opt.code
                                        }}
                                        isLoading={isProductsOptionsLoading}
                                        autocomplete
                                        virtualize
                                        multiple

                                        disabled={!selectedProduct || !selectedCarrier}
                                        disableReason={t("fields.service.selectProductFirst")}
                                    />
                                )}
                            />
                        </BaseInputsGrid>
                    </div>
                </Collapse>
            </BaseInputsGrid>
        </div>
    );
}