import React from "react";
import BaseTooltip from "@reusables/BaseTooltip";
import { HelpCircle } from "lucide-react";

interface BaseHelpProps {
    value: string;
}

export default function BaseHelp(props: BaseHelpProps) {
    return (
        <BaseTooltip title={props.value}>
            <HelpCircle size={16} color={"#686868"} />
        </BaseTooltip>
    );
}