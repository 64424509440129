import React from "react";
import {useTranslation} from "react-i18next";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import BaseButton from "@reusables/BaseButton";
import {TabProperties} from "../../types";
import BaseCheckbox from "@reusables/BaseCheckbox";
import {useGetCountriesQuery} from "@redux/api/internalApiSlice";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {CustomerFormTyping} from "./TabsController";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BasePhoneInput from "@reusables/BasePhoneInput";
import {getCountryFlag} from "@helpers/utils";
import DeliveryAddress from "./parts/DeliveryAddress";
import FormErrorWarning from "../modals/FormErrorWarning";
import { useAbility } from "@/casl.config";

export default function AddressTab({onNext, onPrev, mode="creation"}: TabProperties & { onPrev: () => void }) {
    const { t } = useTranslation("", { keyPrefix: "customers.general.tabs.address" });
    const ability = useAbility();

    const {control, setValue, getValues} = useFormContext<CustomerFormTyping>();

    const {data: countryOptions = [], isLoading: countryOptionsLoading} = useGetCountriesQuery();

    const {fields: deliveries, append, remove} = useFieldArray({
        control,
        name: "deliveries",
        keyName: "loop_id"
    });

    const selectedBillingCountry = useWatch({
        control,
        name: "billing_address.country"
    });

    return (
        <form className="space-y-[40px]" onSubmit={onNext}>
            <FormErrorWarning />

            <div className="text-xl text-accent">{t("categories.billing")}</div>
            <div>
                <Controller
                    control={control}
                    name="billing_address.is_used_for_shipping"
                    render={({field}) => (
                        <BaseCheckbox
                            {...field}

                            label={t("checkboxes.useForShipping")}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />
            </div>
            <BaseInputsGrid>
                <Controller
                    control={control}
                    name="billing_address.name"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.name.label") + " *"}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="billing_address.street"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.street.label")}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="billing_address.street_2"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.street2.label")}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="billing_address.zipcode"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.zipcode.label")}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="billing_address.city"
                    render={({field, fieldState}) => (
                        <>
                            <BaseInput
                                {...field}
                                error={fieldState.error}

                                label={t("fields.city.label")}
                                disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                            />
                        </>
                    )}
                />

                <Controller
                    control={control}
                    name="billing_address.country"
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}

                            options={countryOptions}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name,
                                renderOption: (opt, icon) => (
                                    <div>
                                        <img src={getCountryFlag(opt.code)} width={20} alt={opt.name + " flag"}/>
                                        <span className="ml-[8px] grow">{opt.name}</span>
                                        {icon}
                                    </div>
                                )
                            }}
                            label={t("fields.country.label")}
                            isLoading={countryOptionsLoading}
                            autocomplete
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="billing_address.phone"
                    render={({field, fieldState}) => (
                        <BasePhoneInput
                            {...field}
                            {...fieldState}
                            label={t('fields.phone.label')}
                            size='md'
                            variant='custom'
                            country={!getValues("billing_address.phone") ? selectedBillingCountry?.code : undefined}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="billing_address.email"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.email.label")}
                            type={"email"}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />
            </BaseInputsGrid>

            {
                deliveries.map((address, index) => 
                    <DeliveryAddress
                        key={address.loop_id}
                        mode={mode}
                        index={index}
                        control={control}
                        onAppend={() => append({})}
                        onRemove={() => {
                            if (address.id != undefined) {
                                setValue("deleted_deliveries", [...getValues("deleted_deliveries"), address.id])
                            }

                            remove(index);
                        }}
                    />
                )
            }

            <div className="flex justify-center space-x-[24px]">
                <BaseButton
                    text={t("buttons.prev")}
                    size="md"
                    buttonWidth="200px"
                    primaryOutlined
                    type={"button"}

                    onClick={onPrev}
                />
                <BaseButton
                    text={t("buttons.next")}
                    size="md"
                    buttonWidth="200px"
                />
            </div>
        </form>
    );
}