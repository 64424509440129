import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

function fetchLocations() {
  const API_URL = import.meta.env.VITE_API_URL;
  const token = localStorage.getItem("sanctum");

  const [currentLocationsPage, setCurrentLocationsPage] = useState(1);

  const [locationsLoading, setLocationsLoading] = useState<boolean>(false);
  const [locationsData, setLocationsData] = useState<Array<any>>([]);
  const [locationsLinks, setLocationsLinks] = useState<any | null>(null);
  const [locationsMeta, setLocationsMeta] = useState<any | null>(null);

  const changePage = () => {
    setLocationsLoading(true);

    if (token) {
      axios
        .get(`${API_URL}/api/location?page=${currentLocationsPage}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          setLocationsData(res?.data?.payload);
          setLocationsLinks(res.data.links);
          setLocationsMeta(res.data.meta);
        })
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {
          setLocationsLoading(false);
        });
    }
  };
  useEffect(() => {
    changePage();
  }, [currentLocationsPage]);

  return {
    locationsLoading,
    locationsData,
    locationsLinks,
    locationsMeta,
    currentLocationsPage,
    setCurrentLocationsPage,
  };
}

// Store
function storeLocation() {
  const API_URL = import.meta.env.VITE_API_URL;
  const token = localStorage.getItem("sanctum");

  const history = useHistory();
  const [createAndNew, setCreateAndNew] = useState<boolean>(false);
  const [storeLoading, setStoreLoading] = useState<boolean>(false);

  const reloadPage = () => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    history.go(0);
  };

  const redirectToLocationPage = () => {
    history.push(`/dashboard/inventory/locations`);
  };

  const callStoreLocation = (params: any) => {
    setStoreLoading(true);

    axios
      .post(`${API_URL}/api/location`, params, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        createAndNew ? reloadPage() : redirectToLocationPage();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setStoreLoading(false);
      });
  };

  return { storeLoading, callStoreLocation, createAndNew, setCreateAndNew };
}

export { fetchLocations, storeLocation };
