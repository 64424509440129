import React, { useState } from "react";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { useGetBOMQuery } from "@redux/features/bom/bomApi";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import ViewLayout from "../components/ViewLayout";
import { Stack } from "@mui/material";
import ProduceModal from "../components/modals/ProduceModal";
import BaseTinyActionIcon, { TinyActionIconType } from "@reusables/BaseTinyActionIcon";
import { Can } from "@/casl.config";
import DeletionModal from "../components/modals/DeletionModal";
import BaseTooltip from "@/components/reusables/BaseTooltip";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import { copyBOM } from "@components/Dashboard/pages/BOM/utils";

export default function BOMDetailsPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "bom.details" });

    const { id } = useParams<{ id: string }>();

    const { data: bom, isLoading: isBOMLoading } = useGetBOMQuery(parseInt(id));

    const [isProduceModalOpen, setIsProduceModalOpen] = useState<boolean>(false);

    const [isDeletionModalOpen, setIsDeletionModalOpen] = React.useState<boolean>(false);

    return (
        <>
            <Can not I="bill_of_materials.view" a="product">
                <div className="levitation-extended">
                    <NoPermissionBanner />
                </div>
            </Can>

            <Can I="bill_of_materials.view" a="product">
                <Can not I="bill_of_materials.view" a="product">
                    <div className="levitation-extended">
                        <NoPermissionBanner />
                    </div>
                </Can>

                <Can I="bill_of_materials.view" a="product">
                    <BaseRecordRenderer
                        record={bom}
                        loading={isBOMLoading}
                        hideLevitation
                    >
                        <div className="space-y-[40px] mb-[24px] levitation-extended">
                            <div className="flex items-center">
                                <h6 className="text-accent grow">{bom?.name}</h6>
                                <Stack direction={"row-reverse"} spacing={4} alignItems={"center"}>
                                    <div className="space-x-[8px] flex items-center h-full">
                                        <BaseTooltip title={`${t("actions.produce")}`} placement="bottom">
                                            <BaseTinyActionIcon
                                                type={TinyActionIconType.Produce}
                                                onClick={() => setIsProduceModalOpen(true)}
                                            />
                                        </BaseTooltip>
                                        <Can I="bill_of_materials.create" a="product">
                                            <BaseTinyActionIcon
                                                type={TinyActionIconType.Copy}
                                                onClick={() => {
                                                    if (!bom) return;

                                                    copyBOM(bom, history);
                                                }}
                                            />
                                            <BaseTooltip title={`${t("actions.edit")}`} placement="bottom">
                                                <BaseTinyActionIcon
                                                    type={TinyActionIconType.Edit}
                                                    onClick={() => void history.push(`/dashboard/bill-of-materials/${bom?.id}/edit`)}
                                                />
                                            </BaseTooltip>
                                        </Can>
                                        <Can I="bill_of_materials.create" a="product">
                                            <BaseTooltip title={`${t("actions.delete")}`} placement="bottom">
                                                <BaseTinyActionIcon
                                                    type={TinyActionIconType.Delete}
                                                    onClick={() => setIsDeletionModalOpen(true)}
                                                />
                                            </BaseTooltip>
                                        </Can>
                                    </div>
                                </Stack>
                            </div>
                            <ViewLayout.main bom={bom as NonNullable<typeof bom>} />
                        </div>
                        <ViewLayout.history bom={bom as NonNullable<typeof bom>} />
                    </BaseRecordRenderer>
                </Can>

                {/* PRODUCE MODAL */}
                <ProduceModal
                    isOpen={isProduceModalOpen}
                    onClose={() => setIsProduceModalOpen(false)}
                    entity_id={bom?.id}
                />

                {/* DELETION MODAL */}
                <DeletionModal
                    isOpen={isDeletionModalOpen}
                    entity={bom}
                    onClose={() => setIsDeletionModalOpen(false)}
                    onDeletion={() => history.push("/dashboard/bill-of-materials")}
                />
            </Can>
        </>
    );
}
