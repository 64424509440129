import React, { forwardRef, useContext, useEffect, useMemo, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, PickersDay, PickersDayProps } from "@mui/x-date-pickers";

import { ReactComponent as CalendarIcon } from "@assets/icons/ic_calendar.svg";
import { ReactComponent as CloseIcon } from "@assets/icons/ic_cross_circled.svg";


import { DatepickerDayjs, DatepickerRange, RangeDatepickerProps, SimpleDatepickerProps } from "./types";
import { DatepickerRangeContext } from "./context";

import { swap, TransientAdapter } from "@helpers/utils";

import dayjs, { Dayjs } from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import { styled } from "@mui/material/styles";
import { InputBasics } from "@reusables/reusablesCore";
import { Box, SxProps } from "@mui/material";

dayjs.extend(isBetweenPlugin);


type BaseDatepickerProps = {
    /**
     * Gray label above the input.
     */
    label?: string;
    /**
     * Input placeholder.
     */
    placeholder?: string;
    /**
     * Specifies dayjs format for displaying dates.
     * @default DD.MM.YYYY
     */
    format?: string;
    /**
     * Date change callback.
     * @param value selected date as dayjs object.
     * @param keyboardInputValue
     */
    onChange?: (value: DatepickerDayjs) => void;
    disablePast?: boolean;
    brightLabel?: boolean;
    disableFuture?: boolean;
    inputSx?: SxProps;
} & (SimpleDatepickerProps | RangeDatepickerProps) & Omit<InputBasics<DatepickerDayjs>, "value" | "onChange">; // allows specifying range datepicker or simple datepicker (not both)

const BaseDatepicker = forwardRef<HTMLInputElement, BaseDatepickerProps>(({
                                                                              label,
                                                                              placeholder,
                                                                              format = "DD.MM.YYYY",
                                                                              onChange,
                                                                              rangeConfig,
                                                                              value: dpValue,
                                                                              brightLabel,
                                                                              disablePast,
                                                                              disableFuture,
                                                                              disabled = false,
                                                                              error,
                                                                              inputSx
                                                                          }, ref) => {

    const [currentValue, setCurrentValue] = useState<DatepickerDayjs>();
    const rangeContext = useContext(DatepickerRangeContext);

    const isSoloRange = useMemo(() => rangeConfig?.role === "solo", [rangeConfig]);

    /**
     * This hooks handles internal rangeContext updates to update field`s data, but pay attention,
     * that for simple datepickers, value change is handled by using setCurrentValue directly from onChange listener
     */
    useEffect(() => {

        if (rangeConfig && rangeContext[rangeConfig.groupKey] && !dpValue) {
            if (isSoloRange) {
                setCurrentValue(rangeContext[rangeConfig.groupKey].range?.["from"]);
            } else {
                setCurrentValue(rangeContext[rangeConfig.groupKey].range?.[rangeConfig.role as "from" | "to"]);
            }
        } else {
            setCurrentValue(dpValue);
        }
    }, [rangeContext, dpValue]);

    /**
     * Because of custom input, we have to manage open state by ourselves.
     */
    const [open, setOpen] = useState<boolean>(false);

    const datepickerChangeHandler = (value: DatepickerDayjs) => {
        if (onChange) onChange(value);
    
        // ! NOTE: range change is handled by useEffect above, not by setCurrentValue directly, as for simple datepicker
        if (rangeContext && rangeConfig && value) {
            if (isSoloRange) { // if we use only one input for date range
                let range = rangeContext[rangeConfig.groupKey].range;
                if (!range?.from) {
                    range = { from: value, to: null };
                } else if (!range.to) {
                    if (value.isBefore(range.from)) {
                        range = { from: value, to: range.from };
                    } else {
                        range = { from: range.from, to: value };
                    }
                } else {
                    range = { from: value, to: null };
                }
    
                rangeContext[rangeConfig.groupKey].setRange(range);
            } else if (rangeContext[rangeConfig.groupKey].eachOtherExclude) { // If we have to exclude another date, when the current is selected
                // Creating empty object and filling only current field
                const newRange: DatepickerRange = { from: null, to: null };
                newRange[rangeConfig.role as "from" | "to"] = value;
    
                rangeContext[rangeConfig.groupKey].setRange(newRange);
            } else { // If we have to set both dates in range and correct range, if "from" is after "to" (or vice versa)
                // Copying original range object and replacing only current field
                const newRange = { ...(rangeContext[rangeConfig.groupKey].range ?? { to: null, from: null }) };
    
                if (rangeConfig.role === "from") {
                    newRange.from = value;
                    if (newRange.to && newRange.from.isAfter(newRange.to)) {
                        newRange.to = null;
                    }
                } else if (rangeConfig.role === "to") {
                    newRange.to = value;
                    if (newRange.from && newRange.to.isBefore(newRange.from)) {
                        newRange.from = null;
                    }
                }
    
                rangeContext[rangeConfig.groupKey].setRange(newRange);
            }
        } else { // when we send null to that function to remove current value
            if (rangeContext && rangeConfig) {
                if (isSoloRange) {
                    rangeContext[rangeConfig.groupKey].setRange({ to: null, from: null });
                } else {
                    const newRange = rangeContext[rangeConfig.groupKey].range ?? { to: null, from: null };
                    newRange[rangeConfig.role as "from" | "to"] = null;
                    rangeContext[rangeConfig.groupKey].setRange(newRange);
                }
            }
    
            setCurrentValue(value);
        }
    };
    
    return (
        <div>
            {label &&
                <p className={`mb-2 font-semibold${brightLabel ? " text-inputs-label-bright" : " text-inputs-label-dim"}`}>{label}</p>}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    closeOnSelect={!isSoloRange}
                    reduceAnimations

                    open={open}
                    onChange={datepickerChangeHandler}
                    onClose={() => setOpen(false)}

                    value={currentValue}

                    disablePast={disablePast}
                    disableFuture={disableFuture}

                    renderInput={({ inputRef, ...input_props }) => {
                        const inputValue = getDatepickerInputText();

                        return (
                            // inputRef is needed, to let MUI know how to position the datepicker window
                            <Box className="relative" sx={inputSx}>
                                <input
                                    readOnly
                                    placeholder={placeholder}
                                    className={
                                        `w-full rounded-[8px] p-[16px] bg-main border-[1px] border-solid font-[300]
                      ${disabled ? "bg-inputs-disabled" : "bg-inputs-default"}
                      ${error ? "border-inputs-border-error text-inputs-textError placeholder:text-inputs-textError" : `${disabled ? "border-transparent" : "border-inputs-border-default"} text-inputs-text placeholder:text-inputs-placeholder`} 
                      ${open ? "border-inputs-border-focused" : ""} 
                      transition-[.25s]`
                                    }
                                    value={inputValue}
                                    ref={inputRef}
                                    onClick={() => setOpen(true)}
                                    disabled={disabled}
                                />

                                {
                                    inputValue.length && !disabled ?
                                        <CloseIcon
                                            className="absolute top-50 right-[16px] translate-middle-y cursor-pointer text-datepicker-closeIcon"
                                            onClick={() => datepickerChangeHandler(null)} />
                                        :
                                        <CalendarIcon
                                            className="absolute top-50 right-[16px] translate-middle-y text-datepicker-inputIcon" />
                                }
                            </Box>
                        );
                    }
                    }

                    renderDay={
                        (date, selectedDays, pickersDayProps) =>
                            CustomDay(rangeContext && rangeConfig ? rangeContext[rangeConfig.groupKey]?.range : undefined,
                                date as Dayjs,
                                selectedDays as Array<dayjs.Dayjs | null>,
                                pickersDayProps as PickersDayProps<dayjs.Dayjs>)
                    }

                    PaperProps={{
                        sx: {
                            "& *": {
                                fontFamily: "Poppins",
                                color: (theme) => theme.custom.textAccent + "!important"
                            },
                            boxShadow: "0px 19.2249px 53.4024px rgba(25, 42, 89, 0.08)",
                            borderRadius: "16px",
                            padding: "20px",
                            "& .MuiDayPicker-header": {
                                "& .MuiTypography-root": {
                                    height: "unset",
                                    fontSize: 14
                                },
                                borderBottom: (theme) => `1px solid ${theme.custom.gray[400]}`,
                                paddingBottom: "16px",
                                paddingTop: "24px"
                            },
                            "& .PrivatePickersSlideTransition-root": {
                                minHeight: "unset",
                                paddingTop: "16px"
                            },
                            "& .MuiCalendarOrClockPicker-root>div, & .MuiCalendarPicker-root": {
                                maxHeight: "unset"
                            },
                            "& .MuiPickersDay-root, & .PrivatePickersYear-root button, & .MuiPickersYear-root": {
                                "&.Mui-selected": {
                                    backgroundColor: (theme) => theme.custom.datepicker.activeDayBackground + "!important"
                                },
                                "&.Mui-disabled": {
                                    color: (theme) => theme.custom.gray[400] + "!important"
                                }
                            }
                        }
                    }}

                    disableHighlightToday={true}
                    showDaysOutsideCurrentMonth={false} />
            </LocalizationProvider>
        </div>
    );

    // TODO: [nekear] If needed, we can add calendar days selection animations by tracking hover effect and updating some react state, I think
    // but this task is postponed, because of potential useless of the date range feature in the future (written 2023.01.06)
    function CustomDay(range: DatepickerRange | undefined,
                       date: dayjs.Dayjs,
                       selectedDates: Array<dayjs.Dayjs | null>,
                       pickersDayProps: PickersDayProps<dayjs.Dayjs>) {
        if (range) {
            // Disabling "selected" property to remove native .mui-selected
            pickersDayProps = { ...pickersDayProps, selected: false };

            if (range.from?.isSame(date, "day") && range.to) {
                return <ActiveDay {...pickersDayProps} _backdropPosition="right" />;
            } else if (range.to?.isSame(date, "day") && range.from) {
                return <ActiveDay {...pickersDayProps} _backdropPosition="left" />;
            } else if (range.to && range.from && date.isBetween(range.to, range.from)) {
                return <DefaultDay {...pickersDayProps} sx={{ background: "#DAEFB7", borderRadius: 0 }} />;
            } else if (range.from?.isSame(date, "day") || range.to?.isSame(date, "day")) {
                return <ActiveDay {...pickersDayProps} />;
            }
        }

        return <DefaultDay {...pickersDayProps} />;
    }

    function getDatepickerInputText(): string {
        if (isSoloRange && rangeConfig && rangeContext) {
            const range = rangeContext[rangeConfig.groupKey]?.range;

            if (range?.from && range?.to)
                return range.from.format(format) + " - " + range.to.format(format);

            if (range?.from)
                return range.from.format(format);

            if (range?.to)
                return range.to.format(format);

            return "";
        } else {
            return currentValue?.format(format) ?? "";
        }
    }
});

const DefaultDay = styled(PickersDay)(({ theme }) => ({
    position: "relative",
    zIndex: 100,

    width: 40,
    height: 40,
    backgroundColor: "transparent",
    margin: 0,

    fontSize: 14
})) as React.ComponentType<PickersDayProps<Dayjs>>;


const ActiveDay = styled(DefaultDay, TransientAdapter)<{
    _backdropPosition?: "left" | "right"
}>(({ theme, ...props }) => ({
    ...(props._backdropPosition && {
        "&:before": {
            content: "''",
            background: "#DAEFB7",
            position: "absolute",
            width: "50%",
            height: "100%",
            ...(props._backdropPosition === "left" ? { left: 0 } : { right: 0 }),
            top: 0,
            zIndex: -1
        }
    }),
    "&:after": {
        content: "''",
        background: "#A6DD4C",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "100%",
        zIndex: -1
    }
})) as React.ComponentType<PickersDayProps<Dayjs> & { _backdropPosition?: "left" | "right" }>;

BaseDatepicker.displayName = "BaseDatepicker";

export default BaseDatepicker;

/**
 * EXAMPLE (range datepicker should be covered in DatepickerRangeContext):
 * const [range, setRange] = useState<DatepickerRange>({
 *       from: null,
 *       to: null
 *     });
 *
 * <DatepickerRangeContext.Provider value={{
 *                       "date": {range: range, setRange: setRange}
 *                     }}>
 *   <BaseDatepicker placeholder="Date from" rangeConfig={{groupKey: "date", role: "from"}}/>
 *   <BaseDatepicker placeholder="Date to" rangeConfig={{groupKey: "date", role: "to"}}/>
 * </DatepickerRangeContext.Provider>
 */