import { Dayjs } from "dayjs";
import { isValidPhoneNumber, validatePhoneNumberLength } from "libphonenumber-js/min";

const noSpaces = {
    refine: (value?: string | null) => {
        return !value?.includes(" ");
    },
    message: {
        params: {
            i18n: "general.validation.global.noSpaces"
        }
    }
};

const required = {
    refine: (value?: unknown) => {
        return !!value;
    },
    message: {
        params: {
            i18n: "general.validation.global.required"
        }
    }
};

const nonNumeric = {
    refine: (value?: string | null) => {
        return !value?.match(/\d/);
    },
    message: {
        params: {
            i18n: "general.validation.global.nonNumeric"
        }
    }
};

// field should contain at least one letter
const alphaNumeric = {
    refine: (value?: string | null) => {
        return !value || value.match(/[a-zA-Z]/);
    },
    message: {
        params: {
            i18n: "general.validation.global.alphaNumeric"
        }
    }
};

const numericRequired = {
    refine: (value?: string | null) => {
        return !value || value.match(/\d/);
    },
    message: {
        params: {
            i18n: "general.validation.global.numericRequired"
        }
    }
};

const domainName = {
    refine: (value?: string | null) => {
        return !value || value.match(/^(?!.*--)[A-Za-z0-9-]{1,63}(?:\.[A-Za-z0-9-]{1,63})*\.[A-Za-z]{2,}$/);

    },
    message: {
        params: {
            i18n: "general.validation.global.domainName"
        }
    }
};

const email = {
    refine: (value?: string | null) => {
        return !value || value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    },
    message: {
        params: {
            i18n: "general.validation.global.email"
        }
    }
};

const positive = {
    refine: (value?: number | null) => {
        return (value ?? 0) >= 0;
    },
    message: {
        params: {
            i18n: "general.validation.global.positive"
        }
    }

};

const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp"];
const imageFormat = {
    refine: (file: any) => !file || file.type === "" || ACCEPTED_IMAGE_TYPES.includes(file?.type),
    message: {
        params: {
            i18n: "general.validation.global.imageFormats"
        }
    }
};

/**
 * @param size (in bytes)
 * @param suffix
 */
const fileSize = (size: number, suffix: "KB" | "MB" | "GB") => ({
    refine: (file: any) => !file || file?.size < size,
    message: {
        params: {
            i18n: {
                key: "general.validation.global.fileSize",
                values: {
                    size: size / 1000 + suffix
                }
            }
        }
    }
});

const maxDecimalPrecision = (maxPrecision: number) => ({
    refine: (value?: string | number | null) => {
        if (value) {
            const valueStr = typeof value === "number" ? value.toString() : value;
            const parts = valueStr.split(".");
            // Checking if there is a decimal part and if its length is within the allowed precision
            return parts.length === 2 ? parts[1].length <= maxPrecision : true;
        }
    },
    message: {
        params: {
            i18n: {
                key: "general.validation.global.maxDecimalPrecision",
                values: {
                    maxPrecision
                }
            }
        }
    }
});

const validDate = {
    refine: (value: Dayjs) => {
        return value.isValid();
    },
    message: {
        params: {
            i18n: "general.validation.global.invalidDate"
        }
    }
};

const boundedBy = (min: number, max: number) => ({
    refine: (value?: number | null) => {
        return (value ?? 0) >= min && (value ?? 0) <= max;
    },
    message: {
        params: {
            i18n: {
                key: "general.validation.global.boundedBy",
                values: {
                    min,
                    max
                }
            }
        }
    }
});

const isPhoneValid = {
    refine: (phone: string) => {
        return isValidPhoneNumber(`+${phone}`);
    },
    message: {
        params: {
            i18n: "general.validation.global.invalidPhoneNumber"
        }
    }
};

const isPhoneNumberLengthValid = {
    refine: (phone: string) => {
        return validatePhoneNumberLength(`+${phone}`);
    },
    message: {
        params: {
            i18n: "general.validation.global.invalidPhoneNumberLength"
        }
    }
};

export const vatNumberPatterns: Record<string, RegExp> = {
    "AT": /^ATU\d{8}$/, // Austria
    "BE": /^BE0\d{9}$/, // Belgium
    "DE": /^DE\d{9}$/, // Germany
    "DK": /^DK\d{8}$/, // Denmark
    "ES": /^ES([A-Z0-9]{9})$/, // Spain
    "FR": /^FR[A-Z0-9]{2}\d{9}$/, // France
    "GB": /^GB(\d{9}|\d{12}|(GD|HA)\d{3})$/, // United Kingdom
    "NL": /^NL\d{9}B\d{2}$/, // Netherlands
    "NO": /^NO\d{9}$/, // Norway
    "PL": /^PL\d{10}$/, // Poland
    "SE": /^SE\d{10}01$/, // Sweden
    "UA": /^UA\d{12}$/, // Ukraine,
    "LV": /^LV\d{11}$/, // Latvia
    "LT": /^LT(\d{9}|\d{12})$/ // Lithuania
};

const validateCRN = {
    // Define all patterns in an array
    refine: (value: string) => {
        // If the value is empty or undefined, consider it valid
        if (!value) {
            return true;
        }
        // Extract the country code
        const countryCode = value.substring(0, 2).toUpperCase();

        // Find the pattern for the country code
        const pattern = vatNumberPatterns[countryCode];

        // If no country code is found, return false
        if (!pattern) {
            return false;
        }

        // Validate the registration number
        return pattern.test(value);
    },
    message: {
        params: {
            i18n: "general.validation.global.invalidRegistrationNumber"
        }
    }
};

export const refinements = {
    noSpaces,
    required,
    nonNumeric,
    alphaNumeric,
    numericRequired,
    domainName,
    email,
    imageFormat,
    fileSize,
    positive,
    maxDecimalPrecision,
    validDate,
    boundedBy,
    validateCRN,
    isPhoneValid,
    isPhoneNumberLengthValid
};