import React from 'react'
import BaseTooltip from '../BaseTooltip';
import { Actions, Modules, useAbility } from '@/casl.config';
import { TooltipProps } from '@mui/material';

type Props = {
    action: Actions,
    module: Modules,
    children: React.ReactElement,
    expression?: boolean,
    title?: string,
    placement?: TooltipProps['placement']
}

export default function BasePermissionBlocker({ action, module, children, expression = true, title = "Access denied", placement = "bottom" }: Props) {
    const ability = useAbility();

    return (
        <BaseTooltip active={expression && ability.cannot(action, module)} title={title} placement={placement}>
            {children}
        </BaseTooltip>
    )
}
