import React from "react";

import { ReactComponent as AllProductsIcon } from "@assets/icons/sidebar/ic_small_bag.svg";
import { ReactComponent as AllPurchasesIcon } from "@assets/icons/sidebar/ic_market_bucket.svg";
import { ReactComponent as ReceiveIcon } from "@assets/icons/sidebar/ic_package_receive.svg";
import { ReactComponent as AdjustmentIcon } from "@assets/icons/sidebar/ic_present.svg";
import { ReactComponent as TransfersIcon } from "@assets/icons/sidebar/ic_buildings.svg";
import { ReactComponent as PickingIcon } from "@assets/icons/sidebar/ic_picking.svg";
import { ReactComponent as SalesIcon } from "@assets/images/icons/dashboard/sales.svg";
import { ReactComponent as InventoryIcon } from "@assets/icons/sidebar/ic_packages.svg";
import { ReactComponent as SuppliersIcon } from "@assets/icons/sidebar/ic_package_delivery.svg";
import { ReactComponent as CustomerIcon } from "@assets/icons/sidebar/ic_person.svg";
import { Permission } from "@/types/general";

/**
 * That's the first, ugly version of sidebar configuration via such config objects.
 * Intention is to make links and icons consistent and available throughout the project with one of the main consumers being the sidebar and the feed.
 */

export const modules_links: Partial<Record<Permission.Components.Modules, { link: string; icon: JSX.Element }>> = {
    "product": { link: "/dashboard/products", icon: <AllProductsIcon /> },
    "purchase_order": { link: "/dashboard/purchasing", icon: <AllPurchasesIcon /> },
    "receive": { link: "/dashboard/receive", icon: <ReceiveIcon /> },
    "sale_order": { link: "/dashboard/sales/orders", icon: <SalesIcon /> },
    "picking": { link: "/dashboard/picking", icon: <PickingIcon /> },
    "adjustment": { link: "/dashboard/inventory/adjustments", icon: <AdjustmentIcon /> },
    "transfer": { link: "/dashboard/inventory/transfers", icon: <TransfersIcon /> },
    "stock_count": { link: "/dashboard/inventory/stock", icon: <InventoryIcon /> },
    "supplier": { link: "/dashboard/suppliers", icon: <SuppliersIcon /> },
    "customer": { link: "/dashboard/customers", icon: <CustomerIcon /> },
};