import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetFeedQuery } from "@redux/features/dashboard/dashboardApi";
import BaseMicroUtils from "@/components/reusables/BaseMicroUtils";
import { Link } from "react-router-dom";
import { Pagination, Stack } from "@mui/material";
import { ArrayElementType, removeEmpty, usePagination } from "@helpers/utils";
import BaseTable from "@reusables/BaseTable";
import { modules_links } from "@components/Dashboard/DashboardPage/subpages/FeedPage/config";
import BaseHoverTransition from "@reusables/wrappers/BaseHoverTransition";

export interface FeedTableProperties {
    variant: "compact" | "extended";
    search?: string;
}

export default function FeedTable({ variant, search }: FeedTableProperties): JSX.Element {
    const { t } = useTranslation("", { keyPrefix: "dashboard.dashboard.stats.feed" });

    const filters = removeEmpty({
        search
    });

    const pagination = usePagination({ page: 1, limit: 8 });

    const { data: feedResponse, isLoading: isFeedLoading } = useGetFeedQuery(
        {
            filters,
            pagination: {
                page: pagination.page,
                limit: pagination.limit
            }
        }
    );

    // Transforming feed response
    const feed = useMemo(() => {
        const feedResponsePayload = feedResponse?.payload ?? [];

        const val = feedResponsePayload
            .map((row) => {
                let meta;

                if ("code" in row.event.meta) {
                    meta = {
                        id: row.event.meta.id,
                        code: row.event.meta.code,
                        moduleLink: modules_links[row.event.module]
                    };
                }

                if (meta)
                    return {
                        origin: row,
                        optimized: meta
                    };
            })
            .filter((row) => row !== undefined);

        const cleanFeed = val as NonNullable<ArrayElementType<typeof val>>[];

        if (variant === "compact") {
            return cleanFeed.slice(0, 5);
        }

        return cleanFeed;
    }, [feedResponse]);

    // Getting last updated timestamp

    return (
        <>
            <BaseTable
                data={feed}
                isDataLoading={isFeedLoading}
                columns={[
                    {
                        header: "",
                        getter: (row) => <div className={"text-[#B49FFB]"}>{row.optimized.moduleLink?.icon}</div>,
                        sx: {
                            width: "40px"
                        }
                    },
                    {
                        header: t("code"),
                        getter: (row) => (
                            <Stack direction={"row"} spacing={"4px"} className={"text-accent"}>
                                {
                                    row.optimized.moduleLink?.link ?
                                        <Link to={row.optimized.moduleLink?.link + "/" + row.optimized.id + "/details"}>
                                            <u>{row.optimized.code}</u>
                                        </Link>
                                        :
                                        row.optimized.code
                                }
                                <span>{t("actionConnector")}</span>
                                <span>{t(`actions.${row.origin.event.action}`)}</span>
                            </Stack>
                        )
                    },
                    {
                        header: t("time"),
                        getter: (row) => (
                            <div className={"text-xs text-[#686868] cursor-pointer w-fit"}>
                                <BaseHoverTransition>
                                    {(hovered) =>
                                        hovered ? <div
                                                className={"animate-in fade-in-50"}>{row.origin.date.format("YYYY-MM-DD HH:mm")}</div> :
                                            <BaseMicroUtils.RelativeTime to={row.origin.date} />
                                    }
                                </BaseHoverTransition>
                            </div>
                        ),
                        sx: {
                            width: "100%"
                        }
                    }
                ]}
                headerSx={{
                    display: "none"
                }}
                sx={{
                    "& .MuiTableBody-root": {
                        borderCollapse: "separate",
                        "& .MuiTableCell-root:first-child": {
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px"
                        },
                        "& .MuiTableCell-root:last-child": {
                            borderTopRightRadius: "8px",
                            borderBottomRightRadius: "8px"
                        }
                    }
                }}
                alternate
            />

            <Stack direction={"row"} justifyItems={"space-between"}>
                {variant === "extended" && <Pagination className="mt-[32px]" {...pagination.adapt(feedResponse)} />}
            </Stack>
        </>
    );
}
