import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next';
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import BaseTable from '@reusables/BaseTable'
import {ReactComponent as EditSVG} from "@assets/icons/ic_edit.svg";
import {ReactComponent as TrashSVG} from "@assets/icons/ic_trash.svg";
import {useHistory} from 'react-router-dom';
import {CustomerGroups} from '@/types/general';
import {useGetCustomerGroupsQuery} from '@redux/features/customerGroups/customerGroupsApi';
import {removeEmpty, useOrdering, usePagination} from '@helpers/utils';
import {Pagination} from '@mui/material';
import DeletionModal from '../components/modals/DeletionModal';
import {AbilityContext, Can} from '@/casl.config';
import {useAbility} from '@casl/react';
import NoPermissionBanner from '@/components/ErrorPages/NoPermissionBanner';
import _ from 'lodash';

export default function CustomerGroupsPage() {
    const {t} = useTranslation("", {keyPrefix: "customerGroups.main"});
    const history = useHistory();

    const ability = useAbility(AbilityContext);

    // Searching value with debounce
    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(_.debounce(setSearchingValue, 1000), []);

    const {orderBy, setOrderBy} = useOrdering<CustomerGroups.DTO.OrderBy>({name: "id", type: "desc"});

    const pagination = usePagination({ page: 1, limit: 8 });

    const filters = removeEmpty({
        search: searchingValue,
    });

    const {data: customerGroups, isLoading: isCustomerGroupsLoading} = useGetCustomerGroupsQuery({
        filters,
        orderBy,
        pagination: {
            page: pagination.page,
            limit: pagination.limit,
        }
    });

    const [actionEntity, setActionEntity] = useState<CustomerGroups.Root>();
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);

    return (
        <>
            {/* FILTERS BLOCK */}
            <BaseAdminFilter
                permissionModule={"customer"}

                handleSearch={searchInputDebounce}
                handleCreate={() => history.push("/dashboard/customer-groups/new")}
            />

            <div className="levitation-extended mt-[32px]">
                <Can not I="view" a="customer">
                    <NoPermissionBanner />
                </Can>

                <Can I="view" a="customer">
                    <BaseTable
                        data={customerGroups?.payload ?? []}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: row => 
                                <>
                                    <Can I="view" a="customer">
                                        <div
                                            className="bold-highlight hover:opacity-70 transition-[.15s] cursor-pointer" 
                                            onClick={() => history.push(`/dashboard/customer-groups/${row.id}/details`)} 
                                        >
                                            <div className="text-sm text-accent">{row.code}</div>
                                        </div>
                                    </Can>
                                    <Can not I="view" a="customer">
                                        <div>
                                            <div className="text-sm text-accent">{row.code}</div>
                                        </div>
                                    </Can>
                                </>,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.1"),
                                getter: row => row.name,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.2"),
                                getter: row => row.discount,
                                comparator: () => 0,
                            },
                            {
                                visible: ability.can("edit", "customer") || ability.can("delete", "customer"),
                                header: t("table.columns.3"),
                                getter: row => <div className="text-center space-x-[12px]">
                                    <Can I="edit" a="customer">
                                    <EditSVG 
                                        className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer" 
                                        onClick={() => history.push(`/dashboard/customer-groups/${row.id}/edit`)} 
                                    />
                                    </Can>
                                    <Can I="delete" a="customer">
                                    <TrashSVG 
                                        className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer" 
                                        onClick={() => {
                                            setActionEntity(row);
                                            setIsDeletionModalOpen(true);
                                        }} 
                                    />
                                    </Can>
                                </div>,
                                sx: {
                                    width: 100,
                                },
                            }
                        ]}

                        isDataLoading={isCustomerGroupsLoading}

                        alternate

                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering) {
                                    let name: CustomerGroups.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 0:
                                            name = "code";
                                            break;
                                        case 1:
                                            name = "name";
                                            break;
                                        case 2:
                                            name = "discount";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                    setOrderBy({
                                        name,
                                        type: newOrdering.order
                                    });
                                } else {
                                    setOrderBy(undefined);
                                }  
                            }
                        }}
                        size="medium"
                    />

                    <Pagination
                        className="mt-[32px]"
                        {...pagination.adapt(customerGroups)}
                    />
                </Can>
            </div>

            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                entity={actionEntity}
            />
        </>
    );
}