import React from "react";
import {Button, styled} from "@mui/material";
import {TransientAdapter} from "@helpers/utils";
import {ButtonProps} from "@mui/material/Button/Button";
import BaseTooltip from "../BaseTooltip";

interface BaseMaterialIconButtonProps extends Omit<ButtonProps, "style"> {
  icon: JSX.Element;
  size?: "small" | "medium";
  style?: "light" | "filled";
  onClick?: () => void;
  tip?: string; // The tooltip text. If not provided, no tooltip will be shown
  className?: string;
}

export default function BaseMaterialIconButton({
  icon,
  size = "medium",
  style = "filled",
  onClick,
  tip,
  ...props
}: BaseMaterialIconButtonProps) {
  // If a tooltip is provided, wrap the button in a Tooltip component
  const tooltip = tip ? (
    <BaseTooltip title={tip!} placement="bottom">
      <MaterialIconButton onClick={onClick} _size={size} _style={style} {...props} >
        {icon}
      </MaterialIconButton>
    </BaseTooltip>
  ) : (
    <MaterialIconButton onClick={onClick} _size={size} _style={style} {...props} >
      {icon}
    </MaterialIconButton>
  );

  return tooltip;
}

const sizes: {
  [P: string]: 
    {width: number; height: number}
} = {
  small: {
    width: 32,
    height: 32,
  },
  medium: {
    width: 40,
    height: 40,
  },
};

const MaterialIconButton = styled(Button, TransientAdapter)<{
  _size: string;
  _style?: string;
}>(({ theme, _size, _style }) => ({
  backgroundColor: _style === "filled" ? theme.custom.purple["100"] : "#fff",
  borderRadius: "8px",
  fontWeight: 400,
  transition: "background-color .15s",
  border:
    _style === "filled"
      ? `1px solid ${theme.custom.purple["100"]}`
      : `1px solid ${theme.custom.textAccent}`,
  width: sizes[_size].width,
  height: sizes[_size].height,
  padding: 0,
  minWidth: "unset",
  // "& path": _style === "filled" ? { fill: `${theme.custom.purple["400"]} !important` } : null,
}));
