import React, { useState } from "react";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import { useGetRolesQuery } from "@redux/features/roles/rolesApi";
import { Trans, useTranslation } from "react-i18next";
import BaseTable from "@reusables/BaseTable";

import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import { ReactComponent as UserAddSVG } from "@assets/icons/ic_user_add.svg";
import { ReactComponent as CopySVG } from "@assets/icons/ic_copy.svg";

import { useHistory } from "react-router-dom";
import { useAppSelector } from "@redux/hooks";
import DeletionModal from "../components/modals/DeletionModal";
import BaseTooltip from "@reusables/BaseTooltip";
import BaseBadge from "@reusables/BaseBadge";
import { Shield } from "lucide-react";
import { useAnchor } from "@helpers/utils";
import { Role } from "@/types/general";
import { Menu } from "@mui/material";
import RoleAssignmentModal
    from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Roles/components/modals/RoleAssignmentModal";
import CopyingModal
    from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Roles/components/modals/CopyingModal";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function RolesMainPage() {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.roles" });


    const history = useHistory();

    // Getting Roles data using RTK Query
    const { data: roles = [], isLoading: isLoadingRoles } = useGetRolesQuery();

    // Open states determine visibility of modals
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
    const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
    const [isCopyingModalOpen, setIsCopyingModalOpen] = useState(false);

    // Ids and corresponding selected cache entries allows modals to work with data
    const [deletableRoleId, setDeletableRoleId] = useState<number>();
    const [assignableRoleId, setAssignableRoleId] = useState<number>();
    const [copyableRoleId, setCopyableRoleId] = useState<number>();

    const currentRole = useAppSelector(state => state.auth?.user?.role);

    const anchor = useAnchor<Role.Root>();

    return (
        <>
            <Can not I="role.view" a="general">
                <NoPermissionBanner />
            </Can>

            <Can I="role.view" a="general">
                <div>
                    <div className="flex flex-row justify-between items-center mb-[44px]">
                        <div className="font-bold text-2xl text-accent">{t("heading")}</div>
                        <Can I="role.edit" a="general">
                            <BaseMaterialButton  fontWeight={500} onClick={() => {
                                // setIsMutationModalOpen(true);
                                history.push(`/dashboard/settings/company/roles/new`);
                            }}>
                                {t("table.buttons.add")}
                            </BaseMaterialButton>
                        </Can>
                    </div>

                    <BaseTable
                        data={roles}
                        columns={[
                            {
                                header: t("table.columns.name"),
                                getter: (row) => <div>
                                    <div className={"flex flex-row items-center"}>
                                        {
                                            row.immutable &&
                                            <BaseTooltip title={t("table.immutableTooltip") + ""}>
                                                <Shield className={"mr-1"} size={12} />
                                            </BaseTooltip>
                                        }
                                        <div className="bold-highlight">{row.name}</div>
                                        {
                                            row.id === currentRole?.id &&
                                            <BaseBadge className={"ml-2 rounded-xl px-2"}>
                                                {t("table.yourRole")}
                                            </BaseBadge>
                                        }
                                    </div>
                                    {
                                        row.description &&
                                        <div className="text-secondary">{row.description}</div>
                                    }
                                </div>,
                                comparator: (a, b) => a.name.localeCompare(b.name),
                                sx: {
                                    width: "30%"
                                }
                            },
                            {
                                header: t("table.columns.users.label"),
                                getter: (row) => row.users.length ?
                                    <BaseTooltip title={
                                        <ul className={"list-decimal pl-4 marker:text-accent"}>
                                            {
                                                row.users.map(user => (
                                                    <li key={user.id}>{user.first_name} {user.last_name}</li>
                                                ))
                                            }
                                        </ul>
                                    }>
                                        <u className={"text-secondary"} onClick={() => {
                                            history.push(`/dashboard/settings/company/users`, {
                                                roles: [row.id]
                                            });
                                        }}>
                                            <Trans
                                                t={t}
                                                i18nKey={"table.columns.users.prompt"}
                                                count={row.users.length}
                                                values={{ count: row.users.length }}
                                            />
                                        </u>
                                    </BaseTooltip>
                                    :
                                    <>
                                        -
                                    </>
                            },
                            {
                                header: () => t("table.columns.actions"),
                                getter: (row, _, collapseState) => (
                                    <div
                                        className="flex space-x-[4px] justify-center align-center py-[10px] cursor-pointer group"
                                        onClick={(e) => {
                                            anchor.activateAnchor(row, e.currentTarget);
                                        }}>
                                        {
                                            [...Array(3)].map((x, i) => <div key={i}
                                                                            className={`${collapseState ? "bg-gray-600" : "bg-gray-300"} rounded-full w-[5px] h-[5px] group-hover:bg-gray-600 transition-[.15s]`}></div>)
                                        }
                                    </div>
                                ),
                                sx: {
                                    width: "1%"
                                }
                            }
                        ]}
                        isDataLoading={isLoadingRoles}

                        headerSx={{
                            backgroundColor: "#F9F9F9"
                        }}

                        sx={{
                            borderCollapse: "separate",
                            borderSpacing: "0 15px"
                        }}

                        size={"small"}
                    />


                </div>

                <DeletionModal
                    isOpen={isDeletionModalOpen}
                    onClose={() => setIsDeletionModalOpen(false)}
                    role_id={deletableRoleId}
                />

                <RoleAssignmentModal
                    isOpen={isAssignmentModalOpen}
                    onClose={() => void setIsAssignmentModalOpen(false)}
                    role_id={assignableRoleId}
                />

                <CopyingModal
                    isOpen={isCopyingModalOpen}
                    onClose={() => void setIsCopyingModalOpen(false)}
                    role_id={copyableRoleId}
                />

                <Menu
                    id="basic-menu"
                    anchorEl={anchor.anchor}
                    open={!!anchor.anchor && !!anchor.entity}
                    onClose={() => anchor.hideAnchor()}
                >
                    <div
                        className="flex space-x-[16px] p-[18px] bg-gradient-to-r from-gradients-primaryLinear-from to-gradients-primaryLinear-to text-[#fff]"
                    >
                        <Can I="role.view" a="general">
                            <BaseTooltip title={t("table.actions.assignUser") + ""} placement="bottom">
                                <UserAddSVG
                                    onClick={() => {
                                        setAssignableRoleId(anchor.entity?.id);
                                        setIsAssignmentModalOpen(true);
                                        anchor.hideAnchor();
                                    }}
                                />
                            </BaseTooltip>
                        </Can>

                        <Can I="role.edit" a="general">
                            <BaseTooltip title={t("table.actions.copy") + ""} placement="bottom">
                                <CopySVG
                                    onClick={() => {
                                        setCopyableRoleId(anchor.entity?.id);
                                        setIsCopyingModalOpen(true);
                                        anchor.hideAnchor();
                                    }}
                                />
                            </BaseTooltip>
                        </Can>

                        {
                            !anchor.entity?.immutable && <>
                                <Can I="role.edit" a="general">
                                    <BaseTooltip title={t("table.actions.edit") + ""} placement="bottom">
                                        <EditSVG
                                            onClick={() => {
                                                history.push(`/dashboard/settings/company/roles/${anchor.entity?.id}`);
                                            }}
                                        />
                                    </BaseTooltip>
                                </Can>

                                <Can I="role.delete" a="general">
                                    <BaseTooltip title={t("table.actions.delete") + ""} placement="bottom">
                                        <DeleteSVG
                                            onClick={() => {
                                                setDeletableRoleId(anchor.entity?.id);
                                                setIsDeletionModalOpen(true);
                                                anchor.hideAnchor();
                                            }}
                                        />
                                    </BaseTooltip>
                                </Can>
                            </>
                        }
                    </div>
                </Menu>
            </Can>
        </>
    );
}
