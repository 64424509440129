import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StepperLink } from "../../Stepper/types";
import Stepper from "../../Stepper";
import { Switch } from "react-router-dom";
import PrivateRoute from "@/components/route-helper/PrivateRoute";
import UsersTab from "./subpages/Users";
import RolesMainPage from "./subpages/Roles/MainPage";
import RolesCreationPage from "./subpages/Roles/CreationPage";
import RolesEditingPage from "./subpages/Roles/EditingPage";
import DetailsTab from "./subpages/Details";
import AddressTab from "./subpages/Adresses";
import { StepsContext, StepsContextType } from "../MyAccount/context";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { setIsSelectedCompanyLoading, setSelectedCompany } from "@redux/features/companies/companiesSlice";
import { companiesApi } from "@redux/features/companies/companiesApi";
import EmployeesTab from "./subpages/Employees/EmployeesTab";

export default function CompanyInformationPage() {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs" });

    const activeCompany = useAppSelector(state => state.auth.user?.company);
    const selectedCompany = useAppSelector(state => state.companies.selectedCompany);
    const isSelectedCompanyLoading = useAppSelector(state => state.companies.isSelectedCompanyLoading);

    const dispatch = useAppDispatch();

    // Tracking active company change and loading selected company data
    useEffect(() => {
        if (activeCompany) {
            dispatch(setIsSelectedCompanyLoading(true));

            dispatch(companiesApi.endpoints.getCompany.initiate(activeCompany?.id))
            .unwrap()
            .then((response) => {
                dispatch(setSelectedCompany(response));
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                dispatch(setIsSelectedCompanyLoading(false));
            });
        }
    }, [activeCompany]);

    const stepperItems: StepperLink[] = [
        {
            title: t("companyDetails.title"),
            link: "/dashboard/settings/company/details"
        },
        {
            title: t("address.title"),
            link: "/dashboard/settings/company/address"
        },
        {
            title: t("employees.title"),
            link: "/dashboard/settings/company/employees"
        },
        {
            title: t("users.title"),
            link: "/dashboard/settings/company/users"
        },
        {
            title: t("roles.title"),
            link: "/dashboard/settings/company/roles"
        }
    ];

    const contextData: StepsContextType = {};

    return (
        <div className="levitation-extended w-full">
            <StepsContext.Provider value={contextData}>
                <Stepper
                    items={stepperItems}
                    className="mb-[40px]"
                />
                <BaseRecordRenderer
                    loading={isSelectedCompanyLoading}
                    dontShowNotFound
                    hideLevitation
                >
                    <Switch>
                        <PrivateRoute
                            path="/dashboard/settings/company/details"
                            component={() => <DetailsTab />}
                            exact
                        />

                        <PrivateRoute
                            path="/dashboard/settings/company/address"
                            component={() => <AddressTab />}
                            exact
                        />

                        <PrivateRoute
                            path="/dashboard/settings/company/employees"
                            component={() => <EmployeesTab />}
                            exact
                        />

                        <PrivateRoute
                            path={`/dashboard/settings/company/users`}
                            component={() => <UsersTab />}
                            exact
                        />

                        <PrivateRoute
                            path="/dashboard/settings/company/roles"
                            component={() => <RolesMainPage />}
                            exact
                        />

                        <PrivateRoute
                            path="/dashboard/settings/company/roles/new"
                            component={() => <RolesCreationPage />}
                            exact
                        />

                        <PrivateRoute
                            path="/dashboard/settings/company/roles/:id"
                            component={() => <RolesEditingPage />}
                            exact
                        />
                    </Switch>
                </BaseRecordRenderer>
            </StepsContext.Provider>
        </div>
    );
}