import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { ProductSchemeTyping } from "@components/Dashboard/pages/Products/components/CreationEditingLayout/types";
import { Link2 } from "lucide-react";
import BaseTooltip from "@reusables/BaseTooltip";
import { Fade, Stack } from "@mui/material";
import BaseSwitch from "@reusables/BaseSwitch";
import { cn } from "@helpers/utils";
import { useAbility } from "@/casl.config";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";

export default function IsServiceCheckbox() {
    const { t } = useTranslation("", { keyPrefix: "products.general.fields.isService" });

    const ability = useAbility();

    const { control } = useFormContext<ProductSchemeTyping>();

    const { field, fieldState } = useController({
        name: "is_service",
        control
    });

    const category = useWatch({
        name: "general.category",
        control
    });

    useEffect(() => {
        if (category?.is_service) {
            field.onChange(true);
        }
    }, [category]);

    return (
        <BasePermissionBlocker action="services.create" module="product">
            <Stack
                direction={"row"}
                spacing={1}
                alignItems={"center"}
                className={cn(
                    "h-[44px] cursor-pointer px-[16px] py-[8px] rounded-[8px] " + "border-[1px] border-solid border-inputs-border-default hover:bg-inputs-border-default !bg-opacity-30 transition-[.25s]",
                    field.value && "border-purple-400"
                )}
                onClick={() => {
                    ability.can("services.create", "product") ? field.onChange(!field.value) : null;
                }}
            >
                <BaseSwitch checked={field.value} onChange={field.onChange} disabled={ability.cannot("services.create", "product")} />
                <div>{t("label")}</div>

                <Fade in={category?.is_service && field.value}>
                    <div>
                        <BaseTooltip title={t("obtainedFromCategory") + ""}>
                            <div className="bg-[#D9DAFD] w-[20px] h-[20px] rounded-full flex justify-center items-center transition-all hover:bg-lightGreen-400 cursor-pointer">
                                <Link2 size={14} color={"#fff"} />
                            </div>
                        </BaseTooltip>
                    </div>
                </Fade>
            </Stack>
        </BasePermissionBlocker>
    );
}
