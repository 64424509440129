import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ResendTimerProps {
    duration: number;
    onTimerEnd: () => void;
}

export const ResendTimer = ({ duration, onTimerEnd }: ResendTimerProps) => {
    const {t} = useTranslation("", {keyPrefix: "verifyEmailPage.timer"});

    const [timeLeft, setTimeLeft] = useState<number>(duration);

    useEffect(() => {
        // Set up the interval for countdown
        const intervalId = setInterval(() => {
            setTimeLeft((prevTime) => {
                const updatedTime = prevTime - 1;
                if (updatedTime <= 0) {
                    clearInterval(intervalId); 
                    onTimerEnd(); 
                    return 0;
                }
                return updatedTime;
            });
        }, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [onTimerEnd]);

    return (
        <div className='mt-2'>
            {`${t("wait")} ${Math.floor(timeLeft / 60)}:${String(timeLeft % 60).padStart(2, '0')} ${t("beforeResend")}.`}
        </div>
    );
};
