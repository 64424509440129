import {
    flattenPaginationRequest,
    internalApiSlice,
    PaginationRequest,
    PaginationResponse,
    transformResponse
} from "@redux/api/internalApiSlice";
import { Picking } from "@/types/general";

export const pickingApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPickingsFull: builder.query<PaginationResponse<Picking.Root>, PaginationRequest<Picking.DTO.Filters, Picking.DTO.OrderBy>>({
            query: (req) => ({
                url: "picking",
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: ["date", "shipment_change_date"]
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({ id }) => ({ type: "Picking" as const, id })),
                        { type: "Picking", id: "PARTIAL-LIST" }
                    ]
                    : [{ type: "Picking", id: "PARTIAL-LIST" }]
        }),

        getPicking: builder.query<Picking.Extended, number>({
            query: (id) => ({
                url: `picking/${id}`,
                method: "GET",
                parseDates: ["date", "expiration_date", "shipment_change_date"]
            }),
            providesTags: (result, error, id) => [{ type: "Picking", id }],
            transformResponse
        }),

        // Returns pdf blob
        createPicking: builder.mutation<Blob, Picking.DTO.Create>({
            query: (data) => ({
                url: `picking`,
                method: "POST",
                responseType: "blob",
                data
            }),
            invalidatesTags: (result, error, body) => [
                { type: "Picking", id: "PARTIAL-LIST" },
                "Stock",
                { type: "SaleOrder", id: body.sale_order_id },
                ...body.lines.map((line) => ({ type: "Product", id: line.product_id }))
            ]
        }),

        downloadDeliveryNote: builder.query<Blob, number>({
            query: (id) => ({
                url: `picking/${id}/delivery-note`,
                method: "GET",
                responseType: "blob"
            })
        })
    })
});

export const {
    useGetPickingsFullQuery,
    useGetPickingQuery,
    useCreatePickingMutation
} = pickingApi;