import React from "react";
import BaseButton from "@reusables/BaseButton";
import BaseModal from "@reusables/Modals/BaseModal";
import { useSuspendUserMutation } from "@redux/features/users/usersApi";
import { Users } from "@/types/general";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { toastError } from "@helpers/utils";

interface ActivateModalProperties {
    isOpen: boolean;
    entity?: Users.Root;
    onClose: () => void;
}

export default function ActivationModal(props: ActivateModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.users.modals.activation" });

    const [suspendUser, { isLoading: isSuspendedLoading }] = useSuspendUserMutation();

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
            isLoading={isSuspendedLoading}
        >
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">{t("subheading")} <span
                    className="bold-highlight">{props.entity?.first_name} {props.entity?.last_name}</span>?
                </div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose} />
                    <BaseButton text={t("buttons.activate")} size="md" onClick={() => {
                        if (props.entity) {
                            suspendUser(props.entity.id).then(() => {
                                toast.success(t("responses.success"));
                            }).catch(e => {
                                console.error(e);
                                toastError(e);
                            }).finally(() => {
                                props.onClose();
                            });
                        }
                    }} />
                </div>
            </div>
        </BaseModal>

    );
}
