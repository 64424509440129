import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetPickingQuery } from "@redux/features/picking/pickingApi";
import { useHistory, useParams } from "react-router-dom";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { BaseTinyActionIcon, TinyActionIconType } from "@reusables/BaseTinyActionIcon";
import { useAppDispatch } from "@redux/hooks";
import downloadDeliveryNoteHandler from "@components/Dashboard/pages/Sales/Picking/utils";
import i18next from "i18next";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import ViewLayout from "../components/ViewLayout";
import { Can, useAbility } from "@/casl.config";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";


export default function PickingDetailsPage() {
    const history = useHistory();

    const ability = useAbility();

    const dispatch = useAppDispatch();

    const { t } = useTranslation("", { keyPrefix: "sales.picking.mutation" });
    const { id } = useParams<{ id: string }>();

    const { data: picking, isLoading: isPickingLoading } = useGetPickingQuery(parseInt(id));

    const [isDeliveryNoteLoading, setIsDeliveryNoteLoading] = useState(false);

    return (
        <>
            <Can not I="view" a="picking">
                <div className="levitation-extended">
                    <NoPermissionBanner />
                </div>
            </Can>

            <Can I="view" a="picking">
                <BaseLoadingBlocker active={isDeliveryNoteLoading}>
                    <BaseRecordRenderer
                        loading={isPickingLoading}
                        record={picking}
                        hideLevitation
                    >
                        <div className="space-y-10">
                            <div className="space-y-[40px] levitation-extended">
                                <div className="flex items-center">
                                    <h6 className="text-accent grow">{picking?.code}</h6>
                                    <div className="space-x-[8px] flex items-center h-full">
                                        {
                                            picking?.has_delivery_note &&
                                            <BasePermissionBlocker action="download" module="picking">
                                                <BaseTinyActionIcon
                                                    type={TinyActionIconType.PDF}
                                                    onClick={() => ability.can("download", "picking") && downloadDeliveryNoteHandler({
                                                        pickingId: picking?.id as number,
                                                        dispatch,
                                                        setIsDeliveryNoteLoading,
                                                        defaultErrorMessage: i18next.t("sales.picking.main.responses.deliveryNoteFetchError")
                                                    })}
                                                />
                                            </BasePermissionBlocker>
                                        }

                                    </div>
                                </div>
                                <ViewLayout.Main picking={picking as NonNullable<typeof picking>} />
                            </div>

                            <ViewLayout.Shipmondo picking={picking as NonNullable<typeof picking>} />
                        </div>
                    </BaseRecordRenderer>
                </BaseLoadingBlocker>
            </Can>
        </>
    );
}
