import React from "react";
import { useTranslation } from "react-i18next";
import { Alert, Collapse } from "@mui/material";
import { SaleOrdersFormTyping } from "@components/Dashboard/pages/Sales/Orders/components/MutationLayout/types";
import { useFormContext, useWatch } from "react-hook-form";
import _ from "lodash";

export default function LowStockWarning() {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general.orders" });

    const { control } = useFormContext<SaleOrdersFormTyping>();

    const lines = useWatch({
        control,
        name: "lines"
    });

    // Group lines by product ID
    const groupedLines = _.groupBy(lines, line => line.data?.product?.id);

    // Find products that are overstocked
    const lowStockProducts = Object.values(groupedLines)
        .filter(group => {
            const product = group[0]?.data?.product;
            if (!product || product.is_service) return false;

            const totalQuantity = _.sumBy(group, line => line.data?.quantity || 0);
            return totalQuantity > (product.in_stock ?? 0);
        })
        .map(group => {
            const product = group[0]?.data?.product;
            const totalQuantity = _.sumBy(group, line => line.data?.quantity || 0);
            return {
                ...product,
                totalQuantity,
                inStock: product?.in_stock ?? 0,
            };
        });

    const isLowStock = lowStockProducts.length > 0;

    return (
        <Collapse in={isLowStock}>
            <Alert severity={"warning"}>
                <div className="space-y-2">
                    <div>{t("lowStockWarning")}</div>
                    <div className="space-y-1">
                        {lowStockProducts.map(product => (
                            <div key={product?.id}>
                                {/* {product?.code} {product?.name} - {t("usedQuantity", { qty: product.totalQuantity })} / {t("inStock", { qty: product.inStock })} */}
                                {product?.code} {product?.name} - {product.totalQuantity} / {product.inStock}
                            </div>
                        ))}
                    </div>
                </div>
            </Alert>
        </Collapse>
    );
}
