import React, { useState } from "react";
import { validate } from "@reusables/Validator/validationStore";
import { manualRequest } from "@helpers/utils";
import BaseButton from "@reusables/BaseButton";
import { toast } from "react-toastify";
import BaseModal from "@reusables/Modals/BaseModal";
import { ModalProperties } from "../../types";
import { useTranslation } from "react-i18next";
import { Template } from "@/types/general";

export default function TemplateDeletionModal(props: ModalProperties & {template?: Template.Root, onDeletion: (deleted: Template.Root) => void;}){
    const { t } = useTranslation("", { keyPrefix: "products.createEditProduct.modals.templates.deletionModal" });

    const [deletionLoading, setDeletionLoading] = useState<boolean>(false);

    return (
      <BaseModal
        isOpen={props.isOpen}
        onClose={() => {
            props.onClose?.()
        }}
        width={674}
        padding="56px"
        isLoading={deletionLoading}
      >
          <div className="space-y-[32px]">
              <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
              <div className="text-accent text-center">{t("subheading")} <span className="bold-highlight">{props.template?.name} {t("templateInterpretation")}?</span></div>

              <div className="grid grid-cols-2 space-x-[16px]">
                  <BaseButton text={t("buttons.cancel")} primaryOutlined className="w-full" size="md" onClick={props.onClose} />
                  <BaseButton text={t("buttons.delete")} className="w-full" size="md" onClick={() => {
                      if(props.template){
                          manualRequest({
                              method: "DELETE",
                              route: `template/${props.template.id}`,
                              then: resp => {
                                  toast.success(t("responses.success"))

                                  props.onDeletion(props.template as NonNullable<typeof props.template>);
                                  props.onClose?.()
                              },
                              catch: e => {
                                  toast.error(t("responses.error"))
                              },
                              onLoadingChange: setDeletionLoading
                          })
                      }
                  }} />
              </div>
          </div>
      </BaseModal>
    )
}