import React from 'react'

function SuccessPage() {
  return (
    <div>
        <h1>Success</h1>
        <h2>Thank you for your purchase</h2>
    </div>
  )
}

export default SuccessPage