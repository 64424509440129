import React, { useState } from "react";
import { ViewLayoutProps } from "./types";
import BaseTooltip from "@reusables/BaseTooltip";
import { ReactComponent as CircleChevronSVG } from "@assets/icons/ic_expand_down_circled.svg";
import { Collapse, Stack } from "@mui/material";
import { cn, useFilters, useOrdering, usePagination } from "@helpers/utils";
import BaseTable from "@reusables/BaseTable";
import { useGetBOMsHistoryFullQuery } from "@redux/features/bom/bomApi";
import { BOMHistory } from "@/types/general";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BaseButton from "@/components/reusables/BaseButton";
import TableButton from "@/components/reusables/BaseTable/TableButton";
import SerialNumbersModal from "../modals/ProduceModal/modals/SerialNumbersModal";
import BatchNumbersModal from "../modals/ProduceModal/modals/BatchNumbersModal";
import dayjs from "dayjs";

export default function BOMProducedHistory({ bom }: ViewLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "bom.details.producingHistory" });

    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    // BSN modals
    const [isSerialNumberModalOpen, setIsSerialNumberModalOpen] = React.useState(false);
    const [isBatchNumberModalOpen, setIsBatchNumberModalOpen] = React.useState(false);

    const [selectedBOM, setSelectedBOM] = React.useState<BOMHistory.Root | null>(null);

    const filters = useFilters(
        () => ({
            bomId: bom?.id
        }),
        [bom?.id]
    );

    const { orderBy, setOrderBy } = useOrdering<BOMHistory.DTO.OrderBy>({
        name: "date",
        type: "desc"
    });

    const pagination = usePagination({ page: 1, limit: 8 });

    const { data, isLoading: isDataLoading } = useGetBOMsHistoryFullQuery({
        filters,
        orderBy,
        pagination: {
            page: pagination.page,
            limit: pagination.limit
        }
    });

    const serialNumbersBOM = data?.payload?.filter(bom => bom.serial_numbers.length > 0);
    const batchNumbersBOM = data?.payload?.filter(bom => bom.batch_numbers.length > 0);

    if (!data?.payload?.length)
        return (
            <div className="levitation-extended">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <div
                        className={`${!data?.payload?.length && "opacity-25"} text-accent font-semibold text-xl`}>{t("heading")}</div>
                    <BaseTooltip title={`${t("tip.noHistoryFound")}`}>
                        <CircleChevronSVG className={"text-[#D7D4DA]"} />
                    </BaseTooltip>
                </Stack>
            </div>
        );

    return (
        <>
            <div className="levitation-extended">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <div className={"text-accent font-semibold text-xl"}>{t("heading")}</div>
                    <CircleChevronSVG
                        onClick={() => void setIsCollapsed(prevState => !prevState)}
                        className={cn("cursor-pointer text-accent transition-[.25s]")}
                        style={{ transform: !isCollapsed ? "rotate(0deg)" : "rotate(180deg)" }}
                    />
                </Stack>

                <Collapse in={isCollapsed}>
                    <div className="mt-[24px]">
                        <BaseTable
                            data={data?.payload ?? []}
                            columns={[
                                {
                                    header: t("table.columns.0"),
                                    getter: row => row?.produce_location.section?.name ?
                                        `${row?.produce_location.store.name} - ${row?.produce_location.section?.name}` :
                                        row?.produce_location.store.name
                                },
                                {
                                    header: t("table.columns.1"),
                                    getter: row => row?.quantity
                                },
                                {
                                    header: t("table.columns.2"),
                                    getter: row => row?.date.format("DD.MM.YYYY")
                                },
                                {
                                    header: t("table.columns.3"),
                                    getter: row => (
                                        <div className="flex justify-between space-x-2">
                                            {row.serial_numbers.length > 0 && (
                                                <TableButton
                                                    onClick={() => {
                                                        setSelectedBOM(row);
                                                        setIsSerialNumberModalOpen(true);
                                                    }}
                                                    className="text-sm text-accent"
                                                >
                                                    {t("table.buttons.sn")}
                                                </TableButton>
                                            )}
                                            {row.batch_numbers.length > 0 && (
                                                <TableButton
                                                    onClick={() => {
                                                        setSelectedBOM(row);
                                                        setIsBatchNumberModalOpen(true);
                                                    }}
                                                    className="text-sm text-accent rounded-sm"
                                                >
                                                    {t("table.buttons.bn")}
                                                </TableButton>
                                            )}
                                        </div>
                                    ),
                                    preventCollapsePropagation: true,
                                    sx: {width: "100px"}
                                }
                            ]}
                            headerSx={{
                                backgroundColor: "rgba(0, 0, 0, 0.03)"
                            }}

                            collapse={{
                                fill: "#D9DAFD",
                                borderColor: "#B3B5EB",
                                content: (mainRow) => {
                                    return (
                                        <BaseTable
                                            data={mainRow.components}
                                            columns={[
                                                {
                                                    header: <div className="text-accent font-semibold">
                                                        {t("table.subtable.columns.0")}
                                                    </div>,
                                                    getter: (row) => <Link
                                                        to={`/dashboard/products/${row.product.id}/details`}><u>{row.product.name}</u></Link>
                                                },
                                                {
                                                    header: <div className="text-accent font-semibold">
                                                        {t("table.subtable.columns.1")}
                                                    </div>,
                                                    getter: (row) => <>
                                                        {row.outgoing_locations.map((location, index) => {
                                                                return <div key={index}>{location.quantity}</div>;
                                                            }
                                                        )}
                                                    </>
                                                },
                                                {
                                                    header: <div className="text-accent font-semibold">
                                                        {t("table.subtable.columns.2")}
                                                    </div>,
                                                    getter: (row) => <>
                                                        {row.outgoing_locations.map((location, index) => {
                                                                const { store, section } = location;
                                                                return (
                                                                    <div key={index} className={"space-x-[4px]"}>
                                                                        <span className={"font-[600]"}>{store.name}</span>
                                                                        {
                                                                            !!section && <span>- {section.name}</span>
                                                                        }
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                },
                                            ]}
                                            sx={{
                                                "& tbody td": {
                                                    verticalAlign: "top",
                                                    "&:nth-child(-n+5)": {
                                                        paddingTop: "16px"
                                                    }
                                                }
                                            }}
                                            alternate
                                        />
                                    );
                                }
                            }}
                            isDataLoading={isDataLoading}
                            hideTableWhenNothingFound
                        />
                    </div>
                </Collapse>
            </div>

            {/* Serial Number Modal */}
            {
                serialNumbersBOM && serialNumbersBOM?.length > 0 && (
                    <SerialNumbersModal
                        isOpen={isSerialNumberModalOpen}
                        onClose={() => setIsSerialNumberModalOpen(false)}
                        product={{
                            quantity: selectedBOM?.quantity ?? 0,
                            serial_numbers: selectedBOM?.serial_numbers
                        }}
                        preview
                    />
                )
            }

            {/* Batch Number Modal */}
            {
                batchNumbersBOM && batchNumbersBOM?.length > 0 && (
                    <BatchNumbersModal
                        isOpen={isBatchNumberModalOpen}
                        onClose={() => setIsBatchNumberModalOpen(false)}
                        product={{
                            quantity: selectedBOM?.quantity ?? 0,
                            batch_numbers: selectedBOM?.batch_numbers.map(batch => {
                                return {
                                    batch_number: batch.batch_number,
                                    expiration_date: dayjs(batch.expiration_date)
                                };
                            })
                        }}
                        preview
                    />
                )
            }
        </>
    );
}
