import React from "react";
import {useTranslation} from "react-i18next";
import CreationEditingLayout from "../components/CreationEditingLayout";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function ProductCreationPage() {
    const { t } = useTranslation("", { keyPrefix: "products.newProduct" });

    return (
        <div className="levitation-extended">
            <Can not I="create" a="product">
                <NoPermissionBanner />
            </Can>
            
            <Can I="create" a="product">
                <div className="flex mb-[40px] items-center">
                    <h6 className="text-accent grow">{t("heading")}</h6>
                    <div id="product-creation-header">

                    </div>
                </div>
                <CreationEditingLayout
                    product={undefined}
                />
            </Can>
        </div>
    );
}