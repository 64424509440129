import React from "react";
import {useTranslation} from "react-i18next";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseButton from "@reusables/BaseButton";
import {toast} from "react-toastify";
import {useDeleteSaleOrderMutation} from "@redux/features/sales/orders/salesOrdersApi";
import {isErrorWithMessage} from "@redux/api/query";

interface DeletionModalProperties {
    isOpen: boolean;
    entity_id?: number;
    onClose: () => void;
    onDeletion?: () => void;
}

export default function OrderDeletionModal(props: DeletionModalProperties){
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general.modals.deletion" });

    const [deleteOrder, { isLoading: isActiveDeletionLoading }] = useDeleteSaleOrderMutation();

    return (
      <BaseModal
        isOpen={props.isOpen}
        onClose={props.onClose}
        width={700}
        padding="56px"
        isLoading={isActiveDeletionLoading}
      >
          <div className="space-y-[32px]">
              <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
              <div className="text-center text-accent">{t("subheading")} <span className="bold-highlight">{t("orderDeletionItem")} #{props.entity_id}?</span></div>
              <div className="grid grid-cols-2 gap-[16px]">
                  <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose} />
                  <BaseButton text={t("buttons.delete")} size="md" onClick={() => {
                      if(props.entity_id) {
                          deleteOrder(props.entity_id)
                          .unwrap()
                          .then(_ => {
                              toast.success(t("responses.success"));
                              props.onDeletion?.();
                          })
                          .catch(e => {
                              if(isErrorWithMessage(e)){
                                    toast.error(e.message);
                              }else{
                                  toast.error(t("responses.error"))
                              }
                          })
                          .finally(() => props.onClose())
                      }
                  }}/>
              </div>
          </div>
      </BaseModal>
    )
}