import React, { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ProduceFormTyping } from "@components/Dashboard/pages/BOM/components/modals/ProduceModal/types";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import SerialNumbersModal
    from "@components/Dashboard/pages/BOM/components/modals/ProduceModal/modals/SerialNumbersModal";
import BatchNumbersModal from "@components/Dashboard/pages/BOM/components/modals/ProduceModal/modals/BatchNumbersModal";
import { useTranslation } from "react-i18next";

interface BSNSectionProps {
    is_serial_number: boolean;
    is_batch_number: boolean;
}

export default function BSNSection({ is_serial_number, is_batch_number }: BSNSectionProps) {
    const { t } = useTranslation("", { keyPrefix: "bom.produceModal" });

    const form = useFormContext<ProduceFormTyping>();

    const quantity = useWatch({
        control: form.control,
        name: "quantity"
    });

    const [isSerialNumbersModalOpen, setIsSerialNumbersModalOpen] = useState<boolean>(false);
    const [isBatchNumbersModalOpen, setIsBatchNumbersModalOpen] = useState<boolean>(false);

    if (!quantity)
        return null;

    return (
        <>
            <div className="space-x-2">
                {/* Serial Numbers Modal button */}
                {
                    is_serial_number && (
                        <BaseMaterialButton
                            type="button"
                            onClick={() => setIsSerialNumbersModalOpen(true)}
                        >
                            {t("buttons.addSerialNumbers")}
                        </BaseMaterialButton>
                    )
                }

                {/* Batch Numbers Modal button */}
                {
                    is_batch_number && (
                        <BaseMaterialButton
                            type="button"
                            onClick={() => setIsBatchNumbersModalOpen(true)}
                        >
                            {t("buttons.addBatchNumbers")}
                        </BaseMaterialButton>
                    )
                }
            </div>

            {/* Serial Numbers Modal */}
            <SerialNumbersModal
                isOpen={isSerialNumbersModalOpen}
                onClose={() => setIsSerialNumbersModalOpen(false)}
                product={{
                    quantity: form.getValues("quantity") ?? 0,
                    serial_numbers: form.getValues("serial_numbers") ?? []
                }}
                onSave={(data) => {
                    const readySerialNumbers = data.product.serial_numbers.map(serialNumber => ({
                        serial_number: serialNumber.serial_number
                    }));

                    form.setValue("serial_numbers", readySerialNumbers);
                    setIsSerialNumbersModalOpen(false);
                }}
            />

            {/* Batch Numbers Modal */}
            <BatchNumbersModal
                isOpen={isBatchNumbersModalOpen}
                onClose={() => setIsBatchNumbersModalOpen(false)}
                product={{
                    quantity: form.getValues("quantity") ?? 0,
                    batch_numbers: form.getValues("batch_numbers") ?? []
                }}
                onSave={(data) => {
                    const readyBatchNumbers = data.product.batch_numbers.map(batchNumber => ({
                        batch_number: batchNumber.batch_number,
                        expiration_date: batchNumber.expiration_date
                    }));

                    form.setValue("batch_numbers", readyBatchNumbers);
                    setIsBatchNumbersModalOpen(false);
                }}
            />
        </>
    );
}