import { internalApiSlice, tags, transformResponse } from "@redux/api/internalApiSlice";
import { Login, Me, Registration } from "@/types/general";
import { AxiosResponse } from "axios";

export const authApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        register: builder.mutation<void, Registration.DTO.Create>({
            query: (body) => ({
                url: `register`,
                method: "POST",
                data: body
            })
        }),

        verificationEmail: builder.mutation<void, Registration.DTO.ResendVerificationEmail>({
            query: (body) => ({
                url: `email/send-verification`,
                method: "POST",
                data: body
            })
        }),

        login: builder.mutation<Login.Root, Login.DTO.Auth>({
            query: (credentials) => ({
                url: `login`,
                method: "POST",
                data: credentials
            }),
            transformResponse,
            invalidatesTags: tags
        }),

        getMe: builder.query<Me.Root, void>({
            query: () => ({
                url: `me`
            }),
            transformResponse
        }),

        authEchoChannel: builder.mutation<AxiosResponse, { socketId: number; channelName: string }>({
            query: ({ socketId, channelName }) => ({
                url: `broadcasting/auth`,
                method: "POST",
                data: {
                    socket_id: socketId,
                    channel_name: channelName
                }
            })
        })
    })
});

export const {
    useRegisterMutation,
    useVerificationEmailMutation,
    useLoginMutation,
    useGetMeQuery
} = authApi;