import React, { useState } from "react";
import "@assets/stylesheets/_footer.scss";
import { useTranslation, Trans } from "react-i18next";

// images
import BrandLogo from "@assets/images/logo.svg";
import IcFacebook from "@assets/icons/social_media/ic_facebook.svg";
import IcLinkedin from "@assets/icons/social_media/ic_linkedin.svg";
import IcInstagram from "@assets/icons/social_media/ic_instagram.svg";

import IcFacebookActive from "@assets/icons/social_media/ic_facebook_active.svg";
import IcLinkedinActive from "@assets/icons/social_media/ic_linkedin_active.svg";
import IcInstagramActive from "@assets/icons/social_media/ic_instagram_active.svg";

import IcFacebookHover from "@assets/icons/social_media/ic_facebook_hover.svg";
import IcLinkedinHover from "@assets/icons/social_media/ic_linkedin_hover.svg";
import IcInstagramHover from "@assets/icons/social_media/ic_instagram_hover.svg";
import { toInteger } from "lodash";

interface FooterBlockProperties {
  useTopMargin?: boolean;
}

const FooterBlock: React.FC<FooterBlockProperties> = ({
  useTopMargin = false
}) => {
  const { t, i18n } = useTranslation();

  const [smState, setSmState] = useState('n')

  const stateIcons = { n: [IcFacebook, IcLinkedin, IcInstagram], a: [IcFacebookActive, IcLinkedinActive, IcInstagramActive], h: [IcFacebookHover, IcLinkedinHover, IcInstagramHover] }

  const smIcon = function (index: string): string {

    const x: number = toInteger(index)
    if (smState === 'a') return stateIcons.a[x]
    else return stateIcons.h[x]
  };

  return (
    <div className={`relative ${useTopMargin && "mt-[200px]"}`}>
      {/*<div className="absolute w-full h-full bg-gradient-to-b from-[#ffffff] to-[#7555F9] opacity-10"></div>*/}
      <div className="container-lg py-[100px] min-w-[500px]">
        <footer className="tablet:flex tablet:justify-between">
          <div className="flex flex-column tablet:max-w-[427px] max-tablet:mb-8">
            <div className="grow">
              <a href="/"><img src={BrandLogo} alt="suppli-logo" /></a>
              <p className="text-description font-light mt-[24px]">{t("footer.desc")}</p>
            </div>
            <div className="text-[#2B2A35] text-base font-semibold leading-[1.5]">
              {t("footer.copyright")}
            </div>
          </div>

          <div className="tablet:min-w-[642px] tablet:flex tablet:justify-between grid max-tablet:gap-y-8 max-tablet:grid-cols-2">
            {/* Company */}
            <div>
              <div className="text-[#2B2A35] text-xl font-semibold leading-[1.4] mb-8">
                {t("footer.company.title")}
              </div>
              <ul className="space-y-5">
                {[
                  // [t("navbar.home"), "/"],
                  [t("navbar.product"), "/product"],
                  [t("navbar.pricing"), "/price"],
                  ["Partners", "/partners"],
                  [t("navbar.about_us"), "/about"],
                  [t("navbar.news"), "/news"],
                ].map(([text, href], i) => (
                  <li key={i}>
                    <a
                      className="text-[#2B2A35] text-base leading-[1.5] font-light"
                      href={href}
                    >
                      {text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Product */}
            <div>
              <div className="text-[#2B2A35] text-xl font-semibold leading-[1.4] mb-8">
                {t("footer.product.title")}
              </div>
              <ul className="space-y-4">
                {[
                  [(t("navbar.login") + " / " + (t("loginPage.registerBtn"))), "/login"],
                  [t("footer.product.links.1"), "/"],
                  [t("footer.product.links.2"), "/support"],
                ].map(([text, href], i) => (
                  <li key={i}>
                    <a
                      className="text-[#2B2A35] text-base leading-[1.5] font-light"
                      href={href}
                    >
                      {text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Follow Us */}
            <div className="min-w-[103px]">
              <div className="text-[#2B2A35] text-xl font-semibold leading-[1.4] mb-8">
                {t("footer.followUs")}
              </div>
              <div className="flex flex-row space-x-6">
                <a key={0} href={"https://www.facebook.com/suppliwms"} target="_blank" rel="noreferrer">
                  <img
                    src={IcFacebook}
                    onMouseOver={e => e.currentTarget.src = IcFacebookHover}
                    onMouseOut={e => e.currentTarget.src = IcFacebook}
                    onClick={e => e.currentTarget.src = IcFacebookActive} />
                </a>

                <a key={1} href={"https://www.linkedin.com/company/suppliwms/"} target="_blank" rel="noreferrer">
                  <img
                    src={IcLinkedin}
                    onMouseOver={e => e.currentTarget.src = IcLinkedinHover}
                    onMouseOut={e => e.currentTarget.src = IcLinkedin}
                    onClick={e => e.currentTarget.src = IcLinkedinActive} />
                </a>

                <a key={2} href={"https://www.instagram.com/suppliwms/"} target="_blank" rel="noreferrer">
                  <img
                    src={IcInstagram}
                    onMouseOver={e => e.currentTarget.src = IcInstagramHover}
                    onMouseOut={e => e.currentTarget.src = IcInstagram}
                    onClick={e => e.currentTarget.src = IcInstagramActive} />
                </a>

              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default FooterBlock;
