import React, { HTMLAttributes, PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, ButtonGroup } from "@mui/material";
import { BaseButtonGroupItem } from "../../../types/reusablesTypes";

interface BaseButtonGroupProperties {
  items: BaseButtonGroupItem[];
  defaultActive?: number;
  className?: string;
}

export default function BaseButtonGroup({
  items,
  defaultActive = 0,
  className
}: BaseButtonGroupProperties): JSX.Element {

  // Custom animation configuration. Moving "back fade" to the relative position of clicked button, fully copying its size.
  const [active, setActive] = useState<number>(defaultActive);
  const [backFadeConfig, setBackFadeConfig] = useState<{ left: number, width: number, height: number }>();
  const buttonsGroupRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const button = buttonsGroupRef?.current?.children.item(active) as HTMLElement | null;

    if (button != null) {
      setBackFadeConfig({
        left: button.offsetLeft,
        width: button.offsetWidth + 1,
        height: button.offsetHeight
      });
    }
  }, [active]);

  return (
    <div className={`${className ?? ""} flex flex-row relative bg-[#A6DD4C] rounded-md p-[3px] gap-[3px]`} ref={buttonsGroupRef}>
      {
        items.map((item, index) => {
          return (
            <div key={index} onClick={() => {
              setActive(index);

              if (item.onClick)
                item.onClick();
            }} className="py-[4px] px-[10px] text-[#3C3769] font-light cursor-pointer rounded-md transition-colors hover:bg-white">
              <span className="relative z-50">{item.content}</span>
            </div>
          )
        })
      }

      <div className="absolute top-[3px] bg-white rounded-md transition-all z-0" style={{
        left: backFadeConfig?.left,
        width: backFadeConfig?.width,
        height: backFadeConfig?.height
      }}></div>
    </div>
  )
}