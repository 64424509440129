import React, { useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { SignUpFormTyping } from "./TabsController";
import { TabProperties } from "../types";
import BaseInput from "@reusables/BaseInput";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BasePhoneInput from "@reusables/BasePhoneInput";
import BaseButton from "@reusables/BaseButton";
import { Typography } from "@mui/material";
import BasePopper from "@reusables/BasePopper";
import { AlertCircle, Check } from "lucide-react";
import { useTranslation } from "react-i18next";

export default function YourProfileTab({onNext}: TabProperties) {
    const {t} = useTranslation("", {keyPrefix: "signupPage.yourProfile"});

    const {control, setValue, trigger} = useFormContext<SignUpFormTyping>();

    // Define a state to store the anchor element for the Popper.
    const [popperAnchorEl, setPopperAnchorEl] = useState<HTMLDivElement | null>(null);

    // Define a ref for the div wrapping the input to use as an anchor for the Popper.
    const inputWrapperRef = useRef(null);

    const handleFocus = () => {
        // Use the current element as the anchor for the Popper.
        setPopperAnchorEl(inputWrapperRef.current);
    };

    const handleBlur = () => {
        setPopperAnchorEl(null);
    };

    // Define a function to evaluate the password rules.
    const evaluatePasswordRules = (password: SignUpFormTyping['your_profile']['password']) => {
        const rules = [
            { id: 'length', text: t("passwordRules.0"), isValid: password?.length >= 8 },
            { id: 'letter', text: t("passwordRules.1"), isValid: password && /[a-zA-Z]/.test(password) },
            { id: 'number', text: t("passwordRules.2"), isValid: /[0-9]/.test(password) }
        ];

        return rules;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const isProfileValid = await trigger('your_profile');

        if (isProfileValid) {
            onNext(e);
        }
    };

    return (
        <>
            <div className="flex flex-col my-16 space-y-8">
                <div className="text-2xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-xl font-thin text-center text-secondary-gray">{t("subheading")}</div>
            </div>
            <form className="space-y-[40px]" onSubmit={handleSubmit}>
                <BaseInputsGrid cols={{xs: 1, sm: 2}} gap={{x: 32, y: 24}}>
                    <Controller
                        control={control}
                        name="your_profile.first_name"
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.firstName")}
                                onChange={(e) => {
                                    field.onChange(e);
                                    if (fieldState.error) {
                                        trigger('your_profile.first_name');
                                    }
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="your_profile.last_name"
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.lastName")}
                                onChange={(e) => {
                                    field.onChange(e);
                                    if (fieldState.error) {
                                        trigger('your_profile.last_name');
                                    }
                                }}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="your_profile.email"
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.email")}
                                onChange={(e) => {
                                    field.onChange(e);
                                    if(fieldState.error) {
                                        trigger('your_profile.email');
                                    }
                                }}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="your_profile.phone.number"
                        render={({field, fieldState}) => (
                            <BasePhoneInput
                                {...field}
                                {...fieldState}
                                label={t("fields.phone")}
                                variant={"custom"}
                                onChange={(e) => {
                                    field.onChange(e);
                                    if (fieldState.error) {
                                        trigger('your_profile.phone.number');
                                    }
                                }}
                                onValueChange={(value, country) => {
                                    setValue("your_profile.phone.code", country.countryCode);
                                }}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="your_profile.password"
                        render={({ field, fieldState }) => (
                            <div ref={inputWrapperRef} className="relative" onFocus={handleFocus} onBlur={handleBlur}>
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.password")}
                                    type="password"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        if (fieldState.error) {
                                            trigger('your_profile.password');
                                        }
                                    }}
                                    hideErrors
                                />
                                <BasePopper open={!!popperAnchorEl} anchorEl={popperAnchorEl} placement='bottom-start'>
                                    <div className="bg-[#fff] p-[14px] rounded-[8px] levitation font-thin text-sm space-y-[4px]">
                                        {evaluatePasswordRules(field.value).map(rule => (
                                            <div key={rule.id} className="flex items-center space-x-2">
                                                {rule.isValid ? <Check size={16} className="text-accent" /> : <AlertCircle size={16} className="text-error" />}
                                                <Typography variant="body2" className={rule.isValid ? "text-accent" : "text-error"}>{rule.text}</Typography>
                                            </div>
                                        ))}
                                    </div>
                                </BasePopper>
                            </div>
                        )}
                    />

                    <Controller
                        control={control}
                        name="your_profile.confirm_password"
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.confirmPassword")}
                                type="password"
                                onChange={(e) => {
                                    field.onChange(e);
                                    if (fieldState.error) {
                                        trigger('your_profile.confirm_password');
                                    }
                                }}
                            />
                        )}
                    />
                </BaseInputsGrid>

                <div className="flex justify-center">
                    <BaseButton
                        text={t("button")}
                        size="md"
                        style={{backgroundImage: 'linear-gradient(90.89deg, #2B2A35 0.67%, #6959FC 99.24%) 1'}}
                    />
                </div>
            </form>
        </>
    )
}