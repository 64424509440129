import React from "react";
import {Pagination, PaginationProps} from "@mui/material";
import {PaginationWrapper} from "@/types/types";

export const BasePagination = (props: PaginationProps & {config?: PaginationWrapper}) => (
  <Pagination
    count={props.config?.count.pages}
    page={props.config?.page.currentPage}

    {...props}
    sx={{
      ".Mui-selected": {
        background: "#7556FA !important",
        color: "#fff",
      },

      ...props.sx
    }}
    shape="rounded"

    onChange={(event, value) => {
      props.config?.page.setCurrentPage(value);

      if(props.onChange)
        props.onChange(event, value);
    }}
  />
);

export default BasePagination;
