import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BaseModal from '@reusables/Modals/BaseModal';
import { z } from 'zod';
import { selectEmployeeByCode, useCreateEmployeeMutation } from '@redux/features/employees/employeesApi';
import { globalStore } from '@redux/store';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetLanguagesQuery } from '@redux/features/languages/languagesApi';
import BaseInput from '@reusables/BaseInput';
import { toast } from 'react-toastify';
import { isErrorWithMessage } from '@redux/api/query';
import BasePhoneInput from '@reusables/BasePhoneInput';
import BaseDropdown from '@reusables/dropdowns/BaseDropdown';
import BaseButton from '@reusables/BaseButton';
import { Employee } from '@/types/general';
import { formScheme } from '@/components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Employees/modals/MutationModal';


type CreateOurRefernceModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onCreate: (employee: Employee.Root) => void;
}

type FormTyping = z.infer<typeof formScheme>

export default function CreateOurReferenceModal({ isOpen, onClose, onCreate}: CreateOurRefernceModalProps) {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.general.modals.createOurReference"});

    const { data: languageOptions = [], isLoading: languageOptionsLoading } = useGetLanguagesQuery();

    const [createEmployee, { isLoading: isCreationLoading }] = useCreateEmployeeMutation();

    const {control, handleSubmit, reset} = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            code: {
                checkable: true,
            }
        }
    });

    useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen]);

    const onSubmit = handleSubmit((data) => {
        createEmployee({
            code: data.code.value ?? undefined,
            name: data.name,
            job_title: data.job_title,
            email: data.email,
            phone: data.phone,
            language: data.language?.id
        }).unwrap().then((ourReference) => {
            console.log(ourReference);
            onCreate(ourReference);
            toast.success(t("responses.success"));
        })
        .catch((e) => {
            if(isErrorWithMessage(e)) {
                toast.error(e.message);
            } else {
                toast.error(t("responses.error"));
                console.error(e);
            }
        });
    });

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={562}
            padding="56px"
            useCloseIcon
            isLoading={isCreationLoading}
        >
            <form className="space-y-8" onSubmit={onSubmit}>
                <div className="modal-title">{t("heading")}</div>

                <Controller
                    name="code.value"
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            label={t("fields.code")}
                            error={fieldState.error}
                        />
                    )}
                />

                <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            label={t("fields.name")}
                            error={fieldState.error}
                        />
                    )}
                />

                <Controller
                    name="job_title"
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            label={t("fields.jobTitle")}
                            error={fieldState.error}
                        />
                    )}
                />

                <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            label={t("fields.email")}
                            error={fieldState.error}
                        />
                    )}
                />

                <Controller
                    name="phone"
                    control={control}
                    render={({ field, fieldState }) => (
                        <BasePhoneInput
                            {...field}
                            error={fieldState.error}
                            label={t('fields.phone')}
                            variant="custom"
                            size="md"
                        />
                    )}
                />

                <Controller
                    name="language"
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseDropdown
                            {...field}
                            label={t("dropdowns.language.label")}
                            placeholder={t("dropdowns.language.placeholder")}

                            options={languageOptions}
                            error={fieldState.error}
                            isLoading={languageOptionsLoading}

                            getter={{
                                key: (opt) => opt.id,
                                label: (opt) => opt.name,
                            }}

                            autocomplete
                        />
                    )}
                />

                <div>
                    <BaseButton 
                        buttonWidth="100%" 
                        text={`${t("buttons.create")}`}
                        size="md" 
                        type="submit" 
                    />
                </div>
            </form>
        </BaseModal>
    )
}
