import { AbilityBuilder, AbilityClass, PureAbility } from "@casl/ability";
import { Me } from "@/types/general";
import { createContext, useContext } from "react";
import { createContextualCan } from "@casl/react";

const permissions = {
    general: {
        company: ["view", "edit"] as const,
        address: ["view", "edit"] as const,
        role: ["view", "edit", "delete"] as const,
        employees: ["view", "edit", "add"] as const,
        user: ["view", "edit", "invite"] as const,
        units: ["view"] as const,
        locations: ["view", "edit"] as const,
        currencies: ["view", "edit"] as const,
        payment_terms: ["view", "edit"] as const,
        delivery_terms: ["view", "edit"] as const,
        packaging: ["view", "edit"] as const,
        languages: ["view", "edit"] as const,
        tax_rates: ["view", "edit"] as const,
        categories: ["view", "edit"] as const,
        upcoming_shipment: ["view"] as const,
        revenue: ["view"] as const,
        best_selling_products: ["view"] as const,
        feed: ["view"] as const,
        restocking: ["view"] as const,
        integrations: ["view"] as const
    },
    product: {
        view: null,
        create: null,
        edit: null,
        delete: null,
        export: null,
        import: null,
        price: ["view", "edit", "view_price_history"] as const,
        locations: ["view", "edit"] as const,
        purchase_orders: ["view"] as const,
        sale_orders: ["view"] as const,
        serial_number: ["view", "edit"] as const,
        batch_number: ["view", "edit"] as const,
        services: ["create"] as const,
        bill_of_materials: ["view", "create"] as const
    },
    sale_order: {
        view: null,
        create: null,
        edit: ["own_records", "specific_records", "all_records"] as const,
        delete: null,
        export: null,
        purchase_order: ["create"] as const,
        price: ["view"] as const,
        picking: ["view"] as const
    },
    picking: {
        view: null,
        create: null,
        edit: ["own_records", "specific_records", "all_records"] as const,
        delete: null,
        download: null,
        packaging: ["view", "edit"] as const
    },
    purchase_order: {
        view: null,
        create: null,
        edit: ["own_records", "specific_records", "all_records"] as const,
        delete: null,
        export: null,
        order_price: ["view"] as const,
        purchase_order_pdf: ["download"] as const,
        receive: ["view"] as const
    },
    receive: {
        view: null,
        create: null,
        edit: ["own_records", "specific_records", "all_records"] as const,
        delete: null,
        export: null
    },
    customer: {
        view: null,
        create: null,
        edit: ["own_records", "specific_records", "all_records"] as const,
        delete: null,
        import: null,
        contact_detail: ["view"] as const,
        billing_address: ["view", "edit"] as const,
        delivery_address: ["view", "edit"] as const,
        sale_order: ["view"] as const,
        product_list: ["view", "edit"] as const,
        tax_rate: ["edit"] as const,
        discount: ["edit"] as const
    },
    supplier: {
        view: null,
        create: null,
        edit: ["own_records", "specific_records", "all_records"] as const,
        delete: null,
        contact_detail: ["view"] as const,
        billing_address: ["view", "edit"] as const,
        return_address: ["view", "edit"] as const,
        tax_rate: ["edit"] as const,
        purchase_order: ["view"] as const
    },
    adjustment: {
        view: null,
        with_purchase_price: ["create"] as const,
        with_quantity: ["create"] as const
    },
    collection: {
        view: null,
        create: null,
        edit: null,
        delete: null,
        restore: ["view"] as const
    },
    stock: {
        view: null,
        create: null,
        edit: null,
        export: null
    },
    stock_count: {
        view: null,
        create: null,
        manage: ["view"] as const,
        export: null
    },
    transfer: {
        view: null,
        create: null,
        export: null
    },
    custom_fields: {
        view: null,
        create: null,
        edit: null,
        delete: null
    }
} as const;

type ModulePermissions = typeof permissions;

export type FlattenPermissions<T> = T extends unknown
    ? {
        [K in keyof T]:
        K extends "edit" | "view"
            ? T[K] extends null
                ? K
                : T[K] extends readonly string[]
                    ? K | `${K & string}.${T[K][number]}`
                    : never
            : T[K] extends null
                ? K
                : T[K] extends readonly string[]
                    ? `${K & string}.${T[K][number]}`
                    : never;
    }[keyof T]
    : never;

export type Actions = FlattenPermissions<ModulePermissions[keyof ModulePermissions]>;
export type Modules = keyof ModulePermissions;

type AppAbility = PureAbility<[Actions, Modules]>;

export const ability = new PureAbility<[Actions, Modules]>();

export function updateAbility(permissions: Me.Components.Permission[]) {
    const { can: allow, rules } = new AbilityBuilder<AppAbility>(PureAbility as AbilityClass<AppAbility>);

    permissions.forEach(permission => {
        const { module_key: module, abilities } = permission;
        abilities.forEach(abilityItem => {
            const { key: action } = abilityItem;

            const splitAction = action.split(".");

            if (
                splitAction.length > 1 &&
                (splitAction[1] === "edit" || splitAction[1] === "view")
            ) {
                allow(splitAction[1] as Actions, module as Modules);
            }

            allow(action as Actions, module as Modules);
        });
    });

    ability.update(rules);
}

export const AbilityContext = createContext(ability);
export const useAbility = () => useContext(AbilityContext);
export const Can = createContextualCan(AbilityContext.Consumer);
