import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import BaseTable from "@reusables/BaseTable";
import { useExportStockCountsMutation, useGetStockCountsQuery } from "@redux/features/stockCount/stockCountApi";
import BaseMaterialCheckbox from "@reusables/BaseMaterialCheckbox";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import {
    ArrayElementType,
    genT,
    jsxSwitch,
    PredefinedTranslations,
    removeEmpty,
    useDatesRangeFilter,
    useOrdering,
    usePagination
} from "@helpers/utils";

import { ReactComponent as CarretRightSVG } from "@assets/icons/ic_carret_right.svg";
import { ReactComponent as MergeSVG } from "@assets/icons/ic_merge.svg";
import { ReactComponent as ApproveSVG } from "@assets/icons/ic_approve_stock.svg";
import { ReactComponent as DeclineSVG } from "@assets/icons/ic_decline_stock.svg";

import BaseChip from "@reusables/BaseChip";
import _ from "lodash";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import { useHistory } from "react-router-dom";
import { Button, Fade, Pagination } from "@mui/material";
import ApproveModal from "./modals/ApproveModal";
import DeclineModal from "./modals/DeclineModal";
import { StockCount } from "@/types/general";
import { useGetModuleLocationsQuery } from "@redux/features/locations/locationsApi";
import { useGetModuleUsersQuery } from "@redux/api/internalApiSlice";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { DatepickerRangeContext } from "@reusables/BaseDatepicker/context";
import BaseDatepicker from "@reusables/BaseDatepicker";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import MergeModal from "@components/Dashboard/pages/Inventory/StockCount/modals/MergeModal";
import StockCountExportExportModal from "@components/Dashboard/pages/Inventory/StockCount/modals/StockCountExportModal";
import { Can, useAbility } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";


export default function StockCountPage() {
    const { t } = useTranslation("", { keyPrefix: "inventory.stockCount.main" });
    const history = useHistory();

    const ability = useAbility();

    // Searching value with debounce
    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = _.debounce(setSearchingValue, 1000);

    // Dates range
    const { datesRange, setDatesRange, filterAdaptedDatesRange } = useDatesRangeFilter();

    // Locations filter
    const { data: locationsOptions, isLoading: isLocationsLoading } = useGetModuleLocationsQuery("stock-count");
    const [selectedLocations, setSelectedLocations] = useState<typeof locationsOptions>();

    // Users filter
    const { data: usersOptions, isLoading: usersLoading } = useGetModuleUsersQuery("stock-count");
    const [selectedUsers, setSelectedUsers] = useState<typeof usersOptions>();

    // Defining status options
    const statusOptions: { label: string; key: 0 | 1 | 2 | 3 }[] = [
        {
            label: t("status.0"),
            key: 0
        },
        {
            label: t("status.1"),
            key: 1
        },
        {
            label: t("status.2"),
            key: 2
        },
        {
            label: t("status.3"),
            key: 3
        }
    ];

    const [selectedStatus, setSelectedStatus] = useState<ArrayElementType<typeof statusOptions>>();

    const { orderBy, setOrderBy } = useOrdering<StockCount.DTO.OrderBy>({ name: "id", type: "desc" });

    const pagination = usePagination({ page: 1, limit: 8 });

    const filters = removeEmpty<StockCount.DTO.Filters>({
        search: searchingValue,
        status: selectedStatus?.key,
        dates_range: filterAdaptedDatesRange,
        locations: selectedLocations?.map(x => ({
            store: x.store.id,
            ...(!!x.section && { section: x.section.id })
        })),
        workers: selectedUsers?.map(x => x.id)
    });

    const { data: stockCount, isFetching: isLoadingStockCount } = useGetStockCountsQuery({
        filters,
        orderBy,
        pagination: {
            page: pagination.page,
            limit: pagination.limit
        }
    });

    const [exportStockCounts, { isLoading: isExportingStockCounts }] = useExportStockCountsMutation();

    // =========== OTHER STUFF =========== //
    const [selectedStocks, setSelectedStocks] = useState<number[]>([]);
    useEffect(() => {
        setSelectedStocks([]);
    }, [stockCount]);

    /**
     * Full info of type StockCount.Root for selected stocks used for merge.
     * Used with `useMemo` to prevent unnecessary re-renders inside the MergeModal.
     * @see MergeModal
     */
    const selectedStocksExtended = useMemo(() => {
        if (!stockCount?.payload || !selectedStocks.length)
            return [];

        return stockCount.payload.filter(stock => selectedStocks.includes(stock.id));
    }, [selectedStocks, stockCount]);

    const [selectedStockForStatusChange, setSelectedStockForStatusChange] = useState<StockCount.Root>();

    // =========== MODALS =========== //
    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
    const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
    const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);

    return (
        <>
            <div className="flex space-x-[24px]">
                <BaseAdminFilter
                    permissionModule="stock_count"
                    handleSearch={searchInputDebounce}
                    handleFilter={() => console.log("Filter")}
                    handleExport={() => setIsExportModalOpen(true)}
                    filterItems={
                        <div className="w-[363px]">
                            <BaseInputsGrid cols={1} gap={24}>
                                <BaseDropdown
                                    label={t("filters.location.label")}

                                    options={locationsOptions}
                                    getter={{
                                        label: opt => `${opt.store.name}${opt.section ? ` - ${opt.section.name}` : ""}`,
                                        key: opt => opt.section ? `${opt.store.id}_${opt.section.id}` : `${opt.store.id}`,
                                        renderOption: (opt, icon) => (
                                            <div>
                                                <div className="grow">
                                                    <span
                                                        className="bold-highlight">{opt.store.name}</span>{opt.section ? <> - {opt.section.name}</> : null}
                                                </div>
                                                {
                                                    selectedLocations?.includes(opt) ? icon : null
                                                }
                                            </div>
                                        )
                                    }}
                                    value={selectedLocations}

                                    onChange={(_, opts) => setSelectedLocations(opts)}

                                    emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                    isLoading={isLocationsLoading}

                                    autocomplete
                                    multiple
                                />

                                <DatepickerRangeContext.Provider value={{
                                    "dates_range": { range: datesRange, setRange: setDatesRange }
                                }}>
                                    <BaseDatepicker
                                        label={t("filters.date.label")}
                                        placeholder={t("filters.date.placeholder")}
                                        rangeConfig={{ groupKey: "dates_range", role: "solo" }}
                                    />
                                </DatepickerRangeContext.Provider>

                                <BaseDropdown
                                    label={t("filters.worker.label")}

                                    options={usersOptions}
                                    getter={{
                                        label: opt => `${opt.first_name} ${opt.last_name}`,
                                        key: opt => opt.id
                                    }}
                                    value={selectedUsers}

                                    onChange={(_, opts) => setSelectedUsers(opts)}

                                    emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                    isLoading={usersLoading}

                                    autocomplete
                                    multiple
                                />

                                <BaseDropdown
                                    label={t("filters.status.label")}

                                    options={statusOptions}
                                    getter={{
                                        label: opt => opt.label,
                                        key: opt => opt.key
                                    }}
                                    value={selectedStatus}

                                    onChange={setSelectedStatus}

                                    emptyValue={genT(PredefinedTranslations.DropdownsALL)}
                                />
                            </BaseInputsGrid>
                        </div>
                    }

                    extendWith={
                        <Fade in={selectedStocks.length > 1}>
                            <BasePermissionBlocker action="create" module="stock_count">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => setIsMergeModalOpen(true)}
                                    className="h-[56px] max-w-[128px]"
                                    disabled={ability.cannot("create", "stock_count")}
                                >
                                    <div className="flex justify-center items-center space-x-2 px-[32px]">
                                        <MergeSVG />
                                        <span>Merge</span>
                                    </div>
                                </Button>
                            </BasePermissionBlocker>
                        </Fade>
                    }
                    className="grow"
                />
                <div
                    className="flex justify-between gap-[16px] p-[24px] rounded-xl items-center bg-[#D9DAFD] w-[242px]">
                    <span className="text-accent font-[600]">{t("redirect")}</span>
                    <div className={"rotate-90"}>
                        <BaseMaterialIconButton
                            style={"light"}
                            icon={<CarretRightSVG />}
                            onClick={() => history.push("/dashboard/inventory/stock")}
                            className={"!bg-[#7556FA] border-0 hover:!bg-accent !text-[#fff]"}
                        />
                    </div>
                </div>
            </div>
            <div className="levitation-extended mt-[32px]">
                <Can not I="view" a="stock_count">
                    <NoPermissionBanner />
                </Can>

                <Can I="view" a="stock_count">
                    <BaseTable
                        data={stockCount?.payload ?? []}
                        columns={[
                            {
                                header: <BaseMaterialCheckbox
                                    checked={!isLoadingStockCount && selectedStocks.length === stockCount?.payload.length}
                                    onChange={(e, state) => {
                                        if (state) {
                                            setSelectedStocks(stockCount?.payload.map(stock => stock.id) ?? []);
                                        } else {
                                            setSelectedStocks([]);
                                        }
                                    }}
                                />,
                                getter: (row, index, isCollapsed) => <BaseMaterialCheckbox
                                    checked={selectedStocks.includes(row.id)}
                                    onChange={(e, state) => {
                                        const resultingChecked = selectedStocks;

                                        const index = resultingChecked.indexOf(row.id);

                                        if (index > -1)
                                            resultingChecked.splice(index, 1);

                                        if (state)
                                            resultingChecked.push(row.id);

                                        setSelectedStocks([...resultingChecked]);
                                    }}
                                    borderColor={isCollapsed ? "#3C3769" : undefined}
                                />,
                                preventCollapsePropagation: true
                            },
                            {
                                header: t("table.columns.0"),
                                getter: row => <div className="bold-highlight">{row.code}</div>,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.1"),
                                getter: row => row.entries[0].store?.name ?? ""
                            },
                            {
                                header: t("table.columns.2"),
                                getter: row => row.date.format("DD.MM.YYYY"),
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.3"),
                                getter: row => row.user.first_name + " " + row.user.last_name,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.4"),
                                getter: row => jsxSwitch(
                                    {
                                        0: <BaseChip fill={"yellow"}>{t(`status.0`)}</BaseChip>,
                                        1: <BaseChip fill={"blue"}>{t(`status.1`)}</BaseChip>,
                                        2: <BaseChip fill={"red"}>{t(`status.2`)}</BaseChip>,
                                        3: <BaseChip fill={"green"}>{t(`status.3`)}</BaseChip>
                                    },
                                    row.status
                                ),
                                comparator: () => 0
                            }
                        ]}

                        collapse={{
                            fill: "#D9DAFD",
                            borderColor: "#B3B5EB",
                            content: mainRow => {
                                return (
                                    <>
                                        <BaseTable
                                            data={mainRow.entries}
                                            columns={[
                                                {
                                                    header: <div
                                                        className="text-accent font-semibold">{t("subtable.columns.0")}</div>,
                                                    getter: subRow => subRow.product.name

                                                },
                                                {
                                                    header: <div
                                                        className="text-accent font-semibold">{t("subtable.columns.1")}</div>,
                                                    getter: subRow => subRow.store?.name ?? ""

                                                },
                                                {
                                                    header: <div
                                                        className="text-accent font-semibold">{t("subtable.columns.2")}</div>,
                                                    getter: subRow => subRow.system_quantity
                                                },
                                                {
                                                    header: <div
                                                        className="text-accent font-semibold">{t("subtable.columns.3")}</div>,
                                                    getter: subRow => subRow.counted_quantity
                                                }
                                            ]}

                                            size="small"
                                            nothingFound={{
                                                height: 200
                                            }}
                                            hideTableWhenNothingFound
                                        />

                                        <div className="space-y-2 ml-4">
                                            {
                                                mainRow.cancel_comment &&
                                                <div>
                                                    <div className="flex">
                                                        <span
                                                            className="text-accent font-semibold mr-2">{mainRow.user?.first_name + " " + mainRow.user?.last_name}</span>
                                                    </div>
                                                    <div>
                                                        {mainRow.cancel_comment}
                                                    </div>
                                                </div>
                                            }

                                            {
                                                mainRow.decline_comment &&
                                                <div>
                                                    <div className="flex">
                                                        <span
                                                            className="text-accent font-semibold mr-2">{mainRow.reviewer?.first_name + " " + mainRow.reviewer?.last_name}</span>
                                                    </div>
                                                    <div>
                                                        {mainRow.decline_comment}
                                                    </div>
                                                </div>
                                            }

                                            {
                                                mainRow.status === 0 &&
                                                <div className="flex gap-4">
                                                    <Can I="create" a="stock_count">
                                                        <BaseMaterialButton onClick={() => {
                                                            setIsApproveModalOpen(true);
                                                            setSelectedStockForStatusChange(mainRow);
                                                        }}>
                                                            <ApproveSVG className="text-positive mr-2" />
                                                            <span
                                                                className="text-positive">{t("subtable.buttons.approve")}</span>
                                                        </BaseMaterialButton>
                                                    </Can>
                                                    <Can I="create" a="stock_count">
                                                        <BaseMaterialButton onClick={() => {
                                                            setIsDeclineModalOpen(true);
                                                            setSelectedStockForStatusChange(mainRow);
                                                        }}>
                                                            <DeclineSVG className="text-error mr-2" />
                                                            <span className="text-error">{t("subtable.buttons.decline")}</span>
                                                        </BaseMaterialButton>
                                                    </Can>
                                                </div>
                                            }
                                        </div>
                                    </>
                                );
                            }
                        }}

                        alternate
                        isDataLoading={isLoadingStockCount}

                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering) {
                                    let name: StockCount.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 1:
                                            name = "id";
                                            break;
                                        case 3:
                                            name = "date";
                                            break;
                                        case 4:
                                            name = "worker";
                                            break;
                                        case 5:
                                            name = "status";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                        setOrderBy({
                                            name,
                                            type: newOrdering.order
                                        });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}
                    />

                    <Pagination
                        className="mt-[32px]"
                        {...pagination.adapt(stockCount)}
                    />
                </Can>
            </div>


            <ApproveModal
                isOpen={isApproveModalOpen}
                onClose={() => {
                    setIsApproveModalOpen(false);
                }}
                report={selectedStockForStatusChange}
            />

            <DeclineModal
                isOpen={isDeclineModalOpen}
                onClose={() => {
                    setIsDeclineModalOpen(false);
                }}
                report={selectedStockForStatusChange}
            />

            <MergeModal
                isOpen={isMergeModalOpen}
                onClose={() => setIsMergeModalOpen(false)}
                reports={selectedStocksExtended}
            />

            <StockCountExportExportModal
                isOpen={isExportModalOpen}
                onClose={() => void setIsExportModalOpen(false)}
                records={selectedStocks}
                filters={filters}
                orderBy={orderBy}
            />
        </>
    );
}