import React from "react";
import NavigationBar from "../NavigationBar";
import "./index.scss";
import "@assets/stylesheets/_header.scss";
import "@assets/stylesheets/_page-top.scss";

import WaveImg from "@assets/images/waveIcon.svg";
import ProductPlugImg from "@assets/images/productPlug.png";
import DescrPlugOneImg from "@assets/images/productDescrPlugOne.png";
import DescrPlugTwoImg from "@assets/images/productDescrPlugTwo.png";
import DescrPlugThreeImg from "@assets/images/productDescrPlugThree.png";
import DescrPlugFourImg from "@assets/images/productDescrPlugFour.png";
import GradientCard from "../Card/GradientCard";
import FooterBlock from "../FooterBlock";

import { useTranslation, Trans } from 'react-i18next';

const ProductPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  const productDescrArr = [
    {
      imgSrc: DescrPlugOneImg,
      header: t('productPage.productDesc.1.header'),
      description:
        t('productPage.productDesc.1.description'),
    },
    {
      imgSrc: DescrPlugTwoImg,
      header: t('productPage.productDesc.2.header'),
      description:
        t('productPage.productDesc.2.description'),
    },
    {
      imgSrc: DescrPlugThreeImg,
      header: t('productPage.productDesc.3.header'),
      description:
        t('productPage.productDesc.3.description'),
    },
    {
      imgSrc: DescrPlugFourImg,
      header: t('productPage.productDesc.4.header'),
      description:
        t('productPage.productDesc.4.description'),
    },
  ];

  return (
    <>
      <NavigationBar />
      <main className="product-wrap">
        <div className="container-lg">
          <div className="d-flex justify-content-center">
            <div className="img-block page-top">
              <img src={ProductPlugImg} className="img" />
            </div>
          </div>
          <div className="header-block">
            <img src={WaveImg} />
            <div className="header-product">{t('productPage.productsBlock.header')}</div>
            <div className="description">
              {t('productPage.productsBlock.desc')}
            </div>
          </div>
          <div className="product-description">
            {productDescrArr.map((el, idx) => (
              <GradientCard
                key={idx}
                img={el.imgSrc}
                header={el.header}
                description={el.description}
                isGradientHeader
              />
            ))}
          </div>
        </div>
      </main>
      <FooterBlock />
    </>
  );
};

export default ProductPage;
