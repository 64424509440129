import React from "react";

const NavigationCard: React.FC<{className?: string}> = ({children, className}) => {
  return(
    <div className={`levitation rounded-xl p-[10px] flex ${className}`}>
      {children}
    </div>
  )
}

export default NavigationCard;