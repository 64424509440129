import React, { useEffect, useState } from "react";
import { Product, Template } from "@/types/general";
import BaseButton from "@reusables/BaseButton";
import { ProductContext } from "./context";
import Options from "./parts/Options";
import General from "./parts/General";
import Prices from "./parts/Prices";
import Weights from "./parts/Weights";
import Inventory from "./parts/Inventory";
import { DisTempField, productScheme, ProductSchemeTyping, ProductType, TemplateMode } from "./types";
import Description from "./parts/Description";
import {
    ArrayElementType,
    arraysHaveSameValues,
    fetchArrayReactively,
    manualRequest,
    removeEmpty
} from "@helpers/utils";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import BaseDropdown from "@reusables/dropdowns/BaseDropdownLegacy";

import { ReactComponent as TemplateSVG } from "@assets/icons/ic_template.svg";
import { useTranslation } from "react-i18next";
import TemplateCreationModal from "./parts/Templates/TemplateCreationModal";
import TemplateDeletionModal from "./parts/Templates/TemplateDeletionModal";
import BaseLoadingModal from "@reusables/Modals/BaseLoadingModal";
import {
    useCreateProductMutation,
    useDeleteProductMutation,
    useUpdateProductInformationMutation
} from "@redux/features/products/productsApi";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { isErrorWithMessage } from "@redux/api/query";
import IsServiceCheckbox
    from "@components/Dashboard/pages/Products/components/CreationEditingLayout/parts/elements/IsServiceCheckbox";
import ServiceHider
    from "@components/Dashboard/pages/Products/components/CreationEditingLayout/parts/elements/ServiceHider";
import { createPortal } from "react-dom";
import { Stack } from "@mui/material";
import VariantsHider from "./parts/elements/VariantsHider";
import Variants from "./parts/Variants";
import { useAbility } from "@casl/react";
import { AbilityContext } from "@/casl.config";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { emptyProductCopy } from "@redux/features/products/productsSlice";
import BaseCustomFieldsSection from "@reusables/BaseCustomFieldsSection";

type CELProperties = {
    product: Product.Extended | undefined;
}

const imageDomainURLPrefix = import.meta.env.VITE_API_URL;

export default function CreationEditingLayout({
                                                  product
                                              }: CELProperties) {
    const history = useHistory();

    const dispatch = useAppDispatch();
    const productCopied = useAppSelector(state => state.products.product);

    const ability = useAbility(AbilityContext);

    const { t } = useTranslation("", { keyPrefix: "products" });

    const methods = useForm<ProductSchemeTyping>({
        resolver: zodResolver(productScheme),
        defaultValues: {
            mode: "creation",
            serial_numbers: [],
            batch_numbers: [],
            variants: [],
            is_service: false,
            options: {
                is_batch_number: false,
                is_serial_number: false,
                is_component: false
            },
            deleted_variants: [],
            custom_fields: []
        },
    });

    /**
     * Disabled fields contain unique field keywords to determine if a specific field is disabled or not
     */
    const [disabledFields, setDisabledFields] = useState<DisTempField[]>([]);

    /**
     * Loading available templates
     */
    const {
        data: templatesOptions,
        loading: templatesOptionsLoading,
        setData: setTemplatesOptions
    } = fetchArrayReactively<Template.Root<DisTempField>>({
        route: "template"
    });

    const [selectedTemplates, setSelectedTemplates] = useState<typeof templatesOptions>([]);

    const [templatesMode, setTemplatesMode] = useState<TemplateMode>("empty");

    // Getting mutation hooks for creation and updating
    const [createProduct, creationResult] = useCreateProductMutation();
    const [updateProduct, updateResult] = useUpdateProductInformationMutation();
    const [deleteProduct] = useDeleteProductMutation();

    /**
     * Monitor template selection to switch between modes; when a template is selected, disabled fields are hidden until the user enables management mode.
     */
    useEffect(() => {
        if (selectedTemplates.length) {
            setTemplatesMode("selected");
            setDisabledFields(selectedTemplates[0].disabled_fields);
        } else {
            setTemplatesMode("empty");
            setDisabledFields([]);
        }
    }, [selectedTemplates]);

    useEffect(() => {
        if (!product && productCopied) {
            product = productCopied;
        }
        dispatch(emptyProductCopy());
    }, [productCopied]);

    useEffect(() => {
        if (product) {
            const { setValue } = methods;

            setValue("mode", !productCopied ? "editing" : "creation");
            // General
            setValue("general.name", product.name);
            !productCopied && setValue("general.code", product.code);
            setValue("general.barcode", product.barcode);
            setValue("general.unit", product.unit);
            setValue("general.supplier", product.supplier);
            setValue("general.category", product.category);
            setValue("general.tax", product.tax);

            // Prices
            setValue("prices.purchase", product.prices?.purchase_price);
            setValue("prices.sale", product.prices?.selling_price);
            setValue("prices.extra_cost", product.prices?.extra_cost);

            // Weights & Sizes
            setValue("weights.weight", product.weights_and_sizes?.weight);
            setValue("weights.CBM", product.weights_and_sizes?.CBM);
            setValue("weights.width", product.weights_and_sizes?.width);
            setValue("weights.height", product.weights_and_sizes?.height);
            setValue("weights.length", product.weights_and_sizes?.length);

            // Options
            setValue("options.is_batch_number", product.is_batch_number ?? false);
            setValue("options.is_serial_number", product.is_serial_number ?? false);
            setValue("options.is_component", product.is_component ?? false);
            setValue("is_service", product.is_service ?? false);

            setValue("custom_fields", product.custom_fields ?? []);

            // Storing promises to fetch images for variants after the form is set
            const variantsImagesPromises: Promise<{ file: File, variant_index: number }>[] = [];

            // Variants
            setValue("variants", product?.variants?.map((variant, v_index) => {
                if (variant.image)
                    variantsImagesPromises.push(
                        fetch(imageDomainURLPrefix + variant.image_path)
                            .then(r => r.blob())
                            .then(blobFile => ({
                                    file: new File([blobFile], variant.image_path as string),
                                    variant_index: v_index
                                })
                            ));

                return {
                    mode: "editing" as const,

                    is_service: variant.is_service,

                    ...(!productCopied && {id: variant.id}),
                    general: {
                        name: variant.name,
                        ...(!productCopied && {code: variant.code}),
                        barcode: variant.barcode,
                        unit: variant.unit,
                        supplier: variant.supplier,
                        category: variant.category,
                        tax: variant?.tax
                    },
                    prices: {
                        purchase: variant.prices?.purchase_price,
                        sale: variant.prices?.selling_price,
                        extra_cost: variant.prices?.extra_cost
                    },
                    weights: {
                        weight: variant.weights_and_sizes?.weight,
                        CBM: variant.weights_and_sizes?.CBM,
                        width: variant.weights_and_sizes?.width,
                        height: variant.weights_and_sizes?.height,
                        length: variant.weights_and_sizes?.length
                    },
                    options: {
                        is_batch_number: variant.is_batch_number,
                        is_serial_number: variant.is_serial_number,
                        is_component: variant.is_component
                    },

                    inventory: {
                        min_purchase_quantity: variant.min_purchase_quantity,
                        min_sale_quantity: variant.min_sale_quantity
                    },

                    description: {
                        description: variant.description
                    },

                    batch_numbers: [],
                    serial_numbers: [],

                    custom_fields: variant.custom_fields ?? []
                };
            }) ?? []);

            setValue("inventory.min_purchase_quantity", product.min_purchase_quantity);
            setValue("inventory.min_sale_quantity", product.min_sale_quantity);

            // Description
            if (product.image)
                fetch(imageDomainURLPrefix + product.image_path)
                    .then(r => r.blob())
                    .then(blobFile => new File([blobFile], product?.image_path as string))
                    .then(file => {
                        setValue("description.image", file);
                    });

            setValue("description.description", product.description);

            if (product?.template) {
                setSelectedTemplates([product.template]);
            }

            // Fetching images for variants and setting them to the form
            Promise.allSettled(variantsImagesPromises).then(results => {
                results.forEach(result => {
                    if (result.status === "fulfilled") {
                        const { file, variant_index } = result.value;
                        setValue(`variants.${variant_index}.description.image`, file);
                    }
                });
            });
        } else {
            methods.reset();
        }
    }, [product]);

    /**
     * Method to get the correctly structured DTO for product / variant creation or mutation.
     * @param data
     */
    function getProductDTO(data: ProductType | ProductType) {
        const { mode, ...rest } = data;

        const mutationData: Product.DTO.UpdateInformation = removeEmpty({
            name: rest.general.name,
            code: rest.general.code,
            unit: rest.general.unit?.id,
            supplier: rest.general.supplier?.id,
            category: rest.general.category?.id,
            tax: rest.general.tax?.id,

            is_RFID: 0, // TODO: add RFID support

            ...(!!data.description.image && data.description.image.type !== "" && data.description.image && {
                image: data.description.image
            }),

            ...(product?.image && !data.description.image && {
                delete_image: 1 as const
            }),

            ...(data.is_service ?
                    // In case the product is SERVICE
                    {
                        is_batch_number: 0,
                        is_serial_number: 0,
                        is_component: 0,
                        is_service: 1,

                        weights_and_sizes: {},
                        inventory: {}
                    }
                    :
                    // In case the product is NOT SERVICE
                    {
                        barcode: rest.general.barcode,

                        is_batch_number: rest.options.is_batch_number ? 1 : 0,
                        is_serial_number: rest.options.is_serial_number ? 1 : 0,
                        is_component: rest.options.is_component ? 1 : 0,
                        is_service: 0,

                        weights_and_sizes: removeEmpty({
                            weight: rest.weights.weight,
                            CBM: rest.weights.CBM ?? undefined,
                            width: rest.weights.width ?? undefined,
                            height: rest.weights.height ?? undefined,
                            length: rest.weights.length ?? undefined
                        }),

                        inventory: removeEmpty({
                            in_stock: rest.inventory.in_stock,
                            min_inventory_quantity: rest.inventory.min_inventory_quantity
                        })
                    }
            ),

            description: data.description.description,

            min_purchase_quantity: rest.inventory.min_purchase_quantity,
            min_sale_quantity: rest.inventory.min_sale_quantity,

            custom_fields: rest.custom_fields.filter(x => x.value).map(field => ({
                id: field.id,
                value: field.value
            }))
        });

        if (mode !== "creation") {
            return {
                ...mutationData,
                prices: {
                    extra_cost: rest.prices.extra_cost,
                    ...(ability.can("price.edit", "product") && {
                        selling_price: data.prices.sale
                    })
                }
            };
        } else {
            const creationDTO: Product.DTO.Create = {
                ...mutationData,

                batch_numbers: [],
                serial_numbers: [],

                ...(!data.is_service && {
                    location: {
                        store: data.general.location?.store.id as number,

                        ...(data.general.location?.section && {
                            section: data.general.location?.section?.id
                        })
                    },

                    batch_numbers: data.batch_numbers.map(x => ({
                        batch_number: x.batch_number,
                        ...(
                            x.expiration_date && {
                                expiration_date: x.expiration_date.format("YYYY-MM-DD")
                            }
                        )
                    })),

                    serial_numbers: data.serial_numbers
                }),

                prices: removeEmpty({
                    purchase_price: rest.prices.purchase,
                    selling_price: rest.prices.sale,
                    extra_cost: rest.prices.extra_cost
                })
            };

            return creationDTO;
        }
    }

    const onSubmit = methods.handleSubmit(data => {
        if (!product) {
            // === Creating product with connected variants === //
            createProduct(
                getProductDTO(data) as Product.DTO.Create
            ).unwrap()
                .then(parentProduct => {

                    // Creating variants in-parallel
                    return Promise.allSettled(data.variants.map(variant => {
                        const variantData = getProductDTO(variant) as Product.DTO.Create;

                        return createProduct({
                            ...variantData,
                            variant_parent_id: parentProduct.id
                        }).unwrap();
                    }));

                }).then(() => {
                toast.success(t("createEditProduct.responses.creation.success"));
                history.push(`/dashboard/products`);
            })
                .catch(e => {
                    console.error(e);

                    if (isErrorWithMessage(e)) {
                        toast.error(e.message);
                    } else {
                        toast.error(t("createEditProduct.responses.creation.error"));
                    }
                });
        } else {
            // Filtering out deleted variants to not updated records that will be deleted
            const variantsToMutate = data.variants.filter(variant => variant.id ? !data.deleted_variants.includes(variant.id) : true);

            // Updating product with connected variants
            updateProduct({
                id: product.id,
                ...getProductDTO(data) as Product.DTO.UpdateInformation
            }).unwrap().then(parentProduct => {
                // Creating (or updating) variants in-parallel
                return Promise.allSettled(variantsToMutate.map(variant => {
                    if (variant.id) {
                        return updateProduct({
                            id: variant.id,
                            ...getProductDTO(variant) as Product.DTO.UpdateInformation
                        }).unwrap();
                    } else {
                        return createProduct({
                            variant_parent_id: parentProduct.id,
                            ...getProductDTO(variant) as Product.DTO.Create
                        }).unwrap();
                    }
                }));
            })
                .then(() => {
                    // Deleting variants
                    return Promise.allSettled(data.deleted_variants.map(variantId => {
                        return deleteProduct(variantId).unwrap();
                    }));
                })
                .then(() => {
                    toast.success(t("createEditProduct.responses.update.success"));
                    history.push(`/dashboard/products`);
                })
                .catch(e => {
                    if (isErrorWithMessage(e)) {
                        toast.error(e.message);
                    } else {
                        toast.error(t("createEditProduct.responses.update.error"));
                    }
                });
        }
    }, console.error);

    const [isTemplateCreationModalOpen, setIsTemplateCreationModalOpen] = useState<boolean>(false);
    const [activeTemplateDeletion, setActiveTemplateDeletion] = useState<ArrayElementType<typeof templatesOptions>>();
    const [isTemplateSavingLoadingModalOpen, setIsTemplateSavingLoadingModalOpen] = useState<boolean>(false);

    const productCreationHeaderEl = document.getElementById("product-creation-header");


    return (
        <BaseLoadingBlocker active={creationResult.isLoading || updateResult.isLoading}>
            <ProductContext.Provider value={{
                disabledFields: [disabledFields, setDisabledFields],
                templatesMode: templatesMode,
                product: product
            }}>
                <FormProvider {...methods}>
                    <form className="space-y-[40px]" onSubmit={onSubmit}>
                        {
                            productCreationHeaderEl && createPortal(
                                <Stack direction={"row"} spacing={1}>
                                    <IsServiceCheckbox />
                                    <div className={"w-[200px]"}>
                                        <BaseDropdown
                                            options={templatesOptions}
                                            value={selectedTemplates}
                                            getter={{
                                                key: opt => opt.id,
                                                label: opt => opt.name
                                            }}

                                            customize={{
                                                padding: "5px",
                                                leftIcon: {
                                                    el: <TemplateSVG className="text-purple-400" />,
                                                    paddingLeft: "40px"
                                                }
                                            }}

                                            emptyValue={t("newProduct.templates.dropdown.default")}

                                            onChange={setSelectedTemplates}

                                            isLoading={templatesOptionsLoading}

                                        />
                                    </div>
                                </Stack>,
                                productCreationHeaderEl
                            )
                        }

                        <General editing={!!product} />

                        <Prices editing={!!product} />

                        <ServiceHider className={"space-y-[40px]"}>
                            <Weights />
                        </ServiceHider>

                        <Inventory editing={!!product} />

                        <ServiceHider className={"space-y-[40px]"}>
                            <Options />
                        </ServiceHider>

                        <VariantsHider className={"space-x-[40px]"}>
                            <Variants editing={!!product} />
                        </VariantsHider>

                        <BaseCustomFieldsSection.Mutation
                            mode={product ? "editing" : "creation"}
                            module={"product"}
                        />

                        <Description />

                        <div className="flex items-center justify-center space-x-[24px]">
                            {
                                product ?
                                    <>
                                        <BaseButton type="button" size="md"
                                                    text={t(`createEditProduct.buttons.cancel`)}
                                                    primaryOutlined buttonWidth="200px" className="font-semibold"
                                                    onClick={() => history.go(-1)} />
                                        <BaseButton size="md" text={t(`createEditProduct.buttons.save`)}
                                                    buttonWidth="200px" className="font-semibold" />
                                    </>
                                    :
                                    (() => {
                                        switch (templatesMode) {
                                            case "empty":
                                                return <>
                                                    {/* Save (create) template button */}
                                                    <BaseButton type="button" size="md"
                                                                onClick={() => setIsTemplateCreationModalOpen(true)}
                                                                text={t("createEditProduct.modals.templates.buttons.create")}
                                                                primaryOutlined buttonWidth="440px"
                                                                className="font-semibold"
                                                                disabled={!disabledFields.length} />

                                                    {/* Create product button */}
                                                    <BaseButton size="md"
                                                                text={t(`createEditProduct.buttons.create`)}
                                                                buttonWidth="440px" className="font-semibold" />
                                                </>;
                                            case "selected":
                                                return <>
                                                    {/* Manage template button */}
                                                    <BaseButton type="button" size="md"
                                                                text={t("createEditProduct.modals.templates.buttons.manage")}
                                                                onClick={() => setTemplatesMode("management")}
                                                                primaryOutlined buttonWidth="440px"
                                                                className="font-semibold" />

                                                    {/* Create product button */}
                                                    <BaseButton size="md"
                                                                text={t(`createEditProduct.buttons.create`)}
                                                                buttonWidth="440px" className="font-semibold" />
                                                </>;
                                            case "management":
                                                return <>
                                                    {/* Delete template button */}
                                                    <BaseButton type="button" size="md"
                                                                onClick={() => selectedTemplates.length && setActiveTemplateDeletion(selectedTemplates[0])}
                                                                text={t("createEditProduct.modals.templates.buttons.delete")}
                                                                primaryOutlined buttonWidth="440px"
                                                                className="font-semibold" />

                                                    {/* Save template changes button */}
                                                    <BaseButton type="button" size="md"
                                                                text={t("createEditProduct.modals.templates.buttons.save")}
                                                                buttonWidth="440px" className="font-semibold"
                                                                onClick={() => {
                                                                    if (selectedTemplates.length) {
                                                                        const template = selectedTemplates[0];
                                                                        if (!arraysHaveSameValues(template.disabled_fields, disabledFields)) {
                                                                            manualRequest({
                                                                                method: "PUT",
                                                                                route: `template/${template.id}`,
                                                                                body: {
                                                                                    disabled_fields: disabledFields
                                                                                },
                                                                                then: () => {
                                                                                    const newTemplateState = {
                                                                                        ...template,
                                                                                        disabled_fields: disabledFields
                                                                                    };

                                                                                    setTemplatesOptions(prevState => [
                                                                                        ...prevState.filter(temp => temp.id !== template.id),
                                                                                        newTemplateState
                                                                                    ]);

                                                                                    setSelectedTemplates([
                                                                                        newTemplateState
                                                                                    ]);

                                                                                    toast.success(t("createEditProduct.modals.templates.savingProcess.responses.success"));
                                                                                },
                                                                                catch: () => {
                                                                                    setDisabledFields(template.disabled_fields);
                                                                                    toast.error(t("createEditProduct.modals.templates.savingProcess.responses.error"));
                                                                                },
                                                                                finally: () => {
                                                                                    setTemplatesMode("selected");
                                                                                },
                                                                                onLoadingChange: setIsTemplateSavingLoadingModalOpen
                                                                            });
                                                                        } else {
                                                                            setTemplatesMode("selected");
                                                                        }
                                                                    }
                                                                }} />
                                                </>;
                                        }
                                    })()
                            }
                        </div>
                    </form>
                </FormProvider>

                <TemplateCreationModal
                    isOpen={isTemplateCreationModalOpen}
                    onClose={() => setIsTemplateCreationModalOpen(false)}
                    onCreation={newTemplate => {
                        setTemplatesOptions(prevState => [newTemplate, ...prevState]);
                        setSelectedTemplates([newTemplate]);
                        // createOrUpdateProductState(newTemplate)
                    }}
                    disabled_fields={disabledFields}
                />

                <TemplateDeletionModal
                    isOpen={!!activeTemplateDeletion}
                    onClose={() => setActiveTemplateDeletion(undefined)}
                    template={activeTemplateDeletion}
                    onDeletion={deletedTemplate => {
                        setTemplatesOptions(prevState => prevState.filter(temp => temp.id !== deletedTemplate.id));
                        setSelectedTemplates([]);
                    }}
                />

                <BaseLoadingModal
                    isLoading={isTemplateSavingLoadingModalOpen}
                />

            </ProductContext.Provider>
        </BaseLoadingBlocker>
    );
}