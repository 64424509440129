import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// custom reusable components
import BaseButton from '../../../../../reusables/BaseButton';
import BaseDropdown from '../../../../../reusables/BaseDropdown';
import BaseInput from '../../../../../reusables/BaseInputOld';
import {Legend} from "recharts";
import {Switch} from "@mui/material";


function SalesOrderLayoutPage() {




    return (
        <div className="container-lg">
            <div>
                <p className="text-xl-semibold color-1 text-center">Sales Order  (PDF customizer)</p>
            </div>
            <div className="custom-card container">
                <div className="d-flex flex-wrap"
                     style={{columnGap: "24px", rowGap: "40px"}}
                >
                    <BaseDropdown
                        label="Terms"
                        selectName="create-new-supplier-terms"
                        options={[
                            { value: "Terms 1", label: "Terms 1" },
                            { value: "Terms 2", label: "Terms 2" }
                        ]}
                    />
                    <BaseDropdown
                        label="Sales Order"
                        selectName="create-new-sales-order"
                        options={[
                            { value: "Terms 1", label: "Terms 1" },
                            { value: "Terms 2", label: "Terms 2" }
                        ]}
                    />
                    <BaseInput label="Co.Reg No" />
                    <BaseInput label="Brand" />
                    <BaseInput label="Category" />
                    <BaseInput label="Bill to" />
                    <BaseInput label="Ship to" />
                    <BaseInput label="Date" />
                    <BaseInput label="Estimate delivery date" />
                    <BaseInput label="Customer purchase order No" />
                    <BaseInput label="Credit Terms" />
                    <BaseInput label="Shipment Terms" />
                    <BaseInput label="Shipment Method" />
                    <BaseInput label="Sales rep." />
                    <BaseInput label="Item" />
                    <BaseInput label="Tax registration number" />
                    <BaseInput label="Price" />
                    <BaseInput label="Unit" />
                    <BaseInput label="Qty" />
                    <BaseInput label="Disc" />
                    <BaseInput label="Tax" />
                    <BaseInput label="Amount" />
                    <BaseInput label="Total quantity" />
                    <BaseInput label="Sub Total" />
                    <BaseInput label="Included Tax" />
                    <BaseInput label="Plus Tax" />
                    <BaseInput label="Total" />
                    <BaseInput label="Additional change" />
                    <BaseInput label="Discount on total price" />
                    <BaseInput label="page" />
                    <BaseInput label="Phone" />
                    <BaseInput label="Fax" />
                    {/*<Switch aria-label="Email" defaultChecked />*/}
                    <BaseInput label="Email" />

                    <div className="d-flex flex-wrap">
                    <BaseButton text="Preview" size="sm" primaryOutlined/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SalesOrderLayoutPage;
