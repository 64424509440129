import { AppThunk } from "@redux/hooks";
import {purchasesApi} from "@redux/features/purchases/purchasesApi";
import { addReceiveOrder } from "@redux/features/purchases/purchasesSlice";
import { History } from "history";

/**
 * Thunk to handle the quick receive of a purchase order.
 * @param purchaseOrderId
 * @param history
 */
export const handleQuickReceive = (purchaseOrderId: number, history: History): AppThunk => async (dispatch) => {
    if (!purchaseOrderId) {
        console.error("No purchase order id provided");
        return;
    }

    try {
        const purchaseOrder = await dispatch(purchasesApi.endpoints.getPurchaseOrder.initiate(purchaseOrderId)).unwrap();
        dispatch(addReceiveOrder(purchaseOrder));
        console.log("Purchase order fetched:", purchaseOrder);
        history.push(`/dashboard/receive/new`);
    } catch (error) {
        console.error("Error fetching the purchase order:", error);
    }
};