import React, { useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import BaseStepper from "@reusables/BaseStepper";
import BaseUnderconstruct from "@reusables/BaseUnderconstruct";
import { StepperItem } from "@reusables/BaseStepper/types";

interface CustomerStepper {
    tabs: {
        title: (t: TFunction<"", "customers.general.tabs">) => string;
        content: JSX.Element | undefined;
    }[],
    manual: {
        activeTab: number;
        onClick?: (tabIndex: number) => void;
    };
}

export default function CustomerStepper(props: CustomerStepper){
    const {t} = useTranslation("", {keyPrefix: "customers.general.tabs"});

    return (
      <div className="space-y-[40px]">
          <BaseStepper
            items={props.tabs.map(tab => ({
                title: tab.title?.(t)
            }))}
            manual={props.manual}
          />

          {
              props.tabs.map((tab, index) => <div style={{ display: props.manual?.activeTab === index ? "block" : "none" }} key={index}>
                  {
                      tab.content ?? <BaseUnderconstruct/>
                  }
              </div>)
          }
      </div>
    )
}