import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExportProgressStatus, ExportState } from "./types";

const initialState = {
    value: {
        status: ExportProgressStatus.IDLE
    } as ExportState
};

export const exportSlice = createSlice({
    name: "export",
    initialState: initialState,
    reducers: {
        startExport: (state, action: PayloadAction<{ module: string }>) => {
            state.value = {
                status: ExportProgressStatus.ACTIVE,
                module: action.payload.module
            };
        },

        // TODO: add here progress update, when implemented on backend

        finishExport: (state) => {
            state.value = {
                status: ExportProgressStatus.FINISHED,
                module: state.value.module
            };
        },

        resetExport: (state) => {
            state.value = {
                status: ExportProgressStatus.IDLE
            };
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    startExport,
    finishExport,
    resetExport
} = exportSlice.actions;

export default exportSlice.reducer;