import axios from 'axios';
import React, { useEffect, useState } from 'react'

const API_URL = import.meta.env.VITE_API_URL;

function EmailVerificationPage() {

    const [url, setUrl] = useState<string|null>("");

    const token = localStorage.getItem("sanctum");
    
    useEffect(() => {
        const currentUrl = window.location.search;
        const params = new URLSearchParams(currentUrl);
        const queryUrl = params.get('url');

        setUrl(queryUrl);
    }, [])

    useEffect(() => {
        if(url) {
            verifyEmail();
        }
    }, [url])

    const verifyEmail = () => {
        console.log("URL", url);
        axios.get(url!, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            // redirect back to home page
            window.location.href = "/";
        })
    }

    return (
        <main className='main' style={{ height: '100vh' }}>
            {/* <p>{url}</p> */}
            <p className='text-center'>Loading</p>
        </main>
    )
}

export default EmailVerificationPage;