import React, { useEffect, useState } from 'react'

import BrandLogo from "../../../assets/images/logo.svg";
import LoginPic from "../../../assets/images/login.png";

import './index.scss';
import axios from 'axios';
import { useTranslation } from "react-i18next";

const API_URL = import.meta.env.VITE_API_URL;

function NewPasswordPage() {

  const {t} = useTranslation();

  // password change token
  const [token, setToken] = useState<string|null>("");
  const [email, setEmail] = useState<string|null>("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");

  // is password saved bool to determine page content
  const [operationFinished, setOperationFinished] = useState(false);

  useEffect(() => {
    const url = window.location.search;
    const params = new URLSearchParams(url);
    const queryToken = params.get('token');
    const queryEmail = params.get('email');

    setToken(queryToken);
    setEmail(queryEmail);
  }, [])

  const submitNewPasswordForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("submitted");

    // send axios request to reset password.
    // Request will include: token, email??, password, password-confirmation
    axios.post(`${API_URL}/api/reset-password`, {
      token,
      email,
      password,
      password_confirmation
    })
    .then((res) => {
      console.log(res);

      setOperationFinished(true);
    })
    .catch((err) => {
      console.log(err);

      setOperationFinished(false);
    })
  }

  const newPasswordContent = (
    <div className="login-main">
      <div className="container d-flex flex-column align-items-center">
        <div>
          <div className="rp-heading">
            <h1 className="rp-heading__title">{t("forgotPassword.resetPage.stage1.heading")}</h1>
            <p className="rp-heading__info pretitle">{t("forgotPassword.resetPage.stage1.subheading")}</p>
          </div>

          <div style={{ marginTop: '96px' }}>
            <form onSubmit={submitNewPasswordForm} className="login-form">
              <div className="input-wrapper">
                <label>
                  {t("forgotPassword.resetPage.stage1.inputs.original.title")}
                </label>

                <input
                  style={{ marginBottom: '8px' }}
                  className="input"
                  type="password"
                  name="email"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <small className="input-message">{t("forgotPassword.resetPage.stage1.inputs.original.restriction")}</small>

                <br />

                <label style={{ marginTop: '32px' }}>
                  {t("forgotPassword.resetPage.stage1.inputs.confirmation.title")}
                </label>

                <input
                  className="input"
                  type="password"
                  name="email"
                  value={password_confirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-filled">
                {t("forgotPassword.resetPage.stage1.action")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

  const passwordSavedContent = (
    <div className="">
      <div style={{ maxWidth: '30rem' }} className="container d-flex flex-column align-items-center text-center">
        <div>
          <div className="rp-heading">
            <h1 className="rp-heading__title">{t("forgotPassword.resetPage.stage2.heading")}</h1>
            <p className="rp-heading__info">
              {t("forgotPassword.resetPage.stage2.subheading.0")}
              <br />
              {t("forgotPassword.resetPage.stage2.subheading.1")}
            </p>
          </div>

          <div style={{ marginTop: '96px' }}>
            <a href="/login" className="btn btn-filled w-100">
              {t("forgotPassword.resetPage.stage2.action")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <main className="login" style={{ height: '100vh' }}>
      <div className="section">
        <div className="container-lg" style={{ width: '1500px' }}>
          <div className="login-top">
            <a href="/">
              <img src={BrandLogo} alt="Suppli Brand Logo" />
            </a>
          </div>
          {
            operationFinished ? passwordSavedContent : newPasswordContent
          }
        </div>
      </div>
    </main>
  )
}

export default NewPasswordPage;