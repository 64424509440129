import React, {useEffect, useRef, useState} from "react";
import NavigationBar from "../NavigationBar";
import FooterBlock from "../FooterBlock";

import "./index.scss";

// images
import WaveImg from "@assets/images/waveIcon.svg";
import IcGoToArrow from "@assets/images/goToArrow.svg";
import {createPagination, fetchExternalArray} from "@helpers/utils";
import {News} from "@/types/general";
import BasePagination from "@reusables/BasePagination";
import i18nInstance from "@/i18n";
import {useTranslation} from "react-i18next";

const NewsPage: React.FC = () => {
    const blogRef = useRef<HTMLDivElement>(null);
    const pagination = createPagination();
    const filters = ["All news", "Suppli WMS News", "Warehouse Management Systems (WMS)", "Warehouse Management", "Inventory Management", "Warehouse Trends", "WMS Selection and Implementation     ", "WMS Integrations"]
    const [selectedFilter, selectFilter] = useState<number>(0);
    const [showNews, setShowNews] = useState<any[]>([]);
    // Use the provided utility method to fetch blog data
    const {t} = useTranslation();

    const { data: news, loading: newsLoading, call: fetchBlogData } = fetchExternalArray<News.Root>(
        undefined,
        resp => {
            return resp.data.data
        },
        resp => {
            if (resp.data.meta) {
                pagination.calculate(resp.data.meta.pagination.total)
            }
        }
    );

    const latestNews = news.length > 0 ? [news[0]] : []
    useEffect(() => {
        // TODO: improve fetching, currently it is pretty bad
        const locale = i18nInstance.language === "no" ? "nb" : "en"

        fetchBlogData(`https://admin.suppli.cloud/api/posts?pagination[page]=${pagination.page.currentPage}&pagination[pageSize]=${pagination.perPage.elementsPerPage}&sort=createdAt%3Adesc&populate=*&locale=${locale}`);
    }, [pagination.page.currentPage, t]);

    const scrollToBlogTop = () => {
        if (window.pageYOffset > 50 && blogRef && blogRef.current) {
            blogRef.current.scrollIntoView();
        }
    };

    useEffect(() => {
        scrollToBlogTop();

    }, [news]);

    useEffect(() => {
        const tempNews: any = []
        if (news.length === 0) return
        news.map((item: any) => {
            if (selectedFilter === 0 || filters[selectedFilter] === item.attributes?.category) tempNews.push(item)
        })

        setShowNews(tempNews)
    }, [news, selectedFilter])

    return (
        <div>
            <NavigationBar />
            <main className="main">
                <div className="container-lg">
                    <div className="tablet:flex flex-col items-center mb-16">
                        {/* <div className="max-w-[462px] mb-12">
                          <img src={ImBuilding} className="w-full" />
                      </div> */}
                        <div className="space-y-8 text-center mt-[96px] mb-[64px]">
                            <img src={WaveImg} />
                            <h2 className="text-title">The Blog</h2>
                        </div>
                        <div>
                            {news && news.length > 0 && (
                                <div className="space-y-8 min-w-[1300px]">
                                    {latestNews.map(item => {
                                        return (
                                            <a
                                                key={item.id}
                                                href={`/news/${item.attributes.slug}`}
                                                className="block tablet:flex tablet:items-center rounded-lg overflow-hidden shadow-lg cursor-pointer max-h-[381px]"
                                            >
                                                <div className="tablet:max-w-[650px] tablet:mr-[112px]">
                                                    {
                                                        item.attributes.mainImg.data ?
                                                            <img
                                                                className="w-full min-h-[192px] tablet:min-h-[381px]"
                                                                src={`https://admin.suppli.cloud${item.attributes.mainImg.data.attributes.url}`}
                                                            />
                                                            :
                                                            <div className="w-full min-h-[192px] tablet:min-h-[381px]"></div>
                                                    }

                                                </div>
                                                <div className="max-tablet:p-4 tablet:py-10 max-w-[467px]">
                                                    <div className="mb-10">
                                                        <div className="text-[#8850F4] text-base leading-[2] mb-2">
                                                            {item.attributes.smallHeader}
                                                        </div>
                                                        <div className="text-[#2B2A35] text-[24px] leading-[1.15] mb-6 hover:text-[#B1B2E1]">
                                                            {item.attributes.header}
                                                        </div>
                                                        <div className="text-description">
                                                            {item.attributes.text}
                                                        </div>
                                                    </div>

                                                    <div className="flex items-end justify-between">
                                                        <div className="flex">
                                                            <div className="w-[64px] h-[65px] rounded-full overflow-hidden mr-4">
                                                                <img
                                                                    className="w-full"
                                                                    src={`https://admin.suppli.cloud${item.attributes.authorImg.data?.attributes.url}`}
                                                                />
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className="text-[#2B2A35] text-lg leading-[1.4] font-semibold mb-2">
                                                                    {item.attributes.author?.fullName}
                                                                </div>
                                                                <div className="text-description">
                                                                    {item.attributes.author?.date}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <img src={IcGoToArrow} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="space-y-8 text-center mt-[200px] mb-[96px]">
                        <h2 className="text-title mb-[48px]">Latest news</h2>
                        {/* <div className="text-description">
                              Our latest blogs about development, technology and trends
                          </div> */}
                        <div className="flex-auto mr-auto ml-auto max-w-[1060px]">
                            {filters.map((filter, i) => <button
                                className=
                                {
                                    "text-center border-none font-[400] text-[16px] leading-[24px] mx-[20px] my-[20px]"
                                    + (selectedFilter === i ? " text-white bg-[#7556FA] rounded-lg py-[8px] px-[16px]" : " bg-transparent text-[#686868]")
                                }
                                onClick={() => selectFilter(i)}
                            >
                                {filter}</button>)}
                        </div>
                    </div>
                    <div>
                        {news && news.length > 0 && (
                            <div className="space-y-8">
                                {showNews.map(item => (
                                    <a
                                        key={item.id}
                                        href={`/news/${item.attributes.slug}`}
                                        className="block tablet:flex tablet:items-center rounded-lg overflow-hidden shadow-lg cursor-pointer max-h-[381px]"
                                    >
                                        <div className="tablet:max-w-[650px] tablet:mr-[112px]">
                                            {
                                                item.attributes.mainImg.data ?
                                                    <img
                                                        className="w-full min-h-[192px] tablet:min-h-[381px]"
                                                        src={`https://admin.suppli.cloud${item.attributes.mainImg.data.attributes.url}`}
                                                    />
                                                    :
                                                    <div className="w-full min-h-[192px] tablet:min-h-[381px]"></div>
                                            }

                                        </div>
                                        <div className="max-tablet:p-4 tablet:py-10 max-w-[467px]">
                                            <div className="mb-10">
                                                <div className="text-[#8850F4] text-base leading-[2] mb-2">
                                                    {item.attributes.smallHeader}
                                                </div>
                                                <div className="text-[#2B2A35] text-[24px] leading-[1.15] mb-6 hover:text-[#B1B2E1]">
                                                    {item.attributes.header}
                                                </div>
                                                <div className="text-description">
                                                    {item.attributes.text}
                                                </div>
                                            </div>

                                            <div className="flex items-end justify-between">
                                                <div className="flex">
                                                    <div className="w-[64px] h-[65px] rounded-full overflow-hidden mr-4">
                                                        <img
                                                            className="w-full"
                                                            src={`https://admin.suppli.cloud${item.attributes.authorImg.data?.attributes.url}`}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div
                                                            className="text-[#2B2A35] text-lg leading-[1.4] font-semibold mb-2">
                                                            {item.attributes.author?.fullName}
                                                        </div>
                                                        <div className="text-description">
                                                            {item.attributes.author?.date}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <img src={IcGoToArrow} />
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        )}
                    </div>

                    {showNews.length >= 8 || pagination.page.currentPage > 1 ? (pagination && <BasePagination
                        className="mt-[32px]"
                        config={pagination}
                        disabled={newsLoading}
                    />) : ""}
                </div>
            </main>
            <FooterBlock />
        </div>
    );
};

export default NewsPage;
