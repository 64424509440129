import React from "react";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { useHistory, useParams } from "react-router-dom";
import MutationLayout from "../components/MutationLayout";
import { useGetBOMQuery, useUpdateBOMMutation } from "@redux/features/bom/bomApi";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function BOMEditingPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "bom.editing" });

    const { id } = useParams<{ id: string }>();

    const { data: bom, isLoading: isBomLoading } = useGetBOMQuery(parseInt(id));

    const [updateBOM, { isLoading: isBOMLoading }] = useUpdateBOMMutation();

    return (
        <BaseRecordRenderer
            record={bom}
            loading={isBomLoading}
        >
            <BaseLoadingBlocker active={isBOMLoading}>
                <div className="flex mb-[40px]">
                    <h6 className="text-accent grow">{`${t("heading")} ${bom?.name}`}</h6>
                </div>
                <MutationLayout
                    bom={bom}
                    buttonText={t("buttons.save")}
                    onSubmit={(data) => {
                        const mutationData = {
                            components: data.components.map(component => ({
                                product_id: component.id,
                                quantity: component.quantity
                            })),

                            // Ommiting name if it is the same as the previous one
                            ...(bom?.name?.localeCompare(data.name) && { name: data.name })
                        };

                        updateBOM({
                            id: bom?.id as number,
                            ...mutationData
                        }).unwrap()
                            .then(updatedBOM => {
                                toast.success(t("responses.success"));
                                history.push(`/dashboard/bill-of-materials/${updatedBOM.id}/details`);
                            })
                            .catch((error) => {
                                if (isErrorWithMessage(error)) {
                                    toast.error(error.message);
                                } else {
                                    toast.error(t("responses.error"));
                                }
                                console.error("BOM update error:", error)
                            });
                    }}
                />
            </BaseLoadingBlocker>
        </BaseRecordRenderer>
    );
}
