import React from "react";
import _ from "lodash";
import BaseTooltip from "@/components/reusables/BaseTooltip";

interface AuthorProps {
    first_name: string;
    last_name: string;
    email: string;
}

export default function Author(props: AuthorProps) {
    const gluedName = [props.first_name, props.last_name].filter(x => !_.isEmpty(x)).join(" ");
    return (
        <BaseTooltip
            title={
                <div>
                    <div>{gluedName}</div>
                    <i>{props.email}</i>
                </div>
            }
            placement={"top"}
        >
            <span>
                {
                    props.first_name
                }
            </span>
        </BaseTooltip>
    );
}