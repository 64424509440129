import React, { useCallback, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

export default function BaseRelativeTime(props: { to: Dayjs, refreshInterval?: number }) {

    const [relativeTime, setRelativeTime] = useState<string>();

    const getRelativeTime = useCallback((to: Dayjs) => {
        return dayjs().to(to);
    }, []);

    useEffect(() => {
        setRelativeTime(getRelativeTime(props.to));

        const timer = setInterval(() => setRelativeTime(getRelativeTime(props.to)), props.refreshInterval ?? 60000);

        return () => clearInterval(timer);
    }, [props]);

    return <>
        {
            relativeTime
        }
    </>;

}