import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SearchSVG } from "@assets/icons/ic_search.svg";
import { ReactComponent as IcFilter } from "@assets/images/icons/dashboard/filter.svg";
import { Button, CircularProgress, Menu } from "@mui/material";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import _ from "lodash";

type FilteringHeaderProps = {
    onSearchTyping: (value: string) => void;
    onSearchToggle: (state: boolean) => void;
    /**
     * @default false
     */
    isSearchLoading?: boolean;
    /**
     * Needed for typing loader (UX improvement)
     * @default 1000
     */
    typingResetCooldown?: number;
    filters: JSX.Element;
}

export default function FilteringHeader(props: FilteringHeaderProps) {
    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct.tabs.numbers.header" });

    // Everything related to filters
    const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);
    const toggleShowFilter = () => setIsFiltersOpen((prev) => !prev);
    const filterBtnRef = useRef(null);

    // Everything related to search
    const [isSearchVisible, setIsSearchVisible] = React.useState(false);

    // Search input ref to focus on it
    const searchInputRef = useRef<HTMLInputElement>(null);

    // Focus on search input when it's shown
    useEffect(() => {
        if (isSearchVisible && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [isSearchVisible]);

    const [isTyping, setIsTyping] = React.useState(false);
    const debounceTypingReset = useCallback(
        _.debounce(() => setIsTyping(false), props.typingResetCooldown ?? 1000),
        [setIsTyping, props.typingResetCooldown]
    );

    return (
        <>
            <div className="relative w-full transition-all duration-300 ease-in-out"
                 style={{ opacity: isSearchVisible ? 1 : 0 }}>
                <input
                    type="text"
                    placeholder={t("search.placeholder")}
                    onChange={(e) => {
                        setIsTyping(true);
                        debounceTypingReset();

                        props.onSearchTyping(e.target.value);
                    }}
                    onBlur={() => {
                        setIsSearchVisible(false);
                        props.onSearchToggle(false);
                    }}
                    className="w-full h-[40px] px-10 py-[7px] rounded-lg border-2 border-solid border-gray-300 focus:border-purple-400/60 focus:outline-none transition-all duration-300 ease-in"
                    ref={searchInputRef}
                />
                {
                    isTyping || props.isSearchLoading ?
                        <div className={"absolute top-50 left-[18px] translate-middle-y mt-[2px]"}>
                            <CircularProgress size={16} />
                        </div>
                        :
                        <div className="absolute top-50 left-[18px] translate-middle-y">
                            <SearchSVG className="mt-[-2px]" />
                        </div>
                }
            </div>
            {!isSearchVisible && (
                <div className="flex items-center gap-[16px]">
                    <Button
                        ref={filterBtnRef}
                        variant="contained"
                        size="medium"
                        onClick={toggleShowFilter}
                        className="max-w-[90px] h-[40px]"
                        sx={{
                            backgroundColor: "#F3F2EF !important",
                            color: "#686868",
                            "&:active": {
                                backgroundColor: "#e0dfdc !important"
                            }
                        }}
                    >
                        <div className="flex justify-center items-center space-x-2 px-[32px]">
                            <span>{t("filters.heading")}</span>
                            <IcFilter />
                        </div>
                    </Button>
                    <Menu
                        anchorEl={filterBtnRef?.current}
                        id="filterMenu"
                        open={isFiltersOpen}
                        onClose={toggleShowFilter}
                        elevation={0}
                        className="!shadow-sm-1 mt-2 p-6"
                        sx={{
                            ".MuiPaper-root": {
                                p: "24px",
                                boxShadow: "-6px 5px 33px rgba(59, 65, 208, 0.11)",
                                width: "300px"
                            }
                        }}
                    >
                        {props.filters}
                    </Menu>

                    <BaseMaterialIconButton
                        icon={<SearchSVG />}
                        onClick={() => {
                            setIsSearchVisible(true);
                            props.onSearchToggle(true);
                        }}
                        className="w-[40px] h-[40px]"
                    />
                </div>
            )}
        </>
    );
}