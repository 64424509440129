import React from "react";
import { useTranslation } from "react-i18next";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseCheckbox from "@reusables/BaseCheckbox";
import BaseTooltip from "@reusables/BaseTooltip";

import { ReactComponent as QuestionSVG } from "@assets/icons/ic_question.svg";
import { ProductType } from "../../types";
import { Controller, useFormContext } from "react-hook-form";

export default function Options() {
    const {t} = useTranslation("", {keyPrefix: "products.general"});

    const {control} = useFormContext<ProductType>();



    return (
        <BaseInputsGrid title={t("categories.options")} cols={{
            xs: 1,
            sm: 4,
            xl: 4
        }}>
            {/*/!* RFID *!/*/}
            {/*<div className="flex items-center">*/}
            {/*    <Controller*/}
            {/*        name="options."*/}
            {/*        control={control}*/}
            {/*        render={({field}) => (*/}
            {/*            <BaseCheckbox*/}
            {/*                {...field}*/}
            {/*                label={<span className="ml-[16px]">{t("fields.rfid.title")}</span>}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*    />*/}
            {/*    <BaseTooltip title={`${t("fields.rfid.tip")}`} placement="top">*/}
            {/*        <QuestionSVG className="text-specialIcons-question"/>*/}
            {/*    </BaseTooltip>*/}
            {/*</div>*/}

            {/* BATCH NUMBER */}
                <div className="flex items-center">
                    <Controller
                        name="options.is_batch_number"
                        control={control}
                        render={({field}) => (
                            <BaseCheckbox
                                {...field}
                                label={<span className="ml-[16px]">{t("fields.batchNumber.title")}</span>}
                            />
                        )}
                    />
                    <BaseTooltip title={`${t("fields.batchNumber.tip")}`} placement="top">
                        <QuestionSVG className="text-specialIcons-question"/>
                    </BaseTooltip>
                </div>
            
                {/* SERIAL NUMBER */}
                <div className="flex items-center">
                    <Controller
                        name="options.is_serial_number"
                        control={control}
                        render={({field}) => (
                            <BaseCheckbox
                                {...field}
                                label={<span className="ml-[16px]">{t("fields.serialNumber.title")}</span>}
                            />
                        )}
                    />
                    <BaseTooltip title={`${t("fields.serialNumber.tip")}`} placement="top">
                        <QuestionSVG className="text-specialIcons-question"/>
                    </BaseTooltip>
                </div>

                {/* COMPONENTS */}
                <div className="flex items-center">
                    <Controller
                        name="options.is_component"
                        control={control}
                        render={({field}) => (
                            <BaseCheckbox
                                {...field}
                                label={<span className="ml-[16px]">{t("fields.components.title")}</span>}
                            />
                        )}
                    />
                    <BaseTooltip title={`${t("fields.components.tip")}`} placement="top">
                        <QuestionSVG className="text-specialIcons-question"/>
                    </BaseTooltip>
                </div>
        </BaseInputsGrid>
    )
}