import {useGetCompaniesQuery} from "@redux/features/companies/companiesApi";
import React, { useMemo } from "react";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import {useAppDispatch} from "@redux/hooks";
import {Company} from "@/types/general";
import {useTranslation} from "react-i18next";

interface CompaniesDropdownProps {
    value?: Company.Root;
    onChange: (newActiveCompany?: Company.Root) => void;
}

export default function CompaniesDropdown({value, onChange}: CompaniesDropdownProps) {
    // Loading translation utilities
    const {t} = useTranslation("", {keyPrefix: "settings.general.companyInformation.tabs.companyDetails"});

    // Loading companies options
    const {data: companiesOptions, isLoading: companiesOptionsLoading} = useGetCompaniesQuery();

    const selectedCompany = useMemo(() => {
        if(value) {
            return companiesOptions?.find(opt => opt.id === value.id);
        }
    }, [value, companiesOptions])

    // General middleware looking for options change
    if (companiesOptions && companiesOptions.length) {
        // Setting dropdown element according to companies options state:
        // No companies => nothing
        // One company (default) => Create new company
        // Many companies (and active selected) => dropdown with active or dropdown with nothing
        if (companiesOptions.length === 1 && selectedCompany) {
            return (
                <BaseMaterialButton
                    fontWeight={500}
                    onClick={() => onChange(undefined)}>
                    {t("actions.createNewCompanyButton")}
                </BaseMaterialButton>
            )

        } else {
            return <BaseDropdown
                placeholder={t("actions.selectCompaniesDropdown")}
                options={companiesOptions}
                getter={{
                    label: opt => opt.name,
                    key: opt => opt.id
                }}

                value={selectedCompany}

                onChange={onChange}
                isLoading={companiesOptionsLoading}
                autocomplete

                customize={{
                    padding: "6px 8px"
                }}


                {
                    ...(selectedCompany && {
                        action: {
                            title: t("actions.createNewCompanyButtonDropdown"),
                            onClick: () => onChange(undefined)
                        }
                    })
                }

                disableClearable
            />
        }
    } else {
        return null;
    }
}