import React, { useMemo } from "react";
import { Permission } from "@/types/general";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import AbilityRenderer
    from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Roles/components/MutationLayout/components/abilities/AbilityRenderer";
import {
    doesContainOptions
} from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Roles/definitions";
import BooleanAbility
    from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Roles/components/MutationLayout/components/abilities/BooleanAbility";
import BaseDropdown from "@/components/reusables/dropdowns/BaseDropdown";

interface PermissionsRendererProps {
    permissions: Permission.Root[keyof Permission.Root];
}

export default function PermissionsRenderer({ permissions }: PermissionsRendererProps) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.roles.mutation" });

    // const viewPermissions = useMemo(() => {
    //     return _.values(permissions.permissions.view?.options);
    // }, [permissions.permissions.view?.options]);

    return (
        <div className={"space-y-[32px]"}>
            <div className={"space-y-[24px]"}>
                {/* <div className={"max-w-[300px]"}>
                   <BaseDropdown
                       options={viewPermissions}
                       getter={{
                           label: opt => opt.label,
                           key: opt => opt.key
                       }}

                       customize={{
                           padding: "4px"
                       }}

                       emptyValue={t("accessDenied")}
                   />
                </div> */}
                <div className={"flex flex-row space-x-2"}>
                    {
                        _.values(permissions.permissions).map((ability, index) => (
                            <BooleanAbility
                                key={index}
                                ability={ability}
                                path={permissions.key + ".permissions." + ability.key}
                            />
                        ))
                    }
                </div>
            </div>
            <div className={"space-y-[24px]"}>
                {_.values(permissions.subPermissions).length > 0 && _.values(permissions.permissions).length > 0 && <div className={"text-accent"}>Sub permissions</div>}
                {
                    _.values(permissions.subPermissions)
                        .sort((a, b) => {
                            const aO = doesContainOptions(a) ? 1 : 0;
                            const bO = doesContainOptions(b) ? 1 : 0;
                            return aO - bO;
                        })
                        .map((subPermission, index) => {
                                const path = permissions.key + ".subPermissions." + subPermission.key;
                                return <AbilityRenderer
                                    ability={subPermission}
                                    path={path}
                                    key={index}
                                />;
                            }
                        )
                }
            </div>
        </div>
    );
}