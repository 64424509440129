import React from "react";
import { cn } from "@helpers/utils";
import { Controller, useController, useFormContext, useWatch } from "react-hook-form";
import { LowStockRefillSchemeTyping } from "@components/Dashboard/pages/Sales/Orders/components/modals/LowStockRefillModal/types";
import BaseInput from "@reusables/BaseInput";
import { Collapse, Stack } from "@mui/material";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BOMSelector from "@components/Dashboard/pages/Sales/Orders/components/modals/LowStockRefillModal/parts/BOMSelector";
import { useTranslation } from "react-i18next";
import BaseMaterialCheckbox from "@reusables/BaseMaterialCheckbox";

interface RefillLineProps {
    index: number;
}

export default function RefillLine({ index }: RefillLineProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.mutations.creation.lowStockOffer.modals.refill" });
    const { control, setValue } = useFormContext<LowStockRefillSchemeTyping>();

    const productTypesOptions = [
        {
            id: 1,
            name: t("selector.refillTypeOptions.product")
        },
        {
            id: 2,
            name: t("selector.refillTypeOptions.bom")
        }
    ];

    const line = useWatch({
        control,
        name: `lines.${index}`
    });

    const shouldBeRefilledController = useController({
        name: `lines.${index}.should_be_refilled`,
        control: control
    });

    return (
        <div
            className={cn(
                `grow border-[1px] border-solid rounded-lg bg-white transition-all overflow-hidden relative`,
                `border-inputs-border-default`,
                "hover:border-inputs-border-focused",
                !line?.should_be_refilled && "opacity-50 cursor-pointer"
            )}
            onClick={() => {
                if (!line?.should_be_refilled) shouldBeRefilledController.field.onChange(true);
            }}
        >
            <div className={cn(`flex flex-row items-center p-3 space-x-3`)}>
                <div>
                    <BaseMaterialCheckbox checked={shouldBeRefilledController.field.value} onChange={shouldBeRefilledController.field.onChange} checkedClassName={"bg-purple-500"} />
                </div>
                <div>{line?.product.name}</div>
                <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"end"} className={"grow"}>
                    <div>{t("selector.keywords.0")}</div>
                    <div className={"w-[100px]"}>
                        <Controller
                            name={`lines.${index}.quantity`}
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    type="number"
                                    placeholder={"0"}
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            padding: "8px"
                                        }
                                    }}
                                    hideErrors
                                />
                            )}
                        />
                    </div>
                    {line?.product.has_bom && (
                        <>
                            <div>{t("selector.keywords.1")}</div>
                            <div className={"w-[150px]"}>
                                <Controller
                                    name={`lines.${index}.should_use_bom`}
                                    control={control}
                                    render={({ field, fieldState }) => {
                                        const value = field.value ? productTypesOptions[1] : productTypesOptions[0];
                                        return (
                                            <BaseDropdown
                                                options={productTypesOptions}
                                                value={value}
                                                getter={{
                                                    key: (opt) => opt.id,
                                                    label: (opt) => opt.name
                                                }}
                                                onChange={(opt) => {
                                                    field.onChange(opt?.id === 2);
                                                }}
                                                disableClearable
                                                customize={{
                                                    padding: "4px"
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </>
                    )}
                </Stack>
            </div>
            <Collapse in={line?.should_use_bom}>
                <BOMSelector active={line?.should_use_bom ?? false} index={index} />
            </Collapse>
        </div>
    );
}
