import React, { useContext, useMemo } from "react";
import { Product } from "@/types/general";
import { useTranslation } from "react-i18next";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseCheckbox from "@reusables/BaseCheckboxLegacy";
import BaseTooltip from "@reusables/BaseTooltip";

import { ReactComponent as QuestionSVG } from "@assets/icons/ic_question.svg";
import { Typography } from "@mui/material";
import { normalizePrice } from "@helpers/utils";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "@redux/hooks";
import BaseCustomFieldsSection from "@reusables/BaseCustomFieldsSection";
import { Can } from "@/casl.config";

type CELProperties = {
    product: Product.Extended | undefined;
};

const DisabledFieldsSpreadContext = React.createContext<{
    disabledFields: Product.Components.DisableableTemplateField[];
}>({ disabledFields: [] });

const imageDomainURLPrefix = import.meta.env.VITE_API_URL;

export default function ViewLayout({ product }: CELProperties) {
    const { t } = useTranslation("", { keyPrefix: "products.general" });

    const history = useHistory();

    const disabledFields = useMemo(() => product?.template?.disabled_fields ?? [], [product]);

    const baseCurrency = useAppSelector((state) => state.auth.user?.company.currency);

    return (
        <div className="space-y-[40px]">
            <DisabledFieldsSpreadContext.Provider value={{ disabledFields: disabledFields }}>
                <BaseInputsGrid title={t("categories.general")}>
                    {/*  PRODUCT NAME */}
                    <InputLikeContainer label={t("fields.productName")} content={product?.name} />

                    {/*  PRODUCT CODE */}
                    <InputLikeContainer label={t("fields.productCode")} content={product?.code} />

                    {/*  BARCODE */}
                    {!product?.is_service && <InputLikeContainer label={t("fields.barcode")} content={product?.barcode} fieldKey={"barcode"} />}

                    {/* UNIT */}
                    <InputLikeContainer label={t("fields.unit.label")} content={product?.unit?.name} fieldKey={"unit"} />

                    {/*  CATEGORY */}
                    <InputLikeContainer label={t("fields.category.label")} content={product?.category?.name} fieldKey={"category"} />

                    {/*  PRIMARY SUPPLIER */}
                    <InputLikeContainer label={t("fields.primarySupplier.label")} content={product?.supplier?.name} fieldKey={"supplier"} />

                    {/*  TAX */}
                    <InputLikeContainer label={t("fields.defaultTax.label")} content={product?.tax?.rate ? product.tax.rate + " %" : "-"} fieldKey={"tax"} />
                </BaseInputsGrid>

                <Can I={"price.view"} a="product">
                    <div className="space-y-[10px]">
                        <div className="space-y-[40px]">
                            <BaseInputsGrid title={t("categories.prices")}>
                                {/*  PURCHASE PRICE */}
                                <InputLikeContainer
                                    label={t("fields.purchasePrice")}
                                    content={
                                        <>
                                            <span className="mr-1">{baseCurrency?.symbol}</span>
                                            <span>{normalizePrice(product?.prices.purchase_price)}</span>
                                            {product?.prices?.source_currency && product?.prices.exchange_rate && (
                                                <span className="text-[#B1B2E1] ml-[4px]">
                                                    ({product.prices.source_currency.symbol} {normalizePrice(product.prices.exchange_rate * product.prices.purchase_price)})
                                                </span>
                                            )}
                                        </>
                                    }
                                />

                                {/*  SELLING PRICE */}
                                <InputLikeContainer
                                    label={t("fields.sellingPrice")}
                                    content={
                                        <>
                                            <span className="mr-1">{baseCurrency?.symbol}</span>
                                            <span>{normalizePrice(product?.prices.selling_price)}</span>
                                        </>
                                    }
                                />

                                {/* EXTRA COST   */}
                                <InputLikeContainer
                                    label={t("fields.extraCost")}
                                    content={
                                        <>
                                            <span className="mr-1">{baseCurrency?.symbol}</span>
                                            <span>{normalizePrice(product?.prices.extra_cost) ?? "-"}</span>
                                        </>
                                    }
                                />
                            </BaseInputsGrid>
                        </div>
                        <Typography variant="caption" display="block" gutterBottom sx={{ opacity: 0.5 }}>
                            {t("fields.dynamicPricePrompt")}
                        </Typography>
                    </div>
                </Can>

                {!product?.is_service && (
                    <BaseInputsGrid title={t("categories.weights")}>
                        <InputLikeContainer label={t("fields.weight") + " (kg)"} content={product?.weights_and_sizes.weight} fieldKey={"weight"} />

                        {/*  CBM */}
                        <InputLikeContainer label={t("fields.cbm")} content={product?.weights_and_sizes.CBM} fieldKey={"CBM"} />

                        {/*  WIDTH */}
                        <InputLikeContainer label={t("fields.width") + " (mm)"} content={product?.weights_and_sizes.width} fieldKey={"width"} />

                        {/*  HEIGHT */}
                        <InputLikeContainer label={t("fields.height") + " (mm)"} content={product?.weights_and_sizes.height} fieldKey={"height"} />

                        {/*  LENGTH */}
                        <InputLikeContainer label={t("fields.length") + " (mm)"} content={product?.weights_and_sizes.length} fieldKey={"length"} />
                    </BaseInputsGrid>
                )}

                <BaseInputsGrid
                    title={t("categories.inventory")}
                    cols={{
                        xl: 4,
                        md: 3,
                        xs: 2
                    }}
                >
                    {/*  PURCHASE QUANTITY */}
                    <InputLikeContainer label={t("fields.purchaseQty")} content={product?.min_purchase_quantity} fieldKey={"purchaseQty"} />

                    {/*  SALE QUANTITY */}
                    <InputLikeContainer label={t("fields.saleQty")} content={product?.min_sale_quantity} fieldKey={"saleQty"} />
                </BaseInputsGrid>

                <BaseInputsGrid
                    title={t("categories.options")}
                    cols={{
                        xs: 1,
                        sm: 4,
                        xl: 4
                    }}
                >
                    {" "}
                    {!product?.is_service && (
                        <>
                            {/* RFID */}
                            {/*<div className="flex items-center">*/}
                            {/*    <BaseCheckbox checked={product?.is_RFID}*/}
                            {/*                  label={<span className="ml-[16px]">{t("fields.rfid.title")}</span>}*/}
                            {/*                  disabled*/}
                            {/*    />*/}
                            {/*    <BaseTooltip title={`${t("fields.rfid.tip")}`} placement="top">*/}
                            {/*        <QuestionSVG className="text-specialIcons-question" />*/}
                            {/*    </BaseTooltip>*/}
                            {/*</div>*/}

                            {/* BATCH NUMBER */}
                            <div className="flex items-center">
                                <BaseCheckbox checked={product?.is_batch_number} label={<span className="ml-[16px]">{t("fields.batchNumber.title")}</span>} disabled />
                                <BaseTooltip title={`${t("fields.batchNumber.tip")}`} placement="top">
                                    <QuestionSVG className="text-specialIcons-question" />
                                </BaseTooltip>
                            </div>

                            {/* SERIAL NUMBER */}
                            <div className="flex items-center">
                                <BaseCheckbox checked={product?.is_serial_number} label={<span className="ml-[16px]">{t("fields.serialNumber.title")}</span>} disabled />
                                <BaseTooltip title={`${t("fields.serialNumber.tip")}`} placement="top">
                                    <QuestionSVG className="text-specialIcons-question" />
                                </BaseTooltip>
                            </div>

                            {/* COMPONENTS */}
                            <div className="flex items-center">
                                <BaseCheckbox checked={product?.is_component} label={<span className="ml-[16px]">{t("fields.components.title")}</span>} disabled />
                                <BaseTooltip title={`${t("fields.components.tip")}`} placement="top">
                                    <QuestionSVG className="text-specialIcons-question" />
                                </BaseTooltip>
                            </div>
                        </>
                    )}
                </BaseInputsGrid>

                {!product?.parent && (
                    <BaseInputsGrid title={t("categories.variants")} cols={1}>
                        <div>
                            {product?.variants?.map((variant, index) => (
                                <div key={index} className="flex justify-between mt-2">
                                    <div
                                        className="text-accent underline hover:opacity-50 cursor-pointer"
                                        onClick={() => {
                                            history.push(`/dashboard/products/${variant.id}/details`);
                                        }}
                                    >
                                        {variant.name ?? "-"}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </BaseInputsGrid>
                )}

                <BaseCustomFieldsSection.Previewer source={product}/>

                <div className="text-xl text-accent">{t("categories.attachments")}</div>
                <div className="flex flex-row space-x-[40px]">
                    {product?.image_path && (
                        <div className="flex flex-column justify-center w-[120px] relative">
                            <div 
                                className="h-[120px] w-full rounded-[6px]" 
                                style={{ 
                                    backgroundImage: `url("${imageDomainURLPrefix}${product.image_path}")`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                }}></div>
                        </div>
                    )}
                    <InputLikeContainer label={t("fields.attachmentDescription")} content={product?.description ?? "-"} />
                </div>
            </DisabledFieldsSpreadContext.Provider>
        </div>
    );
}

function InputLikeContainer({ label, content, fieldKey }: { label: string; content?: JSX.Element | string | number | null; fieldKey?: Product.Components.DisableableTemplateField }) {
    const disabledFieldsContext = useContext(DisabledFieldsSpreadContext);

    if (fieldKey && disabledFieldsContext.disabledFields.includes(fieldKey)) return null;

    return (
        <div>
            <div className="font-semibold text-inputs-label-dim mb-[24px]">{label}</div>
            <div className="text-accent">{content ?? "-"}</div>
        </div>
    );
}
