import { RootState } from "@redux/hooks";
import { Role } from "@/types/general";
import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { createSelector } from "@reduxjs/toolkit";

export const rolesApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRoles: builder.query<Role.Root[], void>({
            query: () => ({
                url: `roles`
            }),
            transformResponse,
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: "Roles" as const, id })), "Roles"]
                    : ["Roles"]
        }),

        getRole: builder.query<Role.Extended, number>({
            query: (id) => ({
                url: `roles/${id}`
            }),
            transformResponse,
            providesTags: (result, error, id) => [{ type: "Roles", id }]
        }),

        createRole: builder.mutation<Role.Extended, Role.DTO.Create>({
            query: (body) => ({
                url: `roles`,
                method: "POST",
                data: body
            }),
            transformResponse,
            invalidatesTags: ["Roles"],

            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: createdResult } = await queryFulfilled;
                    dispatch(rolesApi.util.updateQueryData("getRoles", undefined, (draft) => {
                        draft.push({
                            id: createdResult.id,
                            name: createdResult.name,
                            description: createdResult.description,
                            users: createdResult.users,
                            immutable: createdResult.immutable,
                        });
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        updateRole: builder.mutation<Role.Extended, Role.DTO.Update & { id: number }>({
            query: ({ id, ...body }) => ({
                url: `roles/${id}`,
                method: "PUT",
                data: body
            }),
            transformResponse,

            invalidatesTags: (_, __, args) => [{ type: "Roles", id: args.id }],

            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedResult } = await queryFulfilled;
                    dispatch(rolesApi.util.updateQueryData("getRoles", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === updatedResult.id);
                        if (index !== -1) {
                            draft[index] = {
                                id: updatedResult.id,
                                name: updatedResult.name,
                                description: updatedResult.description,
                                users: updatedResult.users,
                                immutable: updatedResult.immutable,
                            };
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        deleteRole: builder.mutation<void, { id: number } & Role.DTO.Delete>({
            query: ({ id, ...data }) => ({
                url: `roles/${id}`,
                method: "DELETE",
                data: data
            }),

            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(rolesApi.util.updateQueryData("getRoles", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === args.id);
                        if (index !== -1) {
                            draft.splice(index, 1);
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        assignRole: builder.mutation<{ users: Role.Root["users"] }, { role_id: number; users: number[] }>({
            query: ({ role_id, users }) => ({
                url: `roles/${role_id}/assign`,
                method: "POST",
                data: { users }
            }),
            invalidatesTags: (_, __, arg) => ["Roles", ...arg.users.map(id => ({ type: "User", id }))]
        }),

        copyRole: builder.mutation<Role.Root, { role_id: number } & Role.DTO.Copy>({
            query: ({ role_id, ...data }) => ({
                url: `roles/${role_id}/copy`,
                method: "POST",
                data
            }),
            invalidatesTags: ["Roles"]
        })
    })
});

const selectRoles = rolesApi.endpoints.getRoles.select();

const selectAllRoles = createSelector(
    selectRoles,
    (roles) => roles.data ?? []
);

export const selectRoleById = createSelector(
    selectAllRoles,
    (state: RootState, r_id: number | undefined) => r_id,
    (roles, r_id) => r_id !== undefined ? roles.find(r => r.id === r_id) : undefined
);

export const {
    useGetRolesQuery,
    useGetRoleQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
    useAssignRoleMutation,
    useCopyRoleMutation
} = rolesApi;