import React from 'react'
import {useTranslation} from 'react-i18next';
import {Controller, useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {SupplierFormTyping} from './TabsController';
import {useGetCountriesQuery} from '@redux/api/internalApiSlice';
import BaseCheckbox from '@reusables/BaseCheckbox';
import BaseInputsGrid from '@reusables/BaseInputsGrid';
import BaseInput from '@reusables/BaseInput';
import BaseDropdown from '@reusables/dropdowns/BaseDropdown';
import {getCountryFlag} from '@helpers/utils';
import BasePhoneInput from '@reusables/BasePhoneInput';
import BaseButton from '@reusables/BaseButton';
import {TabProperties} from '../../types';
import FormErrorWarning from '../modals/FormErrorWarning';
import ReturnAddress from "@components/Dashboard/pages/Suppliers/components/tabs/parts/ReturnAddress";
import { useAbility } from '@/casl.config';

export default function AddressTab({onNext, onPrev}: TabProperties & {onPrev: () => void}) {
    const {t} = useTranslation("", {keyPrefix: "suppliers.general.tabs.address"});

    const ability = useAbility();

    const {control, setValue, getValues} = useFormContext<SupplierFormTyping>();

    const {data: countryOptions = [], isLoading: countryOptionsLoading} = useGetCountriesQuery();

    const {fields: returns, append, remove} = useFieldArray({
        control,
        name: "returns",
        keyName: "loop_id"
    });

    const selectedBillingCountry = useWatch({
        control,
        name: "billing_address.country"
    });

    const mode = useWatch({
        control,
        name: "mode"
    });

    // useEffect(() => {
    //     if (getValues("returns").length === 0) {
    //         append({});
    //     }
    // }, [returns]);

    // --> Use primary as primary <-- //
    const handlePrimaryChange = (changedIndex: number) => {
        // Update the form values for is_primary
        const updatedReturns = getValues("returns").map((delivery, index) => ({
            ...delivery,
            is_primary: index === changedIndex
        }));
        setValue("returns", updatedReturns);
        setValue("billing_address.is_used_for_return", false);
    };

    // --> Use billing address as primary <-- //
    const handleUseAsPrimary = () => {
        // Update the form values for is_primary
        const updatedReturns = getValues("returns").map((delivery) => ({
            ...delivery,
            is_primary: false
        }));
        setValue("returns", updatedReturns);
        setValue("billing_address.is_used_for_return", true);
    };


    return (
        <form className="space-y-[40px]" onSubmit={onNext}>
            <FormErrorWarning />

            <div className='text-xl text-accent'>{t("categories.billing")}</div>
            <div>
                <Controller
                    control={control}
                    name="billing_address.is_used_for_return"
                    render={({field}) => (
                        <BaseCheckbox
                            {...field}

                            label={t("checkboxes.useForReturn")}
                            onChange={() => {
                                field.onChange(!field.value); // Toggle the value
                                if (!field.value) {
                                    handleUseAsPrimary();
                                }
                            }}

                            disabled={mode === "editing" && ability.cannot("billing_address.edit", "supplier")}
                        />
                    )}
                />
            </div>
            <BaseInputsGrid>
                <Controller
                    control={control}
                    name="billing_address.name"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.name.label")}
                            disabled={mode === "editing" && ability.cannot("billing_address.edit", "supplier")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="billing_address.street"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.street.label")}
                            disabled={mode === "editing" && ability.cannot("billing_address.edit", "supplier")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="billing_address.street_2"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.street2.label")}
                            disabled={mode === "editing" && ability.cannot("billing_address.edit", "supplier")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="billing_address.zipcode"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.zipcode.label")}
                            disabled={mode === "editing" && ability.cannot("billing_address.edit", "supplier")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="billing_address.city"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.city.label")}
                            disabled={mode === "editing" && ability.cannot("billing_address.edit", "supplier")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="billing_address.country"
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}

                            options={countryOptions}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name,
                                renderOption: (opt, icon) => (
                                    <div>
                                        <img src={getCountryFlag(opt.code)} width={20} alt={opt.name + " flag"}/>
                                        <span className="ml-[8px] grow">{opt.name}</span>
                                        {icon}
                                    </div>
                                )
                            }}
                            label={t("fields.country.label")}
                            isLoading={countryOptionsLoading}
                            autocomplete

                            disabled={mode === "editing" && ability.cannot("billing_address.edit", "supplier")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="billing_address.phone"
                    render={({field, fieldState}) => (
                        <BasePhoneInput
                            {...field}
                            {...fieldState}
                            label={t('fields.phone.label')}
                            size='md'
                            variant='custom'
                            country={getValues("mode") !== "editing" ? selectedBillingCountry?.code : undefined}
                            disabled={mode === "editing" && ability.cannot("billing_address.edit", "supplier")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="billing_address.email"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.email.label")}
                            type={"email"}
                            disabled={mode === "editing" && ability.cannot("billing_address.edit", "supplier")}
                        />
                    )}
                />
            </BaseInputsGrid>

            {
                returns.map((address, index) =>
                    <ReturnAddress
                        key={address.loop_id}
                        index={index}
                        mode={mode}
                        control={control}
                        onAppend={() => append({})}
                        onRemove={() => {
                            if (address.id != undefined) {
                                setValue("deleted_returns", [...getValues("deleted_returns"), address.id])
                            }

                            remove(index);
                        }}
                        onPrimaryChange={() => handlePrimaryChange(index)}
                        
                    />
                )
            }

            <div className="flex justify-center space-x-[24px]">
                <BaseButton
                    text={t("buttons.prev")}
                    size="md"
                    buttonWidth="200px"
                    primaryOutlined
                    type={"button"}

                    onClick={onPrev}
                />
                <BaseButton
                    text={t("buttons.next")}
                    size="md"
                    buttonWidth="200px"
                />
            </div>
        </form>
    );
}
