import React from "react";
import { useTranslation } from "react-i18next";
import { useDeletePackageMutation } from "@redux/features/packaging/packagingApi";

import BaseButton from "@reusables/BaseButton";
import BaseModal from "@reusables/Modals/BaseModal";

import { Packaging } from "@/types/general";
import { toast } from "react-toastify";


interface DeletionModalProperties {
    isOpen: boolean;
    onClose: () => void;
    package?: Packaging.Root;
}

export default function DeletionModal(props: DeletionModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.packaging.modals.deletion" });

    const [deletePackage, { isLoading: isDeletionLoading }] = useDeletePackageMutation();

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
            isLoading={isDeletionLoading}
        >
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">{t("subheading")}.</div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose} />
                    <BaseButton text={t("buttons.delete")} size="md" onClick={() => {
                        if (props.package) {
                            deletePackage(props.package.id).unwrap()
                                .then(() => {
                                    toast.success(t("responses.success"));
                                }).catch((err) => {
                                toast.error(t("responses.error"));

                                console.error(err);
                            }).finally(() => props.onClose());
                        }
                    }} />
                </div>
            </div>
        </BaseModal>
    );
}
