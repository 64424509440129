import { useState, useEffect } from 'react';
import { DatepickerRange } from '@reusables/BaseDatepicker/types';


// This hook is used to manage the dates range filter in the batch numbers and serial numbers tabs
// It returns the dates range, the expired dates range, and the formatted dates range to be used in the filters
export function useDatesRangeFilter() {
    const [datesRange, setDatesRange] = useState<DatepickerRange>({
        from: null,
        to: null
    });

    const [expiredDatesRange, setExpiredDatesRange] = useState<DatepickerRange>({
        from: null,
        to: null
    });

    const [filterDatesRangeFormatted, setFilterDatesRangeFormatted] = useState<{ from: string; to: string }>();
    const [filterExpiredDatesRangeFormatted, setFilterExpiredDatesRangeFormatted] = useState<{ from: string; to: string }>();

    useEffect(() => {
        if (datesRange.to && datesRange.from) {
            setFilterDatesRangeFormatted({
                from: datesRange.from.format("YYYY-MM-DD"),
                to: datesRange.to.format("YYYY-MM-DD")
            });
        } else {
            setFilterDatesRangeFormatted(undefined);
        }
    }, [datesRange]);

    useEffect(() => {
        if (expiredDatesRange.to && expiredDatesRange.from) {
            setFilterExpiredDatesRangeFormatted({
                from: expiredDatesRange.from.format("YYYY-MM-DD"),
                to: expiredDatesRange.to.format("YYYY-MM-DD")
            });
        } else {
            setFilterExpiredDatesRangeFormatted(undefined);
        }
    }, [expiredDatesRange]);

    return {
        datesRange,
        setDatesRange,
        filterAdaptedDatesRange: filterDatesRangeFormatted,
        expiredDatesRange,
        setExpiredDatesRange,
        filterAdaptedExpiredDatesRange: filterExpiredDatesRangeFormatted
    };
}
