import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import IcCrown from "@assets/images/crown.svg";
import IcCheck from "@assets/images/checkIcon.svg";
import BaseButton from "@reusables/BaseButton";
import Slider from "../common/Slider";
import BaseModal from "@reusables/Modals/BaseModal";
import { Input, Select } from "@mui/material";
import BaseCheckbox from "@reusables/BaseCheckboxLegacy";

import "./pricing.css"

type Currency = "nok" | "usd" | "eur";
type CurrencySymbol = "Kr" | "$" | "€";

const PRICE_CONFIG_MAP = {
  starting: { nok: 15000, eur: 1450, usd: 1415 },
  licences: {
    premium: { nok: 990, eur: 99, usd: 99 },
    warehouse: { nok: 490, eur: 49, usd: 49 },
    accounting: { nok: 290, eur: 29, usd: 29 },
    business_package_1: { nok: 4990, eur: 499, usd: 499 },
    business_package_2: { nok: 9990, eur: 999, usd: 999 },
  },
  support: {
    gold: { nok: 2999, eur: 290, usd: 285 },
    silver: { nok: 1499, eur: 145, usd: 142 },
    bronze: { nok: 999, eur: 99, usd: 96 },
  },
};
const CURRENCY_SYMBOL_MAP: Record<Currency, CurrencySymbol> = {
  nok: "Kr",
  usd: "$",
  eur: "€",
};





export const Pricing = (props: any) => {
  const { t } = useTranslation();
  const [currencyList] = useState(["nok", "usd", "eur"]);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>("nok");
  const [selectedCard, setSelectedCard] = useState<any>(2);
  const [isOpen, openModal] = useState<true | false>();
  const priceList = useMemo(
    () => [
      {
        title: "Start Package",
        list: [
          t("pricingPage.pricingItems.1.features.1"),
          t("pricingPage.pricingItems.1.features.2"),
          t("pricingPage.pricingItems.1.features.3"),
          t("pricingPage.pricingItems.1.features.4"),
          t("pricingPage.pricingItems.1.features.5"),
        ],
        price: PRICE_CONFIG_MAP.starting[selectedCurrency],
        interval: ", -",
        notes: t("pricingPage.pricingItems.1.footnote.1"),
      },
      {
        title: "License",
        items: [
          {
            title: t("pricingPage.pricingItems.2.premium.title"),
            list: [t("pricingPage.pricingItems.2.premium.features.1")],
            price: PRICE_CONFIG_MAP.licences.premium[selectedCurrency],
            interval: t("pricingPage.pricingItems.2.premium.interval"),
          },
          {
            title: t("pricingPage.pricingItems.2.warehouse.title"),
            list: [t("pricingPage.pricingItems.2.warehouse.features.1")],
            price: PRICE_CONFIG_MAP.licences.warehouse[selectedCurrency],
            interval: t("pricingPage.pricingItems.2.warehouse.interval"),
          },
          {
            title: t("pricingPage.pricingItems.2.accounting.title"),
            list: [t("pricingPage.pricingItems.2.accounting.features.1")],
            price: PRICE_CONFIG_MAP.licences.accounting[selectedCurrency],
            interval: t("pricingPage.pricingItems.2.accounting.interval"),
          },
          {
            title: t("pricingPage.pricingItems.2.businessP1.title"),
            list: [t("pricingPage.pricingItems.2.businessP1.features.1")],
            price:
              PRICE_CONFIG_MAP.licences.business_package_1[selectedCurrency],
            interval: t("pricingPage.pricingItems.2.businessP1.interval"),
          },
          {
            title: t("pricingPage.pricingItems.2.businessP2.title"),
            list: [t("pricingPage.pricingItems.2.businessP2.features.1")],
            price:
              PRICE_CONFIG_MAP.licences.business_package_2[selectedCurrency],
            interval: t("pricingPage.pricingItems.2.businessP2.interval"),
          },
        ],
      },
      {
        title: t("pricingPage.pricingItems.3.title"),
        items: [
          {
            title: t("pricingPage.pricingItems.3.gold.title"),
            list: [
              t("pricingPage.pricingItems.3.gold.features.1"),
              t("pricingPage.pricingItems.3.gold.features.2"),
              t("pricingPage.pricingItems.3.gold.features.3"),
              t("pricingPage.pricingItems.3.gold.features.4"),
              t("pricingPage.pricingItems.3.gold.features.5"),
            ],
            price: PRICE_CONFIG_MAP.support.gold[selectedCurrency],
            interval: t("pricingPage.pricingItems.3.gold.interval"),
          },
          {
            title: t("pricingPage.pricingItems.3.silver.title"),
            list: [
              t("pricingPage.pricingItems.3.silver.features.1"),
              t("pricingPage.pricingItems.3.silver.features.2"),
            ],
            price: PRICE_CONFIG_MAP.support.silver[selectedCurrency],
            interval: t("pricingPage.pricingItems.3.silver.interval"),
          },
          {
            title: t("pricingPage.pricingItems.3.bronze.title"),
            list: [
              t("pricingPage.pricingItems.3.bronze.features.1"),
              t("pricingPage.pricingItems.3.bronze.features.2"),
            ],
            price: PRICE_CONFIG_MAP.support.bronze[selectedCurrency],
            interval: t("pricingPage.pricingItems.3.bronze.interval"),
          },
        ],
        premium: true,
        footnotes: [t("pricingPage.pricingItems.3.footnote")],
      },
    ],
    [selectedCurrency, t]
  );

  const _renderPricingItem = ({ title, list, price, interval, sub }: any) => {
    return (
      <div className={`${sub && "mb-3"}`}>
        <div
          className={`${sub
            ? "text-base mb-3 text-[#8850F4]"
            : "mb-4 text-xl font-bold text-[#2B2A35]"
            }`}
        >
          {title}
        </div>
        <div className="space-y-4 mb-1">
          {list.map((checkItem: string) => (
            <div key={checkItem} className="flex space-x-4">
              <img className="h-5 w-5 tablet:w-6 tablet:h-6" src={IcCheck} />
              <span className="text-sm font-medium">{checkItem}</span>
            </div>
          ))}
        </div>
        {price && (
          <div className="flex justify-center items-center space-x-1">
            <div
              className={`my-1 text-[#2B2A35] text-xl tablet:text-[30px] font-medium text-center ${sub && "pl-4 tablet:pl-10"
                }`}
            >
              {CURRENCY_SYMBOL_MAP[selectedCurrency]} {price}
            </div>
            <span className="text-sm"> / {interval}</span>
          </div>
        )}
      </div>
    );
  };
  const [checked, setChecked] = useState<true | false>()
  return (
    <>
      {/* Currency Selection */}
      <div className="flex justify-center">

        <div className="flex w-full max-w-[344px] bg-[#F3F1F5] p-1 rounded-md">
          {currencyList.map((cur: any) => (
            <button
              key={cur}
              className={`border-0 flex-1 rounded-md py-2 ${selectedCurrency === cur
                ? "font-bold bg-gradient-to-r from-[#8850F4] to-[#6959FC] text-white"
                : "font-thin text-gray-600 bg-transparent "
                }`}
              onClick={() => setSelectedCurrency(cur)}
            >
              {cur.toUpperCase()}
            </button>
          ))}
          <BaseModal
            isOpen={isOpen}
            onClose={() => openModal(false)}
            width={920}
            padding="56px"
            useCloseIcon={true}
          >
            <h1 className="text-[#A6DD4C] text-[32px] border-solid border-1 mb-20 rounded-3xl border-[#A6DD4C] text-center p-2 w-[415px] mr-auto ml-auto">Start 30-Days Free Trial</h1>
            <form>
              <div className="grid grid-cols-2 gap-8">
                <div>
                  <label className="mb-2 font-[600] text-[16px] text-[#686868]">{t('settings.general.myAccount.tabs.profile.fields.firstName')}</label>
                  <Input className="mb-6 bg-[#F9F9F9] material-no-border" />
                  <label className="mb-2 font-[600] text-[16px] text-[#686868]">{t('settings.general.companyInformation.tabs.companyDetails.fields.companyName')}</label>
                  <Input className="mb-8 bg-[#F9F9F9] material-no-border" />
                </div>
                <div>
                <label className="mb-2 font-[600] text-[16px] text-[#686868]">{t('settings.general.myAccount.tabs.profile.fields.lastName')}</label>
                  <Input className="mb-6 bg-[#F9F9F9] material-no-border" />
                  <label className="mb-2 font-[600] text-[16px] text-[#686868]">{t('settings.general.companyInformation.tabs.companyDetails.fields.email')}</label>
                  <Input className="border-hidden border-none mb-8 bg-[#F9F9F9] material-no-border" />
                </div>
              </div>
              <BaseCheckbox className="font-[300] text-[16px] text-[#3C3769] mb-20" checked={checked} onChange={() => setChecked(checked === true ? false : true)} label={"I accept the Terms and Conditions"} />
              <BaseButton
                text="Submit"
                size="md"
                buttonWidth="300px"
                height="56px"
                className="mr-auto ml-auto"
              />
            </form>
          </BaseModal>
        </div>
      </div>

      {/* Pricing Card */}
      <div className="mt-8">
        <Slider
          wrapperClassName="overflow-auto! overflow-x-hidden swipper-pricing"
          slideClassName="h-[904px] w-full tablet:max-w-[350px]"
          options={{
            slidesPerView: 1,
            breakpoints: {
              1024: { slidesPerView: 3 },
            },
          }}
          items={priceList.map((item, i) => {
            const handleClick = () => setSelectedCard(i);

            return {
              render: () => (
                <div
                  key={item.title}
                  className={`relative h-full rounded-2xl py-4 px-3 border border-[#B9B9B9] tablet:max-w-[350px] cursor-pointer transition-shadow hover:shadow-[0_6px_80px_rgba(38,78,118,0.15)]`}
                  onClick={handleClick}
                >
                  {item.premium && (
                    <span className="absolute z-10 top-[-1em] left-12">
                      <img src={IcCrown} />
                    </span>
                  )}
                  <div className="card-body">
                    {item.items ? (
                      <>
                        <div
                          className={`mb-4 text-2xl font-bold text-[#2B2A35]`}
                        >
                          {item.title}
                        </div>
                        {item.items.map((i) =>
                          _renderPricingItem({ ...i, sub: true })
                        )}
                      </>
                    ) : (
                      _renderPricingItem({
                        title: item.title,
                        list: item.list,
                      })
                    )}
                    <div className="mt-10 flex flex-col items-center">
                      {item.price && (
                        <div className="mb-10 text-[#2B2A35] text-3xl font-medium text-center">
                          <span>
                            {CURRENCY_SYMBOL_MAP[selectedCurrency]} {item.price}
                          </span>
                          {item.interval}
                        </div>
                      )}
                      <div className="mt-auto w-full absolute mb-[24px] bottom-0">

                        {item.notes && (
                          <div className="mt-8 text-xs text-[#686868] text-center p-2 mb-8">
                            {item.notes}
                          </div>
                        )}

                        <button
                          className="btn btn-filled w-[284px] h-[58px] rounded-lg mr-auto ml-auto font-[600] text-[16px] leading-[24px]"
                          onClick={() => openModal(true)}
                        >Buy Now</button>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            };
          })}
        />
        <div className="w-full flex justify-center space-x-8"></div>
      </div>

      {/* Pricing Notes */}
      <div className="xl:px-20 xl:mx-2 mt-8 tablet:mt-16 text-sm font-normal">
        <p className="text-black font-semibold mb-4">{t("homePage.pricingBlock.note")}</p>
        <div className="text-gray">
          <p className="mb-4">{t("homePage.pricingBlock.footerTexts.1")}</p>
          <p className="mb-4">{t("homePage.pricingBlock.footerTexts.2")}</p>
          <p>{t("homePage.pricingBlock.footerTexts.3")}</p>
        </div>
      </div>
    </>
  );
};

export default Pricing;
