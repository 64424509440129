import axios from "axios";
import { BASE_API_URL } from "../../../../../config";
export const API_BASE_URL = import.meta.env.VITE_API_URL;

const createClientAPI = () => {
  const token = localStorage.getItem("sanctum");
  const api: any = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
  });
  if (token) {
    api.defaults.headers["Authorization"] = `Bearer ${token}`;
  }
  return api;
};

export const client = createClientAPI();

export const getLocationById = async (id: number) => {
  let res, error;
  const token = localStorage.getItem("sanctum");
  try {
    const { data } = await axios.get(`${BASE_API_URL}/api/location/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    res = data?.payload;
  } catch (e) {
    error = e;
  }
  return { res, error };
};

export const createLocation = async (body: any) => {
  const token = localStorage.getItem("sanctum");
  const { data } = await axios.post(`${BASE_API_URL}/api/location/`, body, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  if (body.has_sub_location) {
    const locationId = data.id;
    await Promise.all(
      body?.subLocation?.map((subLocation: any) =>
        axios.post(
          `${BASE_API_URL}/api/sub-location/`,
          { ...subLocation, location_id: locationId },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
      )
    );
  }
};

export const updateLocationById = async (id: number, body: any) => {
  const token = localStorage.getItem("sanctum");
  return axios.put(`${BASE_API_URL}/api/location/${id}`, body, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const deleteLocationById = async (id: number) => {
  let res, error;
  const token = localStorage.getItem("sanctum");
  try {
    const { data } = await axios.delete(`${BASE_API_URL}/api/location/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    res = data;
  } catch (e) {
    error = e;
  }
  return { res, error };
};
