import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

import BaseModal from "@reusables/Modals/BaseModal";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseButton from "@reusables/BaseButton";
import BasePhoneInput from "@reusables/BasePhoneInput";

import {useGetCountriesQuery} from "@redux/api/internalApiSlice";
import {getCountryFlag, removeEmpty, requiredIfAnyFilled} from "@helpers/utils";

import {Controller, useForm, useWatch} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {toast} from "react-toastify";
import _ from "lodash";
import {Customer} from "@/types/general";
import {useCreateCustomerMutation} from "@redux/features/customers/customersApi";
import {isErrorWithMessage} from "@redux/api/query";
import {Stack} from "@mui/material";
import BaseCheckbox from "@reusables/BaseCheckbox";

const formScheme = z.object({
    name: z.string().nonempty(),
    code: z.string().optional(),
    is_person: z.boolean(),
    billing: z.object({
        name: z.string().optional(),
        street: z.string().optional(),
        street_2: z.string().optional(),
        zipcode: z.string().optional(),
        city: z.string().optional(),
        country: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string()
        }).optional(),
        email: z.string().email().optional(),
        phone: z.string().optional(),
    }).superRefine((scheme, ctx) => {
        requiredIfAnyFilled(scheme, ["street_2"])
            .forEach(key => {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: `Required`,
                    path: [key]
                });
            })
    }),
});

type FormTyping = z.infer<typeof formScheme>

type CreateCustomerModalProperties = {
    isOpen: boolean;
    onClose: () => void;
    onCreate: (customer: Customer.Extended) => void;
}

export default function CreateCustomerModal({isOpen, onClose, onCreate}: CreateCustomerModalProperties): JSX.Element {
    const {t} = useTranslation("", {keyPrefix: "sales.orders.general.dropdowns.customer.creationModal"});
    // ---> Countries selection <--- //
    const {data: countriesOptions, isLoading: countriesOptionsLoading} = useGetCountriesQuery();

    const [createCustomer, {isLoading: isCreationLoading}] = useCreateCustomerMutation();

    const {control, handleSubmit, reset} = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            is_person: false,
        }
    });

    useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen]);

    const onSubmit = handleSubmit((data) => {
        createCustomer({
            name: data.name,
            code: data.code,
            is_person: data.is_person,
            ...(!_.isEmpty(removeEmpty(data.billing)) && {
                billing: {
                    name: data.billing.name ?? "",
                    street: data.billing.street as string,
                    street_2: data.billing.street_2,
                    zipcode: data.billing.zipcode as string,
                    city: data.billing.city as string,
                    country: data.billing.country?.id as number,
                    email: data.billing.email as string,
                    phone: data.billing.phone as string,
                    is_used_for_shipping: false,
                }
            })
        }).unwrap().then(cus => {
            onCreate(cus);
        }).catch(e => {
            if(isErrorWithMessage(e)){
                toast.error(e.message);
            }else{
                toast.error(t("responses.error"));
            }
        })
    });

    const country = useWatch({
        control,
        name: "billing.country"
    });

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={900}
            padding="56px"
            useCloseIcon
            isLoading={isCreationLoading}
        >
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="modal-title">{t('heading')}</div>

                <Stack
                    direction={"row"}
                    spacing={2}
                >
                    <Controller
                        control={control}
                        name="is_person"
                        render={({field}) => (
                            <BaseCheckbox
                                value={!field.value}
                                onChange={val => {
                                    if(val)
                                        field.onChange(!val);
                                }}


                                label={t("checkboxes.isCompany")}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="is_person"
                        render={({field}) => (
                            <BaseCheckbox
                                value={field.value}
                                onChange={val => {
                                    if(val)
                                        field.onChange(val);
                                }}


                                label={t("checkboxes.isPerson")}
                            />
                        )}
                    />
                </Stack>

                <BaseInputsGrid gap={{x: 24, y: 40}} cols={2}>
                    <Controller
                        name="name"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.customerName") + " *"}
                            />
                        )}
                    />

                    <Controller
                        name="code"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                {...fieldState}
                                label={t("fields.customerCode")}
                            />
                        )}
                    />
                </BaseInputsGrid>

                <BaseInputsGrid title={t("subheading")} gap={{x: 24, y: 40}} cols={2}>
                    <Controller
                        name="billing.name"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.name")}
                            />
                        )}
                    />

                    <Controller
                        name="billing.country"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseDropdown
                                {...field}
                                {...fieldState}
                                label={t("dropdowns.country.label")}
                                placeholder={t("dropdowns.country.placeholder")}
                                options={countriesOptions}
                                isLoading={countriesOptionsLoading}
                                getter={{
                                    label: (opt) => opt.name,
                                    key: (opt) => opt.id,
                                    renderOption: (opt, icon) => (
                                        <div>
                                            <img src={getCountryFlag(opt.code)} width={20} alt={opt.code}/>
                                            <span className="ml-[8px] grow">{opt.name}</span>
                                            {icon}
                                        </div>
                                    )
                                }}

                                autocomplete
                            />
                        )}
                    />

                    <Controller
                        name="billing.street"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.street")}
                            />
                        )}
                    />

                    <Controller
                        name="billing.street_2"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.street2")}
                            />
                        )}
                    />

                    <Controller
                        name="billing.zipcode"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.zipcode")}
                            />
                        )}
                    />

                    <Controller
                        name="billing.city"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.city")}
                            />
                        )}
                    />

                    <Controller
                        name="billing.email"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.email")}
                            />
                        )}
                    />

                    <Controller
                        name={'billing.phone'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BasePhoneInput
                                {...field}
                                {...fieldState}
                                label={t("fields.phone")}
                                size='md'
                                variant='custom'
                                country={field.value ? "" : country?.code}
                            />
                        )}
                    />

                </BaseInputsGrid>

                <BaseButton
                    buttonWidth="100%"
                    text={t("buttons.create")}
                    size="md"
                    type={"submit"}
                />
            </form>
        </BaseModal>
    );
}