import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const externalApiSlice = createApi({
    reducerPath: "external_api",
    baseQuery: fetchBaseQuery(),
    endpoints: (builder) => ({

    }),
});

export const {
} = externalApiSlice;