import React from 'react';
import {Supplier} from '@/types/general'
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import {useTranslation} from "react-i18next";

export default function SettingsTab({ supplier }: { supplier: Supplier.Extended }) {
    const { t } = useTranslation("", { keyPrefix: "suppliers.general.tabs.settings" });

    return (
        <div className="space-y-[40px]">
            <BaseInputsGrid>
                <InputLikeContainer 
                    label={t("dropdowns.language.label")} 
                    content={supplier.language?.name}
                />
                <InputLikeContainer
                    label={t("dropdowns.currency.label")}
                    content={supplier.currency?.name}
                />

                <InputLikeContainer
                    label={t("dropdowns.taxRate.label")}
                    content={supplier.tax_rate ? `${supplier.tax_rate.rate}%` : "-"}
                />

                <InputLikeContainer
                    label={t("dropdowns.paymentTerms.label")}
                    content={supplier.payment_terms?.name}
                />

                <InputLikeContainer
                    label={t("dropdowns.deliveryTerms.label")}
                    content={supplier.delivery_terms?.name}
                />
            </BaseInputsGrid>
      </div>
    );
}

function InputLikeContainer({ label, content }: { label: string, content: JSX.Element | string | undefined | number }) {
    return (
      <div>
          <div className="font-semibold text-inputs-label-dim mb-[8px]">{label}</div>
          <div className="text-accent">
              {content ?? "-"}
          </div>
      </div>
    );
}
