import React, { useState } from 'react'
import { z } from 'zod';
import YourProfileTab from './YourProfileTab'
import CompanyInformationTab from './CompanyInformationTab'
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { requiredIfAnyFilled } from '@helpers/utils';
import { refinements } from '@helpers/refinements';
import { useTranslation } from 'react-i18next';
import BaseStepper from '@reusables/BaseStepper';

const formScheme = z.object({
    your_profile: z.object({
        first_name: z.string().trim().min(2).refine(refinements.nonNumeric.refine, refinements.nonNumeric.message),
        last_name: z.string().trim().min(2).refine(refinements.nonNumeric.refine, refinements.nonNumeric.message),
        email: z.string().email(),
        phone: z.object({
            code: z.string(),
            number: z.string()
        }).superRefine((scheme, ctx) => {
            if (!scheme.number) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    params: {
                        i18n: "general.validation.global.required"
                    },
                    path: ["number"]
                });
            }
        }),
        password: z.string().min(8)
        .refine(value => /[a-zA-Z]/.test(value), {
            params: {
                i18n: "signupPage.yourProfile.passwordRules.1"
            }
        })
        .refine(value => /[0-9]/.test(value), {
            params: {
                i18n: "signupPage.yourProfile.passwordRules.2"
            }
        }),
        confirm_password: z.string(),
    }).superRefine((scheme, ctx) => {
        requiredIfAnyFilled(scheme, [])
            .forEach(key => {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    params: {
                        i18n: "general.validation.global.required"
                    },
                    path: [key]
                });
            })
    }).superRefine((scheme, ctx) => {
        if (scheme.password !== scheme.confirm_password) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                params: {
                    i18n: "signupPage.yourProfile.errors.passwordMismatch"
                },
                path: ["confirm_password"]
            });
        }
    }).superRefine((scheme, ctx) => {
        console.log(scheme.phone.number);
        if (!refinements.isPhoneValid.refine(scheme.phone.number) && !refinements.isPhoneNumberLengthValid.refine(scheme.phone.number)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                params: {
                    i18n: "general.validation.global.invalidPhoneNumber"
                },
                path: ["phone.number"]
            });
        }

        if (scheme.phone.number.length && !!refinements.isPhoneNumberLengthValid.refine(scheme.phone.number)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                params: {
                    i18n: "general.validation.global.invalidPhoneNumberLength"
                },
                path: ["phone.number"]
            });
        }
    }),

    company_information: z.object({
        company_name: z.string().trim().min(3),
        registration_number: z.string().trim().refine(refinements.validateCRN.refine, refinements.validateCRN.message),
        currency: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string()
        }),
        country: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string(),
        }),
        street: z.string().trim().min(2).refine(refinements.alphaNumeric.refine, refinements.alphaNumeric.message),
        street2: z.string().trim().optional().refine(refinements.alphaNumeric.refine, refinements.alphaNumeric.message),
        city: z.string().trim().min(2).refine(refinements.nonNumeric.refine, refinements.nonNumeric.message),
        zip_code: z.string().trim().refine(refinements.numericRequired.refine, refinements.numericRequired.message)
    }),
    terms_and_conditions: z.boolean().refine(value => value, {
        params: {
            i18n: "signupPage.companyInformation.errors.termsAndConditions"
        }
    }),
    marketing_info: z.boolean()
});

export type SignUpFormTyping = z.infer<typeof formScheme>;

interface TabsControllerProps {
    onSubmit: (data: SignUpFormTyping) => void;
}

export default function TabsController({onSubmit}: TabsControllerProps) {
    const {t} = useTranslation("", {keyPrefix: "signupPage"});

    const methods = useForm<SignUpFormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            marketing_info: false,
            terms_and_conditions: false
        }
    });

    const [activeTab, setActiveTab] = useState<number>(0);

    const onFormSubmit = methods.handleSubmit(onSubmit);

    const tabs = [
        {
            title: t("yourProfile.heading"),
            content: <YourProfileTab
                        onNext={(e) => {
                            e.preventDefault();
                            setActiveTab(1);
                        }}
                    />,
        },
        {
            title: t("companyInformation.heading"),
            content: <CompanyInformationTab
                        onPrev={() => setActiveTab(0)}
                        onNext={(e) => {
                            e.preventDefault();
                            onFormSubmit();
                        }}
                    />,
        },
    ];


    return (
        <>
            <FormProvider {...methods}>
                <div className="space-y-[40px]">
                    <BaseStepper
                        items={tabs.map(tab => ({ title: tab.title }))}
                        manual={{ activeTab: activeTab }}
                    />
                    {tabs.map((tab, index) => (
                        <div key={index} style={{ display: activeTab === index ? "block" : "none" }}>
                            {tab.content}
                        </div>
                    ))}
                </div>
            </FormProvider>
        </>
    )
}
