import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { Category } from "@/types/general";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@redux/hooks";

export const categoriesApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCategories: builder.query<Category.Root[], void>({
            query: () => ({
                url: `category`
            }),
            transformResponse,
            providesTags: [{type: "Category", id: "LIST"}]
        }),

        getModuleCategories: builder.query<Category.Root[], string>({
            query: (module) => ({
                url: `${module}/category`
            }),
            transformResponse,
            providesTags: (result) => [{type: "Category", id: "LIST"}]
        }),

        createCategory: builder.mutation<Category.Root, Category.DTO.CreateUpdate>({
            query: (body) => ({
                url: `category`,
                method: "POST",
                data: body
            }),

            transformResponse,

            async onQueryStarted(body, { dispatch, queryFulfilled}) {
                try {
                    const { data: createdCategory } = await queryFulfilled;

                    const createResult = dispatch(categoriesApi.util.updateQueryData("getCategories", undefined, (draft) => {
                        draft.push({
                            id: createdCategory.id,
                            name: createdCategory.name,
                            code: createdCategory.code,
                            is_service: createdCategory.is_service,
                            integrations: createdCategory.integrations
                        })
                    }));
                } catch (err) {
                    console.error(err);
                }   
            },
            invalidatesTags: (result) => [{type: "Category", id: "LIST"}]
        }),

        updateCategory: builder.mutation<Category.Root, Category.DTO.CreateUpdate & {id: number}>({
            query: ({id, ...body}) => ({
                url: `category/${id}`,
                method: "PUT",
                data: body
            }),

            async onQueryStarted(body, { dispatch, queryFulfilled}) {
                try {
                    const { data: updatedCategory } = await queryFulfilled;
                    const updateResult = dispatch(categoriesApi.util.updateQueryData("getCategories", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === updatedCategory.id);
                        if (index !== -1) {
                            draft[index] = updatedCategory;
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            },
            invalidatesTags: (result) => [{type: "Category", id: "LIST"}]
        }),

        deleteCategory: builder.mutation<void, number>({
            query: (id) => ({
                url: `category/${id}`,
                method: "DELETE"
            }),

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    const { data: deletedCategory } = await queryFulfilled;
                    const deleteResult = dispatch(categoriesApi.util.updateQueryData("getCategories", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === id);
                        if (index !== -1) {
                            draft.splice(index, 1);
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        })
    })
});

const selectCategoriesResult = categoriesApi.endpoints.getCategories.select();

const selectAllCategories = createSelector(
    selectCategoriesResult,
    (categories) => categories?.data ?? []
);

export const selectCategoryById = createSelector(
    selectAllCategories,
    (state: RootState, cat_id: number | undefined) => cat_id,
    (categories, cat_id) => cat_id != undefined ? categories.find(cat => cat.id === cat_id) : undefined
);

export const selectCategoryByCode = createSelector(
    selectAllCategories,
    (state: RootState, code: string | undefined) => code,
    (categories, code) => categories.find(cat => cat.code === code)
);

export const {
    useGetCategoriesQuery,
    useGetModuleCategoriesQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation
} = categoriesApi;