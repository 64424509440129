import { configureStore, createListenerMiddleware, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Constraint, Registrations } from "./types";

// Configuring slices
export const validationSlice = createSlice({
  name: "validationSlice",
  initialState: {
    inputs: {} as Registrations,
    errors: {} as Registrations,
    validationTrigger: 0,
    alreadyValidated: 0
  },
  reducers: {
    /**
     * Method for validating input's constraints and updating current errors state.
     * @param state
     * @param action awaits object with fieldId and value - current input data, might be string or an object, basically managing data correctness is up to you,
     * because there were no way for me to provide such support and control value type to match T generic in Constraint class check method.
     */
    analyzeInput: (state, action: PayloadAction<{fieldId: string, value: any}>) => {
      const {fieldId, value} = action.payload;

      if(fieldId in state.inputs){
        const actualErrors = [] as Constraint[];

        // Collecting active errors
        state.inputs[fieldId].forEach(potentialError => {
          if(!potentialError.validate(value)){
            actualErrors.push(potentialError as Constraint);
          }
        })

        // Setting errors or if no found, removing errors data
        if(actualErrors.length){
          state.errors[fieldId] = actualErrors;
        }else{
          delete state.errors[fieldId];
        }

        state.alreadyValidated++;
      }
    },

    cleanErrors: (state, action: PayloadAction<{fieldId: string}>) => {
      delete state.errors[action.payload.fieldId]
    },

    cleanAllErrors: (state) => {
      state.errors = {}
    },

    removeError: (state, action: PayloadAction<{fieldId: string, error: Constraint<any>}>) => {
      const { fieldId, error } = action.payload;

      if(fieldId in state.errors){
        const failedConstraints = state.errors[fieldId];
        for(let i = 0; i < failedConstraints.length; i++){
          if(failedConstraints[i].isSameAs(error)){
            state.errors[fieldId].splice(i, 1);
            return;
          }
        }
      }
    },

    /**
     * Method for registering input's constraints.
     */
    registerInput: (state, action: PayloadAction<{fieldId: string, constraints: Constraint<any>[] | undefined}>) => {
      const { fieldId, constraints } = action.payload;

      if(!constraints || !constraints.length){
        if(fieldId in state.inputs) delete state.inputs[fieldId]
        if(fieldId in state.errors) delete state.errors[fieldId]
      }else{
        state.inputs[fieldId] = constraints;
      }
    },

    deregisterInput: (state, action: PayloadAction<{fieldId: string}>) => {
      const { fieldId } = action.payload;

      if(fieldId in state.inputs) delete state.inputs[fieldId]
      if(fieldId in state.errors) delete state.errors[fieldId]
    },

    /**
     * To trigger validation on each connected input, we have to let them somehow understand that they should revalidate their state,
     * so the best approach here, is to use such simple counter and listen to its changes inside needed components.
     * @param state
     * @param action onSuccess parameter - action, when validation is successful, onFail - action, when validation caused errors
     */
    validate: (state, action: PayloadAction<{onSuccess?: () => void, onFail?: (errors: Registrations) => void} | undefined>) => {
      state.alreadyValidated = 0;
      state.validationTrigger++;
    }
  }
});

const validationMiddleware = createListenerMiddleware<ReturnType<typeof validationSlice.getInitialState>>();

validationMiddleware.startListening({
  actionCreator: validationSlice.actions.validate,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();

    if(await listenerApi.condition((action, currentState) => currentState.alreadyValidated === Object.keys(currentState.inputs).length)){
      if(Object.keys(listenerApi.getState().errors).length){
        action.payload?.onFail?.(listenerApi.getState().errors)
      }else{
        action.payload?.onSuccess?.()
      }
    }
  }
});

// THIS STORE SHOULD BE CREATED EXTERNALLY TO PROVIDE CODE SEPARATION
export function initializeValidationStore(){
  return configureStore({
    reducer: validationSlice.reducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false}).prepend(validationMiddleware.middleware)
  });
}


// Any other exports for outside functionality
export type ValidationStoreState = ReturnType<typeof validationSlice.getInitialState>;
export type ValidationStore = ReturnType<typeof initializeValidationStore>;
export const { registerInput, validate, analyzeInput, cleanErrors, cleanAllErrors, deregisterInput } = validationSlice.actions;