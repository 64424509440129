import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import BaseInputsGrid from "@/components/reusables/BaseInputsGrid";
import BaseTable from "@/components/reusables/BaseTable";
import { CustomerGroups } from "@/types/general";
import dayjs from "dayjs";


interface ViewLayoutProperties {
    customerGroup: CustomerGroups.Extended;
}

export default function ViewLayout({customerGroup}: ViewLayoutProperties) {
    const {t} = useTranslation("", {keyPrefix: "customerGroups.mutation"});


    const blocks = useMemo<Record<"info", InfoItem[]>>(() => ({
        info: [
            {
                title: t("fields.groupCode.label"),
                data: () => customerGroup?.code.toString() ?? "-"
            },
            {
                title: t("fields.groupName.label"),
                data: () => customerGroup?.name ?? "-"
            },
            {
                title: t("fields.discount.label"),
                data: () => customerGroup?.discount.toString() + " %" ?? "-"
            }
        ],
    }), [customerGroup, t]);

    return (
        <div className='space-y-[40px]'>
            <BaseInputsGrid>
            {
                blocks.info.map((item, index) => (
                    <div key={index}>
                        <div className="mb-[16px] font-semibold">{item.title}</div>
                        <div className="space-x-[24px] text-accent">{item.data()}</div>
                    </div>
                ))
            }
            </BaseInputsGrid>
            <BaseInputsGrid title={t("dropdowns.customer.title")}>
            {
                customerGroup?.customers?.map((customer, index) => (
                    <div key={index}>
                        <div className="mb-[16px] font-semibold">{t("dropdowns.customer.label")}</div>
                        <div className="space-x-[24px] text-accent">{customer.name}</div>
                    </div>
                )) ?? "-"
            }
            </BaseInputsGrid>

            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                {
                    customerGroup?.products?.length 
                    ? 
                    <>
                        <div className="flex mb-[46px] items-center space-x-[8px]">
                            <div className="text-xl font-thin text-gray-600 grow">{t("table.heading")}</div>
                        </div>
                        <BaseTable 
                            data={customerGroup?.products ?? []}
                            columns={[
                                {
                                    header: t("table.columns.0"),
                                    getter: row => row?.product?.code
                                },
                                {
                                    header: t("table.columns.1"),
                                    getter: row => row?.product?.name
                                },
                                {
                                    header: t("table.columns.2"),
                                    getter: row => row.sale_price
                                },
                                {
                                    header: t("table.columns.3"),
                                    getter: row => row.valid_from?.format("DD.MM.YYYY")
                                },
                                {
                                    header: t("table.columns.4"),
                                    getter: (row) => {
                                        if (!row.valid_to) {
                                            // No expiration date
                                            return <span className='bg-[#E5E5E5] rounded-[16px] px-2 py-1 font-thin'>{t("table.noExpiration")}</span>;
                                        }
                                    
                                        const expirationDate = dayjs(row.valid_to);
                                        const currentDate = dayjs();
                                    
                                        // Check if expiration date is in the future
                                        if (currentDate.isBefore(expirationDate)) {
                                            return expirationDate.format("DD.MM.YYYY");
                                        } else {
                                            return (
                                                <div className={"space-x-2"}>
                                                    <span>{expirationDate.format("DD.MM.YYYY")}</span>
                                                    <span className='bg-[#E5E5E5] rounded-[16px] px-2 py-1 font-thin'>
                                                        {t("table.expiredLabel")}
                                                    </span>
                                                </div>
                                            );
                                        }
                                    }
                                }
                            ]}

                            size='small'
                            headerSx={{
                                background: "#F9F9F9"
                            }}
                        />
                    </>
                    : 
                    <>
                        <div className="text-xl text-center text-gray-300 font-thin">
                            {t("table.noProduct")}
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

type InfoItem = {
    title: string;
    data: () => JSX.Element | string;
}