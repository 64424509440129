import React, { useCallback } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { ReactComponent as ImageUploadSVG } from "../../../../assets/icons/image_upload.svg";

type BoxUploadingAreaProperties = {
    prompt?: string;

    size?: {
        width: number;
        height: number;
    },
}

export default function BoxUploadingArea(props: DropzoneOptions & BoxUploadingAreaProperties){
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        ...props
    })

    return (
      <div {...getRootProps()}>
          <input {...getInputProps()}/>
          <div
            className={`flex flex-column items-center justify-center border-[1.5px] !border-dashed rounded-[6px] transition-[.15s] ${isDragActive ? "border-purple-500 text-purple-500" : "border-gray-300 text-gray-600"}`}
            style={{
                width: props.size?.width ?? 88,
                height: props.size?.height ?? 88
          }}
          >
              <ImageUploadSVG width={40} height={40} />
              <div className="mt-[5px] text-[10px]">{props.prompt ?? "Drag image here"}</div>
          </div>
      </div>
    )

}