import React, { Dispatch, PropsWithChildren, useEffect, useRef } from "react";
import { Provider } from "react-redux";
import { initializeValidationStore, ValidationStore } from "./validationStore";

type BaseValidationManagerProperties = {
  onStoreInit?: (store: ValidationStore) => void;
}
export default function BaseValidationManager({children, onStoreInit}: PropsWithChildren<BaseValidationManagerProperties>){

  const storeRef = useRef(initializeValidationStore());

  useEffect(() => {
    if(storeRef.current && onStoreInit)
      onStoreInit(storeRef.current)
  }, [storeRef])

  return (
    <Provider store={storeRef.current}>
      {children}
    </Provider>
  )
}