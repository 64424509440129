import React, { ReactElement } from "react";
import { Bar, BarChart, Cell, Tooltip, XAxis, YAxis } from "recharts";
import { BarChartItem } from "../types";

/**
 * Interface, defining arguments of local bar renderer function. Needed to allow creating wrapper around bar chart.
 * Might not be the best idea, but percentage bar chart on Dashboard page needed that thing, so here it is.
 * Fully used at {@link renderBarChart} (locally defined function).
 */
interface BarChartElementProperties{
  data: BarChartItem[],
  width?: number;
  /**
   * If maxWidth is set, width of the container is calculated dynamically depending on barSize + 10px between bars.
   */
  maxWidth?: number;
  height?: number;
  margin?: { top?: number, right?: number, bottom?: number, left?: number }
  barSize?: number;
  /**
   * The same as "ry" parameter on svg rect element.
   */
  cornerRounding?: number;
}

/**
 * Draws N different rounded bars and a legend, if specified by **showLegend** property. Colors generated pseudo-randomly, meaning you will always get the same order.
 * @param props the only needed parameter is "data" with objects, containing "name" and "value" keys (see {@link BarChartItem}).
 * Might also contain other parameters from {@link BarChartElementProperties} (defined locally) and, besides that,
 * **chartWrapper** function, which accepts current chart and returns it in user-defined wrapper or (and) **showLegend** boolean property.
 * @constructor
 */
export default function BasePercentageBarChart(
  props: BarChartElementProperties & {chartWrapper?: (chart: JSX.Element) => JSX.Element; showLegend?: boolean}
): JSX.Element {

  const barChartEl = renderBarChart(props);

  return (
    <>
      { props.chartWrapper ? props.chartWrapper(barChartEl) : barChartEl }
      { props.showLegend && renderLegend(props.data) }
    </>
  )
}

/**
 * Local helper function, to minimize repeatable code. Renders bar chart with specified properties.
 * @param data array of {@link BarChartItem} objects.
 * @param width might not be specified if bar chart lays in ResponsiveContainer.
 * @param height might not be specified if bar chart lays in ResponsiveContainer.
 * @param barSize size of each bar. Defaults to 24px.
 * @param cornerRounding "ry" parameter on svg rect element. Defaults to 12px.
 * @param margin better not be specified, but sometimes we might need to reduce margin from the left side.
 */
function renderBarChart({
    data,
    width,
    maxWidth,
    height,
    barSize = 24,
    cornerRounding = 12,
    margin
                          }: BarChartElementProperties){

  if(maxWidth){
    width = Math.min(maxWidth, data.length * (barSize+10));

  }

  return(
    <BarChart width={width} height={height} data={data} margin={margin}>
      <XAxis hide />
      <YAxis hide />
      <Tooltip content={CustomTooltip} cursor={{fill: "transparent"}}/>
      <Bar dataKey="value" barSize={barSize}
           shape={({ fill, x, y, width, height, background }) => {
             return (
               <>
                 {/*Grey background of a bar*/}
                 <rect
                   x={background.x}
                   y={background.y}
                   width={background.width}
                   height={background.height}
                   fill={"#E5E5E5"}
                   ry={cornerRounding}
                 />

                 {/*Colorful filling of a bar*/}
                 <rect
                   x={x}
                   y={y}
                   width={width}
                   height={height}
                   fill={fill}
                   ry={cornerRounding}
                 />

               </>
             )
           }
           }>
        {
          data.map((item, index) => {
            return (
              <Cell key={index} fill={generateBarColor(index)} />
            )
          })
        }
      </Bar>
    </BarChart>
  );
}

/**
 * Renders legend like list of items combined with colorful dots. As it`s not a part of recharts chart`s legend stuff,
 * uses pseudo-random {@link generateBarColor} function to repeat the same colors.
 * @param data will use "name" and "value" keys of {@link BarChartItem} objects to render items as "*name* (+*value*%)".
 */
function renderLegend(data: BarChartItem[]): ReactElement {
  const legends: JSX.Element[] = [];
  for(let i = 0; i < data.length; i += 4){
    const group: JSX.Element[] = [];

    for(let j = i; j < Math.min(data.length, i+4); j++){
      const entry = data[j];
      group.push(
        <div key={j} className="font-thin truncate">
                <span className="w-[9px] h-[9px] rounded-full inline-block align-middle mr-[8px] mt-[-2px]" style={{
                  backgroundColor: generateBarColor(j)
                }}></span>
          {entry.name}
        </div>
      )
    }

    legends.push(<div className="flex flex-column gap-[14px]" key={i}>{group.map(i => i)}</div>);
  }

  return (
    <div className="grid md:grid-cols-2 xs:grid-cols-1 gap-x-[14px] gap-y-[24px] pt-4">
      {
        legends.map(i => i)
      }
    </div>
  )
}

/**
 * Pseudo-random generator of colors for the bars. Starts with 4 predefined colors,
 * taken from original Figma design and then slightly shifts their rgb values.
 * @param index the same *index* will give the same color.
 */
function generateBarColor(index: number): string{
  const startingColors = [
    [135, 80, 244],
    [166, 221, 76],
    [241, 80, 244],
    [244, 198, 80],
    [255, 138, 0, 1],
    [58, 169, 129, 1],
    [123, 67, 158, 1],
    [54, 130, 219, 1]
  ];

  if(index >= 0 && index < startingColors.length)
    return formRGB(startingColors[index]);

  const baseColor = startingColors[index % startingColors.length];

  return formRGB([
    (baseColor[0] + ((index + 1) * 2)) % 256,
    (baseColor[1] + ((index + 1) * 3)) % 256,
    (baseColor[2] - ((index + 1) * 2)) % 256
  ]);

  function formRGB(colors: number[]){
    return `rgba(${colors[0]},${colors[1]},${colors[2]},1)`;
  }
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-chart-tooltip inline-block text-chart-text font-thin p-[8px] rounded-[8px]">
        +{payload[0].value}%
      </div>
    );
  }

  return null;
};
