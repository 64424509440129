import { BASE_API_URL } from "../config";
const API_URL = import.meta.env.VITE_API_URL;


export async function sendPartnersClick(partner_name) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "name": partner_name
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch(API_URL + "/api/visitors", requestOptions)
        .then(result => console.log(result))

    console.log("SENT REQEUST")
}

export async function sendPartnerUp(data, captchaToken) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    let success = false
    await fetch(BASE_API_URL + ("/api/partners/send-email?response=" + captchaToken), requestOptions)
        .then(response => {
            if (response.status === 200) {
                success = true
            }
            else success = false
        })

    return success
}