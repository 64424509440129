import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./assets/stylesheets/app.scss";
import App from "./App";

import "./i18n";

// Depends
import $ from "jquery";

// Modules
import Slider from "./modules/slider";
import { globalStore, persistor } from "@redux/store";
import { ability, AbilityContext } from "@/casl.config";
import { PersistGate } from "redux-persist/integration/react";
// * Uncomment import statement below to enable language setting based on IP address
// Stylesheet entrypoint (used for autochanging language dependent on IP)
// import i18n from "./i18n";
import * as Sentry from "@sentry/react";
import { BASE_API_URL } from "@/config";

const env = import.meta.env.VITE_ENV;

Sentry.init({
  enabled: env != "local",
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: env,
  tunnel:  import.meta.env.VITE_API_URL + import.meta.env.VITE_SENTRY_TUNNEL_URL,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", BASE_API_URL],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


// Are you ready?
$(function () {
  // * Commenting below code fixes pricing page navbar styles
  // * but it causes main page slider styles to disappear
  new (Slider as any)();

  // fixed header
  const header = $(".header");
  let scrollPrev = 0;

  $(window).on("scroll", function () {
    const scrolled = $(window).scrollTop() as number;
    if (scrolled > 10) {
      header.addClass("fixed");
    } else {
      header.removeClass("fixed");
    }
    scrollPrev = scrolled;
  });

  // languages

  $(".header-lang__curr").click(() => {
    $(this).closest(".header-lang").toggleClass("active");
  });

  $(document).click(function () {
    $(".header-lang").removeClass("active");
  });

  $(document).on("click", ".header-lang__curr", function (e: Event) {
    e.stopPropagation();
  });

  $(document).on("click", ".header-lang__list", function (e: Event) {
    e.stopPropagation();
  });

  // mobile menu
  // * Removal of below commented code fixed mobile navbar.
  // ? Check if above listeners can be deleted or no.

  // const touch = $(".mobile-menu__btn");

  // const toggles = document.querySelectorAll(".mobile-menu__btn");

  // for (let i = toggles.length - 1; i >= 0; i--) {
  //   const toggle = toggles[i];
  //   toggleHandler(toggle);
  // }

  //! Toggle element here does not work properly for mobile devices
  // function toggleHandler(toggle: Element) {
  //   toggle.addEventListener("click", (e: Event) => {
  //     e.preventDefault();
  //     console.log(toggle.classList);
  //     console.log(toggle.classList.contains("active"));
  //     toggle.classList.contains("active") === true
  //       ? toggle.classList.remove("active")
  //       : toggle.classList.add("active");

  //       // example print
  //       // console.log(toggle.classList);
  //   });
  // }

  // $(touch).on("click", function (e) {
  //   e.preventDefault();
  //   $("body").toggleClass("menu-opened").removeClass("login-menu__show");
  //   return false;
  // });

  // $(document).on("click", ".mobile-menu__btn", function (e) {
  //   e.stopPropagation();
  // });

  // $(document).on("click", ".mobile-menu__wrapper", function (e) {
  //   e.stopPropagation();
  // });

  // faq

  $(".faq-main__header").click(function () {
    $(this)
      .closest(".faq-main__item")
      .find(".faq-main__body")
      .slideToggle()
      .closest(".faq-main__item")
      .toggleClass("active")
      .siblings()
      .removeClass("active")
      .find(".faq-main__body")
      .slideUp();
  });

  // show/hide password

  $("#show-password").on("click", function () {
    if (!$(this).hasClass("active")) {
      $(this)
        .addClass("active")
        .next("label")
        .html("Hide password")
        .closest("form")
        .find('input[name="password"]')
        .attr("type", "text");
    } else {
      $(this)
        .removeClass("active")
        .next("label")
        .html("Show password")
        .closest("form")
        .find('input[name="password"]')
        .attr("type", "password");
    }
  });
});

// Change language based on IP address
// ! Not used as react i18n library does something similar to this

// fetch("https://ipinfo.io/json?token=c65d800a5eb0a9").then(
//   (response) => response.json()
// ).then(
//   (jsonResponse) => {
//     console.log(jsonResponse.ip, jsonResponse.country)
//     if(jsonResponse.country == 'NO') {
//       i18n.changeLanguage('no');
//     }

//     if(jsonResponse.country == 'LT') {
//       i18n.changeLanguage('lt');
//     }
//   }
// )

ReactDOM.render(
  <Provider store={globalStore}>
    <PersistGate loading={null} persistor={persistor}>
      <AbilityContext.Provider value={ability}>
          <App />
      </AbilityContext.Provider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
