import React, { PropsWithChildren } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ProductSchemeTyping } from "../../types";
import { cn } from "@/helpers/utils";

export default function VariantsHider({ children, className }: PropsWithChildren<{ className?: string }>) {
    const { control } = useFormContext<ProductSchemeTyping>();

    const parent = useWatch({
        control,
        name: "parent"
    });

    return <div className={cn(className, !!parent && "hidden")}>{children}</div>;
}
