import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseInput from "@reusables/BaseInput";
import BaseButton from "@reusables/BaseButton";
import BaseModal from "@reusables/Modals/BaseModal";
import { useTranslation } from "react-i18next";
import {
    useCreateCustomFieldMutation,
    useUpdateCustomFieldMutation
} from "@redux/features/customFields/customFieldsApi";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomFields } from "@/types/general";
import i18n from "@/i18n";
import { toastError } from "@helpers/utils";
import { toast } from "react-toastify";
import { customFieldsModulesList } from "@reusables/BaseCustomFieldsSection/logic";

interface MutationModalProps {
    isOpen: boolean;
    onClose: () => void;
    field?: CustomFields.Components.Field;
}

const formScheme = z.object({
    module: z.enum([
        ...customFieldsModulesList
    ]),
    label: z.string(),
    placeholder: z.string()
});

type FormSchemeTyping = z.infer<typeof formScheme>;

export default function MutationModal(props: MutationModalProps) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.customFields.modals.mutation" });

    const form = useForm<FormSchemeTyping>({
        resolver: zodResolver(formScheme)
    });

    // ==> RTKQ Hooks
    const [createCustomField, { isLoading: isCreationLoading }] = useCreateCustomFieldMutation();
    const [updateCustomField, { isLoading: isUpdateLoading }] = useUpdateCustomFieldMutation();

    const isLoading = isCreationLoading || isUpdateLoading;

    // ==> Submission form
    const onSubmit = form.handleSubmit((data) => {
        let request;
        if (props.field) {
            request = updateCustomField({ id: props.field.id, ...data });
        } else {
            request = createCustomField(data);
        }

        request.unwrap().then(() => {
            if (props.field) {
                toast.success(t("responses.update.success"));
            } else {
                toast.success(t("responses.creation.success"));
            }

            props.onClose?.();
        }).catch(toastError);
    });

    // ==> Module options with translations for the dropdown
    const moduleOptions = customFieldsModulesList.map((module) => ({
        value: module,
        label: i18n.t(`settings.general.customFields.modules.${module}`)
    }));

    // ==> Effect for resetting the form and setting the field values
    useEffect(() => {
        if (props.isOpen) {
            form.reset();

            if (props.field) {
                form.setValue("module", props.field.module);
                form.setValue("label", props.field.label);
                form.setValue("placeholder", props.field.placeholder);
            }
        }
    }, [props.isOpen, props.field]);

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={() => {
                props.onClose?.();
            }}
            width={562}
            padding="56px"
            useCloseIcon
            isLoading={isLoading}>
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent">
                    {t("creationHeading")}
                </div>

                <Controller
                    name={"module"}
                    control={form.control}
                    render={({ field, fieldState }) => (
                        <BaseDropdown
                            label={t("fields.module.label")}
                            placeholder={t("fields.module.placeholder")}

                            options={moduleOptions}
                            value={moduleOptions.find((option) => option.value === field.value)}

                            getter={{
                                label: (option) => option.label,
                                key: (option) => option.value
                            }}

                            onChange={(value) => value && field.onChange(value.value)}
                            error={fieldState.error}

                            autocomplete

                            disabled={!!props.field}
                        />
                    )}
                />

                <Controller
                    name={"label"}
                    control={form.control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            label={t("fields.label.label")}
                            placeholder={t("fields.label.placeholder")}
                            {...field}
                            error={fieldState.error}
                        />
                    )}
                />


                <Controller
                    name={"placeholder"}
                    control={form.control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            label={t("fields.placeholder.label")}
                            placeholder={t("fields.placeholder.placeholder")}
                            {...field}
                            error={fieldState.error}
                        />
                    )}
                />
                <div>
                    <BaseButton
                        buttonWidth="100%"
                        text={`${t("buttons.save")}`}
                        size="md"
                        loading={isLoading}
                    />
                </div>
            </form>
        </BaseModal>
    );
}