import React, { useMemo } from "react";
import { Control, Controller, useController, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Alert, Stack } from "@mui/material";
import BaseInput from "@reusables/BaseInput";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import { BatchNumbersScheme } from "./index";
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import dayjs from "dayjs";
import BaseDatepicker from "@reusables/BaseDatepicker";
import BaseInputsGrid from "@reusables/BaseInputsGrid";

export function BatchGroup({ index, control, preview }: { index: number, control: Control<BatchNumbersScheme>, preview?: boolean }) {
    const { t } = useTranslation("", { keyPrefix: "receive.modals.batchNumbers" });

    const { field: line, fieldState: { error } } = useController({
        control,
        name: `receipts.${index}`
    });

    const serialNumbersError = useMemo(() => {
        if (error && "batch_numbers" in error) {
            return (error.batch_numbers as { root?: { message: string } } & { message?: string });
        }
    }, [error]);

    const { fields: batchNumbers, append, remove } = useFieldArray({
        control,
        name: `receipts.${index}.batch_numbers` as "receipts.0.batch_numbers"
    });

    return (
        <div>
            <div className="flex items-center mb-[32px]">
                <div className="grow">
                    <div className="text-lg font-semibold text-accent">{line.value.product.name}</div>
                    <div className="mt-1 text-xs text-secondary-gray">
                        {`${line.value.location?.store.name} ${line.value.location?.section?.name ? `- ${line.value.location.section.name}` : ""}`}
                    </div>
                </div>
                {
                    !preview &&
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                        >
                            {/* Batches for the product left (limited by received quantity) */}
                            <div
                                className={`opacity-50 ${batchNumbers.length > line.value.received_quantity ? "text-[#E25C5C]" : ""}`}>
                                ({batchNumbers.length} / {line.value.received_quantity})
                            </div>
                            {/* Button to append batches to the product */}
                            <BaseMaterialButton onClick={() => {
                                append({
                                    batch_number: ""
                                });
                            }}>
                                {t("buttons.addBatchNumber")}
                            </BaseMaterialButton>
                        </Stack>
                }
            </div>

            {/* Error, if the batches number exceeds the picked quantity */}
            {
                !!serialNumbersError?.root &&
                <Alert severity="error" className="mb-[32px]">
                    {serialNumbersError.root?.message}
                </Alert>
            }

            <div className="space-y-[8px]">
                {
                    batchNumbers.map((batch, batchIndex) => (
                        <Stack
                            key={batch.id}
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                        >
                            <div className="w-full">
                                <BaseInputsGrid cols={2} gap={8}>
                                    {/* Value */}
                                    <Controller
                                        control={control}
                                        name={`receipts.${index}.batch_numbers.${batchIndex}.batch_number`}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <BaseInput
                                                    {...field}
                                                    error={fieldState.error}

                                                    placeholder={t("fields.batchNumber.placeholder")}
                                                    className="w-full"
                                                    sx={{
                                                        "&": {
                                                            "input": {
                                                                background: "#F9F9F9",
                                                                padding: "12px",
                                                                border: 0
                                                            }
                                                        }
                                                    }}
                                                    disabled={preview}
                                                />
                                            </>
                                        )}
                                    />

                                    {/* Expiration date*/}
                                    <Controller
                                        control={control}
                                        name={`receipts.${index}.batch_numbers.${batchIndex}.expiration_date`}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <BaseDatepicker
                                                    {...field}
                                                    error={fieldState.error}

                                                    placeholder={t("fields.expirationDate.placeholder")}
                                                    inputSx={{
                                                        "&": {
                                                            border: 0,
                                                            padding: 0,
                                                            "input": {
                                                                background: "#F9F9F9",
                                                                padding: "12px",
                                                                border: 0
                                                            }
                                                        }
                                                    }}

                                                    disabled={preview}
                                                />
                                            </>
                                        )}
                                    />
                                </BaseInputsGrid>
                            </div>
                            <span>
                            {
                                !preview &&
                                <DeleteSVG
                                    className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                    onClick={() => {
                                        remove(batchIndex);
                                    }}
                                />
                            }
                        </span>
                        </Stack>
                    ))
                }
            </div>
        </div>
    );

}