import { createTheme } from "@mui/system";

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}

type Size = "small" | "medium" | "large" | undefined;
type TextTransform = "none" | "uppercase" | "lowercase" | undefined;

export const themeConfig = {
    palette: {
        primary: {
            main: "#7556FA",
            light: "#6959FC",
            dark: "#896EF8"
        },
        secondary: {
            main: "#C8E98E",
            dark: "#A6DD4C"
        },
        neutral: {
            main: "#D9DAFD",
            secondary: "#f9f9f9"
        },
        blue: {
            dark: "#3C3769"
        },
        text: {
            main: "#3C3769"
        },
        error: {
            main: "#E25C5C"
        }
    },
    typography: {
        fontFamily: "Poppins"
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { size: "large" as Size },
                    style: { fontSize: 16, padding: "14px 28px" }
                },
                {
                    props: { size: "medium" as Size },
                    style: { fontSize: 16, padding: "10px 28px" }
                }
            ],
            styleOverrides: {
                // TODO: find type for ownerstate and theme
                root: ({ ownerState, theme }: any) => ({
                    borderRadius: "8px",
                    boxShadow: "none",
                    fontWeight: 600,
                    lineHeight: "150%",
                    textTransform: "none" as TextTransform,
                    "&.MuiButton-contained": {
                        "&:hover": {
                            backgroundColor: theme.palette[ownerState.color].dark,
                            transition: "0.2s",
                            boxShadow: "none"
                        },
                        "&:active": {
                            backgroundColor: theme.palette[ownerState.color].dark,
                            boxShadow: "inset 1px 2px 4px #8EBB46",
                            transition: "0.2s"
                        },
                        "&.MuiButton-containedNeutral": {
                            color: theme.palette.primary.main
                        }
                    },
                    "&.MuiButton-outlined": {
                        "&:hover": {
                            background: "transparent",
                            borderColor: theme.palette.blue.dark
                        },
                        "&:active": {
                            background: "transparent",
                            borderColor: theme.palette.blue.dark,
                            color: theme.palette.blue.dark
                        }
                    },
                    ...(ownerState.color === "neutral" && {
                        backgroundColor: "#F3F2EF !important",
                        borderColor: "#F3F2EF !important",
                        "& .MuiTouchRipple-child": {
                            backgroundColor: "#686868 !important"
                        }
                    })
                })
            }
        },
        MuiInput: {
            styleOverrides: {
                root: ({ ownerState, theme }: any) => ({
                    width: "100%",
                    ":before": { borderBottom: "none", transition: "none" },
                    ":after": { borderBottom: "none", transition: "none" },
                    "input, textarea": {
                        padding: "16px",
                        fontSize: "16px",
                        color: theme.palette.text.main,
                        fontWeight: 300,
                        borderRadius: "8px",
                        border: `1px solid ${theme.palette.neutral.main}`,
                        lineHeight: "150%",
                        transition: ".115s",
                        "&:focus": {
                            borderColor: theme.palette.primary.main
                            // boxShadow: "0 0 0 0.2rem rgba(117, 86, 250, 0.25)",
                        },
                        ...(ownerState.size &&
                            {
                                small: { padding: "10px 12px", fontSize: "13px" },
                                medium: { padding: "16px", fontSize: "16px" },
                                large: { padding: "16px", fontSize: "16px" }
                            }[(ownerState.size as Size) || "medium"])
                    },
                    ":hover:not(.Mui-disabled)": {
                        "&:before": { borderBottom: "none", transition: "none" },
                        "&:after": { borderBottom: "none", transition: "none" }
                    },
                    "&.Mui-error input, &.Mui-error textarea": {
                        borderColor: theme.palette.error.main,
                        color: theme.palette.error.main,
                        "&::placeholder": {
                            color: theme.palette.error.main
                        },
                        "&:focus": {
                            // boxShadow: "0 0 0 0.2rem rgba(220, 53, 69, 0.25)",
                        }
                    },
                    "&.Mui-disabled": {
                        "&:before": { borderBottom: "none", transition: "none" },
                        "&:after": { borderBottom: "none", transition: "none" },
                        "& input, & textarea": {
                            backgroundColor: theme.palette.neutral.secondary,
                            borderColor: theme.palette.neutral.secondary
                        }
                    },
                    "&.filled": {
                        background: "#F9F9F9",
                        "&:before": { borderBottom: "none", transition: "none" },
                        "&:after": { borderBottom: "none", transition: "none" },
                        "input, textarea": {
                            border: "none"
                        }
                    }
                })
            },
            variants: [
                {
                    props: { variant: "secondary" },
                    style: {
                        "input, textarea": {
                            background: "#F9F9F9"
                        }
                    }
                }
            ]
        },
        MuiSelect: {
            styleOverrides: {
                root: ({ ownerState, theme }: any) => ({
                    width: "100%",
                    border: "1px solid #D9D6DA",
                    borderRadius: "8px",
                    "input, textarea": {
                        padding: "16px",
                        fontSize: "16px",
                        color: theme.palette.text.main,
                        fontWeight: 300,
                        lineHeight: "150%"
                    },
                    ".MuiSelect-select > *": {
                        padding: "0 !important"
                    },
                    ".MuiSelect-icon": {
                        right: "16px !important",
                        "&.Mui-disabled": {
                            color: "#E5E5E5"
                        }
                    },
                    "&.Mui-focused": {
                        border: `1px solid ${theme.palette.primary.main}`
                    },
                    "&.Mui-disabled": {
                        background: "none",
                        borderColor: "#E5E5E5",
                        "&:before": { borderBottom: "none", transition: "none" },
                        "&:after": { borderBottom: "none", transition: "none" }
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                        display: "none"
                    },
                    "&.filled": {
                        background: "#F9F9F9",
                        border: "none"
                    }
                })
            }
        },
        MuiTooltip: {
            styleOverrides: {
                root: ({ ownerState, theme }: any) => ({
                    tooltip: {
                        bgcolor: theme?.palette.secondary.main,
                        color: theme?.palette.text.main,
                        fontSize: "14px",
                        fontWeight: 300,
                        padding: 1
                    }
                })
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: ({ ownerState, theme }: any) => ({
                    ...(ownerState.color === "primary" ? {
                                color: theme.palette.primary.main
                            }
                            : {
                                color: "#D9D6DA"
                            }
                    )
                })
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    ".Mui-selected": {
                        background: "#7556FA !important",
                        color: "#fff"
                    }
                }
            },
            defaultProps: {
                shape: "rounded"
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: ({ ownerState, theme }: any) => ({
                    boxShadow: "none",
                    borderRadius: "8px",
                    border: `1px solid ${theme.palette.neutral.main}`,
                    "&.MuiAccordion-root:before": {
                        display: "none"
                    }
                })
            }
        }
    }
};
const theme = createTheme(themeConfig);

export default theme;
