import React from 'react'
import BaseUnderconstruct from "@reusables/BaseUnderconstruct";

function UserLogsPage() {
  return (
    <div className="container-lg">
      <BaseUnderconstruct/>
    {/*<div>UserLogsPage</div>*/}

    </div>
  )
}

export default UserLogsPage;
