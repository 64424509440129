import React from "react";

import {useTranslation} from "react-i18next";
import Stepper from "../../Stepper";
import {StepperLink} from "../../Stepper/types";
import {Switch} from "react-router-dom";
import PrivateRoute from "../../../../../route-helper/PrivateRoute";
import {StepsContext, StepsContextType} from "./context";
import {useSelector} from "react-redux";
import {RootState} from "@redux/hooks";
// import UsersTab from "./subpages/Users/index";
// import RolesMainPage from "@components/Dashboard/pages/SettingsPage/General/MyAccount/subpages/Roles/MainPage";
// import RolesCreationPage from "@components/Dashboard/pages/SettingsPage/General/MyAccount/subpages/Roles/CreationPage";
// import RolesEditingPage from "@components/Dashboard/pages/SettingsPage/General/MyAccount/subpages/Roles/CreationPage";
import BaseUnderconstruct from "@reusables/BaseUnderconstruct";
import Profile from "./subpages/Profile";


function MyAccountRouter() {
    const { t } = useTranslation("", { keyPrefix: "settings.general.myAccount.tabs" });
    const stepperItems: StepperLink[] = [
        {
            title: t("profile.title"),
            link: "/dashboard/settings/general/account/profile"
        },
        // {
        //     title: t("users.heading"),
        //     link: "/dashboard/settings/general/account/users"
        // },
        // {
        //     title: t("roles.heading"),
        //     link: "/dashboard/settings/general/account/roles",
        // },
        {
            title: t("billing.title"),
            link: "/dashboard/settings/general/account/billing"
        },
        {
            title: t("subscriptions.title"),
            link: "/dashboard/settings/general/account/subscriptions"
        }
    ]

    const contextData: StepsContextType = {}

    const currentUser = useSelector((state: RootState) => state.auth.user)

    return (
        <div className="levitation-extended w-full">
            <StepsContext.Provider value={contextData}>
                <Stepper items={stepperItems} className="mb-[40px]" />
                <Switch>
                    <PrivateRoute
                        path={`/dashboard/settings/general/account/profile`}
                        component={() => <Profile />}
                        exact
                    />

                    {/* <PrivateRoute
                        path={`/dashboard/settings/general/account/users`}
                        component={() => <UsersTab />}
                        exact
                    />

                    <PrivateRoute
                        path="/dashboard/settings/general/account/roles"
                        component={() => <RolesMainPage />}
                        exact
                    />

                    <PrivateRoute
                        path="/dashboard/settings/general/account/roles/new"
                        component={() => <RolesCreationPage />}
                        exact
                    />

                    <PrivateRoute
                        path="/dashboard/settings/general/account/roles/:id"
                        component={() => <RolesEditingPage />}
                        exact
                    /> */}

                    <PrivateRoute
                        path={`/dashboard/settings/general/account/billing`}
                        component={() => <BaseUnderconstruct />}
                        exact
                    />

                    <PrivateRoute
                        path={`/dashboard/settings/general/account/subscriptions`}
                        component={() => <BaseUnderconstruct />}
                        exact
                    />
                </Switch>
            </StepsContext.Provider>
        </div>
    )
}


export default MyAccountRouter;
