import React, {useEffect, useState} from "react";
import axios from "axios";

function fetchShipments() {
    const API_URL = import.meta.env.VITE_API_URL;
    const token = localStorage.getItem("sanctum");

    const [currentPage, setCurrentPage] = useState<any>(1)

    const [perPage, setPerPage] = useState<number | null>(10)
    const [shipmentsLoading, setShipmentsLoading] = useState<boolean>(false)
    const [shipmentsData, setShipmentsData] = useState<Array<any>>([])
    const [shipmentsLinks , setShipmentsLinks] = useState<any | null>(null)
    const [shipmentsMeta, setShipmentsMeta] = useState<any | null>(null)


    const changePage = () => {

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

        if (token) {
            setShipmentsLoading(true)
            axios.get(`${API_URL}/api/shipment` , {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            .then((res) => {
                setShipmentsData(res.data.data)
                setShipmentsLinks(res.data.links)
                setShipmentsMeta(res.data.meta)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setShipmentsLoading(false)
            })
        }
    }

    useEffect(() => {
        changePage()
    }, [currentPage])
    useEffect(() => {
        setCurrentPage(1)
        changePage()
    }, [perPage])

    return {
        shipmentsLoading,
        shipmentsData,
        shipmentsLinks,
        currentPage,
        setCurrentPage,
        perPage,
        setPerPage,
        shipmentsMeta,
        setShipmentsMeta
    }
}

export { fetchShipments }
