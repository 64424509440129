import i18n from "i18next";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// translation files
import translationEN from "./translations/en/translation";
import translationNO from "./translations/no/translation";

import zodTranslationEN from "zod-i18n-map/locales/en/zod.json";
import zodTranslationNB from "zod-i18n-map/locales/nb/zod.json";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,

        // language to switch when can't find translation
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: {
                translation: {
                    ...translationEN
                },
                zod: zodTranslationEN
            },
            no: {
                translation: {
                    ...translationNO
                },
                zod: zodTranslationNB
            }
        }
    });

z.setErrorMap(makeZodI18nMap({ t: i18next.t, ns: ["zod", "translation"] }));

export default i18n;