import React from "react";
import { Tooltip, TooltipProps } from "@mui/material";

export const BaseTooltip = ({ children, placement, active = true, ...rest }: TooltipProps & { active?: boolean }) => {
    if (!active)
        return children;

    return <Tooltip
        placement={placement ?? "right"}
        arrow
        componentsProps={{
            tooltip: {
                sx: {
                    bgcolor: "#C8E98E",
                    "& .MuiTooltip-arrow": {
                        color: "#C8E98E"
                    },
                    color: "#3C3769",
                    fontSize: "14px",
                    fontWeight: "light",
                    padding: "8px"
                }
            }
        }}
        {...rest}
    >
        <span className="cursor-pointer">
            {children}
        </span>
    </Tooltip>;
};

export default BaseTooltip;
