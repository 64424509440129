import {PurchaseOrder, Supplier, Tax} from "@/types/general";

export interface Order {
    id?: number;
    discount?: number;
    unit_price?: number;
    quantity: number;
    product: {
        id: number;
        name: string;
        code: string;

        supplier?: Supplier.Slim;
    };

    tax?: Tax.Slim;
}

export interface FlattenedLine {
    data: PurchaseOrder.Components.LineData; 
}[];

export function flattenLines(lines: PurchaseOrder.Components.Lines): FlattenedLine[] {
    return lines.flatMap(line => {
        if (line.type === "line") {
            return [{ data: line.data }];
        } else if (line.type === "group") {
            return line.data?.map(lineData => ({ data: lineData })) ?? [];
        } 
        return [];
    });
}