import React from 'react'
import { Control, Controller, useController, useFieldArray } from 'react-hook-form'
import { SerialNumbersScheme } from '.'
import { Stack } from '@mui/material';
import BaseMaterialButton from '@/components/reusables/BaseMaterialButton';
import BaseInputsGrid from '@/components/reusables/BaseInputsGrid';
import BaseInput from '@/components/reusables/BaseInput';
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import { useTranslation } from 'react-i18next';

export default function SerialGroup({control, preview}: { control: Control<SerialNumbersScheme>, preview?: boolean}) {
    const { t } = useTranslation("", { keyPrefix: "bom.produceModal.modals.serialNumbersModal" });

    const {field: quantity} = useController({
        control,
        name: `product.quantity`
    });

    const {fields: serialNumbers, prepend, remove} = useFieldArray({
        control,
        name: `product.serial_numbers`
    });

    return (
        <div>
            <div className="flex items-center mb-[32px]">
                <div className="flex justify-start items-center grow">
                    <div className="text-lg font-semibold text-accent">{t("heading")}</div>
                </div>
                
                    {
                        !preview && (
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}
                            >
                                {/* Serial inputs number limit (limited with the picked quantity for the product) */}
                                <div className={`opacity-50 ${serialNumbers.length > quantity.value ? "text-[#E25C5C]" : ""}`}>
                                    ({serialNumbers.length} / {quantity.value})
                                </div>
                                <BaseMaterialButton type="button" onClick={() => {
                                    prepend({
                                        serial_number: ""
                                    })
                                }}>
                                    {t("buttons.addSerialNumber")}
                                </BaseMaterialButton>
                            </Stack>
                        )
                    }
                </div>

            <BaseInputsGrid cols={2} gap={{ x: 32, y: 8 }}>
                {
                    serialNumbers.map((serialNumber, index) => (
                        <Stack
                            key={serialNumber.id}
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                        >
                            <Controller
                                control={control}
                                name={`product.serial_numbers.${index}.serial_number`}
                                render={({field}) => (
                                    <BaseInput
                                        {...field}
                                        placeholder={t("fields.serialNumber.placeholder")}
                                        className="w-full"
                                        sx={{
                                            "& input": {
                                                background: "#F9F9F9",
                                                padding: "12px",
                                                border: 0
                                            }
                                        }}

                                        disabled={preview}
                                    />
                                )}
                            />

                            {/* Deletion button */}
                            {
                                !preview &&
                                <span>
                                    <DeleteSVG
                                        className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                        onClick={() => {
                                            remove(index)
                                        }}
                                    />
                                </span>
                            }
                        </Stack>
                    ))
                }
            </BaseInputsGrid>
        </div>
    )
}
