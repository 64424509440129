import React, { useEffect, useMemo, useState } from "react";
import NavigationBar from "../NavigationBar";
import OfficeImage from "@assets/images/officeNotebook.png";
import OfficePeopleImage from "@assets/images/officePeople.png";
import WaveIcon from "@assets/images/waveIcon.svg";
import PolicyImg from "@assets/images/policy.jpg";
import TermsImg from "@assets/images/terms.jpg";
import "./index.scss";
import FooterBlock from "../FooterBlock";
import { Button } from "@mui/material";

// Legal PDFs
import PrivacyPdf from "@assets/files/legal/Suppli_Privacy_policy.pdf";

const PrivacyTermPage: React.FC = () => {
  const [isSecondPage, setIsSecondPage] = useState<boolean>(false);
  const handleChangeView = () => {
    setIsSecondPage(!isSecondPage);
  };

  const { header, subheader } = useMemo(
    () =>
      isSecondPage
        ? {
            header: "Terms & Conditions Agreement",
            subheader: "Terms & Conditions",
          }
        : {
            header: "Personal Information Privacy and Transparency Notice",
            subheader: "Privacy Policy",
          },
    [isSecondPage, setIsSecondPage]
  );

  const privacyPolicyText = (
    <>
      <div className="page-top-title">
        <svg
          width="84"
          height="18"
          viewBox="0 0 84 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 16.6711L8.96668 4.73778C9.7367 3.19773 11.9344 3.19773 12.7045 4.73778L16.8022 12.9334C17.5723 14.4734 19.77 14.4734 20.54 12.9334L24.6378 4.73778C25.4078 3.19773 27.6056 3.19773 28.3756 4.73778L32.4734 12.9334C33.2434 14.4734 35.4411 14.4734 36.2112 12.9334L40.3089 4.73778C41.079 3.19773 43.2767 3.19773 44.0467 4.73778L48.1445 12.9334C48.9145 14.4734 51.1123 14.4734 51.8823 12.9334L55.9801 4.73778C56.7501 3.19773 58.9478 3.19773 59.7179 4.73778L63.8156 12.9334C64.5857 14.4734 66.7834 14.4734 67.5534 12.9334L71.6512 4.73778C72.4212 3.19773 74.619 3.19773 75.389 4.73778L81.3557 16.6711"
            stroke="url(#paint0_linear_106_308)"
            strokeWidth="5.22371"
          />
          <defs>
            <linearGradient
              id="paint0_linear_106_308"
              x1="3"
              y1="7.90105"
              x2="80.9664"
              y2="13.9363"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#8850F4" />
              <stop offset="1" stopColor="#6959FC" />
            </linearGradient>
          </defs>
        </svg>
        <h1>{header}</h1>
        <p className="text-lg-bold text-center">Suppli</p>
      </div>
      <div className="policy-text">
        <p className="text-xl-bold">{subheader}</p>
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You respond to
          our advertisements and tells You about Your privacy rights and how the
          law protects You. We use Your Personal Data to contact and support
          you, as well as to provide and improve the Service. By using the
          Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>

        <div>
          <p className="text-xl-bold text-center my-5">
            Interpretation and Definitions
          </p>

          <div>
            <p className="text-lg-bold">Interpretation</p>

            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
          </div>

          <div>
            <p className="text-lg-bold">Definitions</p>

            <p>For the purposes of this Privacy Policy:</p>

            <div>
              <ul>
                <li>
                  <p>
                    <span className="text-md-bold">Company</span> &#40;referred
                    to as either &quot;the Company&quot;, &quot;We&quot;,
                    &quot;Us&quot; or &quot;Our&quot; in this Agreement&#41;
                    refers to Suppli.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="text-md-bold">Device</span> means any
                    device that can access the Service such as a computer, a
                    cellphone or a digital tablet.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="text-md-bold">Personal Data</span> is any
                    information that relates to an identified or identifiable
                    individual.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="text-md-bold">Service</span> refers to the
                    advertisement, website, or application.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="text-md-bold">Service Provider</span> means
                    any natural or legal person who processes the data on behalf
                    of the Company. It refers to third-party companies or
                    individuals employed by the Company to facilitate the
                    Service, to provide the Service on behalf of the Company, to
                    perform services related to the Service or to assist the
                    Company in analyzing how the Service is used.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="text-md-bold">Usage Data</span> refers to
                    data collected automatically, either generated by the use of
                    the Service or from the Service infrastructure itself
                    &#40;for example, the duration of a page visit&#41;.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="text-md-bold">You</span> means the
                    individual accessing or using the Service, or the company,
                    or other legal entity on behalf of which such individual is
                    accessing or using the Service, as applicable.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <p className="text-xl-bold text-center my-5">
            Collecting and Using Your Personal Data
          </p>

          <div>
            <p className="text-lg-bold">Types of Data Collected</p>

            <div>
              <p className="text-lg-bold">Personal Data</p>

              <p>
                While using Our Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. Personally identifiable information may
                include, but is not limited to:
              </p>

              <ul>
                <li>
                  <p>First name and last name</p>
                </li>

                <li>
                  <p>Email address</p>
                </li>

                <li>
                  <p>Phone number</p>
                </li>

                <li>
                  <p>Location details such as your address, city, or country</p>
                </li>

                <li>
                  <p>Company name</p>
                </li>

                <li>
                  <p>
                    Any other data such as personal preferences, requirements,
                    or comments
                  </p>
                </li>
              </ul>
            </div>

            <div className="mt-5">
              <div>
                <p className="text-lg-bold">Use of Your Personal Data</p>

                <p>
                  The Company may use Personal Data for the following purposes:
                </p>

                <ul>
                  <li>
                    <p>
                      <span className="text-md-bold">
                        To provide and maintain our Service,
                      </span>{" "}
                      including to monitor the usage of our Service.
                    </p>
                  </li>

                  <li>
                    <p>
                      <span className="text-md-bold">
                        To manage Your Account:
                      </span>{" "}
                      to manage Your registration as a user of the Service. The
                      Personal Data You provide can give You access to different
                      functionalities of the Service that are available to You
                      as a registered user.
                    </p>
                  </li>

                  <li>
                    <p>
                      <span className="text-md-bold">
                        For the performance of a contract:
                      </span>{" "}
                      the development, compliance and undertaking of the
                      purchase contract for the products, items or services You
                      have purchased or of any other contract with Us through
                      the Service.
                    </p>
                  </li>

                  <li>
                    <p>
                      <span className="text-md-bold">To contact You:</span> To
                      contact You by email, telephone calls, SMS, or other
                      equivalent forms of electronic communication, such as a
                      mobile application&apos;s push notifications regarding
                      updates or informative communications related to the
                      functionalities, products or contracted services,
                      including the security updates, when necessary or
                      reasonable for their implementation.
                    </p>
                  </li>

                  <li>
                    <p>
                      <span className="text-md-bold">To provide You</span> with
                      news, special offers and general information about other
                      goods, services and events which we offer that are similar
                      to those that you have already purchased or enquired about
                      unless You have opted not to receive such information.
                    </p>
                  </li>

                  <li>
                    <p>
                      <span className="text-md-bold">
                        To manage Your requests:
                      </span>{" "}
                      To attend and manage Your requests to Us.
                    </p>
                  </li>

                  <li>
                    <p>
                      <span className="text-md-bold">
                        For business transfers:
                      </span>{" "}
                      We may use Your information to evaluate or conduct a
                      merger, divestiture, restructuring, reorganization,
                      dissolution, or other sale or transfer of some or all of
                      Our assets, whether as a going concern or as part of
                      bankruptcy, liquidation, or similar proceeding, in which
                      Personal Data held by Us about our Service users is among
                      the assets transferred.
                    </p>
                  </li>

                  <li>
                    <p>
                      <span className="text-md-bold">For other purposes:</span>{" "}
                      We may use Your information for other purposes, such as
                      data analysis, identifying usage trends, determining the
                      effectiveness of our promotional campaigns and to evaluate
                      and improve our Service, products, services, marketing and
                      your experience.
                    </p>
                  </li>
                </ul>
              </div>

              <div className="mt-5">
                <p>
                  We may share Your personal information in the following
                  situations:
                </p>

                <ul>
                  <li>
                    <p>
                      <span className="text-md-bold">
                        With Service Providers:
                      </span>{" "}
                      We may share Your personal information with Service
                      Providers to monitor and analyze the use of our Service,
                      to contact You.
                    </p>
                  </li>

                  <li>
                    <p>
                      <span className="text-md-bold">
                        For business transfers:
                      </span>{" "}
                      We may share or transfer Your personal information in
                      connection with, or during negotiations of, any merger,
                      sale of Company assets, financing, or acquisition of all
                      or a portion of Our business to another company.
                    </p>
                  </li>

                  <li>
                    <p>
                      <span className="text-md-bold">With Affiliates: We</span>{" "}
                      may share Your information with Our affiliates, in which
                      case we will require those affiliates to honor this
                      Privacy Policy. Affiliates include Our parent company and
                      any other subsidiaries, joint venture partners or other
                      companies that We control or that are under common control
                      with Us.
                    </p>
                  </li>

                  <li>
                    <p>
                      <span className="text-md-bold">
                        With business partners:
                      </span>{" "}
                      We may share Your information with Our business partners
                      to offer You certain products, services or promotions.
                    </p>
                  </li>

                  <li>
                    <p>
                      <span className="text-md-bold">With other users:</span>{" "}
                      when You share personal information or otherwise interact
                      in the public areas with other users, such information may
                      be viewed by all users and may be publicly distributed
                      outside.
                    </p>
                  </li>

                  <li>
                    <p>
                      <span className="text-md-bold">With Your consent:</span>{" "}
                      We may disclose Your personal information for any other
                      purpose with Your consent.
                    </p>
                  </li>
                </ul>
              </div>

              <div className="mt-5">
                <p className="text-lg-bold">Retention of Your Personal Data</p>

                <p>
                  The Company will retain Your Personal Data only for as long as
                  is necessary for the purposes set out in this Privacy Policy.
                  We will retain and use Your Personal Data to the extent
                  necessary to comply with our legal obligations &#40;for
                  example, if we are required to retain your data to comply with
                  applicable laws&#41;, resolve disputes, and enforce our legal
                  agreements and policies.
                </p>

                <p>
                  The Company will also retain Usage Data for internal analysis
                  purposes.
                </p>
              </div>

              <div className="my-5">
                <p className="text-lg-bold">Transfer of Your Personal Data</p>

                <p>
                  Your information, including Personal Data, is processed at the
                  Company&apos;s operating offices and in any other places where
                  the parties involved in the processing are located. It means
                  that this information may be transferred to — and maintained
                  on — computers located outside of Your state, province,
                  country or other governmental jurisdiction where the data
                  protection laws may differ than those from Your jurisdiction.
                </p>

                <p>
                  Your consent to this Privacy Policy followed by Your
                  submission of such information represents Your agreement to
                  that transfer.
                </p>

                <p>
                  The Company will take all steps reasonably necessary to ensure
                  that Your data is treated securely and in accordance with this
                  Privacy Policy and no transfer of Your Personal Data will take
                  place to an organization or a country unless there are
                  adequate controls in place including the security of Your data
                  and other personal information.
                </p>
              </div>

              <div className="my-5">
                <p className="text-lg-bold">Disclosure of Your Personal Data</p>

                <p className="text-lg-bold">Law enforcement</p>

                <p>
                  Under certain circumstances, the Company may be required to
                  disclose Your Personal Data if required to do so by law or in
                  response to valid requests by public authorities (e.g. a court
                  or a government agency).
                </p>

                <p className="text-lg-bold">Other legal requirements</p>

                <p>
                  The Company may disclose Your Personal Data in the good faith
                  belief that such action is necessary to:
                </p>

                <ul>
                  <li>
                    <p>Comply with a legal obligation</p>
                  </li>

                  <li>
                    <p>
                      Protect and defend the rights or property of the Company
                    </p>
                  </li>

                  <li>
                    <p>
                      Prevent or investigate possible wrongdoing in connection
                      with the Service
                    </p>
                  </li>

                  <li>
                    <p>
                      Protect the personal safety of Users of the Service or the
                      public
                    </p>
                  </li>

                  <li>
                    <p>Protect against legal liability</p>
                  </li>
                </ul>

                <p className="text-lg-bold">Security of Your Personal Data</p>

                <p>
                  The security of Your Personal Data is important to Us, but
                  remember that no method of transmission over the Internet, or
                  method of electronic storage is 100% secure. While We strive
                  to use commercially acceptable means to protect Your Personal
                  Data, We cannot guarantee its absolute security.
                </p>
              </div>

              <div>
                <p className="text-lg-bold">Links to Other Websites</p>

                <p>
                  Our Service may contain links to other websites that are not
                  operated by Us. If You click on a third party link, You will
                  be directed to that third party&apos;s site. We strongly advise You
                  to review the Privacy Policy of every site You visit.
                </p>

                <p>
                  We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
                </p>
              </div>

              <div>
                <p className="text-lg-bold">Changes to this Privacy Policy</p>

                <p>
                  We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
                </p>

                <p>
                  You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                </p>

                <p>
                  This Privacy Policy was last updated on 2022/05/15.
                </p>
              </div>

              <div>
                <p className="text-lg-bold">Contact Us</p>

                <p>
                  If you have any questions about this Privacy Policy, You can contact us via email at <a href="mailto:annahych1@gmail.com">annahych1@gmail.com</a>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="policy-footer">
        <p>Updated: May 2022</p>
        <a href={PrivacyPdf} download className="btn btn-filled">
          PDF version
        </a>
      </div>
    </>
  );

  // const termsAndConditionsText = (
  //   <>
  //     <div className="page-top-title">
  //       <svg
  //         width="84"
  //         height="18"
  //         viewBox="0 0 84 18"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <path
  //           d="M3 16.6711L8.96668 4.73778C9.7367 3.19773 11.9344 3.19773 12.7045 4.73778L16.8022 12.9334C17.5723 14.4734 19.77 14.4734 20.54 12.9334L24.6378 4.73778C25.4078 3.19773 27.6056 3.19773 28.3756 4.73778L32.4734 12.9334C33.2434 14.4734 35.4411 14.4734 36.2112 12.9334L40.3089 4.73778C41.079 3.19773 43.2767 3.19773 44.0467 4.73778L48.1445 12.9334C48.9145 14.4734 51.1123 14.4734 51.8823 12.9334L55.9801 4.73778C56.7501 3.19773 58.9478 3.19773 59.7179 4.73778L63.8156 12.9334C64.5857 14.4734 66.7834 14.4734 67.5534 12.9334L71.6512 4.73778C72.4212 3.19773 74.619 3.19773 75.389 4.73778L81.3557 16.6711"
  //           stroke="url(#paint0_linear_106_308)"
  //           strokeWidth="5.22371"
  //         />
  //         <defs>
  //           <linearGradient
  //             id="paint0_linear_106_308"
  //             x1="3"
  //             y1="7.90105"
  //             x2="80.9664"
  //             y2="13.9363"
  //             gradientUnits="userSpaceOnUse"
  //           >
  //             <stop stopColor="#8850F4" />
  //             <stop offset="1" stopColor="#6959FC" />
  //           </linearGradient>
  //         </defs>
  //       </svg>
  //       <h1>{header}</h1>
  //       <span>Suppli terms</span>
  //     </div>
  //     <div className="policy-text">
  //       <h2>{subheader}</h2>
  //       <p>
  //         This Privacy Policy describes Our policies and procedures on the
  //         collection, use and disclosure of Your information when You respond to
  //         our advertisements and tells You about Your privacy rights and how the
  //         law protects You. We use Your Personal Data to contact and support
  //         you, as well as to provide and improve the Service. By using the
  //         Service, You agree to the collection and use of information in
  //         accordance with this Privacy Policy.
  //       </p>
  //       <p>
  //         <a href="javascript:void(0)">Tristique sit in congue</a> id. Risus
  //         imperdiet tellus et neque, in augue. Ullamcorper ipsum, turpis gravida
  //         condimentum sagittis. Orci pulvinar justo ornare sed condimentum
  //         viverra mattis maecenas. Ultricies ut enim est parturient ut est.
  //         Magna senectus condimentum eu dignissim. At odio sed metus neque, et
  //         elementum et, aliquet. Fermentum lobortis penatibus dolor justo arcu
  //         vel. Quam viverra suspendisse tortor, amet amet a. Cursus mauris eget
  //         interdum ac pulvinar aliquam. Est vel quisque quis varius facilisi. Eu
  //         fermentum, nulla orci feugiat et urna, sed amet vel.
  //       </p>
  //       <p>
  //         Tristique sit in congue id. Risus imperdiet tellus et neque, in augue.
  //         Ullamcorper ipsum, turpis gravida condimentum sagittis. Orci pulvinar
  //         justo ornare sed condimentum viverra mattis maecenas. Ultricies ut
  //         enim est parturient ut est. Magna senectus condimentum eu dignissim.
  //         At odio sed metus neque, et elementum et, aliquet. Fermentum lobortis
  //         penatibus dolor justo arcu vel. Quam viverra suspendisse tortor, amet
  //         amet a. Cursus mauris eget interdum ac pulvinar aliquam. Est vel
  //         quisque quis varius facilisi. Eu fermentum, nulla orci feugiat et
  //         urna, sed amet vel.
  //       </p>
  //       <h2>Privacy Policy</h2>
  //       <p>
  //         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
  //         risus ultrices enim consectetur. Nulla justo, nisi nunc, sit euismod.
  //         Nulla diam massa purus commodo nec. Eget in ullamcorper vitae magna
  //         ultricies turpis ullamcorper quam sem. Urna et molestie nunc, at
  //         ultrices donec. Vel fermentum netus morbi lacus eu, fringilla diam.
  //         Diam magna amet at massa leo, egestas gravida faucibus pellentesque.
  //         Semper et condimentum risus, amet, velit. Velit elit nec donec
  //         pellentesque. In sem ultricies dolor et, faucibus in sit. Felis, felis
  //         faucibus pulvinar in non aenean sodales sem pretium. Massa habitant
  //         felis, nisl malesuada neque. In dictum nam interdum amet cras. Dapibus
  //         at non odio non urna. Cursus libero, sed aliquam risus adipiscing. Sed
  //         aliquam amet fames tellus id morbi vitae sit etiam. Purus enim nullam
  //         eget rutrum blandit a habitant quis nibh. Tristique sit in congue id.
  //         Risus imperdiet tellus et neque, in augue. Ullamcorper ipsum, turpis
  //         gravida condimentum sagittis. Orci pulvinar justo ornare sed
  //         condimentum viverra mattis maecenas. Ultricies ut enim est parturient
  //         ut est. Magna senectus condimentum eu dignissim. At odio sed metus
  //         neque, et elementum et, aliquet. Fermentum lobortis penatibus dolor
  //         justo arcu vel. Quam viverra suspendisse tortor, amet amet a. Cursus
  //         mauris eget interdum ac pulvinar aliquam. Est vel quisque quis varius
  //         facilisi. Eu fermentum, nulla orci feugiat et urna, sed amet vel.
  //       </p>
  //       <p>
  //         <a href="javascript:void(0)">Tristique sit in congue</a> id. Risus
  //         imperdiet tellus et neque, in augue. Ullamcorper ipsum, turpis gravida
  //         condimentum sagittis. Orci pulvinar justo ornare sed condimentum
  //         viverra mattis maecenas. Ultricies ut enim est parturient ut est.
  //         Magna senectus condimentum eu dignissim. At odio sed metus neque, et
  //         elementum et, aliquet. Fermentum lobortis penatibus dolor justo arcu
  //         vel. Quam viverra suspendisse tortor, amet amet a. Cursus mauris eget
  //         interdum ac pulvinar aliquam. Est vel quisque quis varius facilisi. Eu
  //         fermentum, nulla orci feugiat et urna, sed amet vel.
  //       </p>
  //       <p>
  //         Tristique sit in congue id. Risus imperdiet tellus et neque, in augue.
  //         Ullamcorper ipsum, turpis gravida condimentum sagittis. Orci pulvinar
  //         justo ornare sed condimentum viverra mattis maecenas. Ultricies ut
  //         enim est parturient ut est. Magna senectus condimentum eu dignissim.
  //         At odio sed metus neque, et elementum et, aliquet. Fermentum lobortis
  //         penatibus dolor justo arcu vel. Quam viverra suspendisse tortor, amet
  //         amet a. Cursus mauris eget interdum ac pulvinar aliquam. Est vel
  //         quisque quis varius facilisi. Eu fermentum, nulla orci feugiat et
  //         urna, sed amet vel.
  //       </p>
  //       <h2>Privacy Policy</h2>
  //       <p>
  //         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
  //         risus ultrices enim consectetur. Nulla justo, nisi nunc, sit euismod.
  //         Nulla diam massa purus commodo nec. Eget in ullamcorper vitae magna
  //         ultricies turpis ullamcorper quam sem. Urna et molestie nunc, at
  //         ultrices donec. Vel fermentum netus morbi lacus eu, fringilla diam.
  //         Diam magna amet at massa leo, egestas gravida faucibus pellentesque.
  //         Semper et condimentum risus, amet, velit. Velit elit nec donec
  //         pellentesque. In sem ultricies dolor et, faucibus in sit. Felis, felis
  //         faucibus pulvinar in non aenean sodales sem pretium. Massa habitant
  //         felis, nisl malesuada neque. In dictum nam interdum amet cras. Dapibus
  //         at non odio non urna. Cursus libero, sed aliquam risus adipiscing. Sed
  //         aliquam amet fames tellus id morbi vitae sit etiam. Purus enim nullam
  //         eget rutrum blandit a habitant quis nibh. Tristique sit in congue id.
  //         Risus imperdiet tellus et neque, in augue. Ullamcorper ipsum, turpis
  //         gravida condimentum sagittis. Orci pulvinar justo ornare sed
  //         condimentum viverra mattis maecenas. Ultricies ut enim est parturient
  //         ut est. Magna senectus condimentum eu dignissim. At odio sed metus
  //         neque, et elementum et, aliquet. Fermentum lobortis penatibus dolor
  //         justo arcu vel. Quam viverra suspendisse tortor, amet amet a. Cursus
  //         mauris eget interdum ac pulvinar aliquam. Est vel quisque quis varius
  //         facilisi. Eu fermentum, nulla orci feugiat et urna, sed amet vel.
  //       </p>
  //       <p>
  //         <a href="javascript:void(0)">Tristique sit in congue</a> id. Risus
  //         imperdiet tellus et neque, in augue. Ullamcorper ipsum, turpis gravida
  //         condimentum sagittis. Orci pulvinar justo ornare sed condimentum
  //         viverra mattis maecenas. Ultricies ut enim est parturient ut est.
  //         Magna senectus condimentum eu dignissim. At odio sed metus neque, et
  //         elementum et, aliquet. Fermentum lobortis penatibus dolor justo arcu
  //         vel. Quam viverra suspendisse tortor, amet amet a. Cursus mauris eget
  //         interdum ac pulvinar aliquam. Est vel quisque quis varius facilisi. Eu
  //         fermentum, nulla orci feugiat et urna, sed amet vel.
  //       </p>
  //       <p>
  //         Tristique sit in congue id. Risus imperdiet tellus et neque, in augue.
  //         Ullamcorper ipsum, turpis gravida condimentum sagittis. Orci pulvinar
  //         justo ornare sed condimentum viverra mattis maecenas. Ultricies ut
  //         enim est parturient ut est. Magna senectus condimentum eu dignissim.
  //         At odio sed metus neque, et elementum et, aliquet. Fermentum lobortis
  //         penatibus dolor justo arcu vel. Quam viverra suspendisse tortor, amet
  //         amet a. Cursus mauris eget interdum ac pulvinar aliquam. Est vel
  //         quisque quis varius facilisi. Eu fermentum, nulla orci feugiat et
  //         urna, sed amet vel.
  //       </p>
  //     </div>
  //     <div className="policy-footer">
  //       <p>Updated: October 2021</p>
  //       <a href="javascript:void(0)" className="btn btn-filled">
  //         PDF version
  //       </a>
  //     </div>
  //   </>
  // );

  return (
    <>
      <NavigationBar />
      <main className="main">
        <div className="section policy-section">
          <div className="container-lg">
            <div className="page-top">
              <div>
                <img src={isSecondPage ? TermsImg : PolicyImg} alt="" />
              </div>
              <div className="page-top-logo">
                <svg
                  width="278"
                  height="253"
                  viewBox="0 0 278 253"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="M0.024054 153.532C1.63101 70.4061 88.6645 10.0208 184.77 1.96529C208.172 0.0114513 247.765 -0.537048 277.07 8.63623L249.342 59.789C229.988 54.8643 207.137 53.4619 184.358 54.4611C86.3282 57.8529 24.6354 115.371 23.2597 173.527C22.5362 204.409 37.4465 234.149 67.0507 251.349L65.1176 253C22.1508 231.617 -0.85058 197.975 0.024054 153.532Z"
                      fill="white"
                    />
                    <path
                      d="M45.6487 173.047C55.3704 124.506 109.435 87.4276 194.39 91.86C206.154 92.4797 219.511 93.633 231.433 96.2451L206.875 141.527C199.231 140.478 187.298 138.352 177.054 137.801C110.122 134.299 68.9406 161.991 62.1096 197.302C58.442 216.263 66.2159 237.449 85.615 250.771L83.67 252.265C50.4517 233.883 39.6923 202.722 45.6487 173.047Z"
                      fill="white"
                    />
                    <path
                      d="M85.7688 197.625C102.028 170.283 151.028 161.43 194.778 172.056L181.952 195.568C147.174 187.565 106.911 193 95.372 213.048C89.0984 223.959 92.5969 238.952 99.6829 249.534L98.3843 250.841C82.0272 236.21 76.0767 213.938 85.7688 197.625Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </div>
            </div>
            {!isSecondPage && (
              <ul className="policy-menu">
                <li>
                  {/* <span>Privacy Policy</span> */}
                  <span onClick={handleChangeView}>Privacy Policy</span>
                </li>
                {/* <li> */}
                  {/* <a href="javascript:void(0)">Terms & Conditions</a> */}
                  {/* <a onClick={handleChangeView}>Terms & Conditions</a> */}
                {/* </li> */}
              </ul>
            )}
            {/* {isSecondPage && (
              <ul className="policy-menu">
                <li>
                  <a onClick={handleChangeView}>Privacy Policy</a>
                </li>
                <li>
                  <span onClick={handleChangeView}>Terms & Conditions</span>
                </li>
              </ul>
            )} */}

            {/* // * Commented out terms page */}
            {/* {isSecondPage ? termsAndConditionsText : privacyPolicyText} */}

            { privacyPolicyText }

          </div>
        </div>
      </main>
      <FooterBlock />
    </>
  );
};

export default PrivacyTermPage;
