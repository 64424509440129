import React from 'react'
import NavigationBar from '../../NavigationBar';
import ErrorImage from "@assets/images/error-image.png"

function NotFoundPage() {
  return (
    <>
      <NavigationBar />
      <main className='main'>
        <div className="grid grid-cols-2 mt-[2.5%]">
          <div className="m-auto max-w-[447px]">
            <h1 className="text-[#5A5587] font-[700] text-[160px] leading-[160px]">404</h1>
            <div className=""><h2 className="text-[#5A5587] font-[600] text-[64px] leading-[70.4px]">Something went <b className="text-[#A6DD4C] font-[600]">wrong</b></h2></div>
            <a href="/" className="btn btn-filled w-[447px] mt-[40px]">Back to home</a>
          </div>

          <img src={ErrorImage} className="max-w-[600px] m-auto" />
        </div>
      </main>
    </>
  )
}

export default NotFoundPage;