import dayjs, { Dayjs } from "dayjs";
import { z } from "zod";
import { refinements } from "@helpers/refinements";
import { ArrayElementType } from "@helpers/utils";

const ORDER_PRICE_PRECISION = import.meta.env.VITE_ORDER_PRICE_PRECISION;

const maxDecimalPrecisionRefinement = refinements.maxDecimalPrecision(ORDER_PRICE_PRECISION);

export const customerGroupsScheme = z.object({
    code: z.string().trim().optional(),
    name: z.string().trim(),
    discount: z.coerce.number().min(0).max(100),

    customers: z.array(z.object({
        value: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string(),
        })
    })),

    products: z.array(z.object({
        product: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string(),
        }),
        sale_price: z.coerce.number().min(0)
            .refine(maxDecimalPrecisionRefinement.refine, maxDecimalPrecisionRefinement.message),
        valid_from: z.instanceof(dayjs as unknown as typeof Dayjs).nullish(),
        valid_to: z.instanceof(dayjs as unknown as typeof Dayjs).nullish(),
    })).nullish(),
});

export type CustomerGroupsFormTyping = z.infer<typeof customerGroupsScheme>;
export type CustomerItem = ArrayElementType<CustomerGroupsFormTyping["customers"]>
export type Product = ArrayElementType<CustomerGroupsFormTyping["products"]>

export interface Customer {
    id: number;
    name: string;
}