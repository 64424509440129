import React from 'react'
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {CustomerFormTyping} from './TabsController';
import {useGetModulePaymentTermsQuery} from '@redux/features/paymentTerms/paymentTermsApi';
import {useGetModuleDeliveryTermsQuery} from '@redux/features/deliveryTerms/deliveryTermsApi';
import {useGetModuleEmployeesQuery} from '@redux/features/employees/employeesApi';
import {useGetModuleTaxesQuery} from '@redux/features/taxes/taxesApi';
import BaseInputsGrid from '@reusables/BaseInputsGrid';
import BaseDropdown from '@reusables/dropdowns/BaseDropdown';
import {TabProperties} from '../../types';
import BaseButton from '@reusables/BaseButton';
import BaseInput from '@reusables/BaseInput';
import FormErrorWarning from '../modals/FormErrorWarning';
import { useGetLanguagesQuery } from '@redux/features/languages/languagesApi';
import { useAbility } from '@/casl.config';
import BasePermissionBlocker from '@reusables/BasePermissionBlocker';

export default function SettingsTab({onNext, onPrev, mode="creation"}: TabProperties & { onPrev: () => void}) {
    const { t } = useTranslation("", { keyPrefix: "customers.general.tabs.settings" });

    const ability = useAbility();

    const {control} = useFormContext<CustomerFormTyping>();

    const {data: languageOptions, isLoading: isLanguageOptions} = useGetLanguagesQuery();

    const {data: paymentTermsOptions, isLoading: isPaymentTermsOptionsLoading} = useGetModulePaymentTermsQuery("customer");

    const {data: deliveryTermsOptions, isLoading: isDeliveryTermsOptionsLoading} = useGetModuleDeliveryTermsQuery("customer");

    const {data: referencesOptions, isLoading: isReferencesOptionsLoading} = useGetModuleEmployeesQuery("customer");

    const {data: taxOptions, isLoading: taxOptionsLoading} = useGetModuleTaxesQuery("customer");

    return (
        <form className="space-y-[40px]" onSubmit={onNext}>
            <FormErrorWarning />
            
            <BaseInputsGrid>
                {/* TODO: postpone until languages for products are implemented */}
                <Controller
                    control={control}
                    name="language"
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}

                            options={languageOptions}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name
                            }}
                            
                            label={t("dropdowns.language.label")}
                            placeholder={t("dropdowns.language.placeholder")}
                            isLoading={isLanguageOptions}
                            autocomplete
                        />
                    )}
                />

                <BasePermissionBlocker expression={mode == "editing"} action="tax_rate.edit" module="customer" placement="top-start">
                    <Controller
                        control={control}
                        name="tax_rate"
                        render={({field, fieldState}) => (
                            <BaseDropdown
                                {...field}
                                {...fieldState}

                                options={taxOptions}
                                getter={{
                                    key: opt => opt.id,
                                    label: opt => `${opt.rate}%`
                                }}

                                label={t("dropdowns.taxRate.label")}
                                placeholder={t("dropdowns.taxRate.placeholder")}
                                isLoading={taxOptionsLoading}
                                autocomplete
                                disabled={mode == "editing" && ability.cannot("tax_rate.edit", "customer")}
                            />
                        )}
                    />
                </BasePermissionBlocker>

                <BasePermissionBlocker expression={mode == "editing"} action="tax_rate.edit" module="customer" placement="top-start">
                    <Controller
                        control={control}
                        name="discount"
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}

                                label={t("fields.discount.label")}
                                placeholder={t("fields.discount.placeholder")}
                                disabled={mode == "editing" && ability.cannot("discount.edit", "customer")}
                            />
                        )}
                    />
                </BasePermissionBlocker>

                <Controller
                    control={control}
                    name="payment_terms"
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}

                            options={paymentTermsOptions}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name
                            }}
                            
                            label={t("dropdowns.paymentTerms.label")}
                            placeholder={t("dropdowns.paymentTerms.placeholder")}
                            isLoading={isPaymentTermsOptionsLoading}
                            autocomplete
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="delivery_terms"
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}

                            options={deliveryTermsOptions}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name
                            }}

                            label={t("dropdowns.deliveryTerms.label")}
                            placeholder={t("dropdowns.deliveryTerms.placeholder")}
                            isLoading={isDeliveryTermsOptionsLoading}
                            autocomplete
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="reference"
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}

                            options={referencesOptions}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name
                            }}
                            
                            label={t("dropdowns.reference.label")}
                            placeholder={t("dropdowns.reference.placeholder")}
                            isLoading={isReferencesOptionsLoading}
                            autocomplete
                        />
                    )}
                />
            </BaseInputsGrid>

            <div className="flex justify-center space-x-[24px]">
                <BaseButton
                    text={t("buttons.prev")}
                    size="md"
                    buttonWidth="200px"
                    primaryOutlined
                    type={"button"}

                    onClick={onPrev}
                />
                <BaseButton
                    text={t("buttons.save")}
                    size="md"
                    buttonWidth="200px"
                />
            </div>
        </form>
    )
}
