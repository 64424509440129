import { Permission } from "@/types/general";
import AbilitiesGroup
    from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Roles/components/MutationLayout/components/abilities/AbilitiesGroup";
import {
    doesContainOptions
} from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Roles/definitions";
import BooleanAbility
    from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Roles/components/MutationLayout/components/abilities/BooleanAbility";

interface AbilityRendererProps {
    ability: Permission.Components.Ability;
    path: string;
}

export default function AbilityRenderer({ ability, path }: AbilityRendererProps) {
    if (doesContainOptions(ability)) {
        return AbilitiesGroup({ ability, path });
    } else {
        return BooleanAbility({ ability, path });
    }
}