import React from "react";
import { SwitchProps } from "@mui/material/Switch/Switch";
import { Switch } from "@mui/material";

/**
 * Extension of Switch from MUI library to provide custom design features.
 * @param switchProps original props from Switch component.
 * @constructor
 */
export default function BaseSwitch(switchProps: SwitchProps): JSX.Element{
  return(
    <Switch
      {...switchProps}
      sx={{
        padding: 0,
        height: "auto",
        width: "auto",
        "& .MuiSwitch-switchBase":{
          padding: 0,
          '&.Mui-checked': {
            color: "#fff",
            '& + .MuiSwitch-track': {
              backgroundColor: "#7556FA",
              opacity: 1,
              border: 0,
            },
            '& .MuiSwitch-thumb':{
              marginLeft: "1px"
            }
          }
        },
        '& .MuiSwitch-thumb': {
          boxShadow: 'none',
          width: "12px",
          height: "12px",
          margin: 0,
          marginTop: "3px",
          marginLeft: "3px",
        },
        '& .MuiSwitch-track':{
          width: "36px",
          height: "18px",
          borderRadius: "12px",
          backgroundColor: "#d1d5e6"
        }
      }}
    />
  )
}