import React, {useMemo} from "react";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import _ from "lodash";
import {Controller, useFormContext} from "react-hook-form";
import {ReceiptsMutationFormTyping} from "@components/Dashboard/pages/PurchasingPage/Receive/components/MutationLayout";
import {useGetModuleLocationsNestedQuery} from "@redux/features/locations/locationsApi";
import {useGetModuleProductDefaultLocationsQuery} from "@redux/features/products/productsApi";
import {locationSlimToOption} from "@helpers/utils";
import {ReactComponent as StockIcon} from "@assets/icons/sidebar/ic_packages_more.svg";

type ReceiptLocationDropdownProps = {
    product_id: number;
    index: number;
}

type LocationOptionWithQuantity = {
    quantity: number;
    store: { id: number; name: string };
    section?: { id: number; name: string }
}

export default function ReceiptLocationDropdown({product_id, index}: ReceiptLocationDropdownProps) {
    const {control} = useFormContext<ReceiptsMutationFormTyping>()

    // Determines, whether dropdown was opened at least once and therefore, whether to lazy load default locations
    const [isLazyLoadDefaultLocations, setIsLazyLoadDefaultLocations] = React.useState(false);

    // General locations list to show even the ons that product is not present on
    const {
        data: generalLocationsOptions = [],
        isLoading: isGeneralLocationOptionsLoading
    } = useGetModuleLocationsNestedQuery("receive");

    // Getting locations where the product is present "GET: /defaultLocations/{id}" route
    const {
        data: defaultLocationsOptions = [],
        isLoading: isDefaultLocationOptionsLoading,
    } = useGetModuleProductDefaultLocationsQuery({module: "receive", id: product_id}, {skip: !isLazyLoadDefaultLocations})

    // Storing combined locations options to allow using both arrays, mentioned earlier, in one dropdown
    const combinedLocationsOptions: LocationOptionWithQuantity[] = useMemo(() => {
        // Mapping default locations to object with keys by store and by section (underlying)
        const keyedDefaultLocations = _.keyBy(_.map(defaultLocationsOptions, (location) => {
            return {
                ...location,
                sections: _.keyBy(location.sections, "id")
            }
        }), "id")

        // Iterating global locations and forming locations list with quantities based on the keyedDefaultLocations
        const mergedLocations = generalLocationsOptions.map(locationSlimToOption).flat().map(loc => {
            const quantifiedLocationOption = loc;

            if (keyedDefaultLocations[loc.store.id]) {
                if (loc.section) {
                    const quantity = keyedDefaultLocations[loc.store.id].sections[loc.section?.id ?? 0]?.quantity ?? 0
                    _.set(quantifiedLocationOption, "quantity", quantity)
                } else {
                    _.set(quantifiedLocationOption, "quantity", keyedDefaultLocations[loc.store.id].quantity)
                }
            } else {
                _.set(quantifiedLocationOption, "quantity", 0)
            }

            return quantifiedLocationOption as LocationOptionWithQuantity;
        })

        return _.orderBy(mergedLocations, ["quantity", "store.name", "section.name"], ["desc", "desc", "asc", "asc"]);
    }, [generalLocationsOptions, defaultLocationsOptions])

    return (
        <>
            <Controller
                name={`receipts.${index}.location`}
                control={control}
                render={({field, fieldState}) =>
                    <BaseDropdown
                        {...field}
                        error={fieldState.error}

                        options={combinedLocationsOptions}
                        getter={{
                            label: opt => `${opt.store.name}${opt.section ? ` - ${opt.section.name}` : ""}`,
                            key: opt => opt.section ? `${opt.store.id}_${opt.section.id}` : `${opt.store.id}`,
                            renderOption: (opt, icon) => (
                                <div>
                                    <div className="grow">
                                        <span className="bold-highlight">{opt.store.name}</span>
                                        {opt.section ? <> - {opt.section.name}</> : null}
                                    </div>
                                    {
                                        _.isEqual(field.value, opt) ? icon :
                                            opt.quantity > 0 ?
                                                <div className="text-accent space-x-[4px] bg-gray-200 text-center min-w-[60px] rounded-[6px]">
                                                    <span>{opt.quantity}</span>
                                                    <StockIcon width={16} height={16}/>
                                                </div> : null
                                    }
                                </div>
                            ),
                        }}

                        filterOptions={opt => {
                            const label = `${opt.store.name}${opt.section ? ` - ${opt.section.name}` : ""}`;

                            return label + (opt.quantity > 0 ? ` !` : "");
                        }}

                        onOpen={() => {
                            if (!isLazyLoadDefaultLocations) {
                                setIsLazyLoadDefaultLocations(true);
                            }
                        }}

                        isLoading={isGeneralLocationOptionsLoading || isDefaultLocationOptionsLoading}
                        autocomplete
                        hideErrors

                        virtualize

                        customize={{
                            padding: "5px"
                        }}
                    />
                }
            />
        </>
    )
}