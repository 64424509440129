import React, { ReactElement } from "react";
import { Area, AreaChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { AreaChartItem, StrokeConfiguration } from "../types";
import { Props } from "recharts/types/component/DefaultLegendContent";

/**
 * BaseAreaChart component tries to provide maximum flexibility in configuring area chart component.
 * You can specify data parametrized with some generic *T* and component will use that *T* to create constraints
 * for strokes configuration. See more at {@link BaseAreaChartProperties}.
 */

/**
 * Define area chart component properties. Requires "data" property,
 * which is an array of {@link AreaChartItem} + strokes - an object with keys from defined <T> generic,
 * which defines {@link StrokeConfiguration} for each stroke, used in chart.
 */
interface BaseAreaChartProperties<T>{
  data: AreaChartItem<T>[],
  strokes: {[P in keyof Omit<T, "name">]: StrokeConfiguration};
  width?: number;
  height?: number;
}

/**
 * Renders custom area chart. You must specify **data** and **strokes** properties, which corresponds to
 * used data and strokes configuration to provide different colors.
 */
export default function BaseAreaChart<T>({
  data,
  strokes,
  width,
  height
                                      }: BaseAreaChartProperties<T>): JSX.Element{
  return(
    <AreaChart width={width} height={height} data={data}>
      <Legend
        verticalAlign="top"
        align="left"
        content={renderLegend}
      />
      <XAxis dataKey="name"/>
      <YAxis/>
      <CartesianGrid vertical={false}/>
      <Tooltip/>
      {
        Object.entries(strokes).map((val, index) => {
          const strokeKey: string = val[0];
          const strokeConfiguration = val[1] as StrokeConfiguration;

          return (
            <Area
              key={index}
              name={val[0].charAt(0).toUpperCase() + val[0].slice(1)}
              type="linear"
              dataKey={strokeKey}
              stroke={strokeConfiguration.color}
              fillOpacity={0.5}
              fill={strokeConfiguration.fill}
              animationDuration={250}
              dot={{ stroke: strokeConfiguration.color, strokeWidth: 2 }}
            />
          )
        })
      }
    </AreaChart>
  )
}

/**
 * Renders legend like list of inlined items combined with colorful dots.
 * @param param0 extracts **value** from payload, which equals to **name** defined at **Area** element.
 */
function renderLegend({payload}: Props): ReactElement {
  return (
    <ul className="flex gap-[24px] pb-4">
      {
        payload?.map((entry, index) => (
          <li key={index} className="font-thin">
            <span className="w-[9px] h-[9px] rounded-full inline-block align-middle mr-[8px] mt-[-2px]" style={{
              backgroundColor: entry.color
            }}></span>
            {entry.value}
          </li>
        ))
      }
    </ul>
  );
}