import React from "react";
import { Box, Stack } from "@mui/material";
import { Control, Controller, useWatch } from "react-hook-form";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import _ from "lodash";
import BaseTooltip from "@reusables/BaseTooltip";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import { PickingMutationFormTyping } from "@components/Dashboard/pages/Sales/Picking/components/MutationLayout/types";

import { ReactComponent as PlusSVG } from "@assets/icons/ic_plus.svg";
import { useTranslation } from "react-i18next";

type OutgoingLocationCellProps = {
    index: number;
    control: Control<PickingMutationFormTyping>;
    onAdd: () => void;
}
export default function OutgoingLocationCell({control, index, onAdd}: OutgoingLocationCellProps) {
    const {t} = useTranslation("", {keyPrefix: "sales.picking.general"});

    const row = useWatch({
        control,
        name: `lines.${index}.data`
    });

    if(!row)
        return null;

    if (!row?.product?.is_service) {
        return (
            <Stack
                spacing={"16px"}
            >
                {
                    row.picks?.map((pick, pickIndex) => {
                        const allowAddingLocation =
                            pickIndex === row.picks.length - 1
                            && row.picks.length < row.product.locations.length;

                        const isAlreadyPicked = row.product.quantity === row.product.already_picked_quantity;

                        return (
                            <Stack
                                key={pickIndex}
                                direction={"row"}
                                spacing={"8px"}
                                alignItems="center"
                            >
                                <Box
                                    width={"100%"}
                                    marginRight={allowAddingLocation && !isAlreadyPicked ? "0px" : "40px"}
                                >
                                    <Controller
                                        name={`lines.${index}.data.picks.${pickIndex}.outgoing_location`}
                                        control={control}
                                        render={({field, fieldState}) =>
                                            <>
                                                <BaseDropdown
                                                    {...field}
                                                    error={fieldState.error}

                                                    options={
                                                        row.product.locations.filter(location => {
                                                            const isLocationAlreadyPicked = row.picks.some((somePick, somePickIndex) => {
                                                                return pickIndex != somePickIndex && somePick.outgoing_location?.location_id === location.location_id
                                                                    && somePick.outgoing_location?.sub_location_id === location.sub_location_id;
                                                            });

                                                            return !isLocationAlreadyPicked;
                                                        })
                                                    }
                                                    getter={{
                                                        label: opt => `${opt.location_name}${opt.section_name ? ` - ${opt.section_name}` : ""}`,
                                                        key: opt => opt.sub_location_id ? `${opt.location_id}_${opt.sub_location_id}` : `${opt.location_id}`,
                                                        renderOption: (opt, icon) => (
                                                            <div>
                                                                <div
                                                                    className="grow">
                                                                                            <span
                                                                                                className="bold-highlight">{opt.location_name}
                                                                                            </span>
                                                                    {opt.section_name ? <> - {opt.section_name}</> : null}
                                                                </div>
                                                                {
                                                                    _.isEqual(field.value, opt) ? icon : null
                                                                }
                                                            </div>
                                                        )
                                                    }}

                                                    autocomplete
                                                    hideErrors

                                                    customize={{
                                                        padding: "5px 30px 5px 5px",
                                                    }}

                                                    disabled={isAlreadyPicked}
                                                />
                                            </>
                                        }
                                    />
                                </Box>
                                    <BaseTooltip
                                        title={`${t("buttons.addOutgoingLocation")}`}
                                        placement={"right"}
                                        hidden={!allowAddingLocation || isAlreadyPicked}
                                    >
                                        <BaseMaterialIconButton
                                            color={"neutral"}
                                            size={"small"}
                                            icon={<PlusSVG width={16} height={16}
                                                        color={"#686868"}/>}
                                            onClick={() => void onAdd()}
                                        />
                                    </BaseTooltip>
                            </Stack>
                        );
                    })
                }
            </Stack>
        )
    } else {
        return <></>
    }
}