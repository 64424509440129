import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BASE_API_URL } from '../config'
interface TUser {
  company_id?: number,
  created_at?: string,
  email?: string,
  id?: number,
  name?: string,
}

// ? Modify to use react redux global variables
function useAuth() {

    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState<TUser>({});
    // const [userCompany, setUserCompany] = useState<any>({});

    const token = localStorage.getItem("sanctum");

    const API_URL = import.meta.env.VITE_API_URL;

    useEffect(() => {
        setLoading(true);

        if(token) {
            axios.get(`${BASE_API_URL}/api/user`, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .then((res) => {
              // console.log("AUTH Hook:")
              // console.log(res);
              setLoggedIn(true);
              setUser(res.data);
            })
            .catch((err) => {
              localStorage.removeItem('sanctum');
              setLoggedIn(false);
              setUser({});

              setLoading(false);
            })
            .finally(() => {
              setLoading(false)
            })
          }
          else {
            setLoading(false);
          }
    }, [token]) // cleanup function had token

    return { loading, loggedIn, token, user };
}

export default useAuth;
