import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

import BaseModal from "@reusables/Modals/BaseModal";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseButton from "@reusables/BaseButton";
import BasePhoneInput from "@reusables/BasePhoneInput";

import {useCreateSupplierMutation} from "@redux/features/suppliers/suppliersApi";
import {useGetCountriesQuery} from "@redux/api/internalApiSlice";
import {getCountryFlag, removeEmpty, requiredIfAnyFilled} from "@helpers/utils";

import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {toast} from "react-toastify";
import _ from "lodash";
import {Supplier} from "@/types/general";
import {isErrorWithMessage} from "@redux/api/query";

const formScheme = z.object({
    name: z.string().nonempty(),
    code: z.string().optional(),
    billing: z.object({
        name: z.string().optional(),
        street: z.string().optional(),
        street_2: z.string().optional(),
        zipcode: z.string().optional(),
        city: z.string().optional(),
        country: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string()
        }).optional(),
        email: z.string().email().optional(),
        phone: z.string().optional(),
    }).superRefine((scheme, ctx) => {
        requiredIfAnyFilled(scheme, ["street_2"])
            .forEach(key => {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: `Required`,
                    path: [key]
                });
            })
    }),
});

type FormTyping = z.infer<typeof formScheme>

type CreateSupplierModalProperties = {
    isOpen: boolean;
    onClose: () => void;
    onCreate: (supplier: Supplier.Extended) => void;
}

export default function CreateSupplierModal({isOpen, onClose, onCreate}: CreateSupplierModalProperties): JSX.Element {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.general.modals.createSupplier"});
    // ---> Countries selection <--- //
    const {data: countriesOptions, isLoading: countriesOptionsLoading} = useGetCountriesQuery();

    const [createSupplier, {isLoading: isCreationLoading}] = useCreateSupplierMutation();

    const {control, handleSubmit, reset} = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
    });

    useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen]);

    const onSubmit = handleSubmit((data) => {
        createSupplier({
            name: data.name,
            code: data.code,
            ...(!_.isEmpty(removeEmpty(data.billing)) && {
                billing: {
                    name: data.billing.name ?? "",
                    street: data.billing.street,
                    street_2: data.billing.street_2,
                    zipcode: data.billing.zipcode,
                    city: data.billing.city,
                    country: data.billing.country?.id,
                    email: data.billing.email,
                    phone: data.billing.phone,
                    is_used_for_return: false,
                }
            })
        }).unwrap().then(sup => {
            onCreate(sup);
        }).catch(e => {
            if (isErrorWithMessage(e)) {
                toast.error(e.message);
            } else {
                toast.error(t("responses.error"));
            }
        })
    });

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={900}
            padding="56px"
            useCloseIcon
            isLoading={isCreationLoading}
        >
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="modal-title">{t('heading')}</div>

                <BaseInputsGrid gap={{x: 24, y: 40}} cols={2}>
                    <Controller
                        name="name"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.supplierName") + " *"}
                            />
                        )}
                    />

                    <Controller
                        name="code"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.supplierCode")}
                            />
                        )}
                    />
                </BaseInputsGrid>

                <BaseInputsGrid title={t("categories.address")} gap={{x: 24, y: 40}} cols={2}>
                    <Controller
                        name="billing.name"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.name")}
                            />
                        )}
                    />

                    <Controller
                        name="billing.country"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseDropdown
                                {...field}
                                {...fieldState}
                                label={t("dropdowns.country.label")}
                                placeholder={t("dropdowns.country.placeholder")}
                                options={countriesOptions}
                                isLoading={countriesOptionsLoading}
                                getter={{
                                    label: (opt) => opt.name,
                                    key: (opt) => opt.id,
                                    renderOption: (opt, icon) => (
                                        <div>
                                            <img src={getCountryFlag(opt.code)} width={20} alt={opt.code}/>
                                            <span className="ml-[8px] grow">{opt.name}</span>
                                            {icon}
                                        </div>
                                    )
                                }}

                                autocomplete
                            />
                        )}
                    />

                    <Controller
                        name="billing.street"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.street")}
                            />
                        )}
                    />

                    <Controller
                        name="billing.street_2"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.street2")}
                            />
                        )}
                    />

                    <Controller
                        name="billing.zipcode"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.zipcode")}
                            />
                        )}
                    />

                    <Controller
                        name="billing.city"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.city")}
                            />
                        )}
                    />

                    <Controller
                        name="billing.email"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.email")}
                            />
                        )}
                    />

                    <Controller
                        name={'billing.phone'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BasePhoneInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.phone")}
                                size='md'
                                variant='custom'
                            />
                        )}
                    />

                </BaseInputsGrid>

                <BaseButton
                    buttonWidth="100%"
                    text={t("buttons.create")}
                    size="md"
                    type={"submit"}
                />
            </form>
        </BaseModal>
    );
}