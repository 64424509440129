import { z } from "zod";
import dayjs, { Dayjs } from "dayjs";
import i18n from "@/i18n";

const placementScheme = z.object({
    id: z.number(),
    name: z.string(),
    quantity: z.number()
});

type PlacementTyping = z.infer<typeof placementScheme>

export const adjustmentFormScheme = z.object({
    adjustment_type: z.union([z.literal(0), z.literal(1)]),
    product: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string(),
        is_service: z.boolean(),
        prices: z.object({
            purchase_price: z.number(),
            selling_price: z.number()
        })
    }),
    date: z.instanceof(dayjs as unknown as typeof Dayjs),
    location: z.object({
        store: placementScheme,
        section: placementScheme.optional()
    }).nullish(),
    actual_quantity: z.coerce.number().min(0).optional(),
    actual_cost_price: z.coerce.number().min(0).optional(),
    remarks: z.string().nonempty()
})
    .refine((data) => {
            // Location should be required, when adjustment type = 0
            return data.adjustment_type !== 0 || !!data.location
        },
        i18n.t("general.validation.global.required")
    )
    .superRefine((data, ctx) => {
        if (data.adjustment_type === 0 && (data.actual_quantity === undefined || data.actual_quantity === null)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("general.validation.global.required"),
                path: ["actual_quantity"]
            });
        } else if (data.adjustment_type === 1 && (data.actual_cost_price === undefined || data.actual_cost_price === null)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("general.validation.global.required"),
                path: ["actual_cost_price"]
            });
        }
    });

export type AdjustmentFormTyping = z.infer<typeof adjustmentFormScheme>
