import React from 'react'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { EventInput } from '@fullcalendar/react'
import BaseUnderconstruct from "@reusables/BaseUnderconstruct";

function CalendarPage() {

    let eventGuid = 0
    const todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

    const INITIAL_EVENTS: EventInput[] = [
        {
            id: createEventId(),
            title: 'All-day event',
            start: todayStr
        },
        {
            id: createEventId(),
            title: 'Timed event',
            start: todayStr + 'T12:00:00'
        },
        {
            id: createEventId(),
            title: 'Delivery',
            start: new Date('2022,08,10').toISOString(),
            end: new Date('2022,08,12').toISOString(),
            color: '#7556FA'
        },
        {
            id: createEventId(),
            title: 'Delivery from supplier',
            start: new Date('2022,08,16').toISOString(),
            end: new Date('2022,08,20').toISOString(),
            color: '#A6DD4C'
        }
    ]

     function createEventId() {
        return String(eventGuid++)
    }

    return (
    <div className="container-lg">
        <BaseUnderconstruct/>
      {/*<span>Calendar Page</span>*/}
      {/*<FullCalendar*/}
      {/*    plugins={[dayGridPlugin,timeGridPlugin]}*/}
      {/*    initialView="dayGridMonth"*/}
      {/*    headerToolbar={{*/}
      {/*      left: 'prev,next,prevYear,nextYear',*/}
      {/*      center: 'title',*/}
      {/*      end: 'dayGridMonth,timeGridWeek,timeGridDay',*/}
      {/*    }}*/}
      {/*    editable={true}*/}
      {/*    selectable={true}*/}
      {/*    selectMirror={true}*/}
      {/*    dayMaxEvents={true}*/}
      {/*    weekends={true}*/}
      {/*    initialEvents={INITIAL_EVENTS}*/}
      {/*/>*/}
    </div>
  )
}

export default CalendarPage
