export default [
    {
        label: "CODE 128",
        key: "CODE128"
    },
    {
        label: "CODE 39",
        key: "CODE39"
    },
    {
        label: "EAN-13",
        key: "EAN13"
    },
    {
        label: "EAN-8",
        key: "EAN8"
    },
    {
        label: "EAN-5",
        key: "EAN5"
    },
    {
        label: "EAN-2",
        key: "EAN2"
    },
    {
        label: "UPC",
        key: "UPC"
    },
    {
        label: "ITF",
        key: "ITF"
    },
    {
        label: "ITF-14",
        key: "ITF14"
    },
    {
        label: "MSI",
        key: "MSI"
    },
    {
        label: "MSI-10",
        key: "MSI10"
    },
    {
        label: "MSI-11",
        key: "MSI11"
    },
    {
        label: "MSI-1010",
        key: "MSI1010"
    },
    {
        label: "MSI-1110",
        key: "MSI1110"
    },
    {
        label: "Pharmacode",
        key: "pharmacode"
    },
    {
        label: "Codabar",
        key: "codabar"
    }
];
