import { z } from "zod";
import { Permission } from "@/types/general";
import { RequiredBy } from "@helpers/utils";

// ZOD Schema
const keyScheme = z.object({
    key: z.string(),
    checked: z.boolean().optional()
});

const abilityScheme = z.object({
    options: z.record(
        z.string(),
        z.object({
            dependency: z.string().nullish()
        }).and(keyScheme)
    ).nullish(),
    dependency: z.string().nullish()
}).and(keyScheme);

export type AbilitySchemeTyping = z.infer<typeof abilityScheme>;

export const roleFormScheme = z.object({
    name: z.string(),
    description: z.string().nullish(),
    access: z.record(
        z.string(),
        z.object({
                permissions: z.record(
                    z.union([z.literal("view"), z.literal("edit"), z.literal("delete"), z.literal("create"), z.literal("download"), z.literal("manage"), z.literal("restore")]),
                    abilityScheme
                ),
                subPermissions: z.record(
                    z.string(),
                    abilityScheme
                )
            }
        ).and(keyScheme)
    )
});

export type RoleFormTyping = z.infer<typeof roleFormScheme>;

// Sections
class Collapsable {
    key: string;

    constructor(key: string) {
        this.key = key;
    }
}

export class Module extends Collapsable {
    key: Permission.Components.Modules;

    constructor(key: Permission.Components.Modules) {
        super(key);
        this.key = key;
    }
}

export class Group extends Collapsable {
    modules: Module[];

    constructor(key: string, modules: Module[]) {
        super(key);
        this.modules = modules;
    }
}

export const collapseGroupsStructure: Collapsable[] = [
    new Module("product"),
    new Group("orders", [
        new Module("purchase_order"),
        new Module("receive"),
        new Module("sale_order"),
        new Module("picking"),
        new Module("customer"),
        new Module("supplier"),
    ]),
    new Group("inventory", [
        new Module("stock"),
        new Module("stock_count"),
        new Module("adjustment"),
        new Module("collection"),
        new Module("transfer"),
    ]),
    new Module("general"),
    new Module("custom_fields"),
];

export function doesContainOptions(ability: Permission.Components.Ability): ability is RequiredBy<Permission.Components.Ability, "options"> {
    return "options" in ability;
}
