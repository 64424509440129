import React, { useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import BaseInputsGrid, { BaseInputsGridProps } from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import { useTranslation } from "react-i18next";
import { customFieldsApi } from "@redux/features/customFields/customFieldsApi";
import { CustomFieldsInjectTyping } from "@reusables/BaseCustomFieldsSection/logic";
import { Collapse } from "@mui/material";
import { Link } from "react-router-dom";
import { CustomFields } from "@/types/general";
import _ from "lodash";
import { useAppDispatch } from "@/redux/hooks";


type CFMutatorProps = {
    inputsGrid?: BaseInputsGridProps;
    module: CustomFields.Components.Modules;
    mode: "creation" | "editing";
};

/**
 * Mutator component for custom fields. It is used to inject custom fields into the form.
 * Should always be wrapped with RHF FormProvider.
 * @param props
 * @constructor
 */
export default function CFMutator(props: CFMutatorProps) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.customFields.inject" });

    const form = useFormContext<CustomFieldsInjectTyping>();

    const dispatch = useAppDispatch();

    const [isCustomFieldsListLoading, setCustomFieldsListLoading] = React.useState(false);

    const { fields, replace } = useFieldArray({
        control: form.control,
        name: "custom_fields",
        keyName: "loop_id"
    });

    /**
     * Loading custom fields on component mount and extending current fields list in RHF with the loaded ones.
     */
    useEffect(() => {
        setCustomFieldsListLoading(true);
        dispatch(customFieldsApi.endpoints.getCustomFields.initiate(
            {
                module: props.module
            },
            {
                subscribe: false
            }
        ))
            .unwrap()
            .then(cf_list => {
                // ===
                //  Here we merge current custom fields (extracted from record details) with all other custom fields,
                //  allowing for available fields preview on record editing.
                // ===
                const currentFields = form.getValues("custom_fields");

                const currentFieldsKeyedById = _.keyBy(currentFields, "id");

                const replacingFields = [
                    // Keep current fields
                    ...currentFields,
                    // Adding new fields from the available list
                    ...cf_list.filter(x => !(x.id in currentFieldsKeyedById))
                ];

                replace(replacingFields);
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => {
                setCustomFieldsListLoading(false);
            });
    }, [props.module]);

    const { inputsGrid } = props;

    return (
        <>
            <Collapse in={!isCustomFieldsListLoading || fields.length > 0}>
                {
                    fields.filter(x => !x.is_disabled).length > 0 ?
                        <div className={"space-y-[40px]"}>
                            <BaseInputsGrid
                                {...inputsGrid}
                                title={inputsGrid?.title ?? t("title")}
                            >
                                {
                                    fields.map((cf, index) => {
                                        if ((props.mode === "creation" || !cf.value) && cf.is_disabled) return null;

                                        return (
                                            <Controller
                                                key={cf.loop_id}
                                                name={`custom_fields.${index}`}
                                                control={form.control}
                                                render={({ field, fieldState }) => {
                                                    return (
                                                        <BaseInput
                                                            key={index}
                                                            label={field.value.label}
                                                            placeholder={field.value.placeholder}
                                                            value={field.value.value}
                                                            onChange={(val) => {
                                                                field.onChange({
                                                                    ...field.value,
                                                                    value: val
                                                                });
                                                            }}
                                                            error={fieldState.error}
                                                        />
                                                    );
                                                }}
                                            />
                                        );
                                    })
                                }
                            </BaseInputsGrid>
                        </div>
                        :
                        <div>
                            <Link to={"/dashboard/settings/general/custom-fields"}
                                  className={"text-[#7556FA] font-thin"}>
                                <u>{t("activateCustomFields")}</u>
                            </Link>
                        </div>
                }
            </Collapse>
        </>
    );
}