import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";
import { ExportModalFormTyping } from "@reusables/Modals/BaseExportModal";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";

interface AmountSelectorProps {
    records?: {
        /*
        * Should be specified if checkboxes selection is used for the table
         */
        selected: number;
    };
}

export default function AmountSelector(props: AmountSelectorProps) {
    const { t } = useTranslation("", { keyPrefix: "general.modals.export.fields.amount" });

    const { control } = useFormContext<ExportModalFormTyping>();
    const { field: amountSelectionField, fieldState: amountSelectionFieldState } = useController({
        control,
        name: "amount_selector"
    });

    // Options for the dropdown
    const exportOptions: { value: 0 | 1 | 2, title: string }[] = [
        {
            title: t("options.all"),
            value: 0
        },
        {
            title: t("options.selected"),
            value: 2
        }
    ];

    // Setting the initial value for better UX
    useEffect(() => {
        if (props.records && props.records?.selected > 0) {
            amountSelectionField.onChange({ selected: props.records.selected });
        } else {
            amountSelectionField.onChange({ is_all: true });
        }
    }, [props.records]);


    // Determining value, based on the current state
    let selectedValue = exportOptions[0];
    if (amountSelectionField.value?.selected) {
        selectedValue = exportOptions[1];
    }

    return (
        <>
            <BaseDropdown
                {...amountSelectionFieldState}

                label={t("label")}
                options={exportOptions}

                value={selectedValue}
                onChange={(opt) => {
                    if (opt?.value === 0) {
                        amountSelectionField.onChange({ is_all: true });
                    } else if (opt?.value === 2) {
                        amountSelectionField.onChange({ selected: props.records?.selected ?? 0});
                    }
                }}

                getter={{
                    label: opt => opt.title,
                    key: opt => opt.value,
                    renderOption: (opt, icon) => {
                        switch (opt.value) {
                            case 0:
                                return <div>
                                    <span className="grow">{opt.title}</span>
                                    {icon}
                                </div>;
                            case 2:
                                return <div>
                                                    <span className="grow">{opt.title} <span
                                                        className="opacity-50">({props.records?.selected ?? 0})</span></span>
                                    {icon}
                                </div>;
                            default:
                                return <></>;
                        }
                    }
                }}

                disableClearable
            />
        </>
    );
}