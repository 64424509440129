import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomerGroupsFormTyping, Product } from "../../types";
import { useFieldArray, useFormContext } from "react-hook-form";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import BaseTable from "@reusables/BaseTable";

import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import MutationProductModal from "./MutationProductModal";
import dayjs from "dayjs";

export default function ProductSection() {
    const {t} = useTranslation("", {keyPrefix: "customerGroups.mutation.table"});

    const [selectedProductIndex, setSelectedProductIndex] = useState<number>();
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [isAddProductModal, setIsAddProductModal] = useState<boolean>(false);

    const {control} = useFormContext<CustomerGroupsFormTyping>();

    const { fields: products, append, update, remove} = useFieldArray({
        control: control,
        name: "products",
        keyName: "loop_id"
    });

    return (
        <>
            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px] ">
                {
                    products.length ? (
                        <div className="flex flex-col space-y-[16px]">
                            <div className="flex flex-row justify-between items-center">
                                <div className="text-lg font-semibold text-accent">{t("heading")}</div>
                                <BaseMaterialButton
                                    fontSize={14}
                                    fontWeight={400}
                                    onClick={() => {
                                        setIsAddProductModal(true);
                                    }}
                                >{t("buttons.addProduct")}</BaseMaterialButton>
                            </div>

                            <div className="flex flex-col space-y-[16px]">
                                {
                                    <BaseTable
                                        data={products}
                                        immutable
                                        rowKeyGetter={(row) => row.loop_id}
                                        columns={[
                                            {
                                                header: t("columns.0"),
                                                getter: row => row?.product?.code
                                            },
                                            {
                                                header: t("columns.1"),
                                                getter: row => row?.product?.name
                                            },
                                            {
                                                header: t("columns.2"),
                                                getter: row => row.sale_price
                                            },
                                            {
                                                header: t("columns.3"),
                                                getter: row => row.valid_from?.format("DD.MM.YYYY") ?? dayjs().format("DD.MM.YYYY")
                                            },
                                            {
                                                header: t("columns.4"),
                                                getter: (row) => {
                                                    if (!row.valid_to) {
                                                        // No expiration date
                                                        return <span className='bg-[#E5E5E5] rounded-[16px] px-2 py-1 font-thin'>{t("noExpiration")}</span>;
                                                    }
                                                
                                                    const expirationDate = dayjs(row.valid_to);
                                                    const currentDate = dayjs();
                                                
                                                    // Check if expiration date is in the future
                                                    if (currentDate.isBefore(expirationDate)) {
                                                        return expirationDate.format("DD.MM.YYYY");
                                                    } else {
                                                        return (
                                                            <div className={"space-x-2"}>
                                                                <span>{expirationDate.format("DD.MM.YYYY")}</span>
                                                                    <span className='bg-[#E5E5E5] rounded-[16px] px-2 py-1 font-thin'>
                                                                    {t("expiredLabel")}
                                                                </span>
                                                            </div>
                                                        );
                                                    }
                                                }
                                            },
                                            {
                                                header: t("columns.5"),
                                                getter: (row, index) => (
                                                    <div className="flex flex-row space-x-[12px]">
                                                        <EditSVG 
                                                            className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer" 
                                                            onClick={() => {
                                                                setIsAddProductModal(true);
                                                                setSelectedProduct(row);
                                                                setSelectedProductIndex(index);
                                                            }}
                                                        />
                                                        <TrashSVG
                                                            className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer" 

                                                            onClick={() => {
                                                                remove(index)
                                                            }}
                                                        />
                                                    </div>
                                                ),
                                            }
                                        ]}

                                        size='medium'
                                        headerSx={{
                                            background: "#F9F9F9"
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    ) : (
                        <div className='flex justify-center'>
                            <div className="flex flex-column items-center justify-center space-y-[24px] h-[128px]">
                                <div className="text-2xl text-center text-gray-300 font-thin">{t("noProduct")}</div>
                                <div className='mt-[16px]'>
                                    <BaseMaterialButton
                                        onClick={() => setIsAddProductModal(true)}
                                        fontSize={14}
                                        className='w-[200px] h-[48px]'
                                    >
                                        {t("buttons.addProduct")}
                                    </BaseMaterialButton>
                                </div>
                            </div>
                        </div>
                    )
                }                    
            </div>

            <MutationProductModal
                isOpen={isAddProductModal}
                onClose={() => {
                    setSelectedProduct(undefined);
                    setSelectedProductIndex(undefined);
                    setIsAddProductModal(false);
                }}
                onProductAddition={(data) => {
                    selectedProductIndex !== undefined ? update(selectedProductIndex, data) : append(data);

                    setIsAddProductModal(false);
                    setSelectedProduct(undefined);
                    setSelectedProductIndex(undefined);
                }}
                product={selectedProduct}
            />
        </>
    )
}
