import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { DeliveryTerms } from "@/types/general";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@redux/hooks";

export const deliveryTermsApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDeliveryTerms: builder.query<DeliveryTerms.Root[], void>({
            query: () => ({
                url: `delivery-terms`
            }),
            providesTags: [{ type: "DeliveryTerms", id: "LIST" }],
            transformResponse
        }),

        getModuleDeliveryTerms: builder.query<DeliveryTerms.Root[], string>({
            query: (module) => ({
                url: `${module}/delivery-terms`
            }),
            providesTags: [{ type: "DeliveryTerms", id: "LIST" }],
            transformResponse
        }),

        createDeliveryTerms: builder.mutation<DeliveryTerms.Root, DeliveryTerms.DTO.CreateUpdate>({
            query: (body) => ({
                url: `delivery-terms`,
                method: "POST",
                data: body
            }),

            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: createdDeliveryTerms } = await queryFulfilled;
                    const createResult = dispatch(deliveryTermsApi.util.updateQueryData("getDeliveryTerms", undefined, (draft) => {
                        draft.push({
                            id: createdDeliveryTerms.id,
                            code: createdDeliveryTerms.code,
                            name: createdDeliveryTerms.name,
                            description: createdDeliveryTerms.description
                        });
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        updateDeliveryTerms: builder.mutation<DeliveryTerms.Root, DeliveryTerms.DTO.CreateUpdate & { id: number }>({
            query: ({ id, ...body }) => ({
                url: `delivery-terms/${id}`,
                method: "PUT",
                data: body
            }),
            transformResponse,
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedDeliveryTerms } = await queryFulfilled;
                    const updateResult = dispatch(deliveryTermsApi.util.updateQueryData("getDeliveryTerms", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === updatedDeliveryTerms.id);
                        if (index !== -1) {
                            draft[index] = updatedDeliveryTerms;
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        deleteDeliveryTerms: builder.mutation<void, number>({
            query: (id) => ({
                url: `delivery-terms/${id}`,
                method: "DELETE"
            }),

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    const { data: deletedDeliveryTerms } = await queryFulfilled;
                    const deleteResult = dispatch(deliveryTermsApi.util.updateQueryData("getDeliveryTerms", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === id);
                        if (index !== -1) {
                            draft.splice(index, 1);
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        })
    })
});

const selectDeliveryTermsResult = deliveryTermsApi.endpoints.getDeliveryTerms.select();

const selectAllDeliveryTerms = createSelector(
  selectDeliveryTermsResult,
  (deliveryTerms) => deliveryTerms?.data ?? []
);

export const selectDeliveryTermsById = createSelector(
  selectAllDeliveryTerms,
  (state: RootState, dt_id: number | undefined) => dt_id,
  (deliveryTerms, dt_id) => dt_id != undefined ? deliveryTerms.find(pt => pt.id === dt_id) : undefined
);

export const {
    useGetDeliveryTermsQuery,
    useGetModuleDeliveryTermsQuery,
    useCreateDeliveryTermsMutation,
    useUpdateDeliveryTermsMutation,
    useDeleteDeliveryTermsMutation
} = deliveryTermsApi;