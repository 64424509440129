import React from "react";
import {Link} from "react-router-dom";

export function BaseInputLikeContainer({label, value, linkValue}: {
    label: string,
    value: string | number | JSX.Element | undefined,
    linkValue?: string
}) {
    return (
        <div>
            <div className="font-semibold text-inputs-label-dim mb-[24px]">{label}</div>
            {
                linkValue ?
                    <Link to={linkValue} className={"text-accent text-decoration-underline"}>{value}</Link>
                    :
                    <div className="text-accent">
                        {value}
                    </div>
            }
        </div>
    );
}