import { PaletteMode } from "@mui/material";

interface ThemeCategories{
    textAccent: string,
    datepicker: {
      activeDayBackground: string;
    },
    modal: {
        icon: string,
    },
    purple: {
        100: string,
        400: string,
        500: string
    },
    green: {
        100: string
    },
    red: {
        100: string
    },
    blue:{
        900: string
    },
    gray: {
        50: string,
        100: string,
        200: string,
        300: string,
        400: string;
        600: string
    },

    modalBackdrop: string;
}

// Configuration to add types to "styled()" utility
declare module '@mui/material/styles'{
    interface Theme{
        custom: ThemeCategories
    }

    interface ThemeOptions{
        custom?: Partial<ThemeCategories>
    }
}

export default function getThemePalette(mode: PaletteMode){
    if(mode == "light"){
        return {
            custom: {
                datepicker: {
                    activeDayBackground: "#A6DD4C"
                },
                modal: {
                    icon: "#3C3769",
                },
                textAccent: "#3C3769",
                purple: {
                    100: "#D9DAFD",
                    400: "#7556FA",
                    500: "#6959FC"
                },
                blue:{
                    900: "#3C3769"
                },
                green: {
                    100: "#C8E98E",
                },
                red: {
                    100:  "#f5b5b5"
                },
                gray: {
                    50: "rgba(196, 196, 196, 0.11)",
                    100: "#F3F2EF",
                    200: "#e2e8f0",
                    300: "#D9D6DA",
                    400: "#E5E5E5",
                    600: "#686868"
                },
                modalBackdrop: "#252525",
            }
        }
    }else{ // if theme is "dark"
        return{
            custom: {
                grayDefault: "red",
                grayWeak: "rgba(196, 196, 196, 0.11)",
                grayHover: "rgba(196, 196, 196, 0.22)"
            }
        }
    }
}

