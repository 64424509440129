import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import { Plus } from "lucide-react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CustomerGroupsFormTyping } from "../../types";
import CustomerDropdown from "./CustomerDropdown";

export default function CustomerSection() {
    const {t} = useTranslation("", {keyPrefix: "customerGroups.mutation.dropdowns.customer"});

    const {control} = useFormContext<CustomerGroupsFormTyping>();

    const { fields: customers, append, remove} = useFieldArray({
        control: control,
        name: "customers",
        keyName: "loop_id"
    });

    useEffect(() => {
        if (customers.length === 0)
            append({} as any);
    }, [customers]);

    return (
        <>
            <div className='relative'>
                <div className='absolute right-0'>
                    <BaseMaterialIconButton
                        icon={<Plus />}
                        onClick={() => append(undefined as any)}
                    />
                </div>
            </div>
            
            <BaseInputsGrid title={t("title")}>
                {
                    customers.map((customer, index) => (
                        <CustomerDropdown
                            key={customer.loop_id}
                            control={control}
                            index={index}
                            onRemove={() => remove(index)}
                        />
                    ))
                }
            </BaseInputsGrid>
        </>
  )
}
