import { PaletteMode } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const themeSlice = createSlice({
    name: "themeSlice",
    initialState: {
      theme: "light" as PaletteMode
    },
    reducers: {
      setTheme: (state, action: PayloadAction<PaletteMode>) => {
        state.theme = action.payload;
      }
    }
});

export const {setTheme} = themeSlice.actions; 
export default themeSlice.reducer;

