import React from "react";
import { useAppDispatch } from "@redux/hooks";
import { useExportPurchaseOrdersMutation } from "@redux/features/purchases/purchasesApi";
import BaseExportModal from "@reusables/Modals/BaseExportModal";
import { performExport } from "@redux/features/importExport/export/thunks";
import { adaptToExportRequest, BaseExportModalPropsProvider } from "@reusables/Modals/BaseExportModal/utils";
import { useGetExportableColumnsQuery } from "@redux/features/importExport/importExportApi";

type PurchaseOrdersExportModalProps = BaseExportModalPropsProvider;

export default function PurchaseOrdersExportModal(props: PurchaseOrdersExportModalProps) {
    const dispatch = useAppDispatch();

    const [exportQuery] = useExportPurchaseOrdersMutation();

    const {
        data: columns = [],
        isLoading: isColumnsLoading
    } = useGetExportableColumnsQuery({ module: "purchase_order" });

    return (
        <BaseExportModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            onSubmit={(data) => {
                dispatch(
                    performExport("purchase_order", () => {
                        return exportQuery(adaptToExportRequest(props, data)).unwrap();
                    })
                );
            }}
        >
            <BaseExportModal.FiltersInheritanceWarning />
            <BaseExportModal.Email />
            <BaseExportModal.ColumnsSelector columns={columns} isLoading={isColumnsLoading} />
            <BaseExportModal.ConcurrencyWarning />
            <BaseExportModal.Confirm />
        </BaseExportModal>
    );
}