import React, { PropsWithChildren } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ProductSchemeTyping } from "@components/Dashboard/pages/Products/components/CreationEditingLayout/types";
import { cn } from "@helpers/utils";

export default function ServiceHider({ children, className }: PropsWithChildren<{ className?: string }>) {
    const { control } = useFormContext<ProductSchemeTyping>();

    const isService = useWatch({
        name: "is_service",
        control
    });

    return <div className={cn(className, isService && "hidden")}>{children}</div>;
}
