import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import BaseInput from "@reusables/BaseInputOld";
import BaseButton from "@reusables/BaseButton";
import IcTrash from "@assets/images/icons/NewIcons/trash-simple.svg";

import {
  getLocationById,
  createLocation,
  updateLocationById,
} from "./services";
import { toast } from "react-toastify";

type SubLocation = {
  row: string;
  sector: string;
  height: string;
};

type Location = {
  number: string;
  name: string;
  country: string;
  city: string;
  street: string;
  postal: string;
  subLocation: SubLocation[];
};

const initialValuesLocation: Location = {
  number: "",
  name: "",
  country: "",
  city: "",
  street: "",
  postal: "",
  subLocation: [],
};
const initialValuesSubLocation: SubLocation = {
  row: "",
  sector: "",
  height: "",
};

function AddLocationPage() {
  // routing
  const history = useHistory();
  const params: any = useParams();
  const { id, isEdit } = useMemo(
    () => ({
      id: params?.id,
      isEdit: Boolean(params?.id),
    }),
    [params]
  );

  const [formLoading, setFormLoading] = useState<boolean>(false);

  //* form inputs
  // --- general
  const [values, setValues] = useState<Location>(initialValuesLocation);
  // form buttons
  const [createAndNew, setCreateAndNew] = useState<boolean>(false);

  const handleAddSubLocation = () => {
    setValues((prev) => {
      const newSubLocations = [...prev.subLocation];
      newSubLocations.push({ ...initialValuesSubLocation });
      return { ...prev, subLocation: newSubLocations };
    });
  };

  const handleRemoveSubLocation = (index: number) => {
    setValues((prev) => {
      const newSubLocations = [...prev.subLocation];
      newSubLocations.splice(index, 1);
      return { ...prev, subLocation: newSubLocations };
    });
  };

  //* form submission
  const handleFormSubmit = () => {
    // event: React.FormEvent<HTMLFormElement>
    // event.preventDefault();

    setFormLoading(true);
    const body: Record<string, any> = values;

    body.has_sub_location = values?.subLocation?.length ? true : false;

    const handleSubmit = isEdit
      ? updateLocationById(id, body)
      : createLocation(body);

    handleSubmit
      .then(() => {
        toast.success(`${isEdit ? "Update" : "Create"} location successfully`);
        redirectToLocationPage();
      })
      .catch((error) => {
        toast.error(
          `Failed to ${isEdit ? "Update" : "Create"} location: ${error}`
        );
      })
      .finally(() => {
        setFormLoading(false);
      });
  };

  const reloadPage = () => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    history.go(0);
  };

  const redirectToLocationPage = () => {
    history.push(`/dashboard/settings/general/locations`);
  };

  const _renderLocationField = (
    key: keyof Omit<Partial<Location>, "subLocation">
  ) => (
    <BaseInput
      label={key}
      value={values[key]}
      onChange={(e) =>
        setValues((prev) => ({ ...prev, [key]: e.target.value }))
      }
    />
  );

  const _renderSublocationForm = (item: any, i: any) => {
    const handleItemChange = (key: keyof SubLocation, value: any) => {
      setValues((prev) => {
        const newSubLocations = [...prev.subLocation];
        newSubLocations[i][key] = value;
        return { ...prev, subLocation: newSubLocations };
      });
    };

    const handleItemRemove = () => handleRemoveSubLocation(i);

    // TODO: [nekear] improve responsiveness of those elements
    return (
      <div className="flex items-center space-x-4" key={i}>
        <div className="grid gap-x-10 gap-y-10 xl:grid-cols-3 md:grid-cols-3 xs:grid-cols-1">
          <BaseInput
            label="Section Number"
            value={item.row}
            onChange={(e) => handleItemChange("row", e.target.value)}
          />
          <BaseInput
            label="Sector"
            value={item.sector}
            onChange={(e) => handleItemChange("sector", e.target.value)}
          />
          <BaseInput
            label="Shelf Height"
            value={item.height}
            onChange={(e) => handleItemChange("height", e.target.value)}
          />
        </div>

        <div className="w-5 h-5 mt-3 cursor-pointer" onClick={handleItemRemove}>
          <img src={IcTrash} />
        </div>
      </div>
    );
  };

  const initialize = async () => {
    const { res, error } = await getLocationById(id);
    if (!error) setValues(res);
  };

  useEffect(() => {
    isEdit && initialize();
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <div className="custom-card container">
        <div style={{ marginBottom: "40px" }}>
          <span className="text-lg-semibold color-4">Create new location</span>
        </div>

        <div>
          <div>
            <fieldset className="mb-5">
              <div className="grid gap-x-10 gap-y-10 xl:grid-cols-3 md:grid-cols-2 xs:grid-cols-1">
                {Object.keys(initialValuesLocation)
                  ?.filter((key) => key != "subLocation")
                  ?.map((value: any) => _renderLocationField(value))}
              </div>
            </fieldset>

            {/* Identifiers */}
            <div className="d-flex justify-content-between">
              <fieldset className="mb-5">
                <legend className="text-lg-light color-4 mb-3">
                  Sub Location
                </legend>
              </fieldset>
              <div
                className="text-[#7556FA] cursor-pointer"
                onClick={handleAddSubLocation}
              >
                + Create Sub Location
              </div>
            </div>

            <div className="flex flex-col space-y-4">
              {values?.subLocation?.map(_renderSublocationForm)}
            </div>

            <div
              className="d-flex justify-content-center mt-5 mb-2"
              style={{ columnGap: "40px" }}
            >
              {isEdit && (
                <BaseButton
                  text="Cancel"
                  size="sm"
                  buttonWidth="200px"
                  height="48px"
                  primaryOutlined
                  onClick={redirectToLocationPage}
                />
              )}

              <BaseButton
                text={isEdit ? "Save Changes" : "Create"}
                size="sm"
                buttonWidth="200px"
                height="48px"
                onClick={handleFormSubmit}
                loading={formLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddLocationPage;
