import { flattenPaginationRequest, internalApiSlice, PaginationRequest, PaginationResponse, transformResponse } from "@redux/api/internalApiSlice";
import { Receive } from "@/types/general";

export const receiptsApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getReceiptsFull: builder.query<PaginationResponse<Receive.Extended>, PaginationRequest<Receive.DTO.Filters, Receive.DTO.OrderBy>>({
            query: (req) => ({
                url: "receive",
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: ["date"],
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({ id }) => ({ type: 'Receive' as const, id })),
                        { type: 'Receive', id: 'PARTIAL-LIST' },
                    ]
                    : [{ type: 'Receive', id: 'PARTIAL-LIST' }],
        }),

        getModuleReceiptsFull: builder.query<PaginationResponse<Receive.Extended>, PaginationRequest<Receive.DTO.Filters, Receive.DTO.OrderBy> & { module: string }>({
            query: ({ module, ...req }) => ({
                url: `${module}/receive`,
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: ["date"],
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({ id }) => ({ type: 'Receive' as const, id })),
                        { type: 'Receive', id: 'PARTIAL-LIST' },
                    ]
                    : [{ type: 'Receive', id: 'PARTIAL-LIST' }],
        }),

        deleteReceipt: builder.mutation<void, number>({
            query: (id) => ({
                url: `receive/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, id) => [{ type: "Receive", id }]
        }),

        createReceipt: builder.mutation<Receive.Extended, Receive.DTO.Create>({
            query: (body) => ({
                url: `receive`,
                method: "POST",
                data: body
            }),
            invalidatesTags: (result, error, body) => [
                { type: "Receive", id: "PARTIAL-LIST" },
                "Stock", "Picking", "PurchaseOrder", "Product", "SaleOrder"
            ]
        }),

        getReceipt: builder.query<Receive.Extended, number>({
            query: (id) => ({
                url: `receive/${id}`,
                method: "GET",
                parseDates: ["date", "expiration_date"],
            }),
            transformResponse,
            providesTags: (result, error, id) => [{ type: "Receive", id }],
        }),
    })
});

export const {
    useDeleteReceiptMutation,
    useCreateReceiptMutation,
    useGetReceiptQuery,
    useGetReceiptsFullQuery,
    useGetModuleReceiptsFullQuery,
} = receiptsApi;