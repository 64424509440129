import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ExportModalFormTyping } from "@reusables/Modals/BaseExportModal";
import { genT, PredefinedTranslations } from "@helpers/utils";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";

export interface Column<ColumnsList extends string> {
    key: ColumnsList;
    title?: string;
}

interface ColumnsSelectorProps<ColumnsList extends string> {
    columns: Column<ColumnsList>[];
    isLoading?: boolean;
}

export default function ColumnsSelector<ColumnsList extends string>(props: ColumnsSelectorProps<ColumnsList>) {
    const { t } = useTranslation("", { keyPrefix: "general.modals.export.fields.columns" });

    const { control } = useFormContext<ExportModalFormTyping>();

    return (
        <Controller
            control={control}
            name={"columns"}
            render={({ field, fieldState }) => (
                <BaseDropdown
                    {...field}
                    {...fieldState}

                    onChange={(_, opts) => field.onChange(opts)}

                    label={t("label")}
                    options={props.columns}

                    getter={{
                        key: opt => opt.key,
                        label: opt => opt.title ?? opt.key
                    }}

                    autocomplete
                    multiple

                    emptyValue={genT(PredefinedTranslations.DropdownsALL)}
                    isLoading={props.isLoading}
                />
            )}
        />
    );
}