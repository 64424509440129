import BaseCheckbox from '@reusables/BaseCheckbox'
import BaseInput from '@reusables/BaseInput'
import React from 'react'
import {Controller, useFormContext, useWatch} from 'react-hook-form'
import {PurchaseOrdersFormTyping} from '../../../types';
import {useTranslation} from 'react-i18next';

export default function ShippingCostInput() {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.general"});
    const {control, setValue, watch} = useFormContext<PurchaseOrdersFormTyping>();

    const currency = useWatch({
        control,
        name: "currency"
    });

    return (
        <div className="space-y-[18px]">
            <Controller
                name="shipping_cost"
                control={control}
                render={({field, fieldState}) => (
                    <>
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.shippingCost.label")}

                            sx={{
                                "& .MuiInputBase-input": {
                                    paddingRight: "30px"
                                }
                            }}

                            icon={{
                                right: {
                                    el: <span className={"text-purple-500"}>{currency?.symbol}</span>,
                                    offset: "10px"
                                }
                            }}
                        />
                    </>
                )}
            />
            <Controller
                name="is_split_by_products"
                control={control}
                render={({field, fieldState}) => (
                    <>
                        <BaseCheckbox
                            {...field}

                            label={t("fields.shippingCost.checkboxes.splitByProducts")}
                        />
                    </>
                )}
            />
        </div>
    )
}
