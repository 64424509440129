import React, { useState } from 'react'
import {ReactComponent as HeaderLogo} from "@assets/images/logo-registration.svg";
import {ReactComponent as EmailVerify} from "@assets/images/email_verify.svg";

import BaseButton from '@reusables/BaseButton';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useVerificationEmailMutation } from '@redux/features/auth/authApi';
import { toast } from 'react-toastify';
import { ResendTimer } from './parts/ResendTimer';
import { isErrorWithMessage } from '@redux/api/query';


export default function VerifyEmailPage() {
    const {t} = useTranslation("", {keyPrefix: "verifyEmailPage"});

    const history = useHistory();

    const location = useLocation<{email: string}>();

    const email = location.state.email;

    const [resendEmail, {isLoading: resendEmailLoading}] = useVerificationEmailMutation();

    const [showTimer, setShowTimer] = useState(false);

    const handleResendEmail = () => {
        resendEmail({ email: email })
            .unwrap()
            .then(() => {
                toast.success(t("responses.success"));
                setShowTimer(true);
            })
            .catch((error) => {
                if (isErrorWithMessage(error)) {
                    toast.error(error.message);
                } else {
                    toast.error(t("responses.error"));
                }
            });
    };

    return (
        <div className="min-h-screen flex justify-center items-center">
            <div className="flex flex-col items-center px-2 space-y-[96px]">
                <div>
                    <HeaderLogo className='h-12 cursor-pointer' onClick={
                        () => history.push('/login')
                    }/>
                </div>
                <div className="flex justify-center max-w-screen-md">
                    <EmailVerify className="h-full w-full xl:w-1/2 md:w-1/2 object-contain"/>
                </div>
                <div className="flex flex-col items-center">
                    <h2 className="m-0 text-2xl font-bold text-center text-accent">{t("heading")}</h2>
                    <p className="text-center m-0 mt-3">{email}</p>
                    <BaseButton
                        text={t("resend")}
                        size="md"
                        onClick={handleResendEmail}
                        className="mt-[40px]"
                        loading={resendEmailLoading}
                        disabled={showTimer || resendEmailLoading}
                    />
                    {showTimer && (
                        <ResendTimer
                            duration={300}
                            onTimerEnd={() => setShowTimer(false)}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
