enum ImportProgressStatus {
    IDLE = "idle",
    ACTIVE = "active",
    FINISHED = "finished",
}

interface ImportStateBase {
    status: ImportProgressStatus;
    module?: string;
}

interface ImportStateIdle extends ImportStateBase {
    status: ImportProgressStatus.IDLE;
}

interface ImportStateActive extends ImportStateBase {
    status: ImportProgressStatus.ACTIVE;
    progress?: { total: number; current: number }[];
}

interface ImportStateFinished extends ImportStateBase {
    status: ImportProgressStatus.FINISHED;
    has_errors: boolean;
    data?: any;
}

type ImportState = ImportStateIdle | ImportStateActive | ImportStateFinished;

function isImportStateActive(state: ImportState): state is ImportStateActive {
    return state.status === ImportProgressStatus.ACTIVE
}

function isImportStateFinished(state: ImportState): state is ImportStateFinished {
    return state.status === ImportProgressStatus.FINISHED;
}

/**
 * A utility selector to get the mean progress of all active imports
 * @param state active import object
 */
const selectProgressMean = (state: ImportStateActive) => {
    const { progress } = state;

    if (!progress?.length) return 0;

    const totalProgress = progress.reduce((sum, p) => sum + p.current, 0);
    const totalTotal = progress.reduce((sum, p) => sum + p.total, 0);
    return totalTotal === 0 ? 0 : totalProgress / totalTotal;
};

export { isImportStateActive, isImportStateFinished, selectProgressMean, ImportProgressStatus };
export type { ImportState, ImportStateActive, ImportStateFinished };