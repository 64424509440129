import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {FormControl, FormHelperText} from "@mui/material";
import {FieldError} from "react-hook-form";
import BoxUploadingArea from "../uploadings/BoxUploadingArea";
import { cn } from "@helpers/utils";

interface BaseImageUploaderProps {
    value?: File;
    onChange: (file: File) => void;
    size?: {
        width: number;
        height: number;
    };
    disabled?: boolean;
    error?: FieldError;
}

export default forwardRef<HTMLDivElement, BaseImageUploaderProps>(function BaseImageUploader({
    error,
    onChange,
    value,
    disabled,
    size = {width: 88, height: 88} // Default size
}, ref) {
    const popperElRef = useRef<HTMLDivElement>(null);
    const [imagePreview, setImagePreview] = useState<string>();

    useEffect(() => {
        // Removing image preview if value is undefined
        if (!value) {
            setImagePreview(undefined);
            return;
        }

        // Creating a new file reader to read the file as a data URL and avoid unnecessary re-renders
        setImagePreview(URL.createObjectURL(value));
    }, [value]);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImagePreview(e.target?.result as string); // Set the data URL as the image preview
                onChange(file);
            };
            reader.readAsDataURL(file); // Read the file as a data URL
        }
    }, [onChange]);

    useImperativeHandle(ref, () => {
        return popperElRef.current as unknown as HTMLDivElement;
    }, [popperElRef]);

    return (
        <FormControl ref={popperElRef} error={!!error} fullWidth>
            {!imagePreview && (
                <BoxUploadingArea onDrop={onDrop} disabled={disabled} size={size}/>
            )}

            {imagePreview && (
                <>
                    <div
                        className={cn(`flex flex-column justify-center w-[${size.width}px] h-[${size.height}px] !border-dashed border-gray-300 rounded-[8px] relative`,
                        value ? "border-0" : "border-[1.5px]")}
                    >
                        <div
                            className="h-full w-full rounded-[8px]"
                            style={{
                                backgroundImage: `url(${imagePreview})`,
                                backgroundPosition: 'center', // Centers the image within the div
                                backgroundSize: 'contain', // Ensures the image is fully visible within the div
                                backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                            }}
                        ></div>
                    </div>
                </>
            )}

            {error && (
                <FormHelperText sx={{marginLeft: 0}}>{error.message}</FormHelperText>
            )}
        </FormControl>
    );
});