import React, { useState } from "react";
import EditingLayout from "../components/EditingLayout";
import { useTranslation } from "react-i18next";
import { useCreateAdjustmentMutation } from "@redux/features/adjustments/adjustmentsApi";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import SellingPriceSuggestionModal
    from "@components/Dashboard/pages/Inventory/Adjustments/CreationPage/SellingPriceSuggestionModal";
import { isErrorWithMessage } from "@redux/api/query";
import { Can, useAbility } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function AdjustmentsCreationPage() {
    const history = useHistory();

    const ability = useAbility();

    const cannotCreateWithQuantity = ability.cannot('with_quantity.create', 'adjustment');
    const cannotCreateWithPrice = ability.cannot('with_purchase_price.create','adjustment');
    const canCreateWithQuantity = ability.can('with_quantity.create', 'adjustment');
    const canCreateWithPrice = ability.can('with_purchase_price.create', 'adjustment');

    const { t } = useTranslation("", { keyPrefix: "inventory.adjustments.createAdjustment" });

    const [createAdjustment, { isLoading }] = useCreateAdjustmentMutation();

    const [isSuggestionModalOpen, setIsSuggestionModalOpen] = useState(false);
    const [suggestionModalData, setSuggestionModalData] = useState<{
        selling_price: number;
        purchase_prices: {
            old: number;
            new: number;
        };
        onUpdate: (value: number) => void;
        onDecline?: () => void;
    }>();

    return (
        <>
            {cannotCreateWithQuantity && cannotCreateWithPrice && (
                <div className="levitation-extended">
                    <NoPermissionBanner />
                </div>
            )}

            {(canCreateWithQuantity || canCreateWithPrice) && (
                <BaseLoadingBlocker active={isLoading}>
                    <div className="levitation-extended">
                        <div className="flex mb-[40px]">
                            <h6 className="text-accent grow">{t("heading")}</h6>
                        </div>
                        <EditingLayout
                            adjustment={undefined}
                            buttonText={t("button")}
                            onSubmit={data => {
                                // Function to handle the creation of the adjustment, which will be later reused in different scenarios
                                const handleAdjustmentCreation = (selling_price_change?: number) => {
                                    createAdjustment({
                                            product_id: data.product.id,
                                            adjustment_type: data.adjustment_type,
                                            changed_value: data.adjustment_type === 0 ? data.actual_quantity ?? 0 : data.actual_cost_price ?? 0,
                                            ...(!!data.location && data.adjustment_type === 0 && {
                                                location: {
                                                    store_id: data.location.store.id,
                                                    ...(data.location.section?.id && {
                                                        section_id: data.location.section.id
                                                    })
                                                }
                                            }),
                                            remarks: data.remarks,

                                            ...(!!selling_price_change && data.adjustment_type === 1 && {
                                                updated_selling_price: selling_price_change
                                            })
                                        }
                                    )
                                        .unwrap()
                                        .then(() => {
                                            toast.success(t("responses.success"));

                                            history.push("/dashboard/inventory/adjustments");
                                        }).catch(e => {
                                        if (isErrorWithMessage(e)) {
                                            toast.error(e.message);
                                        } else {
                                            toast.error(t("responses.error"));
                                        }
                                    });
                                };


                                // Handling creation:
                                // 1. -> If the adjustment type is 1 (cost price change), we need to show a modal with the suggestion (which can be declined)
                                // 2. -> If the adjustment type is 0 (quantity change), we can proceed with the creation
                                if (data.adjustment_type === 1) {
                                    setSuggestionModalData({
                                        selling_price: data.product.prices.selling_price,
                                        purchase_prices: {
                                            old: data.product.prices.purchase_price,
                                            new: data.actual_cost_price ?? 0
                                        },
                                        onUpdate: (value) => {
                                            handleAdjustmentCreation(value);
                                        },
                                        onDecline: () => {
                                            handleAdjustmentCreation();
                                        }
                                    });
                                    setIsSuggestionModalOpen(true);
                                } else {
                                    handleAdjustmentCreation();
                                }
                            }}
                        />
                    </div>
                </BaseLoadingBlocker>
            )}

            <SellingPriceSuggestionModal
                isOpen={isSuggestionModalOpen}
                onClose={() => void setIsSuggestionModalOpen(false)}
                isLoading={isLoading}
                selling_price={suggestionModalData?.selling_price}
                purchase_prices={suggestionModalData?.purchase_prices}
                onUpdate={suggestionModalData?.onUpdate}
                onDecline={suggestionModalData?.onDecline}
            />
        </>
    );
}