import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import BaseButton from '@reusables/BaseButton';
import BaseInput from '@reusables/BaseInputOld';

function UsersSettingPage() {

  const history = useHistory();

  const redirectToAddUser = ():void => {
    history.push('/dashboard/settings/general/users/add')
  }
  
  return (
    <div className="form-card">
      <div className="d-flex justify-content-between">
        <div>
          <span className="text-lg-semibold color-4">Users</span>
        </div>

        <div>
          <BaseButton 
            text="Add new"
            textColor="white"
            size="sm"
            icon="add"
            iconPos="left"
            height="45px"
            onClick={redirectToAddUser}
          />
        </div>
      </div>

      <div>
        Users table will be added here
      </div>
    </div>
  )
}

export default UsersSettingPage;