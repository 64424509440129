import React from "react";
import { Permission } from "@/types/general";
import { RequiredBy } from "@helpers/utils";
import BooleanAbility
    from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Roles/components/MutationLayout/components/abilities/BooleanAbility";
import _ from "lodash";

interface AbilitiesGroupProps {
    ability: RequiredBy<Permission.Components.Ability, "options">;
    path: string;
}

export default function AbilitiesGroup({ ability, path }: AbilitiesGroupProps) {
    return (
        <div className={"pt-[24px]"} style={{ borderTop: "1px solid #F3F2EF" }}>
            <div className={"font-[400] text-[16px] text-[#686868] mb-[16px]"}>{ability.label}</div>
            <div className={"flex flex-row space-x-2"}>
                {
                    _.values(ability.options).map((option, index) => (
                        <BooleanAbility ability={option} path={path + ".options." + option.key} key={index} />
                    ))
                }
            </div>
        </div>
    );

}