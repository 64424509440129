import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

import NavigationBar from "../NavigationBar";
import AboutBlock from "../AboutPage/AboutBlock";
import FooterBlock from "../FooterBlock";

import "@assets/stylesheets/inc/_sidebar.scss";
import "@assets/stylesheets/main/_section-1.scss";

// image imports
// import ImDashboard from "@assets/images/im_dashboard.webp";
import ImDashboard from "@assets/images/im_dashboard_new.webp";

// logos
import Confirm from "@assets/images/confirm.svg";

// product slide images
// import Slide1 from "@assets/images/homepage-images/slide-01.png";
import LogoSmSvg from "@assets/images/logo-sm.svg";
// import Slide1Jpg from "@assets/images/slide-01.jpg";
import ProductBgSvg from "@assets/images/product-bg.svg";

import Slide01Png from "@assets/images/im_product_1.jpg";
import Slide02Png from "@assets/images/im_product_2.jpg";
import Slide03Png from "@assets/images/im_product_3.jpg";

// pricing checkmark icon
import Pricing from "../section/Pricing";
import BaseButton from "@reusables/BaseButton";
import Benefit from "./components/Benefit";
import { useHistory } from "react-router-dom";
import BaseModal from "@reusables/Modals/BaseModal";
import { Input } from "@mui/material";
import BaseCheckbox from "@reusables/BaseCheckboxLegacy";

const HomePage: React.FC = () => {
  const { t, i18n } = useTranslation();

  // * For later integration with strapi
  // useEffect(() => {
  //   axios
  //     .get("http://localhost:1337/api/home-page?select=[welcomeBlock]")
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => console.error(err.message));
  // }, []);

  const history = useHistory();

  const [isOpen, openModal] = useState<true | false>();

  const [checked, setChecked] = useState<true | false>();

  const [submit, setSubmit] = useState<boolean>(false);

  return (
    <div className="overflow-hidden">
      <NavigationBar />
      <main className="main flex flex-col space-y-[100px] tablet:space-y-[200px]">
        <div className="section">
          <BaseModal
            isOpen={isOpen}
            onClose={() => {
              openModal(false)
              setSubmit(false)
            }}
            width={!submit ? 920 : 1096}
            height={!submit ? "" : 439}
            padding="56px"
            useCloseIcon
            sx={{
              "& .MuiDialogContent-root" : {
                backgroundColor: submit ? "#8850F4" : "#fff"
              }
            }}
          >
            {!submit ?
              <>
                <h1 className="text-[#A6DD4C] text-[32px] border-solid border-1 mb-20 rounded-3xl border-[#A6DD4C] text-center p-2 w-[100%] sm:w-[415px] mr-auto ml-auto">Start 30-Days Free Trial</h1>
                <form>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                    <div>
                      <label className="mb-2 font-[600] text-[16px] text-[#686868]">{t('settings.general.myAccount.tabs.profile.fields.firstName')}</label>
                      <Input className="mb-6 bg-[#F9F9F9] material-no-border" />
                      <label className="mb-2 font-[600] text-[16px] text-[#686868]">{t('settings.general.companyInformation.tabs.companyDetails.fields.companyName')}</label>
                      <Input className="mb-8 bg-[#F9F9F9] material-no-border" />
                    </div>
                    <div>
                      <label className="mb-2 font-[600] text-[16px] text-[#686868]">{t('settings.general.myAccount.tabs.profile.fields.lastName')}</label>
                      <Input className="mb-6 bg-[#F9F9F9] material-no-border" />
                      <label className="mb-2 font-[600] text-[16px] text-[#686868]">{t('settings.general.companyInformation.tabs.companyDetails.fields.email')}</label>
                      <Input className="border-hidden border-none mb-8 bg-[#F9F9F9] material-no-border" />
                    </div>
                  </div>
                  <BaseCheckbox className="font-[300] text-[16px] text-[#3C3769] mb-20" checked={checked} onChange={() => setChecked(checked === true ? false : true)} label={"I accept the Terms and Conditions"} />
                  <BaseButton
                    text="Submit"
                    size="md"
                    buttonWidth="300px"
                    height="56px"
                    className="mr-auto ml-auto"
                    onClick={() => setSubmit(true)}
                  />
                </form></> :
                <div className="h-[327px] w-min rounded-xl"><div className="min-h-[100%] min-w-[100%] flex my-auto">
                  <img src={Confirm} />
                  <div className="ml-[80px] text-[#FFFFFF] my-auto">
                    <h1 className="font-[700] text-[44px] leading-[55.88px] text-[#FFFFFF] mr-auto ml-auto w-[559px] mb-[24px]">You're One Step Closer to WMS Excellence!</h1>
                    <p className="font-[400] text-[16px] leading-[32.8px] w-[528px]">Congratulations on taking the first step towards warehouse management success. Keep an eye on your inbox within the next <b>3 working days</b> for your free trial details.</p>
                    <div className="flex gap-x-[24px] mt-[24px]">
                      <button className="w-[180px] h-[52px] rounded-lg border-none font-[600] text-[16px] leading-[24px] bg-[#C8E98E] text-[#3C3769]" onClick={() => window.location.href = "/news"}>Explore the Blog</button>
                      <button className="w-[180px] h-[52px] rounded-lg border-[#C8E98E] border-1 border-solid shadow-none bg-transparent text-[#C8E98E] font-[600] text-[16px] leading-[24px]" onClick={() => window.location.href = "/partners"}>View Partners</button>
                    </div>
                  </div>
                </div>
              </div>}
          </BaseModal>
          <div className="max-tablet:p-8 container-lg">
            <div className="flex flex-col tablet:flex-row tablet:items-center">
              <div className="tablet:min-w-[488px] max-w-[468px] space-y-8">
                <div>
                  <svg
                    width="85"
                    height="18"
                    viewBox="0 0 85 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.31445 16.6711L9.28113 4.73778C10.0512 3.19773 12.2489 3.19773 13.0189 4.73778L17.1167 12.9334C17.8867 14.4734 20.0845 14.4734 20.8545 12.9334L24.9523 4.73778C25.7223 3.19773 27.92 3.19773 28.69 4.73778L32.7878 12.9334C33.5579 14.4734 35.7556 14.4734 36.5256 12.9334L40.6234 4.73778C41.3934 3.19773 43.5912 3.19773 44.3612 4.73778L48.459 12.9334C49.229 14.4734 51.4267 14.4734 52.1967 12.9334L56.2945 4.73778C57.0646 3.19773 59.2623 3.19773 60.0323 4.73778L64.1301 12.9334C64.9001 14.4734 67.0979 14.4734 67.8679 12.9334L71.9657 4.73778C72.7357 3.19773 74.9334 3.19773 75.7035 4.73778L81.6701 16.6711"
                      stroke="url(#paint0_linear_12_1733)"
                      strokeWidth="5.22371"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_12_1733"
                        x1="3.31445"
                        y1="7.90105"
                        x2="81.2809"
                        y2="13.9363"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#8850F4" />
                        <stop offset="1" stopColor="#6959FC" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <span className="block font-bold text-4xl tablet:text-[56px] text-[#2B2A35] leading-[1.15]">
                  {t("homePage.welcomeBlock.main")}
                </span>
                <div className="text-base leading-[2.05]">
                  {t("homePage.welcomeBlock.desc")}
                </div>
                <button
                  className="btn btn-filled rounded-lg w-[250px] h-[56px]"
                  onClick={() => {
                    openModal(true);
                  }}
                >{t("homePage.welcomeBlock.btnText")}</button>
              </div>
              <div className="flex-1 max-tablet:mt-12">
                <LazyLoad height={587}>
                  <img
                    className="w-full"
                    src={ImDashboard}
                    alt="Suppli Dashboard"
                  />
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>


        <div className="section">
          <div className="container-lg">
            <Benefit />
          </div>
        </div>

        <div className="section">
          <div className="container-lg">
            <div className="product-wrapper">
              <div
                className="product-slider slick-slider list-unstyled"
                data-slick='{"slidesToShow": 1, "slidesToScroll": 1, "fade": true, "autoplay": true, "arrows": true, "dots": true, "infinite": false, "responsive":[{"breakpoint":574,"settings":{"fade": true}}]}'
              >
                <div className="slide">
                  <div className="slide-pic">
                    <LazyLoad>
                      <img src={Slide01Png} alt="Product Pic" />
                    </LazyLoad>
                  </div>
                  <div className="slide-label">
                    <LazyLoad>
                      <img src={LogoSmSvg} alt="Product Pic" />
                    </LazyLoad>
                    <div className="slide-label__inner">
                      <svg
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.9204 3.20991C17.6171 2.75701 17.4637 2.26191 17.565 1.70718C17.7144 0.889709 18.1811 0.337227 18.9702 0.0981182C19.7525 -0.139303 20.4531 0.0570478 21.006 0.655663C21.5801 1.27791 21.7094 2.0228 21.3843 2.80145C21.0592 3.58123 20.4335 3.98181 19.5936 4.03975C19.5494 4.04257 19.5001 4.05213 19.4604 4.03807C19.1285 3.92161 18.9388 4.10333 18.7312 4.334C17.8135 5.35626 16.879 6.36389 15.9506 7.37715C15.9002 7.43172 15.8521 7.48854 15.8163 7.52962C15.9248 7.79573 16.058 8.04046 16.1257 8.30208C16.4039 9.37272 15.6758 10.5176 14.5964 10.7342C13.4509 10.9643 12.3412 10.1896 12.2058 9.04866C12.1772 8.80842 12.2013 8.55694 12.236 8.31614C12.2545 8.19012 12.2315 8.12879 12.1358 8.05734C11.4481 7.54481 10.7626 7.02946 10.0815 6.50848C9.97018 6.42353 9.8896 6.42184 9.76593 6.48823C9.13526 6.82467 8.4962 6.82523 7.86609 6.48654C7.74018 6.41903 7.66016 6.4269 7.55048 6.51073C6.86889 7.03114 6.18394 7.54706 5.49564 8.05903C5.39771 8.13161 5.37533 8.19462 5.40163 8.31896C5.57622 9.15274 5.32552 9.84813 4.65736 10.364C4.03957 10.8406 3.34063 10.9154 2.6361 10.5941C1.86833 10.2442 1.46486 9.60902 1.45143 8.76454C1.42737 7.24437 3.07706 6.26093 4.40778 6.99064C4.53369 7.05984 4.61372 7.04859 4.72284 6.96532C5.39827 6.44941 6.07706 5.93687 6.75977 5.43052C6.87281 5.34669 6.89687 5.27468 6.86441 5.13403C6.61539 4.05551 7.34959 2.93085 8.4173 2.739C9.57398 2.5314 10.6562 3.29092 10.7889 4.42345C10.818 4.67044 10.7654 4.92643 10.7648 5.17847C10.7648 5.25274 10.7911 5.35626 10.8437 5.3962C11.5415 5.93237 12.2444 6.46235 12.9517 6.98501C13.006 7.02496 13.1229 7.03396 13.1828 7.00245C13.6059 6.78191 14.044 6.68345 14.5197 6.77122C14.5857 6.7836 14.6881 6.75434 14.7323 6.70596C15.7905 5.5588 16.8437 4.40714 17.8969 3.25548C17.9075 3.24704 17.9109 3.23072 17.9204 3.20991Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M21.5419 20.2947C20.2039 20.2947 18.8821 20.2947 17.5273 20.2947C17.5273 20.2153 17.5273 20.1366 17.5273 20.0578C17.5273 16.339 17.5273 12.6201 17.5273 8.90129C17.5273 8.34037 17.7752 8.08776 18.3253 8.0872C19.1513 8.08664 19.9778 8.08495 20.8038 8.08776C21.2716 8.08945 21.5469 8.35781 21.5475 8.82084C21.5492 12.6072 21.5486 16.3936 21.548 20.1793C21.548 20.2092 21.5447 20.239 21.5419 20.2947Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M10.8181 20.3009C9.4734 20.3009 8.15667 20.3009 6.80469 20.3009C6.80469 20.2188 6.80469 20.1406 6.80469 20.0624C6.80469 17.2415 6.80469 14.4211 6.80469 11.6002C6.80469 11.0297 7.05203 10.7822 7.62114 10.7822C8.4471 10.7816 9.27307 10.7793 10.099 10.7833C10.5495 10.7855 10.8243 11.059 10.8243 11.5113C10.8265 14.3919 10.8254 17.2724 10.8248 20.153C10.8259 20.1963 10.8215 20.239 10.8181 20.3009Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M16.1803 20.2952C14.8423 20.2952 13.5205 20.2952 12.1663 20.2952C12.1663 20.2159 12.1663 20.1371 12.1663 20.0583C12.1663 18.1359 12.1657 16.2135 12.1663 14.291C12.1663 13.7312 12.4147 13.4786 12.9654 13.4781C13.7913 13.4775 14.6173 13.4758 15.4433 13.4786C15.9105 13.4803 16.1864 13.7487 16.1864 14.2111C16.1886 16.2011 16.1875 18.191 16.187 20.1804C16.187 20.2097 16.1836 20.2395 16.1803 20.2952Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M1.4435 20.2891C1.4435 20.2159 1.4435 20.1569 1.4435 20.0978C1.4435 18.6018 1.44294 17.1058 1.44406 15.6099C1.44462 15.0844 1.70259 14.8256 2.22582 14.825C3.05178 14.8239 3.87719 14.8234 4.70315 14.825C5.19784 14.8262 5.46364 15.0906 5.4642 15.5851C5.46588 17.0963 5.46476 18.6069 5.46476 20.118C5.46476 20.1698 5.46476 20.221 5.46476 20.2891C4.12621 20.2891 2.7994 20.2891 1.4435 20.2891Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M22.3268 23.0003H0.673195C0.301623 23.0003 0 22.6976 0 22.324C0 21.9505 0.301623 21.6472 0.673195 21.6472H22.3268C22.6984 21.6472 23 21.9505 23 22.324C23 22.6976 22.6984 23.0003 22.3268 23.0003Z"
                          fill="#EFB76C"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="slide">
                  <div className="slide-pic">
                    <LazyLoad>
                      <img src={Slide02Png} alt="Product Pic" />
                    </LazyLoad>
                  </div>
                  <div className="slide-label">
                    <img src={LogoSmSvg} alt="" />
                    <div className="slide-label__inner">
                      <svg
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.9204 3.20991C17.6171 2.75701 17.4637 2.26191 17.565 1.70718C17.7144 0.889709 18.1811 0.337227 18.9702 0.0981182C19.7525 -0.139303 20.4531 0.0570478 21.006 0.655663C21.5801 1.27791 21.7094 2.0228 21.3843 2.80145C21.0592 3.58123 20.4335 3.98181 19.5936 4.03975C19.5494 4.04257 19.5001 4.05213 19.4604 4.03807C19.1285 3.92161 18.9388 4.10333 18.7312 4.334C17.8135 5.35626 16.879 6.36389 15.9506 7.37715C15.9002 7.43172 15.8521 7.48854 15.8163 7.52962C15.9248 7.79573 16.058 8.04046 16.1257 8.30208C16.4039 9.37272 15.6758 10.5176 14.5964 10.7342C13.4509 10.9643 12.3412 10.1896 12.2058 9.04866C12.1772 8.80842 12.2013 8.55694 12.236 8.31614C12.2545 8.19012 12.2315 8.12879 12.1358 8.05734C11.4481 7.54481 10.7626 7.02946 10.0815 6.50848C9.97018 6.42353 9.8896 6.42184 9.76593 6.48823C9.13526 6.82467 8.4962 6.82523 7.86609 6.48654C7.74018 6.41903 7.66016 6.4269 7.55048 6.51073C6.86889 7.03114 6.18394 7.54706 5.49564 8.05903C5.39771 8.13161 5.37533 8.19462 5.40163 8.31896C5.57622 9.15274 5.32552 9.84813 4.65736 10.364C4.03957 10.8406 3.34063 10.9154 2.6361 10.5941C1.86833 10.2442 1.46486 9.60902 1.45143 8.76454C1.42737 7.24437 3.07706 6.26093 4.40778 6.99064C4.53369 7.05984 4.61372 7.04859 4.72284 6.96532C5.39827 6.44941 6.07706 5.93687 6.75977 5.43052C6.87281 5.34669 6.89687 5.27468 6.86441 5.13403C6.61539 4.05551 7.34959 2.93085 8.4173 2.739C9.57398 2.5314 10.6562 3.29092 10.7889 4.42345C10.818 4.67044 10.7654 4.92643 10.7648 5.17847C10.7648 5.25274 10.7911 5.35626 10.8437 5.3962C11.5415 5.93237 12.2444 6.46235 12.9517 6.98501C13.006 7.02496 13.1229 7.03396 13.1828 7.00245C13.6059 6.78191 14.044 6.68345 14.5197 6.77122C14.5857 6.7836 14.6881 6.75434 14.7323 6.70596C15.7905 5.5588 16.8437 4.40714 17.8969 3.25548C17.9075 3.24704 17.9109 3.23072 17.9204 3.20991Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M21.5419 20.2947C20.2039 20.2947 18.8821 20.2947 17.5273 20.2947C17.5273 20.2153 17.5273 20.1366 17.5273 20.0578C17.5273 16.339 17.5273 12.6201 17.5273 8.90129C17.5273 8.34037 17.7752 8.08776 18.3253 8.0872C19.1513 8.08664 19.9778 8.08495 20.8038 8.08776C21.2716 8.08945 21.5469 8.35781 21.5475 8.82084C21.5492 12.6072 21.5486 16.3936 21.548 20.1793C21.548 20.2092 21.5447 20.239 21.5419 20.2947Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M10.8181 20.3009C9.4734 20.3009 8.15667 20.3009 6.80469 20.3009C6.80469 20.2188 6.80469 20.1406 6.80469 20.0624C6.80469 17.2415 6.80469 14.4211 6.80469 11.6002C6.80469 11.0297 7.05203 10.7822 7.62114 10.7822C8.4471 10.7816 9.27307 10.7793 10.099 10.7833C10.5495 10.7855 10.8243 11.059 10.8243 11.5113C10.8265 14.3919 10.8254 17.2724 10.8248 20.153C10.8259 20.1963 10.8215 20.239 10.8181 20.3009Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M16.1803 20.2952C14.8423 20.2952 13.5205 20.2952 12.1663 20.2952C12.1663 20.2159 12.1663 20.1371 12.1663 20.0583C12.1663 18.1359 12.1657 16.2135 12.1663 14.291C12.1663 13.7312 12.4147 13.4786 12.9654 13.4781C13.7913 13.4775 14.6173 13.4758 15.4433 13.4786C15.9105 13.4803 16.1864 13.7487 16.1864 14.2111C16.1886 16.2011 16.1875 18.191 16.187 20.1804C16.187 20.2097 16.1836 20.2395 16.1803 20.2952Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M1.4435 20.2891C1.4435 20.2159 1.4435 20.1569 1.4435 20.0978C1.4435 18.6018 1.44294 17.1058 1.44406 15.6099C1.44462 15.0844 1.70259 14.8256 2.22582 14.825C3.05178 14.8239 3.87719 14.8234 4.70315 14.825C5.19784 14.8262 5.46364 15.0906 5.4642 15.5851C5.46588 17.0963 5.46476 18.6069 5.46476 20.118C5.46476 20.1698 5.46476 20.221 5.46476 20.2891C4.12621 20.2891 2.7994 20.2891 1.4435 20.2891Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M22.3268 23.0003H0.673195C0.301623 23.0003 0 22.6976 0 22.324C0 21.9505 0.301623 21.6472 0.673195 21.6472H22.3268C22.6984 21.6472 23 21.9505 23 22.324C23 22.6976 22.6984 23.0003 22.3268 23.0003Z"
                          fill="#EFB76C"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="slide">
                  <div className="slide-pic">
                    <LazyLoad>
                      <img src={Slide03Png} alt="Product Pic" />
                    </LazyLoad>
                  </div>
                  <div className="slide-label">
                    <img src={LogoSmSvg} alt="" />
                    <div className="slide-label__inner">
                      <svg
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.9204 3.20991C17.6171 2.75701 17.4637 2.26191 17.565 1.70718C17.7144 0.889709 18.1811 0.337227 18.9702 0.0981182C19.7525 -0.139303 20.4531 0.0570478 21.006 0.655663C21.5801 1.27791 21.7094 2.0228 21.3843 2.80145C21.0592 3.58123 20.4335 3.98181 19.5936 4.03975C19.5494 4.04257 19.5001 4.05213 19.4604 4.03807C19.1285 3.92161 18.9388 4.10333 18.7312 4.334C17.8135 5.35626 16.879 6.36389 15.9506 7.37715C15.9002 7.43172 15.8521 7.48854 15.8163 7.52962C15.9248 7.79573 16.058 8.04046 16.1257 8.30208C16.4039 9.37272 15.6758 10.5176 14.5964 10.7342C13.4509 10.9643 12.3412 10.1896 12.2058 9.04866C12.1772 8.80842 12.2013 8.55694 12.236 8.31614C12.2545 8.19012 12.2315 8.12879 12.1358 8.05734C11.4481 7.54481 10.7626 7.02946 10.0815 6.50848C9.97018 6.42353 9.8896 6.42184 9.76593 6.48823C9.13526 6.82467 8.4962 6.82523 7.86609 6.48654C7.74018 6.41903 7.66016 6.4269 7.55048 6.51073C6.86889 7.03114 6.18394 7.54706 5.49564 8.05903C5.39771 8.13161 5.37533 8.19462 5.40163 8.31896C5.57622 9.15274 5.32552 9.84813 4.65736 10.364C4.03957 10.8406 3.34063 10.9154 2.6361 10.5941C1.86833 10.2442 1.46486 9.60902 1.45143 8.76454C1.42737 7.24437 3.07706 6.26093 4.40778 6.99064C4.53369 7.05984 4.61372 7.04859 4.72284 6.96532C5.39827 6.44941 6.07706 5.93687 6.75977 5.43052C6.87281 5.34669 6.89687 5.27468 6.86441 5.13403C6.61539 4.05551 7.34959 2.93085 8.4173 2.739C9.57398 2.5314 10.6562 3.29092 10.7889 4.42345C10.818 4.67044 10.7654 4.92643 10.7648 5.17847C10.7648 5.25274 10.7911 5.35626 10.8437 5.3962C11.5415 5.93237 12.2444 6.46235 12.9517 6.98501C13.006 7.02496 13.1229 7.03396 13.1828 7.00245C13.6059 6.78191 14.044 6.68345 14.5197 6.77122C14.5857 6.7836 14.6881 6.75434 14.7323 6.70596C15.7905 5.5588 16.8437 4.40714 17.8969 3.25548C17.9075 3.24704 17.9109 3.23072 17.9204 3.20991Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M21.5419 20.2947C20.2039 20.2947 18.8821 20.2947 17.5273 20.2947C17.5273 20.2153 17.5273 20.1366 17.5273 20.0578C17.5273 16.339 17.5273 12.6201 17.5273 8.90129C17.5273 8.34037 17.7752 8.08776 18.3253 8.0872C19.1513 8.08664 19.9778 8.08495 20.8038 8.08776C21.2716 8.08945 21.5469 8.35781 21.5475 8.82084C21.5492 12.6072 21.5486 16.3936 21.548 20.1793C21.548 20.2092 21.5447 20.239 21.5419 20.2947Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M10.8181 20.3009C9.4734 20.3009 8.15667 20.3009 6.80469 20.3009C6.80469 20.2188 6.80469 20.1406 6.80469 20.0624C6.80469 17.2415 6.80469 14.4211 6.80469 11.6002C6.80469 11.0297 7.05203 10.7822 7.62114 10.7822C8.4471 10.7816 9.27307 10.7793 10.099 10.7833C10.5495 10.7855 10.8243 11.059 10.8243 11.5113C10.8265 14.3919 10.8254 17.2724 10.8248 20.153C10.8259 20.1963 10.8215 20.239 10.8181 20.3009Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M16.1803 20.2952C14.8423 20.2952 13.5205 20.2952 12.1663 20.2952C12.1663 20.2159 12.1663 20.1371 12.1663 20.0583C12.1663 18.1359 12.1657 16.2135 12.1663 14.291C12.1663 13.7312 12.4147 13.4786 12.9654 13.4781C13.7913 13.4775 14.6173 13.4758 15.4433 13.4786C15.9105 13.4803 16.1864 13.7487 16.1864 14.2111C16.1886 16.2011 16.1875 18.191 16.187 20.1804C16.187 20.2097 16.1836 20.2395 16.1803 20.2952Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M1.4435 20.2891C1.4435 20.2159 1.4435 20.1569 1.4435 20.0978C1.4435 18.6018 1.44294 17.1058 1.44406 15.6099C1.44462 15.0844 1.70259 14.8256 2.22582 14.825C3.05178 14.8239 3.87719 14.8234 4.70315 14.825C5.19784 14.8262 5.46364 15.0906 5.4642 15.5851C5.46588 17.0963 5.46476 18.6069 5.46476 20.118C5.46476 20.1698 5.46476 20.221 5.46476 20.2891C4.12621 20.2891 2.7994 20.2891 1.4435 20.2891Z"
                          fill="#EFB76C"
                        />
                        <path
                          d="M22.3268 23.0003H0.673195C0.301623 23.0003 0 22.6976 0 22.324C0 21.9505 0.301623 21.6472 0.673195 21.6472H22.3268C22.6984 21.6472 23 21.9505 23 22.324C23 22.6976 22.6984 23.0003 22.3268 23.0003Z"
                          fill="#EFB76C"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product-text">
                <img className="product-bg" src={ProductBgSvg} alt="" />
                <span className="section-pretitle">
                  {t("homePage.productBlock.preTitle")}
                </span>
                <div className="section-title">
                  {t("homePage.productBlock.title")}
                </div>
                <div className="product-descr">
                  <p>
                    <strong>Suppli</strong>{" "}
                    {t("homePage.productBlock.descriptions.1")}
                  </p>
                  <p>
                    <strong>Suppli</strong>{" "}
                    {t("homePage.productBlock.descriptions.2")}
                  </p>
                  <p>
                    <strong>Suppli</strong>{" "}
                    {t("homePage.productBlock.descriptions.3")}
                  </p>
                  <p>
                    <strong>Suppli</strong>{" "}
                    {t("homePage.productBlock.descriptions.4")}
                  </p>
                </div>
                <div className="product-footer">
                  <a className="btn btn-filled" href="/product">
                    {t("homePage.productBlock.productFooter.btnMain")}
                  </a>
                  {/* <div
                    className="product-video__btn popup-btn"
                    data-mfp-src=""
                    data-type="iframe"
                  >
                    <i>
                      <svg
                        width="13"
                        height="14"
                        viewBox="0 0 13 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.4947 4.95129C12.8877 5.75553 12.8877 7.76614 11.4947 8.57038L3.65911 13.0942C2.26612 13.8985 0.524884 12.8932 0.524885 11.2847L0.524885 2.23697C0.524885 0.62848 2.26612 -0.376823 3.65911 0.42742L11.4947 4.95129Z"
                          fill="url(#paint0_linear_26_69)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_26_69"
                            x1="6.34765"
                            y1="-2.64185"
                            x2="6.05629"
                            y2="16.1777"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#8850F4" />
                            <stop offset="1" stopColor="#6959FC" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </i>
                    <span>
                      {t("homePage.productBlock.productFooter.videoBtn")}
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="container-lg">
            <div className="text-center mb-16">
              <div className="text-lg text-[#8850F4] mb-4 tracking-wider uppercase">
                {t("homePage.pricingBlock.preTitle")}
              </div>
              <div className="text-[56px] text-[#2B2A35] font-[700] leading-[64.4px] mb-8">
                {t("homePage.pricingBlock.title")}
              </div>
              <div className="max-w-[678px] mx-auto text-base text-center leading-[2.05]">
                {t("homePage.pricingBlock.desc")}
              </div>
            </div>

            <Pricing />
          </div>
        </div>

        {/* Block with about us information and team member cards */}
        <AboutBlock />

        <div className="section">
          <div className="container-lg">
            <div className="contacts-main">
              <div className="contacts-main__wrapper py-[24px] px-[12px]">
                <div className="contacts-main__left">
                  <span className="contacts-main__title">
                    {t("homePage.supportBlock.title")}
                  </span>
                  <div className="contacts-main__text">
                    <p>{t("homePage.supportBlock.text1")}</p>
                    <p>
                      {t("homePage.supportBlock.text2")}{" "}
                      <a href="tel:">+47 404 926 00</a>{" "}
                      {t("homePage.supportBlock.text3")}{" "}
                      <a
                        href="mailto:post@suppli.no"
                        className="[text-decoration:underline_!important] underline-offset-2"
                      >
                        post@suppli.no
                      </a>
                    </p>
                    <p>{t("homePage.supportBlock.text4")}</p>
                  </div>
                  <div className="contacts-main__address">
                    <svg
                      width="14"
                      height="20"
                      viewBox="0 0 14 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.36998 0C7.56208 0.0254322 7.75561 0.0459736 7.94677 0.0772748C10.5364 0.505221 12.3765 1.96904 13.4378 4.43157C14.1149 6.00201 14.1528 7.64092 13.7 9.29597C13.3068 10.7324 12.685 12.0647 11.9696 13.3509C10.7143 15.6081 9.2087 17.6813 7.56729 19.6513C7.18024 20.1154 6.8036 20.1164 6.4175 19.6513C4.55701 17.4098 2.85883 15.0495 1.52687 12.4271C0.917904 11.2278 0.381809 9.99144 0.140968 8.65038C-0.33267 6.0157 0.390326 3.76348 2.26311 1.92062C3.36937 0.832905 4.71174 0.238183 6.2235 0.0445064C6.35362 0.0278776 6.48421 0.0146724 6.61434 0C6.86606 0 7.11778 0 7.36998 0ZM6.96968 10.8855C8.87701 10.9212 10.4749 9.29744 10.5108 7.28829C10.5463 5.28501 8.98536 3.63241 7.0241 3.5967C5.11062 3.56198 3.51511 5.17448 3.47394 7.18363C3.43278 9.19473 4.99469 10.8488 6.96968 10.8855Z"
                        fill="white"
                      />
                    </svg>
                    <strong>{t("homePage.supportBlock.postalAddress")}</strong>
                    <br />
                    {t("homePage.supportBlock.info1")},
                    <br />
                    {t("homePage.supportBlock.info2")},
                    <br />
                    {t("homePage.supportBlock.info3")},
                    <br />
                    {t("homePage.supportBlock.info4")}
                  </div>
                </div>
                <div className="contacts-main__right">
                  <div className="contacts-main__schedule">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.708 19.709C4.76468 19.6438 0.773155 15.6523 0.708 10.709C0.773155 5.76568 4.76468 1.77415 9.708 1.709C14.6513 1.77415 18.6428 5.76568 18.708 10.709C18.6428 15.6523 14.6513 19.6438 9.708 19.709ZM9.708 3.709C5.86317 3.75962 2.75862 6.86417 2.708 10.709C2.75862 14.5538 5.86317 17.6584 9.708 17.709C13.5528 17.6584 16.6574 14.5538 16.708 10.709C16.6574 6.86417 13.5528 3.75962 9.708 3.709ZM14.708 11.709H8.708V5.709H10.708V9.709H14.708V11.709ZM18 4.417L14.991 1.417L16.4 0L19.41 3L18 4.416V4.417ZM1.415 4.417L0 3L2.991 0L4.406 1.417L1.417 4.417H1.415Z"
                        fill="white"
                      />
                    </svg>
                    <span>{t("homePage.supportBlock.text5")}</span>
                    <ul>
                      <li>
                        <strong>{t("homePage.supportBlock.text6")}</strong>{" "}
                        08:00 - 16:00
                      </li>
                      <li>
                        <strong>{t("homePage.supportBlock.text7")}</strong>{" "}
                        {t("homePage.supportBlock.text8")}
                      </li>
                    </ul>
                  </div>
                  <div className="contacts-main__map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2050.5815699112645!2d10.046282876943632!3d59.0724608744909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4646e9e5ef18a7ed%3A0x2f435e8eb46e98a!2sMellombakken%206%2C%203271%20Larvik%2C%20Norway!5e0!3m2!1sen!2smk!4v1683543450769!5m2!1sen!2smk"
                      width="600"
                      height="450"
                      style={{ border: 0 }}
                      allowFullScreen={false}
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <FooterBlock useTopMargin />
    </div>
  );
};

export default HomePage;
