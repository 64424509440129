import React, { useState } from "react";
import { Box, CircularProgress, CircularProgressProps, Typography } from "@mui/material";
import BaseTooltip from "@reusables/BaseTooltip";

export function BaseCircularProgressWithContent(
    props: CircularProgressProps & { tooltip?: string, children?: React.ReactNode }
) {
    const [isHovered, setIsHovered] = useState(false);

    const loader =
        <Box
            sx={{ position: "relative", display: "inline-flex" }}
            onMouseOver={() => void setIsHovered(true)}
            onMouseLeave={() => void setIsHovered(false)}
            onClick={props.onClick}
        >
            <CircularProgress
                sx={{
                    color: "#A6DD4C"
                }}
                {...props}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >

                {
                    isHovered && props.value ?
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.primary"
                            fontSize={8}
                        >
                            {`${Math.round(props.value)}%`}
                        </Typography>
                        :
                        props.children
                }
            </Box>
        </Box>;

    return (
        {
            ...props.tooltip ? (
                <BaseTooltip title={props.tooltip} className={"flex items-center justify-center"}>
                    {loader}
                </BaseTooltip>
            ) : loader
        }
    );
}
