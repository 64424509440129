import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { FileDown } from "lucide-react";
import { useEchoEffect } from "@helpers/utils";
import {
    extractDataFlowProcess,
    useGetCurrentDataFlowProcessQuery
} from "@redux/features/importExport/importExportApi";
import { Exports } from "@/types/general";
import { useTranslation } from "react-i18next";
import { finishExport, startExport } from "@redux/features/importExport/export/exportSlice";
import { isExportStateActive } from "@redux/features/importExport/export/types";
import { BaseCircularProgressWithContent } from "@reusables/BaseCircularProgressWithContent";


export default function ExportIndicator() {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const company = useAppSelector(state => state.auth.user?.company);

    const { data: currentDataFlowProcess } = useGetCurrentDataFlowProcessQuery();
    const currentExport = extractDataFlowProcess(currentDataFlowProcess, "export");

    const exportState = useAppSelector(state => state.export.value);

    useEffect(() => {
        if (currentExport?.module) {
            dispatch(startExport(
                {
                    module: currentExport.module
                }
            ));
        }
    }, [currentExport]);

    useEchoEffect<Exports.ExportStarted>(
        `exports.${company?.id ?? -1}`,
        "ExportStarted",
        (startData) => {
            dispatch(startExport({ module: startData.module }));
        },
        undefined,
        [company]
    );

    useEchoEffect<Exports.ExportFinished>(
        `exports.${company?.id ?? -1}`,
        "ExportFinished",
        () => {
            dispatch(finishExport());
        },
        undefined,
        [company]
    );

    return (
        <>
            {
                isExportStateActive(exportState) && (
                    <BaseCircularProgressWithContent
                        size={26}
                        variant={"indeterminate"}

                        tooltip={t(`exports.modules.${exportState.module}.states.inProgress`)}
                    >
                        <FileDown size={12} />
                    </BaseCircularProgressWithContent>
                )
            }
        </>
    );
}
