import React from "react";
import { Control, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ProduceFormTyping } from "@components/Dashboard/pages/BOM/components/modals/ProduceModal/types";

type TableHeadingProps = {
    control: Control<ProduceFormTyping>
    onComponentsUpdate: (components: ProduceFormTyping["components_picks"]) => void
}

export default function TableHeading({ control, onComponentsUpdate }: TableHeadingProps) {
    const { t } = useTranslation("", { keyPrefix: "bom.produceModal" });

    const components = useWatch({
        control,
        name: "components_picks"
    })

    return (
        <div>{t("table.components")}</div>
    )
}
