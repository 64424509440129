import BaseNotFound from "@reusables/BaseNotFound";
import { Chip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { cn } from "@helpers/utils";
import { Permission } from "@/types/general";

interface DashboardCardProperties {
    title: string;
    className?: string;
    headerActions?: JSX.Element;
    preview?: boolean;
    previewHeight?: number;
    dashboardAbility?: Permission.Components.Ability["dependency"];
}

const DashboardCard: React.FunctionComponent<DashboardCardProperties> = ({ title, className, headerActions, children, preview, previewHeight, dashboardAbility }) => {
    const { t } = useTranslation("", { keyPrefix: "general" });

    return (
        <div className={cn("h-full", preview && "opacity-30 hover:opacity-100 cursor-pointer transition-all", className)}>
            <div className={`levitation rounded-3xl p-[20px] h-full`}>
                <div className="mb-6 flex items-center">
                    <div className="text-md-bold color-4 text-xl grow uppercase">
                        {title}
                        {preview && <Chip label="preview" size="small" className="ml-[10px] font-thin" />}
                    </div>
                    {headerActions && !preview && <div>{headerActions}</div>}
                </div>

                {preview ? (
                    <BaseNotFound text={t("inDevelopment")} height={previewHeight ?? 200} />
                ) : (
                    children
                )}
            </div>
        </div>
    );
};

export default DashboardCard;
