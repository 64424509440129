import React, { useEffect } from "react";
import {useTranslation} from "react-i18next";
import BaseInput from "@reusables/BaseInput";
import BaseInputsGrid from "@reusables/BaseInputsGrid";

import {ReactComponent as TrashSVG} from "@assets/icons/ic_trash.svg";
import BaseButton from "@reusables/BaseButton";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import BasePhoneInput from "@reusables/BasePhoneInput";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import {ReactComponent as PlusSVG} from "@assets/icons/ic_plus.svg";
import {SupplierFormTyping} from "./TabsController";
import {TabProperties} from "../../types";
import FormErrorWarning from "../modals/FormErrorWarning";
import { Fade } from "@mui/material";

export default function GeneralTab({onNext}: TabProperties) {
    const {t} = useTranslation("", {keyPrefix: "suppliers.general.tabs.general"});

    const {control, setValue, getValues} = useFormContext<SupplierFormTyping>();

    const {fields: contacts, append, remove} = useFieldArray({
        control,
        name: "contacts",
        keyName: "loop_id"
    });

    useEffect(() => {
        if (getValues("contacts").length === 0) {
            append({});
        }
    }, [contacts]);

    return(
        <form className="space-y-[40px]" onSubmit={onNext}>
            <FormErrorWarning />
            
            <BaseInputsGrid>
                <Controller
                    control={control}
                    name="name"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.supplierName.label") + " *"}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="code"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.supplierCode.label")}
                            
                            disabled={getValues("mode") === "editing"}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="vat"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.registrationNumber.label")}
                        />
                    )}
                />
            </BaseInputsGrid>

            {
                contacts.map((contact, index) => 
                    <div key={contact.loop_id} className="space-y-[24px]">
                        {
                            index === 0 ?
                                <div className="flex items-center justify-between">
                                    <div className="text-xl text-accent">{t("categories.contactInfo")}</div>
                                    <BaseMaterialIconButton
                                        icon={<PlusSVG />}
                                        onClick={() => append({})}
                                    />
                                </div>
                                :
                                <div className="flex items-center justify-between">
                                    <div className="text-xl text-accent">{t("categories.additionalContactInfo")}</div>
                                    {/* {contact.id} */}
                                    <div 
                                        className="text-gray-300 space-x-[10px] font-thin cursor-pointer hover:text-actionableIcons"
                                        onClick={() => {
                                            if(contact.id != undefined) {
                                                setValue("deleted_contacts", [...getValues("deleted_contacts"), contact.id])
                                            }
                                            remove(index);
                                        }}
                                    >
                                        <TrashSVG />
                                        <span className="align-middle">{t("buttons.delete")}</span>
                                    </div>
                                </div>
                        }

                        <div className="grid grid-cols-3 gap-x-[40px]">
                            <Controller
                                control={control}
                                name={`contacts.${index}.name`}
                                render={({field, fieldState}) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}

                                        label={t("fields.contactName.label")}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name={`contacts.${index}.phone`}
                                render={({field, fieldState}) => (
                                    <BasePhoneInput
                                        {...field}
                                        {...fieldState}
                                        label={t('fields.phone.label')}
                                        size='md'
                                        variant='custom'
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name={`contacts.${index}.email`}
                                render={({field, fieldState}) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}

                                        label={t("fields.email.label")}
                                        type={"email"}
                                    />
                                )}
                            />
                        </div>
                    </div>
                )
            }
            <div className="flex justify-center">
                <BaseButton
                    text={t("buttons.next")}
                    size="md"
                    buttonWidth="200px"
                />
            </div>
            {/*<DevTool control={control} />*/}
        </form>
    );
}