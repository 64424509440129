import React, { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { BOMFormTyping } from '..';
import { normalizePrice } from '@helpers/utils';
import { useTranslation } from 'react-i18next';

export default function SummaryBlock() {
    const { t } = useTranslation("", { keyPrefix: "bom.mutation" });
    const {control} = useFormContext<BOMFormTyping>();

    const components = useWatch({
        control,
        name: "components"
    });

    const componentsSummary = useMemo(() => {
        const total = components?.reduce((acc, component) => {
            return acc + component?.quantity * component?.prices?.purchase_price;
        }, 0);

        return {
            total
        }
    }, [components, control]);

    return (
        <div className="flex justify-end mt-[48px] font-thin">
            <div className="w-1/2 grid grid-cols-2 gap-y-[16px]">
                <div className="font-bold text-right text-accent">
                    {t("totalPrice")}
                </div>
                <div className="font-bold text-right pr-[16px] text-accent">
                    {
                        normalizePrice(componentsSummary?.total)
                    }
                </div>
            </div>
        </div>
    )
}