import {Employee, Product, PurchaseOrder, Supplier} from "@/types/general";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Order} from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/types";
import dayjs from "dayjs";

type PurchasesSliceState = {
    QPO: {
        orders: Order[];
        meta: {
            supplier?: Supplier.Slim;
        }
    }; // QPO = Quick Purchase Order
    receiveOrder?: PurchaseOrder.Extended;
    fromSO?: {
        order: {
            id: number;
            code: string;
            preferred_delivery_date?: dayjs.Dayjs;
            our_reference?: Employee.Root;
        },
        lines: Order[];
    }
}

const initialState = {
    QPO: {
        orders: [],
        meta: {}
    },
} as PurchasesSliceState;

const purchasesSlice = createSlice({
    name: "purchases",
    initialState,
    reducers: {
        addQPO: (state, action: PayloadAction<{
            order: { product: Product.Slim, quantity: number },
            meta: { supplier?: Supplier.Slim }
        }>) => {
            const product = action.payload.order.product;
            const quantity = action.payload.order.quantity;

            state.QPO.orders.push({
                product: {
                    id: product.id,
                    name: product.name,
                    code: product.code,
                },
                quantity: quantity,
                unit_price: product.prices.purchase_price,
                tax: product.tax
            });

            state.QPO.meta.supplier = action.payload.meta.supplier;
        },

        addReceiveOrder: (state, action: PayloadAction<PurchaseOrder.Extended>) => {
            state.receiveOrder = action.payload;
        },

        addFromSO: (state, action: PayloadAction<PurchasesSliceState["fromSO"]>) => {
            state.fromSO = action.payload;
        },

        emptyQPO: (state) => {
            state.QPO = {
                orders: [],
                meta: {}
            };
        },

        emptyFromSO: (state) => {
            state.fromSO = undefined;
        },

        emptyReceiveOrder: (state) => {
            state.receiveOrder = undefined;
        }
    }
});

export const {addQPO, addReceiveOrder, addFromSO, emptyReceiveOrder, emptyFromSO, emptyQPO} = purchasesSlice.actions;

export default purchasesSlice.reducer;