import BaseButton from "@reusables/BaseButton";
import BaseModal from "@reusables/Modals/BaseModal";
import {useDeleteEmployeeMutation} from "@redux/features/employees/employeesApi";
import {Employee} from "@/types/general";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

interface DeletionModalProperties {
    isOpen: boolean;
    onClose: () => void;
    employee?: Employee.Root;
}

export default function DeletionModal(props: DeletionModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.employees.modals.deletion" });

    const [deleteEmployee, { isLoading: isDeletionLoading }] = useDeleteEmployeeMutation();

    return (
        <BaseModal isOpen={props.isOpen} onClose={props.onClose} width={700} padding="56px" isLoading={isDeletionLoading}>
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">
                    {`${t("subheading")} `} {<span className="font-semibold">{props.employee?.name}?</span>}
                </div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose} />
                    <BaseButton
                        text={t("buttons.delete")}
                        size="md"
                        onClick={() => {
                            if (props.employee) {
                                deleteEmployee(props.employee.id)
                                    .unwrap()
                                    .then(() => {
                                        toast.success(t("responses.success"));
                                    })
                                    .catch((e) => {
                                        console.error(e);

                                        toast.error(t("responses.error"));
                                    })
                                    .finally(() => props.onClose());
                            }
                        }}
                    />
                </div>
            </div>
        </BaseModal>
    );
}
