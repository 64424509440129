import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import BaseInput from '@reusables/BaseInput';
import BaseInputsGrid from '@reusables/BaseInputsGrid';
import BaseModal from '@reusables/Modals/BaseModal';
import BasePhoneInput from '@reusables/BasePhoneInput';
import BaseButton from '@reusables/BaseButton';
import BaseDropdown from '@reusables/dropdowns/BaseDropdown';

import {getCountryFlag} from '@helpers/utils';
import {useGetCountriesQuery} from '@redux/api/internalApiSlice';

import {useAppSelector} from '@redux/hooks';
import {useCreateBillingAddressMutation} from '@redux/features/companies/companiesApi';

import {Controller, useForm, useWatch} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {toast} from "react-toastify";

interface CreateBillingAddressModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const formScheme = z.object({
    name: z.string().trim(),
    country: z.object({
        id: z.coerce.number(),
        name: z.string(),
        code: z.string(),
    }),
    street: z.string().trim(),
    street2: z.string().trim().optional(),
    zipcode: z.string().trim(),
    city: z.string().trim(),
    phone: z.string().trim(),
    email: z.string().email(),
    contact_name: z.string().trim()
});

type FormTyping = z.infer<typeof formScheme>

export default function CreateBillingAddressModal({isOpen, onClose}: CreateBillingAddressModalProps): JSX.Element {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.general.fields.billingAddress.modalCreation"});

    const companyStorageData = useAppSelector(state => state.auth.user?.company);

    // ---> Countries selection <--- //
    const {data: countriesOptions, isLoading: countriesOptionsLoading} = useGetCountriesQuery();

    // Getting RTK Query hooks for saving data
    const [createBillingAddress, {isLoading: createBillingAddressLoading}] = useCreateBillingAddressMutation();

    const {control, handleSubmit, reset, formState} = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
    });

    const country = useWatch({
        control,
        name: 'country'
    });

    useEffect(() => {
        reset();
    }, [reset, isOpen]);

    const onSubmit = handleSubmit((data) => {
        if(companyStorageData) {
            createBillingAddress({
                name: data.name,
                country: data.country.id,
                street: data.street,
                street_2: data.street2,
                zipcode: data.zipcode,
                city: data.city,
                phone: data.phone,
                email: data.email,
                contact_name: data.contact_name,
                is_used_for_delivery: false,
            }).unwrap().then(() => {
                toast.success(t('responses.success'));

                onClose();
            }).catch(e => {
                console.error(e);

                toast.error(t('responses.error'));
            })
        }
    });

  return (
    <BaseModal
        keepMounted={false}
        isOpen={isOpen}
        onClose={onClose}
        width={900}
        padding="56px"
        useCloseIcon
        isLoading={createBillingAddressLoading}
    >
        <form className='space-y-[32px]' onSubmit={onSubmit}>
            <div className='modal-title'>{t('heading')}</div>
            <BaseInputsGrid gap={{ x:24, y: 40 }} cols={2}>
                <Controller
                    name={'name'}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t('fields.name')}
                        />
                    )}
                />
                
                <Controller 
                    name={'country'}
                    control={control}
                    render={({field, fieldState}) => ( 
                        <BaseDropdown 
                        {...field}
                        {...fieldState}
                            label={t("dropdowns.country.label")}
                            placeholder={t("dropdowns.country.placeholder")}
                            options={countriesOptions}
                            getter={{
                                label: (opt) => opt.name,
                                key: (opt) => opt.id,
                                renderOption: (opt, icon) => (
                                <div>
                                    <img src={getCountryFlag(opt.code)} width={20} alt={opt.code} />
                                    <span className="ml-[8px] grow">{opt.name}</span>
                                    {icon}
                                </div>
                                )
                            }}

                            autocomplete
                            isLoading={countriesOptionsLoading}
                        />
                    )}
                />
                
                <Controller 
                    name={'street'}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput 
                            {...field}
                            error={fieldState.error}
                            label={t('fields.street')}
                        />
                    )}                
                />

                <Controller 
                    name={'street2'}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput 
                            {...field}
                            error={fieldState.error}
                            label={t('fields.street2')}
                        />
                    )}
                />

                <BaseInputsGrid cols={2}>
                    <Controller
                        name={'zipcode'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.zipCode')}
                            />
                        )}
                    />

                    <Controller
                        name={'city'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.city')}
                            />
                        )}
                    />
                </BaseInputsGrid>

                <Controller 
                    name={'email'}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t('fields.email')}
                        />
                    )}
                />

                <Controller
                    name={'contact_name'}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t('fields.contactName')}
                        />
                    )}
                />

                <Controller
                    name={'phone'}
                    control={control}
                    render={({field, fieldState}) => (
                        <BasePhoneInput
                            {...field}
                            {...fieldState}
                            label={t('fields.phone')}
                            value={field.value || ''}
                            onChange={field.onChange}
                            size='md'
                            variant='custom'

                            country={!field.value ? country?.code : ""}
                        />
                    )}
                />
            </BaseInputsGrid>

            <BaseButton 
                buttonWidth='100%'
                text={`${t("buttons.save")}`}
                size='md'
                type='submit'
            />
        </form>
    </BaseModal>
  )
}
