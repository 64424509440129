import { Permission } from "@/types/general";

/**
 * Simply represents sidebar link.
 * Used as core sidebar items component.
 */
class UIDStore{
  /**
   * Contains last issued UID, allowing setting unique identifiers to collapsable groups and collapse any non-active.
   * @see works with {@link UID}.
   */
  public static lastUID = 0;
}

class EndpointItem{
  readonly UID: number;
  readonly icon: JSX.Element;
  readonly title: (() => string) | string; // function is needed for reactive translations change reflection
  readonly isAccessible?: boolean;

  constructor(icon: JSX.Element, title: (() => string) | string, isAccessible = true, UID?: number) {
    this.UID = UID ?? UIDStore.lastUID++;
    this.icon = icon;
    this.title = title;
    this.isAccessible = isAccessible;
  }

  getTitle = () => this.title instanceof Function ? this.title() : this.title;
}

class Link extends EndpointItem {
  readonly link: string;

  constructor(icon: JSX.Element, title: (() => string) | string, link: string, isAccessible = true, UID?: number) {
    super(icon, title, isAccessible, UID);
    this.link = link;
  }

  // Enhanced static method to check multiple permissions
  static checkPermissions(ability: any, moduleKey?: Permission.Components.Modules, actions: Permission.Components.Ability["key"][] = ["view"]): boolean {
    // Check if any of the given actions is allowed
    return actions.some(action => ability.can(action, moduleKey));
  }
}

/**
 * Represents a collapsable items group. Can contain (include) {@link Link} items or other {@link ItemsGroup}.
 */
class ItemsGroup extends EndpointItem{
  includes: SidebarItemT[];

  constructor(icon: JSX.Element, title: (() => string) | string, includes: SidebarItemT[], isAccessible = true, UID?: number) {
    super(icon, title, isAccessible, UID);
    this.includes = includes;
  }
}

/**
 * Combination of {@link ItemsGroup}, {@link Link} to provide flexible link customization,
 * allowing building *endless* hierarchy.
 */
type SidebarItemT = ItemsGroup | Link;

export { Link, ItemsGroup, EndpointItem };
export type { SidebarItemT };
