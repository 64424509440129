import BaseModal from "@reusables/Modals/BaseModal";
import React, {useEffect} from "react";
import BaseButton from "@reusables/BaseButton";
import {useTranslation} from "react-i18next";
import {
    useCreatePaymentTermsMutation,
    useUpdatePaymentTermsMutation
} from "@redux/features/paymentTerms/paymentTermsApi";
import {PaymentTerms} from "@/types/general";
import BaseInput from "@reusables/BaseInput";
import {Controller, useForm} from "react-hook-form";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";

import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {toast} from "react-toastify";

interface MutationPaymentTermsModalProperties {
    isOpen: boolean;
    onClose: () => void;
    paymentTerms?: PaymentTerms.Root;
}

const formScheme = z.object({
    name: z.string().nonempty(),
    days: z.coerce.number().positive(),
    selectedType: z.object({
        key: z.union([z.literal(0), z.literal(1)]),
        title: z.string(),
    })
});

type FormTyping = z.infer<typeof formScheme>


export default function MutationModal(props: MutationPaymentTermsModalProperties) {
    const {t} = useTranslation("", {keyPrefix: "settings.general.paymentTerms.modals.mutation"});
    const {t: parentT} = useTranslation("", {keyPrefix: "settings.general.paymentTerms"});

    // Payment terms types dropdown options
    const typeOptions: { key: 0 | 1, title: string }[] = [
        {
            key: 0,
            title: parentT("paymentTypes.daysAfter")
        },
        {
            key: 1,
            title: parentT("paymentTypes.prepayment")
        }
    ];

    // Getting RTK Query hooks for saving data
    const [createPaymentTerms, {isLoading: isCreationLoading}] = useCreatePaymentTermsMutation();
    const [updatePaymentTerms, {isLoading: isUpdateLoading}] = useUpdatePaymentTermsMutation();

    const {control, handleSubmit, setValue, reset} = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
    });

    // Updating values depending on the selected payment terms
    useEffect(() => {
        if(props.isOpen){
            if (props.paymentTerms) {
                setValue("name", props.paymentTerms.name);
                setValue("days", props.paymentTerms.days);

                const option = typeOptions.find(opt => opt.key === props.paymentTerms?.type)
                option && setValue("selectedType", option);
            }
        }else{
            reset();
        }
    }, [props.isOpen, props.paymentTerms]);

    const onSubmit = handleSubmit((data) => {
        if (props.paymentTerms) {
            updatePaymentTerms({
                id: props.paymentTerms.id,
                name: data.name,
                days: data.days,
                type: data.selectedType.key,
            })
            .then(() => {
                toast.success(t("responses.update.success"));
            }).catch(() => {
                toast.error(t("responses.update.error"));
            }).finally(() => {
                props.onClose();
            });
        } else {
            createPaymentTerms({
                name: data.name,
                days: data.days,
                type: data.selectedType.key,
            })
            .then(() => {
                toast.success(t("responses.creation.success"));
            }).catch(() => {
                toast.error(t("responses.creation.error"));
            }).finally(() => {
                props.onClose();
            });
        }
    });

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={562}
            padding="56px"
            useCloseIcon
            isLoading={isCreationLoading || isUpdateLoading}
        >
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent">
                    {
                        props.paymentTerms ?
                            props.paymentTerms.name
                            :
                            t("creationHeading")
                    }
                </div>

                <Controller
                    name={"name"}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            {...fieldState}
                            label={t("fields.name.label")}
                        />
                    )}
                />

                <Controller
                    name={"days"}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            {...fieldState}
                            label={t("fields.days.label")}
                            type={"number"}
                        />
                    )}
                />

                <Controller
                    name={"selectedType"}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}
                            label={t("dropdowns.type.label")}
                            options={typeOptions}
                            getter={{
                                key: opt => opt.key,
                                label: opt => opt.title
                            }}
                        />
                    )}
                />

                <div>
                    <BaseButton
                        buttonWidth="100%"
                        text={`${t("buttons.save")}`}
                        size="md"
                        type={"submit"}
                    />
                </div>
            </form>
        </BaseModal>
    );
}