import { Supplier } from "@/types/general";
import { flattenPaginationRequest, internalApiSlice, PaginationRequest, PaginationResponse, transformResponse } from "../../api/internalApiSlice";

export const suppliersApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSuppliers: builder.query<Supplier.Slim[], void>({
            query: () => ({
                url: `supplier?slim=1`,
            }),
            transformResponse,
            providesTags: [{ type: "Supplier", id: "LIST" }]
        }),

        getSuppliersFull: builder.query<PaginationResponse<Supplier.Root>, PaginationRequest<Supplier.DTO.Filters, Supplier.DTO.OrderBy>>({
            query: (req) => ({
                url: `supplier`,
                params: {
                    ...flattenPaginationRequest(req)
                }
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({id}) => ({type: 'Supplier' as const, id})),
                        {type: 'Supplier', id: 'PARTIAL-LIST'},
                    ]
                    : [{type: 'Supplier', id: 'PARTIAL-LIST'}]
        }),

        getSupplier: builder.query<Supplier.Extended, number>({
            query: (id) => ({
                url: `supplier/${id}`,
                ignoreDatesParsing: ["phone", "vat", "zipcode", "code"]
            }),
            transformResponse,
            providesTags: result => result ? [{ type: "Supplier", id: result.id }] : []
        }),

        getModuleSuppliers: builder.query<Supplier.Slim[], string>({
            query: (module) => ({
                url: `${module}/supplier`
            }),
            transformResponse,
            providesTags: (result) => [{ type: "Supplier", id: "LIST" }]
        }),

        getModuleSupplier: builder.query<Supplier.Extended, { module: string, id: number }>({
            query: ({ module, id }) => ({
                url: `${module}/supplier/${id}`
            }),
            transformResponse,
            providesTags: (result) => result ? [{ type: "Supplier", id: result.id }] : []
        }),

        createSupplier: builder.mutation<Supplier.Extended, Supplier.DTO.Create>({
            query: (body) => ({
                url: `supplier`,
                method: "POST",
                data: body
            }),
            transformResponse,
            invalidatesTags: [{ type: "Supplier", id: "LIST" }, {type: "Supplier", id: "PARTIAL-LIST"}]
            // async onQueryStarted(body, { dispatch, queryFulfilled }) {
            //     try {
            //         const { data: updatedSupplier } = await queryFulfilled;
            //         const createResult = dispatch(suppliersApi.util.updateQueryData("getSuppliers", undefined, (draft) => {
            //             draft.unshift({
            //                 id: updatedSupplier.id,
            //                 name: updatedSupplier.name,
            //                 code: updatedSupplier.code,
            //             });
            //         }));
            //     } catch (err) {
            //         console.error(err);
            //     }
            // }
        }),

        updateSupplier: builder.mutation<Supplier.Extended, Supplier.DTO.Update & { id: number }>({
            query: ({ id, ...body }) => ({
                url: `supplier/${id}`,
                method: "PUT",
                data: body
            }),
            // async onQueryStarted(body, { dispatch, queryFulfilled }) {
            //     try {
            //         const { data: updatedSupplier } = await queryFulfilled;
            //         const updateResult = dispatch(suppliersApi.util.updateQueryData("getSuppliers", undefined, (draft) => {
            //             const index = draft.findIndex((supplier) => supplier.id === updatedSupplier.id);
            //             draft[index] = {
            //                 id: updatedSupplier.id,
            //                 name: updatedSupplier.name,
            //                 code: updatedSupplier.code
            //             };
            //         }));
            //     } catch (err) {
            //         console.error(err);
            //     }
            // },
            transformResponse,
            invalidatesTags: (result, error, { id }) => [{ type: "Supplier", id }]
        }),

        deleteSupplier: builder.mutation<void, number>({
            query: (id) => ({
                url: `supplier/${id}`,
                method: "DELETE"
            }),
            // async onQueryStarted(id, { dispatch, queryFulfilled }) {
            //     try {
            //         await queryFulfilled;
            //         dispatch(suppliersApi.util.updateQueryData("getSuppliers", undefined, (draft) => {
            //             const index = draft.findIndex((supplier) => supplier.id === id);
            //             draft.splice(index, 1);
            //         }));
            //     } catch (e) {
            //         console.error(e);
            //     }
            // },
            transformResponse,
            invalidatesTags: (result, error, id) => [{ type: "Supplier", id }]
        })
    })
});

export const {
    useGetSuppliersQuery,
    useGetSuppliersFullQuery,
    useGetSupplierQuery,
    useGetModuleSuppliersQuery,
    useGetModuleSupplierQuery,
    useCreateSupplierMutation,
    useUpdateSupplierMutation,
    useDeleteSupplierMutation
} = suppliersApi;