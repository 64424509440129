import { useAppDispatch } from "@/redux/hooks";
import React from "react";
import { useExportTransfersMutation } from "@redux/features/transfers/transfersApi";
import BaseExportModal from "@reusables/Modals/BaseExportModal";
import { performExport } from "@redux/features/importExport/export/thunks";
import { adaptToExportRequest, BaseExportModalPropsProvider } from "@reusables/Modals/BaseExportModal/utils";
import { useGetExportableColumnsQuery } from "@redux/features/importExport/importExportApi";

type TransfersExportModalProps = BaseExportModalPropsProvider;

export default function TransfersExportModal(props: TransfersExportModalProps) {
    const dispatch = useAppDispatch();

    const [exportTransfers] = useExportTransfersMutation();

    const { data: columns = [], isLoading: isColumnsLoading } = useGetExportableColumnsQuery({ module: "transfer" });

    return (
        <BaseExportModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            onSubmit={(data) => {
                dispatch(
                    performExport("transfer", () => {
                        return exportTransfers(adaptToExportRequest(props, data)).unwrap();
                    })
                );
            }}
        >
            <BaseExportModal.FiltersInheritanceWarning />
            <BaseExportModal.Email />
            <BaseExportModal.ColumnsSelector columns={columns} isLoading={isColumnsLoading} />
            <BaseExportModal.ConcurrencyWarning />
            <BaseExportModal.Confirm />
        </BaseExportModal>
    );
}