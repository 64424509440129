import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";


import BaseCheckbox from "@reusables/BaseCheckbox";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseButton from "@reusables/BaseButton";

import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useGetCountriesQuery } from "@redux/api/internalApiSlice";
import { useUpdateCompanyAddressesMutation } from "@redux/features/companies/companiesApi";
import { z } from "zod";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import BasePhoneInput from "@reusables/BasePhoneInput";
import { getCountryFlag, removeEmpty, requiredIfAnyFilled } from "@helpers/utils";
import DeliveryAddress
    from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Adresses/components/DeliveryAddress";
import _ from "lodash";
import { setSelectedCompany } from "@redux/features/companies/companiesSlice";
import { isErrorWithMessage } from "@redux/api/query";
import { refinements } from "@helpers/refinements";
import { Can, useAbility } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";

const formScheme = z.object({
    billing_address: z.object({
        id: z.number().optional(),
        name: z.string().optional().refine(refinements.alphaNumeric.refine, refinements.alphaNumeric.message),
        street: z.string().optional().refine(refinements.alphaNumeric.refine, refinements.alphaNumeric.message),
        street_2: z.string().nullable().refine(refinements.alphaNumeric.refine, refinements.alphaNumeric.message).optional(),
        zipcode: z.string().optional().refine(refinements.numericRequired.refine, refinements.numericRequired.message),
        city: z.string().optional().refine(refinements.nonNumeric.refine, refinements.nonNumeric.message),
        country: z.object({
            id: z.number(),
            name: z.string(),
            code: z.string()
        }).optional(),
        phone: z.string().optional(),
        email: z.string().email().optional(),
        contact_name: z.string().optional().refine(refinements.nonNumeric.refine, refinements.nonNumeric.message),
        is_used_for_delivery: z.boolean().optional()
    }).superRefine((scheme, ctx) => {
        requiredIfAnyFilled(scheme, ["is_used_for_delivery", "street_2"])
            .forEach(key => {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    params: {i18n: "general.validation.global.required"},
                    path: [key]
                });
            });
    }),

    deliveries: z.array(
        z.object({
            id: z.number().nullish(),
            name: z.string().nullish().refine(refinements.alphaNumeric.refine, refinements.alphaNumeric.message),
            street: z.string().nullish().refine(refinements.alphaNumeric.refine, refinements.alphaNumeric.message),
            street_2: z.string().nullish().refine(refinements.alphaNumeric.refine, refinements.alphaNumeric.message).optional(),
            zipcode: z.string().nullish().refine(refinements.numericRequired.refine, refinements.numericRequired.message),
            city: z.string().nullish().refine(refinements.nonNumeric.refine, refinements.nonNumeric.message),
            country: z.object({
                id: z.number(),
                name: z.string(),
                code: z.string()
            }).nullish(),
            contact_person: z.string().nullish().refine(refinements.nonNumeric.refine, refinements.nonNumeric.message),
            phone: z.string().nullish(),
            email: z.string().email().nullish(),
            is_primary: z.boolean().nullish()
        })
        .superRefine((scheme, ctx) => {
            requiredIfAnyFilled(scheme, ["is_primary", "street_2"])
                .forEach(key => {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        params: {i18n: "general.validation.global.required"},
                        path: [key]
                    });
                });
        })
    ),

    deleted_deliveries: z.array(z.number())
});

export type AddressesFormTyping = z.infer<typeof formScheme>;

export default function AddressTab() {
    const {t} = useTranslation("", {keyPrefix: "settings.general.companyInformation.tabs.address"});

    const ability = useAbility();

    const dispatch = useAppDispatch();

    const selectedCompany = useAppSelector(state => state.companies.selectedCompany);

    const [updateAddresses, {isLoading: isUpdateLoading}] = useUpdateCompanyAddressesMutation();

    // --> Country selection for billing address <-- //
    const {data: countryOptions = [], isLoading: isCountryOptionsLoading} = useGetCountriesQuery();

    const {control, handleSubmit, setValue, getValues, reset} = useForm<AddressesFormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            deliveries: [{}],
            deleted_deliveries: []
        }
    });

    const {fields: deliveries, append, remove, replace} = useFieldArray({
        control: control,
        name: "deliveries",
        keyName: "loop_id"
    });

    const selectedBillingCountry = useWatch({
        control: control,
        name: "billing_address.country"
    });

    // --> Use primary as primary <-- //
    const handlePrimaryChange = (changedIndex: number) => {
        // Update the form values for is_primary
        const updatedDeliveries = getValues("deliveries").map((delivery, index) => ({
            ...delivery,
            is_primary: index === changedIndex
        }));
        setValue("deliveries", updatedDeliveries);
        setValue("billing_address.is_used_for_delivery", false);
    };

    // --> Use billing address as primary <-- //
    const handleUseAsPrimary = () => {
        // Update the form values for is_primary
        const updatedDeliveries = getValues("deliveries").map((delivery) => ({
            ...delivery,
            is_primary: false
        }));
        setValue("deliveries", updatedDeliveries);
        setValue("billing_address.is_used_for_delivery", true);
    };

    // --> Setting up initial state for editing mode <-- //
    useEffect(() => {
        reset();

        if (selectedCompany) {
            if (selectedCompany?.billing_address) {
                const {billing_address} = selectedCompany;

                setValue('billing_address.id', billing_address.id);
                setValue('billing_address.name', billing_address.name);
                setValue('billing_address.street', billing_address.street);
                setValue('billing_address.street_2', billing_address.street_2);
                setValue('billing_address.zipcode', billing_address.zipcode);
                setValue('billing_address.city', billing_address.city);
                setValue('billing_address.phone', billing_address.phone);
                setValue('billing_address.email', billing_address.email);
                setValue('billing_address.country', billing_address.country);
                setValue('billing_address.contact_name', billing_address.contact_name);
                setValue('billing_address.is_used_for_delivery', billing_address.is_used_for_delivery);
            }

            // Setting deliveries field
            // const deliveries = selectedCompany.deliveries && selectedCompany.deliveries.length > 0 ? selectedCompany.deliveries : [{}];
            selectedCompany.deliveries && selectedCompany.deliveries.length > 0 && replace(selectedCompany.deliveries.map(delivery => ({
                id: delivery.id,
                name: delivery.name,
                street: delivery.street,
                street_2: delivery.street_2,
                zipcode: delivery.zipcode,
                city: delivery.city,
                country: delivery.country,
                phone: delivery.phone,
                email: delivery.email,
                contact_person: delivery.contact_person ?? undefined,
                is_primary: delivery.is_primary
            })));

            // Setting deleted_deliveries field
            setValue('deleted_deliveries', []);
        }
    }, [selectedCompany]);

    const onSubmit = handleSubmit((data) => {
        if (!selectedCompany) return;

        const mutationData = {
            id: selectedCompany.id,

            ...(!_.isEmpty(removeEmpty(data.billing_address)) && {
                billing: {
                    id: data.billing_address.id,
                    name: data.billing_address.name,
                    street: data.billing_address.street,
                    street_2: data.billing_address.street_2 as string | undefined,
                    zipcode: data.billing_address.zipcode,
                    city: data.billing_address.city,
                    country: data.billing_address.country?.id,
                    phone: data.billing_address.phone,
                    email: data.billing_address.email,
                    contact_name: data.billing_address.contact_name,
                    is_used_for_shipping: data.billing_address.is_used_for_delivery ?? false,
                }
            }),

            deliveries: data.deliveries.filter(del => !_.isEmpty(removeEmpty(del))).map(delivery => ({
                id: delivery.id,
                name: delivery.name,
                street: delivery.street,
                street_2: delivery.street_2 as string | undefined,
                zipcode: delivery.zipcode,
                city: delivery.city,
                country: delivery?.country?.id,
                phone: delivery.phone,
                email: delivery.email,
                contact_person: delivery.contact_person,
                is_primary: delivery.is_primary ?? false
            }))
        };

        updateAddresses({
            ...mutationData,
            deleted_deliveries: data.deleted_deliveries
        })
            .unwrap()
            .then(data => {
                toast.success(t("responses.update.success"));

                dispatch(setSelectedCompany(data));
            }).catch((e) => {
                if (isErrorWithMessage(e)) {
                    toast.error(e.message)
                } else {
                    toast.error(t("responses.update.error"));
                }
            });
    });

    return (
        <>
            <Can not I="address.view" a="general">
                <NoPermissionBanner />
            </Can>

            <Can I="address.view" a="general">
                <div className="space-y-[40px]">
                    <form onSubmit={onSubmit}>
                        <div className="text-xl text-accent font-semibold">{t("billingAddress.heading")}</div>
                        <div className="mt-4 mb-4">
                            <Controller
                                name="billing_address.is_used_for_delivery"
                                control={control}
                                render={({field}) => (
                                    <BaseCheckbox
                                        {...field}
                                        label={`${t("useThisAddressCheckbox")}`}
                                        onChange={(e) => {
                                            field.onChange(!field.value);
                                            if (!field.value) {
                                                handleUseAsPrimary();
                                            }
                                        }}
                                        disabled={ability.cannot("address.edit", "general")}
                                    />
                                )}
                            />
                        </div>
                        <BaseInputsGrid>
                            <Controller
                                name="billing_address.name"
                                control={control}
                                render={({field, fieldState}) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("billingAddress.fields.name") + " *"}
                                        disabled={ability.cannot("address.edit", "general")}
                                    />
                                )}
                            />

                            <Controller
                                name="billing_address.street"
                                control={control}
                                render={({field, fieldState}) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("billingAddress.fields.street")}
                                        disabled={ability.cannot("address.edit", "general")}
                                    />
                                )}
                            />

                            <Controller
                                name="billing_address.street_2"
                                control={control}
                                render={({field, fieldState}) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("billingAddress.fields.street2")}
                                        disabled={ability.cannot("address.edit", "general")}
                                    />
                                )}
                            />

                            <Controller
                                name="billing_address.zipcode"
                                control={control}
                                render={({field, fieldState}) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("billingAddress.fields.zipCode")}
                                        disabled={ability.cannot("address.edit", "general")}
                                    />
                                )}
                            />

                            <Controller
                                name="billing_address.city"
                                control={control}
                                render={({field, fieldState}) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("billingAddress.fields.city")}
                                        disabled={ability.cannot("address.edit", "general")}
                                    />
                                )}
                            />

                            <Controller
                                name="billing_address.country"
                                control={control}
                                render={({field, fieldState}) => (
                                    <BaseDropdown
                                        {...field}
                                        error={fieldState.error}
                                        label={t("billingAddress.fields.country")}
                                        placeholder={t("billingAddress.fields.country")}
                                        options={countryOptions}


                                        getter={{
                                            label: (opt) => opt.name,
                                            key: (opt) => opt.id,
                                            renderOption: (opt, icon) => (
                                                <div>
                                                    <img src={getCountryFlag(opt.code)} width={20} alt={opt.code}/>
                                                    <span className="ml-[8px] grow">{opt.name}</span>
                                                    {icon}
                                                </div>
                                            )
                                        }}

                                        isLoading={isCountryOptionsLoading}
                                        autocomplete

                                        disabled={ability.cannot("address.edit", "general")}
                                    />
                                )}
                            />

                            <Controller
                                name="billing_address.email"
                                control={control}
                                render={({field, fieldState}) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("billingAddress.fields.email")}
                                        disabled={ability.cannot("address.edit", "general")}
                                    />
                                )}
                            />

                            <Controller
                                name="billing_address.phone"
                                control={control}
                                render={({field, fieldState}) => (
                                    <BasePhoneInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("billingAddress.fields.phone")}
                                        variant="custom"
                                        size="md"

                                        country={!selectedCompany?.billing_address?.phone ? selectedBillingCountry?.code : undefined}
                                        disabled={ability.cannot("address.edit", "general")}
                                    />
                                )}
                            />

                            <Controller
                                name="billing_address.contact_name"
                                control={control}
                                render={({field, fieldState}) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("billingAddress.fields.contactName")}
                                        disabled={ability.cannot("address.edit", "general")}
                                    />
                                )}
                            />
                        </BaseInputsGrid>

                        {
                            deliveries.map((address, index) => {
                                return <DeliveryAddress
                                    key={address.loop_id}
                                    control={control}
                                    index={index}
                                    onAppend={() => append({})}
                                    onRemove={() => {
                                        if (address.id) {
                                            setValue("deleted_deliveries", [...getValues("deleted_deliveries"), address.id]);
                                        }

                                        remove(index);
                                    }}
                                    onPrimaryChange={handlePrimaryChange}
                                />;
                            })
                        }

                        <div className="flex justify-center space-x-[24px] mt-5">
                            <BasePermissionBlocker action="address.edit" module="general" title="You don't have permission to edit addresses">
                                <BaseButton
                                    loading={isUpdateLoading}
                                    size="md"
                                    text="Save"
                                    type="submit"
                                    disabled={ability.cannot("address.edit", "general")}
                                />
                            </BasePermissionBlocker>
                        </div>
                    </form>
                </div>
            </Can>
        </>
    );
}