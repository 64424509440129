import {
    ExportRequest,
    flattenExportRequest,
    flattenPaginationRequest,
    internalApiSlice,
    PaginationRequest,
    PaginationResponse,
    transformResponse
} from "../../api/internalApiSlice";
import { Stock, Transfer } from "@/types/general";

export const transfersApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTransfersFull: builder.query<
            PaginationResponse<Transfer.Root>,
            PaginationRequest<Transfer.DTO.Filters, Transfer.DTO.OrderBy> & {}
        >({
            query: (req) => ({
                url: "transfer",
                method: "GET",
                params: flattenPaginationRequest(req),
                parseDates: ["date"]
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        // Adding all products and their variants to the cache
                        ...(result.payload.flatMap((transfer) => [
                                { type: "Transfer", id: transfer.product.id },
                                { type: "Product", id: transfer.product.id },
                                { type: "Location", id: transfer.location_from.id },
                                { type: "Location", id: transfer.location_to.id }
                            ]
                        )),
                        { type: "Transfer", id: "PARTIAL-LIST" }
                    ]
                    : [{ type: "Transfer", id: "PARTIAL-LIST" }]
        }),

        getTransfer: builder.query<Transfer.Root, number>({
            query: (id) => ({
                url: `transfer/${id}`,
                parseDates: ["date"]
            }),
            transformResponse,
            providesTags: (resp, error, id) => [{ type: "Transfer", id }]
        }),

        createTransfer: builder.mutation<Transfer.Extended, Transfer.DTO.Create>({
            query: (body) => ({
                url: `transfer`,
                method: "POST",
                data: body,
                parseDates: ["date"]
            }),
            // TODO: make product data invalidation smarter (which also should solve the problem of Stock invalidation)
            invalidatesTags: [{ type: "Transfer", id: "LIST" }, "Stock", "Picking", "Product"]
        }),

        deleteTransfer: builder.mutation<void, number>({
            query: (id) => ({
                url: `transfer/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (_, __, id) => [{ type: "Transfer", id }]
        }),

        exportTransfers: builder.mutation<void, ExportRequest<Transfer.DTO.Filters>>({
            query: (args) => ({
                url: `transfer/export`,
                responseType: "blob",
                method: "POST",
                data: flattenExportRequest(args)
            })
        })
    })
});

export const {
    useGetTransfersFullQuery,
    useGetTransferQuery,
    useCreateTransferMutation,
    useDeleteTransferMutation,
    useExportTransfersMutation
} = transfersApi;