import React from "react";
import BaseButton from "@reusables/BaseButton";
import BaseModal from "@reusables/Modals/BaseModal";
import { isErrorWithMessage } from "@redux/api/query";
import { disableIntegration } from "@redux/features/auth/authSlice";
import { useDetachIntegrationMutation } from "@redux/features/integrations/integrationsApi";
import { useAppDispatch } from "@redux/hooks";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Integration } from "@/types/general";

interface DisableModalProps {
    isOpen: boolean;
    onClose: () => void;
    integrationName?: string;
    slug?: Integration.Components.Slug;
}

export default function DisableModal({ isOpen, onClose, slug, integrationName }: DisableModalProps) {

    const { t } = useTranslation("", { keyPrefix: "settings.integrations.thirdParty.modals.disable" });

    const dispatch = useAppDispatch();

    const [disableIntegrationQuery, { isLoading: isDisablingLoading }] = useDetachIntegrationMutation();

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={800}
            padding="45px"
            useCloseIcon
            isLoading={isDisablingLoading}
        >
            <div className="space-y-8">
                <div className="text-xl font-semibold text-center text-accent mb-4">
                    {t("heading", { integrationName })}
                </div>
                <div>
                    <div className="text-center text-accent mb-2">
                        <Trans i18nKey="settings.integrations.thirdParty.modals.disable.subheading"
                               values={{ integrationName }}
                               components={{ strong: <span className="font-semibold" /> }} />
                    </div>
                    <div className="text-center text-accent">
                        {t("warning")}
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={onClose} />
                    <BaseButton text={t("buttons.disable")} size="md" onClick={() => {
                        if (slug) {
                            disableIntegrationQuery(slug)
                                .unwrap()
                                .then(() => {
                                    toast.success(t("responses.success"));
                                    dispatch(disableIntegration(slug));
                                })
                                .catch((e) => {
                                    if (isErrorWithMessage(e)) {
                                        toast.error(e.message);
                                    } else {
                                        toast.error(t("responses.error"));
                                    }
                                })
                                .finally(() => onClose());
                        }
                    }} />
                </div>
            </div>
        </BaseModal>
    );
}
