import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import NavigationBar from "../NavigationBar";
import FooterBlock from "../FooterBlock";

import "./index.scss";

import WaveImg from "@assets/images/waveIcon.svg";
import angleImgOne from "@assets/images/angleOne.png";
import angleImgTwo from "@assets/images/angleTwo.png";
import angleImgThree from "@assets/images/angleThree.png";
import angleImgFour from "@assets/images/angleFour.png";

import axios from "axios";
import {Typography} from "@mui/material";
import i18nInstance from "@/i18n";

// import ArticleLoader from "../ArticleLoading/ArticleLoading";

const testImgArr = [angleImgOne, angleImgTwo, angleImgThree, angleImgFour];

interface PostPageInterface {
  headImg: string;
  headerText: string;
  authorDate: string;
  previewText: string;
  previewHeader: string;
  text: string;
}

interface RouteParams {
  slug: string;
}

const PostPage: React.FC = (props) => {
  // const { headImg, headerText, authorDate, previewText, previewHeader, text } =
  //   props;

  // router parameters.
  const { slug } = useParams<RouteParams>();

  // loading state
  const [loading, setLoading] = useState(true);

  /**
   * @description
   * Blog Post stateful variables.
   *
   * @info
   * Empty by default. Are updated in useEffect below.
   */
  const [headImg, setHeadImg] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [authorDate, setAuthorDate] = useState("");
  const [previewText, setPreviewText] = useState("");
  const [previewHeader, setPreviewHeader] = useState("");
  const [richText, setRichText] = useState("");
  const [imgPlugs, setImagePlugs] = useState<any>([]);
  const [metaData, setMetaData] = useState({metaTitle: "Suppli", metaDescription: ""});

  const adminPanelUrl = `https://admin.suppli.cloud`;

  useEffect(() => {
    const locale = i18nInstance.language === "no" ? "nb" : "en"

    axios
      .get(`${adminPanelUrl}/api/posts?filters[slug][$eq]=${slug}&populate=*&locale=${locale}`)
      .then((res) => {
        setLoading(true);
        console.log(res.data);

        setHeadImg(
          adminPanelUrl +
          "/" +
          res?.data?.data[0]?.attributes?.coverImg?.data?.attributes?.url
        );
        setHeaderText(res?.data?.data[0]?.attributes?.header);
        setAuthorName(res?.data?.data[0]?.attributes?.author?.fullName);
        setAuthorDate(res?.data?.data[0]?.attributes?.author?.date);
        setPreviewText(res?.data?.data[0]?.attributes?.description);
        setPreviewHeader(res?.data?.data[0]?.attributes?.smallHeader);
        setRichText(res?.data?.data[0]?.attributes?.mainText);
        setImagePlugs(res?.data?.data[0]?.attributes?.imgPlugs?.data);
        setMetaData({metaTitle: res?.data?.data[0]?.attributes?.metaTitle, metaDescription: res?.data?.data[0]?.attributes?.metaDescription})
        document.title = res?.data?.data[0]?.attributes?.metaTitle
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
    <meta name="description" content={metaData.metaDescription} />
      <div className="blog-page">
        <NavigationBar />
        <main className="main">
          {loading && (
            <div style={{ textAlign: "center" }}>
              {/* <ArticleLoader /> */}
              LOADING
            </div>
          )}
          {!loading && (
            <div className="container-lg">
              <div className="d-flex justify-content-center">
                <img src={headImg} width="100%" height="100%" className="" />
              </div>
              <div className="header-block">
                <img src={WaveImg} />
                <div className="header-blog">{headerText}</div>
                <div className="description">
                  {authorName} · {authorDate}
                </div>
              </div>
              <div className="page-text">
                <div className="gradient-txt">{previewHeader}</div>
                <div className="preview description">{previewText}</div>
                <div className="text description leading-[2.05]">
                  <Typography
                    sx={{
                      "&.MuiTypography-root a": {
                        textDecoration: "underline !important",
                      },
                      "&.MuiTypography-root a:hover": { color: "#B1B2E1" }
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: richText }} />
                  </Typography>
                </div>
              </div>
              <div className="img-block-list">
                {imgPlugs?.map((imgSrc: any, idx: number) => (
                  <img
                    key={idx}
                    src={adminPanelUrl + imgSrc.attributes.url}
                    style={{ objectFit: "contain", minWidth: "20rem" }}
                    className="img-block-list-el"
                  />
                ))}
              </div>
            </div>
          )}
        </main>
      </div>
      <FooterBlock />
    </>
  );
};

export default PostPage;
