import axios from "axios";
import { parseDate } from "@helpers/utils";

export const BASE_API_URL = import.meta.env.VITE_API_URL || 'http://127.0.0.1:8000';
console.log("🚀 ~ BASE_API_URL:", BASE_API_URL)

const caxios = axios.create({
  baseURL: BASE_API_URL+"/api/"
});


declare module 'axios' {
  interface AxiosRequestConfig {
    parseDates?: string[];
  }
}

caxios.interceptors.response.use(originalResponse => {
  originalResponse.config.parseDates?.forEach(field => {
    parseDate(originalResponse.data.payload, field);
  })


  return originalResponse;
})

export default caxios;
