import React, { forwardRef } from "react";
import { Check } from "@mui/icons-material";
import { Checkbox, FormControlLabel, styled, Tooltip, TooltipProps } from "@mui/material";
import Info from "@assets/images/Vector.svg";
import { FieldError } from "react-hook-form";
import { cn } from "@helpers/utils";

type Props = {
    label?: string | JSX.Element;
    onChange?: (checked: boolean, evt: React.SyntheticEvent) => any;
    value?: boolean | null;
    className?: string;
    tooltipTitle?: string;
    disabled?: boolean;

    /**
     * If specified, the checkbox will be highlighted with red color.
     * BaseCheckbox doesn't use any data from that field and it accepts "FieldError" only for compatibility with react-hook-form.
     */
    error?: boolean | FieldError;
};

// custom tooltip for purchases more popover icons
const BlueOnGreenTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ tooltip: className }} />
))(`
    color: #3C3769;
    background-color: #C8E98E;
    font-size: 14px;
    font-weight: 300;
    border-radius: 6px;
    padding: 8px;
    & .MuiTooltip-arrow {
        color: #C8E98E;
    }
`);

/**
 *  Apart from being redesigned checkbox, supports adding "Tooltip". To activate this feature, specify "tooltipTitle" argument.
 *  Note: if no className specified, uses "flex items-center" classes.
 */
export default forwardRef<HTMLButtonElement, Omit<Props, "checked">>(function BaseCheckbox({
                                                                                               label,
                                                                                               value,
                                                                                               onChange,
                                                                                               className,
                                                                                               tooltipTitle,
                                                                                               disabled,
                                                                                               ...rest
                                                                                           }, ref) {
    return (
        <div className={cn(className, "flex items-center text-accent font-thin", disabled && "opacity-50")}>
            <FormControlLabel
                {...rest}
                control={
                    <Checkbox
                        ref={ref}
                        sx={{
                            background: "rgba(166, 221, 76, 0.25) !important",
                            padding: "6px",
                            borderRadius: "8px",
                            marginLeft: "10px",
                            color: "transparent",
                            ...(!!rest.error && {
                                background: "rgba(226, 92, 92, 0.25) !important"
                            }),
                            ".MuiSvgIcon-root": {
                                width: "16px",
                                height: "16px",
                                opacity: disabled ? value ? 1 : 0 : 1,
                                transition: ".15s",
                                borderRadius: "4px"
                            },
                            "&.Mui-checked": {
                                color: "white",
                                ".MuiSvgIcon-root": {
                                    background: "#a6dd4c",
                                    borderRadius: "8px",
                                    "& path": {
                                        opacity: 0
                                    }
                                }
                            }
                        }}
                        checkedIcon={<Check />}
                    />
                }
                sx={{
                    ".MuiFormControlLabel-label": {
                        marginLeft: 1,
                        color: "#3C3769 !important"
                    },
                    "&:hover": {
                        ".MuiCheckbox-root": {
                            background: "rgba(166, 221, 76, 0.25) !important",
                            ".MuiSvgIcon-root": {
                                background: "#a6dd4c"
                            }
                        }
                    }
                }}
                value={value ?? false}
                label={label ?? ""}
                onChange={(evt, state) => {
                    onChange?.(state, evt);
                }}
                disabled={disabled}
                checked={value ?? false}
            />
            {tooltipTitle && (
                <BlueOnGreenTooltip title={tooltipTitle}>
                    <img src={Info} alt={tooltipTitle} />
                </BlueOnGreenTooltip>
            )}
        </div>
    );
});