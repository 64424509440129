import React, {useEffect, useRef, useState} from "react";
import NavigationBar from "../NavigationBar";

import "./index.scss";

// product slide images
import HandsFourPNG from "@assets/images/handsFour.png";

import {sendPartnersClick, sendPartnerUp} from "@helpers/partners"

// import RivalsPNG from "@assets/images/partners/rivals.png";
// partner logos

// partners

import PopupBanner from "@assets/images/partners/popup-banner.svg"


import {ReactComponent as Rivals} from "@assets/images/partners/rivals.svg"
import {ReactComponent as Unitech} from "@assets/images/partners/unitech.svg"
import {ReactComponent as Icore} from "@assets/images/partners/icore.svg"
import {ReactComponent as Sendify} from "@assets/images/partners/sendify.svg"
import {ReactComponent as Two} from "@assets/images/partners/two.svg"
import {ReactComponent as Aketo} from "@assets/images/partners/aketo.svg"
import {ReactComponent as Twelvedevs} from "@assets/images/partners/twelvedevs.svg"
import {ReactComponent as Shipstation} from "@assets/images/partners/shipstation.svg"
import {ReactComponent as Freidesk} from "@assets/images/partners/freidesk.svg"
import {ReactComponent as Stripe} from "@assets/images/partners/stripe.svg"
import {ReactComponent as Stream} from "@assets/images/partners/stream.svg"
import {ReactComponent as Addimotion} from "@assets/images/partners/addimotion.svg"
import {ReactComponent as Bill} from "@assets/images/partners/bill.svg"
import {ReactComponent as Fortnox} from "@assets/images/partners/fortnox.svg"

import {ReactComponent as RivalsBanner} from "@assets/images/partners/banners/rivals.svg"
import {ReactComponent as UnitechBanner} from "@assets/images/partners/banners/unitech.svg"
import {ReactComponent as SendifyBanner} from "@assets/images/partners/banners/sendify.svg"
import {ReactComponent as TwoBanner} from "@assets/images/partners/banners/two.svg"
import {ReactComponent as TwelvedevsBanner} from "@assets/images/partners/banners/twelvedevs.svg"
import {ReactComponent as ShipstationBanner} from "@assets/images/partners/banners/shipstation.svg"
import {ReactComponent as FreideskBanner} from "@assets/images/partners/banners/freidesk.svg"
import {ReactComponent as StripeBanner} from "@assets/images/partners/banners/stripe.svg"
import {ReactComponent as StreamBanner} from "@assets/images/partners/banners/stream.svg"
import {ReactComponent as AddimotionBanner} from "@assets/images/partners/banners/addimotion.svg"
import {ReactComponent as BillBanner} from "@assets/images/partners/banners/bill.svg"
import {ReactComponent as FortnoxBanner} from "@assets/images/partners/banners/fortnox.svg"
import AketoBanner from "@assets/images/partners/banners/aketo.png";
import IcoreBanner from "@assets/images/partners/banners/icore.png";


import {ReactComponent as ArrowDown} from "@assets/images/icons/arrow-down.svg"
import {ReactComponent as ArrowDownClicked} from "@assets/images/icons/arrow-down-clicked.svg"
import {ReactComponent as Balloons} from "@assets/images/partners/Balloons.svg"

import FooterBlock from "../FooterBlock";
import {useTranslation} from "react-i18next";
import BaseModal from "@reusables/Modals/BaseModal";
import PhoneInput from "react-phone-input-2";
import ReCAPTCHA from "react-google-recaptcha"
import {toast} from "react-toastify";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BasePhoneInput from "@reusables/BasePhoneInput";

interface PartnerUpInterface {
    contact_person: string;
    email: string;
    phone: string;
    company_name: string;
}

function PartnersPage() {
    const { t } = useTranslation();

    const [partnerSlider, setPartnerSlider] = useState<number>(0)

    const filters: Array<string> = ["All", "Hardware", "Payment B2B", "Development/Integrations", "Logistics", "Business community", "Accounting"]
    const [currentFilter, selectFilter] = useState<string>("All")
    const [allCountries, setAllCountries] = useState<Array<string>>(["All countries", "UK", "Taiwan", "Norway", "Sweden", "USA", "Lithuania", "Ireland", "Poland"]);
    const [currentCountry, setCurrentCountry] = useState<string>("All countries")
    const [currentPartner, setCurrentPartner] = useState<number>(-1)
    const [submitted, setSubmit] = useState<boolean>(false);
    const [token, setToken] = useState<string>("");

    const recaptchaRef = useRef<ReCAPTCHA>(null);
    // partners.map(partner => {
    //     if (!(partner.country in allCountries)) setAllCountries([...allCountries, partner.country]) 
    // })

    const [isOpen, openModal] = useState<boolean>(false);
    const [partnerFormOpen, openPartnerForm] = useState<boolean>(false);

    const [data, setData] = useState<PartnerUpInterface>({
        contact_person: "",
        email: "",
        phone: "",
        company_name: ""
    })

    useEffect(() => console.log(window.innerHeight), [window.innerHeight])


    const partners: { name: string; logo: JSX.Element; url: string; country: string; type: string; description: string, banner: JSX.Element; }[] = [
        {
            name: "Rivals Software",
            logo: <Rivals className="mt-auto mb-auto min-h-[96px] max-h-[96px]" />,
            url: "https://rivalssoftware.com/",
            country: "UK",
            type: "",
            description: `
            Rivals Software is an innovative software development company that specializes in developing ready-to-deploy and customized software solutions, as well as state-of-the-art websites. The company serves a wide range of industries, including healthcare, finance, education, and retail, among others.

Rivals Software's mission is to help businesses improve their operations and increase their productivity through the use of cutting-edge software technologies.

The company has a team of highly skilled software developers and engineers dedicated to providing effective solutions to businesses of all sizes.
            `,
            banner: <RivalsBanner />
        },
        {
            name: "Unitech",
            logo: <Unitech className="mt-auto mb-auto min-h-[96px] max-h-[96px]" />,
            url: "https://www.ute.com/en",
            country: "Taiwan",
            type: "Hardware",
            description: `
            With more than four decades of expertise, Unitech Electronics is a worldwide supplier of AIDC(Automatic Identification and Data Capture) technologies.The company delivers an extensive array of products, including enterprise mobile computers, rugged handheld PDAs, industrial tablets, barcode scanners, RFID readers, and IoT solutions.
Unitech provides Suppli with a wide range of equipment to power up our Warehouse Management Solution. 
Our goal is to collaboratively help our customers succeed in their respective industries.Unitech's product portfolio makes sure that we can offer suitable equipment for all environments.

            `,
            banner: <UnitechBanner />
        },
        {
            name: "iCore Solutions AB",
            logo: <Icore className="mt-auto mb-auto min-h-[96px] max-h-[96px]" />,
            url: "https://www.icoresolutions.com/en",
            country: "Sweden",
            type: "Development/Integrations",
            description: `
            Over the past two decades, the mission of iCore Solutions AB has been to simplify complex IT structures and solve challenging business problems by providing straightforward solutions.

With a proven track record, the iCore team has earned the trust of over 1,500 companies in more than 30 countries, consistently delivering tangible results.

The main goal of the company is to empower businesses to effectively manage their processes and unlock the true potential hidden within their data. By doing so, iCore Solutions AB enables them to unlock new opportunities and achieve sustainable growth.
            `,
            banner: <img src={IcoreBanner} alt={"icore banner"}/>
        },
        {
            name: "Sendify",
            logo: <Sendify className="mt-auto mb-auto min-h-[96px] max-h-[96px]" />,
            url: "https://www.sendify.se/",
            country: "Sweden",
            type: "Logistics",
            description: `
            Sendify is a web-based shipping service specifically designed to cater to the needs of businesses aiming to maximize efficiency and minimize costs. With its user-friendly platform, Sendify empowers anyone to optimize their supply chain and make smarter shipping decisions.

Since its establishment in 2016, Sendify has successfully assisted over 15,000 small businesses worldwide in saving time and money on their shipping operations, allowing them to focus on business growth.

This track record has made Sendify the go-to shipping and logistics solution for streamlined operations and business growth.
            `,
            banner: <SendifyBanner />
        },
        {
            name: "Two",
            logo: <Two className="mt-auto mb-auto min-h-[96px] max-h-[96px]" />,
            url: "https://www.two.inc/)",
            country: "Norway",
            type: "Payment B2B",
            description: `
            Two revolutionizes the B2B payment industry with its all-inclusive payment suite, streamlining the selling process for online and offline business customers.

With Two's innovative Buy Now, Pay Later solutions, businesses can offer their B2B customers flexible and interest-free credit options. The unique advantage lies in eliminating credit risk and the necessity for invoice factoring.

Two's payment suite empowers businesses to increase sales through upfront payments while ensuring no associated risks, enhance cash flow, and create a seamless and secure payment experience for their B2B customers.

            `,
            banner: <TwoBanner />
        },
        {
            name: "Twelvedevs",
            logo: <Twelvedevs className="mt-auto mb-auto min-h-[96px] max-h-[96px]" />,
            url: "https://twelvedevs.com/",
            country: "Poland",
            type: "Development/Integrations",
            description: `
            Twelvedevs, a software development company established in 2012, stands out as a specialist in providing exceptional engineering and consulting services.
 
Having successfully operated on the global stage for over a decade, the company has accumulated invaluable expertise and cultivated an extensive network of niche experts and consultants.

Twelvedevs does not only possesses extensive knowledge and advanced technology but also excels at transforming these assets into tangible value for businesses. Their expertise and solutions are specifically designed to make operations more manageable and predictable.

            `,
            banner: <TwelvedevsBanner />
        },
        {
            name: "ShipStation",
            logo: <Shipstation className="mt-auto mb-auto min-h-[96px] max-h-[96px]" />,
            url: "https://www.shipstation.com/",
            country: "USA",
            type: "Logistics",
            description: `
            ShipStation is the leading global web-based solution that combines order management and shipping software to enhance retailers' efficiency in processing, fulfilling, and shipping e-commerce orders.

The robust functionality enables merchants to efficiently manage their orders, automate shipping labels, and easily track packages, empowering businesses to optimize their shipping operations and deliver an amazing customer experience. 

With its user-friendly interface and seamless integration with popular e-commerce platforms, ShipStation is the top choice for businesses seeking to optimize their shipping operations.

            `,
            banner: <ShipstationBanner />
        },
        {
            name: "Freidesk",
            logo: <Freidesk className="mt-auto mb-auto min-h-[96px] max-h-[96px]" />,
            url: "https://freidesk.com/",
            country: "Lithuania",
            type: "Logistics",
            description: `
            Freidesk, a logistics software company, is driven by the mission to simplify freight logistics. With their innovative approach, Freidesk offers a superior method to ship goods effectively for businesses of all sizes.

Their innovative software streamlines order management, enabling companies to reduce repetitive tasks, optimize their freight logistics operations and concentrate on core business activities.

With a strong commitment to effortless logistics management, Freidesk aims to create transport and warehouse time slot software that empowers businesses to achieve hassle-free logistics processes.

            `,
            banner: <FreideskBanner />
        },
        {
            name: "Stripe",
            logo: <Stripe className="mt-auto mb-auto min-h-[96px] max-h-[96px]" />,
            url: "https://stripe.com/",
            country: "Ireland",
            type: "Payment B2B",
            description: `
            Stripe stands out as a prominent global B2B payment software, trusted by millions of clients across more than 180 countries. 

It offers a comprehensive suite of fully integrated payment products, enabling companies to seamlessly accept payments, facilitate global payouts, boost revenue, and expedite the pursuit of new business prospects.
 
Stripe's commitment to simplifying fund transfers ensures a seamless and flexible experience. With its international teams stationed in various offices, Stripe efficiently handles fund processing for businesses of all scales, united by their ambitious goals.
`,
            banner: <StripeBanner />
        },
        {
            name: "Stream",
            logo: <Stream className="mt-auto mb-auto min-h-[96px] max-h-[96px]" />,
            url: "https://go2stream.com/",
            country: "UK",
            type: "Logistics",
            description: `
            Stream offers a comprehensive logistics, fleet maintenance, and transport management software solution that simplifies the complexities of these operations. It allows businesses to effectively manage their deliveries, collections, vehicles, drivers, and orders, all from a single platform.

As a multi-award-winning and cost-effective system, Stream empowers companies of all sizes with complete control and real-time information throughout the entire order-to-delivery process, providing valuable insights.

With Stream, businesses can enhance their customer service and efficiently handle higher order volumes.

            `,
            banner: <StreamBanner />
        },
        {
            name: "Addimotion",
            logo: <Addimotion className="mt-auto mb-auto min-h-[96px] max-h-[96px]" />,
            url: "https://addimotion.com/",
            country: "Sweden",
            type: "Logistics",
            description: `
            Addimotion specializes in cloud services that unlock the full potential of the supply chain, make processes more transparent and enable logistics, customer service, and sales teams to be proactive in their approach.

OurBoxes, their product, revolutionizes the distribution process for e-retailers. With a single platform, it empowers businesses to have real-time tracking of all shipments and ensure proactive management of potential issues, often before customers even notice.

With Addimotion's innovative solutions, it is easy to streamline distribution operations and elevate the overall customer experience.

            `,
            banner: <AddimotionBanner />
        },
        {
            name: "Bill",
            logo: <Bill className="mt-auto mb-auto min-h-[96px] max-h-[96px]" />,
            url: "https://www.bill.com/",
            country: "USA",
            type: "Payment B2B",
            description: `
            BILL is an esteemed industry leader in financial automation software, catering specifically to small and midsize businesses. With the trust of hundreds of thousands of businesses, they deliver comprehensive solutions that seamlessly manage vital financial workflows, including payables, receivables, and spend and expense management.

            Their mission is to simplify business connections, making it effortless for clients to thrive. That is why they offer an expansive network of millions of members, enabling faster payments and transactions.
            
            Whether you're a startup, an established brand, a nonprofit, or a franchise, BILL will help you unlock the potential for growth and success.
            `,
            banner: <BillBanner />
        },
        {
            name: "Fortnox",
            logo: <Fortnox className="mt-auto mb-auto min-h-[96px] max-h-[96px]" />,
            url: "https://www.fortnox.se",
            country: "Sweden",
            type: "Accounting",
            description: `
        Fortnox is Sweden's top provider of software solutions for companies, associations, and accounting and auditing firms. They excel at delivering a wide range of powerful and user-friendly programs to meet diverse needs.

            The company's vision is to foster a thriving society propelled by successful companies.
            
            No matter what your business goals are, Fortnox is dedicated to supporting you throughout your journey.With their extensive network and deep expertise, they assist you in overcoming obstacles and turning your plans into reality with greater ease.Count on Fortnox to be there every step of the way, helping you reach your goals effortlessly.
            `,
            banner: <FortnoxBanner />
        },
        {
            name: "Aketo AS",
            logo: <Aketo className="mt-auto mb-auto min-h-[96px] max-h-[96px]" />,
            url: "https://www.aketo.no/",
            country: "Norway",
            type: "Accounting",
            description: `
            Aketo AS is a progressive accounting firm based in Oslo and Larvik. They utilize advanced techniques to simplify and enhance accounting processes. Although based in two cities, Aketo AS serves clients nationwide.

            The team at Aketo has vast expertise in accounting, auditing, and consultancy. They focus on streamlining tasks, particularly using the PowerOffice Go system, which leads to significant time and cost benefits for their clients.
            
            Aketo's proficiency aligns well with Suppli's standards. Suppli seeks partnerships with accounting firms that excel in their system use and service, and Aketo surely meets this criterion.
            `,
            banner: <img src={AketoBanner} alt={"aketo banner"}/>
        }
    ]

    const handlePartnerSliderChange = (idx: number) => {
        setPartnerSlider(idx)
    }

    return (
        <>
            <NavigationBar />

            <main className="main">
                <section className="section main-section relative h-[70vh]">
                    <div className="container-lg min-w-[500px]">
                        <BaseModal
                            isOpen={isOpen}
                            onClose={() => openModal(false)}
                            width={1248}
                            height={780}
                            padding="56px"
                            useCloseIcon={true}
                        >

                            {isOpen === true ?
                                <div className="flex mt-auto mb-auto min-h-[100%]">
                                    <div className="font-[300] text-[16px] text-[#686868] leading-[32px] max-w-[680px] max-h-[550px] mb-auto mt-auto">
                                        <h1 className="font-[700] text-[32px] leading-[40px] lg:text-[44px] lg:leading-[55.88px] text-[#2B2A35] mb-[20px]">{partners[currentPartner].name}</h1>
                                        <p onClick={() => {
                                            sendPartnersClick(partners[currentPartner].name)
                                            window.open(partners[currentPartner].url, '_blank')
                                        }} className="underline cursor-pointer">{partners[currentPartner].url}</p>
                                        <p className="whitespace-pre-line">{partners[currentPartner].description}</p>
                                    </div>
                                    <div className="mr-auto ml-auto mt-[8%] mb-auto">{partners[currentPartner].banner}</div>
                                </div>
                                : ""}
                            <div>
                            </div>
                            {/* <Rectangle />
                            <Freidesk /> */}
                        </BaseModal>

                        <BaseModal
                            isOpen={partnerFormOpen}
                            onClose={() => {
                                openPartnerForm(false)
                                setSubmit(false)
                            }}
                            width={submitted ? 1096 : (window.innerWidth > 920 ? 920 : window.innerWidth)}
                            padding={((window.innerHeight / 12.5) / (window.innerHeight / 280) + (window.innerWidth / 100)).toString() + "px"}
                            useCloseIcon
                            position={"center"}
                            closeIconClass="top-[20px] right-[26px]"
                            sx={{
                                overflowX: "hidden",
                                overflowY: "hidden",
                                "& .MuiDialogContent-root" : {
                                    backgroundColor: submitted ? "#8850F4" : "#fff"
                                }
                            }}
                        >
                            {!submitted ? <div>
                                <h1 className="font-[700] text-[44px] leading-[55.88px] text-[#3C3769] text-center mx-auto mb-16 min-w-[360px] mt-10">{t("partnersPage.partnersPopup.lets")}</h1>
                                <form className="font-[600] text-[16px] leading-[24px] text-[#686868]">
                                    <BaseInputsGrid cols={{
                                        md: 2,
                                        sm: 1
                                    }}>
                                        <div className="space-y-[8px]">
                                            <label>{t("partnersPage.partnersPopup.companyName")}</label>
                                            <input className="border-none bg-[#F9F9F9] w-full h-[68px] p-[16px] rounded-lg mx-auto" onChange={e => setData({ ...data, company_name: e.target.value })} />
                                        </div>
                                        <div className="space-y-[8px]">
                                            <label>{t("partnersPage.partnersPopup.email")}</label>
                                            <input className="border-none bg-[#F9F9F9] w-full h-[68px] p-[16px] rounded-lg mx-auto" onChange={e => setData({ ...data, email: e.target.value })} />
                                        </div>
                                        <div className="space-y-[8px]">
                                            <label>{t("partnersPage.partnersPopup.phone")}</label>
                                            <BasePhoneInput
                                                value={data.phone}
                                                onChange={phone => {
                                                    setData({ ...data, phone: phone ?? "" })
                                                }}
                                                inputStyle={{
                                                    height: "68px",
                                                }}
                                            />
                                        </div>
                                        <div className="space-y-[8px]">
                                            <label>{t("partnersPage.partnersPopup.contactPerson")}</label>
                                            <input className="border-none bg-[#F9F9F9] w-full h-[68px] p-[16px] rounded-lg mx-auto" onChange={e => setData({ ...data, contact_person: e.target.value })} />
                                        </div>
                                    </BaseInputsGrid>
                                </form>
                                <div className="mt-8 mr-auto ml-auto">
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        // size="invisible"
                                        sitekey="6LerlVEoAAAAACdChAqo1rDr59GL-FM2biSxR0Xw"
                                        className="mr-auto ml-auto w-fit"
                                        onChange={e => {
                                            const tokenValue = recaptchaRef.current?.getValue()
                                            console.log(tokenValue)
                                            if (tokenValue) setToken(tokenValue)
                                        }}
                                    />
                                    <script src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
                                        async defer>
                                    </script>
                                </div>
                                <button
                                    className="btn btn-filled rounded-lg ml-4 w-[95%] lg:w-[300px] h-[56px] mt-[20px] lg:ml-auto lg:mr-auto"
                                    onClick={() => {
                                        if (token === "") toast.error("Please pass the robot verification. This helps us prevent spam messages and bots.")
                                        else {
                                            const successful = sendPartnerUp(data, token).then(response => {
                                                console.log("RESPONSE:", response)
                                                if (response === true) setSubmit(true)
                                                else toast.error("Please try again.")
                                                console.log("EE")
                                            })
                                                .catch(err => {
                                                    console.log("ERR OVDE", err)
                                                    toast.error("Please try again.")
                                                })
                                        }
                                    }}>
                                    {t("partnersPage.partnersPopup.getStarted")}
                                </button>
                            </div> :
                                <div className="h-[327px] w-min rounded-xl"><div className="min-h-[100%] min-w-[100%] flex my-auto">
                                    <img src={PopupBanner} />
                                    <div className="ml-[80px] text-[#FFFFFF] my-auto">
                                        <h1 className="font-[700] text-[44px] leading-[55.88px] text-[#FFFFFF] mr-auto ml-auto w-[588px] mb-[24px]">Thank You for the Interest in Partnering with Suppli!</h1>
                                        <p className="font-[400] text-[16px] leading-[32.8px] w-[528px]">We are excited to drive success together. Our team will get in touch within 3 working days to provide further details!</p>
                                        <div className="flex gap-x-[24px] mt-[24px]">
                                            <button className="w-[251px] h-[52px] rounded-lg border-none font-[600] text-[16px] leading-[24px] bg-[#C8E98E] text-[#3C3769]" onClick={() => window.location.href = "/partners"}>Explore Partner Network</button>
                                        </div>
                                    </div>
                                </div>
                                </div>}
                        </BaseModal>
                        <div className="main-txt pt-[50px] z-1">
                            <span className="main-title w-[800px] max-w-[90%] z-1">
                                <svg
                                    width="85"
                                    height="18"
                                    viewBox="0 0 85 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3.31445 16.6711L9.28113 4.73778C10.0512 3.19773 12.2489 3.19773 13.0189 4.73778L17.1167 12.9334C17.8867 14.4734 20.0845 14.4734 20.8545 12.9334L24.9523 4.73778C25.7223 3.19773 27.92 3.19773 28.69 4.73778L32.7878 12.9334C33.5579 14.4734 35.7556 14.4734 36.5256 12.9334L40.6234 4.73778C41.3934 3.19773 43.5912 3.19773 44.3612 4.73778L48.459 12.9334C49.229 14.4734 51.4267 14.4734 52.1967 12.9334L56.2945 4.73778C57.0646 3.19773 59.2623 3.19773 60.0323 4.73778L64.1301 12.9334C64.9001 14.4734 67.0979 14.4734 67.8679 12.9334L71.9657 4.73778C72.7357 3.19773 74.9334 3.19773 75.7035 4.73778L81.6701 16.6711"
                                        stroke="url(#paint0_linear_12_1733)"
                                        strokeWidth="5.22371"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="paint0_linear_12_1733"
                                            x1="3.31445"
                                            y1="7.90105"
                                            x2="81.2809"
                                            y2="13.9363"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#8850F4" />
                                            <stop
                                                offset="1"
                                                stopColor="#6959FC"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                {t("partnersPage.welcomeBlock.heading")}
                            </span>
                            <div className="main-descr w-[555px] max-w-[90%]">
                                {t("partnersPage.welcomeBlock.subheading")}
                            </div>
                            <div className="flex space-x-[32px]">
                                <button className="btn btn-filled rounded-lg min-w-[250px] min-h-[56px] px-[16px] py-[32px]" onClick={() => openPartnerForm(true)}>{t("partnersPage.welcomeBlock.cta_1")}</button>
                                {/* <BaseButton text={t("partnersPage.welcomeBlock.cta_2")} size="md" primaryOutlined buttonWidth="max-content"/> */}
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* {
                            [
                                new BackgroundPartnerIcon(<img src={RivalsPNG} />, "#F3A459", { bottom: -140, right: 423 }),
                                new BackgroundPartnerIcon(<img src={UnitechPNG} />, "#fff", { bottom: 110, right: 230 }),
                                new BackgroundPartnerIcon(<img src={IcorePNG} />, "#CB0510", { bottom: 250, right: -40 }),
                                new BackgroundPartnerIcon(<img src={TelematicsPNG} />, "#232323", { bottom: -120, right: -50 }),
                                new BackgroundPartnerIcon(<img src={SendifyPNG} />, "#FFFFFF", { bottom: -320, right: 180 }),
                            ].map(item => item.getEl())
                        } */}
                        <Balloons className="absolute top-8 right-0 hidden lg:block" style={{ zIndex: "-1" }} />
                    </div>
                </section >

                <div className="container-lg">
                    <div className="why-partners-section d-flex justify-content-around flex-wrap mt-[0px] sm:mt-[200px] min-w-[450px]">
                        <div
                            className="flex-1 mx-[64px] h-fit mt-[2.5rem] md:mt-[3rem] lg:mt-[5rem] xl:mt-[10rem]"
                        >
                            <p className="why-partners-main-text">
                                {t("partnersPage.whyPartners.heading")}
                            </p>

                            <p className="why-partners-description-text">
                                {t("partnersPage.whyPartners.subheading")}
                            </p>
                        </div>
                        <div className="d-flex justify-content-center" style={{ flex: 1, marginTop: '10rem' }}>

                        </div>
                    </div>
                </div>

                <div className="container-lg d-flex flex-wrap align-items-end mt-5 relative min-w-[80%]">
                    <div>
                        <div className="lg:w-[500px] w-[365px]">
                            <h6 className="mb-4">{t("partnersPage.advantages.first.heading")}</h6>
                            <p className="text-body-lp text-gray">{t("partnersPage.advantages.first.subheading")}</p>
                        </div>
                    </div>

                    <div style={{ position: 'absolute' }} className="sm:right-[40px] sm:ml-[200px] sm:bottom-40 md:right-[40px] md:ml-[200px] md:bottom-40 lg:right-[-20px] lg:bottom-40 xl:right-[70px]">
                        <img className="hidden sm:block md:block l:block xl:block w-[250px] md:w-[300px] lg:w-[80%] xl:w-full" src={HandsFourPNG} alt="suppli partners hands" />
                    </div>
                </div>

                <div className="container-lg grid md:grid-cols-3 sm:grid-cols-1 mt-[128px] gap-[60px]">
                    <div style={{ width: '365px' }}>
                        <h6 className="mb-4">{t("partnersPage.advantages.second.heading")}</h6>
                        <p className="text-body-lp text-gray">{t("partnersPage.advantages.second.subheading")}</p>
                    </div>
                    <div style={{ width: '365px' }}>
                        <h6 className="mb-4">{t("partnersPage.advantages.third.heading")}</h6>
                        <p className="text-body-lp text-gray">{t("partnersPage.advantages.third.subheading")}</p>
                    </div>
                    <div style={{ width: '365px' }}>
                        <h6 className="mb-4">{t("partnersPage.advantages.fourth.heading")}</h6>
                        <p className="text-body-lp text-gray">{t("partnersPage.advantages.fourth.subheading")}</p>
                    </div>
                </div>

                <main className="main">
                    <h1 className="font-[700] text-[56px] min-w-[500px] leading-[64.4px] text-[#7556FA] text-center mb-[100px]">Suppli partners</h1>
                    <div className="mx-auto min-w-[500px] sm:min-w-[100%]">
                        <div className="text-center">
                            {filters.map((filter, index) =>
                                <button
                                    key={index}
                                    onClick={() => {
                                        selectFilter(filter)
                                        setCurrentCountry("All countries")
                                    }}
                                    className={("h-[57.6px] m-1.5 text-[16px] leading-[24px] border-[.5px] border-[#D9D6DA] border-solid p-3 px-4 rounded-lg") + (filter === currentFilter ? " text-[#FFFFFF] font-[600] border-none bg-[#7556FA]" : " bg-transparent font-[300] text-[#686868]")}>
                                    {filter}</button>)}
                            <select
                                value={currentCountry}
                                onChange={(e) => {
                                    setCurrentCountry(e.target.value)
                                    selectFilter("Asd")
                                }}
                                className={("h-[57.6px] m-1.5 text-[16px] leading-[24px] border-1 border-[#D9D6DA] p-3 px-4 rounded-lg") + ("Asd" === currentFilter ? " text-[#FFFFFF] font-[600] border-none bg-[#7556FA]" : " bg-transparent font-[300] text-[#686868]")}>
                                {"All countries" === currentFilter ? <ArrowDownClicked className="min-h-[13.3px] min-w-[6.6px]" /> : <ArrowDown className="min-h-[13.3px] min-w-[6.6px]" />}
                                {allCountries.map((country, index) => <option key={index} value={country}>{country}</option>)}
                            </select>
                        </div>
                    </div>
                    <hr className="min-w-[500px] max-w-[85%] mr-auto ml-auto" />
                    <div className="grid sm: grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mr-auto ml-auto max-w-[90%] min-w-[500px]">
                        {partners.map((partner, i) => {
                            if (currentFilter === 'All' || currentFilter === partner.type || partner.country === currentCountry || (currentCountry === "All countries" && currentFilter === "Asd"))
                                return <div onClick={() => {
                                    setCurrentPartner(i)
                                    openModal(true)
                                }} className="m-[18px] mr-auto ml-auto mt-[64px] cursor-pointer">{partner.logo}</div>
                        })}
                    </div>
                    <div className="container-fluid min-w-[500px]" style={{ background: "linear-gradient(90deg, #F2FBFE 0%, #F3F1FE 100%)" }}>
                        <div
                            className="container-lg d-flex flex-wrap justify-content-between align-items-center"
                            style={{ marginTop: '260px', paddingTop: '64px', paddingBottom: '64px', rowGap: '30px' }}
                        >

                            <div style={{ width: '365px' }}>
                                <h6>{t("partnersPage.footerCTA.heading")}</h6>
                            </div>

                            <div className="text-gray text-body-lp">
                                {t("partnersPage.footerCTA.subheading")}
                            </div>

                            <div style={{ width: '365px' }}>
                                <button className="btn btn-filled w-[212px] h-[56px] rounded-lg px-[16px] py-[32px]" onClick={() => window.location.href = "/support"}>{t("partnersPage.footerCTA.cta")}</button>
                            </div>

                        </div>
                    </div>
                </main>
            </main >
            <FooterBlock />
        </>
    );
}

interface PositioningStyles {
    top?: string | number;
    left?: string | number;
    bottom?: string | number;
    right?: string | number;
}

class BackgroundPartnerIcon {
    svg: JSX.Element;
    background: string;
    styles: PositioningStyles;

    constructor(svg: JSX.Element, background: string, styles: PositioningStyles) {
        this.svg = svg;
        this.background = background;
        this.styles = styles;
    }

    getEl(): JSX.Element {
        return (
            <div className="flex justify-center items-center w-[250px] h-[250px] rounded-full" style={{
                position: "absolute",
                boxShadow: "0px 12px 30px rgba(121, 85, 249, 0.1), 0px 7.7037px 12.7407px rgba(121, 85, 249, 0.0607407), 0px 1.62963px 3.25926px rgba(121, 85, 249, 0.0607)",
                backgroundColor: this.background,
                zIndex: -1,
                ...this.styles
            }}>
                {this.svg}
            </div>
        )
    }
}

export default PartnersPage;
