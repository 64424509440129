import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

export default function BaseCurrentTime(props: { refreshInMilliseconds: number, transform: (date: dayjs.Dayjs) => string | JSX.Element | undefined | null}) {

    const [currentDate, setCurrentDate] = useState(dayjs());

    useEffect(() => {
        const timer = setInterval(() => setCurrentDate(dayjs()), props.refreshInMilliseconds);

        return () => clearInterval(timer);
    }, []);

    return <>
        {
            props.transform(currentDate)
        }
    </>;

}