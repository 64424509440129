import React from "react";
import { useAppSelector } from "@redux/hooks";

export default function BaseCurrency() {
    const currency = useAppSelector((state) => state.auth.user?.company.currency);

    if (!currency) return null;

    return <>{currency?.symbol}</>;
}
