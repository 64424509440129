import React, {useMemo, useState} from "react";
import _ from "lodash";
import SerialNumbersModal
    from "@components/Dashboard/pages/PurchasingPage/Receive/components/modals/SerialNumbersModal";
import BatchNumbersModal from "@components/Dashboard/pages/PurchasingPage/Receive/components/modals/BatchNumbersModal";
import {Fade, Stack} from "@mui/material";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import {useFormContext, useWatch} from "react-hook-form";
import {ReceiptsMutationFormTyping} from "@components/Dashboard/pages/PurchasingPage/Receive/components/MutationLayout";
import {useTranslation} from "react-i18next";

export default function TableHeading() {
    const {t} = useTranslation("", {keyPrefix: "receive.mutation"});

    const {control, setValue} = useFormContext<ReceiptsMutationFormTyping>()

    const receipts = useWatch({
        control,
        name: "receipts"
    })

    const serialAndBatchNumberReceipts = useMemo(() => {
        const lines = receipts
            .filter(line => line.received_quantity && line.location)
            .map(line => {
                return {
                    ..._.pick(line, ["order", "location", "serial_numbers", "batch_numbers"]),
                    received_quantity: line.received_quantity
                };
            });


        return {
            withSerialNumbers: lines.filter(line => line.order.data?.product.has_serial_number),
            withBatchNumbers: lines.filter(line => line.order.data?.product.has_batch_number)
        }
    }, [receipts])

    // const serialAndBatchNumberReceipts = useMemo(() => {
    //     const lines = receipts
    //         .filter(line => line.received_quantity && line.location)
    //         .map(line => {
    //             return {
    //                 ..._.pick(line, ["order", "serial_numbers", "batch_numbers"]),
    //                 received_quantity: line.received_quantity
    //             };
    //         });

    //     return {
    //         withSerialNumbers: lines.filter(line => line.order.product.has_serial_number),
    //         withBatchNumbers: lines.filter(line => line.order.product.has_batch_number)
    //     }
    // }, [receipts])

    console.log(receipts)

    const [serialNumberModalOpen, setSerialNumberModalOpen] = useState(false);
    const [batchNumberModalOpen, setBatchNumberModalOpen] = useState(false);

    return <>
        <div className="flex mb-[46px] items-center space-x-[8px]">
            <div className="text-xl font-thin text-gray-600 grow">{t("products.heading")}</div>
            <Stack direction={"row"} spacing={2}>
                {/* Button for adding serial numbers*/}
                <Fade in={serialAndBatchNumberReceipts.withSerialNumbers.length > 0}>
                    <div>
                        <BaseMaterialButton
                            type={"button"}
                            fontWeight={500}
                            onClick={() => setSerialNumberModalOpen(true)}>
                            {t("products.buttons.addSerialNumbers")}
                        </BaseMaterialButton>
                    </div>
                </Fade>

                {/* Button for adding batch numbers */}
                {serialAndBatchNumberReceipts.withBatchNumbers.length > 0 &&
                    <Fade in={serialAndBatchNumberReceipts.withBatchNumbers.length > 0}>
                        <div>
                            <BaseMaterialButton
                                type={"button"}
                                fontWeight={500}
                                onClick={() => setBatchNumberModalOpen(true)}>
                                {t("products.buttons.addBatchNumbers")}
                            </BaseMaterialButton>
                        </div>
                    </Fade>
                }
            </Stack>
        </div>

        {/* Batch numbers modal */}
        {
            serialAndBatchNumberReceipts.withBatchNumbers.length > 0 &&
            <BatchNumbersModal
                isOpen={batchNumberModalOpen}
                onClose={() => setBatchNumberModalOpen(false)}
                receipts={serialAndBatchNumberReceipts.withBatchNumbers.map(line => {
                    
                    return {
                        ...line,
                        order: {
                            ...line.order,
                            data: {
                                id: line.order.data!.id,
                                product: {
                                    id: line.order.data!.product.id,
                                    name: line.order.data!.product.name,
                                    code: line.order.data!.product.code,
                                }
                            }
                        },
                        location: {
                            store: {
                                id: line.location!.store.id,
                                name: line.location!.store.name,
                            },
                            ...(line.location?.section && {
                                section: {
                                    id: line.location.section.id,
                                    name: line.location.section.name,
                                }
                            }),
                        },
                    };
                })}
                onSave={data => {
                    // 1. Transform the array of lines in 'data' to an object, keyed by 'sale_order_line_id'
                    const idBasedData = _.keyBy(data.receipts, (receipt) => receipt.sale_order_line_id);

                    // 2. Iterate through 'controlledReceipts' to generate a new array 'formReadyLines'.
                    // For each line in 'controlledReceipts', merge its properties with the 'batch_numbers' property
                    // from the corresponding line in 'idBasedData' (using the same 'sale_order_line_id' for matching).
                    const formReadyLines = receipts.map(receipt => {
                        const lineId = receipt.order.data?.id;

                        return {
                            ...receipt,
                            batch_numbers: (lineId! in idBasedData)
                                ?
                                idBasedData[lineId!].batch_numbers : []
                        };
                    });

                    setValue("receipts", formReadyLines);

                    setBatchNumberModalOpen(false)
                }}
            />
        }

        {/* Serial numbers modal */}
        {
            serialAndBatchNumberReceipts.withSerialNumbers.length > 0 &&
            <SerialNumbersModal
                isOpen={serialNumberModalOpen}
                onClose={() => setSerialNumberModalOpen(false)}
                receipts={serialAndBatchNumberReceipts.withSerialNumbers.map(line => {
                    
                    return {
                        ...line,
                        order: {
                            ...line.order,
                            data: {
                                id: line.order.data!.id,
                                product: {
                                    id: line.order.data!.product.id,
                                    name: line.order.data!.product.name,
                                    code: line.order.data!.product.code,
                                }
                            }
                        },
                        location: {
                            store: {
                                id: line.location!.store.id,
                                name: line.location!.store.name,
                            },
                            ...(line.location?.section && {
                                section: {
                                    id: line.location.section.id,
                                    name: line.location.section.name,
                                }
                            }),
                        },
                    };
                })}
                onSave={data => {
                    // 1. Transform the array of lines in 'data' to an object, keyed by 'sale_order_line_id'
                    const idBasedData = _.keyBy(data.receipts, (receipt) => receipt.sale_order_line_id);

                    // 2. Iterate through 'controlledReceipts' to generate a new array 'formReadyLines'.
                    // For each line in 'controlledReceipts', merge its properties with the 'serial_numbers' property
                    // from the corresponding line in 'idBasedData' (using the same 'sale_order_line_id' for matching).
                    const formReadyLines = receipts.map(receipt => {
                        const lineId = receipt.order.data?.id;

                        return {
                            ...receipt,
                            serial_numbers: (lineId! in idBasedData) ? idBasedData[lineId!].serial_numbers : []
                        };
                    });

                    setValue("receipts", formReadyLines);

                    setSerialNumberModalOpen(false)
                }}
            />
        }
    </>
}