import React from "react";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { useHistory } from "react-router-dom";
import MutationLayout from "../components/MutationLayout";
import { useCreateBOMMutation } from "@redux/features/bom/bomApi";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { BOM } from "@/types/general";
import _ from "lodash";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function BOMCreationPage() {
    const { t } = useTranslation("", { keyPrefix: "bom.creation" });

    const history = useHistory();

    const location = useLocation();
    const state = location.state as { bom?: BOM.Root };

    const [createBOM, { isLoading: isBOMLoading }] = useCreateBOMMutation();

    return (
        <BaseLoadingBlocker active={isBOMLoading}>
            <div className="levitation-extended">
                <Can not I="bill_of_materials.create" a="product">
                    <NoPermissionBanner />
                </Can>

                <Can I="bill_of_materials.create" a="product">
                    <div className="flex mb-[40px]">
                        <h6 className="text-accent grow">{t("heading")}</h6>
                    </div>
                    <MutationLayout
                        bom={
                            state?.bom ?
                                {
                                    // Omitting id to tell MutationLayout that it's a new BOM and not editing of an existing one
                                    ..._.omit(state.bom, ["id"]),
                                    // Adding copy suffix to avoid duplicated names
                                    name: `${state.bom.name} (${t("copySuffix")})`
                                }
                                : undefined
                        }
                        buttonText={t("buttons.create")}
                        onSubmit={(data) => {
                            createBOM({
                                product_id: data.product.id,
                                name: data.name,
                                components: data.components.map(component => ({
                                    product_id: component.id,
                                    quantity: component.quantity
                                }))
                            }).unwrap()
                                .then((data) => {
                                    history.push(`/dashboard/bill-of-materials/${data.id}/details`);
                                })
                                .catch((error) => {
                                    if (isErrorWithMessage(error)) {
                                        toast.error(error.message);
                                    } else {
                                        toast.error(t("responses.error"));
                                    }
                                });
                        }}
                    />
                </Can>
            </div>
        </BaseLoadingBlocker>
    );
}
