import { Picking } from "@/types/general";
import PickingDetailsPage from "@components/Dashboard/pages/Sales/Picking/components/ViewLayout/PickingDetails";
import PickingShipmondo from "./PickingShipmondo";

export interface ViewLayoutProps {
    picking: Picking.Extended;
}

const ViewLayout = {
    Main: PickingDetailsPage,
    Shipmondo: PickingShipmondo,
}

export default ViewLayout;