import React, { useEffect } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import BaseCheckbox from "@reusables/BaseCheckbox";
import { AdjustmentFormTyping } from "@components/Dashboard/pages/Inventory/Adjustments/components/EditingLayout/types";
import { useTranslation } from "react-i18next";
import { useAbility } from "@/casl.config";

export default function TypeSelector() {
    const { t } = useTranslation("", { keyPrefix: "inventory.adjustments.createViewEditAdjustments" });
    const { control } = useFormContext<AdjustmentFormTyping>();

    const ability = useAbility();

     // Determine user abilities
    const canWithQuantityCreate = ability.can("with_quantity.create", "adjustment");
    const canWithPurchasePriceCreate = ability.can("with_purchase_price.create", "adjustment");

    // Identify if the user can perform only one ability
    const onlyCanWithQuantityCreate = canWithQuantityCreate && !canWithPurchasePriceCreate;
    const onlyCanWithPurchasePriceCreate = !canWithQuantityCreate && canWithPurchasePriceCreate;

    const { field, fieldState } = useController({
        control,
        name: "adjustment_type"
    });

    const selectedProduct = useWatch({
        control,
        name: "product"
    });

    useEffect(() => {
        if (onlyCanWithQuantityCreate) {
            field.onChange(0);
        }
        if (onlyCanWithPurchasePriceCreate) {
            field.onChange(1);
        }
        if (selectedProduct?.is_service && canWithPurchasePriceCreate) {
            field.onChange(1);
        }
        if (selectedProduct?.is_service && !canWithQuantityCreate) {
            field.onChange(undefined);
        }
      }, [
        selectedProduct,
        field.value,
        onlyCanWithQuantityCreate,
        onlyCanWithPurchasePriceCreate,
        field,
      ]);

    return (
        <div>
            <div
                className="font-semibold text-inputs-label-dim mb-[24px]">{t("fieldsGeneral.adjustment.label")}</div>
            <div className="flex space-x-[40px]">
                {/* Quantity checkbox (hidden if product is service)*/}
                {
                    !selectedProduct?.is_service &&
                    <BaseCheckbox
                        value={field.value === 0}
                        label={`${t("fieldsGeneral.adjustment.selectQuantity")}`}
                        onChange={(checked) => checked && field.onChange(0)}
                        disabled={ability.cannot("with_quantity.create", "adjustment")}
                    />
                }

                {/* Price checkbox */}
                <BaseCheckbox
                    value={field.value === 1}
                    label={`${t("fieldsGeneral.adjustment.selectCostPrice")}`}
                    onChange={(checked) => checked && field.onChange(1)}
                    disabled={ability.cannot("with_purchase_price.create", "adjustment")}
                />
            </div>
        </div>
    );
}