import React from "react";
import { useTranslation } from "react-i18next";

interface BaseNotFoundProperties {
    text?: string | JSX.Element;
    height?: number | string;
}

export default function BaseNotFound({text, height}: BaseNotFoundProperties){
    const { t } = useTranslation();

    return (
        <div className="flex items-center justify-center text-xl font-thin text-notFound-blocks uppercase"
             style={{ height: height ?? 500 }}>
            {text ?? t("general.nothingFound.record")}
        </div>
    )
}