import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import BaseButton from "@reusables/BaseButton";
import BaseModal from "@reusables/Modals/BaseModal";
import BasePhoneInput from "@reusables/BasePhoneInput";
import BaseInput from "@reusables/BaseInput";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";

import {
    selectEmployeeByCode,
    useCreateEmployeeMutation,
    useGetEmployeesQuery,
    useUpdateEmployeeMutation
} from "@redux/features/employees/employeesApi";
import { useGetLanguagesQuery } from "@redux/features/languages/languagesApi";
import { Employee } from "@/types/general";

import { toast } from "react-toastify";

import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { globalStore } from "@redux/store";

interface MutationEmployeeModalProperties {
    isOpen: boolean;
    onClose: () => void;
    employee?: Employee.Root;
}

export const formScheme = z.object({
    code: z.object({
        value: z.string().nullish(),
        checkable: z.boolean().optional(),
    }).refine( (code) => {
        return code.checkable ? selectEmployeeByCode(globalStore.getState(), code.value) === undefined : true;
    }, {
        params: {
            i18n: "settings.general.companyInformation.tabs.employees.modals.mutation.codeAlreadyExists"
        },
        path: ["value"]
    }),
    name: z.string().trim().nonempty(),
    job_title: z.string().trim().optional(),
    email: z.string().trim().email(),
    phone: z.string().trim(),
    language: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string(),
    }).optional(),
})

type FormTyping = z.infer<typeof formScheme>

export default function MutationModal(props: MutationEmployeeModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.employees.modals.mutation" });

    // ---> Employees selection <--- //
    const {data: employees } = useGetEmployeesQuery();

    // ---> Language selection <--- //
    const { data: languageOptions = [], isLoading: languageOptionsLoading } = useGetLanguagesQuery();
    // const [selectedLanguage, setSelectedLanguage] = useState<ArrayElementType<typeof languageOptions>>();

    // Getting RTK Query hooks for saving data
    const [createEmployee, { isLoading: isCreationLoading }] = useCreateEmployeeMutation();
    const [updateEmployee, { isLoading: isUpdateLoading }] = useUpdateEmployeeMutation();

    const {control, handleSubmit, setValue, reset} = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            code: {
                checkable: true,
            }
        }
    });

    useEffect(() => {
        if(props.isOpen && props.employee) {
            setValue("code.value", props.employee.code);
            setValue("code.checkable", false);
            setValue("name", props.employee.name);
            setValue("job_title", props.employee.job_title);
            setValue("email", props.employee.email);
            setValue("phone", props.employee.phone ?? "");
            const option = languageOptions.find((opt) => opt.id === props.employee?.language?.id);
            option && setValue("language", option);
        }else{
            reset();
        }
    }, [props.isOpen, reset, setValue, props.employee]);

    const onSubmit = handleSubmit((data) => {
        if(props.employee) {
            updateEmployee({
                id: props.employee.id,
                name: data.name,
                job_title: data.job_title,
                email: data.email,
                phone: data.phone ?? undefined,
                language: data.language?.id,
            }).unwrap().then(() => {
                toast.success(t("responses.update.success"));
                props.onClose();
            })
            .catch(() => {
                toast.error(t("responses.update.error"));
            });
        } else {
            createEmployee({
                // "?? undefined" is used to convert null to undefined
                code: data.code.value ?? undefined,
                name: data.name,
                job_title: data.job_title,
                email: data.email,
                phone: data.phone ?? undefined,
                language: data.language?.id,
            }).unwrap().then(() => {
                toast.success(t("responses.creation.success"));
                props.onClose();
            })
            .catch(() => {
                toast.error(t("responses.creation.error"));
            });
        }
    });

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={562}
            padding="56px"
            useCloseIcon
            isLoading={isCreationLoading || isUpdateLoading}
        >
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent">
                    {props.employee ? props.employee.name : t("creationHeading")}
                </div>

                <Controller
                    name={'code.value'}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t('fields.code.label')}

                            disabled={props.employee !== undefined}
                        />
                    )}
                />

                <Controller
                    name={'name'}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t('fields.name.label')}
                        />
                    )}
                />

                <Controller
                    name={'job_title'}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t('fields.jobTitle.label')}
                        />
                    )}
                />

                <Controller
                    name={'email'}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t('fields.email.label')}
                        />
                    )}
                />

                <Controller
                    name={'phone'}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BasePhoneInput
                            {...field}
                            error={fieldState.error}
                            label={t('fields.phone.label')}
                            variant="custom"
                            size="md"
                        />
                    )}
                />

                <Controller
                    name={'language'}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}
                            label={`${t("dropdowns.language.label")}`}
                            placeholder={`${t("dropdowns.language.placeholder")}`}
                            options={languageOptions}
                            isLoading={languageOptionsLoading}

                            getter={{
                                key: (opt) => opt.id,
                                label: (opt) => opt.name,
                            }}
                              
                            autocomplete
                        />
                    )}
                />

                <div>
                    <BaseButton 
                        buttonWidth="100%" 
                        text={`${t("buttons.save")}`} 
                        size="md" 
                        type="submit" 
                    />
                </div>
            </form>
        </BaseModal>
    );
}
