import React, { useEffect, useState } from 'react'
import NavigationBar from '../NavigationBar';
import FooterBlock from '../FooterBlock';
import useAuth from '../../hooks/auth';
import axios from 'axios';

const API_URL = import.meta.env.VITE_API_URL;

function LicensePage() {

    const [loading, setLoading] = useState(false);
    const [licenseData, setLicenseData] = useState<any>({});

    const { user } = useAuth();

    const token = localStorage.getItem("sanctum");

    const getLicense = () => {
        setLoading(true);

        axios.get(`${API_URL}/api/plan/user`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then((res) => {
            // console.log("LICENSE")
            // console.log(res);
            setLicenseData(res.data);
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        getLicense();
    }, [])

  return (
    <div>
        <NavigationBar />
        <main className="main">
            <div className="container">
                <p>Your plan:</p>
            </div>

            <div className="container">

                {
                    loading && (
                        <div>
                            Loading ...
                        </div>
                    )
                }

                {
                    !loading && !licenseData.id && (
                        <div>
                            You have not purchased license and/or support package
                        </div>
                    )
                }

                {
                    !loading && licenseData.id && (
                        <div>
                            id: {licenseData.id}
                            <br />
                            license: {licenseData.license}
                            <br />
                            license end date: {licenseData.license_end}
                            <br />
                            support: {licenseData.support}
                            <br />
                            support end date: {licenseData.support_end}
                        </div>
                    )
                }

            </div>
        </main>
        <FooterBlock />
    </div>
  )
}

export default LicensePage;
