import React from "react";
import { useTranslation } from "react-i18next";

// images
import ImOrganize from "../../../assets/images/why-01.png";
import ImReports from "../../../assets/images/why-02.png";
import ImManageSales from "../../../assets/images/why-03.png";
import ImControl from "../../../assets/images/why-04.png";
import ImIntegration from "../../../assets/images/why-05.png";

type WhyItem = {
  img: string;
  title: string;
  desc: string;
};

const Benefit = () => {
  const { t, i18n } = useTranslation();

  const items = [
    {
      img: ImOrganize,
      title: t("homePage.whyUsBlock.cards.organize.main"),
      desc: t("homePage.whyUsBlock.cards.organize.desc"),
    },
    {
      img: ImReports,
      title: t("homePage.whyUsBlock.cards.reports.main"),
      desc: t("homePage.whyUsBlock.cards.reports.desc"),
    },
    {
      img: ImManageSales,
      title: t("homePage.whyUsBlock.cards.manageSales.main"),
      desc: t("homePage.whyUsBlock.cards.manageSales.desc"),
    },
    {
      img: ImControl,
      title: t("homePage.whyUsBlock.cards.control.main"),
      desc: t("homePage.whyUsBlock.cards.control.desc"),
    },
    {
      img: ImIntegration,
      title: t("homePage.whyUsBlock.cards.integrations.main"),
      desc: t("homePage.whyUsBlock.cards.integrations.desc"),
    },
  ];

  const _renderWhyItem = (item: WhyItem) => (
    <div className="why-item">
      <div className="why-item__pic">
        <img src={item.img} alt="" />
      </div>
      <span>{item.title}</span>
      <p className="max-tablet:text-xs!">{item.desc}</p>
    </div>
  );

  return (
    <>
      <div className="max-w-[768px] mx-auto text-center mb-8">
        <div className="text-lg text-[#8850F4] uppercase mb-4 tracking-wider">
          {t("homePage.whyUsBlock.preTitle")}
        </div>
        <div className="text-[2em] tablet:text-[56px] leading-[1.15] text-[#2B2A35]">
          {t("homePage.whyUsBlock.title")}
        </div>
      </div>

      <div className="mt-[96px] grid grid-cols-2 tablet:grid-cols-5 gap-8">
        {items.map(_renderWhyItem)}
      </div>
    </>
  );
};

export default Benefit;
