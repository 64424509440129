import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { Shipmondo } from "@/types/general";

export const dashboardApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getShipmondoProducts: builder.query<Shipmondo.Products.Root[], { carrier_code: string }>({
            query: ({ carrier_code }) => ({
                url: `shipmondo/products/${carrier_code}`
            }),
            transformResponse,
            providesTags: ["Shipmondo"]
        }),

        getShipmondoCarries: builder.query<Shipmondo.Carriers.Root[], {
            sender: string,
            receiver: string
        }>({
            query: ({ sender, receiver }) => ({
                url: `shipmondo/carriers`,
                params: {
                    sender,
                    receiver
                }
            }),
            transformResponse,
            providesTags: ["Shipmondo"]
        })
    })
});

export const {
    useGetShipmondoProductsQuery,
    useGetShipmondoCarriesQuery
} = dashboardApi;
