import React from "react";
import BaseButton from "@reusables/BaseButton";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { LowStockRefillSchemeTyping } from "@components/Dashboard/pages/Sales/Orders/components/modals/LowStockRefillModal/types";
import BaseInputsGrid from "@reusables/BaseInputsGrid";

export interface ModalActionButtonsProps {
    onClose: () => void;
}
export default function ModalActionButtons(props: ModalActionButtonsProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.mutations.creation.lowStockOffer.modals.refill.buttons" });

    const {control} = useFormContext<LowStockRefillSchemeTyping>();

    const lines = useWatch({
        control,
        name: "lines"
    })

    return (
        <BaseInputsGrid cols={2}>
            <BaseButton
                buttonWidth="100%"
                text={t("cancel")}
                size="md"
                type={"button"}
                primaryOutlined
                onClick={props.onClose}
            />
            <BaseButton
                buttonWidth="100%"
                text={t("refill")}
                size="md"
                type={"submit"}
                disabled={!lines.some((l) => l.should_be_refilled)}
            />
        </BaseInputsGrid>
    );
}