import React, { useEffect } from 'react';
import BaseButton from '@reusables/BaseButton';
import BaseInput from '@reusables/BaseInput';
import BaseInputsGrid from '@reusables/BaseInputsGrid';
import BasePhoneInput from '@reusables/BasePhoneInput';
import BaseModal from '@reusables/Modals/BaseModal';
import { removeEmpty, requiredIfAnyFilled } from '@helpers/utils';
import { isErrorWithMessage } from '@redux/api/query';
import { useUpdateCustomerMutation } from '@redux/features/customers/customersApi';
import { Customer } from '@/types/general';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { contactScheme, personContactScheme } from '@/components/Dashboard/pages/Customers/Customer/components/tabs/TabsController';

type CreateTheirReferenceModalProps = {
    customer: Customer.Extended;
    isOpen: boolean;
    onClose: () => void;
    onCreate: (contact: Customer.Extended) => void;
}

const formScheme = z.object({
    contacts: contactScheme.nullish(),

    person_contacts: personContactScheme.nullish()
});

type FormTyping = z.infer<typeof formScheme>

export default function CreateTheirReferenceModal({ customer, isOpen, onClose, onCreate}: CreateTheirReferenceModalProps) {
    const {t} = useTranslation("", {keyPrefix: "sales.orders.general.dropdowns.theirReference.creationModal"});

    const [updateCustomer, { isLoading: isCreationLoading }] = useUpdateCustomerMutation();

    const {control, handleSubmit, reset} = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
    });

    useEffect(() => {
        if (isOpen) {
            reset();
        }
    }, [isOpen, reset]);

    const onSubmit = handleSubmit((data) => {
        updateCustomer({
            id: customer?.id,
            code: customer.code,
            is_person: customer?.is_person,
            ...(customer.is_person && {contacts: data.person_contacts?.filter(con => !_.isEmpty(removeEmpty(con))).map(contact => {
                return {
                    id: contact.id,
                    phone: contact.phone as string,
                    email: contact.email as string
                }
            })}),
            ...(!customer.is_person && {contacts: data.contacts?.filter(con => !_.isEmpty(removeEmpty(con))).map(contact => {
                return {
                    id: contact.id,
                    name: contact.name as string,
                    phone: contact.phone as string,
                    email: contact.email as string
                }
            })}),

            deliveries: [],
            deleted_contacts: [],
            deleted_deliveries: [],
        }).unwrap()
        .then((data) => {
            onCreate(data);
        })
        .catch((e) => {
            if (isErrorWithMessage(e)) {
                toast.error(e.message);
            } else {
                toast.error(t("responses.error"))
            }
        });
    })

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={562}
            padding="56px"
            useCloseIcon
            isLoading={isCreationLoading}
        >
            <form className="space-y-8" onSubmit={onSubmit}>
                <div className="modal-title">{t("heading")}</div>

                <BaseInputsGrid cols={1}>
                    {customer?.is_person ? (
                        <>
                            <Controller
                                name="person_contacts.0.phone"
                                control={control}
                                render={({field, fieldState}) => (
                                    <BasePhoneInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("fields.phone")}
                                        variant='custom'
                                    />
                                )}
                            />

                            <Controller
                                name="person_contacts.0.email"
                                control={control}
                                render={({field, fieldState}) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("fields.email")}
                                    />
                                )}
                            />
                        </>
                    ) : (
                        <>
                            <Controller
                                name="contacts.0.name"
                                control={control}
                                render={({field, fieldState}) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("fields.name")}
                                    />
                                )}
                            />

                            <Controller
                                name="contacts.0.phone"
                                control={control}
                                render={({field, fieldState}) => (
                                    <BasePhoneInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("fields.phone")}
                                        variant="custom"
                                    />
                                )}
                            />

                            <Controller
                                name="contacts.0.email"
                                control={control}
                                render={({field, fieldState}) => (
                                    <BaseInput
                                        {...field}
                                        error={fieldState.error}
                                        label={t("fields.email")}
                                    />
                                )}
                            />
                        </>
                    )}
                </BaseInputsGrid>

                <BaseButton
                    buttonWidth="100%"
                    text={t("buttons.create")}
                    size="md"
                    type={"submit"}
                />

            </form>
        </BaseModal>
    )
}
