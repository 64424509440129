// ======== NEEDED FOR DROPDOWNS ======== //
export type LocationOption<StoreAdd = {}, SectionAdd = StoreAdd> = {
  store: {
    id: number;
    name: string;
  } & StoreAdd
  section?: {
    id: number;
    name: string;
  } & SectionAdd
}

export function getDifference(old: number | undefined, actual: number | undefined): number {
  if (!actual)
    return 0;

  const diff = actual - (old ?? 0);
  return parseFloat(diff.toFixed(2));
}

export function addSign(value: number): string {
  return value > 0 ? `+${value}` : value.toString();
}
