import React, { useEffect } from "react";
import { TabProperties } from "../types";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { SignUpFormTyping } from "./TabsController";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import { useGetCountriesQuery, useGetCurrenciesPublicQuery } from "@redux/api/internalApiSlice";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { getCountryFlag } from "@/helpers/utils";
import BaseButton from "@reusables/BaseButton";
import BaseCheckbox from "@reusables/BaseCheckbox";
import { ArrowLeft } from "lucide-react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function CompanyInformationTab({ onNext, onPrev }: TabProperties) {
    const { t } = useTranslation("", { keyPrefix: "signupPage.companyInformation" });

    const { control, setValue } = useFormContext<SignUpFormTyping>();

    const { data: countryOptions = [], isLoading: countryOptionsLoading } = useGetCountriesQuery();
    const { data: currenciesOptions = [], isLoading: isCurrenciesOptionsLoading } = useGetCurrenciesPublicQuery();


    const countryCodeWatch = useWatch({
        control,
        name: "your_profile.phone.code"
    });

    useEffect(() => {
        if (countryCodeWatch && countryOptions) {
            const foundCountry = countryOptions.find(opt => opt.code === countryCodeWatch.toUpperCase()) ?? countryOptions.find(opt => opt.code === "NO");
            !!foundCountry && setValue("company_information.country", foundCountry);
        }
    }, [countryCodeWatch, countryOptions]);

    return (
        <>
            <div className="flex flex-col space-y-8 my-16">
                <div className="text-2xl text-center font-semibold text-accent">{t("heading")}</div>
                <div className="text-xl font-thin text-center text-secondary-gray">{t("subheading")}</div>
            </div>
            <form onSubmit={onNext}>
                <BaseInputsGrid cols={{xs: 1, sm: 2}} gap={{x: 32, y: 24}}>
                    <Controller
                        control={control}
                        name="company_information.company_name"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.companyName")}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="company_information.registration_number"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.registrationNumber")}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="company_information.currency"
                        render={({ field, fieldState }) => (
                            <BaseDropdown
                                {...field}
                                error={fieldState.error}

                                options={currenciesOptions}
                                getter={{
                                    key: opt => opt.id,
                                    label: opt => opt.name,
                                    renderOption: (opt, icon) => (
                                        <div>
                                            <span className="mr-[8px]">{opt.name}</span>
                                            {icon}
                                        </div>
                                    )
                                }}
                                label={t("fields.currency")}
                                isLoading={isCurrenciesOptionsLoading}

                                autocomplete
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="company_information.country"
                        render={({ field, fieldState }) => (
                            <BaseDropdown
                                {...field}
                                error={fieldState.error}

                                options={countryOptions}
                                getter={{
                                    key: opt => opt.id,
                                    label: opt => opt.name,
                                    renderOption: (opt, icon) => (
                                        <div>
                                            <img src={getCountryFlag(opt.code)} width={20} alt={opt.name + " flag"} />
                                            <span className="ml-[8px] grow">{opt.name}</span>
                                            {icon}
                                        </div>
                                    )
                                }}

                                label={t("fields.country")}
                                isLoading={countryOptionsLoading}
                                autocomplete
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="company_information.street"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.street")}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="company_information.street2"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.street2")}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="company_information.city"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.city")}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="company_information.zip_code"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.zipcode")}
                            />
                        )}
                    />
                </BaseInputsGrid>

                <div className="space-y-[24px] mt-6">
                    <Controller
                        control={control}
                        name="terms_and_conditions"
                        render={({ field, fieldState }) => (
                            <div className={"flex flex-row items-center"}>
                                <BaseCheckbox
                                    {...field}
                                    {...fieldState}
                                />
                                <div>
                                    <Trans i18nKey={"checkboxes.terms"}
                                        t={t}
                                        components={{
                                            termsLink: <Link to="/terms-and-conditions"
                                                                className={"text-decoration-underline px-1"} />,
                                            policyLink: <Link to="/privacy-policy"
                                                                className={"text-decoration-underline px-1"} />
                                        }}
                                    />
                                    .
                                </div>
                            </div>
                        )}
                    />

                    <Controller
                        control={control}
                        name="marketing_info"
                        render={({ field, fieldState }) => (
                            <div className={"flex flex-row items-center"}>
                                <BaseCheckbox
                                    {...field}
                                    {...fieldState}
                                />
                                <span className="text-secondary-gray">{t("checkboxes.marketingInfo")}.</span>
                            </div>
                        )}
                    />
                </div>

                <div className="grid mt-[40px] items-center justify-center gap-1 grid-cols-3">
                    <div
                        className="flex items-center justify-start text-secondary-gray text-sm hover:text-accent cursor-pointer"
                        onClick={() => onPrev?.()}
                    >
                        <ArrowLeft size={20} />
                        <span className="mx-2">{t("buttons.previous")}</span>
                    </div>

                    <BaseButton
                        type="submit"
                        text="Create Account"
                        size="md"
                        className="max-w-fit justify-self-center"
                        style={{ backgroundImage: "linear-gradient(90.89deg, #2B2A35 0.67%, #6959FC 99.24%) 1" }}

                    />
                </div>
            </form>
        </>
    );
}