import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { ImportFormTyping } from "..";
import BaseBadge from "@reusables/BaseBadge";
import { ScanEye } from "lucide-react";
import BaseTooltip from "@reusables/BaseTooltip";
import { Trans, useTranslation } from "react-i18next";

interface OptionItemProps {
    /**
     * Index of the option in the options array
     */
    index: number;
    /**
     * Option field
     */
    field: ImportFormTyping["options"][0];
}

export default function OptionItem({ field, index }: OptionItemProps) {
    const { t } = useTranslation("", { keyPrefix: "imports.importModal" });

    const { control } = useFormContext<ImportFormTyping>();

    // Watch all options to determine which headers are already selected
    const allOptions = useWatch({
        control,
        name: "options"
    });

    // Create a set of selected header indices to exclude from the dropdown
    const selectedHeaderIndices = new Set(
        allOptions.filter((opt, optIndex) => opt.selectedHeader && optIndex !== index)
            .map(opt => opt.selectedHeader?.index)
    );

    // Filter out already selected headers except for the current one
    const availableHeaders = useWatch({
        control,
        name: "headers"
    })?.filter(header => !selectedHeaderIndices.has(header.index) || header.index === field.selectedHeader?.index);

    return (
        <div className="w-full flex items-center space-x-2">
            <div
                className="flex justify-between items-center p-[16px] min-w-[200px] h-[56px] rounded-[6px] bg-[#ECEDFE] relative">
                <div className="text-sm text-accent">{field.title}{field.required && ` *`}</div>
                <div className={"absolute top-[2px] right-[4px]"}>
                    {
                        field.is_code &&
                        <BaseTooltip title={
                            <Trans
                                t={t}
                                i18nKey={"codeColumn"}
                                components={{
                                    b: <b className={"font-semibold"}/>
                                }}
                            />
                        }>
                            <BaseBadge className={"bg-white border text-[10px] px-[6px]"}>
                                <ScanEye size={12} />
                            </BaseBadge>
                        </BaseTooltip>
                    }
                </div>
            </div>
            <div className="w-full">
                <Controller
                    control={control}
                    name={`options.${index}.selectedHeader`}
                    render={({ field, fieldState }) => (
                        <>
                            <BaseDropdown
                                {...field}
                                {...fieldState}
                                options={availableHeaders}
                                getter={{
                                    key: (opt) => opt?.index,
                                    label: (opt) => opt?.value
                                }}
                                autocomplete
                            />
                        </>
                    )}
                />
            </div>
        </div>
    );
}
