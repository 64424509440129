import * as React from 'react';
import {
  useAutocomplete,
  AutocompleteGetTagProps,
} from '@mui/base/AutocompleteUnstyled';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import {ReactComponent as DropdownIcon} from "../../../../assets/icons/ic_expand_down.svg";
import {ReactComponent as CrossSVG} from "../../../../assets/icons/ic_cross.svg"

import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { CircularProgress, Fade, Popover, Popper } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import BaseMaterialButton from "../../BaseMaterialButton";
import { ConstraintSupport } from "../../Validator/types";
import { useValidator } from "@helpers/utils";
import BasePopper from "../../BasePopper";
import { useTranslation } from "react-i18next";
import { TFunctionResult } from "i18next";
import { Disable } from "../../blockers/BaseConditionBlocker/types";
import BaseConditionBlocker from "../../blockers/BaseConditionBlocker";

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  border: 1px solid;
  background-color: #fff;
  border-radius: 8px;
  padding: 12px 16px;
  padding-right: 28px;
  display: flex;
  flex-wrap: wrap;
  transition: .15s;
  position: relative;

  &.active {
    border-color: #7556FA !important;
  }
  
  &.active .dropdown-icon {
    transform: rotate(180deg);
  }

  & input {
    background-color: #fff;
    height: 28px;
    box-sizing: border-box;
    padding: 0 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 2px;
    outline: 0;
  }
  
  & input::placeholder {
    color: #D9D6DA;
    font-weight: 100;
  }
  
  & .dropdown-icon {
    transition: .25s;
  }
`,
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

function Tag(props: TagProps) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon style={{marginTop: "-1px"}} onClick={onDelete} />
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 26px;
  line-height: 22px;
  background-color: #F3F2EF;
  color: #3C3769;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: content-box;
  padding: 0 8px;
  font-size: 14px;
  outline: 0;
  overflow: hidden;
  margin: 2px;

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    margin-left: 4px;
    margin-top: 2px;
    color: #3C3769;
  }
`,
);

const Listbox = styled('ul')(
  ({ theme }) => `
  margin: 2px 0 0;
  padding: 14px;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 10px;
  box-shadow: -6px 5px 33px 0px rgba(59, 65, 208, 0.11);
  z-index: 10000;

  & li { 
    & > div {
      padding: 10px 16px;
      color: #3C3769;
      border-radius: 8px;
      
      display: flex;
      align-items: center;
    }

    & .selected-svg {
      opacity: 0;
      color: #7556FA;
      margin-top: -4px;
    }
  }

  & li[aria-selected='true'] {
    & .selected-svg {
      opacity: 1;
    }
  }

  & li.${autocompleteClasses.focused} > div{
    background-color: rgb(243, 242, 239);
    cursor: pointer;
  }
`,
);

export type BaseDropdownProperties<T> = {
  /**
   * Unique autocomplete id.
   */
  id?: string;
  /**
   * Dropdown options to select.
   */
  options: T[] | undefined,
  /**
   * Getters to acquire label for options and key for comparison.
   */
  getter: {
    key: (item: T) => number | string;
    label: (item: T) => string;

    /**
     * Should be wrapped with <div> element!
     * @param opt
     * @param icon
     */
    renderOption?: (opt: T, icon: JSX.Element) => JSX.Element
  },
  /**
   * Values change callback.
   * @param newState T[] array of actually selected values
   * @param newItem newly added item T or null if item has been removed
   * @param previousState selected items before change
   */
  onChange?: (newState: T[], newItem: T | undefined, previousState: T[]) => void;
  /**
   * Initial value of autocomplete component.
   */
  value?: T[] | T;
  /**
   * Bold label above the input.
   */
  label?: string;
  /**
   * Input's placeholder, shown if no items selected.
   */
  placeholder?: string;
  /**
   * Should dropdown container be 100% of with or 300px.
   * @default true
   */
  dropdownFitInputWidth?: boolean;
  /**
   * Should loader be shown or not.
   * @default false
   */
  isLoading?: boolean;
  autocomplete?: boolean;
  multiple?: boolean;
  brightLabel?: boolean;
  hideSelectedOptions?: boolean;
  /**
   * Action, shown below all available options in the selection menu.
   */
  action?: {
    title: string;
    onClick?: () => void;
  },

  emptyValue?: string;

  customize?: {
      padding?: string;
      leftIcon?: {
        paddingLeft: string;
        el: JSX.Element;
      },
  },

  disableClearable?: boolean;

  disabled?: Disable;
} & ConstraintSupport<T[]>

export default function BaseDropdown<T>({
    dropdownFitInputWidth = true,
    isLoading = false,
    autocomplete = false,
    multiple = false,
    showPopper = true,
    ...props
}: BaseDropdownProperties<T>) {
  const {t} = useTranslation("", {keyPrefix: "general"});

  const topLevelAnchorRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<T[]>([]);
  const previousState = useRef<T[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {uniqueId, validationStore, validationDispatch, validationTrigger, errors, resetValidation, revalidate} = useValidator<T[]>(props.constraints, value, props.externalErrors);

  useEffect(() => setValue(props.value ? Array.isArray(props.value) ? props.value : [props.value] : []), [props.value]);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    anchorEl,
    setAnchorEl
  } = useAutocomplete({
    id: props.id,
    value: !multiple && !value.length ? null : value,
    isOptionEqualToValue: (option, value) => {
      if(multiple){
        return compareKeys(props.getter.key(option), props.getter.key(value));
      }else if(value && (value as T[]).length){
        return compareKeys(props.getter.key(option), props.getter.key((value as T[])[0]));
      }
      return false;
    },
    multiple: multiple,
    options: props.options ?? [],
    filterSelectedOptions: props.hideSelectedOptions,
    getOptionLabel: (option) => {
      return option instanceof Array ? option.length ? props.getter.label(option[0]) : "" : props.getter.label(option);
    },
    onChange: (event, newValue) => {
      // If multiple is set, then MUI passed array as newValue, otherwise we await newly selected value
      if(!(newValue instanceof Array)){
        if(props.disableClearable) {
          newValue = newValue ? [newValue] : []
        }else{
          newValue = newValue && !value.filter(item => compareKeys(props.getter.key(item), props.getter.key(newValue as T))).length ? [newValue] : [];
        }
      }

      setValue(newValue);
      revalidate(newValue);

      const newItem = newValue.length >= previousState.current.length ? newValue[newValue.length-1] : undefined;
      props.onChange?.(newValue, newItem, previousState.current);
      previousState.current = newValue;
    },
    onClose: () => setIsOpen(false),
    onOpen: () => setIsOpen(true),
    open: isOpen,
    disableClearable: props.disableClearable
  });

  const [isDisabled, setIsDisabled] = useState<boolean>();

  return (
    <div className="relative" ref={topLevelAnchorRef}>
      <div {...getRootProps}>
        {props.label && <p className={`mb-2 font-semibold${props.brightLabel ? " text-inputs-label-bright" : " text-inputs-label-dim"}`}>
          {props.label}
        </p>}
        <InputWrapper ref={setAnchorEl} className={`${isOpen ? 'active' : ''} ${isDisabled ? "!border-inputs-border-disabled" : errors ? "!border-inputs-border-error" : "!border-inputs-border-default"}`} sx={{
          padding: props.customize?.padding,
          paddingLeft: props.customize?.leftIcon ? props.customize.leftIcon.paddingLeft : undefined
        }}>
          {/* If multiple values need to be shown or autocomplete enabled, then presenting selected as chips */}
          {multiple && value.map((option, index) => (
            <StyledTag label={props.getter.label(option)} {...getTagProps({ index })} key={index}/>
          ))}

          {
            props.customize?.leftIcon && <div className="absolute top-50 left-[16px] translate-middle-y">
              {props.customize.leftIcon.el}
            </div>
          }

          {/* Showing selected value(s) as simple text, rather than chips + blocking ability to enter text, if no autocomplete specified*/}
          <input {...getInputProps()}
                 placeholder={value.length === 0 ? props.placeholder : ""}
                 readOnly={!autocomplete}
                 className="text-accent"
                 {...{...(props.emptyValue && value.length == 0 && (autocomplete ? !isOpen : true) && {value: props.emptyValue})}}
          />

          <div className="absolute top-50 right-[16px] translate-middle-y text-dropdown-icon">
            {
              isLoading
                ? <CircularProgress color="inherit" size={20} style={{marginTop: "5px"}}/>
                : <DropdownIcon className="dropdown-icon"/>
            }
          </div>

          <BaseConditionBlocker
            disabled={props.disabled}
            simplifyState={state => setIsDisabled(state)}
            position="left"
          />

        </InputWrapper>
      </div>

      {
        showPopper &&
        <BasePopper anchorEl={anchorEl} open={isOpen && !!errors} placement="top-start">
          <div className="bg-[#fff] p-[14px] rounded-[8px] levitation text-inputs-textError font-thin text-sm space-y-[4px]" style={{width: topLevelAnchorRef.current?.offsetWidth}}>
            {
              errors?.map(error => (
                <div key={error.id} className="flex items-center"><CrossSVG style={{transform: "scale(.7)", marginRight: "4px", marginTop: "-1px"}}/> <span>{error.text}</span></div>
              ))
            }
          </div>
        </BasePopper>
      }

      <BasePopper open={isOpen && !isLoading && !!topLevelAnchorRef.current} anchorEl={topLevelAnchorRef.current}>
        <Listbox {...getListboxProps()} sx={{width: topLevelAnchorRef.current?.offsetWidth}}>
          {groupedOptions.length ? (groupedOptions as T[]).map((option, index) => (
              <li {...getOptionProps({ option, index })} key={props.getter.key(option)}>
                {
                  props.getter.renderOption?.(option, <CheckIcon fontSize="small" className="selected-svg"/>)
                  ??
                  <div>
                    <div className="grow">{props.getter.label(option)}</div>
                    <CheckIcon fontSize="small" className="selected-svg"/>
                  </div>
                }
              </li>
            )) :
            <div className="text-center text-accent opacity-50">
              Nothing found
            </div>
          }
          {
            props.action && <div className="mt-[14px]">
              <BaseMaterialButton onClick={props.action.onClick} className="w-full" fontWeight={600} size="large">
                {props.action.title}
              </BaseMaterialButton>
            </div>
          }
        </Listbox>
      </BasePopper>
    </div>
  );
}

function compareKeys<T extends number | string>(key1: T, key2: T): boolean{
  if(typeof key1 === "string"){
    return key1.localeCompare(key2 as string) === 0;
  }else{
    return key1 === key2;
  }
}
