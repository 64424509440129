import React from "react";
import BaseExportModal from "@reusables/Modals/BaseExportModal";
import { performExport } from "@redux/features/importExport/export/thunks";
import { useAppDispatch } from "@redux/hooks";
import { useExportStocksMutation } from "@redux/features/stock/stockApi";
import { adaptToExportRequest, BaseExportModalPropsProvider } from "@reusables/Modals/BaseExportModal/utils";
import { useGetExportableColumnsQuery } from "@redux/features/importExport/importExportApi";
import BaseDatepicker from "@reusables/BaseDatepicker";
import { useTranslation } from "react-i18next";
import { DatepickerDayjs } from "@reusables/BaseDatepicker/types";

type TableStocksExportModalProps = BaseExportModalPropsProvider & {
    selectedStocks: number[];
}

export default function TableStocksExportModal(props: TableStocksExportModalProps) {
    const { t } = useTranslation("", { keyPrefix: "inventory.stock.modals.export" });

    const dispatch = useAppDispatch();

    const [exportStock] = useExportStocksMutation();

    const { data: columns = [], isLoading: isColumnsLoading } = useGetExportableColumnsQuery({ module: "stock" });

    const [date, setDate] = React.useState<DatepickerDayjs>();

    return (
        <BaseExportModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            onSubmit={(data) => {
                dispatch(
                    performExport("stock", () => {
                        return exportStock(adaptToExportRequest(
                            props,
                            data,
                            {
                                filters: {
                                    ...props.filters,
                                    ...(data.amount_selector.is_all ? {
                                            is_all: true
                                        } : {
                                            selected: props.selectedStocks
                                        }
                                    ),
                                    ...(date && {
                                        date: date?.format("YYYY-MM-DD")
                                    })
                                }
                            }
                        )).unwrap();
                    })
                );
            }}
        >
            <BaseExportModal.FiltersInheritanceWarning />
            <BaseExportModal.Email />
            <BaseExportModal.ColumnsSelector columns={columns} isLoading={isColumnsLoading} />
            <BaseExportModal.AmountSelector records={{ selected: props.selectedStocks.length }} />
            <div>
                <BaseDatepicker
                    label={t("fields.historyReversionDate.label")}
                    placeholder={t("fields.historyReversionDate.placeholder")}
                    disableFuture
                    value={date}
                    onChange={setDate}
                />
            </div>
            <BaseExportModal.ConcurrencyWarning />
            <BaseExportModal.Confirm />
        </BaseExportModal>
    );
}