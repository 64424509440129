import React from "react";
import ToggleWrapper from "../../../ToggleWrapper";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import {useTranslation} from "react-i18next";
import {ProductSchemeTyping} from "../../../types";
import {Controller, useFormContext} from "react-hook-form";
import { useGetModuleUnitsQuery } from "@redux/features/units/unitsApi";


export default function UnitDropdown() {
    const {t} = useTranslation("", {keyPrefix: "products"});

    const {data: options, isLoading: optionsLoading} = useGetModuleUnitsQuery("product");

    const {control} = useFormContext<ProductSchemeTyping>();

    return (
        <>
            <ToggleWrapper label={t("general.fields.unit.label")} fieldKey="unit">
                <Controller
                    name={"general.unit"}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}

                            placeholder={t("general.fields.unit.placeholder")}
                            options={options}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name,
                                caption: opt => opt.code ?? opt.id
                            }}
                        />
                    )}
                />
            </ToggleWrapper>
        </>
    )
}