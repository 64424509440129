import { PaginationRequest, PaginationResponse, flattenPaginationRequest, internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { RootState } from "@redux/hooks";
import { Employee } from "@/types/general";
import { createSelector } from "@reduxjs/toolkit";


export const employeesApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEmployeesFull: builder.query<PaginationResponse<Employee.Root>, PaginationRequest<Employee.DTO.Filters, Employee.DTO.OrderBy>>({
            query: (req) => ({
                url: "employee",
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req)
                }
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload?.map(({ id }) => ({ type: 'Employee' as const, id })),
                        { type: 'Employee', id: 'LIST' },
                    ]
                    : [{ type: 'Employee', id: 'LIST' }],
        }),

        getEmployees: builder.query<Employee.Root[], void>({
            query: () => ({
                url: `employee`
            }),
            providesTags: [{type: "Employee", id: "LIST"}],
            transformResponse
        }),

        getModuleEmployees: builder.query<Employee.Root[], string>({
            query: (module) => ({
                url: `${module}/employee`
            }),
            providesTags: [{type: "Employee", id: "LIST"}],
            transformResponse
        }),

        createEmployee: builder.mutation<Employee.Root, Employee.DTO.CreateUpdate>({
            query: (body) => ({
                url: `employee`,
                method: "POST",
                data: body
            }),
            transformResponse,
            invalidatesTags: [{ type: "Employee", id: "LIST" }, {type: "Employee", id: "PARTIAL-LIST"}]

            // async onQueryStarted(body, { dispatch, queryFulfilled }) {
            //     try {
            //         const { data: createdEmployee } = await queryFulfilled;
            //         const createResult = dispatch(employeesApi.util.updateQueryData("getEmployees", undefined, (draft) => {
            //             draft.push({
            //                 id: createdEmployee.id,
            //                 code: createdEmployee.code,
            //                 name: createdEmployee.name,
            //                 job_title: createdEmployee.job_title,
            //                 email: createdEmployee.email,
            //                 // phone: createdEmployee.phone,
            //                 // company: createdEmployee.company,
            //                 // language: createdEmployee.language
            //             })
            //         }))
            //     } catch (err) {
            //         console.error(err);
            //     }
            // }
        }),

        updateEmployee: builder.mutation<Employee.Root, Employee.DTO.CreateUpdate & {id: number}>({
            query: ({id, ...body}) => ({
                url: `employee/${id}`,
                method: "PUT",
                data: body
            }),
            transformResponse,
            invalidatesTags: [{ type: "Employee", id: "LIST" }, {type: "Employee", id: "PARTIAL-LIST"}]

            // async onQueryStarted(body, { dispatch, queryFulfilled }) {
            //     try {
            //         const { data: updatedEmployee } = await queryFulfilled;
            //         const updateResult = dispatch(employeesApi.util.updateQueryData("getEmployees", undefined, (draft) => {
            //             const index = draft.findIndex(item => item.id === updatedEmployee.id);
            //             if (index !== -1) {
            //                 draft[index] = updatedEmployee;
            //             }
            //         }));
            //     } catch (err) {
            //         console.error(err);
            //     }
            // }
        }),

        deleteEmployee: builder.mutation<void, number>({
            query: (id) => ({
                url: `employee/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (_, __, id) => [{ type: "Employee", id }],

            // async onQueryStarted(id, { dispatch, queryFulfilled }) {
            //     try {
            //         const { data: deletedEmployee } = await queryFulfilled;
            //         const deleteResult = dispatch(employeesApi.util.updateQueryData("getEmployees", undefined, (draft) => {
            //             const index = draft.findIndex(item => item.id === id);
            //             if (index !== -1) {
            //                 draft.splice(index, 1);
            //             }
            //         }));
            //     } catch (err) {
            //         console.error(err);
            //     }
            // }
        })
    })
});

export const selectEmployeeResult = employeesApi.endpoints.getEmployees.select();

export const selectAllEmployees = createSelector(
    selectEmployeeResult,
    (employees) => employees?.data ?? []
)

export const selectEmployeeById = createSelector(
    selectAllEmployees,
    (state: RootState, emp_id: number | undefined) => emp_id,
    (employees, emp_id) => employees.find(emp => emp.id === emp_id)
)

export const selectEmployeeByCode = createSelector(
    selectAllEmployees,
    (state: RootState, code: string | undefined | null) => code,
    (employees, code) => employees.find(emp => emp.code === code)
)

export const { 
    useGetEmployeesFullQuery, 
    useGetEmployeesQuery, 
    useGetModuleEmployeesQuery,
    useCreateEmployeeMutation, 
    useUpdateEmployeeMutation, 
    useDeleteEmployeeMutation 
} = employeesApi