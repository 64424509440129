import React, { useState, useMemo } from "react";
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import LazyLoad from "react-lazyload";
import IcLogo from "@assets/icons/ic_logo.svg";
import BaseFormItem from "@reusables/BaseFormItem";
import { ReactComponent as IcCheckedPurple } from "@assets/icons/ic_checked_purple.svg";

const CompanyCreatePage = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [selectedRole, setSelectedRole] = useState(1);
  const [selectedBusiness, setSelectedBusiness] = useState(0);

  const steps = [
    { key: 0, label: "Step 1", title: "Add new company" },
    { key: 1, label: "Step 2", title: "Let’s get to know you better" },
    { key: 2, label: "Step 3", title: "What best fits your business?" },
  ];

  const formItems = [
    { type: "input", key: "name", label: "Company name *" },
    { type: "select", key: "country", label: "Country *" },
    { type: "input", key: "street", label: "Street" },
    { type: "input", key: "street_2", label: "Street 2" },
    { type: "input", key: "city", label: "City" },
    { type: "input", key: "zipcode", label: "Zipcode" },
    { type: "input", key: "phone", label: "Phone" },
    { type: "input", key: "email", label: "Email" },
    { type: "input", key: "job_role", label: "Job Role" },
    { type: "select", key: "base_currency", label: "Base currency" },
  ];

  const roleItems = [
    { id: 1, name: "Business owner" },
    { id: 2, name: "Inventory operations" },
    { id: 3, name: "Production and manufacturing" },
    { id: 4, name: "Purchasing or procurement" },
    { id: 5, name: "Finance or office administration" },
    { id: 6, name: "Sales marketing" },
    { id: 7, name: "Consultant" },
  ];

  const businessItems = [
    { id: 1, name: "Wholesale or distribution" },
    { id: 2, name: "Ecommerce" },
    { id: 3, name: "Manufacturing or assembly" },
    { id: 4, name: "Construction or jobsites" },
    { id: 5, name: "Retail" },
    { id: 6, name: "Office supplies or internal inventory" },
    { id: 7, name: "Other" },
  ];

  const currentStep = useMemo(() => steps[activeStep], [activeStep]);

  const handleContinue = () => setActiveStep((prev) => prev + 1);
  const handleChangeRole = (id: number) =>
    setSelectedRole(id);

  const handleChangeBusiness = (id: number) =>
    setSelectedBusiness((prev) => id);

  const _renderForm = () => (
    <div className="grid tablet:grid-cols-2 gap-8">
      {formItems.map((item) => (
        <BaseFormItem className="filled rounded-[8px]" {...item} key={item.key} />
      ))}
    </div>
  );
  const _renderRoleSelect = () => (
    <List className="space-y-4">
      {roleItems.map((item) => {
        const isSelected = selectedRole === item.id;
        return (
          <ListItem
            key={item.id}
            className={
              isSelected
                ? "rouded-md bg-[#ECEDFE] flex justify-between items-center"
                : ""
            }
            onClick={handleChangeRole.bind(this, item.id)}
          >
            <ListItemButton>
              <ListItemText primary={item.name} />
            </ListItemButton>
            {isSelected && <IcCheckedPurple />}
          </ListItem>
        );
      })}
    </List>
  );
  const _renderBusinessSelect = () => (
    <List className="space-y-4">
      {businessItems.map((item) => {
        const isSelected = selectedBusiness === item.id;
        return (
          <ListItem
            key={item.id}
            className={
              isSelected
                ? "rouded-md bg-[#ECEDFE] flex justify-between items-center"
                : ""
            }
            onClick={handleChangeBusiness.bind(this, item.id)}
          >
            <ListItemButton>
              <ListItemText primary={item.name} />
            </ListItemButton>
            {isSelected && <IcCheckedPurple />}
          </ListItem>
        );
      })}
    </List>
  );

  return (
    <div className="container-lg mx-auto py-8 tablet:py-16">
      <div className="tablet:flex space-y-16 tablet:space-x-[100px]">
        <div>
          <div className="space-y-10 tablet:max-w-[447px]">
            <div className="mb-6">
              <LazyLoad height={64}>
                <img src={IcLogo} alt="Suppli Logo" />
              </LazyLoad>
            </div>

            <div className="text-description !font-bold">
              Efficiency made simple. Manage your warehouse with ease and
              precision.
            </div>
            <div className="text-description">
              “Switching to Suppli has tremendously enhanced our warehouse
              operations. It is the most user-friendly and intuitive solution I
              have ever used, far surpassing any other WMS in terms of
              functionality.”
            </div>
            <div className="text-description">CEO, Webshop</div>
            <div className="text-description !text-base">
              <div>Already have an account?</div>
              <div className="text-primary-purple cursor-pointer">
                Click here to log in
              </div>
            </div>
          </div>

          <div className={`w-full mt-[160px] grid grid-cols-5`}>
            {steps.map(({ label }, index) => (
              <>
                <div className={`px-[16px] py-[8px] rounded-[40px] text-xl font-thin transition-[.25s] ${index === activeStep ? "bg-[#ECEDFE] text-[#7556FA]" : "text-[#686868]"}`}>
                  {label}
                </div>
                {
                  index != steps.length - 1 &&
                  <div className="flex items-center justify-center">
                    <div className="bg-[#686868] h-[.5px] w-[32px]"></div>
                  </div>
                }
              </>
            ))}
          </div>
        </div>

        <div className="space-y-10 flex-1">
          <h2 className="text-desktop font-bold">{currentStep.title}</h2>
          <div className="space-y-8">
            {
              {
                0: _renderForm(),
                1: _renderRoleSelect(),
                2: _renderBusinessSelect(),
              }[activeStep]
            }
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyCreatePage;
