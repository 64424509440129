import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    declOfNum,
    genT,
    PredefinedTranslations,
    removeEmpty,
    useDatesRangeFilter,
    useOrdering,
    usePagination
} from "@helpers/utils";
import { Transfer } from "@/types/general";
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import BaseTable from "@reusables/BaseTable";
import BaseTooltip from "@reusables/BaseTooltip";

import { ReactComponent as RemarkSVG } from "@assets/icons/ic_remark.svg";
import BaseDropdown from "@reusables/dropdowns/BaseDropdownLegacy";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseDatepicker from "@reusables/BaseDatepickerLegacy";
import _ from "lodash";
import DeletionModal from "@components/Dashboard/pages/Inventory/Transfers/components/modals/DeletionModal";
import { DatepickerRangeContext } from "@reusables/BaseDatepickerLegacy/context";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";
import { useGetModuleProductsQuery } from "@redux/features/products/productsApi";
import { useGetModuleUsersQuery } from "@redux/api/internalApiSlice";
import { useGetModuleLocationsQuery } from "@redux/features/locations/locationsApi";
import { useGetTransfersFullQuery } from "@redux/features/transfers/transfersApi";
import { Pagination } from "@mui/material";
import TransfersExportModal from "@components/Dashboard/pages/Inventory/Transfers/MainPage/parts/TransfersExportModal";

export default function TransfersPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "inventory.stockTransfers" });

    // =========== DROPDOWNS ============ //

    // ----> Product related <---- //
    const { data: productsOptions = [], isLoading: productsOptionsLoading } = useGetModuleProductsQuery("transfer");

    const [selectedProducts, setSelectedProducts] = useState<typeof productsOptions>([]);

    // ----> Users related <---- //
    const { data: usersOptions = [], isLoading: usersOptionsLoading } = useGetModuleUsersQuery("transfer");

    const [selectedUsers, setSelectedUsers] = useState<typeof usersOptions>([]);

    // ----> Locations related <---- //
    const { data: locationsOptions = [], isLoading: locationsOptionsLoading } = useGetModuleLocationsQuery("transfer");

    const [selectedLocationsFrom, setSelectedLocationsFrom] = useState<typeof locationsOptions>([]);
    const [selectedLocationsTo, setSelectedLocationsTo] = useState<typeof locationsOptions>([]);

    // ----> Date <---- //
    const { datesRange, setDatesRange, filterAdaptedDatesRange } = useDatesRangeFilter();

    // =========== FILTERS RELATED =========== //
    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(_.debounce(setSearchingValue, 1000), [setSearchingValue]);

    const { orderBy, setOrderBy } = useOrdering<Transfer.DTO.OrderBy>({ name: "id", type: "desc" });

    const pagination = usePagination({ page: 1, limit: 8 });

    const filters = removeEmpty({
        search: searchingValue,
        products: selectedProducts.map(x => x.id),
        location_from: selectedLocationsFrom.length ? selectedLocationsFrom.map(x => ({
            store: x.store.id,
            ...(x.section && { section: x.section.id })
        }))[0] : undefined,
        location_to: selectedLocationsTo.length ? selectedLocationsTo.map(x => ({
            store: x.store.id,
            ...(x.section && { section: x.section.id })
        }))[0] : undefined,
        users: selectedUsers.map(x => x.id),
        dates_range: filterAdaptedDatesRange
    });


    const { data: transfersResponse, isFetching: isTransferResponseFetching } = useGetTransfersFullQuery({
        filters,
        orderBy,
        pagination: {
            page: pagination.page,
            limit: pagination.limit
        }
    });

    // ==== MODALS ==== //
    const [activeTransferDeletionId, setActiveTransferDeletionId] = useState<number>();
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);

    return (
        <>
            {/* FILTERS BLOCK */}
            <BaseAdminFilter
                permissionModule={"transfer"}
                filterItems={
                    <div className="w-[702px]">
                        <BaseInputsGrid cols={2} gap={24}>
                            <BaseDropdown
                                label={t("modals.filter.fields.product.label")}
                                options={productsOptions}
                                value={selectedProducts}
                                getter={{
                                    label: opt => opt.name,
                                    key: opt => opt.id,
                                    renderOption: (opt, icon) => (
                                        <div>
                                            <span className="grow">{opt.name}</span>
                                            {
                                                selectedProducts.includes(opt) ? icon :
                                                    <span className="text-lightGreen-500 font-thin">{opt.code}</span>
                                            }
                                        </div>
                                    )
                                }}

                                onChange={setSelectedProducts}

                                autocomplete
                                multiple

                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                isLoading={productsOptionsLoading}

                                brightLabel
                            />

                            <BaseDropdown
                                label={t("modals.filter.fields.user.label")}
                                options={usersOptions}
                                value={selectedUsers}
                                getter={{
                                    label: opt => opt.first_name.charAt(0).toUpperCase().concat(opt.first_name.slice(1, opt.first_name.length)),
                                    key: opt => opt.id
                                }}

                                onChange={setSelectedUsers}

                                autocomplete
                                multiple

                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                isLoading={usersOptionsLoading}

                                brightLabel
                            />

                            <BaseDropdown
                                label={t("modals.filter.fields.fromLocation.label")}
                                options={
                                    selectedLocationsTo.length ?
                                        locationsOptions.filter(loc => !_.isEqual(selectedLocationsTo[0], loc))
                                        :
                                        locationsOptions
                                }
                                value={selectedLocationsFrom}
                                getter={{
                                    label: opt => `${opt.store.name}${opt.section ? ` - ${opt.section.name}` : ""}`,
                                    key: opt => opt.section ? `${opt.store.id}_${opt.section.id}` : `${opt.store.id}`,
                                    renderOption: (opt, icon) => (
                                        <div>
                                            <div className="grow">
                                                <span
                                                    className="bold-highlight">{opt.store.name}</span>{opt.section ? <> - {opt.section.name}</> : null}
                                            </div>
                                            {
                                                selectedLocationsFrom.includes(opt) ? icon : null
                                            }
                                        </div>
                                    )
                                }}

                                onChange={setSelectedLocationsFrom}

                                autocomplete

                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                isLoading={locationsOptionsLoading}

                                brightLabel
                            />

                            <BaseDropdown
                                label={t("modals.filter.fields.toLocation.label")}
                                options={
                                    selectedLocationsFrom.length ?
                                        locationsOptions.filter(loc => !_.isEqual(selectedLocationsFrom[0], loc))
                                        :
                                        locationsOptions
                                }
                                value={selectedLocationsTo}
                                getter={{
                                    label: opt => `${opt.store.name}${opt.section ? ` - ${opt.section.name}` : ""}`,
                                    key: opt => opt.section ? `${opt.store.id}_${opt.section.id}` : `${opt.store.id}`,
                                    renderOption: (opt, icon) => (
                                        <div>
                                            <div className="grow">
                                                <span
                                                    className="bold-highlight">{opt.store.name}</span>{opt.section ? <> - {opt.section.name}</> : null}
                                            </div>
                                            {
                                                selectedLocationsTo.includes(opt) ? icon : null
                                            }
                                        </div>
                                    )
                                }}

                                onChange={setSelectedLocationsTo}

                                autocomplete

                                emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                isLoading={locationsOptionsLoading}

                                brightLabel
                            />

                            <DatepickerRangeContext.Provider value={{
                                "delivery_date": { range: datesRange, setRange: setDatesRange }
                            }}>
                                <BaseDatepicker
                                    label={t("modals.filter.fields.date.label")}
                                    placeholder={t("modals.filter.fields.date.placeholder")}
                                    rangeConfig={{ groupKey: "delivery_date", role: "solo" }}
                                    brightLabel
                                />
                            </DatepickerRangeContext.Provider>
                        </BaseInputsGrid>
                    </div>
                }

                handleSearch={searchInputDebounce}

                handleFilter={(e) => console.log(e)}
                handleCreate={() => history.push("/dashboard/inventory/transfers/new")}
                handleExport={() => setIsExportModalOpen(true)}
            />

            <div className="levitation-extended mt-[32px]">
                <Can not I="view" a="transfer">
                    <NoPermissionBanner />
                </Can>

                <Can I="view" a="transfer">
                    <div className="mb-[24px]">
                        {
                            <div
                                className="bold-highlight capitalize">{transfersResponse?.meta?.total} {declOfNum(transfersResponse?.meta?.total ?? 0, [t("table.titleDeclinations.0"), t("table.titleDeclinations.1"), t("table.titleDeclinations.2")])}</div>
                        }
                    </div>

                    <BaseTable
                        data={transfersResponse?.payload ?? []}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: row =>
                                    <>
                                        <Can I="view" a="transfer">
                                            <div
                                                onClick={() => history.push(`/dashboard/inventory/transfers/${row.id}/details`)}
                                                className="cursor-pointer hover:opacity-60 transition-[.15s]"
                                            >
                                                <div className="bold-highlight">{row.product.name}</div>
                                                <div className="text-sm text-accent">{row.code}</div>
                                            </div>
                                        </Can>

                                        <Can not I="view" a="transfer">
                                            <div>
                                                <div className="bold-highlight">{row.product.name}</div>
                                                <div className="text-sm text-accent">{row.code}</div>
                                            </div>
                                        </Can>
                                    </>,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.1"),
                                getter: row => `${row.location_from.store}${row.section_from ? " - " + row.section_from.name : ""}`,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.2"),
                                getter: row => `${row.location_to.name}${row.section_to ? " - " + row.section_to.name : ""}`,
                                comparator: () => 0
                            },
                            {
                                header: <div className="text-center">{t("table.columns.3")}</div>,
                                getter: row => row.user.name,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.4"),
                                getter: row => row.remarks ? <BaseTooltip title={row.remarks}><RemarkSVG
                                    className="text-tables-primaryIcon" /></BaseTooltip> : <></>
                            },
                            {
                                header: t("table.columns.5"),
                                getter: row => row.date.format("DD.MM.YYYY"),
                                comparator: () => 0
                            }
                            // {
                            //     header: t("table.columns.6"),
                            //     getter: row => <div className="space-x-[20px] text-tables-secondaryIcon">
                            //         <DeleteSVG className="hover:text-tables-highlightedIcon cursor-pointer"
                            //                    onClick={() => setActiveTransferDeletionId(row.id)}/>
                            //     </div>
                            // }
                        ]}

                        alternate
                        isDataLoading={isTransferResponseFetching}
                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering) {
                                    let name: Transfer.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 0:
                                            name = "product";
                                            break;
                                        case 1:
                                            name = "locationFrom";
                                            break;
                                        case 2:
                                            name = "locationTo";
                                            break;
                                        case 3:
                                            name = "user";
                                            break;
                                        case 5:
                                            name = "date";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                        setOrderBy({
                                            name,
                                            type: newOrdering.order
                                        });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}
                    />

                    <Pagination
                        className="mt-[32px]"
                        {...pagination.adapt(transfersResponse)}
                    />
                </Can>
            </div>

            {/* DELETION MODAL */}
            <DeletionModal
                isOpen={!!activeTransferDeletionId}
                onClose={() => setActiveTransferDeletionId(undefined)}
                transferId={activeTransferDeletionId}
            />

            {/* EXPORT MODAL */}
            <TransfersExportModal
                isOpen={isExportModalOpen}
                onClose={() => void setIsExportModalOpen(false)}
                filters={filters}
                orderBy={orderBy}
            />
        </>
    );
}

