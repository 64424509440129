import React, { useEffect, useState } from 'react'
import { loadStripe } from "@stripe/stripe-js"
import { useLocation } from 'react-router-dom';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const stripePK = import.meta.env.VITE_STRIPE_PK;

let stripePromise:any;

const getStripe = () => {
  if(!stripePromise) {
    stripePromise = loadStripe(stripePK!);
  }

  return stripePromise;
};

function PaymentPage() {

  const [stripeError, setStripeError] = useState(null);
  const [loading, setLoading] = useState(false);

  // url plan
  const [plan, setPlan] = useState<string|null>("");

  // item passed to stripe constructor
  const [stripeItem, setStripeItem] = useState<any>(null);

  // license option (from 5 different types)
  const [licenseType, setLicenseType] = useState("");

  // quantity of months
  const [licenseQuantity, setLicenseQuantity] = useState<number>(1);

  // single price
  const [singlePrice, setSinglePrice] = useState<number>(0);

  // overall payment
  const [overall, setOverall] = useState<number>(0);

  // one time start package payment
  const startPackage = {
    price: "price_1KlAbxCwPNpJ34Ji2l9dHIia",
    quantity: 1,
  };

  // monthly recurring payments

  // premium license
  const premiumLicense = {
    price: 'price_1KlAdrCwPNpJ34JiVehLBPYN',
  }
  // warehouse license
  const warehouseLicense = {
    price: 'price_1KlAhwCwPNpJ34JiZh60P4WW',
  }
  // accounting license
  const accountingLicense = {
    price: 'price_1KlAjXCwPNpJ34JiLoGucukt',
  }
  // bp1 license
  const bp1License = {
    price: 'price_1KlAkeCwPNpJ34Ji7U7BqTfj',
  }

  // bp2 license
  const bp2License = {
    price: 'price_1KlAmYCwPNpJ34JizqFMdJbv',
  }

  const decreaseLicenseAmount = () => {
    if(licenseQuantity > 1) {
      setLicenseQuantity(licenseQuantity-1);
    }
  }

  const increaseLicenseAmount = () => {
    if(licenseQuantity < 12) {
      setLicenseQuantity(licenseQuantity+1);
    }
  }

  useEffect(() => {
    setOverall(singlePrice * licenseQuantity);
  }, [licenseQuantity, licenseType])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const p = urlParams.get('plan');

    if(p=='start' || p=='license' || p=='support') {
      console.log(p);
      setPlan(p);
    }
    else {
      setPlan("Incorrect pack");
    }


  }, [])


  const checkoutOptions = {
    lineItems: [{
      ...stripeItem, quantity: licenseQuantity
    }],
    mode: plan=='start' ? 'payment' : 'subscription',
    successUrl: `${window.location.origin}/success`,
    cancelUrl: `${window.location.origin}/cancel`
  };

  const redirectToCheckout = async() => {
    setLoading(true);

    if(plan=='start') {
      setStripeItem(startPackage);
    }

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);

    // console.log("STRIPE ERROR: ", error);

    if(error) {
      setStripeError(error);
    }

    setLoading(false);
  }

  if(stripeError) {
    alert(stripeError);
  }

  return (
    <div className='container my-2'>
        <h4>
            More information about the specific plan.
        </h4>

        <h6>
          Plan you selected: {plan}
        </h6>

        {/* License */}
        {
          plan=='license' && (
            <div>
              {licenseType && (
                <h6>Selected License: {licenseType}</h6>
              )}
                <div className="d-flex">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Premium"
                    name="radio-buttons-group"
                    value={licenseType}
                    onChange={(e) => setLicenseType(e.target.value)}
                  >

                    {/* Radio buttons that set stripe payment */}
                    <FormControlLabel value="premium" control={<Radio />} label="Premium" onClick={() => {
                      setSinglePrice(41.94)
                      setStripeItem(premiumLicense)
                    }} />
                    <FormControlLabel value="warehouse" control={<Radio />} label="Warehouse" onClick={() => {
                      setSinglePrice(20.97)
                      setStripeItem(warehouseLicense)
                    }} />
                    <FormControlLabel value="accounting" control={<Radio />} label="Accouting" onClick={() => {
                      setSinglePrice(20.97)
                      setStripeItem(accountingLicense)
                    }} />
                    <FormControlLabel value="bp1" control={<Radio />} label="Business Package 1" onClick={() => {
                      setSinglePrice(209.71)
                      setStripeItem(bp1License)
                    }} />
                    <FormControlLabel value="bp2" control={<Radio />} label="Business Package 2" onClick={() => {
                      setSinglePrice(471.84)
                      setStripeItem(bp2License)
                    }} />
                  </RadioGroup>

                  <div>
                    <p style={{ fontWeight: '500', color: 'black' }}>
                      Price (single): { singlePrice } €
                    </p>

                    <p style={{ fontWeight: '600', color: 'black' }}>
                      Overall price: { overall } €
                    </p>
                  </div>
                </div>
            </div>
          )
        }

        {/* Start package button */}
        {
          plan=='start' && (
            <button
              onClick={redirectToCheckout}
              disabled={loading}
            >
              {
                loading ? 'Loading...' : 'Buy now'
              }
            </button>
          )
        }

        {/* License button */}
        {
          plan=='license' && licenseType && (
            <div>
              <div>
                <button onClick={decreaseLicenseAmount}>
                  -
                </button>
                Quantity is: {licenseQuantity}
                <button onClick={increaseLicenseAmount}>
                  +
                </button>
              </div>
              <button
                onClick={redirectToCheckout}
                disabled={loading}
              >
                {
                  loading ? 'Loading...' : 'Buy now'
                }
              </button>
            </div>
          )
        }


    </div>
  )
}

export default PaymentPage;
