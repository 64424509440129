import React, { useCallback, useState } from "react";
import { useOrdering, usePagination } from "@helpers/utils";
import { useGetBOMsHistoryFullQuery } from "@redux/features/bom/bomApi";
import { BOMHistory } from "@/types/general";
import BaseTable from "@reusables/BaseTable";
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import { Pagination } from "@mui/material";
import _ from "lodash";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@components/ErrorPages/NoPermissionBanner";
import TableButton from "@/components/reusables/BaseTable/TableButton";
import SerialNumbersModal from "../components/modals/ProduceModal/modals/SerialNumbersModal";
import BatchNumbersModal from "../components/modals/ProduceModal/modals/BatchNumbersModal";
import dayjs from "dayjs";

export default function ProducingHistoryPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "bom.producingHistory" });

    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(
        _.debounce(setSearchingValue, 1000),
        []
    );

    const filters = {
        search: searchingValue
    };

    const { orderBy, setOrderBy } = useOrdering<BOMHistory.DTO.OrderBy>({
        name: "date",
        type: "desc"
    });

    const pagination = usePagination({ page: 1, limit: 8 });

    const { data, isFetching: dataLoading } = useGetBOMsHistoryFullQuery({
        filters,
        orderBy,
        pagination
    });

    const serialNumbersBOM = data?.payload?.filter(bom => bom.serial_numbers.length > 0);
    const batchNumbersBOM = data?.payload?.filter(bom => bom.batch_numbers.length > 0);

    // BSN modals
    const [isSerialNumberModalOpen, setIsSerialNumberModalOpen] = React.useState(false);
    const [isBatchNumberModalOpen, setIsBatchNumberModalOpen] = React.useState(false);

    const [selectedBOM, setSelectedBOM] = React.useState<BOMHistory.Root | null>(null);

    return (
        <>
            <BaseAdminFilter
                handleSearch={searchInputDebounce}
            />

            <div className="levitation-extended mt-[32px]">
                <Can not I="bill_of_materials.view" a="product">
                    <NoPermissionBanner />
                </Can>
                
                <Can I="bill_of_materials.view" a="product">
                    <div className="flex justify-between mb-[24px]">
                        {
                            <>
                                <div className="bold-highlight">{t("heading")}</div>
                                <div
                                    className="font-thin underline underline-offset-1 text-[#686868] hover:text-accent cursor-pointer"
                                    onClick={() => history.push("/dashboard/bill-of-materials")}
                                >
                                    {t("links.bomList")}
                                </div>
                            </>
                        }
                    </div>
                    <BaseTable
                        data={data?.payload ?? []}
                        columns={[
                            {
                                header: t("table.columns.0"),
                                getter: (row) =>
                                    <>
                                        <Can I="bill_of_materials.create" a="product">
                                            <div
                                                className="bold-highlight text-accent hover:underline cursor-pointer"
                                                onClick={() => history.push(`/dashboard/bill-of-materials/${row.bom.id}/details`)}
                                            >
                                                {row.bom.name}
                                            </div>
                                        </Can>

                                        <Can not I="bill_of_materials.create" a="product">
                                            <div className="bold-highlight text-accent">{row.bom.name}</div>
                                        </Can>
                                    </>,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.1"),
                                getter: (row) =>
                                    <>
                                        <Can I="view" a="product">
                                            <Link
                                                to={`/dashboard/products/${row.product.id}/details`}
                                            >
                                                <u>
                                                    {row.product.name}
                                                </u>
                                            </Link>
                                        </Can>

                                        <Can not I="view" a="product">
                                            <div className="bold-highlight text-accent">{row.product.name}</div>
                                        </Can>
                                    </>,
                                comparator: () => 0
                            },

                            {
                                header: t("table.columns.2"),
                                getter: (row) => row.quantity,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.3"),
                                getter: (row) => `${row.produce_location.store.name} ${row.produce_location.section ? " - " + row.produce_location.section?.name : ""}`,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.4"),
                                getter: (row) => row.date.format("DD.MM.YYYY") ?? "",
                                comparator: () => 0
                            },
                            // TODO: ADD Serial and Batch numbers
                            {
                                header: t("table.columns.5"),
                                getter: row => (
                                    <div className="flex justify-between space-x-2">
                                        {row.serial_numbers.length > 0 && (
                                            <TableButton
                                                onClick={() => {
                                                    setSelectedBOM(row);
                                                    setIsSerialNumberModalOpen(true);
                                                }}
                                                className="text-sm text-accent"
                                            >
                                                {t("table.buttons.sn")}
                                            </TableButton>
                                        )}
                                        {row.batch_numbers.length > 0 && (
                                            <TableButton
                                                onClick={() => {
                                                    setSelectedBOM(row);
                                                    setIsBatchNumberModalOpen(true);
                                                }}
                                                className="text-sm text-accent rounded-sm"
                                            >
                                                {t("table.buttons.bn")}
                                            </TableButton>
                                        )}
                                    </div>
                                ),
                                preventCollapsePropagation: true,
                                sx: {width: "100px"}
                            }
                        ]}

                        collapse={{
                            fill: "#D9DAFD",
                            borderColor: "#B3B5EB",
                            content: (mainRow) => {
                                return (
                                    <BaseTable
                                        data={mainRow.components}
                                        columns={[
                                            {
                                                header: <div className="text-accent font-semibold">
                                                    {t("table.subtable.columns.0")}
                                                </div>,
                                                getter: (row) => <Link
                                                    to={`/dashboard/products/${row.product.id}/details`}><u>{row.product.name}</u></Link>
                                            },
                                            {
                                                header: <div className="text-accent font-semibold">
                                                    {t("table.subtable.columns.1")}
                                                </div>,
                                                getter: (row) => <>
                                                    {row.outgoing_locations.map((location, index) => {
                                                            return <div key={index}>{location.quantity}</div>;
                                                        }
                                                    )}
                                                </>
                                            },
                                            {
                                                header: <div className="text-accent font-semibold">
                                                    {t("table.subtable.columns.2")}
                                                </div>,
                                                getter: (row) => <>
                                                    {row.outgoing_locations.map((location, index) => {
                                                            const { store, section } = location;
                                                            return (
                                                                <div key={index} className={"space-x-[4px]"}>
                                                                    <span className={"font-[600]"}>{store.name}</span>
                                                                    {
                                                                        !!section && <span>- {section.name}</span>
                                                                    }
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </>
                                            }
                                        ]}
                                        nothingFound={{
                                            height: 200
                                        }}
                                        hideTableWhenNothingFound
                                        alternate
                                        sx={{
                                            "& tbody td": {
                                                verticalAlign: "top",
                                                "&:nth-child(-n+5)": {
                                                    paddingTop: "16px"
                                                }
                                            }
                                        }}
                                    />
                                );
                            }
                        }}

                        alternate
                        isDataLoading={dataLoading}
                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering) {
                                    let name: BOMHistory.DTO.OrderBy | undefined;

                                    switch (newOrdering.index) {
                                        case 0:
                                            name = "productName";
                                            break;
                                        case 1:
                                            name = "producedQuantity";
                                            break;
                                        case 2:
                                            name = "locationName";
                                            break;
                                        case 3:
                                            name = "date";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name) {
                                        setOrderBy({
                                            name,
                                            type: newOrdering.order
                                        });
                                    }
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}
                    />
                    <Pagination className="mt-32" {...pagination.adapt(data)} />
                </Can>
            </div>

            {/* Serial Number Modal */}
            {
                serialNumbersBOM && serialNumbersBOM?.length > 0 && (
                    <SerialNumbersModal
                        isOpen={isSerialNumberModalOpen}
                        onClose={() => setIsSerialNumberModalOpen(false)}
                        product={{
                            quantity: selectedBOM?.quantity ?? 0,
                            serial_numbers: selectedBOM?.serial_numbers
                        }}
                        preview
                    />
                )
            }

            {/* Batch Number Modal */}
            {
                batchNumbersBOM && batchNumbersBOM?.length > 0 && (
                    <BatchNumbersModal
                        isOpen={isBatchNumberModalOpen}
                        onClose={() => setIsBatchNumberModalOpen(false)}
                        product={{
                            quantity: selectedBOM?.quantity ?? 0,
                            batch_numbers: selectedBOM?.batch_numbers.map(batch => {
                                return {
                                    batch_number: batch.batch_number,
                                    expiration_date: dayjs(batch.expiration_date)
                                };
                            })
                        }}
                        preview
                    />
                )
            }
        </>
    );
}
