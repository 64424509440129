import React, { forwardRef, useRef, useState } from "react";
import { FormControl, Input, InputProps, useFormControl } from "@mui/material";
import BasePopper from "@reusables/BasePopper";

import { ReactComponent as CrossSVG } from "@assets/icons/ic_cross.svg";
import { FieldError } from "react-hook-form/dist/types";
import BaseConditionBlocker from "../blockers/BaseConditionBlocker";
import { InputBasics } from "../reusablesCore";
import _ from "lodash";

type Merged = Omit<InputProps, "error" | "onChange"> & InputBasics<string, string | number>;

interface BaseInputProperties extends Merged {
    label?: string;
    brightLabel?: boolean;

    icon?: Partial<Record<"left" | "right", {
        el: JSX.Element,
        offset: string | number;
    }>>;

    step?: "any" | number;

    /**
     * For now we have configured only "secondary" variant in the mui theme configuration (@see src/config/theme.ts).
     * More can be added.
     */
    variant?: "secondary";
}

const BaseInput = forwardRef<HTMLInputElement, BaseInputProperties>(({
                                                                         icon,
                                                                         hideErrors = false,
                                                                         ...props
                                                                     }, ref) => {
    const popperElRef = useRef<HTMLDivElement>(null);

    const [isFocused, setIsFocused] = useState<boolean>(false);

    return (
        <FormControl
            ref={popperElRef}
            error={!!props.error}
            disabled={props.disabled}
            fullWidth
        >
            {/* ## Label ## */}
            {
                props.label &&
              <p
                className={`mb-2 font-semibold${props.brightLabel ? " text-inputs-label-bright" : " text-inputs-label-dim"}`}>
                  {props.label}
              </p>
            }

            <div className="relative">
                {/* ## Input ## */}
                <Input
                    {..._.omit(props, ["error", "step"])}

                    inputRef={ref}

                    onChange={e => {
                        props.onChange?.(e.target.value);
                    }}

                    onFocus={(e) => {
                        props.onFocus?.(e);
                        setIsFocused(true);
                    }}

                    onBlur={(e) => {
                        setIsFocused(false);
                        props.onBlur?.(e);
                    }}

                    value={props.value?.toString() ?? ""}

                    inputProps={{
                        step: props.step,
                        style: {
                            ...(props.disabled && props.error && {
                                borderColor: "#E25C5C"
                            })
                        }
                    }}
                />

                {/* ## Icon LEFT ## */}
                {
                    icon?.left &&
                  <div className={`absolute top-50 translate-middle-y`} style={{ left: icon.left.offset }}>
                      {icon.left.el}
                  </div>
                }

                {/* ## Icon RIGHT ## */}
                {
                    icon?.right &&
                  <div className={`absolute top-50 translate-middle-y`} style={{ right: icon.right.offset }}>
                      {icon.right.el}
                  </div>
                }

                {/* ## Blocker with a reason ## */}
                {
                    props.disabled && props.disableReason &&

                  <BaseConditionBlocker
                    disabled={{
                        reason: props.disableReason,
                        state: props.disabled
                    }}
                    position="left"
                  />
                }
            </div>


            {!hideErrors && <ErrorsPopper anchorEl={popperElRef} error={props.error} />}
        </FormControl>
    );
});

// This component is placed in a separate function to all using useFormControl hook, which depends on parent's FromControl
function ErrorsPopper({ anchorEl, error }: { anchorEl: React.RefObject<HTMLElement>, error?: FieldError | string }) {
    const { focused } = useFormControl() || {};

    return (
        <BasePopper anchorEl={anchorEl.current} open={!!focused && !!error} placement="bottom">
            <div
                className="bg-[#fff] p-[14px] rounded-[8px] levitation text-inputs-textError font-thin text-sm space-y-[4px]"
                style={{ width: anchorEl.current?.offsetWidth }}>
                <div className="flex items-center">
                    <CrossSVG style={{ transform: "scale(.7)", marginRight: "4px", marginTop: "-1px" }} />
                    <span>{typeof error === "string" ? error : error?.message}</span>
                </div>
            </div>
        </BasePopper>
    );
}

BaseInput.displayName = "BaseInput";

export default BaseInput;