/* eslint-disable react/jsx-no-undef */
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next';
import {Can} from "@/casl.config";
import BaseMaterialButton from '@reusables/BaseMaterialButton';
import BaseTable from '@reusables/BaseTable';
import {ReactComponent as EditSVG} from "@assets/icons/ic_edit.svg";
import {ReactComponent as DeleteSVG} from "@assets/icons/ic_trash.svg";
import DeletionModal from './modals/DeletionModal';
import {useAppSelector} from '@redux/hooks';
import {selectUnitsById, useGetUnitsQuery} from '@redux/features/units/unitsApi';
import MutationModal from './modals/MutationModal';
import NoPermissionBanner from "@components/ErrorPages/NoPermissionBanner";

export default function UnitsPage() {

    // Getting units data using RTK Query
    const { data: units = [], isLoading: isLoadingUnits } = useGetUnitsQuery();

    const { t } = useTranslation("", { keyPrefix: "settings.general.units" }); //TODO: LOCALIZE

    // Open states determine visibility of modals
    const [isMutationModalOpen, setIsMutationModalOpen] = useState(false);
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

    // Ids and corresponding selected cache entries allows modals to work with data
    const [selectedUnitId, setSelectedUnitId] = useState<number>();
    const [deletableUnitId, setDeletableUnitId] = useState<number>();

    const selectedUnit = useAppSelector(state => selectUnitsById(state, selectedUnitId));
    const deletableUnit = useAppSelector(state => selectUnitsById(state, deletableUnitId));

    return (
        <>
            <div className="levitation-extended">
                <div className="flex flex-model justify-between items-center mb-[44px]">
                    <div className="font-bold text-2xl text-accent">{t("heading")}
                    </div>
                    <Can I="units.view" a="general">
                        <BaseMaterialButton fontWeight={500} onClick={() => {
                            setSelectedUnitId(undefined);
                            setIsMutationModalOpen(true);
                        }}>
                            {t("table.buttons.add")}
                        </BaseMaterialButton>
                    </Can>
                </div>

                <Can not I="units.view" a="general">
                    <NoPermissionBanner />
                </Can>

                <Can I="units.view" a="general">
                    <BaseTable
                        data={units}
                        columns={[
                            {
                                header:t("table.columns.0"),
                                getter: (row) => <div className="bold-highlight">{row.name}</div>,
                                comparator: (a, b) => a.name.localeCompare(b.name)
                            },
                            {
                                header:t("table.columns.1"),
                                getter: (row) => row.code,
                                comparator: (a, b) => a.code.localeCompare(b.code),
                            },
                            {
                                header:t("table.columns.2"),
                                getter: (row) => row.custom_name ?? "-",
                                comparator: (a, b) => a.custom_name?.localeCompare(b.custom_name ?? "") ?? 0, //IS THERE A LOCALE COMPARE FOR CUSTOM NAMES??
                            },
                            {
                                visible: true, // ability.can("update", "units") || ability.can("delete", "units"), // doesnt work as inteneded (but it should)
                                header: () => "",
                                getter: (row) => (
                                    <div className="text-tables-secondaryIcon">
                                        <Can I="units.view" a="general">
                                            <EditSVG
                                                className="mr-[8px] cursor-pointer hover:text-tables-highlightedIcon"
                                                onClick={() => {
                                                    setSelectedUnitId(row.id);
                                                    setIsMutationModalOpen(true);
                                                }}
                                            />
                                        </Can>
                                        <Can I="units.view" a="general">
                                            <DeleteSVG
                                                className="cursor-pointer hover:text-tables-highlightedIcon"
                                                onClick={() => {
                                            
                                                    setDeletableUnitId(row.id);
                                                    setIsDeletionModalOpen(true);                      
                                                }}
                                            />
                                        </Can>
                                    </div>
                                ),
                                sx: {
                                    textAlign: "right"
                                }
                            }
                        ]}
                        isDataLoading={isLoadingUnits}

                        headerSx={{
                            backgroundColor: "#F9F9F9"
                        }}

                        sx={{
                            borderCollapse: "separate",
                            borderSpacing: "0 15px"
                        }}

                        size={"small"}
                    />
                </Can>
            </div>

            <MutationModal
                isOpen={isMutationModalOpen}
                onClose={() => {
                    setIsMutationModalOpen(false);
                    
                }}
                unit={selectedUnit}
                availableUnits={units}
                
            />

            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                unit={deletableUnit}
            />

        </>
    );
}