import Slider from "@mui/material/Slider/Slider";
import { styled } from "@mui/material/styles";
import React, { useEffect, useMemo, useState } from "react";
import BaseInput from "@reusables/BaseInput";
import { normalizeNumber } from "@helpers/utils";

type BaseRangeSliderProperties = {
    /**
     * Minimum value of the slider.
     */
    min: number;
    /**
     * Maximum value of the slider.
     */
    max: number;
    /**
     * Current slider value: array, containing two values for min and max thumbs correspondingly.
     */
    value?: number[];
    /**
     * Pay attention, that newValue might be undefined if current values range is equal to min:max values range
     */
    onChange?: (e: Event, newValue: number[] | undefined, activeThumb: number) => void;
    /**
     * Allows configuring whether label should be shown always or only when slider is "touched".
     * @default "auto"
     */
    valueLabelDisplay?: "auto" | "on" | "off";
    /**
     * Allows configuration with numerical inputs for better precision.
     * @default false
     */
    showInputs?: boolean;
}

export default function BaseRangeSlider({
                                            min,
                                            max,
                                            value,
                                            onChange,
                                            valueLabelDisplay = "auto",
                                            showInputs = false
                                        }: BaseRangeSliderProperties) {
    /**
     * Tracking internal state to dynamically update slider values wihout parent controller rerender.
     */
    const [internalValue, setInternalValue] = useState<number[]>([]);

    /**
     * Tracking input values to properly handle clearing of the input field.
     */
    const [minInputValue, setMinInputValue] = useState<number | undefined>(min);
    const [maxInputValue, setMaxInputValue] = useState<number | undefined>(max);

    /**
     * Specifying slider step.
     */
    const step = useMemo(() => {
        // Commented out, since step "1" seems to be the most convenient value for the end user
        // const diff = (max - min) / 100;
        // return diff > 1 ? Math.round(diff) : diff;
        return 1;
    }, [min, max]);

    /**
     *  Updating slider values, when user types in the inputs.
     *  This is necessary to keep the slider and input values in sync including handling of the clean inputs cases.
     */
    useEffect(() => {
        setInternalValue([minInputValue ?? min, maxInputValue ?? max]);
    }, [minInputValue, maxInputValue]);

    /**
     * Updating internal value, when parent component changes value prop.
     */
    useEffect(() => {
        const final = value ?? [min, max];
        setInternalValue(final);
        setMinInputValue(final[0]);
        setMaxInputValue(final[1]);
    }, [value]);

    /**
     * Propagating change to the parent component.
     */
    const propagateChange = (newValue: number[] | undefined, activeThumb: number, e?: Event) => {
        onChange?.(e ?? new Event("slider_thumb_" + activeThumb + "_change"), newValue ? [
            Math.min(newValue[0], newValue[1]),
            Math.max(newValue[0], newValue[1])
        ] : undefined, activeThumb);
    };

    return (
        <div>
            {showInputs && (
                <div className="flex items-center justify-between space-x-2">
                    <BaseInput
                        type="number"
                        value={minInputValue}
                        onChange={(input) => {
                            const parsedNumber = normalizeNumber(input ?? "", "float");
                            setMinInputValue(parsedNumber);
                        }}
                        onBlur={(e) => {
                            // This setter is needed to handle clean input cases
                            setMinInputValue(prev => prev ?? internalValue[0]);
                            propagateChange(internalValue, 0);
                        }}
                    />
                    <span>-</span>
                    <BaseInput
                        type="number"
                        value={maxInputValue}
                        onChange={(input) => {
                            const parsedNumber = normalizeNumber(input ?? "", "float");
                            setMaxInputValue(parsedNumber);
                        }}
                        onBlur={(e) => {
                            // This setter is needed to handle clean input cases
                            setMaxInputValue(prev => prev ?? internalValue[1]);
                            propagateChange(internalValue, 1);
                        }}
                    />
                </div>
            )}
            <SuppliSlider
                min={min}
                max={max}
                value={internalValue ?? [min, max]}
                step={step}
                onChange={(e, val, activeThumb) => {
                    const localVal = val as number[];
                    const propagatableValue = localVal[0] === min && localVal[1] === max ? undefined : localVal;
                    propagateChange(propagatableValue, activeThumb, e);
                    setInternalValue(propagatableValue ?? [min, max]);
                }}
                valueLabelDisplay={valueLabelDisplay}
                disableSwap
            />
        </div>
    );
}

const SuppliSlider = styled(Slider)({
    color: "#7556FA",
    height: 8,
    "& .MuiSlider-track": {
        border: "none"
    },
    "& .MuiSlider-thumb": {
        height: 22,
        width: 22,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit"
        },
        "&:before": {
            display: "none"
        }
    },
    "& .MuiSlider-valueLabel": {
        position: "relative",
        lineHeight: 1.2,
        fontSize: 12,
        background: "unset",
        padding: "3px",
        borderRadius: "6px",
        backgroundColor: "#7556FA",
        zIndex: 1000,
        "&:before": {
            content: "''",
            position: "absolute",
            background: "#7556FA",
            width: "15px",
            height: "15px",
            bottom: "0",
            left: "50%",
            borderRadius: "0 50% 50% 50%",
            transform: "translateX(-50%) rotate(225deg)",
            zIndex: -1
        }
    }
});