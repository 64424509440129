import { configureStore, createSlice, PayloadAction } from "@reduxjs/toolkit";

// Configuring slices
/**
 * CollapseSlice stores collapseHierarchy of currently opened collapsable groups.
 * See **SidebarItem** component to get more.
 */
export const collapseSlice = createSlice({
  name: "collapseSlice",
  initialState: {
    collapseHierarchy: [] as number[]
  },
  reducers: {
    collapseGroup: (state, action: PayloadAction<number[]>) => {
      state.collapseHierarchy = action.payload;
    }
  }
});

// Configuring store
export const sidebarStore = configureStore({
  reducer: {
    collapseSlice: collapseSlice.reducer
  }
});

// Any other exports for outside functionality
export type StoreState = ReturnType<typeof sidebarStore.getState>;
export type StoreDispatcher = typeof sidebarStore.dispatch;
export const {collapseGroup} = collapseSlice.actions;