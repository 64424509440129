import {createSelector} from "@reduxjs/toolkit";
import {internalApiSlice, transformResponse} from "../../api/internalApiSlice";
import {Tax} from "@/types/general";
import {RootState} from "@redux/hooks";

export enum TaxType {
    ANY = "any",
    SALES = "sale",
    PURCHASES = "purchase"
}

export const taxesApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // Getting minified taxes list for dropdowns
        getTaxes: builder.query<Tax.Slim[], { type: TaxType } | void>({
            query: (args) => {
                args = args ?? {type: TaxType.ANY};
                return {
                    url: `tax`,
                    params: {
                        slim: true,
                        ...(args.type != TaxType.ANY && {type: args.type?.toString()})
                    }
                }
            },
            transformResponse,
            providesTags: [{ type: "Tax", id: "LIST" }]
        }),

        getTaxesFull: builder.query<Tax.Root[], void>({
            query: () => ({
                url: `tax`
            }),
            transformResponse,
            providesTags: [{ type: "Tax", id: "LIST" }]
        }),

        getModuleTaxes: builder.query<Tax.Slim[], string>({
            query: (module) => ({
                url: `${module}/tax`
            }),
            transformResponse,
            providesTags: (result) => [{ type: "Tax", id: "LIST" }]
        }),

        createTax: builder.mutation<Tax.Root, Tax.DTO.CreateUpdate>({
            query: (body) => ({
                url: `tax`,
                method: "POST",
                data: body
            }),
            async onQueryStarted(body, {dispatch, queryFulfilled}) {
                try {
                    const {data: createdTax} = await queryFulfilled;
                    const createResult = dispatch(taxesApi.util.updateQueryData("getTaxesFull", undefined, (draft) => {
                        const createdTaxTyped = createdTax as unknown as {payload: Tax.Root};

                        draft.unshift({
                            id: createdTaxTyped.payload.id,
                            code: createdTaxTyped.payload.code,
                            rate: createdTaxTyped.payload.rate,
                            name: createdTaxTyped.payload.name,
                            is_sales_tax: createdTaxTyped.payload.is_sales_tax,
                            is_purchase_tax: createdTaxTyped.payload.is_purchase_tax,
                        });
                    }));
                } catch (err) {
                    console.error(err);
                }
            },
            invalidatesTags: (result) => [{type: "Tax", id: "LIST"}]
        }),

        updateTax: builder.mutation<Tax.Root, Tax.DTO.CreateUpdate & { id: number }>({
            query: ({id, ...body}) => ({
                url: `tax/${id}`,
                method: "PUT",
                data: body
            }),

            async onQueryStarted(body, {dispatch, queryFulfilled}) {
                try {
                    const {data: updatedTax} = await queryFulfilled;
                    const updateResult = dispatch(taxesApi.util.updateQueryData("getTaxesFull", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === updatedTax.id);
                        if (index !== -1) {
                            draft[index] = updatedTax;
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            },
            invalidatesTags: (result) => [{type: "Tax", id: "LIST"}]
        }),

        deleteTax: builder.mutation<void, number>({
            query: (id) => ({
                url: `tax/${id}`,
                method: "DELETE"
            }),

            async onQueryStarted(id, {dispatch, queryFulfilled}) {
                try {
                    const {data: deletedTax} = await queryFulfilled;
                    const deleteResult = dispatch(taxesApi.util.updateQueryData("getTaxesFull", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === id);
                        if (index !== -1) {
                            draft.splice(index, 1);
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            },
            invalidatesTags: (result) => [{type: "Tax", id: "LIST"}]
        })
    })
});

const selectedTaxesResult = taxesApi.endpoints.getTaxesFull.select()

const selectAllTaxes = createSelector(
    selectedTaxesResult,
    (taxes) => taxes?.data ?? []
)

export const selectTaxById = createSelector(
    selectAllTaxes,
    (state: RootState, tax_id: number | undefined) => tax_id,
    (taxes, tax_id) => tax_id != undefined ? taxes.find(tax => tax.id === tax_id) : undefined
)

export const {
    useGetTaxesQuery,
    useGetTaxesFullQuery,
    useGetModuleTaxesQuery,
    useCreateTaxMutation,
    useUpdateTaxMutation,
    useDeleteTaxMutation
} = taxesApi;