import { useTranslation } from "react-i18next";
import { useGetPOGOProjectsQuery } from "@redux/features/integrations/integrationsApi";
import { Accordion, AccordionDetails, AccordionSummary, Skeleton } from "@mui/material";
import { ChevronDown } from "lucide-react";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import React from "react";
import { PowerOffice } from "@/types/general";
import BaseIntegrationInject from "@reusables/BaseIntegrationInject";
import BaseBadge from "@reusables/BaseBadge";
import { genT, PredefinedTranslations } from "@helpers/utils";

interface PowerOfficeIntegrationSectionProps {
    value: PowerOffice.Projects.Root[];
    onChange: (projects: PowerOffice.Projects.Root[]) => void;
}

// TODO: rewrite this component to RHF
export default function PowerOfficeIntegrationSection({ value, onChange }: PowerOfficeIntegrationSectionProps) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.locations.integrations.powerOffice" });

    const { data: projects, isLoading: isProjectsLoading } = useGetPOGOProjectsQuery();

    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ChevronDown />}
                aria-controls="pogo-integration-content"
                id="pogo-integration-header"
            >
                <BaseIntegrationInject integration={"poweroffice"}>
                    {
                        (integration, isLoading) =>
                            <div className={"flex flex-row items-center space-x-2"}>
                                {
                                    isLoading
                                        ?
                                        <>
                                            <Skeleton variant="circular" width={40} height={40} />
                                            <Skeleton variant="text" width={150} height={30} />
                                        </>
                                        :
                                        <>
                                            <img src={integration?.imageUrl} width={40} height={40}
                                                 alt={integration?.name} />
                                            <div className={"text-accent"}>
                                                {integration?.name}
                                            </div>
                                        </>
                                }
                                <BaseBadge className={"bg-purple-400 text-white"}>
                                    {genT(PredefinedTranslations.IntegrationsBadge)}
                                </BaseBadge>
                            </div>
                    }
                </BaseIntegrationInject>
            </AccordionSummary>
            <AccordionDetails>
                <div className="max-w-[500px]">
                    <BaseDropdown
                        label={t("projects.label")}
                        placeholder={t("projects.placeholder")}

                        value={value}
                        options={projects ?? []}
                        getter={{
                            key: opt => opt.id,
                            label: opt => opt.name
                        }}
                        onChange={(_, sel) => onChange(sel)}
                        isLoading={isProjectsLoading}

                        virtualize
                        autocomplete
                        multiple
                    />
                </div>
            </AccordionDetails>
        </Accordion>
    );
}
