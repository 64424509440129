import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {useGetCountriesQuery} from '@redux/api/internalApiSlice';
import {useCreateCustomerBillingAddressMutation} from '@redux/features/customers/customersApi';
import {getCountryFlag} from '@helpers/utils';

import BaseModal from '@reusables/Modals/BaseModal';
import BaseInputsGrid from '@reusables/BaseInputsGrid';
import BaseInput from '@reusables/BaseInput';
import BaseDropdown from '@reusables/dropdowns/BaseDropdown';
import BaseButton from '@reusables/BaseButton';
import BasePhoneInput from '@reusables/BasePhoneInput';

import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from 'zod';
import {toast} from 'react-toastify';
import {isErrorWithMessage} from "@redux/api/query";

interface CreateBillingAddressModalProps {
    id: number;
    isOpen: boolean;
    onClose: () => void;
}

const formScheme = z.object({
    name: z.string().trim().nonempty(),
    country: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string()
    }),
    street: z.string().trim(),
    street_2: z.string().trim().optional(),
    zipcode: z.string().trim(),
    city: z.string().trim(),
    email: z.string().trim(),
    phone: z.string(),
});

type FormTyping = z.infer<typeof formScheme>

export default function CreateBillingAddressModal({id, isOpen, onClose}: CreateBillingAddressModalProps): JSX.Element {
    const {t} = useTranslation("", {keyPrefix: "sales.orders.general.fields.billingAddress.creationModal"});

    // ---> Countries selection <--- //
    const {data: countriesOptions, isLoading: countriesOptionsLoading} = useGetCountriesQuery();

    const [createBillingAddress, {isLoading: createBillingAddressLoading}] = useCreateCustomerBillingAddressMutation();

    const {control, handleSubmit, reset} = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
    });

    useEffect(() => {
        reset();
    }, [reset, isOpen]);

    const onSubmit = handleSubmit((data) => {
        createBillingAddress({
            id: id,
            name: data.name,
            country_id: data.country.id,
            street: data.street,
            street_2: data.street_2,
            zipcode: data.zipcode,
            city: data.city,
            email: data.email,
            phone: data.phone,
            is_used_for_shipping: false,
        }).unwrap().then(() => {
            toast.success(t('responses.success'));
            onClose();
        }).catch(e => {
            if(isErrorWithMessage(e)){
                toast.error(e.message);
            }else{
                toast.error(t('responses.error'));
            }
        });

    });

    return (
        <BaseModal
            keepMounted={false}
            isOpen={isOpen}
            onClose={onClose}
            width={900}
            padding="56px"
            useCloseIcon
            isLoading={createBillingAddressLoading}
        >
            <form className='space-y-[32px]' onSubmit={onSubmit}>
                <div className='modal-title'>{t('heading')}</div>
                <BaseInputsGrid gap={{ x:24, y: 40 }} cols={2}>
                    <Controller 
                        name={'name'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.name') + " *"}
                            />
                        )}
                    />

                    <Controller 
                        name={'country'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseDropdown
                                {...field}
                                {...fieldState}
                                label={t("dropdowns.country.label") + " *"}
                                placeholder={t("dropdowns.country.placeholder")}
                                options={countriesOptions}
                                getter={{
                                    label: (opt) => opt.name,
                                    key: (opt) => opt.id,
                                    renderOption: (opt, icon) => (
                                        <div>
                                            <img src={getCountryFlag(opt.code)} width={20} alt={opt.code} />
                                            <span className="ml-[8px] grow">{opt.name}</span>
                                            {icon}
                                        </div>
                                    )
                                }}

                                autocomplete
                                isLoading={countriesOptionsLoading}
                            />
                        )}
                    />

                    <Controller 
                        name={'street'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.street')}
                            />
                        )}
                    />

                    <Controller 
                        name={'street_2'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.street2')}
                            />
                        )}
                    />

                    <Controller 
                        name={'zipcode'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.zipcode')}
                            />
                        )}
                    />

                    <Controller 
                        name={'city'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.city')}
                            />
                        )}
                    />

                    <Controller 
                        name={'email'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t('fields.email')}
                            />
                        )}
                    />

                    <Controller 
                        name={'phone'}
                        control={control}
                        render={({field, fieldState}) => (
                            <BasePhoneInput
                                {...field}
                                {...fieldState}
                                label={t('fields.phone')}
                                value={field.value || ''}
                                onChange={field.onChange}
                                size='md'
                                variant='custom'
                            />
                        )}
                    />
                </BaseInputsGrid>

                <BaseButton
                    buttonWidth='100%'
                    text={`${t("buttons.create")}`}
                    size='md'
                    type='submit'
                />
            </form>
        </BaseModal>
    )
}
