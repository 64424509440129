import { Import } from "@/types/general";
import { internalApiSlice, transformResponse } from "../api/internalApiSlice";

export const importApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getFileInfo: builder.mutation<Import.Root, FormData>({
            query: (formData) => ({
                url: 'data-transfer/import/sheet-info',
                method: 'POST',
                data: formData
            }),
            // transformResponse
        }),

        startImport: builder.mutation<Import.Root, Import.DTO.Create>({
            query: ({module, formData}) => {
                return {
                    url: `${module}/import`,
                    method: "POST",
                    data: formData
                }
            },
            transformResponse
        }),
    }),
});

export const { useGetFileInfoMutation, useStartImportMutation } = importApi;