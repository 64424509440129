import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProductCard from "../../Card/ProductCard";

// product images
import Product1 from "../../../assets/images/EA520.png";
import Product2 from "../../../assets/images/EA630.jpg";
import Product3 from "../../../assets/images/HT730.png";
import Product4 from "../../../assets/images/TSP800II.jpg";
import Product5 from "../../../assets/images/MS340.jpg";
import Product6 from "../../../assets/images/HT730UHF.png";
import Slider from "../../../components/common/Slider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import BaseModal from "../../reusables/Modals/BaseModal";

export const Attendance = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, openModal] = useState<true | false>();

  type ProductDataType = {
    img: string,
    title: string,
    price: string,
    description: string
  }

  const [currentProduct, setCurrentProduct] = useState<ProductDataType | undefined>(undefined)

  const productCardArr = [
    {
      imgSrc: Product1,
      header: t("pricingPage.equipmentBlock.items.0.header"),
      description: t("pricingPage.equipmentBlock.items.0.description"),
      longDesc: t("pricingPage.equipmentBlock.items.0.longDesc"),
      features: [
        t("pricingPage.equipmentBlock.items.0.features.0"),
        t("pricingPage.equipmentBlock.items.0.features.1"),
        t("pricingPage.equipmentBlock.items.0.features.2"),
        t("pricingPage.equipmentBlock.items.0.features.3"),
        t("pricingPage.equipmentBlock.items.0.features.4"),
        t("pricingPage.equipmentBlock.items.0.features.5"),
        t("pricingPage.equipmentBlock.items.0.features.6"),
        t("pricingPage.equipmentBlock.items.0.features.7"),
        t("pricingPage.equipmentBlock.items.0.features.8"),
      ],
      price: t("pricingPage.equipmentBlock.items.0.price"),
    },
    {
      imgSrc: Product2,
      header: t("pricingPage.equipmentBlock.items.1.header"),
      description: t("pricingPage.equipmentBlock.items.1.description"),
      longDesc: t("pricingPage.equipmentBlock.items.1.longDesc"),
      features: [
        t("pricingPage.equipmentBlock.items.1.features.0"),
        t("pricingPage.equipmentBlock.items.1.features.1"),
        t("pricingPage.equipmentBlock.items.1.features.2"),
        t("pricingPage.equipmentBlock.items.1.features.3"),
        t("pricingPage.equipmentBlock.items.1.features.4"),
        t("pricingPage.equipmentBlock.items.1.features.5"),
        t("pricingPage.equipmentBlock.items.1.features.6"),
      ],
      price: t("pricingPage.equipmentBlock.items.1.price"),
    },
    {
      imgSrc: Product3,
      header: t("pricingPage.equipmentBlock.items.2.header"),
      description: t("pricingPage.equipmentBlock.items.2.description"),
      longDesc: t("pricingPage.equipmentBlock.items.2.longDesc"),
      features: [
        t("pricingPage.equipmentBlock.items.2.features.0"),
        t("pricingPage.equipmentBlock.items.2.features.1"),
        t("pricingPage.equipmentBlock.items.2.features.2"),
        t("pricingPage.equipmentBlock.items.2.features.3"),
        t("pricingPage.equipmentBlock.items.2.features.4"),
        t("pricingPage.equipmentBlock.items.2.features.5"),
        t("pricingPage.equipmentBlock.items.2.features.6"),
        t("pricingPage.equipmentBlock.items.2.features.7"),
        t("pricingPage.equipmentBlock.items.2.features.8"),
        t("pricingPage.equipmentBlock.items.2.features.9"),
        t("pricingPage.equipmentBlock.items.2.features.10"),
        t("pricingPage.equipmentBlock.items.2.features.11"),
      ],
      price: t("pricingPage.equipmentBlock.items.2.price"),
    },

    // has links
    {
      imgSrc: Product4,
      header: t("pricingPage.equipmentBlock.items.3.header"),
      description: t("pricingPage.equipmentBlock.items.3.description"),
      longDesc: t("pricingPage.equipmentBlock.items.3.longDesc"),
      features: [],
      price: t("pricingPage.equipmentBlock.items.3.price"),
    },

    {
      imgSrc: Product5,
      header: t("pricingPage.equipmentBlock.items.4.header"),
      description: t("pricingPage.equipmentBlock.items.4.description"),
      longDesc: t("pricingPage.equipmentBlock.items.4.longDesc"),
      features: [
        t("pricingPage.equipmentBlock.items.4.features.0"),
        t("pricingPage.equipmentBlock.items.4.features.1"),
        t("pricingPage.equipmentBlock.items.4.features.2"),
        t("pricingPage.equipmentBlock.items.4.features.3"),
        t("pricingPage.equipmentBlock.items.4.features.4"),
        t("pricingPage.equipmentBlock.items.4.features.5"),
        t("pricingPage.equipmentBlock.items.4.features.6"),
        t("pricingPage.equipmentBlock.items.4.features.7"),
      ],
      price: t("pricingPage.equipmentBlock.items.4.price"),
    },

    {
      imgSrc: Product6,
      header: t("pricingPage.equipmentBlock.items.5.header"),
      description: t("pricingPage.equipmentBlock.items.5.description"),
      longDesc: t("pricingPage.equipmentBlock.items.5.longDesc"),
      features: [
        t("pricingPage.equipmentBlock.items.5.features.0"),
        t("pricingPage.equipmentBlock.items.5.features.1"),
        t("pricingPage.equipmentBlock.items.5.features.2"),
        t("pricingPage.equipmentBlock.items.5.features.3"),
        t("pricingPage.equipmentBlock.items.5.features.4"),
        t("pricingPage.equipmentBlock.items.5.features.5"),
        t("pricingPage.equipmentBlock.items.5.features.6"),
        t("pricingPage.equipmentBlock.items.5.features.7"),
        t("pricingPage.equipmentBlock.items.5.features.8"),
        t("pricingPage.equipmentBlock.items.5.features.9"),
        t("pricingPage.equipmentBlock.items.5.features.10"),
      ],
      price: t("pricingPage.equipmentBlock.items.5.price"),
    },
  ];

  const _renderProductItem = (product: any) => {
    return (
      <div className="border border-[#E7E7E7] rounded-lg p-2 min-w-[350px] pb-4 hover:shadow-lg mb-[64px]"
        onClick={() => {
          setCurrentProduct({ title: product.header, price: product.price, img: product.imgSrc, description: product.description });
          openModal(true);
        }}>

        <div className="w-full mb-4 max-h-[192px] min-h-[168px]">
          <img className="w-auto max-h-48 mr-auto ml-auto block" src={product.imgSrc} />
        </div>
        <div className="py-4 px-8">
          <div className="text-xl text-black font-semibold mb-2">
            {product.header}
          </div>
          <p className="text-base text-gray-600 mb-3">{product.description}</p>
          <div className="text-[#8850F4] text-2xl font-semibold">
            {product.price}
          </div>
        </div>
      </div>
    );
  };

  return (
    // <div className="pricing-wrap">
    //   <div className="equipment-wrap">
    //     <div className="gradient-txt">
    //       {t("pricingPage.equipmentBlock.preTitle")}
    //     </div>
    //     <div className="header-pricing-normal">
    //       {t("pricingPage.equipmentBlock.title")}
    //     </div>
    //     <div className="description">
    //       {t("pricingPage.equipmentBlock.description")}
    //     </div>
    //   </div>
    //   <div className="products-block">
    //     <div
    //       className="pricing-equipment__slider slick-slider"
    //       data-slick='{"slidesToShow": 3, "slidesToScroll": 1, "arrows": true, "dots": false, "infinite": false, "responsive":[{"breakpoint":991,"settings":{"slidesToShow": 2}}, {"breakpoint":574,"settings":{"slidesToShow": 1, "fade": true, "adaptiveHeight": true}}]}'
    //     >
    //       {productCardArr.map((product, idx) => (
    //         <ProductCard key={idx} {...product} />
    //       ))}
    //     </div>
    //   </div>
    // </div>
    <>
      <BaseModal
        isOpen={isOpen}
        onClose={() => openModal(false)}
        width={600}
        padding="56px"
        useCloseIcon={true}
      >


        <div className="w-full mb-0">
          <img className="w-auto max-h-[372px] max-w-[300px] mr-auto ml-auto block" src={currentProduct?.img} />
        </div>
        <div className="py-4 px-8">
          <div className="text-[24px] text-black font-semibold mb-[16px]">
            {currentProduct?.title}
          </div>
          <p className="text-[16px] text-gray-600 mb-[32px]">{currentProduct?.description}</p>
          <div className="text-[#8850F4] text-[28px] font-semibold">
            {currentProduct?.price}
          </div>
        </div>

      </BaseModal>
      <div className="flex flex-col items-center space-y-8 font-display mb-16">
        <div className="text-lg text-[#6959FC] uppercase tracking-widest">
          {t("pricingPage.equipmentBlock.preTitle")}
        </div>
        <div className="text-[56px] text-[#2B2A35] font-[700] leading-[64.4px]">
          {t("pricingPage.equipmentBlock.title")}
        </div>
        <div className="max-w-2xl text-gray-600 text-center">
          {t("pricingPage.equipmentBlock.description")}
        </div>
      </div>

      <Slider
        wrapperClassName="swiper-equipment"
        options={{
          slidesPerView: 1,
          breakpoints: {
            1024: { slidesPerView: 3 },
          },
          spaceBetween: 16,
        }}
        nextEl={<ArrowForwardIosIcon />}
        prevEl={<ArrowBackIosIcon className="relative left-1" />}
        items={productCardArr.map((product) => ({
          render: () => _renderProductItem(product),
        }))}
      />
    </>
  );
};

export default Attendance;
