import React, { PropsWithChildren } from "react";
import BaseModal from "../BaseModal";

import "./index.scss";
import { Icon, IconButton } from "@mui/material";

interface LoadingModalProperties {
  title?: string;
  isLoading?: boolean;
  onClose?: () => void;
  width?: number | string;
}

export default function BaseLoadingModal({title, isLoading, children, width=700, onClose}: PropsWithChildren<LoadingModalProperties>){
    return (
      <BaseModal isOpen={isLoading} width={width} sx={{overflowY: 'hidden'}} padding="56px" onClose={onClose}>
        {onClose && <div className="absolute top-[40px] right-[56px]">
          <IconButton sx={{color: (theme) => theme.custom.modal.icon}} onClick={(e) => onClose()}>
            <Icon sx={{fontSize: "1.8rem"}}>close</Icon>
          </IconButton>
        </div>}
        {
          title && <div className="text-center font-semibold text-xl mb-[64px] text-modal-title">
            {title}
          </div>
        }
        <div className="flex items-center justify-center w-full">
          <span className="loader"></span>
        </div>
        {children}
      </BaseModal>
    )
}