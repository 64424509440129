import Checkbox from "@mui/material/Checkbox/Checkbox";
import React from "react";
import { ReactComponent as CheckSVG } from "@assets/icons/ic_check.svg";
import { Fade } from "@mui/material";
import { cn } from "@helpers/utils";


type BaseMaterialCheckboxProperties = {
    checked?: boolean;
    onChange?: (val: React.SyntheticEvent, checked: boolean) => void;
    borderColor?: string;
    checkedClassName?: string;
}

export default function BaseMaterialCheckbox(props: BaseMaterialCheckboxProperties) {
    return (
        <>
            <Checkbox
                sx={{
                    padding: 0,
                    "input": {
                        background: "transparent"
                    }
                }}

                icon={<div className={`border-[1.7px] border-solid h-[20px] w-[20px] rounded-[4px]`}
                           style={{ borderColor: props.borderColor ?? "#D9D6DA" }}></div>}
                checkedIcon={
                    <Fade in={true}>
                        <div
                            className={cn("flex justify-center items-center h-[20px] w-[20px] rounded-[4px] bg-[#A6DD4C]", props.checkedClassName)}>
                            <CheckSVG className="text-[#fff] w-[15px]" />
                        </div>
                    </Fade>
                }

                checked={props.checked}
                onChange={props.onChange}

                disableRipple
            />
        </>
    );
}