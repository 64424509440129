import { AppThunk } from "@redux/hooks";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import i18n from "@/i18n";
import { resetExport, startExport } from "@redux/features/importExport/export/exportSlice";

type ModulesWithExportSupport = "product" | "stock" | "purchase_order" | "sale_order" | "transfer" | "stock_count";

/**
 * Thunk to perform an export for a specific module. It incorporates the necessary logic to start the export process and everything else will be held by the sockets listeners. * You must create export logic ONLY here, otherwise talk to @nekear about this.
 * @param module
 * @param query
 */
export function performExport<T>(module: ModulesWithExportSupport, query: () => Promise<T>): AppThunk {
    return async (dispatch, getState) => {
        try {
            // Optional. Sockets listeners will catch the ExportStarted event and start the export process, however it will require some time and such manual start improves UX.
            // @see src/components/DashboardNav/components/ExportIndicators.tsx
            dispatch(startExport({ module }));

            return await query();
        } catch (e) {
            if (isErrorWithMessage(e)) {
                toast.error(e.message);
            } else {
                toast.error(i18n.t("general.responses.somethingWentWrong"));
            }
            console.log(`Error while performing an export for module [${module}]}:`, e);
            dispatch(resetExport());
            return Promise.reject(e);
        }
    };
}