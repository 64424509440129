import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@redux/hooks";
import { useEchoEffect } from "@/helpers/utils";
import { useGetBestSellingProductsQuery } from "@redux/features/dashboard/dashboardApi";
import { BaseLoadingBlocker } from "@/components/reusables/blockers/BaseLoadingBlocker";
import DashboardCard from "@components/Dashboard/DashboardPage/DashboardCard";
import BasePercentageBarChart from "@reusables/Charts/BasePercentageBarChart";
import BaseNotFound from "@reusables/BaseNotFound";
import _ from "lodash";

export default function BestSellingProductsWidget() {
    const { t } = useTranslation("", { keyPrefix: "dashboard.dashboard.stats.bestSellingProducts" });

    const user = useAppSelector(state => state.auth.user);

    const {
        data: bestSellingProducts = [],
        isLoading: isBestSellingProductsLoading,
        refetch
    } = useGetBestSellingProductsQuery();

    const refetchDebounced = useCallback(_.debounce(() => refetch(), 1000), []);

    console.log(bestSellingProducts);

    const bestSellingProductsPositive = bestSellingProducts.filter(x => x.value > 0);

    useEchoEffect(
        `dashboard.best_selling.${user?.company.id ?? -1}`,
        "DashboardUpdateEvent",
        () => {
            // This socket doesn't provide any data on update, so we just refetch the data
            refetchDebounced();
        },
        undefined,
        [user]
    );

    return (
        <BaseLoadingBlocker active={isBestSellingProductsLoading} useSolidBackground>
            <DashboardCard
                title={t("heading")}
                headerActions={
                    <div className="text-right">
                              <span
                                  className="px-[8px] py-[4px] bg-indigo-500 text-white rounded-[12px] font-thin">
                                {t("chip")}
                              </span>
                    </div>
                }
            >
                {bestSellingProductsPositive.length > 0 ?
                    <BasePercentageBarChart
                        data={bestSellingProductsPositive}
                        barSize={22}
                        showLegend
                        maxWidth={320}
                        height={120}
                    />
                    :
                    <BaseNotFound
                        text={t("notFound")}
                        height={200}
                    />
                }
            </DashboardCard>
        </BaseLoadingBlocker>

    );
}
