import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";

import DeletionModal from "../components/modals/DeletionModal";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import {useGetSupplierQuery} from "@redux/features/suppliers/suppliersApi";
import {ability, Can, useAbility} from "@/casl.config";
import {BaseTinyActionIcon, TinyActionIconType} from "@reusables/BaseTinyActionIcon";
import GeneralTabPreview from "../components/tabs/previews/GeneralTab";
import AddressTabPreview from "../components/tabs/previews/AddressTab";
import SettingsTab from "../components/tabs/previews/SettingsTab";
import SupplierStepper from "../components/Stepper/SupplierStepper";
import PurchaseOrderTab from "../components/tabs/previews/PurchaseOrderTab";
import ReceivesTab from "../components/tabs/previews/ReceivesTab";
import BaseTooltip from "@reusables/BaseTooltip";
import BasePermissionBlocker from "@reusables/BasePermissionBlocker";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function SupplierDetailsPage() {
    const {t} = useTranslation("", {keyPrefix: "suppliers.viewPage"});
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const abilication = useAbility();

    const { data: supplier, isLoading: supplierLoading } = useGetSupplierQuery(parseInt(id));
    const [activeTab, setActiveTab] = useState<number>(0);

    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);

    return (
        <>
            <Can not I="view" a="supplier">
                <div className="levitation-extended">
                    <NoPermissionBanner />
                </div>
            </Can>

            <Can I="view" a="supplier">
            <BaseRecordRenderer
                record={supplier}
                loading={supplierLoading}
            >
                <div className="flex items-center mb-[40px]">
                    <h6 className="text-accent grow">{supplier?.name}</h6>
                    <div className="space-x-[8px] flex items-center h-full">
                            <BasePermissionBlocker action="create" module="purchase_order" placement="bottom-start">
                                <BaseTooltip active={ability.can("create", "purchase_order")} title={`${t("sideButtons.createPurchase")}`} placement="bottom-start">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.PurchaseOrder}
                                        onClick={() => ability.can("create", "purchase_order") && void history.push(`/dashboard/purchasing/new`, {supplier: supplier})}
                                    />
                                </BaseTooltip>
                            </BasePermissionBlocker>
                            {/* <BaseTooltip title={`${t("sideButtons.startReceiving")}`} placement="bottom-start">
                                <BaseTinyActionIcon
                                    type={TinyActionIconType.StartReceiving}
                                    onClick={() => void history.push(`/dashboard/receive/new`)}
                                />
                            </BaseTooltip> */}
                            <BasePermissionBlocker action="edit" module="supplier" placement="bottom-start">
                                <BaseTooltip active={ability.can("edit", "supplier")} title={`${t("sideButtons.edit")}`} placement="bottom-start">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Edit}
                                        onClick={() => history.push(`/dashboard/suppliers/${id}/edit`)}
                                    />
                                </BaseTooltip>
                            </BasePermissionBlocker>

                            <BasePermissionBlocker action="delete" module="supplier" placement="bottom-start">
                                <BaseTooltip active={ability.can("delete", "supplier")} title={`${t("sideButtons.delete")}`} placement="bottom-start">
                                    <BaseTinyActionIcon
                                        type={TinyActionIconType.Delete}
                                        onClick={() => setIsDeletionModalOpen(true)}
                                    />
                                </BaseTooltip>
                            </BasePermissionBlocker>
                    </div>
                </div>
                <SupplierStepper
                    tabs={[
                        {
                            title: t => t("general.heading"),
                            content: <GeneralTabPreview supplier={supplier as NonNullable<typeof supplier>} />
                        },
                        {
                            title: t => t("address.heading"),
                            content: <AddressTabPreview supplier={supplier as NonNullable<typeof supplier>} />
                        },
                        {
                            title: t => "Purchase Orders",
                            content: <PurchaseOrderTab supplier={supplier as NonNullable<typeof supplier>} />
                        },
                        {
                            title: t => "Receives",
                            content: <ReceivesTab supplier={supplier as NonNullable<typeof supplier>} />
                        },
                        {
                            title: t => t("settings.heading"),
                            content: <SettingsTab supplier={supplier as NonNullable<typeof supplier>} />
                        }
                    ]}

                    manual={{
                        activeTab: activeTab,
                        onClick: setActiveTab
                    }}
                />
            </BaseRecordRenderer>
            </Can>

            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                onDeletion={() => history.push("/dashboard/suppliers")}
                entity={supplier}
            />
      </>
    );
}