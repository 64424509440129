import React, { ReactNode } from "react";
import { FormControlLabel } from "@mui/material";

/**
 * BaseToggleInput is a combination of the FormControlLabel from the MUI and the incoming Switch and BaseInputLegacy / BaseDropdownLegacy
 * to allow you to control the styles of these components from a single location.
 * The idea came from the need to maintain a single style of the external components of the MUI library with the custom styles of the project.
 */

interface BaseToggleInputArguments{
  control: JSX.Element; // Might be a Switch, Checkbox or Radio (primarily from MUI library)
  label: string, // Title of the input, associated with control
  inputEl: JSX.Element | ReactNode // Input field underneath the toggle (BaseInputLegacy often used here or BaseDropdownLegacy)
}

export default function BaseToggleInput({
  control,
  label,
  inputEl
                                        }: BaseToggleInputArguments): JSX.Element{
  
  return (
    <div>
      <FormControlLabel
        control={control}
        label={
          <span className="font-semibold text-gray-600">{label}</span>
        }
        labelPlacement="start"
        className="d-flex justify-content-between mb-2"
        style={{ marginLeft: 0, marginRight: 0}}
      />
      {inputEl}
    </div>
  )
}