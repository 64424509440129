import React from "react";
import {AddressesFormTyping} from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Adresses";
import {Control, Controller, useFormContext, useWatch} from "react-hook-form";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import BaseCheckbox from "@reusables/BaseCheckbox";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import {ArrayElementType, getCountryFlag} from "@helpers/utils";
import BasePhoneInput from "@reusables/BasePhoneInput";

import {ReactComponent as PlusSVG} from "@assets/icons/ic_plus.svg";
import {ReactComponent as TrashSVG} from "@assets/icons/ic_trash.svg";
import {useTranslation} from "react-i18next";
import {useGetCountriesQuery} from "@redux/api/internalApiSlice";
import { Can, useAbility } from "@/casl.config";

interface DeliveryAddressProps {
    control: Control<AddressesFormTyping>;
    index: number;

    onAppend: () => void;
    onRemove: () => void;
    onPrimaryChange: (index: number) => void;
}

export default function DeliveryAddress({control, index, onAppend, onRemove, onPrimaryChange}: DeliveryAddressProps) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.address" });

    const ability = useAbility();

    const {data: countryOptions = [], isLoading: isCountryOptionsLoading} = useGetCountriesQuery();

    const deliveryCountry = useWatch({
        control,
        name: `deliveries.${index}.country`
    });

    return (
        <div className="space-y-[24px] mt-5">
            {
                index === 0 ?
                    <div className="flex items-center justify-between">
                        <div className="text-xl text-accent font-semibold">{t("deliveryAddress.heading")}</div>
                        <Can I="address.edit" a="general">
                            <BaseMaterialIconButton
                                icon={<PlusSVG/>}
                                onClick={onAppend}
                            />
                        </Can>
                    </div>
                    :
                    <div className="flex items-center justify-between">
                        <div className="text-xl text-accent">{t("deliveryAddress.heading")}</div>
                        <Can I="address.edit" a="general">
                            <div
                                className="text-gray-300 space-x-[10px] font-thin cursor-pointer hover:text-actionableIcons"
                                onClick={onRemove}>
                                <TrashSVG/>
                                <span className="align-middle">{t("buttons.delete")}</span>
                            </div>
                        </Can>
                    </div>
            }
            <div>
                <Controller
                    control={control}
                    name={`deliveries.${index}.is_primary`}
                    render={({field}) => (
                        <BaseCheckbox
                            {...field}
                            label={`${t("deliveryAddress.checkboxes.useAsPrimary")}`}
                            onChange={(e) => {
                                field.onChange(!field.value); // Update the individual checkbox
                                if (!field.value) {
                                    onPrimaryChange(index);
                                }
                            }}
                            disabled={ability.cannot("address.edit", "general")}
                        />
                    )}
                />
            </div>
            <BaseInputsGrid>
                <Controller
                    name={`deliveries.${index}.name`}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("deliveryAddress.fields.name") + " *"}
                            disabled={ability.cannot("address.edit", "general")}
                        />
                    )}
                />

                <Controller
                    name={`deliveries.${index}.street`}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("deliveryAddress.fields.street")}
                            disabled={ability.cannot("address.edit", "general")}
                        />
                    )}
                />

                <Controller
                    name={`deliveries.${index}.street_2`}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("deliveryAddress.fields.street2")}
                            disabled={ability.cannot("address.edit", "general")}
                        />
                    )}
                />

                <Controller
                    name={`deliveries.${index}.zipcode`}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("deliveryAddress.fields.zipCode")}
                            disabled={ability.cannot("address.edit", "general")}
                        />
                    )}
                />

                <Controller
                    name={`deliveries.${index}.city`}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("deliveryAddress.fields.city")}
                            disabled={ability.cannot("address.edit", "general")}
                        />
                    )}
                />

                <Controller
                    name={`deliveries.${index}.country`}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}
                            label={t("deliveryAddress.fields.country")}
                            options={countryOptions}
                            placeholder={t("deliveryAddress.fields.country")}

                            getter={{
                                label: (opt) => opt.name,
                                key: (opt) => opt.id,
                                renderOption: (opt, icon) => (
                                    <div>
                                        <img src={getCountryFlag(opt.code)} width={20} alt={opt.code}/>
                                        <span className="ml-[8px] grow">{opt.name}</span>
                                        {icon}
                                    </div>
                                )
                            }}

                            autocomplete
                            isLoading={isCountryOptionsLoading}
                            disabled={ability.cannot("address.edit", "general")}
                        />
                    )}
                />

                <Controller
                    name={`deliveries.${index}.email`}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("deliveryAddress.fields.email")}
                            disabled={ability.cannot("address.edit", "general")}
                        />
                    )}
                />

                <Controller
                    name={`deliveries.${index}.phone`}
                    control={control}
                    render={({field, fieldState}) => (
                        <BasePhoneInput
                            {...field}
                            error={fieldState.error}
                            label={t("deliveryAddress.fields.phone")}
                            variant="custom"
                            size="md"
                            country={!field.value ? deliveryCountry?.code : ""}
                            disabled={ability.cannot("address.edit", "general")}
                        />
                    )}
                />

                <Controller
                    name={`deliveries.${index}.contact_person`}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("deliveryAddress.fields.contactName")}
                            disabled={ability.cannot("address.edit", "general")}
                        />
                    )}
                />
            </BaseInputsGrid>
        </div>
    )
}