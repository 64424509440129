import React, {cloneElement, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseButton from "@reusables/BaseButton";
import {manualRequest} from "@helpers/utils";
import {toast} from "react-toastify";
import ViewLayout from "../components/ViewLayout";
import {useGetAdjustmentQuery} from "@redux/features/adjustments/adjustmentsApi";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";

export default function AdjustmentsViewPage() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("", { keyPrefix: "inventory.adjustments" });

  const icons: { icon: JSX.Element, onClick: () => void }[] = [
    // {
    //   icon: <EditSVG style={{ transform: "scale(.8)" }} />,
    //   onClick: () => history.push(`/dashboard/inventory/adjustments/${id}/edit`)
    // },
    // {
    //   icon: <TrashSVG />,
    //   onClick: () => setIsDeletionModalOpen(true)
    // }
  ];

  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);
  const [isAdjustmentDeletionLoading, setIsAdjustmentDeletionLoading] = useState<boolean>(false);

  const { data: adjustment, isLoading: isAdjustmentLoading } = useGetAdjustmentQuery(parseInt(id), {
    skip: !parseInt(id)
  });

  return (
    <>
      <BaseRecordRenderer
        loading={isAdjustmentLoading}
        record={adjustment}
      >
        <div className="flex mb-[40px]">
          <h6 className="text-accent grow">{t("viewAdjustment.heading")} #{adjustment?.code}</h6>
          <div className="space-x-[8px] flex items-center h-full">
            {icons.map((item, index) =>
              <div
                className="flex justify-center items-center" key={index}
                style={{
                  border: "1px solid transparent",
                  background: "linear-gradient(#fff, #fff) padding-box, linear-gradient(90deg, rgba(43, 42, 53, 1), rgba(105, 89, 252, 1)) border-box",
                  borderRadius: "6px",
                  height: 32,
                  width: 32,
                  cursor: "pointer"
                }}
                onClick={item.onClick}
              >
                {cloneElement(item.icon, { width: 16, height: 16, className: "text-actionableIcons" })}
              </div>
            )}
          </div>
        </div>
        <ViewLayout adjustment={adjustment as NonNullable<typeof adjustment>}/>
      </BaseRecordRenderer>

      {/* DELETION MODAL */}
      <BaseModal
        isOpen={isDeletionModalOpen}
        onClose={() => setIsDeletionModalOpen(false)}
        width={700}
        padding="56px"
        isLoading={isAdjustmentDeletionLoading}
      >
        <div className="space-y-[32px]">
          <div className="text-xl font-semibold text-center text-accent">{t("modals.confirmDelete.label")}</div>
          <div className="text-center">{t("modals.confirmDelete.hint")} <span
            className="bold-highlight">{t("modals.confirmDelete.deletionItem")} THH{id}?</span></div>
          <div className="grid grid-cols-2 gap-[16px]">
            <BaseButton text={t("modals.confirmDelete.buttons.cancel")} size="md" primaryOutlined onClick={() => setIsDeletionModalOpen(false)} />
            <BaseButton text={t("modals.confirmDelete.buttons.cancel")} size="md" onClick={() => {
              manualRequest({
                route: `adjustments/${id}`,
                method: "DELETE",
                onLoadingChange: (state) => {
                  setIsAdjustmentDeletionLoading(state);
                },
                then: (resp) => {
                  toast.success(t("modals.confirmDelete.responses.success"));
                  history.push("/dashboard/inventory/adjustments");
                },
                catch: err => {
                  toast.error(t("modals.confirmDelete.responses.error"));
                }
              });
            }} />
          </div>
        </div>
      </BaseModal>
    </>
  );
}