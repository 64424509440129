import React from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {calculateOrderPrice, normalizePrice} from "@helpers/utils";
import {SaleOrdersFormTyping} from "../../types";

export default function TotalCell({index}: { index: number }) {
    const {control} = useFormContext<SaleOrdersFormTyping>();

    const line = useWatch({
        control,
        name: `lines.${index}`
    });

    return <>
        {
            normalizePrice(calculateOrderPrice(line.data?.quantity ?? 0, line.data?.unit_price ?? 0, line.data?.discount ?? 0))
        }
    </>
}