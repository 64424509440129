import React from 'react'
import {CustomerFormTyping} from '../TabsController';
import {Control, Controller, useFormContext, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useGetCountriesQuery} from '@redux/api/internalApiSlice';
import {ReactComponent as PlusSVG} from "@assets/icons/ic_plus.svg";
import {ReactComponent as TrashSVG} from "@assets/icons/ic_trash.svg";
import BaseMaterialIconButton from '@reusables/BaseMaterialIconButton';
import BaseCheckbox from '@reusables/BaseCheckbox';
import BaseInputsGrid from '@reusables/BaseInputsGrid';
import BaseInput from '@reusables/BaseInput';
import BaseDropdown from '@reusables/dropdowns/BaseDropdown';
import {getCountryFlag} from '@helpers/utils';
import BasePhoneInput from '@reusables/BasePhoneInput';
import { useAbility } from '@/casl.config';
import { TabProperties } from '../../../types';

type DeliveryAddressProps = {
    control: Control<CustomerFormTyping>;
    mode: TabProperties['mode'];
    index: number;
    onAppend: () => void;
    onRemove: () => void;
    // onPrimaryChange: () => void;
}

export default function DeliveryAddress({control, mode = "creation", index, onAppend, onRemove}: DeliveryAddressProps) {
    const {t} = useTranslation("", {keyPrefix: "customers.general.tabs.address"});

    const ability = useAbility();

    const {getValues} = useFormContext<CustomerFormTyping>();

    const {data: countryOptions = [], isLoading: countryOptionsLoading} = useGetCountriesQuery();

    const country = useWatch({
        control,
        name: `deliveries.${index}.country`
    });

    return (
        <div className="space-y-[24px]">
            {
                index === 0 ?
                    <div className="flex items-center justify-between">
                        <div className="text-xl text-accent">{t("categories.delivery")}</div>
                        <div className='flex  items-center gap-2'>
                            {/*TODO: commented due to RHF bug on deletion of the last element */}
                            {/*TODO: opened issue https://github.com/react-hook-form/react-hook-form/issues/11415*/}
                            {/*<div*/}
                            {/*    className="text-gray-300 space-x-[10px] font-thin cursor-pointer hover:text-actionableIcons"*/}
                            {/*    onClick={onRemove}*/}
                            {/*>*/}
                            {/*    <TrashSVG/>*/}
                            {/*    <span className="align-middle">{t("buttons.delete")}</span>*/}
                            {/*</div>*/}
                            <BaseMaterialIconButton
                                icon={<PlusSVG/>}
                                onClick={onAppend}
                                disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                            />
                        </div>
                    </div>
                :
                <div className="flex items-center justify-between">
                        <div className="text-xl text-accent">{t("categories.delivery")}</div>
                        {/* {address.id} */}
                        <div
                            className={`text-gray-300 space-x-[10px] font-thin ${ability.can("delivery_address.edit", "customer") ? "cursor-pointer hover:text-actionableIcons" : "opacity-50"}`}
                            onClick={ability.can("delivery_address.edit", "customer") ? onRemove : undefined}
                        >
                            <TrashSVG/>
                            <span className="align-middle">{t("buttons.delete")}</span>
                        </div>
                    </div>
            }
            <div>
                <Controller
                    control={control}
                    name={`deliveries.${index}.is_primary`}
                    render={({field}) => (
                        <BaseCheckbox
                            {...field}
                            label={t("checkboxes.useAsPrimary")}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />
            </div>
            <BaseInputsGrid>
                <Controller
                    control={control}
                    name={`deliveries.${index}.name`}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.name.label") + " *"}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={`deliveries.${index}.street`}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.street.label")}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`deliveries.${index}.street_2`}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.street2.label")}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`deliveries.${index}.zipcode`}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.zipcode.label")}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`deliveries.${index}.city`}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.city.label")}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`deliveries.${index}.country`}
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}

                            options={countryOptions}
                            getter={{
                                key: opt => opt.id,
                                label: opt => opt.name,
                                renderOption: (opt, icon) => (
                                    <div>
                                        <img src={getCountryFlag(opt.code)} width={20} alt={opt.name + " flag"}/>
                                        <span className="ml-[8px] grow">{opt.name}</span>
                                        {icon}
                                    </div>
                                )
                            }}
                            label={t("fields.country.label")}
                            isLoading={countryOptionsLoading}

                            autocomplete
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`deliveries.${index}.contact_person`}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.contact_person.label")}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`deliveries.${index}.phone`}
                    render={({field, fieldState}) => (
                        <BasePhoneInput
                            {...field}
                            {...fieldState}
                            label={t('fields.phone.label')}
                            size='md'
                            variant='custom'
                            country={!getValues(`deliveries.${index}.phone`) ? country?.code : ""}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`deliveries.${index}.email`}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.email.label")}
                            type={"email"}
                            disabled={mode == "editing" && ability.cannot("billing_address.edit", "customer")}
                        />
                    )}
                />
            </BaseInputsGrid>
        </div>
    )
}
