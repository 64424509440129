import React from 'react'
import { BatchNumbersScheme } from '.'
import { Control, Controller, useController, useFieldArray } from 'react-hook-form'
import { Stack } from '@mui/material';
import BaseMaterialButton from '@/components/reusables/BaseMaterialButton';
import BaseInputsGrid from '@/components/reusables/BaseInputsGrid';
import BaseInput from '@/components/reusables/BaseInput';
import { ReactComponent as DeleteSVG } from "@assets/icons/ic_trash.svg";
import BaseDatepicker from '@/components/reusables/BaseDatepicker';
import { useTranslation } from 'react-i18next';


export default function BatchGroup({control, preview}: { control: Control<BatchNumbersScheme>, preview?: boolean}) {
    const { t } = useTranslation("", { keyPrefix: "bom.produceModal.modals.batchNumbersModal" });

    const {field: quantity} = useController({
        control,
        name: `product.quantity`
    });

    const {fields: batchNumbers, append, remove} = useFieldArray({
        control,
        name: `product.batch_numbers`
    });

    return (
        <div>
            <div className="flex items-center mb-[32px]">
                <div className="flex justify-start items-center grow">
                    <div className="text-lg font-semibold text-accent">{t("heading")}</div>
                </div>
                
                    {
                        !preview && (
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}
                            >
                                {/* Serial inputs number limit (limited with the picked quantity for the product) */}
                                <div className={`opacity-50 ${batchNumbers.length > quantity.value ? "text-[#E25C5C]" : ""}`}>
                                    ({batchNumbers.length} / {quantity.value})
                                </div>
                                <BaseMaterialButton type="button" onClick={() => {
                                    append({
                                        batch_number: ""
                                    })
                                }}>
                                    {t("buttons.addBatchNumber")}
                                </BaseMaterialButton>
                            </Stack>
                        )
                    }
                </div>

            <div className="space-y-2">
                {
                    batchNumbers.map((batchNumber, index) => (
                        <Stack
                            key={batchNumber.id}
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                        >
                            <div className="w-full">
                                <BaseInputsGrid cols={2} gap={8}>
                                    <Controller
                                        control={control}
                                        name={`product.batch_numbers.${index}.batch_number`}
                                        render={({field}) => (
                                            <BaseInput
                                                {...field}
                                                placeholder={t("fields.batchNumber.placeholder")}
                                                className="w-full"
                                                sx={{
                                                    "& input": {
                                                        background: "#F9F9F9",
                                                        padding: "12px",
                                                        border: 0
                                                    }
                                                }}

                                                disabled={preview}
                                            />
                                        )}
                                    />

                                    {/* Expiration date*/}
                                    <Controller
                                        control={control}
                                        name={`product.batch_numbers.${index}.expiration_date`}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <BaseDatepicker
                                                    {...field}
                                                    error={fieldState.error}

                                                    placeholder={t("fields.expirationDate.placeholder")}

                                                    inputSx={{
                                                        "&": {
                                                            border: 0,
                                                            padding: 0,
                                                            "input": {
                                                                background: "#F9F9F9",
                                                                padding: "12px",
                                                                border: 0
                                                            }
                                                        }
                                                    }}

                                                    disabled={preview}
                                                />
                                            </>
                                        )}
                                    />
                                </BaseInputsGrid>
                            </div>

                            {/* Deletion button */}
                            {
                                !preview &&
                                <span>
                                    <DeleteSVG
                                        className="text-tables-secondaryIcon hover:text-tables-highlightedIcon cursor-pointer"
                                        onClick={() => {
                                            remove(index)
                                        }}
                                    />
                                </span>
                            }
                        </Stack>
                    ))
                }
            </div>
        </div>
    )
}
