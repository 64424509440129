import { flattenPaginationRequest, internalApiSlice, PaginationRequest, PaginationResponse, transformResponse } from "../../api/internalApiSlice";
import { Adjustments } from "@/types/general";

export const adjustmentsApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAdjustmentsFull: builder.query<PaginationResponse<Adjustments.Root>, PaginationRequest<Adjustments.DTO.Filters, Adjustments.DTO.OrderBy>>({
            query: (req) => ({
                url: "adjustments",
                method: "GET",
                params: {
                    ...flattenPaginationRequest(req)
                },
                parseDates: ["date"],
            }),
            providesTags: (result, error, page) =>
                result
                    ? [
                        ...result.payload.map(({ id }) => ({ type: 'Adjustment' as const, id })),
                        { type: 'Adjustment', id: 'PARTIAL-LIST' },
                    ]
                    : [{ type: 'Adjustment', id: 'PARTIAL-LIST' }],
        }),

        getAdjustment: builder.query<Adjustments.Extended, number>({
            query: (id) => ({
                url: `adjustments/${id}`,
                parseDates: ["date"],
            }),
            transformResponse,
            providesTags: (resp, error, id) => [{ type: "Adjustment", id }]
        }),

        createAdjustment: builder.mutation<Adjustments.Extended, Adjustments.DTO.Create>({
            query: (body) => ({
                url: `adjustments`,
                method: "POST",
                data: body,
            }),
            invalidatesTags: [{ type: "Adjustment", id: "LIST" }, {type: "Adjustment", id: "PARTIAL-LIST"}, "Stock", "Picking", "Product"],
        }),

        deleteAdjustment: builder.mutation<void, number>({
            query: (id) => ({
                url: `adjustments/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, id) => [{ type: "Adjustment", id }],
        }),
    }),
});

export const {
    useGetAdjustmentQuery,
    useCreateAdjustmentMutation,
    useDeleteAdjustmentMutation,
    useGetAdjustmentsFullQuery,
} = adjustmentsApi;