import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { TableRow, TableCell } from "@mui/material";
import BaseAdminTable from "@reusables/BaseAdminTable";
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import BasePagination from "@reusables/BasePagination";
import ModalConfirm from "../../../../components/common/ModalConfirm";
import { fetchLocations } from "../../../../hooks/location";
import { deleteLocationById } from "./services";
import { toast } from "react-toastify";


function LocationsPage() {
  const history = useHistory();
  const {
    locationsLoading,
    locationsData,
    locationsMeta,
    currentLocationsPage,
    setCurrentLocationsPage,
  } = fetchLocations();

  const [modalDelete, setModalDelete] = useState<any>({
    show: false,
    data: {},
  });

  const filters = [
    {
      key: "status",
      label: "Status",
      type: "select",
      options: [
        { value: "Group 1", label: "Group 1" },
        { value: "Group 2", label: "Group 2" },
      ],
    },
    {
      key: "product",
      label: "Product",
      type: "select",
      options: [
        { value: "Group 1", label: "Group 1" },
        { value: "Group 2", label: "Group 2" },
      ],
    },
    {
      key: "archived",
      label: "Archived",
      type: "select",
      options: [
        { value: "Group 1", label: "Group 1" },
        { value: "Group 2", label: "Group 2" },
      ],
    },
    {
      key: "received",
      label: "Received",
      type: "select",
      options: [
        { value: "Group 1", label: "Group 1" },
        { value: "Group 2", label: "Group 2" },
      ],
    },
    {
      key: "supplier",
      label: "Supplier",
      type: "select",
      options: [
        { value: "Group 1", label: "Group 1" },
        { value: "Group 2", label: "Group 2" },
      ],
    },
    {
      key: "other",
      label: "Other",
      type: "select",
      options: [
        { value: "Group 1", label: "Group 1" },
        { value: "Group 2", label: "Group 2" },
      ],
    },
  ];
  const handleSearch = () => console.log("search");
  const handleFilter = () => console.log("handleFilter");
  const handleCreate = () => history.push("/dashboard/inventory/locations/new");
  const handleDelete = async () => {
    const locationId = modalDelete.data?.id;
    const { res, error } = await deleteLocationById(locationId);
    if (!error) {
      toast.success(`Delete location successs: #${locationId}`);
      setModalDelete({ show: false, data: {} });
    }
  };

  const handleCloseModalDelete = () =>
    setModalDelete({ show: false, data: {} });

  const handlePageChange = (event: any, value: any) => {
    setCurrentLocationsPage(value);
  };

  const rediretToEditPage = (id: number) =>
    history.push(`/dashboard/settings/general/locations/${id}/edit`);

  const tableColumns = [
    { key: "name", label: "Name", width: 216 },
    { key: "country", label: "Country" },
    { key: "city", label: "City" },
    { key: "street", label: "Street" },
    { key: "postal", label: "Postal" },
  ];

  const actions = [
    {
      type: "edit",
      handler: ({ id }: { id: number }) => rediretToEditPage(id),
    },
    {
      type: "delete",
      handler: (data: Record<string, any>) => {
        setModalDelete({ data, show: true });
      },
    },
  ];

  const _renderSubLocation = (row: any) => {
    const defaultSx = {
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
    };
    if (!row?.subLocation?.length) return;
    return row?.subLocation?.map((item: any, i: number) => (
      <TableRow key={i} style={{ background: "#E3F5D5" }}>
        <TableCell sx={defaultSx} width={216}>
          <div className="flex items-center">
            <div className="rounded-full w-2 h-2 bg-purple-600 mr-4"></div>
            Section Number: {item?.row}
          </div>
        </TableCell>

        <TableCell sx={defaultSx} />
        <TableCell sx={defaultSx}>Sector: {item?.sector}</TableCell>
        <TableCell sx={defaultSx}>Shelf Height: {item?.height}</TableCell>
        <TableCell sx={defaultSx} />
        <TableCell sx={defaultSx} />
      </TableRow>
    ));
  };

  return (
    <div className="space-y-8">
      {/* Filter, export, import menu */}
      <BaseAdminFilter
        filterContainerClassName="grid grid-cols-2 gap-8 min-w-[576px]"
        filterItems={filters}
        handleSearch={handleSearch}
        handleFilter={handleFilter}
        handleCreate={handleCreate}
      />

      <div className="space-y-8">
        <div className="admin-card">
          <div className="space-y-4">
            <div className="font-semibold text-base text-dark-blue leading-[1.5]">
              {locationsMeta?.total} Items
            </div>
            <BaseAdminTable
              columns={tableColumns}
              data={locationsData}
              actions={actions}
              loading={locationsLoading}
              // expandable={_renderSubLocation}
              inverted
            />
          </div>
        </div>
        <BasePagination
          count={locationsMeta?.total}
          page={currentLocationsPage}
          onChange={handlePageChange}
          shape="rounded"
          disabled={locationsLoading}
        />
      </div>

      {modalDelete.show && (
        <ModalConfirm
          visible={modalDelete.show}
          onOk={handleDelete}
          onCancel={handleCloseModalDelete}
        >
          <div className="flex flex-col items-center">
            <h4 className="text-[#3C3769] text-xl">Confirm Deletion</h4>
            <p>
              Are you sure you want to deletel the
              <span className="text-[#7556FA]">
                {" "}
                location {modalDelete.data?.name}
              </span>
            </p>
          </div>
        </ModalConfirm>
      )}
    </div>
  );
}

export default LocationsPage;
