import { z } from "zod";
import i18n from "@/i18n";
import dayjs, { Dayjs } from "dayjs";

export const produceFormScheme = z.object({
    product_id: z.number(),
    quantity: z.coerce.number().min(1),
    produce_location: z.object({
        store: z.object({
            id: z.number(),
            name: z.string()
        }),
        section: z.object({
            id: z.number(),
            name: z.string()
        }).nullish()
    }),
    is_serial_number: z.boolean(),
    serial_numbers: z.array(
        z.object({
            serial_number: z.string()
        })
    ).nullish(),
    is_batch_number: z.boolean(),
    batch_numbers: z.array(
        z.object({
            batch_number: z.string(),
            expiration_date: z
                .instanceof(dayjs as unknown as typeof Dayjs)
                .refine((value) => value.isValid(), {
                    message: "Invalid date"
                })
                .optional()
        })
    ).nullish(),
    components_picks: z.array(
        z.object({
            component: z.object({
                product: z.object({
                    id: z.number(),
                    name: z.string(),
                    code: z.string(),
                    in_stock: z.number().nullish(),
                    quantity: z.number()
                }),
                locations: z.array(
                    z.object({
                        store: z.object({
                            id: z.number(),
                            name: z.string()
                        }),
                        section: z.object({
                            id: z.number(),
                            name: z.string()
                        }).nullish(),
                        in_stock: z.number().nullish().optional()
                    })
                )
            }),
            sources: z.array(
                z.object({
                    product_id: z.number(),
                    locations: z.object({
                        store: z.object({
                            id: z.number(),
                            name: z.string()
                        }),
                        section: z.object({
                            id: z.number(),
                            name: z.string()
                        }).nullish().optional(),
                        in_stock: z.number().nullish().optional()
                    }).nullish(),
                    quantity: z.coerce.number().min(1)
                })
            )
        })
    )
}).superRefine((data, ctx) => {
    data.components_picks.forEach((pick, index) => {
        // Assuming each pick can have multiple sources, which each need to be checked
        pick.sources.forEach((source, sourceIndex) => {
            // Find matching location in components.locations based on store and section IDs
            const matchingLocation = pick.component.locations.find(location =>
                location.store.id === source.locations?.store.id &&
                (location.section?.id === source.locations?.section?.id || location.section === undefined)
            );

            if (matchingLocation && source.quantity && (matchingLocation?.in_stock ?? 0) < source.quantity) {
                // A custom issue when the in_stock is less than the source quantity
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: `${i18n.t("bom.produceModal.validationErrors.maxAvailableInStock")} ${matchingLocation.in_stock}`,
                    path: ["components_picks", index, "sources", sourceIndex, "quantity"]
                });
            }
        });
    });
}).superRefine((data, ctx) => {
    let maxProducible = Infinity;

    data.components_picks.forEach((component_pick) => {
        // Assuming each component_pick.components has an accurate in_stock and quantity needed.
        const totalInStock = component_pick.component.product.in_stock;
        const quantityNeeded = component_pick.component.product.quantity;

        if (!totalInStock) return;
        // Calculate the maximum producible units based on the stock of the component.
        if (totalInStock !== undefined && quantityNeeded > 0) {
            const producibleFromThisComponent = Math.floor(totalInStock / quantityNeeded);
            maxProducible = Math.min(maxProducible, producibleFromThisComponent);
        }
    });

    // Validate the main quantity field against maxProducible.
    if (data.quantity > maxProducible) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `${i18n.t("bom.produceModal.validationErrors.maxProducible")} ${maxProducible}.`,
            path: ["quantity"]
        });
    }
}).superRefine((data, ctx) => {
    // Validation for serial numbers
    // If is_serial_number is true, then serial_numbers are required.
    if (data.is_serial_number && !data.serial_numbers) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t("bom.produceModal.validationErrors.serialNumbersRequired"),
            path: ["serial_numbers"],
        })
    }
    if (data.is_serial_number) {
        // Validate the serial_numbers are not greater than the main quantity.
        if (data.serial_numbers && data.serial_numbers?.length > data.quantity) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("bom.produceModal.validationErrors.serialNumbersCount"),
                path: ["serial_numbers"],
            });
        }
    
        // Validate the serial_numbers are not less than the main quantity.
        if (data.serial_numbers && data.serial_numbers?.length < data.quantity) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("bom.produceModal.validationErrors.serialNumbersMatchQuantity"),
                path: ["serial_numbers"],
            });
        }
    }

    // Validation for batch numbers
    // If is_batch_number is true, then batch_numbers are required.
    if (data.is_batch_number && !data.batch_numbers) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t("bom.produceModal.validationErrors.batchNumbersRequired"),
            path: ["batch_numbers"],
        })
    }

    if (data.is_batch_number) {
        // Validate the batch_numbers are not greater than the main quantity.
        if (data.batch_numbers && data.batch_numbers?.length > data.quantity) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("bom.produceModal.validationErrors.batchNumbersCount"),
                path: ["batch_numbers"],
            });
        }

        // Validate that batch number contains at least one entry
        // if (data.batch_numbers && data.batch_numbers?.length < 1) {
        //     ctx.addIssue({
        //         code: z.ZodIssueCode.custom,
        //         message: "Batch numbers count cannot be less than produced quantity",
        //         // message: i18n.t("bom.produceModal.validationErrors.batchNumbersCount"),
        //         path: ["batch_numbers"],
        //     });
        // }
    }
})

export type ProduceFormTyping = z.infer<typeof produceFormScheme>;


export type ProductionSuccessData = {
    product: {
        id: number;
        code: string;
        name: string;
    }
    quantity: number;
}