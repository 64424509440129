import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { Language } from "@/types/general";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@redux/hooks";

export const languagesApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getLanguagesList: builder.query<Language.Root[], void>({
            query: () => ({
                url: `language`,
            }),
            transformResponse
        }),
        
        getLanguages: builder.query<Language.Root[], void>({
            query: () => ({
                url: `language`,
            }),
            providesTags: (result) => 
                result
                ? [...result.map(({id}) => ({type: "Language" as const, id})), "Language"]
                : ["Language"],
            transformResponse
        }),

        createLanguage: builder.mutation<Language.Root, Language.DTO.Create>({
            query: (body) => ({
                url: "language",
                method: "POST",
                data: body
            }),
            transformResponse,
            invalidatesTags: ["Language"],

            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: createdLanguage } = await queryFulfilled;
                    const createResult = dispatch(languagesApi.util.updateQueryData("getLanguages", undefined, (draft) => {
                        draft.push({
                            id: createdLanguage.id,
                            name: createdLanguage.name,
                            code: createdLanguage.code,
                        })
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        deleteLanguage: builder.mutation<void, number>({
            query: (id) => ({
                url: `language/${id}`,
                method: "DELETE"
            }),

            async onQueryStarted(id, { dispatch, queryFulfilled}) {
                try {
                    const { data: deletedLanguage} = await queryFulfilled;
                    const deleteResult = dispatch(languagesApi.util.updateQueryData("getLanguages", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === id);
                        if (index !== -1) {
                            draft.splice(index, 1);
                        }
                    }))
                } catch (err) {
                    console.error(err)
                }
            }
        })
    })
});

const selectLanguagesResult = languagesApi.endpoints.getLanguages.select();

const selectAllLanguages = createSelector(
    selectLanguagesResult,
    (languages) => languages?.data ?? []
)

export const selectLanguageById = createSelector(
    selectAllLanguages,
    (state: RootState, lang_id: number | undefined) => lang_id,
    (languages, lang_id) => lang_id != undefined ? languages.find(lang => lang.id === lang_id) : undefined
);

export const {
    useGetLanguagesListQuery,
    useGetLanguagesQuery, 
    useCreateLanguageMutation, 
    useDeleteLanguageMutation 
} = languagesApi;