import React, { useCallback } from "react";
import NavigationCard from "../NavigationCard";
import { useTranslation } from "react-i18next";
import { normalizePrice, useEchoEffect } from "@helpers/utils";
import { useAppSelector } from "@redux/hooks";
import { useAbility } from "@casl/react";
import { AbilityContext } from "@/casl.config";
import _ from "lodash";
import { useGetTotalInStockQuery, useGetTotalRevenueQuery } from "@redux/features/dashboard/dashboardApi";

export default function NavStatHeader() {
    const { t } = useTranslation("", { keyPrefix: "dashboard.navbar.totalStat" });

    const user = useAppSelector(state => state.auth.user);

    const ability = useAbility(AbilityContext);

    const canSeeRevenue = ability.can("feed.view", "general");

    const { data: totalRevenue, refetch: refetchTotalRevenue } = useGetTotalRevenueQuery(undefined, {
        skip: canSeeRevenue
    });

    const { data: totalInStock, refetch: refetchTotalInStock } = useGetTotalInStockQuery(undefined, {
        skip: !canSeeRevenue
    });

    const displayableValue = canSeeRevenue ?
        (normalizePrice(totalRevenue) + " " + user?.company.currency?.symbol)
        :
        totalInStock;

    const refetchDebounced = useCallback(_.debounce(() => {
        if (canSeeRevenue) {
            refetchTotalRevenue();
        } else {
            refetchTotalInStock();
        }
    }, 1000), []);

    useEchoEffect(
        `dashboard.${canSeeRevenue ? "total_revenue" : "total_in_stock"}.${user?.company.id ?? -1}`,
        "DashboardUpdateEvent",
        () => {
            // This socket doesn't provide any data on update, so we just refetch the data
            refetchDebounced();
        },
        undefined,
        [user, ability]
    );

    return (
        <NavigationCard className="grow px-6 justify-between">
            <div className="flex items-center">
                        <span className="text-lg-bold text-accent mr-4">
                            {
                                canSeeRevenue ?
                                    t("totalRevenue")
                                    : t("totalInStock")
                            }
                        </span>
                <span className="text-sm-light color-suppli-gray">
                            {t("thisMonth")}
                        </span>
            </div>

            <div className="d-flex align-items-center">
                <h6 className="text-accent text-2xl font-bold m-0">
                    {displayableValue}
                </h6>
            </div>
        </NavigationCard>
    );
}