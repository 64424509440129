import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Company } from "@/types/general";

type CompaniesSliceState = {
    selectedCompany?: Company.Extended; // `null` means new company is being created
    isSelectedCompanyLoading: boolean;
}

const initialState: CompaniesSliceState = {
    isSelectedCompanyLoading: false
};

const companiesSlice = createSlice({
    name: "companies",
    initialState,
    reducers: {
        setSelectedCompany: (state, action: PayloadAction<Company.Extended | undefined>) => {
            state.selectedCompany = action.payload;
        },
        setIsSelectedCompanyLoading: (state, action: PayloadAction<boolean>) => {
            state.isSelectedCompanyLoading = action.payload;
        },
    }
});

export const { setSelectedCompany, setIsSelectedCompanyLoading } = companiesSlice.actions;

export default companiesSlice.reducer;
