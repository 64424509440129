
import React from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import { Unit } from "@/types/general";
import { useTranslation } from "react-i18next";
import BaseButton from "@reusables/BaseButton";
import { useDeleteUnitMutation } from "@redux/features/units/unitsApi";
import { toast } from "react-toastify";

interface DeletionModalProperties {
    isOpen: boolean;
    unit?: Unit.Root;
    onClose: () => void;
}


export default function DeletionModal(props: DeletionModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.units.modals.deletion" }); //TODO: LOCALIZE

    const [deleteUnit, { isLoading: isDeletionLoading }] = useDeleteUnitMutation();
    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
            isLoading={isDeletionLoading}
        >
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">{t("subheading")}.</div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose} />
                    <BaseButton text={t("buttons.delete")} size="md" onClick={() => {
                        console.log(props.unit?.id);
                        if (props.unit) {

                            deleteUnit(props.unit.id).unwrap().then(() => {
                                toast.success(t("responses.success"));
                            }).catch(e => { console.error(e); toast.error(t("responses.error")) }).finally(() => props.onClose(),);
                        }
                    }} />
                </div>
            </div>
        </BaseModal>
    )
}