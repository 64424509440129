import { createContext, useContext } from "react";

interface ExportModalContextProps {
    isOpen: boolean;
    onClose?: () => void;
    isAllowedExportProgressIndicator: boolean;
}

export const ExportModalContext = createContext<ExportModalContextProps>(null!);

export const useExportModalContext = () => {
    const props = useContext(ExportModalContext);
    if(!props){
        throw new Error("useExportModalContext must be used within ExportModalProvider");
    }

    return props;
}