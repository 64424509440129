import React from "react";
import {useTranslation} from "react-i18next";
import MutationLayout from "../components/MutationLayout";
import {useCreateReceiptMutation} from "@redux/features/receipts/receiptsApi";
import {BaseLoadingBlocker} from "@reusables/blockers/BaseLoadingBlocker";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function ReceiveCreationPage() {
    const history = useHistory();

    const {t} = useTranslation("", {keyPrefix: "receive.mutation"});

    const [createReceipt, {isLoading: isCreationLoading}] = useCreateReceiptMutation();

    return (
        <>
            <Can not I="create" a="receive">
                <div className="levitation-extended">
                    <NoPermissionBanner />
                </div>
            </Can>

            <Can I="create" a="receive">
                <BaseLoadingBlocker active={isCreationLoading}>
                    <div className="levitation-extended space-y-[40px]">
                        <div className="flex items-center">
                            <h6 className="text-accent grow">{t("headings.creation")}</h6>
                        </div>
                        <MutationLayout onMutation={(data) => {
                            
                            createReceipt({
                                supplier: data.supplier.id,
                                purchase_order_id: data.purchase_order.id,
                                date: data.receive_date.date.format("YYYY-MM-DD"),
                                receives: data.receipts.map(receipt => ({
                                    product_order_id: receipt.order.data?.id,
                                    ...(!receipt.order.data?.product.is_service && {
                                        location: {
                                            store: receipt.location?.store.id as number,
                                            ...(receipt.location?.section && {section: receipt.location.section.id}),
                                        },
                                    }),
                                    received_quantity: receipt.received_quantity as number,
                                    ...(receipt.order.data?.product.has_serial_number && {
                                        serial_numbers: receipt.serial_numbers,
                                    }),
                                    ...(receipt.order.data?.product.has_batch_number && {
                                        batch_numbers: receipt.batch_numbers.map(({batch_number, expiration_date}) => ({
                                            batch_number,
                                            expiration_date: expiration_date?.format("YYYY-MM-DD"),
                                        }))
                                    })
                                }))
                            }).unwrap().then(() => {
                                toast.success(t("responses.creation.success"));

                                history.push("/dashboard/receive");
                            }).catch(e => {
                                console.error(e);

                                toast.error(t("responses.creation.error"));
                            });
                        }}/>
                    </div>
                </BaseLoadingBlocker>
            </Can>
        </>
    );
}
