import React from "react";
import { useTranslation } from "react-i18next";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import ToggleWrapper from "../../ToggleWrapper";
import { Controller, useFormContext } from "react-hook-form";
import { ProductType } from "@components/Dashboard/pages/Products/components/CreationEditingLayout/types";

export default function Weights() {
    const { t } = useTranslation("", { keyPrefix: "products.general" });

    const { control } = useFormContext<ProductType>();

    return (
        <BaseInputsGrid title={t("categories.weights")}>
            {/*  WEIGHT */}
            <ToggleWrapper label={t("fields.weight") + " (kg)"} fieldKey="weight">
                <Controller
                    name={"weights.weight"}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            type="number"
                            step={"any"}
                        />
                    )}
                />
            </ToggleWrapper>

            {/*  CBM */}
            <ToggleWrapper label={t("fields.cbm")} fieldKey="CBM">
                <Controller
                    name={"weights.CBM"}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            type="number"
                            step={"any"}
                        />
                    )}
                />
            </ToggleWrapper>

            {/*  WIDTH */}
            <ToggleWrapper label={t("fields.width") + " (mm)"} fieldKey="width">
                <Controller
                    name={"weights.width"}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            type="number"
                            step={"any"}
                        />
                    )}
                />
            </ToggleWrapper>

            {/*  HEIGHT */}
            <ToggleWrapper label={t("fields.height") + " (mm)"} fieldKey="height">
                <Controller
                    name={"weights.height"}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            type="number"
                            step={"any"}
                        />
                    )}
                />
            </ToggleWrapper>

            {/*  LENGTH */}
            <ToggleWrapper label={t("fields.length") + " (mm)"} fieldKey="length">
                <Controller
                    name={"weights.length"}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            type="number"
                            step={"any"}
                        />
                    )}
                />
            </ToggleWrapper>
        </BaseInputsGrid>
    );
}