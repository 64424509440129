import { AppThunk } from "@/redux/hooks";
import { productsApi } from "./productsApi";
import { addProductCopy } from "./productsSlice";
import { History } from "history";

/**
 * Thunk to handle the product copy.
 * @param product_id // id of the product to copy
 * @param history // history object from react-router-dom
 */
export const handleProductCopy = (product_id: number, history: History): AppThunk => async (dispatch) => {
    if (!product_id) {
        console.error("No product id provided");
        return;
    }

    try {
        const product = await dispatch(productsApi.endpoints.getProduct.initiate(product_id)).unwrap();
        dispatch(addProductCopy(product));
        history.push("/dashboard/products/new");
    } catch (error) {
        console.error("Error fetching the product:", error);
    }
}