/*eslint-disable */

import React, { useRef } from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

type Item = {
  imageUrl?: string;
  render?: () => any;
};

type Props = {
  wrapperClassName?: string;
  slideClassName?: string;
  items: Item[];
  options?: {
    pagination?: boolean;
  } & SwiperProps;
  showNav?: boolean;
  nextEl?: any;
  prevEl?: any;
};

export const Slider = ({
  wrapperClassName,
  slideClassName,
  items = [],
  options = { pagination: false, speed: 500 },
  showNav = true,
  nextEl,
  prevEl,
}: Props) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const modules = [Scrollbar, Navigation, A11y, Autoplay];
  if (options.pagination) modules.push(Pagination);
  return (
    <Swiper
      className={wrapperClassName}
      modules={modules}
      pagination={{ clickable: true }}
      navigation={{
        prevEl: prevRef.current,
        nextEl: nextRef.current,
      }}
      onBeforeInit={(swiper: any) => {
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.update();
      }}
      {...options}
    >
      {items.map((item, i) => (
        <SwiperSlide className={slideClassName} key={i}>
          {item.render && item.render()}
        </SwiperSlide>
      ))}
      {showNav && (
        <div>
          <div
            ref={prevRef}
            className="swiper-button-prev after:!text-2xl tablet:after:!text-4xl !left-0 !text-primary"
          >
            {prevEl}
          </div>
          <div
            ref={nextRef}
            className="swiper-button-next after:!text-2xl tablet:after:!text-4xl !right-0 !text-primary"
          >
            {nextEl}
          </div>
        </div>
      )}
    </Swiper>
  );
};

export default Slider;
