import React, {memo, useEffect} from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseButton from "@reusables/BaseButton";
import {CreationModalProperties} from "../../../../types";
import {useTranslation} from "react-i18next";
import BaseInput from "@reusables/BaseInput";
import {Category} from "@/types/general";
import {toast} from "react-toastify";
import {z} from "zod";
import {refinements} from "@helpers/refinements";
import {zodResolver} from "@hookform/resolvers/zod";
import {Controller, useForm} from "react-hook-form";
import {selectCategoryByCode, useCreateCategoryMutation} from "@redux/features/categories/categoriesApi";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import {globalStore} from "@redux/store";
import {stopPropagate} from "@helpers/utils";
import BaseCheckbox from "@reusables/BaseCheckbox";

const categoryScheme = z.object({
    name: z.string(),
    code: z.string().trim().optional()
        .refine(refinements.noSpaces.refine, refinements.noSpaces.message)
        .refine((code) => {
            return code ? selectCategoryByCode(globalStore.getState(), code) === undefined : true;
        }, {
            params: {
                i18n: "general.validation.custom.code.unique"
            }
        }),
    is_service: z.boolean(),
})

type CategoryTyping = z.infer<typeof categoryScheme>

export default memo(function CategoryCreationModal(props: CreationModalProperties<Category.Root>) {
    const {t} = useTranslation("", {keyPrefix: "products.createEditProduct.modals.category.creationModal"});

    const [createCategory, {isLoading}] = useCreateCategoryMutation();

    const {control, reset, handleSubmit} = useForm<CategoryTyping>({
        resolver: zodResolver(categoryScheme),
        defaultValues: {
            is_service: false
        }
    });

    useEffect(() => {
        if (!props.isOpen) {
            reset();
        }
    }, [props.isOpen])

    const onSubmit = handleSubmit((data) => {
        createCategory(data)
            .unwrap()
            .then(category => {
                props.onCreation(category);
            })
            .catch(err => {
                console.error(err);
                toast.error("responses.error");
            })
            .finally(() => {
                props.onClose?.();
            })
    })

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
            isLoading={isLoading}
            useCloseIcon
        >
            <form className="space-y-[32px]" onSubmit={stopPropagate(onSubmit)}>
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <BaseInputsGrid cols={1}>
                    <Controller
                        name="code"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}

                                label={t("fields.code.label")}
                            />
                        )}
                    />

                    <Controller
                        name="name"
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}

                                label={t("fields.name.label") + " *"}
                            />
                        )}
                    />
                    <Controller
                        name="is_service"
                        control={control}
                        render={({field}) => (
                            <BaseCheckbox
                                {...field}
                                label={t("fields.isService.label")}
                            />
                        )}
                    />
                </BaseInputsGrid>

                <div>
                    <BaseButton text={t("createButton")} className="w-full" size="md"/>
                </div>
            </form>
        </BaseModal>
    )
})