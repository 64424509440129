import { useMemo } from "react";

// Calculate the total price of the components
export const useComponentsSummary = (components: {quantity: number; product?: {unit_price: number}}[]) => {
    return useMemo(() => {
        const total = components?.reduce((acc, component) => {
            return acc + component?.quantity * (component?.product?.unit_price ?? 0);
        }, 0);

        return { total };
    }, [components]);
};
