import React, {useState} from 'react'
import {useTranslation} from 'react-i18next';
import {Product, TabProperties} from '../../types'
import BaseTable from '@reusables/BaseTable';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {CustomerFormTyping} from './TabsController';
import {ReactComponent as EditSVG} from "@assets/icons/ic_edit.svg";
import {ReactComponent as TrashSVG} from "@assets/icons/ic_trash.svg";
import BaseButton from '@reusables/BaseButton';
import BaseMaterialIconButton from '@reusables/BaseMaterialIconButton';
import {Plus} from 'lucide-react';
import BaseMaterialButton from '@reusables/BaseMaterialButton';
import MutationProductModal from '../modals/MutationProductModal';
import dayjs from 'dayjs';
import { useAbility } from '@/casl.config';
import BasePermissionBlocker from '@reusables/BasePermissionBlocker';

export default function DiscountTab({onNext, onPrev, mode="creation"}: TabProperties & { onPrev: () => void }) {
    const {t} = useTranslation("", {keyPrefix: "customers.general.tabs.pricesAndDiscounts"});

    const ability = useAbility();

    const [selectedProductIndex, setSelectedProductIndex] = useState<number>();
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [isAddProductModal, setIsAddProductModal] = useState<boolean>(false);

    const {control} = useFormContext<CustomerFormTyping>();

    const {fields: products, append, update, remove} = useFieldArray({
        control,
        name: "products",
        keyName: "loop_id"
    });   

    return (
        <>
            <form className="space-y-[40px]" onSubmit={onNext}>
                <BaseTable
                    data={products ?? []}
                    immutable
                    rowKeyGetter={(row) => row.loop_id}

                    columns={[
                        {
                            header: t("table.columns.0"),
                            getter: (row) => row.product?.name,
                        },
                        {
                            header: t("table.columns.1"),
                            getter: (row) => row.sale_price
                        },
                        {
                            header: t("table.columns.2"),
                            getter: (row) => row.discount ?? "-"
                        },
                        {
                            header: t("table.columns.3"),
                            getter: (row) => row.min_quantity ?? "-"
                        },
                        {
                            header: t("table.columns.4"),
                            getter: (row) => row.valid_from?.format("DD.MM.YYYY") ?? dayjs().format("DD.MM.YYYY")
                        },
                        {
                            header: t("table.columns.5"),
                            getter: (row) => {
                                if (!row.valid_to) {
                                    // No expiration date
                                    return <span className='bg-[#E5E5E5] rounded-[16px] px-2 py-1 font-thin'>{t("table.noExpiration")}</span>;
                                }
                            
                                const expirationDate = dayjs(row.valid_to);
                                const currentDate = dayjs();
                            
                                // Check if expiration date is in the future
                                if (currentDate.isBefore(expirationDate)) {
                                    return expirationDate.format("DD.MM.YYYY");
                                } else {
                                    return (
                                        <div>
                                            {expirationDate.format("DD.MM.YYYY")} 
                                            <span className='bg-[#E5E5E5] rounded-[16px] px-2 py-1 font-thin'>
                                                {t("table.expiredLabel")}
                                            </span>
                                        </div>
                                    );
                                }
                            }
                        },     
                        {
                            header: t("table.columns.6"),
                            getter: (row, index) => (
                                <div className="flex flex-row space-x-[12px]">
                                    <BasePermissionBlocker expression={mode == "editing"} action="product_list.edit" module="customer">
                                        <EditSVG 
                                            className={`text-tables-secondaryIcon ${mode == "editing" && ability.can("product_list.edit", "customer") ? "hover:text-tables-highlightedIcon cursor-pointer" : "cursor-default"}`} 
                                            onClick={() => {
                                                setIsAddProductModal(true);
                                                setSelectedProduct(row);
                                                setSelectedProductIndex(index);
                                            }}
                                        />
                                    </BasePermissionBlocker>

                                    <BasePermissionBlocker expression={mode == "editing"} action="product_list.edit" module="customer">
                                        <TrashSVG
                                            className={`text-tables-secondaryIcon ${mode == "editing" && ability.can("product_list.edit", "customer") ? "hover:text-tables-highlightedIcon cursor-pointer" : "cursor-default"}`}
                                            onClick={() => {
                                                remove(index)
                                            }}
                                        />
                                    </BasePermissionBlocker>
                                </div>
                            ),
                        }           
                    ]}

                    nothingFound={{
                        text: "",
                        height: 0
                    }}

                    headerSx={{
                        borderBottom: "1px solid #E5E5E5",
                    }}

                    size='medium'
                />

                {
                    products.length ? (
                        <div className='ml-[16px]'>
                            <BasePermissionBlocker expression={mode == "editing"} action="product_list.edit" module="customer">
                                <BaseMaterialIconButton
                                    icon={<Plus />}
                                    onClick={() => setIsAddProductModal(true)}
                                    disabled={mode == "editing" && ability.cannot("product_list.edit", "customer")}
                                />
                            </BasePermissionBlocker>
                        </div>
                    ) : (
                        <div className="border max-h-full border-solid border-gray-300 rounded-[8px] p-[16px] ">
                        <div className='flex justify-center'>
                                <div className="flex flex-column">
                                    <div className="text-2xl text-center text-gray-300 font-thin mx-[24px]">{t("table.noProduct")}</div>
                                    <div className='mt-[16px]'>
                                        <BasePermissionBlocker expression={mode == "editing"}  action="product_list.edit" module="customer">
                                            <BaseMaterialButton
                                                onClick={() => ability.can("product_list.edit", "customer") && setIsAddProductModal(true)}
                                                type={"button"}
                                                fontWeight={600}
                                                fontSize={14}
                                                className={`'w-[200px] h-[48px]' ${ability.cannot("product_list.edit", "customer") ? "opacity-50 cursor-not-allowed" : ""}`}
                                            >
                                                {t("table.buttons.addProduct")}
                                            </BaseMaterialButton>
                                        </BasePermissionBlocker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="flex justify-center space-x-[24px]">
                    <BaseButton
                        text={t("buttons.prev")}
                        size="md"
                        buttonWidth="200px"
                        primaryOutlined
                        type={"button"}

                        onClick={onPrev}
                    />
                    <BaseButton
                        text={t("buttons.next")}
                        size="md"
                        buttonWidth="200px"
                    />
                </div>
            </form>

            <MutationProductModal
                isOpen={isAddProductModal}
                onClose={() => {
                    setSelectedProduct(undefined);
                    setSelectedProductIndex(undefined);
                    setIsAddProductModal(false);
                }}
                onProductAddition={(data) => {
                    selectedProductIndex !== undefined ? update(selectedProductIndex, data) : append(data);

                    setIsAddProductModal(false);
                    setSelectedProduct(undefined);
                    setSelectedProductIndex(undefined);
                }}
                product={selectedProduct}
            />
        </>
    )
}
