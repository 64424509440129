import BaseModal from "@/components/reusables/Modals/BaseModal";
import { z } from "zod";
import React, { useEffect, useState } from "react";
import BaseButton from "@/components/reusables/BaseButton";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseInput from "@reusables/BaseInput";
import BaseDropdown, { adaptInfiniteLoader } from "@reusables/dropdowns/BaseDropdown";
import { productsApi } from "@/redux/features/products/productsApi";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import BaseInfiniteLoader from "@/components/reusables/dropdowns/BaseInfiniteLoader";
import { Product } from "@/types/general";
import { useAppDispatch } from "@redux/hooks";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import i18n from "@/i18n";

const formScheme = z.object({
    selectedProduct: z.object({
        id: z.number(),
        code: z.string(),
        name: z.string()
    }),
    quantity: z.coerce.number().min(0)
});

type FormTyping = z.infer<typeof formScheme>;

interface ProductMutationModalProperties {
    isOpen: boolean;
    onSubmit: (model: FormTyping) => void;
    onClose: () => void;
    product?: {
        id: number;
        code: string;
        name: string;
        quantity: number;
    };
    alreadySelectedProdIds: number[];
}

export default function ProductMutationModal(
    props: ProductMutationModalProperties
) {
    const { t } = useTranslation("", {
        keyPrefix: "inventory.collections.collectionMutation.modals.mutateProduct"
    });

    const dispatch = useAppDispatch();

    const { control, handleSubmit, setValue, reset } = useForm<FormTyping>({
        resolver: zodResolver(formScheme)
    });

    useEffect(() => {
        if (props.isOpen) {
            if (props.product) {
                setValue("selectedProduct", {
                    id: props.product.id,
                    name: props.product.name,
                    code: props.product.code
                });
                setValue("quantity", props.product.quantity);
            }
        } else {
            reset();
        }
    }, [props.isOpen]);

    const onSubmit = handleSubmit((data) => {
        props.onSubmit(data);
    });

    const [isProductLazyLoadingFetching, setIsProductLazyLoadingFetching] = useState(false);
    const [productLazyLoadingPayloadWithoutAlreadyAddedDuplicates, setProductLazyLoadingPayloadWithoutAlreadyAddedDuplicates] = useState<Product.Root[]>([]);

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={562}
            padding="56px"
            useCloseIcon
        >
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent">
                    {props.product ? t("editing.heading") : t("addition.heading")}
                </div>

                <Controller
                    name={"selectedProduct"}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInfiniteLoader
                            fetch={(search, page, limit) => {
                                setIsProductLazyLoadingFetching(true);
                                dispatch(productsApi.endpoints.getProductsFull.initiate(
                                        {
                                            filters: {
                                                search
                                            },
                                            pagination: {
                                                page,
                                                limit
                                            }
                                        },
                                        {
                                            subscribe: false
                                        }
                                    )
                                ).unwrap().then(result => {
                                    const productsToFilter = result.payload ?? [];

                                    // Checking if productsOptions is truthy to proceed, otherwise return an empty array
                                    if (!productsToFilter) return [];

                                    // Transforming the list of already selected product IDs into an object for O(1) access
                                    const selectedProdIdsMap = _.keyBy(props.alreadySelectedProdIds);

                                    // Filtering out products that are already selected
                                    setProductLazyLoadingPayloadWithoutAlreadyAddedDuplicates(productsToFilter.filter(
                                        (product) => !selectedProdIdsMap[product.id]
                                    ));
                                })
                                    .catch(e => {
                                        if (isErrorWithMessage(e)) {
                                            toast.error(e.message);
                                        } else {
                                            toast.error(i18n.t("general.responses.somethingWentWrong"));
                                        }
                                    })
                                    .finally(() => {
                                        setIsProductLazyLoadingFetching(false);
                                    });
                            }}
                            limit={100}
                            result={productLazyLoadingPayloadWithoutAlreadyAddedDuplicates}
                            isLoading={isProductLazyLoadingFetching}
                            skip={!!props.product}
                        >
                            {
                                (infinity) => (
                                    <>
                                        <BaseDropdown
                                            {...field}
                                            {...fieldState}

                                            {...adaptInfiniteLoader(infinity)}

                                            label={`${t("fields.product.label")} *`}
                                            placeholder={t("fields.product.placeholder")}
                                            getter={{
                                                key: (opt) => opt.id,
                                                label: (opt) => opt.name,
                                                caption: (opt) => opt.code
                                            }}
                                            disabled={!!props.product}
                                        />
                                    </>
                                )
                            }
                        </BaseInfiniteLoader>
                    )}
                />

                <Controller
                    name={"quantity"}
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("fields.quantity.label")}
                            placeholder={t("fields.quantity.placeholder")}
                            type={"number"}
                        />
                    )}
                />

                <BaseButton
                    buttonWidth="100%"
                    text={t("saveButton")}
                    size="md"
                    type={"submit"}
                />
            </form>
        </BaseModal>
    );
}
