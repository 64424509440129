import React from 'react'
import BaseModal from '@reusables/Modals/BaseModal';
import {useTranslation} from 'react-i18next';
import BaseButton from '@reusables/BaseButton';

interface ConfirmationModalProperties {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    isSelectedCompany: boolean;
}

export default function ConfirmationModal(props: ConfirmationModalProperties) {
    const {t} = useTranslation("", {keyPrefix: "settings.general.companyInformation.tabs.companyDetails.modals"});

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
            useCloseIcon
        >
            <div className='space-y-[32px]'>
                {/* <div className='text-xl font-semibold text-center text-accent'>{t("heading")}</div> */}
                <div className='text-xl font-semibold text-center text-accent'>Confirmation</div>
                {props.isSelectedCompany && (
                    <>
                        <div className='text-center text-accent'>Are you sure you want to change the company information?</div>
                        <div className='text-center text-accent'>If you confirm this modal, you will not change your registration number anymore.</div>
                        <div className='grid grid-cols-2 gap-[16px]'>
                            <BaseButton text="Cancel" size='md' primaryOutlined onClick={props.onClose} />
                            <BaseButton text="Confirm" type='submit' size='md' onClick={props.onConfirm}/>
                        </div>
                    </>
                )}
                {/* {!props.isSelectedCompany && (  
                    <>
                        <div className='text-center text-accent'>Are you sure you want to finish and create your company?</div>
                        <div className='text-center text-accent'>After creating a company, you can change the registration number once.</div>
                        <div className='grid grid-cols-2 gap-[16px]'>
                            <BaseButton text="Cancel" size='md' primaryOutlined onClick={props.onClose} />
                            <BaseButton text="Confirm" type='submit' size='md' onClick={props.onConfirm}/>
                        </div>
                    </>
                )} */}
            </div>
        </BaseModal>
    )
}
