import React from "react";
import { Control, useWatch } from "react-hook-form";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProduceFormTyping } from "@components/Dashboard/pages/BOM/components/modals/ProduceModal/types";


type InfoCellProps = {
    index: number;
    control: Control<ProduceFormTyping>;
}

export default function InfoCell({ control, index }: InfoCellProps) {
    const { t } = useTranslation("", { keyPrefix: "bom.produceModal" });

    const row = useWatch({
        control,
        name: `components_picks.${index}`
    });

    const totalOrderQuantity = useWatch({
        control,
        name: "quantity"
    });

    if (!row)
        return null;

    return (
        <>
            <Stack
                spacing={"16px"}
            >
                <Box>
                    {totalOrderQuantity && `${row.sources.reduce((acc, source) => acc + Number(source.quantity ?? 0), 0)} ${t("of")} ${totalOrderQuantity && totalOrderQuantity * row.component.product.quantity}`}
                </Box>
            </Stack>
        </>
    )
}
