import React from 'react'
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useGetModulePurchaseOrdersFullQuery, useGetPurchaseOrdersFullQuery} from "@redux/features/purchases/purchasesApi";

import {calculateOrderPriceWithTax, jsxSwitch, useOrdering, usePagination} from '@/helpers/utils';
import {PurchaseOrder, Supplier} from '@/types/general';
import BaseTable from '@reusables/BaseTable';
import BaseChip from '@reusables/BaseChip';
import BaseTooltip from '@/components/reusables/BaseTooltip';
import BaseTinyActionIcon, {TinyActionIconType} from '@/components/reusables/BaseTinyActionIcon';
import {Pagination} from "@mui/material";
import { Can, useAbility } from '@/casl.config';
import { flattenLines } from '@/components/Dashboard/pages/PurchasingPage/PurchasesPage/types';

export default function PurchaseOrderTab({supplier}: { supplier: Supplier.Extended }) {
    const {t} = useTranslation("", {keyPrefix: "suppliers.general.tabs.purchaseOrders"});
    
    const ability = useAbility();

    const history = useHistory();

    const {orderBy, setOrderBy} = useOrdering<PurchaseOrder.DTO.OrderBy>({"name": "id", "type": "desc"});

    const filters = {
        suppliers: [supplier.id],
    };

    const pagination = usePagination({page: 1, limit: 8})

    const {data: purchaseOrders, isLoading: purchaseOrdersLoading} = useGetModulePurchaseOrdersFullQuery({
        filters,
        orderBy,
        pagination,
        module: "supplier"
    }, {
        skip: !supplier,
    });

    return (
        <>
            <Can I="purchase_order.view" a="supplier">
                <BaseTable
                    data={purchaseOrders?.payload ?? []}
                    isDataLoading={purchaseOrdersLoading}

                    columns={[
                        {
                            header: t("columns.0"),
                            getter: (row) => <u className='cursor-pointer'
                                                onClick={() => history.push(`/dashboard/purchasing/${row.id}/details`)}>{row.code ?? row.id}</u>
                        },
                        // {
                        //     header: t("columns.1"),
                        //     getter: (row) => jsxSwitch(
                        //         {
                        //             1: <BaseChip fill="none" className="bg-gray-300 text-accent">{t("invoicedStatus.0")}</BaseChip>,
                        //             2: <BaseChip fill="blue">{t("invoicedStatus.1")}</BaseChip>,
                        //             3: <BaseChip fill="green">{t("invoicedStatus.2")}</BaseChip>,
                        //         },
                        //         row.invoice_state
                        //     )
                        // },
                        {
                            header: t("columns.2"),
                            getter: (row) => jsxSwitch(
                                {
                                    1: <BaseChip fill="none"
                                                className="bg-gray-300 text-accent">{t("receivedStatus.0")}</BaseChip>,
                                    2: <BaseChip fill="blue">{t("receivedStatus.1")}</BaseChip>,
                                    3: <BaseChip fill="green">{t("receivedStatus.2")}</BaseChip>,
                                },
                                row.receive_state
                            )
                        },
                        {
                            header: t("columns.3"),
                            getter: (row) => `${row.currency.symbol} ${flattenLines(row.lines).map(line => calculateOrderPriceWithTax(line.data?.received_quantity ?? 0, line.data?.unit_price ?? 0, line.data?.discount ?? 0, line.data?.tax?.rate ?? 0)).reduce((a, b) => a + b, 0).toFixed(4)}`
                        },
                        {
                            header: t("columns.4"),
                            getter: (row) => row.purchase_date.format("DD.MM.YYYY"),
                            comparator: () => 0
                        },
                        {
                            header: "",
                            getter: (row) => row.receive_state === 1 ?
                                <div>
                                    <BaseTooltip title={`${t("startReceiving")}`} placement="bottom-start">
                                        <BaseTinyActionIcon
                                            type={TinyActionIconType.StartReceiving}
                                            onClick={() => history.push(`/dashboard/receive/new`, {
                                                supplier: supplier,
                                                purchase_order: row
                                            })}
                                        />
                                    </BaseTooltip>
                                </div> : ""
                        }
                    ]}

                    manualControls={{
                        ordering: (newOrdering) => {
                            if (newOrdering) {
                                let name: PurchaseOrder.DTO.OrderBy | undefined;

                                switch (newOrdering?.index) {
                                    case 2:
                                        name = "supplier";
                                        break;
                                    case 4:
                                        name = "delivery_date";
                                        break;
                                    default:
                                        name = undefined;
                                }

                                if (name)
                                    setOrderBy({
                                        name,
                                        type: newOrdering.order
                                    })
                            } else {
                                setOrderBy(undefined);
                            }
                        }
                    }}
                />
                <Pagination
                    className="mt-[32px]"
                    {...pagination.adapt(purchaseOrders)}
                />
            </Can>
        </>
    )
}
