import BaseButton from "@reusables/BaseButton";
import BaseInput from "@reusables/BaseInput";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import {useCreateCurrencyMutation, useGetCurrenciesQuery} from "@redux/features/currencies/currenciesApi";
import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useGetCurrenciesPublicQuery} from "@redux/api/internalApiSlice";
import {z} from "zod";
import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {toast} from "react-toastify";
import _ from "lodash";
import {isErrorWithMessage} from "@redux/api/query";

interface MutationCurrenciesModalProperties {
    isOpen: boolean;
    onClose: () => void;
}

const formScheme = z.object({
    selectedCurrency: z.object({
        id: z.number(),
        name: z.string(),
        code: z.string(),
        symbol: z.string(),
    }),
});

type FormTyping = z.infer<typeof formScheme>;

export default function MutationModal(props: MutationCurrenciesModalProperties) {
    const {t} = useTranslation("", {keyPrefix: "settings.general.currencies.modals.mutation"});

    const form = useForm<FormTyping>({
        resolver: zodResolver(formScheme)
    })

    // Getting currency data from props
    const {data: allCurrenciesOptions = [], isLoading: isAllCurrenciesOptionsLoading} = useGetCurrenciesPublicQuery();
    const {data: companyCurrenciesOptions = [], isLoading: isLoadingCompanyCurrenciesOptions} = useGetCurrenciesQuery();

    const allCurrenciesOptionsExcluded = useMemo(() => {
        const keyedCompanyCurrencies = _.keyBy(companyCurrenciesOptions, "id");
        return allCurrenciesOptions.filter(currency => !(currency.id in keyedCompanyCurrencies));
    }, [companyCurrenciesOptions, allCurrenciesOptions])

    const [createCurrency, {isLoading: isCreationLoading}] = useCreateCurrencyMutation();

    const onSubmit = form.handleSubmit((data) => {
        createCurrency({
            id: data.selectedCurrency.id
        }).unwrap().then(() => {
            toast.success(t("responses.creation.success"));
            props.onClose();
        }).catch((e) => {
            if(isErrorWithMessage(e)){
                toast.error(e.message);
            } else {
                toast.error(t("responses.creation.error"));
            }
        })
    });

    useEffect(() => {
        if(!props.isOpen){
            form.reset();
        }
    }, [props.isOpen]);

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={562}
            padding="56px"
            useCloseIcon
            isLoading={isCreationLoading}>
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent">
                    {t("creationHeading")}
                </div>

                <Controller
                    control={form.control}
                    name="selectedCurrency"
                    render={({field, fieldState}) => (
                        <>
                            <BaseDropdown
                                {...field}
                                {...fieldState}

                                label={t("dropdowns.type.label")}
                                options={allCurrenciesOptionsExcluded}
                                getter={{
                                    key: (opt) => opt.code,
                                    label: (opt) => opt.name,
                                    caption: opt => opt.code
                                }}

                                autocomplete
                            />

                            <BaseInput
                                label={t("fields.symbol.label")}
                                value={field.value?.symbol}
                                disabled
                            />
                        </>
                    )}
                />

                <div>
                    <BaseButton
                        buttonWidth="100%"
                        text={`${t("buttons.save")}`}
                        size="md"
                    />
                </div>
            </form>
        </BaseModal>
    );
}