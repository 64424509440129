import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import BaseInput from "@reusables/BaseInput";
import { useTranslation } from "react-i18next";
import { Send } from "lucide-react";
import { ExportModalFormTyping } from "@reusables/Modals/BaseExportModal";

export default function EmailField() {
    const { t } = useTranslation("", { keyPrefix: "general.modals.export.fields.email" });

    const { control } = useFormContext<ExportModalFormTyping>();

    return (
        <Controller
            control={control}
            name={"email"}
            render={({ field, fieldState }) => (
                <BaseInput
                    {...field}
                    label={t("label")}
                    placeholder={t("placeholder")}
                    error={fieldState.error}
                    icon={{
                        right: {
                            el: <Send size={16} className={"text-accent opacity-50"} />,
                            offset: 20
                        }
                    }}
                    sx={{
                        "& input": {
                            paddingRight: "40px"
                        }
                    }}
                />
            )}
        />
    );
}