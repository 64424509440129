import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { Currency } from "@/types/general";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@redux/hooks";
import { preciseDecimal } from "@helpers/utils";

const CURRENCY_RATE_PRECISION = import.meta.env.VITE_CURRENCY_RATE_PRECISION;

export const currenciesApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCurrencies: builder.query<Currency.Root[], void>({
            query: () => ({
                url: `currencies`
            }),
            transformResponse: resp => transformResponse(resp).map((cur: Currency.Root) => ({
                ...cur,
                rate: preciseDecimal(cur.rate, CURRENCY_RATE_PRECISION)
            })),
            providesTags: [{ type: "Currency", id: "LIST" }]
        }),

        getModuleCurrencies: builder.query<Currency.Root[], string>({
            query: (module) => ({
                url: `${module}/currencies`
            }),
            transformResponse: resp => transformResponse(resp).map((cur: Currency.Root) => ({
                ...cur,
                rate: preciseDecimal(cur.rate, CURRENCY_RATE_PRECISION)
            })),
            providesTags: [{ type: "Currency", id: "LIST" }]
        }),

        createCurrency: builder.mutation<Currency.Root, {id: number}>({
            query: (args) => ({
                url: `currencies`,
                method: "POST",
                data: {
                    id: args.id
                }
            }),
            transformResponse,
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: createdCurrency } = await queryFulfilled;
                    const createResult = dispatch(
                        currenciesApi.util.updateQueryData("getCurrencies", undefined, (draft) => {
                            draft.push({
                                id: createdCurrency.id,
                                name: createdCurrency.name,
                                code: createdCurrency.code,
                                rate: preciseDecimal(createdCurrency.rate, CURRENCY_RATE_PRECISION),
                                symbol: createdCurrency.symbol,
                                isFixed: createdCurrency.isFixed,
                                isBase: createdCurrency.isBase
                            });
                        })
                    );
                } catch (err) {
                    console.log(err);
                }
            }
        }),

        updateCurrency: builder.mutation<Currency.Root, Currency.DTO.Update & { id: number }>({
            query: ({ id, ...body }) => ({
                url: `currencies/${id}`,
                method: "PUT",
                data: body
            }),
            invalidatesTags: (result, error, args) => [{ type: "Currency", id: args.id }],
            transformResponse,
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedCurrency } = await queryFulfilled;
                    const updateResult = dispatch(
                        currenciesApi.util.updateQueryData("getCurrencies", undefined, (draft) => {
                            const index = draft.findIndex((item) => item.id === updatedCurrency.id);
                            if (index !== -1) {
                                draft[index] = {
                                    ...updatedCurrency,
                                    rate: preciseDecimal(updatedCurrency.rate, CURRENCY_RATE_PRECISION)
                                };
                            }
                        })
                    );
                } catch (error) {
                    console.log(console.error());
                }
            }
        }),

        deleteCurrency: builder.mutation<void, number>({
            query: (id) => ({
                url: `currencies/${id}`,
                method: "DELETE"
            }),

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    const { data: deletedEmployee } = await queryFulfilled;
                    const deleteResult = dispatch(currenciesApi.util.updateQueryData("getCurrencies", undefined, (draft) => {
                        const index = draft.findIndex(item => item.id === id);
                        if (index !== -1) {
                            draft.splice(index, 1);
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        })
    })
});

const selectCurrenciesResult = currenciesApi.endpoints.getCurrencies.select();

const selectAllCurrencies = createSelector(selectCurrenciesResult, (currencies) => currencies?.data ?? []);

export const selectCurrencyById = createSelector(
    selectAllCurrencies,
    (state: RootState, cur_id: number | undefined) => cur_id,
    (currencies, cur_id) => (cur_id != undefined ? currencies.find((cur) => cur.id === cur_id) : undefined)
);

export const { 
    useGetCurrenciesQuery, 
    useGetModuleCurrenciesQuery, 
    useCreateCurrencyMutation, 
    useUpdateCurrencyMutation, 
    useDeleteCurrencyMutation 
} = currenciesApi;