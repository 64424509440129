import React from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { SaleOrdersFormTyping } from "../../types";
import { AlertOctagon } from "lucide-react";
import BaseTooltip from "@reusables/BaseTooltip";
import { useTranslation } from "react-i18next";
import _ from "lodash";

export default function QuantityCell({ index }: { index: number }): JSX.Element {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general.orders.quantityCell" });

    const { control } = useFormContext<SaleOrdersFormTyping>();

    const lines = useWatch({
        control,
        name: "lines"
    });

    // Group lines by product ID
    const groupedLines = _.groupBy(lines, line => line.data?.product?.id);

    // Find products that are overstocked
    const lowStockProducts = Object.values(groupedLines)
        .filter(group => {
            const product = group[0]?.data?.product;
            if (!product || product.is_service) return false;

            const totalQuantity = _.sumBy(group, line => line.data?.quantity || 0);
            return totalQuantity > (product.in_stock ?? 0);
        })
        .map(group => {
            const product = group[0]?.data?.product;
            const totalQuantity = _.sumBy(group, line => line.data?.quantity || 0);
            return {
                ...product,
                totalQuantity,
                inStock: product?.in_stock ?? 0,
            };
        });

    const { field: quantityField, fieldState: quantityFieldState } = useController({
        name: `lines.${index}.data.quantity`,
        control
    });

    const product = useWatch({
        control,
        name: `lines.${index}.data.product`
    });

    return (
        <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-2">
                <div>{quantityField.value}</div>
                {lowStockProducts.map(lowStockProduct => (
                    lowStockProduct.id === product?.id && (
                        <BaseTooltip key={lowStockProduct.id} title={`Used: ${lowStockProduct.totalQuantity}` + " of " + t("lowStockWarning", { inStock: lowStockProduct.inStock }) + ""}>
                            <AlertOctagon size={14} color={"orange"} className="mb-1" />
                        </BaseTooltip>
                    )
                ))}
            </div>
        </div>
    );
}
