import React from 'react'
import {Alert, Collapse} from '@mui/material'
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {CustomerFormTyping} from '../tabs/TabsController';
import _ from "lodash";


export default function FormErrorWarning() {
    const {t} = useTranslation("", {keyPrefix: "suppliers.general.tabs"});
    const methods = useFormContext<CustomerFormTyping>();

    return (
        <Collapse in={!_.isEmpty(methods.formState.errors)}>
            <Alert severity={"error"}>
                {t("alerts.formIsInvalid")}
            </Alert>
        </Collapse>
    )
}
