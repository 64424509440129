import React, { useState } from "react";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { useTranslation } from "react-i18next";
import { ProductSchemeTyping } from "../../../types";
import LocationCreationModal from "../modals/location/LocationCreationModal";
import { useGetLocationsQuery, useGetModuleLocationsQuery } from "@redux/features/locations/locationsApi";
import { Controller, useFormContext } from "react-hook-form";
import { LocationOption } from "@components/Dashboard/pages/Inventory/Adjustments/logic";

export default function LocationDropdown() {
    const { t } = useTranslation("", { keyPrefix: "products" });

    const { data: options, isLoading: optionsLoading } = useGetModuleLocationsQuery("product");

    const { control, setValue } = useFormContext<ProductSchemeTyping>();

    const [isCreationModalOpen, setIsCreationModalOpen] = useState<boolean>(false);

    return (
        <>
            {
                <Controller
                    name="general.location"
                    control={control}
                    render={({ field, fieldState }) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}
                            label={t("general.fields.defaultLocation.label") + " *"}
                            placeholder={t("general.fields.defaultLocation.placeholder")}
                            options={options}
                            getter={{
                                label: (opt) => `${opt.store.name}${opt.section ? ` - ${opt.section.name}` : ""}`,
                                key: (opt) => (opt.section ? `${opt.store.id}_${opt.section.id}` : `${opt.store.id}`),
                                renderOption: (opt, icon) => (
                                    <div>
                                        <div className="grow">
                                            <span className="bold-highlight">{opt.store.name}</span>
                                            {opt.section ? <> - {opt.section.name}</> : null}
                                        </div>
                                        {icon}
                                    </div>
                                )
                            }}
                            isLoading={optionsLoading}
                            autocomplete
                            action={{
                                title: t("createEditProduct.modals.location.createButton"),
                                onClick: () => setIsCreationModalOpen(true)
                            }}
                            virtualize
                        />
                    )}
                />
            }

            <LocationCreationModal
                isOpen={isCreationModalOpen}
                onClose={() => setIsCreationModalOpen(false)}
                onCreation={(newLoc) => {
                    console.log(newLoc);

                    let newLocationOption = [] as LocationOption[];

                    // Transforming Location.Extended into LocationOption to use this location in the dropdown
                    if (newLoc.sections && newLoc.sections.length > 0) {
                        newLocationOption = newLoc.sections.map((section) => ({
                            store: {
                                id: newLoc.id,
                                name: newLoc.store
                            },
                            section: {
                                id: section.id,
                                name: section.name
                            }
                        }));
                    } else {
                        newLocationOption = [
                            {
                                store: {
                                    id: newLoc.id,
                                    name: newLoc.store
                                }
                            }
                        ];
                    }

                    setValue("general.location", newLocationOption[0]);
                }}
            />
        </>
    );
}
