import { AppDispatch } from "@redux/hooks";
import { pickingApi } from "@redux/features/picking/pickingApi";
import { downloadBlobExport } from "@helpers/utils";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";

type DownloadDeliveryNoteHandlerArgs = {
    pickingId: number;
    dispatch: AppDispatch;
    setIsDeliveryNoteLoading: (loading: boolean) => void;
    defaultErrorMessage: string;
}

export default function downloadDeliveryNoteHandler({
                                                        pickingId,
                                                        dispatch,
                                                        setIsDeliveryNoteLoading,
                                                        defaultErrorMessage
                                                    }: DownloadDeliveryNoteHandlerArgs) {
    setIsDeliveryNoteLoading(true);
    dispatch(pickingApi.endpoints.downloadDeliveryNote.initiate(pickingId))
        .unwrap()
        .then(response => {
            downloadBlobExport(() => response, `delivery_note_#${pickingId}`, "pdf");
        })
        .catch(e => {
            if (isErrorWithMessage(e)) {
                toast.error(e.message);
            } else {
                toast.error(defaultErrorMessage);
            }
        })
        .finally(() => setIsDeliveryNoteLoading(false));
}