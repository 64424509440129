import React from "react";
import {Customer} from "@/types/general";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import {useTranslation} from "react-i18next";
import {Stack} from "@mui/material";
import BaseCheckbox from "@reusables/BaseCheckbox";
import { useAbility } from "@/casl.config";

export default function GeneralTabPreview({ customer }: { customer: Customer.Extended }) {
    const { t } = useTranslation("", { keyPrefix: "customers.general.tabs.general" });

    const ability = useAbility();

    return (
      <div className="space-y-[40px]">
          <Stack
            direction={"row"}
            spacing={2}
          >
              <BaseCheckbox
                  value={!customer.is_person}
                  label={t("checkboxes.isCompany")}
                  disabled
              />

              <BaseCheckbox
                  value={customer.is_person}
                  label={t("checkboxes.isPerson")}
                  disabled
              />
          </Stack>

          <BaseInputsGrid>
              <InputLikeContainer
                label={t("fields.customerName.label")}
                content={customer.name}
              />

              <InputLikeContainer
                label={t("fields.customerCode.label")}
                content={customer.code}
              />

              {
                !customer.is_person && (
                  <InputLikeContainer
                    label={t("fields.registrationNumber.label")}
                    content={customer.registration_number}
                  />
                )
              }
          </BaseInputsGrid>

          { ability.can("contact_detail.view", "customer") &&
              customer.contacts.map((contact, index) =>
                <div key={index} className="space-y-[24px]">
                    {
                        index === 0 ?
                          <div>
                              <div className="text-xl text-accent">{t("categories.contactInfo")}</div>
                          </div>
                          :
                          <div>
                              <div className="text-xl text-accent">{t("categories.additionalContactInfo")}</div>
                          </div>
                    }
                    <div className="grid grid-cols-3 gap-x-[40px]">
                        {
                          !customer.is_person && (
                            <InputLikeContainer
                              label={t("fields.contactName.label")}
                              content={contact.name}
                            />
                          )
                        }

                        <InputLikeContainer
                          label={t("fields.phone.label")}
                          content={contact.phone}
                        />

                        <InputLikeContainer
                          label={t("fields.email.label")}
                          content={contact.email}
                        />
                    </div>
                </div>
              )
          }
      </div>
    );
}

function InputLikeContainer({ label, content }: { label: string, content: JSX.Element | string | undefined | number }) {
    return (
      <div>
          <div className="font-semibold text-inputs-label-dim mb-[8px]">{label}</div>
          <div className="text-accent">
              {content ?? "-"}
          </div>
      </div>
    );
}