import React, {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next';
import BaseModal from '@reusables/Modals/BaseModal';
import BaseButton from '@reusables/BaseButton';
import {useDeclineCountMutation} from '@redux/features/stockCount/stockCountApi';
import {toast} from 'react-toastify';
import {StockCount} from "@/types/general";
import BaseInput from "@reusables/BaseInput";
import _ from "lodash";

interface DeclineModalProperties {
    isOpen: boolean;
    onClose: () => void;
    report?: StockCount.Root;
}

export default function DeclineModal(props: DeclineModalProperties) {
    const {t} = useTranslation("", {keyPrefix: "inventory.stockCount.modals.decline"});

    const [declineReport, {isLoading: isDecliningLoading}] = useDeclineCountMutation();

    const [reason, setReason] = useState<string>();

    const worker = _.join([props.report?.user.first_name, props.report?.user.last_name], " ");

    return (
        <BaseModal 
            isOpen={props.isOpen} 
            onClose={props.onClose} 
            width={700} 
            padding="56px"
            isLoading={isDecliningLoading}
            >
            <div className="space-y-[16px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent font-thin">
                    <div>
                        <Trans
                            i18nKey="subheading" t={t}
                            components={{
                                bold: <span className="font-semibold" />,
                            }}
                            values={{
                                reportId: props.report?.id,
                                worker
                            }}
                        />
                    </div>
                    <div>
                        <Trans i18nKey="additionalText" t={t} />
                    </div>
                </div>
                <div className='w-full'>
                    <BaseInput
                        placeholder={t("placeholder")}
                        value={reason}
                        onChange={setReason}
                        multiline
                        rows={3}
                    />
                </div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose} />
                    <BaseButton
                        text={t("buttons.confirm")}
                        size="md"
                        onClick={() => {
                            if (props.report) {
                                declineReport({
                                    id: props.report.id,
                                    comment: reason
                                })
                                    .unwrap()
                                    .then(() => {
                                        toast.success(t("responses.success"));
                                    })
                                    .catch((e) => {
                                        console.error(e);

                                        toast.error(t("responses.error"));
                                    })
                                    .finally(() => props.onClose());
                            }
                        }}
                    />
                </div>
            </div>
        </BaseModal>
    )
}
