import React, { ReactNode } from "react";
import { Dialog } from "@mui/material";

import BaseButton from "@reusables/BaseButton";

type Props = {
  visible: boolean;
  okText?: string;
  cancelText?: string;
  loading?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  children?: ReactNode;
  footer?: ReactNode;
};

function ModalConfirm({
  visible,
  okText = "Submit",
  cancelText = "Cancel",
  loading,
  onOk,
  onCancel,
  children,
}: Props) {
  return (
    <Dialog
      open={visible}
      onClose={onCancel}
      fullWidth={true}
      className="rounded-xl"
      PaperProps={{
        style: { borderRadius: 16 },
      }}
    >
      <div className="p-8">
        <div className="my-8">{children}</div>
        <div className="flex space-x-4 justify-center">
          <BaseButton
            size="md"
            text={cancelText}
            primaryOutlined
            onClick={onCancel}
            buttonWidth="100%"
          />
          <BaseButton
            size="md"
            text={okText}
            onClick={onOk}
            buttonWidth="100%"
            loading={loading}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default ModalConfirm;
