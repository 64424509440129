import { CustomFields } from "@/types/general";
import { Trans, useTranslation } from "react-i18next";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseButton from "@reusables/BaseButton";
import { toast } from "react-toastify";
import React from "react";
import { toastError } from "@helpers/utils";
import { useDeleteCustomFieldMutation } from "@redux/features/customFields/customFieldsApi";
import { Alert } from "@mui/material";

interface DeletionModalProperties {
    isOpen: boolean;
    onClose: () => void;
    field?: CustomFields.Components.Field;
}

export default function DeletionModal(props: DeletionModalProperties) {
    const { t } = useTranslation("", { keyPrefix: `settings.general.customFields.modals.deletion` });

    const [deleteField, { isLoading: isDeletionLoading }] = useDeleteCustomFieldMutation();

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
            isLoading={isDeletionLoading}
        >
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">
                    <Trans
                        t={t}
                        i18nKey="subheading"
                        values={{ field: props.field?.label ?? "" }}
                        components={{
                            b: <span className={"font-semibold"} />
                        }}
                    />
                </div>
                {
                    props.field && !props.field?.is_disabled &&
                    <Alert severity={"info"}>
                        {
                            t("tip")
                        }
                    </Alert>
                }
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose} />
                    <BaseButton text={t("buttons.action")} size="md" onClick={() => {
                        if (props.field) {
                            deleteField(props.field.id).unwrap()
                                .then(() => {
                                    toast.success(t("responses.success"));
                                })
                                .catch(toastError)
                                .finally(() => props.onClose());
                        }
                    }} />
                </div>
            </div>
        </BaseModal>
    );
}
