import { productsApi } from "@redux/features/products/productsApi";
import { AppDispatch } from "@redux/hooks";
import {addQPO} from "@redux/features/purchases/purchasesSlice";
import { useHistory } from "react-router-dom";

export const useRestockingRedirect = (dispatch: AppDispatch) => {
    const history = useHistory();

    return (product_id: number, quantity: number) => {
        const result = dispatch(productsApi.endpoints.getProduct.initiate(product_id));

        result.unwrap().then((product) => {
            dispatch(addQPO({
                order: {product, quantity},
                meta: {
                    supplier: product.supplier
                }
            }));

            history.push("/dashboard/purchasing/new");
        })
        .finally(() => {
            result.unsubscribe();
        });
    }
}
