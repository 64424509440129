import React, { PropsWithChildren, useState } from "react";
import { Collapse } from "@mui/material";
import { cn } from "@helpers/utils";
import { Minus, Plus } from "lucide-react";

type PermissionsCollapseProps = PropsWithChildren<{
    title: string;
    type?: "group" | "module";
}>;

export default function PermissionsCollapse({ title, type = "module", children }: PermissionsCollapseProps) {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const isGroup = type === "group";

    return (
        <div>
            <div className={cn(
                isGroup && !isCollapsed && "bg-[#F9F9F9] border-[1px] border-transparent", // When expanded
                isGroup && isCollapsed && "border-[1px] border-solid border-[#D9D6DA]", // When collapsed
                "rounded-[8px] transition-all" // Basic styles for all collapses
            )}>
                <div
                    className={cn(
                        "flex flex-row items-center justify-between cursor-pointer text-accent",
                        isGroup && "p-[20px]",
                        !isGroup && "py-[10px]"
                    )}
                    style={{
                        ...(!isGroup && { borderBottom: "1px solid #F3F2EF" })
                    }}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                >
                    <div className={"font-[500] text-[16px]"}>{title}</div>
                    <div>
                        {
                            isCollapsed ? <Minus /> : <Plus />
                        }
                    </div>
                </div>
                <Collapse in={isCollapsed}>
                    <div className={cn(
                        isGroup && "px-[20px] pb-[20px]",
                        !isGroup && "py-[24px]"
                    )}>
                        {children}
                    </div>
                </Collapse>
            </div>
        </div>
    );
}