import React from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import dayjs from "dayjs";
import { DiscountOption } from "./AddProductModal";
import { normalizePrice } from "@helpers/utils";

interface DiscountCardProps {
    discounts: DiscountOption[];
    selectedDiscount?: DiscountOption;
    onDiscountSelect: (discount: DiscountOption) => void;
}

const DiscountCard = ({ discounts, selectedDiscount, onDiscountSelect }: DiscountCardProps) => {
    const { t } = useTranslation("", { keyPrefix: "sales.orders.general.orders.modals.addProduct.cards" });
    // Group discounts by id
    const productDiscounts = discounts.filter(d => d.id === "productDiscount");
    const customerDiscounts = discounts.filter(d => d.id === "customerDiscount");
    const groupDiscounts = discounts.filter(d => d.id.includes("groupDiscount"));

    // Function to render discount cards for a given group
    const renderDiscountCards = (group: DiscountOption[]) => {
        return group.map((discount: DiscountOption, index: number) => (
            <div
                key={index}
                className={`flex flex-row items-start max-w-sm p-4 border border-gray-800 rounded-lg ${discount.id === selectedDiscount?.id ? "bg-purple-100" : "bg-white"} hover:outline outline-1 outline-offset-0 outline-purple-300 cursor-pointer`}
                onClick={() => onDiscountSelect(discount)}
            >
                <FormControlLabel
                    className="flex-grow-0"
                    control={<Radio value={discount.id} />}
                    label=""
                />
                <div className="flex-grow-1">
                    <div className="text-lg font-medium">
                        {discount?.name}
                    </div>
                    <div className="text-gray-600 font-semibold">
                        {t("fields.salePrice.label")}: {discount.salePrice}
                    </div>
                    <div className="text-gray-600 font-semibold">
                        {t("fields.discount.label")}: {discount.discount}%
                    </div>
                    <div className="text-gray-600">
                        {t("fields.calculatedPrice.label")}: {normalizePrice(discount.calculated_price)}
                    </div>
                    {
                        (discount.valid_from || discount.valid_to) && (
                            <>
                                <div className="text-gray-600">
                                    {t("fields.validFrom.label")}: {discount.valid_from && dayjs(discount.valid_from).format("DD.MM.YYYY")}
                                </div>
                                <div className="text-gray-600">
                                    {t("fields.validTo.label")}: {discount.valid_to ? dayjs(discount.valid_to).format("DD.MM.YYYY") : t("fields.validTo.noExpiration")}
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        ));
    };

    return (
        <RadioGroup
            name="discountOptions"
            value={selectedDiscount?.id}
            onChange={(event) => {
                return onDiscountSelect(discounts.find(discount => discount.id === event.target.value) as DiscountOption);
            }}
        >
            <BaseInputsGrid cols={2}>
                {renderSection(t("titles.pricesAndDiscounts"), productDiscounts)}
                {renderSection(t("titles.generalCustomerDiscount"), customerDiscounts)}
                {renderSection(t("titles.customerGroup"), groupDiscounts)}
            </BaseInputsGrid>
        </RadioGroup>
    );

    function renderSection(title: string, discounts: DiscountOption[]) {
        return discounts.length > 0 && (
            <>
                <div className={`col-span-2 text-xl text-accent`}>
                    {title}
                </div>
                {renderDiscountCards(discounts)}
            </>
        );
    }
};

export default DiscountCard;