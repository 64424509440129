import React, { useEffect } from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import { Trans, useTranslation } from "react-i18next";
import BaseBadge from "@reusables/BaseBadge";
import BaseButton from "@reusables/BaseButton";
import { useCopyRoleMutation, useGetRolesQuery } from "@/redux/features/roles/rolesApi";
import { z } from "zod";
import { useAppSelector } from "@redux/hooks";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import { useGetCompaniesQuery } from "@redux/features/companies/companiesApi";
import BaseInput from "@reusables/BaseInput";
import { toast } from "react-toastify";
import { toastError } from "@helpers/utils";

interface CopyingModal {
    isOpen: boolean;
    onClose: () => void;
    role_id?: number;
}

const copyingScheme = z.object({
    name: z.string().trim(),
    description: z.string().trim().nullish(),
    company: z.object({
        id: z.number(),
        name: z.string(),
        is_default: z.boolean()
    })
});

type CopyingFormTyping = z.infer<typeof copyingScheme>;

export default function CopyingModal({ isOpen, onClose, role_id }: CopyingModal) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.roles.modals.copying" });

    const { control, handleSubmit, reset } = useForm<CopyingFormTyping>({
        resolver: zodResolver(copyingScheme)
    });

    // Loading companies
    const { data: companies = [], isLoading: isUsersLoading } = useGetCompaniesQuery();

    // Getting current user data
    const currentUser = useAppSelector(state => state.auth?.user);

    // Getting method to assign role
    const [copyRole, { isLoading }] = useCopyRoleMutation();

    // Selecting role by id
    const { role } = useGetRolesQuery(undefined, {
        selectFromResult: result => ({
            role: result.data?.find(r => r.id === role_id)
        })
    });

    useEffect(() => {
        if (role && currentUser) {
            reset({
                name: `${role.name} (${t("fields.name.copy")})`,
                description: role.description,
                company: {
                    ...currentUser.company,
                    is_default: true
                }
            });
        } else {
            reset();
        }
    }, [role, currentUser]);

    if (!role) {
        return null;
    }

    const onSubmit = handleSubmit(data => {
        copyRole({
            role_id: role.id,
            name: data.name,
            description: role.description,
            company_id: data.company.id
        })
            .unwrap()
            .then(() => {
                toast.success(t("responses.success"));
            })
            .catch((e) => {
                console.error(e);
                toastError(e);
            })
            .finally(() => {
                onClose();
                reset();
            });
    }, console.error);

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={700}
            padding="56px"
            isLoading={isLoading}
        >
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">
                    <Trans
                        t={t}
                        i18nKey="subheading"
                        values={{ roleName: role.name }}
                        components={{
                            badge: <BaseBadge className={"px-2 bg-[#D9DAFD]"} />
                        }}
                    />
                </div>
                <div className={"space-y-[24px]"}>
                    <Controller
                        name={"name"}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}

                                label={t("fields.name.label")}
                                placeholder={t("fields.name.placeholder")}
                            />
                        )}
                    />
                    <Controller
                        name={"description"}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}

                                label={t("fields.description.label")}
                                placeholder={t("fields.description.placeholder")}
                            />
                        )}
                    />
                    <Controller
                        name={"company"}
                        control={control}
                        render={({ field, fieldState }) => {
                            return (
                                <BaseDropdown
                                    {...field}
                                    {...fieldState}

                                    label={t("fields.company.label")}
                                    placeholder={t("fields.company.placeholder")}

                                    options={companies}
                                    getter={{
                                        key: opt => opt.id,
                                        label: opt => opt.name,
                                        caption: opt => opt.is_default ? t("fields.company.currentCompany") + "" : ""
                                    }}

                                    isLoading={isUsersLoading}

                                    autocomplete
                                />
                            );
                        }}
                    />
                </div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} type="button" size="md" primaryOutlined onClick={onClose} />
                    <BaseButton
                        text={t("buttons.confirm")}
                        size="md"
                        type={"submit"}
                    />
                </div>
            </form>
        </BaseModal>
    );
}