import React from "react";
import { EndpointItem, SidebarItemT } from "../types";
import SidebarItem from "../SidebarItem";

interface SidebarItemsGroup{
  items: SidebarItemT[];
  useIntending?: boolean;
  recursionHierarchy: number[];
  isMinified: boolean;
}

export default function SidebarItemsGroup({
  items,
  useIntending = true,
  recursionHierarchy,
  isMinified
                                      }: SidebarItemsGroup): JSX.Element{

  // Rendering links
  return(
    <ul className={`group-[.minified]:space-y-[18px] space-y-[2px] group-[.minified]:py-[8px] ${useIntending ? "ml-3" : ""}`}>
      {
        items?.map((item, index) => {
          if(!item.isAccessible)
            return null;

          return(
            <SidebarItem
              item={item}
              recursionHierarchy={recursionHierarchy}
              key={(item as EndpointItem).UID}
              isMinified={isMinified}
            />
          )
        })
      }
    </ul>
  )
}