import React, { CSSProperties } from "react";

import "./index.scss";
import {TextField} from "@mui/material";

export type BaseInputType<T extends HTMLInputElement | HTMLTextAreaElement = any> = {
  label?: string | React.ReactNode;
  labelColor?: string;
  boxColor?: string;
  placeholder?: string;
  secondaryPlaceholder?: boolean;
  textColor?: string;
  margin?: boolean;
  noBorder?: boolean;

  height?: string;
  width?: string;

  value?: string | number;
  defaultValue?: string;
  onChange?: React.ChangeEventHandler<T>;

  icon?: string;
  iconPos?: string;
  ownIcon?: boolean;

  textarea?: boolean;

  className?: string;
  disabled?: boolean;

  inputClassName?: string;

  style?: CSSProperties
};

export default function BaseInput<T extends HTMLInputElement | HTMLTextAreaElement = any>({
  label,
  labelColor,
  boxColor,
  placeholder,
  secondaryPlaceholder,
  textColor,
  margin,
  noBorder,
  height,
  width,
  value,
  defaultValue,
  onChange,
  icon,
  iconPos,
  ownIcon,
  textarea,
  className,
  disabled,

  style,
  inputClassName
}: BaseInputType<T>){
  return (
    <div
      className={`input-div w-full ${className}`}
      style={{ marginTop: margin ? "24px" : "0px", position: "relative" }}
    >
      {label && (
        <p
          className="label mb-2 text-md-semibold text-[#686868]"
          style={{ color: labelColor ? labelColor : "" }}
        >
          {label}
        </p>
      )}

      <div style={{ position: "absolute", bottom: "17%", right: "20px" }}>
        {iconPos == "left" && <img src={icon} alt="" />}
      </div>

      {!textarea && !disabled &&  (
        <input
          className={`input text-md-light ${
            secondaryPlaceholder ? "placeholder-secondary" : ""
          } ${inputClassName}`}
          placeholder={placeholder}
          style={{
            height: height,
            width: width,
            color: `${textColor ? textColor : ""}`,
            backgroundColor: boxColor,
            border: `${noBorder ? "none" : "1px solid #CACACA"}`,
            ...style
          }}
          value={value}
          onChange={onChange as React.ChangeEventHandler<HTMLInputElement> | undefined}
        />
      )}

      {textarea && !disabled && (
        <textarea
          name=""
          className={`textarea text-md-light ${inputClassName}`}
          placeholder={placeholder}
          style={{ height: height, width: width, ...style }}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange as React.ChangeEventHandler<HTMLTextAreaElement> | undefined}
          cols={80}
        ></textarea>
      )}

      <div style={{ position: "absolute", bottom: "17px", right: "20px" }}>
        {iconPos == "right" && <img src={icon} alt="" />}
      </div>

      {disabled && (
          <input
              // className={`input text-md-light ${
              //     secondaryPlaceholder ? "placeholder-secondary" : ""
              // }`}
              // placeholder={placeholder}
              style={{
                height: height,
                width: width,
                // color: `${textColor ? textColor : ""}`,
                // backgroundColor: boxColor,
                border: `${noBorder ? "none" : "1px solid #CACACA"}`,
              }}
              disabled={true}
          />
      )}
    </div>
  );
}