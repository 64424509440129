import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useGetModuleCollectionsQuery } from "@redux/features/collections/collectionsApi";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseInput from "@reusables/BaseInput";
import BaseButton from "@reusables/BaseButton";
import TableButton from "@reusables/BaseTable/TableButton";
import { Collapse } from "@mui/material";
import CollectionSelector from "./components/CollectionSelector";
import { cn } from "@helpers/utils";

const formScheme = z.object({
    collection: z.object({
        id: z.number(),
        code: z.string(),
        name: z.string(),
        barcode: z.string().nullish(),
        totalQuantity: z.number(),
        products: z.array(
            z.object({
                id: z.number(),
                name: z.string(),
                code: z.string(),
                in_stock: z.number().nullish(),
                prices: z.object({
                    selling_price: z.number(),
                    purchase_price: z.number()
                }),
                min_purchase_quantity: z.number(),
                min_sale_quantity: z.number(),
                is_service: z.boolean(),

                tax: z.object({
                    id: z.number(),
                    rate: z.number()
                }).nullish(),

                has_bom: z.boolean(),
                is_component: z.boolean(),

                quantity: z.number()
            })
        )
    }),
    quantity: z.coerce.number().min(0)
});

export type FormTyping = z.infer<typeof formScheme>;

export interface AddCollectionModalProps {
    isOpen: boolean;
    onClose: () => void;
    onLineAddition: (chosenCollection: FormTyping) => void;
}

export default function AddCollectionModal({
                                               isOpen,
                                               onLineAddition,
                                               onClose
                                           }: AddCollectionModalProps): JSX.Element {
    const { t } = useTranslation("", {
        keyPrefix: "sales.orders.general.orders.modals.addCollection"
    });

    const { data: collectionOptions, isLoading: collectionOptionsLoading } =
        useGetModuleCollectionsQuery("sale");

    const { control, ...form } = useForm<FormTyping>({
        resolver: zodResolver(formScheme)
    });

    // State to track which collection is being viewed
    const [viewCollection, setViewCollection] = useState<number | null>(null);

    const onSubmit = form.handleSubmit((data) => {
        onLineAddition(data);
        form.reset();
        onClose();
    }, console.error);

    return (
        <>
            <BaseModal
                isOpen={isOpen}
                onClose={onClose}
                isLoading={collectionOptionsLoading}
                width={900}
                padding="56px"
            >
                <form className="space-y-[32px]" onSubmit={onSubmit}>
                    <div className="modal-title">{t("heading")}</div>
                    <BaseInputsGrid cols={1}>
                        <Controller
                            name="collection"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseDropdown
                                    {...field}
                                    {...fieldState}
                                    label={t("fields.collection.label") + " *"}
                                    isLoading={collectionOptionsLoading}
                                    placeholder={t("fields.collection.placeholder")}
                                    options={collectionOptions}
                                    onChange={(e) => {
                                        setViewCollection(null);
                                        field.onChange(e);
                                    }}
                                    getter={{
                                        label: (opt) => opt.name,
                                        key: (opt) => opt.id,
                                        renderOption: (opt) => (
                                            <div className="flex flex-col">
                                                <div className="flex w-full justify-between items-center">
                                                    <div>{opt.name}</div>
                                                    <TableButton
                                                        onClick={(e: any) => {
                                                            // Prevent dropdown selection
                                                            e.stopPropagation();
                                                            // Toggle the collection view
                                                            setViewCollection((prev) =>
                                                                prev === opt.id ? null : opt.id
                                                            );
                                                        }}
                                                        className={cn(
                                                            "border-[1px] border-solid transition-all", 
                                                            viewCollection === opt.id && "bg-white !border-purple-400"
                                                        )}
                                                    >
                                                        {viewCollection === opt.id
                                                            ? t("selector.buttons.hideDetails")
                                                            : t("selector.buttons.showMore")
                                                        }
                                                    </TableButton>
                                                </div>
                                                {/* Show CollectionSelector only for the current option */}
                                                    <Collapse in={viewCollection === opt.id}>
                                                        <CollectionSelector
                                                            products={opt.products}
                                                        />
                                                    </Collapse>
                                            </div>
                                        )
                                    }}
                                />
                            )}
                        />

                        <Controller
                            name="quantity"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.quantity.label")}
                                    placeholder={t("fields.quantity.placeholder")}
                                />
                            )}
                        />
                    </BaseInputsGrid>
                    <BaseButton text={t("buttons.add")} size="md" className="w-full" />
                </form>
            </BaseModal>
        </>
    );
}
