import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import BaseButton from "../../../../../../reusables/BaseButton";
import BaseInput from "../../../../../../reusables/BaseInputOld";

// type Props = {};
// {}: Props

function AddUser() {
  const history = useHistory();

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const redirectToUsers = () => {
    history.push('/dashboard/settings/general/users');
  };

  return (
    <div className="form-card">
      <div>
        <BaseButton
          text="Go back"
          textColor="white"
          size="sm"
          icon="arrow_back"
          iconPos="left"
          height="45px"
          onClick={redirectToUsers}
        />
      </div>
      <div className="d-flex justify-content-center">
        <div>

          <p className="text-lg-semibold color-4 mt-5">Add new user</p>

          <div>
            <BaseInput
              label={"Name"}
              labelColor={"black"}
              placeholder={""}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="my-3"
            />

            <BaseInput
              label={"Email"}
              labelColor={"black"}
              placeholder={""}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="my-3"
            />

            <BaseInput
              label={"Password"}
              labelColor={"black"}
              placeholder={""}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="my-3"
            />

            {/* // TODO: After implementing stripe payment gateway, add subscription here */}
            <p>Subscription selection will be here</p>

            <BaseButton
              text="Create"
              color="#7556FA"
              textColor="white"
              size="sm"
              buttonWidth="150px"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
