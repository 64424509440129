import React from "react";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInputLegacy";
import { useTranslation } from "react-i18next";
import { Transfer } from "@/types/general";

type ViewLayoutProperties = {
  transfer: Transfer.Root
}

export default function ViewLayout({ transfer }: ViewLayoutProperties) {
  const { t } = useTranslation("", { keyPrefix: "inventory.stockTransfers.stockTransfer" });

  return (
    <>
      <div className="space-y-[40px]">
        <BaseInputsGrid>
          <InputLikeContainer
            label={t("fields.product.label")}
            content={transfer.product.name}
          />

          <InputLikeContainer
            label={t("fields.fromLocation.label")}
            content={transfer.location_from.store}
          />

          <InputLikeContainer
            label={t("fields.section.label")}
            content={transfer.section_from?.name ?? "-"}
          />

          <InputLikeContainer
            label={t("fields.toLocation.label")}
            content={transfer.location_to.name}
          />

          <InputLikeContainer
            label={t("fields.section.label")}
            content={transfer.section_to?.name ?? "-"}
          />

          <InputLikeContainer
            label={t("fields.date.label")}
            content={transfer.date.format("YYYY-MM-DD")}
          />

          <BaseInput
            label={t("fields.availableQuantity.label")}
            value={transfer.old_quantity.toString()}
            disabled
          />

          <InputLikeContainer
            label={t("fields.transferQuantity.label")}
            content={transfer.quantity.toString()}
          />
        </BaseInputsGrid>

        <InputLikeContainer
          label={t("fields.remarks.label")}
          content={transfer.remarks ?? "-"}
        />
      </div>
    </>
  );
}


function InputLikeContainer({ label, content }: { label: string, content: JSX.Element | string | undefined }) {
  return (
    <div>
      <div className="font-semibold text-inputs-label-dim mb-[24px]">{label}</div>
      <div className="text-accent">
        {content}
      </div>
    </div>
  );
}