import React from "react";
import { useTranslation } from "react-i18next";
import { ViewLayoutProps } from "./index";
import BaseBadge from "@reusables/BaseBadge";
import { Collapse, Stack } from "@mui/material";
import { BaseInputLikeContainer } from "@reusables/BaseInputLikeContainer";
import { ReactComponent as CircleChevronSVG } from "@assets/icons/ic_expand_down_circled.svg";
import { cn } from "@helpers/utils";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseTooltip from "@reusables/BaseTooltip";

export default function PickingShipmondo({ picking }: ViewLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "sales.picking.shipmondo" });

    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);

    if (!picking.shipmondo)
        return (
            <div className="levitation-extended">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <div className="flex space-x-3">
                        <div className="text-xl font-semibold text-accent">{t("heading")}</div>
                        <BaseBadge className={"bg-purple-400 text-white"}>
                            {t("integrationBadge")}
                        </BaseBadge>
                    </div>
                    <BaseTooltip title={t("shipmondoNotUsed") + ""}>
                        <CircleChevronSVG className={"text-[#D7D4DA]"} />
                    </BaseTooltip>
                </Stack>
            </div>
        );

    return (
        <div className="levitation-extended">
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <div className="flex space-x-3">
                    <div className="text-xl font-semibold text-accent">{t("heading")}</div>
                    <BaseBadge className={"bg-purple-400 text-white"}>
                        {t("integrationBadge")}
                    </BaseBadge>
                </div>
                <CircleChevronSVG
                    onClick={() => void setIsCollapsed(prevState => !prevState)}
                    className={cn("cursor-pointer text-accent transition-[.25s]")}
                    style={{ transform: !isCollapsed ? "rotate(0deg)" : "rotate(180deg)" }}
                />
            </Stack>

            <Collapse in={isCollapsed}>
                <div className="mt-[24px]">
                    <BaseInputsGrid cols={{
                        lg: 3,
                        sm: 2
                    }}>
                        <BaseInputLikeContainer
                            label={t("fields.carrier.label")}
                            value={picking.shipmondo?.carrier?.name ?? "N/A"}
                        />

                        <BaseInputLikeContainer
                            label={t("fields.product.label")}
                            value={picking.shipmondo?.product?.name ?? "N/A"}
                        />

                        <BaseInputLikeContainer
                            label={t("fields.service.label")}
                            value={
                                <div className="flex flex-row items-center space-x-[8px]">
                                    {picking.shipmondo?.services.map(service => (
                                        <BaseBadge className={"bg-[#F3F2EF]"} key={service.code}>
                                            {service.name}
                                        </BaseBadge>
                                    )) ?? t("fields.services.noServices")}
                                </div>
                            }
                        />
                    </BaseInputsGrid>
                </div>
            </Collapse>
        </div>
    );
}