import React from 'react'
import BaseButton from '@reusables/BaseButton';
import BaseModal from '@reusables/Modals/BaseModal';
import {Trans, useTranslation} from 'react-i18next';
import {useApproveCountMutation} from '@redux/features/stockCount/stockCountApi';
import {toast} from 'react-toastify';
import {StockCount} from '@/types/general';
import _ from 'lodash';

interface ApproveModalProperties {
    isOpen: boolean;
    onClose: () => void;
    report?: StockCount.Root;
}

export default function ApproveModal(props: ApproveModalProperties) {
    const {t} = useTranslation("", {keyPrefix: "inventory.stockCount.modals.approve"});

    const [approveReport, {isLoading: isApprovingLoading}] = useApproveCountMutation();

    const worker = _.join([props.report?.user.first_name, props.report?.user.last_name], " ");

    return (
        <BaseModal 
            isOpen={props.isOpen} 
            onClose={props.onClose} 
            width={700}
            padding="56px"
            isLoading={isApprovingLoading}
            >
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent font-thin">
                    <Trans
                        i18nKey="subheading" t={t}
                        components={{
                            bold: <span className="font-semibold" />,
                        }}
                        values={{
                            reportId: props.report?.id,
                            worker
                        }}
                    />
                </div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose} />
                    <BaseButton
                        text={t("buttons.confirm")}
                        size="md"
                        onClick={() => {
                            if (props.report) {
                                approveReport(props.report.id)
                                    .unwrap()
                                    .then(() => {
                                        toast.success(t("responses.success"));
                                    })
                                    .catch((e) => {
                                        console.error(e);

                                        toast.error(t("responses.error"));
                                    })
                                    .finally(() => props.onClose());
                            }
                        }}
                    />
                </div>
            </div>
        </BaseModal>
    )
}
