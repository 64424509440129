import {useTranslation} from 'react-i18next';
import {useDeleteCategoryMutation} from '@redux/features/categories/categoriesApi';
import {Category} from '@/types/general';
import BaseModal from '@reusables/Modals/BaseModal';
import BaseButton from '@reusables/BaseButton';
import {toast} from 'react-toastify';

interface DeletionModalProperties {
    isOpen: boolean;
    onClose: () => void;
    category?: Category.Root;
}

export default function DeletionModal(props: DeletionModalProperties) {
    const {t} = useTranslation("", {keyPrefix: "settings.general.categories.modals.deletion"});

    const [deleteCategory, {isLoading: isDeletionLoading}] = useDeleteCategoryMutation();

    return (
        <BaseModal 
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
            isLoading={isDeletionLoading}
        >
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">{t("subheading")}</div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton 
                        text={t("buttons.cancel")}
                        size="md"
                        primaryOutlined
                        onClick={props.onClose}
                    />
                    <BaseButton 
                        text={t("buttons.delete")}
                        size="md"
                        onClick={() => {
                            if(props.category) {
                                deleteCategory(props.category.id).unwrap().then(() => {
                                    toast.success(t("responses.success"));
                                }).catch(e => {
                                    console.error(e);
                                    toast.error(t("responses.error"));
                                }).finally(() => props.onClose());
                            }
                        }}
                    />
                </div>
            </div>
        </BaseModal>
    )
}
