import React, { PropsWithChildren } from "react";
import { CircularProgress } from "@mui/material";

export function BaseLoadingBlocker({children, active, useSolidBackground}: PropsWithChildren<{ active?: boolean, useSolidBackground?: boolean }>) {
    return (
        <>
            {
                <div className="relative">
                    <div
                        className="absolute justify-center items-center w-full h-full top-0 left-0 rounded-2xl bg-card bg-opacity-60 z-[100] transition-[.25s]"
                        style={{
                            ...(!useSolidBackground ? {
                                backdropFilter: "blur(2px)",
                            }: {
                                background: "#fff",
                            }),
                            display: active ? "flex" : "none",
                        }}
                    >
                        <CircularProgress
                            sx={{
                                transform: "translateY(-50%)",
                            }}
                            color={"primary"}
                        />
                    </div>
                    {children}
                </div>
            }
        </>
    )
}