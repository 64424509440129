import React, { useEffect } from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import { Trans, useTranslation } from "react-i18next";
import BaseBadge from "@reusables/BaseBadge";
import BaseButton from "@reusables/BaseButton";
import { toast } from "react-toastify";
import { useAssignRoleMutation, useGetRolesQuery } from "@/redux/features/roles/rolesApi";
import { toastError } from "@helpers/utils";
import { z } from "zod";
import { useGetUsersQuery } from "@redux/api/internalApiSlice";
import { useAppSelector } from "@redux/hooks";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";

interface RoleAssignmentModal {
    isOpen: boolean;
    onClose: () => void;
    role_id?: number;
}

const assignmentScheme = z.object({
    users: z.array(
        z.object({
            id: z.number(),
            first_name: z.string().nullish(),
            last_name: z.string().nullish(),
            email: z.string()
        })
    ).min(1)
});

type AssignmentFormTyping = z.infer<typeof assignmentScheme>;

export default function RoleAssignmentModal({ isOpen, onClose, role_id }: RoleAssignmentModal) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.roles.modals.roleAssignment" });

    const { control, handleSubmit, reset } = useForm<AssignmentFormTyping>({
        resolver: zodResolver(assignmentScheme),
        defaultValues: {
            users: []
        }
    });

    // Getting current user data
    const currentUser = useAppSelector(state => state.auth?.user);

    // Getting method to assign role
    const [assignRole, { isLoading }] = useAssignRoleMutation();

    // Selecting role by id
    const { role } = useGetRolesQuery(undefined, {
        selectFromResult: result => ({
            role: result.data?.find(r => r.id === role_id)
        })
    });

    // Selecting available users
    const { users = [], isLoading: isUsersLoading } = useGetUsersQuery(undefined, {
        selectFromResult: result => {
            const roleUsersIds = role?.users.map(x => x.id) ?? [];

            return {
                users: result.data?.filter(user => user.id !== currentUser?.id && !user.is_ceo && !roleUsersIds.includes(user.id)),
                isLoading: result.isLoading
            };
        },
        skip: !role
    });

    useEffect(() => {
        reset();
    }, [role_id]);

    if (!role) {
        return null;
    }

    const onSubmit = handleSubmit(data => {
        assignRole({ role_id: role.id, users: data.users.map(x => x.id) })
            .unwrap()
            .then(resp => {
                toast.success(t("responses.success"));
            })
            .catch(e => {
                console.error(e);
                toastError(e);
            })
            .finally(() => {
                onClose();
                reset();
            });
    });

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            width={700}
            padding="56px"
            isLoading={isLoading}
        >
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">
                    <Trans
                        t={t}
                        i18nKey="subheading"
                        values={{ roleName: role.name }}
                        components={{
                            badge: <BaseBadge className={"px-2 bg-[#D9DAFD]"} />
                        }}
                    />
                </div>
                <div>
                    <Controller
                        name={"users"}
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseDropdown
                                {...field}
                                {...fieldState}

                                onChange={(_, values) => field.onChange(values)}

                                label={t("fields.users.label")}
                                placeholder={t("fields.users.placeholder")}

                                options={users}
                                getter={{
                                    key: opt => opt.id,
                                    label: opt => opt.first_name + " " + opt.last_name,
                                    caption: opt => opt.email
                                }}

                                isLoading={isUsersLoading}

                                multiple
                                autocomplete
                                virtualize
                            />
                        )}
                    />
                </div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} type="button" size="md" primaryOutlined onClick={onClose} />
                    <BaseButton
                        text={t("buttons.confirm")}
                        size="md"
                        type={"submit"}
                    />
                </div>
            </form>
        </BaseModal>
    );
}