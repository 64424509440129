import { Skeleton } from "@mui/material";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import React from "react";
import { useTranslation } from "react-i18next";

export default function SkeletonLayout(){
    const { t } = useTranslation("", { keyPrefix: "inventory.stockTransfers.stockTransfer" });

    return (
      <div className="space-y-[40px] mt-[40px]">
          <BaseInputsGrid>
              {/*  PRODUCT */}
              <InputSkeleton />

              {/*  FROM LOCATION */}
              <InputSkeleton />

              {/*  FROM SECTION */}
              <InputSkeleton />

              {/* TO LOCATION */}
              <InputSkeleton />

              {/*  TO SECTION */}
              <InputSkeleton />

              {/*  DATE  */}
              <InputSkeleton />

              {/*  AVAILABLE QUANTITY */}
              <InputSkeleton />

              {/*  TRANSFER QUANTITY */}
              <InputSkeleton />

              {/* REMARKS */}
              <div>
                  <Skeleton variant="text" className="text-base" width={64} sx={{marginBottom: "8px"}}/>
                  <Skeleton variant="rectangular" width="100%" height={56} />
              </div>
          </BaseInputsGrid>
      </div>
    )
}

function InputSkeleton(){
    return (
      <div>
          <Skeleton variant="text" className="text-base" width={64} sx={{marginBottom: "8px"}}/>
          <Skeleton variant="rectangular" width="100%" height={56} />
      </div>
    )
}