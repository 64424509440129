import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import BaseButton from "@reusables/BaseButton";
import BaseModal from "@reusables/Modals/BaseModal";
import BaseInput from "@reusables/BaseInput";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";

import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useGetRolesQuery } from "@redux/features/roles/rolesApi";
import { useInviteUserMutation, useUpdateUserMutation } from "@redux/features/users/usersApi";
import { toast } from "react-toastify";
import { Users } from "@/types/general";
import { toastError } from "@helpers/utils";
import { Shield } from "lucide-react";

interface MutationModalProperties {
    isOpen: boolean;
    onClose: () => void;
    user?: Users.Root;
}

const formScheme = z.object({
    first_name: z.string().trim(),
    last_name: z.string().trim(),
    email: z.string().trim().email(),
    role_id: z.number(),
    password: z.string().trim().optional()
});

type FormTyping = z.infer<typeof formScheme>

export default function MutationModal(props: MutationModalProperties) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.users.modals.mutation" });

    const { data: roleOptions = [], isLoading: roleOptionsLoading } = useGetRolesQuery();

    //Getting RTK Query hooks for saving data
    // const {data: user, isLoading: isSelectedUserLoading} = useGetUserDataQuery(props.user ?? 0);
    const [inviteUser, { isLoading: isInviteUserLoading }] = useInviteUserMutation();
    const [updateUser, { isLoading: isUpdateUserLoading }] = useUpdateUserMutation();

    const { control, handleSubmit, setValue, reset, formState: { errors } } = useForm<FormTyping>({
        resolver: zodResolver(formScheme)
    });

    useEffect(() => {
        if (props.isOpen) {
            reset();

            if (props.user) {
                console.log("user is present");
                setValue("first_name", props.user.first_name);
                setValue("last_name", props.user.last_name);
                setValue("email", props.user.email);
                setValue("role_id", props.user.role.id);
            }
        }
    }, [props.isOpen, reset, props.user]);

    const onSubmit = handleSubmit((data) => {
        if (data.password && data.password.length < 8) {
            toast.error(t("responses.passwordLength"));
            return;
        }

        if (!props.user && !data.password) {
            toast.error(t("responses.invite.noPassword"));
            return;
        }

        if (props.user) {
            const payload = {
                id: props.user.id,
                first_name: data.first_name,
                last_name: data.last_name,
                role: data.role_id,

                // Include email in payload if it's different from the initial email
                ...(data.email !== props.user?.email && {
                    email: data.email
                }),

                ...(data.password && {
                    password: data.password
                })
            };

            updateUser(payload as (typeof payload & { password: string })).unwrap()
            .then(() => {
                toast.success(t("responses.update.success"));
                props.onClose();
            })
            .catch((err) => {
                console.log(err);
                toastError(err);
            });
        } else {
            inviteUser({
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                role: data.role_id,
                password: data.password as string
            }).unwrap()
            .then(() => {
                toast.success(t("responses.invite.success"));
                props.onClose();
            })
            .catch((err) => {
                console.log(err);
                toastError(err);
            });
        }
    });

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={562}
            padding="56px"
            useCloseIcon
            isLoading={isInviteUserLoading || isUpdateUserLoading}
        >
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent">
                    {props.user ? `${props.user?.first_name} ${props.user?.last_name}` : t("creationHeading")}
                </div>

                <Controller
                    control={control}
                    name="first_name"
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("fields.firstName.label") + " *"}
                            placeholder={t("fields.firstName.placeholder")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="last_name"
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("fields.lastName.label") + " *"}
                            placeholder={t("fields.lastName.placeholder")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="email"
                    render={({ field, fieldState }) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("fields.email.label") + " *"}
                            placeholder={t("fields.email.placeholder")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="role_id"
                    render={({ field, fieldState }) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}

                            onChange={(value) => {
                                field.onChange(value?.id ?? null);
                            }}

                            label={t("dropdowns.role.label") + " *"}
                            placeholder={t("dropdowns.role.placeholder")}
                            options={roleOptions}
                            isLoading={roleOptionsLoading}

                            getter={{
                                key: (opt) => opt.id,
                                label: (opt) => opt.name,
                                renderOption: (opt, icon) => (
                                    <div className="flex flex-row justify-between">
                                        <div className={"flex flex-row items-center"}>
                                            {
                                                opt.immutable &&
                                              <Shield className={"mr-1"} size={12} />
                                            }
                                            <span>
                                            {
                                                opt.name
                                            }
                                            </span>
                                        </div>
                                        {
                                            opt.id === field.value &&
                                          <div>
                                              {icon}
                                          </div>
                                        }
                                    </div>
                                )
                            }}

                            autocomplete
                        />
                    )}
                />

                {props.user ? (
                    <Controller
                        control={control}
                        name="password"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.password.label")}
                                placeholder={t("fields.password.placeholder")}
                                type="password"
                            />
                        )}
                    />
                ) : (
                    <Controller
                        control={control}
                        name="password"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.password.label") + " *"}
                                placeholder={t("fields.password.placeholderNew")}
                                type="password"
                            />
                        )}
                    />
                )}


                <div>
                    <BaseButton
                        buttonWidth="100%"
                        text={props.user ? t("buttons.save") : t("buttons.invite")}
                        size="md"
                        type="submit"
                    />
                </div>

            </form>
        </BaseModal>
    );
}
