import BaseModal from "@reusables/Modals/BaseModal";
import {Unit} from "@/types/general";
import {z} from "zod";
import React, {useEffect} from "react";
import BaseButton from "@reusables/BaseButton";
import {Controller, useForm, useWatch} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import BaseInput from "@reusables/BaseInput";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import {
    useCreateUnitMutation as useCreateUnitMutation,
    useGetUnitsListQuery,
    useGetUnitsQuery,
    useUpdateUnitMutation
} from "@redux/features/units/unitsApi";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

interface MutationUnitModalProperties {
    isOpen: boolean;
    onClose: () => void;
    unit?: Unit.Root;
    availableUnits: Unit.Root[],
}

const formScheme = z.object({
    selectedUnit: z.object({
        key: z.number(),
        title: z.string(),
        code: z.string(),
    }),
    name: z.string().optional(),

});


type FormTyping = z.infer<typeof formScheme>

export default function MutationModal(props: MutationUnitModalProperties) {

    const {t} = useTranslation("", {keyPrefix: "settings.general.units.modals.mutation"});

    const {control, handleSubmit, setValue, reset} = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
    });


    useEffect(() => {
        if (props.isOpen) {
            if (props.unit) {
                setValue("name", props.unit.custom_name);
                setValue("selectedUnit", {key: props.unit.id, title: props.unit.name, code: props.unit.code});
            }
        } else {
            reset()
        }

    }, [props.isOpen]);


    // to get all available units
    const {data: unitsOptions = [], isLoading: unitsOptionsLoading} = useGetUnitsListQuery();

    // to get available company-adopted units
    const {data: companyUnitsOptions = [], isLoading: companyUnitsLoading} = useGetUnitsQuery();

    // Getting RTK Query hooks for saving data
    const [createUnit, {isLoading: isCreationLoading}] = useCreateUnitMutation();
    const [updateUnit, {isLoading: isUpdateLoading}] = useUpdateUnitMutation();


    function filterAvailableUnits(): Unit.Public[] {
        const rootIds: Set<number> = new Set(companyUnitsOptions.map(model => model.id));

        return unitsOptions.filter(model => !rootIds.has(model.id))
    }


    const unitSymbol = useWatch({
        control,
        name: "selectedUnit.code" //TODO: WILL BE REPLACED BY THE ACTUAL SHORT VERSION
    })


    const onSubmit = handleSubmit((data) => {
        if (props.unit) {
            updateUnit({
                custom_name: data.name,
                id: data.selectedUnit.key,
            }).then(() => {
                toast.success(t("responses.update.success"));
                props.onClose();
            }).catch(() => {
                toast.error(t("responses.update.error"));
            });
        } else {
            createUnit({
                custom_name: data.name,
                unit_id: data.selectedUnit.key,
            }).then(() => {
                toast.success(t("responses.creation.success"));
                props.onClose();
            }).catch(() => {
                toast.error(t("responses.creation.error"));
            });
        }
    });
    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={562}
            padding="56px"
            useCloseIcon
            isLoading={isCreationLoading || isUpdateLoading}
        >
            <form className="space-y-[32px]" onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent">
                    {props.unit ? t("editHeading") : t("creationHeading")}
                </div>


                <Controller
                    disabled={!!props.unit}
                    name={"selectedUnit"}
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            error={fieldState.error}
                            label={t("fields.name.label") + " *"}

                            options={filterAvailableUnits().map((e) => {
                                return {key: e.id, title: e.name, code: e.code}
                            })}

                            getter={{
                                key: opt => opt.key,
                                label: opt => opt.title
                            }}

                            isLoading={unitsOptionsLoading || companyUnitsLoading}
                        />
                    )}
                />

                <BaseInput
                    label={t("fields.symbol.label")}
                    value={unitSymbol}
                    disabled
                />


                <Controller
                    name={"name"}
                    control={control}

                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            label={t("fields.customName.label")}
                            type={"string"}
                        />
                    )}
                />


                <div>
                    <BaseButton
                        buttonWidth="100%"
                        text={t("buttons.save")}
                        size="md"
                        type={"submit"}
                    />
                </div>
            </form>
        </BaseModal>
    );
}