import React from "react";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import Fade from "@mui/material/Fade/Fade";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {PurchaseOrdersFormTyping} from "../../../types";
import {useTranslation} from "react-i18next";
import {Company} from "@/types/general";
import CreateDeliveryAddressModal from "./CreateDeliveryAddressModal";

type DeliveryAddressInputProps = {
    options: Company.Components.DeliveryAddress[];
    isLoading: boolean;
}

export default function DeliveryAddressInput(props: DeliveryAddressInputProps) {
    const {t} = useTranslation("", {keyPrefix: "purchaseOrder.general.dropdowns.deliveryAddress"});

    const {control, setValue, watch} = useFormContext<PurchaseOrdersFormTyping>();

    const isTheSameBillingForDelivery = useWatch({
        control,
        name: "is_billing_for_delivery"
    });

    const [isCreateDeliveryAddressModalOpen, setIsCreateDeliveryAddressModalOpen] = React.useState(false);

    return (
        <>
            <Fade in={!isTheSameBillingForDelivery}>
                <div>
                    <Controller
                        name={"delivery_address"}
                        control={control}
                        render={({field, fieldState}) => (
                            <BaseDropdown
                                {...field}
                                {...fieldState}

                                label={`${t("label")} *`}
                                placeholder={t("placeholder")}
                                options={props.options}
                                getter={{
                                    label: opt => opt.name,
                                    key: opt => opt.id,
                                    caption: opt => opt.city
                                }}

                                action={{
                                    title: t("buttons.create"),
                                    onClick: () => setIsCreateDeliveryAddressModalOpen(true)
                                }}

                                autocomplete
                                virtualize
                            />
                        )}
                    />
                </div>
            </Fade>

            <CreateDeliveryAddressModal
                isOpen={isCreateDeliveryAddressModalOpen}
                onClose={() => setIsCreateDeliveryAddressModalOpen(false)}
                onCreation={delivery => {
                    setValue("delivery_address", delivery);
                    setIsCreateDeliveryAddressModalOpen(false);
                }}
            />
        </>
    )
}