import React from "react";
import CodeIcon from '@mui/icons-material/Code';

export default function BaseUnderconstruct({text, icon}: {text?: string, icon?: JSX.Element}){
  return (
    <div className="bg-gray-100 rounded-[12px] flex flex-column items-center justify-center w-full h-[600px] text-gray-600 bg-opacity-70">
        {
            icon ??
            <CodeIcon className="mb-[10px]"/>
        }
      <span>{text ?? "Page is under development"}</span>
    </div>
  )
}