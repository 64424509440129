import React from "react";
import BaseModal from "@reusables/Modals/BaseModal";
import { ReactComponent as SuccessSVG } from "@assets/icons/ic_success.svg";
import BaseButton from "@reusables/BaseButton";
import { useTranslation } from "react-i18next";
import { ProductionSuccessData } from "@components/Dashboard/pages/BOM/components/modals/ProduceModal/types";
import BaseBadge from "@/components/reusables/BaseBadge";

interface ProduceSuccessModalProps {
    isOpen: boolean;
    data?: ProductionSuccessData;
    onClose: () => void;
}

export default function ProduceSuccessModal({ isOpen, onClose, data }: ProduceSuccessModalProps) {
    const { t } = useTranslation("", { keyPrefix: "bom.produceModalSuccess" });

    return (
        <BaseModal
            isOpen={isOpen}
            padding="56px"
            onClose={onClose}
            width={512}
        >
            <div className="flex flex-col items-center space-y-8">
                <div>
                    <SuccessSVG  />
                </div>
                <div className="text-4xl font-bold text-center text-accent">{t("heading")}</div>
                <p className="text-center">{t("subheading")}</p>
                <div className="font-semibold text-accent flex items-center space-x-[8px]"><span>{data?.product.name}</span>: <BaseBadge className={"bg-badge-blue"}>{data?.quantity}</BaseBadge></div>
                <BaseButton
                    text={t("button")}
                    size="md"
                    onClick={() => {
                        onClose();
                    }}
                />
            </div>
        </BaseModal>
    )
}
