type LocCodes = "no_sec" | "no_loc" | "allow"
export class LocDropdownDisable {
    private readonly code: LocCodes;
    private readonly reason: string | undefined;

    constructor(code: LocCodes, reason?: string) {
        this.code = code;
        this.reason = reason;
    }

    isDisabled() {
        return this.code === "no_sec" || this.code === "no_loc"
    }

    getReason(){
        return this.reason ?? "";
    }
}

export type TransferFields = "product" | "from_location" | "to_location" | "from_section" | "to_section" | "tr_qty"