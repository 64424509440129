import { Dialog, DialogContent, Fade, Icon, IconButton, Slide, SxProps } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { useTheme } from "@mui/material/styles";
import "./index.scss";
import { TransitionProps } from "@mui/material/transitions";

type BaseModalProperties = {
    width?: string | number;
    position?: "top" | "center";
    padding?: string;
    useCloseIcon?: boolean;
    closeIconClass?: string;

    isOpen?: boolean;
    onClose?: () => void;
    sx?: SxProps;
    keepMounted?: boolean;
    isLoading?: boolean;
    borderRadius?: string | number;
    height?: string | number;
    mainTitle?: JSX.Element;
    mainFooter?: JSX.Element;
};

export default function BaseModal({
                                      children,

                                      width,
                                      position = "top",
                                      onClose,
                                      isOpen = false,
                                      useCloseIcon = false,
                                      padding,
                                      sx,
                                      keepMounted = true,
                                      isLoading = false,
                                      borderRadius = "20px",
                                      height,
                                      mainTitle,
                                      mainFooter,
                                      closeIconClass = "top-[30px] right-[56px]"
                                  }: PropsWithChildren<BaseModalProperties>) {

    const theme = useTheme();

    return (
        <Dialog
            TransitionComponent={Transition}
            open={isOpen}
            onClose={onClose}
            sx={{
                ".MuiDialog-scrollPaper": {
                    alignItems: position == "center" ? "center" : "flex-start"
                },
                ".MuiPaper-root > div": {
                    overflowY: "auto"
                },
                ".MuiPaper-root": {
                    overflowY: "hidden"
                },
                "&": {
                    ...sx as any
                }
            }}
            PaperProps={{
                style: {
                    borderRadius: borderRadius,
                    boxShadow: "none",
                    width: width,
                    maxWidth: "unset",
                    position: "relative",
                    height: height
                }
            }}
            BackdropProps={{
                style: {
                    backgroundColor: theme.custom.modalBackdrop,
                    opacity: "0.20"
                }
            }}
            keepMounted={keepMounted}
        >
            <div>
                {mainTitle}
                <Fade in={!isLoading}>
                    <DialogContent sx={{ padding: padding }}>
                        {
                            useCloseIcon ?
                                <div className={("absolute " + closeIconClass)}>
                                    <IconButton sx={{ color: (theme) => theme.custom.modal.icon }}
                                                onClick={(e) => onClose?.()}>
                                        <Icon sx={{ fontSize: "1.8rem" }}>close</Icon>
                                    </IconButton>
                                </div>
                                : null
                        }
                        {children}
                    </DialogContent>
                </Fade>
                <Fade in={isLoading}>
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                        <span className="loader"></span>
                    </div>
                </Fade>
                {mainFooter}
            </div>
        </Dialog>
    );
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} timeout={{ enter: 350, exit: 350 }} />;
});
