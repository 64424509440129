import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import BaseInput from "@reusables/BaseInput";
import BaseInputsGrid from "@reusables/BaseInputsGrid";

import {ReactComponent as TrashSVG} from "@assets/icons/ic_trash.svg";
import BaseButton from "@reusables/BaseButton";
import {TabProperties} from "../../types";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {CustomerFormTyping} from "./TabsController";
import BasePhoneInput from "@reusables/BasePhoneInput";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import {ReactComponent as PlusSVG} from "@assets/icons/ic_plus.svg";
import BaseCheckbox from "@reusables/BaseCheckbox";
import {Stack} from "@mui/material";
import FormErrorWarning from "../modals/FormErrorWarning";
import { Can } from "@/casl.config";

export default function GeneralTab({onNext, mode = "creation"}: TabProperties) {
    const { t } = useTranslation("", { keyPrefix: "customers.general.tabs.general" });

    const {control, setValue, getValues} = useFormContext<CustomerFormTyping>();

    const {fields: contacts, append, remove} = useFieldArray({
        control,
        name: "contacts",
        keyName: "loop_id"
    });

    const isPerson = useWatch({
        control,
        name: "is_person",
        defaultValue: false
    });

    useEffect(() => {
        if (getValues("is_person") === false && getValues("contacts")?.length === 0) {
            append({});
        }
        if (getValues("is_person") === true && getValues("person_contacts")?.length === 0) {
            append({});
        }
    }, [contacts]);

    return (
        <form className="space-y-[40px]" onSubmit={onNext}>
            <FormErrorWarning />

            <Stack
                direction={"row"}
                spacing={2}
            >
                <Controller
                    control={control}
                    name="is_person"
                    render={({field}) => (
                        <BaseCheckbox
                            value={!field.value}
                            onChange={val => {
                                if(val)
                                    field.onChange(!val);
                            }}

                            disabled={mode == "editing"}

                            label={t("checkboxes.isCompany")}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="is_person"
                    render={({field}) => (
                        <BaseCheckbox
                            value={field.value}
                            onChange={val => {
                                if(val)
                                    field.onChange(val);
                            }}

                            disabled={mode == "editing"}

                            label={t("checkboxes.isPerson")}
                        />
                    )}
                />
            </Stack>

            <BaseInputsGrid>

                <Controller
                    control={control}
                    name="name"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.customerName.label") + " *"}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="code"
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}

                            label={t("fields.customerCode.label")}

                            disabled={mode == "editing"}
                        />
                    )}
                />

                {!isPerson && (
                    <Controller
                        control={control}
                        name="registration_number"
                        render={({field, fieldState}) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}

                                label={t("fields.registrationNumber.label")}
                            />
                        )}
                    />
                )}
            </BaseInputsGrid>

            <Can I="contact_detail.view" a="customer">
                {
                    isPerson ? (
                        <BaseInputsGrid>
                            <Controller
                                    control={control}
                                    name={`person_contacts.0.phone`}
                                    render={({field, fieldState}) => (
                                        <BasePhoneInput
                                            {...field}
                                            {...fieldState}
                                            label={t('fields.phone.label')}
                                            size='md'
                                            variant='custom'
                                        />
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name={`person_contacts.0.email`}
                                    render={({field, fieldState}) => (
                                        <BaseInput
                                            {...field}
                                            error={fieldState.error}

                                            label={t("fields.email.label")}
                                            type={"email"}
                                        />
                                    )}
                                />
                        </BaseInputsGrid>
                    ) : (
                        contacts.map((contact, index) =>
                            <div key={contact.loop_id} className="space-y-[24px]">
                                {
                                    index === 0 ?
                                        <div className="flex items-center justify-between">
                                            <div className="text-xl text-accent">{t("categories.contactInfo")}</div>
                                            {
                                                !isPerson && (
                                                    <BaseMaterialIconButton
                                                        icon={<PlusSVG />}
                                                        onClick={() => append({})}
                                                    />
                                                )
                                            }
                                        </div>
                                        :
                                        <div className="flex items-center justify-between">
                                            <div className="text-xl text-accent">{t("categories.additionalContactInfo")}</div>
                                            <div className="text-gray-300 space-x-[10px] font-thin cursor-pointer hover:text-actionableIcons"
                                                onClick={() => {
                                                    if(contact.id != undefined){
                                                        setValue("deleted_contacts", [...getValues("deleted_contacts"), contact.id])
                                                    }

                                                    remove(index)
                                                }}>
                                                <TrashSVG />
                                                <span className="align-middle">{t("buttons.delete")}</span>
                                            </div>
                                        </div>
                                }
                                <div className="grid grid-cols-3 gap-x-[40px]">
                                    <Controller
                                        control={control}
                                        name={`contacts.${index}.name`}
                                        render={({field, fieldState}) => (
                                            <BaseInput
                                                {...field}
                                                error={fieldState.error}

                                                label={t("fields.contactName.label")}
                                            />
                                        )}
                                    />

                                    <Controller
                                        control={control}
                                        name={`contacts.${index}.phone`}
                                        render={({field, fieldState}) => (
                                            <BasePhoneInput
                                                {...field}
                                                {...fieldState}
                                                label={t('fields.phone.label')}
                                                size='md'
                                                variant='custom'
                                            />
                                        )}
                                    />

                                    <Controller
                                        control={control}
                                        name={`contacts.${index}.email`}
                                        render={({field, fieldState}) => (
                                            <BaseInput
                                                {...field}
                                                error={fieldState.error}

                                                label={t("fields.email.label")}
                                                type={"email"}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        )
                    )
                }
            </Can>

            <div className="flex justify-center">
                <BaseButton
                    text={t("buttons.next")}
                    size="md"
                    buttonWidth="200px"
                />
            </div>
        </form>
    );
}