import React from 'react'
import TabsController from './tabs/TabsController'
import NavigationBar from '../NavigationBar'
import { useRegisterMutation } from '@redux/features/auth/authApi';
import { isErrorWithMessage } from '@redux/api/query';
import { BaseLoadingBlocker } from '@reusables/blockers/BaseLoadingBlocker';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function SignUpPage() {
  const history = useHistory();

  const {t} = useTranslation("", {keyPrefix: "signupPage"});

  const [register, {isLoading}] = useRegisterMutation();

  return (
    <>
      <div className='flex justify-center'>
          <NavigationBar />
          <div className='mt-[160px] py-[20px] px-3 sm:px-0 sm:w-full md:w-5/6 lg:w-3/5 xl:w-1/2'>
            <BaseLoadingBlocker active={isLoading}>
              <TabsController 
                  onSubmit={(data) => {
                      register({
                        first_name: data.your_profile.first_name,
                        last_name: data.your_profile.last_name,
                        email: data.your_profile.email,
                        phone: data.your_profile.phone.number,
                        password: data.your_profile.password,
                        company_name: data.company_information.company_name,
                        registration_number: data.company_information.registration_number,
                        currency_id: data.company_information.currency.id,
                        country: data.company_information.country.id,
                        street: data.company_information.street,
                        street_2: data.company_information.street2 ?? undefined,
                        city: data.company_information.city,
                        zipcode: data.company_information.zip_code
                      }).unwrap().then(() => {
                        history.push('/verify-email', {email: data.your_profile.email});
                      }).catch((error) => {
                        if (isErrorWithMessage(error)) {
                          toast.error(error.message);
                        } else {
                            toast.error(t("responses.error"));
                        }
                      });
                  }}                    
              />
            </BaseLoadingBlocker>
          </div>
      </div>
    </>
  )
}
