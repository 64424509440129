import React, { useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { StepperLink } from "./types";

interface StepperProperties {
  defaultActive?: number;
  items: StepperLink[];
  className?: string;
}

export default function Stepper({ defaultActive=0, items, className }: StepperProperties) {
  // Custom animation configuration. Moving "back fade" to the relative position of clicked button, fully copying its size.
  const [active, setActive] = useState<number>(defaultActive);
  const [backFadeConfig, setBackFadeConfig] = useState<{left: number, width: number, height: number}>();
  const linksGroupRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const button = linksGroupRef?.current?.children.item(active) as HTMLElement | null;

    if(button != null){
      setBackFadeConfig({
        left: button.offsetLeft,
        width: button.offsetWidth + 1,
        height: button.offsetHeight
      });
    }
  }, [active]);

  return (
    <div className={`relative ${className ?? ""}`}>
      <div className="grid grid-flow-col bg-stepper-main rounded-[100px]" ref={linksGroupRef}>
        {
          items.map((item, index) => (
            <Link
              to={item.link}
              key={index}
              className={`flex justify-center items-center px-[32px] py-[20px] rounded-[100px] relative z-50 hover:text-stepper-main ${active === index ? "!text-stepper-selected" : "text-stepper-main"}`}

              onClick={() => {
                setActive(index);

                item.onClick?.();
              }}
            >
              {item.title}
            </Link>
          ))
        }
      </div>

      <div className="absolute bg-stepper-selected rounded-[100px] transition-[.25s] z-0 top-0" style={backFadeConfig}></div>
    </div>
  );
}