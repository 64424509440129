import React from "react";
import { Control, Controller, useWatch } from "react-hook-form";
import BaseInput from "@reusables/BaseInput";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProduceFormTyping } from "@components/Dashboard/pages/BOM/components/modals/ProduceModal/types";

type QuantityCellProps = {
    index: number;
    control: Control<ProduceFormTyping>;
}

export default function QuantityCell({ control, index }: QuantityCellProps) {
    const { t } = useTranslation("", { keyPrefix: "bom.produceModal" });
    
    const row = useWatch({
        control,
        name: `components_picks.${index}`
    });

    if (!row)
        return null;

    return (
        <>
            <Stack 
                spacing={"16px"}
            >
                {
                    row.sources?.map((source, sourceIndex) => (
                        <Stack
                            key={sourceIndex}
                            direction={"row"}
                            spacing={1}
                            alignItems="center"
                        >
                            <Box width={80}>
                                <Controller
                                    name={`components_picks.${index}.sources.${sourceIndex}.quantity`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <BaseInput
                                            {...field}
                                            error={fieldState.error}

                                            hideErrors
                                            type="number"

                                            sx={{
                                                ".MuiInput-input": {
                                                    padding: "9.5px"
                                                }
                                            }}
                                        />
                                    )}
                                />
                                
                            </Box>
                            <span>
                                {source.locations ? `${t("of")} ${source.locations.in_stock}` : ""}
                            </span>
                        </Stack>
                    )
                )}
            </Stack>
        </>
    );
}
