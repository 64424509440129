import React, { useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    genT,
    PredefinedTranslations,
    removeEmpty,
    useDatesRangeFilter,
    useOrdering,
    usePagination
} from "@helpers/utils";
import BaseAdminFilter from "@reusables/BaseAdminFilter";
import BaseTable from "@reusables/BaseTable";
import { ReactComponent as DetailsSVG } from "@assets/icons/ic_document_search.svg";
import { Receive } from "@/types/general";
import _ from "lodash";
import { useGetModuleSuppliersQuery } from "@redux/features/suppliers/suppliersApi";
import BaseDropdown from "@reusables/dropdowns/BaseDropdownLegacy";
import BaseDatepicker from "@reusables/BaseDatepickerLegacy";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import DeletionModal from "../components/modals/DeletionModal";
import { useGetReceiptsFullQuery } from "@redux/features/receipts/receiptsApi";
import { Pagination } from "@mui/material";
import { DatepickerRangeContext } from "@reusables/BaseDatepickerLegacy/context";
import { useAbility } from "@casl/react";
import { AbilityContext, Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function ReceiveMainPage() {
  const history = useHistory();
  const { t } = useTranslation("", { keyPrefix: "receive.main" });

  const ability = useAbility(AbilityContext);

  // Searching value with debounce
  const [searchingValue, setSearchingValue] = useState<string>();
  const searchInputDebounce = useCallback(_.debounce(setSearchingValue, 1000), [setSearchingValue]);

  // Loading suppliers for the dropdown
  const { data: suppliersOptions, isLoading: suppliersLoading } = useGetModuleSuppliersQuery("receive");
  const [selectedSuppliers, setSelectedSuppliers] = useState<NonNullable<typeof suppliersOptions>>([]);

  const {datesRange, setDatesRange, filterAdaptedDatesRange } = useDatesRangeFilter();

  // Table ordering
  const {orderBy, setOrderBy} = useOrdering<Receive.DTO.OrderBy>({"name": "id", "type": "desc"});

  const pagination = usePagination({ page: 1, limit: 8 })

  const filters= removeEmpty({
      search: searchingValue,
      suppliers: selectedSuppliers.map(x => x.id),
      dates_range: filterAdaptedDatesRange
  });

  const {data: receiptsResponse, isFetching: areReceiptsLoading} = useGetReceiptsFullQuery({
      filters,
      orderBy,
      pagination: {
          page: pagination.page,
          limit: pagination.limit
      },
  })

  const [deletionReceivementId, setDeletionReceivementId] = useState<number>();

  const collapsedContent = (rowRelated: Receive.Extended) => {
    const combinedItems = rowRelated.receives.flatMap(receive => {
      if (receive.type === "line") {
        // If it's a line, return it directly
        return [receive];
      } else if (receive.type === "group" && Array.isArray(receive.data)) {
        return receive.data.map(line => ({
          ...receive,
          data: line,
        }));
      }
      return [];
    });

    return (
      <BaseTable
        data={combinedItems}
        columns={[
          {
            header: t("subtable.columns.0"),
            getter: row => row.data?.order.product.code ?? "-", 
          },
          {
            header: t("subtable.columns.1"),
            getter: row => row.data?.order.product.name ?? "-", 
          },
          {
            header: t("subtable.columns.2"),
            getter: row => row.data?.location?.store.name ?? "-", 
          },
          {
            header: t("subtable.columns.3"),
            getter: row => row.data?.location?.section?.name ?? "-",
          },
          {
            header: t("subtable.columns.4"),
            getter: row => <div>
              {row.data?.received_quantity}
              {/*<span*/}
              {/*    className="text-accent font-[500] ml-[3px]">{generalT("of_part")} {subRow.available_quantity}</span>*/}
            </div>
          }
        ]}
        size="small"
        boldHeaders
        nothingFound={{
          height: 200
        }}
        hideTableWhenNothingFound
      />
    );
  };

  return (
    <>
      {/* FILTERS BLOCK */}
      <BaseAdminFilter
        permissionModule="receive"
        filterItems={
          <BaseInputsGrid cols={1} gap={24}>
            <BaseDropdown
              label={t("filters.supplier.label")}
              brightLabel

              options={suppliersOptions}
              getter={{
                label: opt => opt.name,
                key: opt => opt.id
              }}
              value={selectedSuppliers}

              onChange={setSelectedSuppliers}

              emptyValue={genT(PredefinedTranslations.DropdownsALL)}

              isLoading={suppliersLoading}

              autocomplete
              multiple
            />

            <DatepickerRangeContext.Provider value={{
                 "dates_range": {range: datesRange, setRange: setDatesRange},
            }}>
                <BaseDatepicker
                  label={t("filters.date.label")}
                  placeholder={t("filters.date.placeholder")}
                  rangeConfig={{groupKey: "dates_range", role: "solo"}}
                  brightLabel
                />
            </DatepickerRangeContext.Provider>
          </BaseInputsGrid>
        }

        handleSearch={searchInputDebounce}

        handleFilter={(e) => console.log(e)}

        handleCreate={() => history.push("/dashboard/receive/new")}
      />

      <div className="levitation-extended mt-[32px]">
        <Can not I="view" a="receive">
          <NoPermissionBanner />
        </Can>

        <Can I="view" a="receive">
            <BaseTable
              data={receiptsResponse?.payload ?? []}
              columns={[
                {
                  header: t("table.columns.0"),
                  getter: row => <>
                    <div className="bold-highlight">{row.code ?? row.id}</div>
                  </>,
                },
                {
                  header: t("table.columns.1"),
                    getter: row => <Link to={`/dashboard/purchasing/${row.purchase_order.id}/details`}><u>{row.purchase_order.code ?? row.purchase_order.id}</u></Link>,
                },
                {
                  header: t("table.columns.2"),
                  getter: row => row.supplier.name,
                  comparator: () => 0
                },
                {
                  header: t("table.columns.3"),
                  getter: row => row.date.format("DD.MM.YYYY"),
                  comparator: () => 0
                },
                {
                  // visible: ability.can("view", "receive") || ability.can("delete", "receive"),
                  header: <div className="text-center">{t("table.columns.4")}</div>,
                  getter: (row, index, isCollapsed) => <div className="text-center space-x-[12px]">
                    <Can I="view" a="receive">
                      <DetailsSVG
                        className={`${isCollapsed ? "text-tables-highlightedIcon" : "text-tables-secondaryIcon"} hover:text-tables-highlightedIcon cursor-pointer`}
                        onClick={() => {
                          history.push(`/dashboard/receive/${row.id}/details`);
                        }}
                      />
                    </Can>
                    {/*<Can I="delete" a="receive">*/}
                    {/*  <DeleteSVG*/}
                    {/*    className={`${isCollapsed ? "text-tables-highlightedIcon" : "text-tables-secondaryIcon"} hover:text-tables-highlightedIcon cursor-pointer`}*/}
                    {/*    onClick={() => setDeletionReceivementId(row.id)} */}
                    {/*  />*/}
                    {/*</Can>*/}
                  </div>,
                  preventCollapsePropagation: true
                }
              ]}

              collapse={{
                fill: "#D9DAFD",
                borderColor: "#B3B5EB",
                content: collapsedContent
              }}

              alternate
              isDataLoading={areReceiptsLoading}
              manualControls={{
                ordering: (newOrdering) => {
                  if (newOrdering) {
                    let name: Receive.DTO.OrderBy | undefined;

                    switch (newOrdering?.index) {
                      case 2:
                        name = "supplier";
                        break;
                      case 3:
                        name = "date";
                        break;
                      default:
                        name = undefined;
                    }

                    if (name)
                      setOrderBy({
                        name,
                        type: newOrdering.order
                      });
                  } else {
                    setOrderBy(undefined);
                  }
                }
              }}
            />

            <Pagination
              className="mt-[32px]"
              {...pagination.adapt(receiptsResponse)}
            />
        </Can>
      </div>

      <DeletionModal
        isOpen={!!deletionReceivementId}
        onClose={() => setDeletionReceivementId(undefined)}
        entity_id={deletionReceivementId}
      />
    </>
  );
}