import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseMaterialButton from "@reusables/BaseMaterialButton";

import { ReactComponent as IcFilter } from "@assets/images/icons/dashboard/filter.svg";
import { ReactComponent as SearchSVG } from "@assets/icons/ic_search.svg";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as UserRenewSVG } from "@assets/icons/ic_user_renew.svg";
import { ReactComponent as UserSuspendSVG } from "@assets/icons/ic_user_suspend.svg";

import BaseTable from "@reusables/BaseTable";
import { Users } from "@/types/general";
import { Button, Menu, Pagination } from "@mui/material";

import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import _ from "lodash";
import MutationModal from "./modals/MutationModal";
import SuspendedModal from "./modals/SuspendedModal";
import ActivationModal from "./modals/ActivationModal";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import { useGetRolesQuery } from "@redux/features/roles/rolesApi";
import { useGetUsersFullQuery } from "@redux/features/users/usersApi";
import {
    ArrayElementType,
    genT,
    PredefinedTranslations,
    removeEmpty,
    useOrdering,
    usePagination
} from "@helpers/utils";
import { useAppSelector } from "@redux/hooks";
import { useHistory } from "react-router-dom";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";


export default function UsersTab() {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.users" });

    const currentUser = useAppSelector((state) => state.auth.user);

    const history = useHistory();
    const routerTransitArgs = history.location.state as { roles?: number[] };

    // Searching related
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [searchingValue, setSearchingValue] = useState<string>();
    const searchInputDebounce = useCallback(_.debounce(setSearchingValue, 1000), [setSearchingValue]);

    const [showFilter, setShowFilter] = useState(false);
    const toggleShowFilter = () => setShowFilter((prev) => !prev);
    const filterBtnRef = useRef(null);

    const statusOptions = [
        {
            id: 0,
            value: t("table.userStatus.active")
        },
        {
            id: 1,
            value: t("table.userStatus.suspended")
        }
    ] satisfies { id: 0 | 1, value: string }[];

    const [selectedStatus, setSelectedStatus] = useState<ArrayElementType<typeof statusOptions> | null>();

    const { data: roles = [], isLoading: roleOptionsLoading } = useGetRolesQuery();
    const [selectedRoles, setSelectedRoles] = useState<typeof roles>([]);

    const { orderBy, setOrderBy } = useOrdering<Users.DTO.OrderBy>({ name: "id", type: "desc" });
    const pagination = usePagination({ page: 1, limit: 8 });

    useEffect(() => {
        if (routerTransitArgs?.roles) {
            setSelectedRoles(roles.filter(x => routerTransitArgs.roles?.includes(x.id)));
        }
    }, [routerTransitArgs, roles]);

    const filters = removeEmpty({
        search: searchingValue,
        status: selectedStatus?.id,
        roles: selectedRoles.length ? selectedRoles.map(x => x.id) : undefined
    });

    const { data: usersResponse, isFetching: isUsersLoading } = useGetUsersFullQuery({
        filters,
        orderBy,
        pagination
    });

    const [suspensionUser, setSuspensionUser] = useState<Users.Root>();
    const [activationUser, setActivationUser] = useState<Users.Root>();
    const [mutationUser, setMutationUser] = useState<Users.Root>();

    const [isMutationModalOpen, setIsMutationModalOpen] = useState(false);
    const [isSuspensionModalOpen, setIsSuspensionsModalOpen] = useState(false);
    const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);

    return (
        <>
            <Can not I="user.view" a="general">
                <NoPermissionBanner />
            </Can>

            <Can I="user.view" a="general">
                <div>
                    <div className="flex flex-row justify-between items-center mb-[44px]">
                        {showSearch ? (
                            <>
                                <div className="relative w-full mr-[16px]">
                                    <input
                                        type="text"
                                        placeholder={"Search"}
                                        onBlur={() => setShowSearch(false)}
                                        onChange={(e) => searchInputDebounce(e.target.value)}
                                        className="w-full h-[40px] px-10 py-[7px] rounded-lg border-solid border-[#B49FFB]"
                                        autoFocus
                                    />
                                    <div className="absolute top-50 left-[18px] translate-middle-y">
                                        <SearchSVG className="mt-[-2px]" />
                                    </div>
                                </div>
                                <Can I="user.invite" a="general">
                                    <BaseMaterialButton
                                        className="h-[40px]"
                                        fontWeight={500}
                                        onClick={() => {
                                            setMutationUser(undefined);
                                            setIsMutationModalOpen(true);
                                        }}
                                        sx={{
                                            minWidth: "fit-content"
                                        }}
                                    >
                                        {t("table.buttons.invite")}
                                    </BaseMaterialButton>
                                </Can>
                            </>
                        ) : (
                            // Heading and search button shown
                            <>
                                <div className="font-bold text-2xl text-accent">
                                    {t("heading")}
                                </div>
                                <div className="flex items-center gap-[16px]">
                                    <Button
                                        ref={filterBtnRef}
                                        variant="contained"
                                        size="medium"
                                        onClick={toggleShowFilter}
                                        className="max-w-[90px] h-[40px]"
                                        sx={{
                                            backgroundColor: "#F3F2EF !important",
                                            color: "#686868",
                                            "&:active": {
                                                backgroundColor: "#e0dfdc !important"
                                            }
                                        }}
                                    >
                                        <div className="flex justify-center items-center space-x-2 px-[32px]">
                                            <span>{t("filters.title")}</span>
                                            <IcFilter />
                                        </div>
                                    </Button>

                                    <BaseMaterialIconButton
                                        icon={<SearchSVG />}
                                        onClick={() => setShowSearch(true)}
                                        className="w-[40px] h-[40px]"
                                    />

                                    <Can I="user.invite" a="general">
                                        <BaseMaterialButton
                                            fontWeight={500}
                                            className="h-[40px]"
                                            onClick={() => {
                                                setMutationUser(undefined);
                                                setIsMutationModalOpen(true);
                                            }}
                                        >
                                            {t("table.buttons.invite")}
                                        </BaseMaterialButton>
                                    </Can>
                                </div>
                            </>
                        )}

                        <Menu
                            anchorEl={filterBtnRef?.current}
                            id="filterMenu"
                            open={showFilter}
                            onClose={toggleShowFilter}
                            elevation={0}
                            className="!shadow-sm-1 mt-2 p-6"
                            sx={{
                                ".MuiPaper-root": {
                                    p: "24px",
                                    boxShadow: "-6px 5px 33px rgba(59, 65, 208, 0.11)",
                                    width: "300px"
                                }
                            }}
                        >
                            <BaseInputsGrid cols={1} gap={24}>
                                <BaseDropdown
                                    label={t("filters.fields.status.label")}
                                    placeholder={t("filters.fields.status.placeholder")}

                                    options={statusOptions}
                                    value={selectedStatus}

                                    getter={{
                                        label: opt => opt.value,
                                        key: opt => opt.id
                                    }}

                                    onChange={newState => setSelectedStatus(newState)}

                                    emptyValue={genT(PredefinedTranslations.DropdownsALL)}
                                />
                                <BaseDropdown
                                    label={t("filters.fields.roles.label")}
                                    placeholder={t("filters.fields.roles.placeholder")}

                                    options={roles}
                                    isLoading={roleOptionsLoading}
                                    value={selectedRoles}

                                    getter={{
                                        label: opt => opt.name,
                                        key: opt => opt.id
                                    }}

                                    onChange={(_, newState) => setSelectedRoles(newState)}

                                    emptyValue={genT(PredefinedTranslations.DropdownsALL)}

                                    multiple
                                    autocomplete
                                />
                            </BaseInputsGrid>
                        </Menu>
                    </div>

                    <BaseTable
                        data={usersResponse?.payload ?? []}
                        columns={[
                            {
                                header: t("table.columns.fullName"),
                                getter: row => (
                                    <div className={row.status ? "opacity-25" : ""}>
                                        <span className={`bold-highlight`}>
                                            {row.first_name} {row.last_name}
                                        </span>
                                        <div className="text-secondary">{row.email}</div>
                                    </div>
                                ),
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.role"),
                                getter: row => <span className={row.status ? "opacity-25" : ""}>{row.role.name}</span>,
                                comparator: () => 0
                            },
                            {
                                header: t("table.columns.status"),
                                getter: row => (
                                    <span className={row.status ? "opacity-25" : ""}>
                                        {row.status == 1 ? t("table.userStatus.suspended") : t("table.userStatus.active")}
                                    </span>
                                )
                            },
                            {
                                header: t("table.columns.lastLogin"),
                                getter: row => <span className={row.status ? "opacity-25" : ""}>
                                    {row.activity.last_seen?.format("DD.MM.YYYY hh:mm") || t("table.neverLoggedIn")}
                                </span>
                            },
                            {
                                header: () => "",
                                getter: (row) => (
                                    row.status === 0 ? (
                                        <div className="flex justify-end text-tables-secondaryIcon">
                                            {(row.id !== currentUser?.id || !row.is_ceo) && // Prevents the user from deleting themselves.
                                                <>
                                                    <Can I="user.edit" a="general">
                                                        <EditSVG
                                                            className="mr-[16px] cursor-pointer hover:text-tables-highlightedIcon"
                                                            onClick={() => {
                                                                setMutationUser(row);
                                                                setIsMutationModalOpen(true);
                                                            }} 
                                                        />
                                                    </Can>

                                                    {
                                                        !row.is_ceo_in_company &&
                                                            <Can I="user.edit" a="general">
                                                                <UserSuspendSVG
                                                                    className="ml-[16px] cursor-pointer hover:text-tables-highlightedIcon"
                                                                    onClick={() => {
                                                                        setSuspensionUser(row);
                                                                        setIsSuspensionsModalOpen(true);
                                                                    }}
                                                                />
                                                        </Can>
                                                    }
                                                </>
                                            }
                                        </div>
                                    ) : (
                                        <div className="flex justify-end text-tables-secondaryIcon">
                                            <Can I="user.edit" a="general">
                                                <UserRenewSVG
                                                    className="cursor-pointer hover:text-tables-highlightedIcon"
                                                    onClick={() => {
                                                        setActivationUser(row);
                                                        setIsActivationModalOpen(true);
                                                    }}
                                                />
                                            </Can>
                                        </div>
                                    )
                                )
                            }
                        ]}

                        isDataLoading={isUsersLoading}

                        manualControls={{
                            ordering: (newOrdering) => {
                                if (newOrdering?.index) {
                                    let name: Users.DTO.OrderBy | undefined;

                                    switch (newOrdering?.index) {
                                        case 0:
                                            name = "name";
                                            break;
                                        case 1:
                                            name = "role";
                                            break;
                                        default:
                                            name = undefined;
                                    }

                                    if (name)
                                        setOrderBy({
                                            name,
                                            type: newOrdering.order
                                        });
                                } else {
                                    setOrderBy(undefined);
                                }
                            }
                        }}


                        headerSx={{
                            backgroundColor: "#F9F9F9"
                        }}
                        sx={{
                            borderCollapse: "separate",
                            borderSpacing: "0 15px"
                        }}
                    />
                </div>

                <Pagination
                    className="mt-[32px]"
                    {...pagination.adapt(usersResponse)}
                />
            </Can>

            <SuspendedModal
                isOpen={isSuspensionModalOpen}
                onClose={() => void setIsSuspensionsModalOpen(false)}
                entity={suspensionUser}
            />

            <ActivationModal
                isOpen={isActivationModalOpen}
                onClose={() => setIsActivationModalOpen(false)}
                entity={activationUser}
            />

            <MutationModal
                isOpen={isMutationModalOpen}
                onClose={() => void setIsMutationModalOpen(false)}
                user={mutationUser}
            />
        </>
    );
}