import React from "react";
import { ViewLayoutProps } from "./types";
import { useTranslation } from "react-i18next";
import { Collapse, Stack } from "@mui/material";
import { ReactComponent as CircleChevronSVG } from "@assets/icons/ic_expand_down_circled.svg";
import BaseTooltip from "@reusables/BaseTooltip";
import { cn } from "@helpers/utils";
import BaseTable from "@reusables/BaseTable";
import { Link } from "react-router-dom";
import BaseChip from "@reusables/BaseChip";
import _ from "lodash";
import { Can } from "@/casl.config";

export default function OrderReceipts({ purchaseOrder }: ViewLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "purchaseOrder.mutations.details.receiptsSection" });

    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);

    if (!purchaseOrder?.receives.length)
        return (
            <Can I="receive.view" a="purchase_order">
                <div className="levitation-extended">
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <div className={"text-accent font-semibold text-xl"}>{t("heading")}</div>
                        <BaseTooltip title={t("noReceiptsFoundTooltip") + ""}>
                            <CircleChevronSVG className={"text-[#D7D4DA]"} />
                        </BaseTooltip>
                    </Stack>
                </div>
            </Can>
        );

    return (
        <Can I="receive.view" a="purchase_order">
            <div className="levitation-extended">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <div className={"text-accent font-semibold text-xl"}>{t("heading")}</div>
                    <CircleChevronSVG
                        onClick={() => void setIsCollapsed(prevState => !prevState)}
                        className={cn("cursor-pointer text-accent transition-[.25s]")}
                        style={{ transform: !isCollapsed ? "rotate(0deg)" : "rotate(180deg)" }}
                    />
                </Stack>

                <Collapse in={isCollapsed}>
                    <div className={"mt-[24px]"}>
                        <BaseTable
                            data={purchaseOrder.receives}
                            columns={[
                                {
                                    header: t("columns.0"),
                                    getter: (row) =>
                                        <Link to={`/dashboard/receive/${row.id}/details`}><u>{row.code ?? row.id}</u></Link>
                                },
                                {
                                    header: t("columns.1"),
                                    getter: (row) => {
                                        if (row.is_fully_received) {
                                            return <BaseChip fill={"green"} asDot>{t("receiveStatus.1")}</BaseChip>;
                                        } else {
                                            return <BaseChip fill={"yellow"} asDot>{t("receiveStatus.0")}</BaseChip>;
                                        }
                                    }
                                },
                                {
                                    header: t("columns.2"),
                                    getter: (row) => row.receive_date.format("DD.MM.YYYY"),
                                    comparator: (a, b) => a.receive_date.isAfter(b.receive_date) ? -1 : 1
                                },
                                {
                                    header: t("columns.3"),
                                    getter: (row) => row.user ? ([row.user.first_name, row.user.last_name].filter(x => !_.isEmpty(x)).join(" ")) : "-"
                                }
                            ]}
                            headerSx={{
                                backgroundColor: "rgba(0, 0, 0, 0.03)"
                            }}
                            size={"small"}
                        />
                    </div>
                </Collapse>
            </div>
        </Can>
    );
}