import React, { useState } from "react";
import { CustomFields } from "@/types/general";
import { useTranslation } from "react-i18next";
import { Collapse } from "@mui/material";
import { ChevronDown } from "lucide-react";

import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";

import BaseBadge from "@reusables/BaseBadge";
import { cn } from "@helpers/utils";
import BaseSwitch from "@reusables/BaseSwitch";
import { Can } from "@/casl.config";

type FieldAlias = CustomFields.Components.Field;

interface CollapsableModuleProps {
    module: {
        key: CustomFields.Components.Modules;
        fields: FieldAlias[];
    };
    onEdit: (field: FieldAlias) => void;
    onToggle: (field: FieldAlias) => void;
    onDelete: (field: FieldAlias) => void;
}

export default function CollapsableModule(props: CollapsableModuleProps) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.customFields" });

    const [isCollapsed, setIsCollapsed] = useState(true);

    return (
        <div>
            <div
                onClick={() => void setIsCollapsed(s => !s)}
                className="cursor-pointer px-[16px] py-[8px] bg-[#F9F9F9] flex flex-row justify-between"
            >
                {/* Module title */}
                <div className={"font-semibold text-accent text-lg"}>
                    {t(`modules.${props.module.key}`)}
                </div>

                {/* Collapse Chevron */}
                <div className="transition-all text-accent" style={{
                    transform: isCollapsed ? "rotate(180deg)" : "none"
                }}>
                    <ChevronDown />
                </div>
            </div>
            <Collapse in={isCollapsed}>
                <div className={"mt-[24px] px-[16px] space-y-[24px]"}>
                    {
                        props.module.fields.map((field, index) => (
                            <div
                                key={index}
                                className={"flex flex-row justify-between"}
                            >
                                {/* Custom field name*/}
                                <div
                                    className={cn("flex flex-row items-center space-x-2", field.is_disabled && "opacity-50")}>
                                    <div className={"text-accent"}>
                                        {field.label}
                                    </div>
                                    {
                                        field.is_disabled &&
                                        <BaseBadge className={"bg-gray-300"}>
                                            {t(`main.table.disabled`)}
                                        </BaseBadge>
                                    }
                                </div>

                                {/* Fields actions */}
                                <div>
                                    <div className="flex flex-row items-center text-tables-secondaryIcon space-x-[8px]">
                                        <Can I={"edit"} a="custom_fields">
                                            <div>
                                                <BaseSwitch
                                                    checked={!field.is_disabled}
                                                    onClick={() => void props.onToggle(field)}
                                                />
                                            </div>

                                            <div
                                                className="cursor-pointer hover:text-tables-highlightedIcon inline-block px-2"
                                                onClick={() => void props.onEdit(field)}
                                            >
                                                <EditSVG />
                                            </div>
                                        </Can>

                                        <Can I={"delete"} a="custom_fields">
                                            <div
                                                className="cursor-pointer hover:text-tables-highlightedIcon inline-block px-2"
                                                onClick={() => void props.onDelete(field)}
                                            >
                                                <TrashSVG />
                                            </div>
                                        </Can>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Collapse>
        </div>
    );
}