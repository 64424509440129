import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BaseButton from "@reusables/BaseButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { CustomerGroupsFormTyping, customerGroupsScheme } from "./types";
import BaseInput from "@reusables/BaseInput";
import { ReactComponent as PercentageIcon } from "@assets/icons/ic_percentage.svg";

import BaseInputsGrid from "@reusables/BaseInputsGrid";
import CustomerSection from "./parts/CustomerSection";
import ProductSection from "./parts/ProductSection";
import { CustomerGroups } from "@/types/general";


interface MutationLayoutProperties {
    customerGroup: CustomerGroups.Extended | undefined;
    onSubmit: (args: CustomerGroupsFormTyping) => void;
}

export default function MutationLayout(props: MutationLayoutProperties) {
    const {t} = useTranslation("", {keyPrefix: "customerGroups.mutation"})

    const form = useForm<CustomerGroupsFormTyping>({
        resolver: zodResolver(customerGroupsScheme),
    });

    useEffect(() => {
        const {setValue} = form;
        if (props.customerGroup) {
            setValue("code", props.customerGroup.code);
            setValue("name", props.customerGroup.name);
            setValue("discount", props.customerGroup.discount);
            setValue("customers", props.customerGroup.customers.map((customer) => ({value: customer})));
            setValue("products", props.customerGroup.products);
        }
    }, [props.customerGroup]);

    useEffect(() => {
        form.setFocus("code");
    }, []);


    const onSubmit = form.handleSubmit((data: CustomerGroupsFormTyping) => {
        props.onSubmit(data);
    });

    return (
        <>
            <FormProvider {...form}>
                <BaseInputsGrid>
                    <Controller
                        control={form.control}
                        name="code"
                        render={({ field, fieldState }) => (
                            <div className='relative'>
                                <BaseInput
                                    {...field}
                                    error={fieldState.error}
                                    label={t("fields.groupCode.label")}  
                                    placeholder={t("fields.groupCode.placeholder")}

                                    disabled={!!props.customerGroup}
                                />
                            </div>
                        )}
                    />

                    <Controller
                        control={form.control}
                        name="name"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.groupName.label") + " *"}
                                placeholder={t("fields.groupName.placeholder")}
                            />
                        )}
                    />

                    <Controller
                        control={form.control}
                        name="discount"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                label={t("fields.discount.label") + " *"}
                                placeholder={t("fields.discount.placeholder")}
                                icon={{right: {el: <PercentageIcon/>, offset: "18px"}}}
                                sx={{
                                    "& input": {
                                        paddingRight: "40px"
                                    }
                                }}
                            />
                        )}
                    />
                </BaseInputsGrid>

                <CustomerSection />

                <ProductSection />

                <div className="flex justify-center mt-[32px]">
                    <BaseButton
                        text={t("buttons.save")}
                        size={"md"}
                        buttonWidth={"200px"}
                        onClick={() => onSubmit()}
                    />
                </div>
            </FormProvider> 
        </>
    )
}
