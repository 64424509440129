import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Product } from '@/types/general';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdateProductBatchNumberMutation } from '@redux/features/products/productsApi';
import dayjs, { Dayjs } from 'dayjs';
import BaseModal from '@reusables/Modals/BaseModal';
import BaseInput from '@reusables/BaseInput';
import BaseButton from '@reusables/BaseButton';
import BaseDatepicker from '@reusables/BaseDatepicker';
import { toast } from 'react-toastify';
import { isErrorWithMessage } from '@redux/api/query';

interface UpdateBatchNumberModalProperties {
    isOpen: boolean;
    onClose: () => void;
    batchNumber?: Product.Augmentations.BatchNumbers.Root;
    product: Product.Extended;
}

const formScheme = z.object({
    batch_number: z.string().nonempty(),
    expiration_date: z.instanceof(dayjs as unknown as typeof Dayjs).nullish(),
});

type FormTyping = z.infer<typeof formScheme>;

export default function UpdateBatchNumberModal({ isOpen, onClose, batchNumber, product }: UpdateBatchNumberModalProperties): JSX.Element {
    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct.tabs.numbers.batchNumbers.modals.update" });

    const [updateBatchNumber, { isLoading: isUpdateLoading }] = useUpdateProductBatchNumberMutation();

    const { control, handleSubmit, setValue, reset } = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
    });

    useEffect(() => {
        reset();
        if (batchNumber) {
            setValue("batch_number", batchNumber.batch_number);
            setValue("expiration_date", batchNumber.expiration_date);
        }
    }, [batchNumber]);

    const onSubmit = handleSubmit((data) => {
        if (batchNumber) {
            updateBatchNumber({
                id: batchNumber.id,
                batch_number: data.batch_number,
                expiration_date: data.expiration_date?.format('YYYY-MM-DD') ?? undefined
            }).unwrap()
            .then(() => {
                toast.success(t("responses.success"));
                onClose();
            })
            .catch((e) => {
                if (isErrorWithMessage(e)) {
                    toast.error(e.message);
                } else {
                    toast.error(t("responses.error"));
                }
            });
        }
    });

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            isLoading={isUpdateLoading}
            padding="56px"
            width={612}
            position="center"
            useCloseIcon
        >
            <form className="space-y-8" onSubmit={onSubmit}>
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="w-full flex items-center space-x-2">
                    <div className="flex justify-between items-center p-[16px] min-w-[200px] h-[56px] rounded-[6px] bg-[#ECEDFE]">
                        <div className="text-sm font-thin text-[#5A5587]">{t("fields.batchNumber.label")}</div>
                    </div>
                    <Controller
                        control={control}
                        name="batch_number"
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                placeholder={t("fields.batchNumber.placeholder")}
                            />
                        )}
                    />
                </div>
                <div className="w-full flex items-center space-x-2">
                    <div className="flex justify-between items-center p-[16px] min-w-[200px] h-[56px] rounded-[6px] bg-[#ECEDFE]">
                        <div className="text-sm font-thin text-[#5A5587]">{t("fields.expirationDate.label")}</div>
                    </div>
                    <Controller
                        control={control}
                        name="expiration_date"
                        render={({ field, fieldState }) => (
                            <div className="w-full">
                                <BaseDatepicker
                                    {...field}
                                    {...fieldState}
                                    placeholder={t("fields.expirationDate.placeholder")}
                                />
                            </div>
                        )}
                    />
                </div>
                <div className="grid grid-cols-2 gap-[24px]">
                    <BaseButton
                        text={t("buttons.cancel")}
                        size="md"
                        primaryOutlined
                        onClick={onClose}
                        type="button"
                    />
                    <BaseButton
                        text={t("buttons.save")}
                        size="md"
                        type="submit"
                    />
                </div>
            </form>
        </BaseModal>
    )
}
