import React, { useEffect, useMemo } from "react";
import { Disable } from "./types";
import { Fade } from "@mui/material";
import { cn } from "@helpers/utils";

interface BaseConditionBlockerProperties {
    disabled?: Disable & { className?: string };
    simplifyState?: (state: boolean) => void;
    position?: "left" | "center";
    onClick?: () => void;
}

export default function BaseConditionBlocker({ disabled, simplifyState, position = "center", ...props }: BaseConditionBlockerProperties) {
    const isDisabled = useMemo(() => disabled ? (typeof disabled === "boolean" ? disabled : disabled.state) : false, [disabled]);

    useEffect(() => simplifyState?.(isDisabled), [isDisabled])

    return (
      <Fade in={isDisabled}>
          {
              <div
                className={cn(`absolute top-0 left-0 w-full h-full text-inputs-textGrayscale font-thin flex items-center rounded-[8px] bg-inputs-disabled ${position === "center" ? "justify-center" : "px-[16px]"}`, disabled?.className)}
                onClick={props.onClick}
              >
                  {
                    typeof disabled !== "boolean" && <>
                      <span>{disabled?.reason}</span>
                    </>
                  }
              </div>
          }
      </Fade>
    );

}