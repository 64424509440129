import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";

// reusables
import BaseButton from "@reusables/BaseButton";
import BaseInput from "@reusables/BaseInputOld";

// icons
import FilterIcon from "@assets/images/icons/dashboard/filter.svg";
import SearchIcon from "@assets/images/icons/dashboard/search.svg";

//hooks
import {fetchShipments} from "../../../../hooks/shipment";


// styled
import styled from "@emotion/styled"

// MUI
import {
    Collapse, Container,
    IconButton,
    Menu,
    Pagination,
    Paper,
    Popover,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    TooltipProps
} from "@mui/material";
import BaseUnderconstruct from "@reusables/BaseUnderconstruct";


// custom tooltip for purchases more popover icons
const BlueOnGreenTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{tooltip: className}}/>
))(`
    color: #3C3769;
    background-color: #C8E98E;
    font-size: 14px;
    font-weight: 300;
    border-radius: 6px;
    padding: 8px;

    & .MuiTooltip-arrow {
        color: #C8E98E;
    }
`,
)

function ShipmentPage() {

    const {
        shipmentsLoading,
        shipmentsData,
        shipmentsLinks,
        currentPage,
        setCurrentPage,
        perPage,
        setPerPage,
        shipmentsMeta,
        setShipmentsMeta
    } = fetchShipments()

    const handlePageChange = (event:any, value:any) => {
        setCurrentPage(value)
    }

    const [filterAnchor, setFilterAnchor] = React.useState<null | HTMLElement>(null)
    const filterOpen = Boolean(filterAnchor)

    const handleFilterOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setFilterAnchor(event.currentTarget)
    }

    const handleFilterClose = () => {
        setFilterAnchor(null)
    }

    // history and navigation
    const history = useHistory();

    return (

        <div className="container-lg">
            <BaseUnderconstruct/>

            {/*<div>*/}
            {/*    <p className="text-xl-semibold color-1 text-center">Shipment</p>*/}
            {/*</div>*/}

            {/*/!* Filter, export, import menu *!/*/}
            {/*<div*/}
            {/*    className="bg-6-a23 border-6 d-flex justify-content-between align-items-center flex-wrap"*/}
            {/*    style={{*/}
            {/*        borderWidth: "1px",*/}
            {/*        borderStyle: "solid",*/}
            {/*        borderRadius: "20px",*/}
            {/*        paddingInline: "40px",*/}
            {/*        paddingBlock: "24px",*/}
            {/*        gap: "24px",*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <div style={{*/}
            {/*        display: "flex",*/}
            {/*        flexDirection: "column",*/}
            {/*        alignItems: "flex-start",*/}
            {/*        padding: "0px",*/}
            {/*        gap: "8px",*/}
            {/*        width: "300px",*/}
            {/*        height: "52px",*/}
            {/*        background: "#C8E98E",*/}
            {/*        borderRadius: "8px",*/}
            {/*        flex: "none",*/}
            {/*        order: "0",*/}
            {/*        flexGrow: "0",*/}
            {/*    }}>*/}

            {/*        <BaseInputLegacy*/}
            {/*            className="text-md-semibold color-4 ml-5"*/}
            {/*            placeholder="Search"*/}
            {/*            secondaryPlaceholder*/}
            {/*            // value={searchInput}*/}
            {/*            // onChange={(e) => setSearchInput(e.target.value)}*/}
            {/*            boxColor="#C8E98E"*/}
            {/*            iconPos="right"*/}
            {/*            ownIcon={true}*/}
            {/*            icon={SearchIcon}*/}
            {/*            noBorder={true}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        /!* Filter *!/*/}
            {/*        <BaseButton*/}
            {/*            text="Filter"*/}
            {/*            color={filterOpen ? '#AAD65D' : '#C8E98E'}*/}
            {/*            textColor="#3C3769"*/}
            {/*            size="md"*/}
            {/*            buttonWidth="50px"*/}
            {/*            icon={FilterIcon}*/}
            {/*            ownIcon={true}*/}
            {/*            iconPos="right"*/}
            {/*            className="text-md-semibold ml-5"*/}
            {/*            onClick={handleFilterOpen}*/}
            {/*        />*/}

            {/*        <Menu*/}
            {/*            id="filterMenu"*/}
            {/*            anchorEl={filterAnchor}*/}
            {/*            open={filterOpen}*/}
            {/*            onClose={handleFilterClose}*/}
            {/*            elevation={1}*/}
            {/*            sx={{padding: '0px'}}*/}
            {/*        >*/}
            {/*            <div className="filter-paper">*/}
            {/*                <Paper sx={{padding: '24px', margin: '0px'}}>*/}

            {/*                    <div style={{marginBottom: '24px'}}>*/}
            {/*                        <p className="text-md-semibold">Status</p>*/}

            {/*                        <div className="d-flex flex-column" style={{gap: '8px'}}>*/}

            {/*                            <div className="d-flex justify-content-between">*/}
            {/*                                All*/}
            {/*                                <input*/}
            {/*                                    className="radio-input"*/}
            {/*                                    type="radio"*/}
            {/*                                    value="All"*/}
            {/*                                    name="status"*/}
            {/*                                    // checked={selectedFilterStatus=='All'}*/}
            {/*                                    // onChange={(e) => setSelectedFilterStatus(e.target.value)}*/}
            {/*                                />*/}
            {/*                            </div>*/}
            {/*                            <div className="d-flex justify-content-between">*/}
            {/*                                Open*/}
            {/*                                <input*/}
            {/*                                    className="radio-input"*/}
            {/*                                    type="radio"*/}
            {/*                                    value="open"*/}
            {/*                                    name="status"*/}
            {/*                                    // checked={selectedFilterStatus=='open'}*/}
            {/*                                    // onChange={(e) => setSelectedFilterStatus(e.target.value)}*/}
            {/*                                />*/}
            {/*                            </div>*/}
            {/*                            <div className="d-flex justify-content-between">*/}
            {/*                                Barred*/}
            {/*                                <input*/}
            {/*                                    className="radio-input"*/}
            {/*                                    type="radio"*/}
            {/*                                    value="barred"*/}
            {/*                                    name="status"*/}
            {/*                                    // checked={selectedFilterStatus=='barred'}*/}
            {/*                                    // onChange={(e) => setSelectedFilterStatus(e.target.value)}*/}
            {/*                                />*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </Paper>*/}
            {/*            </div>*/}
            {/*        </Menu>*/}

            {/*    </div>*/}

            {/*    <div*/}
            {/*        className="d-flex justify-content-end"*/}
            {/*        style={{gap: "24px"}}*/}
            {/*    >*/}

            {/*    </div>*/}
            {/*    <div style={{*/}
            {/*        display: 'flex',*/}
            {/*        flexDirection: 'row',*/}
            {/*        justifyContent: 'left',*/}
            {/*        alignItems: 'center',*/}
            {/*        padding: '8px, 32px',*/}
            {/*        gap: '32px',*/}
            {/*        width: '288px',*/}
            {/*        height: '100px',*/}
            {/*        backgroundColor: '#FFFFFF',*/}
            {/*        boxShadow: '-6px 5px 33px rgba(59, 65, 208, 0.11)',*/}
            {/*        borderRadius: '12px',*/}
            {/*    }}>*/}
            {/*        <span style={{marginLeft: '20px'}}>Shipped Today:*/}
            {/*             <p style={{*/}
            {/*                 width: '73px',*/}
            {/*                 height: '32px',*/}
            {/*                 fontFamily: 'Poppins',*/}
            {/*                 fontStyle: 'normal',*/}
            {/*                 fontWeight: '700',*/}
            {/*                 fontSize: '28px',*/}
            {/*                 lineHeight: '115%',*/}
            {/*                 textAlign: 'left',*/}
            {/*                 color: '#7556FA',*/}
            {/*                 marginTop: '10px',*/}
            {/*                 marginLeft: '20px',*/}
            {/*             }}>1,560</p>*/}
            {/*        </span>*/}

            {/*    </div>*/}
            {/*</div>*/}
            {/*{*/}

            {/*}*/}

            {/*<div className="custom-card" style={{marginTop: "32px"}}>*/}
            {/*    {shipmentsLoading ? <Skeleton width={150}/> :*/}
            {/*        <p className="text-md-semibold color-4">{shipmentsMeta?.total} Shipments</p>}*/}

            {/*    <div>*/}
            {/*        <TableContainer component={Paper} elevation={0}>*/}
            {/*            <Table style={{tableLayout: 'fixed'}} stickyHeader={true}>*/}
            {/*                <TableHead*/}
            {/*                    sx={{*/}
            {/*                        backgroundColor: "#F3F2EF",*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    <TableRow>*/}
            {/*                        <TableCell*/}
            {/*                            sx={{*/}
            {/*                                paddingTop: "8px",*/}
            {/*                                paddingBottom: "8px",*/}
            {/*                            }}*/}
            {/*                        >*/}
            {/*                            <span className="text-sm-light color-suppli-gray">*/}
            {/*                                Order*/}
            {/*                            </span>*/}
            {/*                        </TableCell>*/}

            {/*                        <TableCell*/}
            {/*                            sx={{*/}
            {/*                                paddingTop: "8px",*/}
            {/*                                paddingBottom: "8px",*/}
            {/*                            }}*/}
            {/*                        >*/}
            {/*                            <span className="text-sm-light color-suppli-gray">*/}
            {/*                                Customer*/}
            {/*                            </span>*/}
            {/*                        </TableCell>*/}

            {/*                        <TableCell*/}
            {/*                            sx={{*/}
            {/*                                paddingTop: "8px",*/}
            {/*                                paddingBottom: "8px",*/}
            {/*                            }}*/}
            {/*                        >*/}
            {/*                            <span className="text-sm-light color-suppli-gray">*/}
            {/*                                Priority*/}
            {/*                            </span>*/}
            {/*                        </TableCell>*/}

            {/*                        <TableCell*/}
            {/*                            sx={{*/}
            {/*                                paddingTop: "8px",*/}
            {/*                                paddingBottom: "8px",*/}
            {/*                            }}*/}
            {/*                        >*/}
            {/*                            <span className="text-sm-light color-suppli-gray">*/}
            {/*                                Item*/}
            {/*                            </span>*/}
            {/*                        </TableCell>*/}

            {/*                        <TableCell*/}
            {/*                            sx={{*/}
            {/*                                paddingTop: "8px",*/}
            {/*                                paddingBottom: "8px",*/}
            {/*                            }}*/}
            {/*                        >*/}
            {/*                            <span className="text-sm-light color-suppli-gray">*/}
            {/*                                Delivery Date*/}
            {/*                            </span>*/}
            {/*                        </TableCell>*/}

            {/*                        <TableCell*/}
            {/*                            sx={{*/}
            {/*                                paddingTop: "8px",*/}
            {/*                                paddingBottom: "8px",*/}
            {/*                            }}*/}
            {/*                        >*/}
            {/*                            <span className="text-sm-light color-suppli-gray">*/}
            {/*                                Destination*/}
            {/*                            </span>*/}
            {/*                        </TableCell>*/}

            {/*                        <TableCell*/}
            {/*                            sx={{*/}
            {/*                                paddingTop: "8px",*/}
            {/*                                paddingBottom: "8px",*/}
            {/*                            }}*/}
            {/*                        >*/}
            {/*                            <span className="text-sm-light color-suppli-gray">*/}
            {/*                                Status*/}
            {/*                            </span>*/}
            {/*                        </TableCell>*/}

            {/*                        <TableCell*/}
            {/*                            sx={{*/}
            {/*                                textAlign: 'center'*/}
            {/*                            }}*/}
            {/*                        >*/}
            {/*                            <span className="text-sm-light color-suppli-gray">*/}
            {/*                                Action*/}
            {/*                            </span>*/}
            {/*                        </TableCell>*/}

            {/*                    </TableRow>*/}
            {/*                </TableHead>*/}

            {/*                {*/}
            {/*                    shipmentsData.length == 0 && !shipmentsLoading ? (*/}
            {/*                        <div>*/}
            {/*                            <p className="text-center">No shipments</p>*/}
            {/*                        </div>*/}
            {/*                    ) : ''*/}
            {/*                }*/}

            {/*                <TableBody>*/}
            {/*                    {*/}
            {/*                        shipmentsData.map((row, idx) => (*/}
            {/*                            <TableRow key={idx}>*/}
            {/*                                <TableCell*/}
            {/*                                    component="th"*/}
            {/*                                    scope="row"*/}
            {/*                                    sx={{*/}
            {/*                                        background: idx % 2 ==0 ? '#F9F9F9' : ''*/}
            {/*                                    }}*/}
            {/*                                >*/}
            {/*                                <span className="text-sm-semibold color-4">*/}
            {/*                                    {shipmentsLoading ? <Skeleton/> : row?.purchase_order?.number}*/}
            {/*                                </span>*/}
            {/*                                </TableCell>*/}

            {/*                                <TableCell*/}
            {/*                                    component="th"*/}
            {/*                                    scope="row"*/}
            {/*                                    sx={{*/}
            {/*                                        background: idx % 2==0 ? '#F9F9F9' : ''*/}
            {/*                                    }}*/}
            {/*                                >*/}
            {/*                                <span className="text-sm-light color-4">*/}
            {/*                                    {shipmentsLoading ? <Skeleton /> : row?.customer?.customer_name}*/}
            {/*                                </span>*/}
            {/*                                </TableCell>*/}

            {/*                                <TableCell*/}
            {/*                                    component="th"*/}
            {/*                                    scope="row"*/}
            {/*                                    sx={{*/}
            {/*                                        background: idx%2==0 ? '#F9F9F9' : ''*/}
            {/*                                    }}*/}
            {/*                                >*/}
            {/*                                <span className="text-sm-light color-4">*/}
            {/*                                     {shipmentsLoading ? <Skeleton /> :*/}
            {/*                                         (row?.premium_delivery ? 'Premium delivery' : 'Free shipping')}*/}
            {/*                                </span>*/}
            {/*                                </TableCell>*/}

            {/*                                <TableCell*/}
            {/*                                    component="th"*/}
            {/*                                    scope="row"*/}
            {/*                                    sx={{*/}
            {/*                                        background: idx%2==0 ? '#F9F9F9' : ''*/}
            {/*                                    }}*/}
            {/*                                >*/}
            {/*                                <span className="text-sm-light color-4">*/}
            {/*                                    {shipmentsLoading ? <Skeleton /> : row?.item}*/}
            {/*                                </span>*/}
            {/*                                </TableCell>*/}

            {/*                                <TableCell*/}
            {/*                                    component="th"*/}
            {/*                                    scope="row"*/}
            {/*                                    sx={{*/}
            {/*                                        background: idx%2==0 ? '#F9F9F9' : ''*/}
            {/*                                    }}*/}
            {/*                                >*/}
            {/*                                <span className="text-sm-light color-4">*/}
            {/*                                    {shipmentsLoading ? <Skeleton /> : row?.delivery_date}*/}
            {/*                                </span>*/}
            {/*                                </TableCell>*/}

            {/*                                <TableCell*/}
            {/*                                    component="th"*/}
            {/*                                    scope="row"*/}
            {/*                                    sx={{*/}
            {/*                                        background: idx%2==0 ? '#F9F9F9' : ''*/}
            {/*                                    }}*/}
            {/*                                >*/}
            {/*                                <span className="text-sm-light color-4">*/}
            {/*                                    {shipmentsLoading ? <Skeleton /> : (row?.shipping_address?.country)}*/}
            {/*                                </span>*/}
            {/*                                </TableCell>*/}

            {/*                                <TableCell*/}
            {/*                                    component="th"*/}
            {/*                                    scope="row"*/}
            {/*                                    sx={{*/}
            {/*                                        background: idx%2==0 ? '#F9F9F9' : ''*/}
            {/*                                    }}*/}
            {/*                                >*/}
            {/*                                <span className="text-sm-light color-4">*/}
            {/*                                    {shipmentsLoading ? <Skeleton /> : row?.is_shipped ? "Shipped" : "Cancelled"}*/}
            {/*                                </span>*/}
            {/*                                </TableCell>*/}

            {/*                                <TableCell*/}
            {/*                                    sx={{*/}
            {/*                                        background: idx%2==0 ? '#F9F9F9' : '',*/}
            {/*                                    }}*/}
            {/*                                    component="th"*/}
            {/*                                    scope="row"*/}
            {/*                                >*/}
            {/*                                    /!* @todo Add a tooltip component, like on the purchase page *!/*/}
            {/*                                </TableCell>*/}
            {/*                            </TableRow>*/}
            {/*                        ))*/}
            {/*                    }*/}
            {/*                </TableBody>*/}
            {/*            </Table>*/}
            {/*        </TableContainer>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<Pagination*/}
            {/*    sx={{*/}
            {/*        marginTop: '32px',*/}
            {/*    }}*/}
            {/*    count={shipmentsMeta?.last_page}*/}
            {/*    page={currentPage}*/}
            {/*    onChange={handlePageChange}*/}
            {/*    shape="rounded"*/}
            {/*    disabled={shipmentsLoading}*/}
            {/*/>*/}
        </div>
    );
}

export default ShipmentPage;
