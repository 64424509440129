import React, { useEffect, useState } from 'react'
import FooterBlock from '../../FooterBlock'
import NavigationBar from '../../NavigationBar'

import axios from "axios"

// MUI
import { Button, Dialog, DialogTitle } from '@mui/material';

// hooks
import useAuth from "../../../hooks/auth";
import AddCompanyForm from '../forms/AddCompanyForm';
import JoinCompanyForm from '../forms/JoinCompanyForm';

import { BASE_API_URL } from '../../../config';
const API_URL = import.meta.env.VITE_API_URL;

function CompanyPage() {

  // form open bool
  const { loading, user } = useAuth();

  const [userCompanies, setUserCompanies] = useState<any[]>([]);

  useEffect(() => {
    axios.post(`${BASE_API_URL}/api/user/companies`, {}, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("sanctum")
      }
    })
    .then((res) => {
      // console.log(res);

      setUserCompanies(res.data);
    })
    .catch((err) => {
      // console.log(err);
    })
  }, [])

  // const userCompany = user.company_id;
  return (
    <main className="main">
      <NavigationBar />

      <div className="container" style={{ height: '500px' }}>
        <p>Your companies:</p>

        {
          userCompanies.map((company) => (
            <li
              key={company.id}
            >
              {company.display_name}
            </li>
          ))
        }

        {
          userCompanies.length == 0 && 'No companies'
        }

        <div>
          <div className='d-flex justify-content-around my-5'>
            <a href="/company/create">
              Add your company
            </a>
          </div>
        </div>
      </div>

      <FooterBlock />
    </main>
  )
}

export default CompanyPage
