import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Permission, Role } from "@/types/general";
import {
    AbilitySchemeTyping,
    collapseGroupsStructure,
    Group,
    Module,
    roleFormScheme,
    RoleFormTyping
} from "../../definitions";
import { zodResolver } from "@hookform/resolvers/zod";
import PermissionsCollapse
    from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Roles/components/MutationLayout/components/PermissionsCollapse";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import _ from "lodash";
import PermissionsRenderer
    from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Roles/components/MutationLayout/components/PermissionsRenderer";
import {
    PermissionsContext
} from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Roles/components/MutationLayout/components/context";
import BaseButton from "@reusables/BaseButton";

interface RoleMutationLayoutProps {
    role?: Role.Extended;
    permissions: Permission.Root;

    onMutation: (
        data: {
            name: string;
            description?: string;
            permissions: string[];
        }
    ) => void;
}

export default function RoleMutationLayout({ role, permissions, onMutation }: RoleMutationLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.roles.mutation" });

    const methods = useForm<RoleFormTyping>({
        resolver: zodResolver(roleFormScheme)
    });

    /**
     * Setting up form values based on the role & permissions
     */
    useEffect(() => {
        const normalizePermissions = (permissionsData: Permission.Root) => {
            const permissionsTransformed = _.cloneDeep(permissionsData);
    
            _.forEach(permissionsTransformed, (pt_value, pt_key) => {
                // Normalize MAIN permissions
                if (Array.isArray(pt_value.permissions)) {
                    pt_value.permissions = {} as any;  // Convert array to object
                }
    
                _.forEach(pt_value.permissions, (perms_value, perms_key) => {
                    // If no options, ensure permissions object structure
                    if (Array.isArray(perms_value.options)) {
                        perms_value.options = {};  // Convert options array to object
                    }
    
                    // Add the "checked" field from role data where it exists, or default to false
                    if (perms_value.options) {
                        _.forEach(perms_value.options, (option_value, option_key) => {
                            if(perms_value.options) {
                             _.set(perms_value.options, `${option_key}.checked`, option_value.checked ?? false);
                            }
                        });
                    } else {
                        // If no options, set the checked field for the permission itself
                        _.set(permissionsTransformed, `${pt_key}.permissions.${perms_key}.checked`, perms_value.checked ?? false);
                    }
                });
    
                // Normalize SUB permissions
                if (Array.isArray(pt_value.subPermissions)) {
                    pt_value.subPermissions = {};  // Convert array to object
                }
    
                _.forEach(pt_value.subPermissions, (subPerms_value, subPerms_key) => {
                    if (Array.isArray(subPerms_value.options)) {
                        subPerms_value.options = {};  // Convert sub options array to object
                    }
    
                    // Add the "checked" field from role data where it exists, or default to false
                    if (subPerms_value.options) {
                        _.forEach(subPerms_value.options, (option_value, option_key) => {
                            if (subPerms_value.options) {
                                _.set(subPerms_value.options, `${option_key}.checked`, option_value.checked ?? false);
                            }
                        });
                    } else {
                        // If no options, set the checked field for the sub-permission itself
                        _.set(permissionsTransformed, `${pt_key}.subPermissions.${subPerms_key}.checked`, subPerms_value.checked ?? false);
                    }
                });
            });
    
            return permissionsTransformed;
        };
    
        if (permissions || role) {
            const permissionsTransformed = role
                ? normalizePermissions(role.permissions)
                : normalizePermissions(permissions);     
    
            // Reset the form with normalized permissions and role details
            methods.reset({
                name: role?.name || '',
                description: role?.description || '',
                access: permissionsTransformed
            });
        }
    }, [role, permissions, methods]);
    

    /**
     * Handling form submission and transforming selected permissions values to the correct format
     */
    const onSubmit = methods.handleSubmit((data) => {
        // Helper function to transform abilities (extract keys or options keys based on "checked" state)
        const transformAbility = (ability: AbilitySchemeTyping) => {
            if (ability.options) {
                return _.map(
                    _.filter(ability.options, (option) => option.checked ?? false),
                    (option) => option.key
                );
            }
            return ability.checked ? ability.key : null;
        };

        // Helper function to process permissions (will be applied both on MAIN and SUB permissions)
        const processPermissions = (module_key: string, permissions: Record<string, AbilitySchemeTyping>, permissionsInlined: string[]) => {
            _.forEach(permissions, (perm) => {
                if (!perm) {
                    console.warn("During permissions transformation encountered: 'ability is undefined'");
                    return;
                }

                const checkedAbilities = transformAbility(perm);

                if (Array.isArray(checkedAbilities)) {
                    permissionsInlined.push(
                        ...checkedAbilities.map((option) => `${module_key}.${perm.key}.${option}`)
                    );
                } else if (checkedAbilities) {
                    permissionsInlined.push(`${module_key}.${checkedAbilities}`);
                }
            });
        };

        // Transforming permissions list
        const permissionsList: string[] = [];

        _.forEach(data.access, (module) => {
            processPermissions(module.key, module.permissions, permissionsList);
            processPermissions(module.key, module.subPermissions, permissionsList);
        });

        onMutation({
            name: data.name.trim(),
            ...(data.description && !_.isEmpty(data.description.trim()) && {
                description: data.description
            }),
            permissions: permissionsList,
        });
    }, console.error);

    return (
        <>
            <PermissionsContext.Provider value={{ permissions }}>
                <FormProvider {...methods}>
                    <form onSubmit={onSubmit}>
                        <div className={"mb-[32px]"}>
                            <BaseInputsGrid>
                                <Controller
                                    control={methods.control}
                                    name={"name"}
                                    render={
                                        ({ field, fieldState }) => (
                                            <BaseInput
                                                {...field}
                                                error={fieldState.error}
                                                label={t("fields.roleName.label")}
                                                placeholder={t("fields.roleName.placeholder")}
                                            />
                                        )
                                    }
                                />

                                <Controller
                                    control={methods.control}
                                    name={"description"}
                                    render={
                                        ({ field, fieldState }) => (
                                            <BaseInput
                                                {...field}
                                                error={fieldState.error}
                                                label={t("fields.roleDescription.label")}
                                                placeholder={t("fields.roleDescription.placeholder")}
                                            />
                                        )
                                    }
                                />
                            </BaseInputsGrid>
                        </div>
                        <div>
                            <div
                                className={"text-[#686868] text-[16px] font-thin mb-[24px]"}>{t("sections.permissions")}</div>
                            <div className={"grid grid-cols-2 gap-2"}>
                                {
                                    collapseGroupsStructure.map(((collapsable, collapsable_index) => {
                                        if (collapsable instanceof Module) {
                                            return (
                                                <PermissionsCollapse
                                                    key={collapsable_index}
                                                    title={permissions[collapsable.key].label}
                                                    type={"group"}
                                                >
                                                    <PermissionsRenderer
                                                        permissions={permissions[collapsable.key]} />
                                                </PermissionsCollapse>
                                            );
                                        } else if (collapsable instanceof Group) {
                                            return (
                                                <PermissionsCollapse
                                                    key={collapsable_index}
                                                    title={t("collapseGroups." + collapsable.key)}
                                                    type={"group"}
                                                >
                                                    {
                                                        collapsable.modules.map(((module, module_index) => (
                                                            <PermissionsCollapse
                                                                key={module_index}
                                                                title={permissions[module.key].label}
                                                            >
                                                                <PermissionsRenderer
                                                                    permissions={permissions[module.key]} />
                                                            </PermissionsCollapse>
                                                        )))
                                                    }
                                                </PermissionsCollapse>
                                            );
                                        }
                                    }))
                                }
                            </div>
                        </div>
                        <div className={"mt-[40px] flex flex-row items-center justify-center"}>
                            <BaseButton
                                size={"md"}
                                type={"submit"}
                                text={t("buttons.save")}
                            />
                        </div>
                    </form>
                </FormProvider>
            </PermissionsContext.Provider>
        </>
    );
}
