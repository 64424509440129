import React from "react";
import { Drawer } from "@mui/material";
import DrawerContent from "../DrawerContent";
import { SidebarItemT } from "../types";

interface DrawerProperties{
  items: SidebarItemT[];
  isMinified?: boolean
}

export default function PermanentDrawer({
  items,
  isMinified = false
                                        }: DrawerProperties): JSX.Element{
  return(
    <Drawer
      variant="permanent"
      open
      sx={{
        ".MuiDrawer-paper":{
          position: "sticky",
          height: "100vh",
          width: isMinified ? "84px" : "302px",
          backgroundColor: "#FCFAF4",
          borderRightColor: "#D9DAFD",
          overflowX: "hidden",
          transition: "all .25s",
          paddingBottom: "20px"
        }
      }}
      className={`permanent-drawer${isMinified ? " minified" : ""}`}
    >
      <DrawerContent items={items} isMinified={isMinified}/>
    </Drawer>
  );
}