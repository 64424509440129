import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCreateRoleMutation } from "@redux/features/roles/rolesApi";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import { useGetPermissionsQuery } from "@redux/features/permissions/permissionsApi";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { Role } from "@/types/general";
import { toast } from "react-toastify";
import RoleMutationLayout from "../components/MutationLayout";
import { toastError } from "@helpers/utils";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function RolesCreationPage() {
    const history = useHistory();
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.roles.creation" });

    const [createRole, { isLoading: isCreationLoading }] = useCreateRoleMutation();
    const { data: permissions, isLoading: isPermissionsLoading } = useGetPermissionsQuery();


    return (
        <>
            <Can not I="role.edit" a="general">
                <NoPermissionBanner />
            </Can>

            <Can I="role.edit" a="general">
                <BaseLoadingBlocker active={isCreationLoading}>
                    <BaseRecordRenderer
                        loading={isPermissionsLoading}
                        record={permissions}
                        hideLevitation
                    >
                        <div className="space-y-[40px]">
                            <div className="flex items-center">
                                <h6 className="text-accent grow">{t("heading")}</h6>
                            </div>
                            <RoleMutationLayout
                                permissions={permissions!}
                                onMutation={data => {
                                    const requestData: Role.DTO.Create = {
                                        name: data.name,
                                        description: data.description,
                                        permissions: data.permissions,
                                    };

                                    createRole(requestData).unwrap().then(() => {
                                        history.push("/dashboard/settings/company/roles");
                                        toast.success(t("responses.success"));
                                    }).catch(e => {
                                        toastError(e);
                                        console.error(e);
                                    });
                                }}
                            />
                        </div>
                    </BaseRecordRenderer>
                </BaseLoadingBlocker>
            </Can>
        </>
    );
}