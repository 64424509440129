import OrderDetails from "@components/Dashboard/pages/Sales/Orders/components/ViewLayout/OrderDetails";
import { SaleOrder } from "@/types/general";
import OrderPickings from "@components/Dashboard/pages/Sales/Orders/components/ViewLayout/OrderPickings";

export interface ViewLayoutProps {
    saleOrder: SaleOrder.Extended;
}

const ViewLayout = {
    Main: OrderDetails,
    Pickings: OrderPickings,
}

export default ViewLayout;