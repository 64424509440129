import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";

type GridDefinition = Partial<Record<"xs" | "sm" | "md" | "lg" | "xl", string | number>>;

export type BaseInputsGridProps = {
    title?: JSX.Element | string;
    cols?: number | GridDefinition;
    gap?: number | { x?: number; y?: number }
}

export default function BaseInputsGrid({
                                           title,
                                           cols = 3,
                                           gap = 32,
                                           children
                                       }: PropsWithChildren<BaseInputsGridProps>) {
    return (
        <>
            {
                title &&
                typeof title === "string"
                    ?
                    <div className="text-xl text-accent">{title}</div>
                    :
                    title
            }
            <Box sx={{
                display: "grid",
                gridTemplateColumns: typeof cols === "number" ? `repeat(${cols}, 1fr)` : transformGridDefinition(cols),
                gap: typeof gap === "number" ? `${gap}px` : `${gap.y}px ${gap.x}px`
            }}>
                {children}
            </Box>
        </>
    );
}

const transformGridDefinition = (gridDefinition: GridDefinition): GridDefinition => {
    const transformed: GridDefinition = {} as GridDefinition;

    for (const key in gridDefinition) {
        transformed[key as keyof GridDefinition] = `repeat(${gridDefinition[key as keyof GridDefinition]}, 1fr)`;
    }

    return transformed;
};