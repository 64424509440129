import React, { useState } from "react";

type BaseIconTransitionProps = {
    children: (hovered: boolean) => JSX.Element;
}

export default function BaseHoverTransition(props: BaseIconTransitionProps) {
    const [hovered, setHovered] = useState(false);

    return (
        <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            {
                props.children(hovered)
            }
        </div>
    )
}