import React, {useEffect} from "react";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import {useTranslation} from "react-i18next";
import BaseDatepicker from "@reusables/BaseDatepicker";
import {SaleOrdersFormTyping} from "../../types";
import {useGetCustomersQuery, useGetModuleCustomersQuery} from "@redux/features/customers/customersApi";
import CreateCustomerModal from "./CreateCustomerModal";
import {useLocation} from "react-router-dom";

interface RouterStateType {
    customer: {
        id: number;
        name: string;
        code?: string;
    }
}

export default function GeneralSection() {
    const {t} = useTranslation("", {keyPrefix: "sales.orders.general"});

    const location = useLocation<RouterStateType>();

    const routerState = location.state;

    const {control, setValue} = useFormContext<SaleOrdersFormTyping>();

    // === Modals ===
    const [isNewCustomerModalOpen, setIsNewCustomerModalOpen] = React.useState(false);

    const isInvoiced = useWatch({
        control,
        name: "is_invoiced"
    });

    const mode = useWatch({
        control,
        name: "mode"
    });

    // === RTKQ ===
    const {data: customerOptions, isLoading: isCustomerOptionsLoading} = useGetModuleCustomersQuery("sale", {
        skip: mode === "editing"
    });

    useEffect(() => {
        if (routerState?.customer) {
            setValue("customer", {
                id: routerState.customer.id,
                name: routerState.customer.name,
                code: routerState.customer.code
            });
        }
    }, [routerState]);

    return (
        <>
            <BaseInputsGrid>
                {/* Supplier */}
                <Controller
                    name="customer"
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDropdown
                            {...field}
                            {...fieldState}

                            label={`${t("dropdowns.customer.label")} *`}
                            placeholder={t("dropdowns.customer.placeholder")}
                            options={customerOptions}
                            getter={{
                                label: (item) => item.name,
                                key: (item) => item.id,
                                caption: item => item.code ?? item.id
                            }}

                            autocomplete

                            action={{
                                // title: t("dropdowns.customer.create"),
                                title: t("dropdowns.customer.buttons.create"),
                                onClick: () => setIsNewCustomerModalOpen(true)
                            }}

                            disableClearable

                            disabled={mode === "editing"}

                            isLoading={isCustomerOptionsLoading}

                            virtualize
                        />
                    )}
                />

                {/* Purchase date */}
                <Controller
                    name="order_date"
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDatepicker
                            {...field}
                            {...fieldState}

                            label={`${t("fields.orderDate.label")} *`}
                            placeholder={t("fields.orderDate.placeholder")}
                            disablePast

                            disabled={isInvoiced}
                        />
                    )}
                />

                {/* Preferred delivery date */}
                <Controller
                    name="preferred_delivery_date"
                    control={control}
                    render={({field, fieldState}) => (
                        <BaseDatepicker
                            {...field}
                            {...fieldState}

                            label={t("fields.preferredDeliveryDate.label")}
                            placeholder={t("fields.preferredDeliveryDate.placeholder")}
                            disablePast

                            disabled={isInvoiced}
                        />
                    )}
                />

            </BaseInputsGrid>

            <CreateCustomerModal
                isOpen={isNewCustomerModalOpen}
                onClose={() => setIsNewCustomerModalOpen(false)}
                onCreate={cus => {
                    setValue("customer", {
                        id: cus.id,
                        name: cus.name,
                        code: cus.code
                    });

                    setIsNewCustomerModalOpen(false)
                }}
            />
        </>
    )
}