import React from "react";
import "./index.scss";
import "@assets/stylesheets/inc/_table.scss";
import MainPage from "./subpages/MainPage";

import UpcomingShipments from "./UpcomingShipments";

function DashboardPage() {
    return (
        <div className="flex flex-column content-between gap-[24px]">
            <UpcomingShipments />
            <MainPage />
        </div>
    );
}

export default DashboardPage;
