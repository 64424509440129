import React from "react";
import { Control, Controller, useWatch } from "react-hook-form";
import { Box, Stack } from "@mui/material";
import BaseDropdown from "@reusables/dropdowns/BaseDropdown";
import BaseTooltip from "@reusables/BaseTooltip";
import BaseMaterialIconButton from "@reusables/BaseMaterialIconButton";
import { ReactComponent as PlusSVG } from "@assets/icons/ic_plus.svg";
import { useTranslation } from "react-i18next";
import { ProduceFormTyping } from "@components/Dashboard/pages/BOM/components/modals/ProduceModal/types";

type OutgoingLocationCellProps = {
    index: number;
    control: Control<ProduceFormTyping>;
    onAdd: () => void;
};

export default function OutgoingLocationCell({ control, index, onAdd }: OutgoingLocationCellProps) {
    const { t } = useTranslation("", { keyPrefix: "bom.produceModal" });

    const row = useWatch({
        control,
        name: `components_picks.${index}`
    });

    if (!row) return null;

    return (
        <Stack spacing={"16px"}>
            {row.sources?.map((source, sourceIndex) => {
                const allowAddingLocation = 
                    sourceIndex === row.sources.length - 1
                    && row.sources.length < row.component.locations.length;

                return (
                    <Stack
                        key={sourceIndex}
                        direction={"row"}
                        spacing={"8px"}
                        alignItems="center"
                    >
                        <Box
                            width={"100%"}
                            marginRight={allowAddingLocation ? "0px" : "40px"}
                        >
                            <Controller
                                name={`components_picks.${index}.sources.${sourceIndex}.locations`}
                                control={control}
                                render={({ field, fieldState }) => {
                                    return (
                                        <BaseDropdown
                                            {...field}
                                            error={fieldState.error}
                                            options={row.component.locations.filter((loc) => {
                                                const alreadySelected = row.sources.map((source) => source.locations?.store.id);
                                                return !alreadySelected.includes(loc.store.id);
                                            })}
                                            getter={{
                                                label: (opt) => `${opt.store.name}${opt.section ? ` - ${opt.section.name}` : ""}`,
                                                key: (opt) => opt.section ? `${opt.store.id}_${opt.section.id}` : `${opt.store.id}`
                                            }}

                                            hideErrors
                                            customize={{
                                                padding: "5px 35px 5px 5px",
                                            }}
                                        />
                                    );
                                }}
                            />
                        </Box>
                        <BaseTooltip
                            title={`${t("actions.addLocation")}`}
                            placement="bottom-start"
                            hidden={!allowAddingLocation}
                        >
                            <BaseMaterialIconButton
                                color="neutral"
                                size="small"
                                icon={<PlusSVG width={16} height={16} color={"#686868"}/>}
                                onClick={onAdd}
                            />
                        </BaseTooltip>
                    </Stack>
                );
            })}
        </Stack>
    );
}
