import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as CrossCircledSVG } from "@assets/icons/ic_cross_circled.svg";
import { Controller, useFormContext } from "react-hook-form";
import { ProductSchemeTyping } from "@components/Dashboard/pages/Products/components/CreationEditingLayout/types";
import BaseImageUploader from "@reusables/BaseImageUploader";
import BaseInput from "@reusables/BaseInput";

export default function Description() {
    const { t } = useTranslation("", { keyPrefix: "products" });

    const { control } = useFormContext<ProductSchemeTyping>();

    return (
        <>
            <div className="text-xl text-accent">{t("general.categories.attachments")}</div>
            <div className={`flex flex-row space-x-[40px]`}>
                <Controller
                    name={"description.image"}
                    control={control}
                    render={({ field, fieldState }) => (
                        <div>
                            {
                                <div className="flex flex-column justify-center w-[120px] relative">
                                    {!!field.value && (
                                        <CrossCircledSVG
                                            className="absolute top-[-12px] right-[-12px] text-gray-600 cursor-pointer"
                                            width={24}
                                            height={24}
                                            onClick={() => {
                                                field.onChange(undefined);
                                            }}
                                        />
                                    )}
                                    <BaseImageUploader {...field} {...fieldState} error={fieldState.error} size={{ width: 120, height: 120 }} />
                                </div>
                            }
                        </div>
                    )}
                />
                <div className="grow">
                    <Controller
                        name={"description.description"}
                        control={control}
                        render={({ field, fieldState }) => <BaseInput {...field} {...fieldState} label={t("general.fields.attachmentDescription")} multiline rows={2} />}
                    />
                </div>
            </div>
        </>
    );
}
