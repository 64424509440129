import React from "react";
import MutationLayout from "../components/MutationLayout";
import {
    useGetCustomerGroupQuery,
    useUpdateCustomerGroupMutation
} from "@redux/features/customerGroups/customerGroupsApi";
import { useHistory, useParams } from "react-router-dom";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { CustomerGroups } from "@/types/general";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import dayjs from "dayjs";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function CustomerGroupEditingPage() {
    const {t} = useTranslation("", {keyPrefix: "customerGroups.editing"});

    const history = useHistory();
    const {id} = useParams<{ id: string }>();

    const { data: customerGroup, isLoading: customerGroupLoading } = useGetCustomerGroupQuery(parseInt(id));

    const [updateCustomerGroup, { isLoading: isUpdatingLoading }] = useUpdateCustomerGroupMutation();

    return (
        <>
            <Can not I="edit" a="customer">
                <div className="levitation-extended">
                    <NoPermissionBanner />
                </div>
            </Can>

            <Can I="edit" a="customer">
                <BaseRecordRenderer
                    record={customerGroup}
                    loading={customerGroupLoading}
                >
                    <BaseLoadingBlocker active={isUpdatingLoading}>
                        <div className='space-y-[40px]'>
                            <div className='text-2xl text-accent font-bold'>{customerGroup?.name}</div>
                            <MutationLayout
                                customerGroup={customerGroup}
                                onSubmit={data => {
                                    const updateData: CustomerGroups.DTO.Update = {
                                        code: data.code,
                                        name: data.name,
                                        discount: data.discount,
                                        customers: data.customers?.map(customer => customer.value.id),
                                        products: data.products?.map(product => {
                                            return {
                                                id: product.product.id,
                                                sale_price: product.sale_price,
                                                valid_from: product.valid_from?.format("YYYY-MM-DD") || dayjs().format("YYYY-MM-DD"),
                                                valid_to: product.valid_to?.format("YYYY-MM-DD")
                                            }
                                        })
                                    };

                                    updateCustomerGroup({ id: customerGroup?.id as number, ...updateData }).unwrap().then(resp => {
                                        history.push(`/dashboard/customer-groups/${resp.id}/details`);

                                        toast.success(t("responses.success"));
                                    }).catch(e => {
                                        if (isErrorWithMessage(e)) {
                                            toast.error(e.message)
                                        } else {
                                            toast.error(t("responses.error"))
                                        }
                                    });
                                }}
                            />
                        </div>
                    </BaseLoadingBlocker>
                </BaseRecordRenderer>
            </Can>
        </>
    )
}
