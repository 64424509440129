import React, { useEffect } from "react";
import BaseCheckbox from "@reusables/BaseCheckbox";
import { Permission } from "@/types/general";
import { useController, useFormContext, useWatch } from "react-hook-form";
import {
    doesContainOptions,
    RoleFormTyping
} from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Roles/definitions";
import _ from "lodash";
import BaseBadge from "@reusables/BaseBadge";
import BaseTooltip from "@reusables/BaseTooltip";
import {
    usePermissionsContext
} from "@components/Dashboard/pages/SettingsPage/General/CompanyInfo/subpages/Roles/components/MutationLayout/components/context";
import { useTranslation } from "react-i18next";

interface BooleanOptionProps {
    ability: Permission.Components.Ability;
    path: string;
}

export default function BooleanAbility({ ability, path }: BooleanOptionProps) {
    const methods = useFormContext<RoleFormTyping>();
    const permissions = usePermissionsContext();

    let isDisabled = false;
    let dependencyLabel: string | undefined = undefined;

    // console.log("Checking for ", ability);

    if (ability.dependency) {
        // console.log("Ability path: ", ability.dependency);
        const dependencyPathSplit = ability.dependency.split(".");
        const dependencyPath = `${dependencyPathSplit[0]}.permissions.${dependencyPathSplit[1]}`;

        // Tracking parent object (may or may not contain "options", will determine later)
        const pathWatcher = useWatch({
            control: methods.control,
            name: "access." + dependencyPath as any
        });

        // Additional check: Ensure "view" is also checked
        const viewPermissionPath = `${dependencyPathSplit[0]}.permissions.view.checked`;
        const isViewChecked = useWatch({
            control: methods.control,
            name: `access.${viewPermissionPath}` as any
        });

        // If "view" is unchecked, also disable this permission
        if (!isViewChecked) {
            isDisabled = true;
            dependencyLabel = "View"; // Dependency on "view"
        }

        if (!pathWatcher)
            return <span className={"text-red-400"}>Unsatisfied dependency: access.{dependencyPath} </span>;

        // Setting label name for "Requires [module]" tooltip
        dependencyLabel = _.get(permissions.permissions, dependencyPath).label;

        if (doesContainOptions(pathWatcher)) {
            // If contains "options", meaning that it rather depends on of them or any selection suffices

            const { options } = pathWatcher;

            if (dependencyPathSplit.length > 2) {
                // Meaning, that path directly depends on some of the options
                isDisabled = !options[dependencyPathSplit[2]].checked;
            } else {
                // Meaning that any selection suffices
                isDisabled = !_.values(options).some(x => x.checked);
            }
        } else {
            // If it doesn't contain "options", meaning that we should check for "checked" field inside

            isDisabled = !pathWatcher.checked;
        }
        // console.log(`Tracking for ${ability.key} (${isDisabled}): `, pathWatcher);
    } else {
        // No dependency case: enforce that the "view" permission is required
        const viewPermissionPath = path.replace(/[^.]+$/, 'view.checked'); // Replaces the current key with 'view.checked'

        const isViewChecked = useWatch({
            control: methods.control,
            name: `access.${viewPermissionPath}` as any,
        });

        // If it's not "view" and "view" is unchecked, disable this permission
        if (ability.key !== 'view' && !isViewChecked) {
            isDisabled = true;
            dependencyLabel = "View";
        }
    }

    return (
        <div>
            <div className={"inline-block"}>
                <BooleanCheckbox
                    label={ability.label}
                    path={"access." + path + ".checked"}
                    dependencyLabel={dependencyLabel}
                    isDisabled={isDisabled}
                />
            </div>
        </div>
    );
}

interface BooleanCheckboxProps {
    path: string;
    dependencyLabel?: string;
    isDisabled: boolean;
    label: string;
}

/**
 * Checkbox, placed in a separate component to provide auto-unchecking, when field is set to be disabled
 */
function BooleanCheckbox({ isDisabled, ...props }: BooleanCheckboxProps) {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.roles.mutation" });

    const methods = useFormContext<RoleFormTyping>();

    const { field } = useController({
        name: props.path as never,
        control: methods.control
    });

    useEffect(() => {
        if (isDisabled) {
            field.onChange(false);
        }
    }, [isDisabled]);

    return (
        <div>
            <BaseTooltip
                title={
                    <>
                        <span className={"mr-2"}>{t("dependsOn")}</span>
                        <BaseBadge className={"!bg-[#F3F2EF]"}>{props.dependencyLabel}</BaseBadge>
                    </>
                }
                active={isDisabled}
                placement={"top"}
            >
                <BaseCheckbox
                    label={props.label}
                    value={field.value}
                    onChange={(val) => {
                        field.onChange(val);
                    }}
                    disabled={isDisabled}
                />
            </BaseTooltip>
        </div>
    );
}