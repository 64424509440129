import _ from "lodash";
import { Column } from "./parts/ColumnsSelector";

export type BaseExportModalConfiguration<Filters = any, OrderBy = any> = {
    filters?: Filters;
    orderBy?: OrderBy;
}

export type BaseExportModalPropsProvider<Filters = any, OrderBy = any> = {
    isOpen?: boolean;
    onClose?: () => void;
} & BaseExportModalConfiguration<Filters, OrderBy>;

export function adaptToExportRequest<Columns, Filters = any, OrderBy = any>(
    ...data: (BaseExportModalConfiguration<Filters, OrderBy> & { columns?: Column<string>[] })[]
) {
    const obj: any = {};

    for (const item of data) {
        if ("email" in item) {
           _.set(obj, "email", item.email);
        }

        if ("columns" in item && !_.isEmpty(item.columns)) {
            _.set(obj, "columns", item.columns?.map(column => column.key));
        }

        if ("filters" in item && !_.isEmpty(item.filters)) {
            _.set(obj, "filters", item.filters);
        }

        if ("orderBy" in item && !_.isEmpty(item.orderBy)) {
            _.set(obj, "orderBy", item.orderBy);
        }
    }

    return obj as {
        email: string;
        columns: Columns[];
        filters: Filters;
        orderBy: OrderBy;
    };
}
