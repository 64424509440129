import React from "react";
import {createPortal} from "react-dom";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import BaseInput from "@reusables/BaseInput";
import {ScanBarcode} from "lucide-react";
import {
    PurchaseOrdersFormTyping
} from "@components/Dashboard/pages/PurchasingPage/PurchasesPage/components/MutationLayout/types";
import {Link} from "react-router-dom";

export default function OrderReference() {
    const {control} = useFormContext<PurchaseOrdersFormTyping>();

    const referenceNumberId = useWatch({
        control: control,
        name: "reference_number.id"
    });

    const referenceNumberElement = document.getElementById("reference_number")

    const Icon = <>
            {
                referenceNumberId
                    ? <Link to={`/dashboard/sales/orders/${referenceNumberId}/details`}><ScanBarcode color={"#a0a0a0"}/></Link>
                    : <ScanBarcode color={"#a0a0a0"}/>
            }
    </>

    return <>
        {
            referenceNumberElement && createPortal(
                <Controller
                    control={control}
                    name={"reference_number.code"}
                    render={({field, fieldState}) => (
                        <BaseInput
                            {...field}
                            error={fieldState.error}
                            icon={{
                                right: {
                                    el: Icon,
                                    offset: "12px"
                                }
                            }}
                        />
                    )}
                />,
                referenceNumberElement
            )
        }
    </>
}