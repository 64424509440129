import React, { useMemo } from "react";
import { ViewLayoutProps } from "./types";
import { useTranslation } from "react-i18next";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseTable from "@reusables/BaseTable";
import { normalizePrice } from "@helpers/utils";
import { useComponentsSummary } from "../helpers/useComponentsSummary";
import { useMaxProducts } from "../helpers/useMaxProducts";
import { Link } from "react-router-dom";

export default function BOMDetails({ bom }: ViewLayoutProps) {
    const { t } = useTranslation("", { keyPrefix: "bom.mutation" });

    const componentsSummary = useComponentsSummary(bom.components);
    const maxProducts = useMaxProducts(bom.components);

    const blocks = useMemo<Record<"general", InfoItem[]>>(
        () => ({
            general: [
                {
                    title: t("dropdowns.productName.label"),
                    data: () => (
                        <Link to={`/dashboard/products/${bom.product.id}/details`}>
                            <u>{bom.product?.name}</u>
                        </Link>
                    )
                },
                {
                    title: t("fields.bomName"),
                    data: () => bom?.name
                }
            ]
        }),
        [bom, t]
    );

    return (
        <div className="space-y-[40px]">
            <BaseInputsGrid cols={3}>
                {blocks.general.map((item, index) => (
                    <div key={index}>
                        <div className="mb-[16px]">{item.title}</div>
                        <div className="space-x-[24px]">{item.data()}</div>
                    </div>
                ))}
            </BaseInputsGrid>

            <div className="border border-solid border-gray-300 rounded-[8px] p-[16px]">
                {bom.components?.length ? (
                    <>
                        <div className="flex mb-[46px] items-center space-x-[8px]">
                            <div className="text-xl font-thin text-gray-600 grow">{t("table.components")}</div>
                        </div>

                        <BaseTable
                            data={bom.components ?? []}
                            columns={[
                                {
                                    header: t("table.columns.0"),
                                    getter: (row) => (
                                        <Link to={`/dashboard/products/${row.product.id}/details`}>
                                            <u>{row.product?.name}</u>
                                        </Link>
                                    )
                                },
                                {
                                    header: t("table.columns.1"),
                                    getter: (row) => row?.quantity
                                },
                                {
                                    header: t("table.columns.2"),
                                    getter: (row) => normalizePrice(row.product?.unit_price)
                                },
                                {
                                    header: t("table.columns.3"),
                                    getter: (row) => normalizePrice(row.quantity * row.product?.unit_price)
                                },
                                {
                                    header: "In stock",
                                    getter: (row) => row.product?.in_stock
                                }
                            ]}
                            size={"small"}
                            headerSx={{
                                background: "#F9F9F9"
                            }}
                        />
                        <div className="flex justify-end mt-[48px] font-thin">
                            <div className="grid grid-cols-2 gap-y-[16px]">
                                <div className="text-right text-accent">{t("quantityCanProduced")}</div>
                                <div className="font-semibold text-right pr-[16px] text-accent">{maxProducts}</div>
                                <div className="font-bold text-right text-accent">{t("totalPrice")}</div>
                                <div className="font-bold text-right pr-[16px] text-accent">{normalizePrice(componentsSummary.total)}</div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="text-xl text-center text-gray-300 font-thin">{t("table.noComponents")}</div>
                )}
            </div>
        </div>
    );
}

type InfoItem = {
    title: string;
    data: () => JSX.Element | string;
}