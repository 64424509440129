import React from "react";
import { Integration } from "@/types/general";
import { modules_links } from "@components/Dashboard/DashboardPage/subpages/FeedPage/config";
import { useTranslation } from "react-i18next";
import BaseBadge from "@reusables/BaseBadge";
import BaseIntegrationInject from "@reusables/BaseIntegrationInject";
import { Link } from "react-router-dom";
import { ChevronRight } from "lucide-react";
import { Box } from "@mui/material";

type OriginLinkProperties = {
    origin: {
        id?: number | null;
        name: string;
    }
}

type KeywordMapping = {
    translation: string;
    integrationSlug?: Integration.Components.Slug;
    link?: (id: number) => string;
}

const keywordMappings: Record<string, KeywordMapping> = {
    "manual": {
        translation: "manual"
    },
    "lime_product": {
        translation: "product",
        integrationSlug: "lime"
    },
    "lime_order": {
        translation: "sale_order",
        integrationSlug: "lime",
        link: (id: number) => [modules_links["sale_order"]?.link, id, "details"].join("/")
    },
    "purchase_order": {
        translation: "purchase_order",
        link: (id: number) => [modules_links["purchase_order"]?.link, id, "details"].join("/")
    },
    // TODO: [nekear] we have inventory instead of this module
    // "adjustment": {
    //     translation: "adjustment",
    //     link: (id: number) => [modules_links["adjustment"]?.link, id, "details"].join("/")
    // },
    "sale_order": {
        translation: "sale_order",
        link: (id: number) => [modules_links["sale_order"]?.link, id, "details"].join("/")
    },
    "tripletex": {
        translation: "product",
        integrationSlug: "tripletex"
    }
};

export default function OriginLink({ origin }: OriginLinkProperties) {
    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct.tabs.prices.table.origins" });

    // Checking for the mapping presence
    const mapping = keywordMappings[origin.name];
    if (!mapping) return <>{origin.name}</>;

    // Getting the translation
    const originTranslation = t(`${mapping.translation}`);

    const originContent = mapping.integrationSlug ? (
            <BaseIntegrationInject integration={mapping.integrationSlug}>
                {
                    (integration) =>
                        <>
                            <BaseBadge
                                image={<img src={integration?.imageUrl} width={12}
                                            height={12} />}
                            >
                                {integration?.name ?? "unknown"}
                                <ChevronRight size={12} />
                                <span className={"clickable-if-needed"}>{originTranslation}</span>
                            </BaseBadge>
                        </>
                }
            </BaseIntegrationInject>
        ) :
        <BaseBadge className={"bg-gray-100"}>
            <span className={"clickable-if-needed"}>{originTranslation}</span>
        </BaseBadge>;

    return (
        <>
            {
                mapping.link && origin.id ?
                    <Box sx={{ ".clickable-if-needed": { textDecoration: "underline" } }}>
                        <Link to={mapping.link(origin.id)}>
                            {originContent}
                        </Link>
                    </Box>
                    :
                    <>{originContent}</>
            }
        </>
    );
}