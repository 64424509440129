import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetRoleQuery, useUpdateRoleMutation } from "@redux/features/roles/rolesApi";
import { BaseLoadingBlocker } from "@reusables/blockers/BaseLoadingBlocker";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { useGetPermissionsQuery } from "@redux/features/permissions/permissionsApi";
import { Role } from "@/types/general";
import { toast } from "react-toastify";
import RoleMutationLayout from "../components/MutationLayout";
import { useAppDispatch } from "@redux/hooks";
import { setRole } from "@redux/features/auth/authSlice";
import { toastError } from "@helpers/utils";
import { Can } from "@/casl.config";
import NoPermissionBanner from "@/components/ErrorPages/NoPermissionBanner";

export default function RolesEditingPage() {
    const { t } = useTranslation("", { keyPrefix: "settings.general.companyInformation.tabs.roles.editing" });

    const dispatch = useAppDispatch();

    const history = useHistory();

    const { id } = useParams<{ id: string }>();

    const { data: role, isLoading: isRoleLoading } = useGetRoleQuery(parseInt(id), {
        skip: !id
    });

    const { data: permissions, isLoading: isPermissionsLoading } = useGetPermissionsQuery();

    const [updateRole, { isLoading: isUpdateLoading }] = useUpdateRoleMutation();

    return (
        <>
            <Can not I="role.edit" a="general">
                <NoPermissionBanner />
            </Can>
            
            <Can I="role.edit" a="general">
                <BaseLoadingBlocker active={isUpdateLoading}>
                    <BaseRecordRenderer
                        loading={isRoleLoading || isPermissionsLoading}
                        record={() => role && permissions}
                        hideLevitation
                    >
                        <div className="space-y-[40px]">
                            <div className="flex items-center">
                                <h6 className="text-accent grow">{role?.name}</h6>
                            </div>
                            <RoleMutationLayout
                                role={role}
                                permissions={permissions!}
                                onMutation={data => {
                                    if (role) {
                                        const requestData: Role.DTO.Update = {
                                            name: data.name,
                                            description: data.description,
                                            permissions: data.permissions
                                        };

                                        updateRole({
                                            id: role.id,
                                            ...requestData
                                        }).unwrap().then(data => {
                                            // dispatch(setRole(data));
                                            // TODO: uncomment, when CASL structure is changed to support new permissions system
                                            // updateAbility(data.permissions);

                                            history.push("/dashboard/settings/company/roles");
                                            toast.success(t("responses.success"));
                                        }).catch(e => {
                                            toastError(e);
                                            console.error(e);
                                        });
                                    }
                                }}
                            />
                        </div>
                    </BaseRecordRenderer>
                </BaseLoadingBlocker>
            </Can>
        </>
    );
}