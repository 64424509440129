import React from "react";
import BaseButton from "@reusables/BaseButton";
import { toast } from "react-toastify";
import BaseModal from "@reusables/Modals/BaseModal";
import { useTranslation } from "react-i18next";
import { useDeleteReceiptMutation } from "@redux/features/receipts/receiptsApi";

interface DeletionModalProperties {
    isOpen: boolean;
    entity_id?: number;
    onClose: () => void;
    onDeletion?: () => void;
}

export default function DeletionModal(props: DeletionModalProperties){
    const { t } = useTranslation("", { keyPrefix: "receive.general.modals.deletion" });

    const [receive, { isLoading: isActiveDeletionLoading }] = useDeleteReceiptMutation();

    return (
        <BaseModal
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={700}
            padding="56px"
            isLoading={isActiveDeletionLoading}
        >
            <div className="space-y-[32px]">
                <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
                <div className="text-center text-accent">{t("subheading")} <span className="bold-highlight">RCV{props.entity_id}?</span></div>
                <div className="grid grid-cols-2 gap-[16px]">
                    <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose} />
                    <BaseButton text={t("buttons.delete")} size="md" onClick={() => {
                        if(props.entity_id) {
                            receive(props.entity_id).then(() => {
                                toast.success(t("responses.success"));
                                props.onDeletion?.();
                            }).catch(e => {
                                console.error(e);

                                toast.error(t("responses.error"));
                            }).finally(() => {
                                props.onClose();
                            })
                        }
                    }}/>
                </div>
            </div>
        </BaseModal>
    )
}