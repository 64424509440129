import { internalApiSlice, transformResponse } from "@redux/api/internalApiSlice";
import { CustomFields } from "@/types/general";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@redux/hooks";
import _ from "lodash";

export const customFieldsApi = internalApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomFields: builder.query<CustomFields.Root[], { module?: CustomFields.Components.Modules } | void>({
            query: (args) => {
                return {
                    url: `custom-fields`,
                    method: "GET",
                    params: {
                        ...(!!args?.module && {
                            module: args.module
                        })
                    }
                };
            },
            providesTags: [{ type: "CustomFields", id: "LIST" }],
            transformResponse
        }),

        createCustomField: builder.mutation<CustomFields.Root, CustomFields.DTO.Create>({
            query: (body) => ({
                url: `custom-fields`,
                method: "POST",
                data: body
            }),
            transformResponse,
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: createdCustomField } = await queryFulfilled;
                    const createResult = dispatch(customFieldsApi.util.updateQueryData("getCustomFields", undefined, (draft) => {
                        draft.push(createdCustomField);
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        updateCustomField: builder.mutation<CustomFields.Root, CustomFields.DTO.Update & { id: number }>({
            query: ({ id, ...body }) => ({
                url: `custom-fields/${id}`,
                method: "PUT",
                data: body
            }),
            transformResponse,
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedCustomField } = await queryFulfilled;
                    const updateResult = dispatch(customFieldsApi.util.updateQueryData("getCustomFields", undefined, (draft) => {
                        const index = draft.findIndex(cf => cf.id === updatedCustomField.id);
                        if (index !== -1) {
                            draft[index] = updatedCustomField;
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        toggleCustomField: builder.mutation<CustomFields.Root, number>({
            query: (id) => ({
                url: `custom-fields/${id}/toggle-disabled`,
                method: "PUT"
            }),
            transformResponse,
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedCustomField } = await queryFulfilled;
                    const updateResult = dispatch(customFieldsApi.util.updateQueryData("getCustomFields", undefined, (draft) => {
                        const index = draft.findIndex(cf => cf.id === updatedCustomField.id);
                        if (index !== -1) {
                            draft[index] = updatedCustomField;
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        }),

        deleteCustomField: builder.mutation<boolean, number>({
            query: (id) => ({
                url: `custom-fields/${id}`,
                method: "DELETE"
            }),
            transformResponse,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(customFieldsApi.util.updateQueryData("getCustomFields", undefined, (draft) => {
                        const index = draft.findIndex(cf => cf.id === arg);
                        if (index !== -1) {
                            draft.splice(index, 1);
                        }
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        })
    })
});

const selectCustomFieldsResult = customFieldsApi.endpoints.getCustomFields.select();

const selectAllCustomFields = createSelector(
    selectCustomFieldsResult,
    (custom_fields) => custom_fields?.data ?? []
);

export const selectAllCustomFieldsGroupedByModule = createSelector(
    selectAllCustomFields,
    (custom_fields) => {
        // Grouping by module
        const grouped = _.groupBy(custom_fields, cf => cf.module);

        // Adding "key" field to the grouped object entries
        return _.values(
            _.mapValues(grouped, (fields, key) => ({
                key: key as CustomFields.Components.Modules,
                fields
            }))
        );
    }
);

export const selectCustomFieldById = createSelector(
    selectAllCustomFields,
    (state: RootState, cf_id: number | undefined) => cf_id,
    (custom_fields, cf_id) => cf_id != undefined ? custom_fields.find(cf => cf.id === cf_id) : undefined
);

export const {
    useGetCustomFieldsQuery,
    useCreateCustomFieldMutation,
    useUpdateCustomFieldMutation,
    useToggleCustomFieldMutation,
    useDeleteCustomFieldMutation
} = customFieldsApi;
