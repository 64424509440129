import React from "react";
import Fade from "@mui/material/Fade/Fade";
import BaseNotFound from "../BaseNotFound";
import { CircularProgress } from "@mui/material";

export default function BaseRecordRenderer(props: {
    record?: unknown | (() => boolean);
    loading: boolean;
    notFoundText?: string;
    children: React.ReactNode;
    dontShowNotFound?: boolean;
    hideLevitation?: boolean;
    bannerHeight?: number;
}) {
    const recordPresence = typeof props.record === "function" ? props.record() : !!props.record;

    return (
      <div className={`relative ${props.hideLevitation ? "" : "levitation-extended"}`}>
          {
              props.dontShowNotFound && props.children
          }

          <Fade
            in={props.loading}
            appear={false}
            timeout={{ enter: 0, exit: 1000 }}
            unmountOnExit={true}
          >
              <div className="absolute flex justify-center items-center w-full h-full bg-card top-0 left-0 rounded-2xl">
                  <CircularProgress sx={{
                      transform: "translateY(-50%)",
                  }}/>
              </div>
          </Fade>

          {
            !props.loading && recordPresence && !props.dontShowNotFound && props.children
          }

          {
              (props.loading || !recordPresence) && !props.dontShowNotFound && <BaseNotFound text={props.notFoundText} height={props.bannerHeight ?? 500} />
          }
      </div>
    );
}