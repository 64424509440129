import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BaseInput from "@reusables/BaseInput";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import ToggleWrapper from "../../ToggleWrapper";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import {
    ProductSectionProps,
    ProductType
} from "@components/Dashboard/pages/Products/components/CreationEditingLayout/types";
import { Alert, Collapse, Fade, Stack } from "@mui/material";
import BaseMaterialButton from "@reusables/BaseMaterialButton";
import BatchNumbersModal from "../../modals/BatchNumbersModal";
import SerialNumbersModal from "../../modals/SerialNumbersModal";
import ServiceHider
    from "@components/Dashboard/pages/Products/components/CreationEditingLayout/parts/elements/ServiceHider";

export default function Inventory(props: ProductSectionProps) {
    const { t } = useTranslation("", { keyPrefix: "products.general" });

    const { control, setValue, formState: { errors } } = useFormContext<ProductType>();

    const { is_batch_number, is_serial_number } = useWatch({
        control,
        name: "options"
    });

    const [serialNumberModalOpen, setSerialNumberModalOpen] = useState(false);
    const [batchNumberModalOpen, setBatchNumberModalOpen] = useState(false);

    const GridTitle = (
        <div className="flex flex-row justify-between">
            <div className="text-xl text-accent">{t("categories.inventory")}</div>
            <ServiceHider>
                <Controller
                    name="inventory.in_stock"
                    control={control}
                    render={({ field }) => (
                        <Stack direction={"row"} spacing={2}>
                            {/* Button for adding serial numbers*/}
                            <Fade in={(field.value ?? 0) > 0 && is_serial_number}>
                                <div>
                                    <BaseMaterialButton type="button" fontWeight={500}
                                                        onClick={() => setSerialNumberModalOpen(true)}>
                                        {t("buttons.addSerialNumbers")}
                                    </BaseMaterialButton>
                                </div>
                            </Fade>

                            {/* Button for adding batch numbers */}
                            <Fade in={(field.value ?? 0) > 0 && is_batch_number}>
                                <div className={(field.value ?? 0) > 0 && is_batch_number ? "" : "hidden"}>
                                    <BaseMaterialButton type="button" fontWeight={500}
                                                        onClick={() => setBatchNumberModalOpen(true)}>
                                        {t("buttons.addBatchNumbers")}
                                    </BaseMaterialButton>
                                </div>
                            </Fade>
                        </Stack>
                    )}
                />
            </ServiceHider>
        </div>
    );

    return (
        <>
            <ServiceHider className={"space-y-[12px]"}>
                {/* Error for bad SERIALS filling */}
                {
                    <Collapse in={!!errors.serial_numbers}>
                        <Alert severity="error">{errors.serial_numbers?.message}</Alert>
                    </Collapse>
                }

                {/* Error for bad BATCHES filling */}
                {
                    <Collapse in={!!errors.batch_numbers}>
                        <Alert severity="error">{errors.batch_numbers?.message}</Alert>
                    </Collapse>
                }
            </ServiceHider>

            <BaseInputsGrid title={GridTitle} cols={{
                xl: 4,
                md: 3,
                xs: 2
            }}>
                {/*  IN STOCK */}
                {
                    !props.editing &&
                    <ServiceHider>
                        <Controller
                            name="inventory.in_stock"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    label={t("fields.inStock")}
                                    {...field}
                                    error={fieldState.error}
                                    type="number"
                                />
                            )}
                        />
                    </ServiceHider>
                }

                {/*  INVENTORY QUANTITY */}
                {!props.editing &&
                    <ServiceHider>
                        <Controller
                            name="inventory.min_inventory_quantity"
                            control={control}
                            render={({ field, fieldState }) => (
                                <BaseInput
                                    label={t("fields.invQty")}
                                    {...field}
                                    error={fieldState.error}
                                    type="number"
                                />
                            )}
                        />
                    </ServiceHider>
                }

                {/*  PURCHASE QUANTITY */}
                <ToggleWrapper label={t("fields.purchaseQty")} fieldKey="purchaseQty">
                    <Controller
                        name="inventory.min_purchase_quantity"
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                type="number"
                            />
                        )}
                    />
                </ToggleWrapper>

                {/*  SALE QUANTITY */}
                <ToggleWrapper label={t("fields.saleQty")} fieldKey="saleQty">
                    <Controller
                        name="inventory.min_sale_quantity"
                        control={control}
                        render={({ field, fieldState }) => (
                            <BaseInput
                                {...field}
                                error={fieldState.error}
                                type="number"
                            />
                        )}
                    />
                </ToggleWrapper>
            </BaseInputsGrid>


            {
                !props.editing &&
                <>
                    <BatchNumbersModal
                        isOpen={batchNumberModalOpen}
                        onClose={() => setBatchNumberModalOpen(false)}
                        onSubmit={data => {
                            setValue("batch_numbers", data.batch_numbers);
                            setBatchNumberModalOpen(false);
                        }}
                    />

                    <SerialNumbersModal
                        isOpen={serialNumberModalOpen}
                        onClose={() => setSerialNumberModalOpen(false)}
                        onSubmit={data => {
                            setValue("serial_numbers", data.serial_numbers);
                            setSerialNumberModalOpen(false);
                        }}
                    />
                </>
            }
        </>
    );
}