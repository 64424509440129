import React, {useMemo} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {calculateOrderPrice, normalizePrice} from "@helpers/utils";
import {SaleOrdersFormTyping} from "../../types";
import { useAppSelector } from "@/redux/hooks";

export default function SummaryBlock() {
    const {t} = useTranslation("", {keyPrefix: "sales.orders.general.orders"});

    const {control} = useFormContext<SaleOrdersFormTyping>();



    const currency = useAppSelector(state => state.auth.user?.company.currency.code)

    const lines = useWatch({
        control,
        name: "lines"
    });

    const linesSummary = useMemo(() => {
        let subtotal = 0;
        let taxes = 0;

        lines.forEach(line => {
            const linePrice = calculateOrderPrice(line.data?.quantity ?? 0, line.data?.unit_price ?? 0, line.data?.discount ?? 0);
            subtotal += linePrice;
            taxes += linePrice * ((line.data?.tax?.rate ?? 0) / 100);
        });

        const totalWithTaxes = subtotal + taxes;

        return {
            subtotal,
            taxes,
            totalWithTaxes
        }
    }, [lines]);

    return (
        <div className="flex justify-end mt-[48px] font-thin">
            <div className="w-1/2 grid grid-cols-2 gap-y-[16px]">
                <div className="text-right">
                    {t("summary.subtotal")}
                </div>
                <div className="text-right pr-[16px]">
                    {
                        normalizePrice(linesSummary.subtotal)
                    }
                    <span className="ml-1">{currency}</span>
                </div>
                <div className="text-right">
                    {t("summary.taxes")}
                </div>
                <div className="text-right pr-[16px]">
                    {
                        normalizePrice(linesSummary.taxes)
                    }
                    <span className="ml-1">{currency}</span>
                </div>
                <div className="text-right font-semibold text-accent">
                    {t("summary.total")}
                </div>
                <div className="text-right pr-[16px] font-semibold text-accent">
                    {
                        normalizePrice(linesSummary.totalWithTaxes)
                    }
                    <span className="ml-1">{currency}</span>
                </div>
            </div>
        </div>
    )
}