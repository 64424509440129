import React from "react";
import BaseButton from "@reusables/BaseButton";
import { toast } from "react-toastify";
import BaseModal from "@reusables/Modals/BaseModal";
import { useTranslation } from "react-i18next";
import { DeliveryTerms, PaymentTerms } from "@/types/general";
import { useDeletePaymentTermsMutation } from "@redux/features/paymentTerms/paymentTermsApi";
import { useDeleteDeliveryTermsMutation } from "@redux/features/deliveryTerms/deliveryTermsApi";

interface DeletionModalProperties {
    isOpen: boolean;
    deliveryTerms?: DeliveryTerms.Root;
    onClose: () => void;
}

export default function DeletionModal(props: DeletionModalProperties){
    const { t } = useTranslation("", { keyPrefix: "settings.general.deliveryTerms.modals.deletion" });

    const [deleteDeliveryTerms, { isLoading: isDeletionLoading }] = useDeleteDeliveryTermsMutation();

    return (
      <BaseModal
        isOpen={props.isOpen}
        onClose={props.onClose}
        width={700}
        padding="56px"
        isLoading={isDeletionLoading}
      >
          <div className="space-y-[32px]">
              <div className="text-xl font-semibold text-center text-accent">{t("heading")}</div>
              <div className="text-center text-accent">{t("subheading")}.</div>
              <div className="grid grid-cols-2 gap-[16px]">
                  <BaseButton text={t("buttons.cancel")} size="md" primaryOutlined onClick={props.onClose} />
                  <BaseButton text={t("buttons.delete")} size="md" onClick={() => {
                      if(props.deliveryTerms) {
                          deleteDeliveryTerms(props.deliveryTerms.id).unwrap().then(resp => {
                              toast.success(t("responses.success"));
                          }).catch(e => {
                              console.error(e);

                              toast.error(t("responses.error"));
                          })
                          .finally(() => props.onClose());
                      }
                  }}/>
              </div>
          </div>
      </BaseModal>
    )
}