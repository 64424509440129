import React from "react";
import { useTranslation } from "react-i18next";
import DashboardCard from "../DashboardCard";

import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import relativeTime from "dayjs/plugin/relativeTime";
import BaseTooltip from "@reusables/BaseTooltip";

import { ReactComponent as ArchiveBoxSVG } from "@assets/icons/ic_archive_box.svg";
import { useAppDispatch } from "@redux/hooks";
import { useGetUpcomingShipmentsQuery } from "@redux/features/purchases/purchasesApi";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import { handleQuickReceive } from "@/redux/features/receipts/thunks";
import { useHistory } from "react-router-dom";

dayjs.extend(isToday);
dayjs.extend(relativeTime); // output "in ... days" or something like this

export default function UpcomingShipments() {
    const {t} = useTranslation("", {
        keyPrefix: "dashboard.dashboard.upcomingShipments",
    });

    const history = useHistory();
    const dispatch = useAppDispatch();

    const {data: upcomingShipments = [], isLoading} = useGetUpcomingShipmentsQuery();

    return (
        <BaseRecordRenderer
            loading={isLoading}
            record={() => upcomingShipments.length > 0}
            bannerHeight={100}
            notFoundText={t("noUpcomingShipmentsFound")}
            hideLevitation={upcomingShipments.length > 0}
        >
            <DashboardCard
                // preview
                title={t("heading")}
            >
                <div className="grid grid-cols-4 gap-[24px] pb-[6px]">
                    {upcomingShipments.slice(0, 4).map((shipment, index) => (
                        <div
                            className="flex flex-row items-center bg-shipment-main px-[20px] py-[10px] rounded-[8px]"
                            key={index}
                        >
                            <div className="text-accent grow">
                                <div className="mb-[4px]">
                                    <strong>
                                        {shipment.code ?? shipment.id}
                                    </strong>
                                </div>
                                <div className="upcomingShipmentsST1:hidden">
                                    {shipment.delivery_date.isToday()
                                        ? t("orderDatePrefix")
                                        : (() => {
                                            const date = dayjs().to(shipment.delivery_date);
                                            return (
                                                date.toString().substring(0, 1).toUpperCase() +
                                                date.toString().substring(1)
                                            );
                                        })()}{" "}
                                    {t("orderTimePrefix")} {shipment.delivery_date.format("hh:mm A")}
                                </div>
                                <div className="hidden upcomingShipmentsST1:block text-sm">
                                    {shipment.delivery_date.format("DD.MM.YYYY")}
                                </div>
                            </div>
                            <BaseTooltip placement="right" title={`${t("tooltip")}`}>
                                <div
                                    onClick={() => {
                                        dispatch(handleQuickReceive(shipment.id, history))
                                    }}
                                    className="cursor-pointer ml-[20px] p-[8px] bg-shipment-icon rounded-[8px] transition-[.15s] hover:bg-shipment-iconHover hover:shadow-lg text-shipment-icon"
                                >
                                    <ArchiveBoxSVG height={24} width={24}/>
                                </div>
                            </BaseTooltip>
                        </div>
                    ))}
                </div>
            </DashboardCard>
        </BaseRecordRenderer>
    );
}
