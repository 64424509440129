import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import BrandLogo from "../../../assets/images/logo.svg";
import LoginPic from "../../../assets/images/im_login_dashboard.svg";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import './index.scss';
import axios from 'axios';
import BaseButton from '../../reusables/BaseButton';
import BaseInput from "../../reusables/BaseInputLegacy";
import { Input } from "@mui/material";

const API_URL = import.meta.env.VITE_API_URL

function ForgotPasswordPage() {
    const { t, i18n } = useTranslation();

    const [email, setEmail] = useState<string>("")

    const [formSubmitted, setFormSubmitted] = useState(false);

    const [loading, setLoading] = useState(false);

    const forgotPassword = () => {
        setLoading(true)

        axios.post(`${API_URL}/api/forgot-password`, {email})
        .then((res) => {
            console.log(res);

            setFormSubmitted(true);
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const handleForgotPasswordForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        forgotPassword();
    }

    const formContent = (
        <div className="login-left">
            <h1>
              {t("forgotPassword.emailInputPage.stage1.heading")}
              <p className="login-pretitle">
                {t("forgotPassword.emailInputPage.stage1.subheading")}
              </p>
            </h1>

            <form onSubmit={handleForgotPasswordForm} className="login-form">
                <div className="input-wrapper">
                    <label>{t('loginPage.emailLabel')}</label>
                    <Input
                      className="filled"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <BaseButton
                  text={t("forgotPassword.emailInputPage.stage1.button")}
                  textColor="white"
                  size="md"
                  buttonWidth="100%"
                  loading={loading}
                />
            </form>

            <div>
              <p className="goback-container">
                  <ArrowBackIcon />
                  <a href="/login" className='goback-label'>
                      {t("forgotPassword.emailInputPage.stage1.return")}
                  </a>
              </p>
            </div>
        </div>
    );

    const checkEmailContent = (
        <div className="login-left">
            <h1>
                {t("forgotPassword.emailInputPage.stage2.heading")}
                <p className="login-pretitle">
                    {t("forgotPassword.emailInputPage.stage2.subheading")}
                    <span className="text-dark"> {email}</span>
                </p>
            </h1>

            <div className='login-form'>
                <p className="login-pretitle">
                  {t("forgotPassword.emailInputPage.stage2.didntReceive")}
                </p>
                {/* <button onClick={forgotPassword} className={`btn btn-filled ${loading ? 'disabled' : ''}`}>
                    {
                        loading ? 'Loading...' : 'Resend email'
                    }
                </button> */}

                <BaseButton
                  onClick={forgotPassword}
                  text={t("forgotPassword.emailInputPage.stage2.button")}
                  textColor="white"
                  size="md"
                  buttonWidth="100%"
                  loading={loading}
                />
            </div>

            <div>
            <p className="goback-container">
                <ArrowBackIcon />
                <a href="/login" className='goback-label'>
                  {t("forgotPassword.emailInputPage.stage2.return")}
                </a>
            </p>
            </div>
        </div>
    )

    return (
    <main className="login">
      <div className="section login-section">
        <div className="container-lg mx-auto">
          <div className="login-top">
            <a href="/">
              <img src={BrandLogo} alt="Suppli Brand Logo" />
            </a>
          </div>
          <div className="login-main">
            {/* 
                If form is submitted successfully then, show secondary page.
                Otherwise, show form with email input.
            */}
            {
                formSubmitted ? checkEmailContent : formContent
            }
            <div className="login-right">
              <div className="login-pic">
                <img src={LoginPic} alt="Suppli Login Dashboard" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ForgotPasswordPage