import React, { useState } from "react";
import NavigationBar from "../NavigationBar";
import WaveImg from "@assets/images/waveIcon.svg";
import "./index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Attendance from "./components/Attendance";
import Equipment from "./components/Equipment";
import FooterBlock from "../FooterBlock";
import Pricing from "../section/Pricing";
import { useTranslation } from "react-i18next";

const PricingPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <NavigationBar />

      <main>
        <div className="container p-4 pt-0">
          {/* Heading */}
          <div>
            <div className="flex flex-col space-y-8 items-center tablet:pt-16">
              <img className="pricingWave" src={WaveImg} />
              <h2 className="max-w-[560px] px-8 text-5xl text-center font-bold">
                {t("pricingPage.getStartedBlock.title.1")} <br/>
                {t("pricingPage.getStartedBlock.title.2")}
              </h2>
              <p className="max-w-[37em] text-base text-center">
                {t("pricingPage.getStartedBlock.description")}
              </p>
            </div>
          </div>

          {/* Pricing */}
          <div className="py-24">
            <Pricing />
          </div>

          {/* Attendance */}
          <div className="py-24">
            <Attendance />
          </div>

          {/* Equipment */}
          <div className="py-24">
            <Equipment />
          </div>
        </div>
        <FooterBlock />
      </main>
    </>
  );
};

export default PricingPage;
