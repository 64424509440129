import React, { useState } from "react";
import { StepperItem } from "@reusables/BaseStepper/types";
import { Product } from "@/types/general";
import { useTranslation } from "react-i18next";
import BaseStepper from "@reusables/BaseStepper";
import LocationsTab from "./items/LocationsTab";
import PricesTab from "./items/PricesTab/PricesTab";
import PurchaseOrdersTab from "./items/PurchaseOrdersTab";
import SaleOrdersTab from "./items/SaleOrdersTab";
import TranslationsTab from "./items/TranslationTab/TranslationTab";
import NumbersTab from "./items/NumbersTab/NumbersTab";
import { useAbility } from "@/casl.config";

interface ProductStepperProperties {
    informationTab: JSX.Element;
    product?: Product.Extended;
    previewMode: boolean;
}

export default function ProductStepper(props: ProductStepperProperties) {
    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct" });

    const [activePage, setActivePage] = useState<number>(0);

    const ability = useAbility();

    const stepperTabs: StepperItem[] = [
        {
            title: t("tabs.information.heading")
        },
        {
            title: t("tabs.translations.heading")
        },
        {
            // hidden: !ability.can("price.view_price_history", "product"),
            title: t("tabs.prices.heading")
        },
        {
            hidden: props.product?.is_service && !ability.can("locations.view", "product"),
            title: t("tabs.locations.heading")
        },
        {
            // hidden: !ability.can("serial_number.view", "product") && !ability.can("batch_number.view", "product"),
            title: t("tabs.numbers.heading")
        },
        {
            title: t("tabs.purchaseOrder.heading")
        },
        {
            // hidden: !ability.can("sale_orders.view", "product"),
            title: t("tabs.saleOrder.heading")
        }
    ];

    return (
        <>
            <BaseStepper items={stepperTabs.map((tab) => ({ onClick: setActivePage, ...tab }))} />
            {/* Information page */}
            <div style={{ display: activePage === 0 ? "block" : "none" }}>{props.informationTab}</div>

            {/* Translations page */}
            {activePage === 1 && <TranslationsTab product={props.product!} />}

            {/* Purchase order page */}
            {/*TODO: Product here is nullable, but I dont see any sence to pass it as nullable to further tabs?*/}
            {activePage === 2 && <PricesTab product={props.product!}></PricesTab>}

            {/* Locations page */}
            <div style={{ display: activePage === 3 ? "block" : "none" }}>
                <LocationsTab product={props.product} preview={props.previewMode} />
            </div>

            {/* Serial/Batch page */}
            {activePage === 4 && <NumbersTab product={props.product!} />}

            {/* Purchase order page */}
            {activePage === 5 && <PurchaseOrdersTab product={props.product!} />}

            {/* Sales order page */}
            {activePage === 6 && <SaleOrdersTab product={props.product!} />}
        </>
    );
}
