import React from "react";
import { Check } from "@mui/icons-material";
import { Checkbox, FormControlLabel, styled, Tooltip, TooltipProps } from "@mui/material";
import Info from "@assets/images/Vector.svg";

type Props = {
  label: string | number | React.ReactElement;
  value?: any;
  extra?: any;
  onChange?: (checked: boolean, evt: React.SyntheticEvent,) => any;
  checked?: boolean;
  className?: string;
  tooltipTitle?: string;
  disabled?: boolean;
};

// custom tooltip for purchases more popover icons
const BlueOnGreenTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
))(`
    color: #3C3769;
    background-color: #C8E98E;
    font-size: 14px;
    font-weight: 300;
    border-radius: 6px;
    padding: 8px;
    & .MuiTooltip-arrow {
        color: #C8E98E;
    }
`);

/**
 *  Apart from being redesigned checkbox, supports adding "Tooltip". To activate this feature, specify "tooltipTitle" argument.
 *  Note: if no className specified, uses "flex items-center" classes.
 */
const BaseCheckbox = ({
  label,
  value,
  extra,
  onChange,
  className,
  tooltipTitle,
  checked,
  disabled,
  ...rest
}: Props) => {
  return (
    <div className={className ?? "flex items-center text-accent font-thin"}>
      <FormControlLabel
        {...rest}
        control={
          <Checkbox
            sx={{
              background: "rgba(166, 221, 76, 0.25) !important",
              padding: "6px",
              borderRadius: 0,
              border: 'none',
              marginLeft: "10px",
              color: "transparent",
              ".MuiSvgIcon-root": {
                width: "16px",
                height: "16px",
                opacity: disabled ? checked ? 1 : 0 : 1,
              },
              "&.Mui-checked": {
                color: "white",
                ".MuiSvgIcon-root": {
                  background: "#a6dd4c",
                  "& path": {
                    opacity: 0
                  }
                },
              },
            }}
            checkedIcon={<Check />}
          />
        }
        sx={{
          ".MuiFormControlLabel-label": {
            marginLeft: 1,
            color: "#3C3769 !important"
          },
          "&:hover": {
            ".MuiSvgIcon-root": {
              background: "#a6dd4c",
            },
          }
        }}
        value={value}
        label={label}
        onChange={(evt, state) => onChange?.(state, evt)}
        disabled={disabled}
        checked={checked}
      />
      {extra && <div>{extra}</div>}

      {tooltipTitle && (
        <BlueOnGreenTooltip title={tooltipTitle}>
          <img src={Info} alt={tooltipTitle} />
        </BlueOnGreenTooltip>
      )}
    </div>
  );
};

export default BaseCheckbox;
