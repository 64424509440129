import React from "react";

type Props = {
  children?: any
}

const BaseLabel = ({ children }: Props) => {
  return (
    <div className="label mb-2 text-md-semibold text-[#686868]              ">
      {children}
    </div>
  );
};

export default BaseLabel;
